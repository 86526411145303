import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { UmbracoHelper } from "src/app/helpers/umbraco.helper";
import { Project } from "src/app/models/project.model";
import { UmbracoService } from "src/app/services/umbraco.service";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";
import { Subscription } from "rxjs";

@Component({
    selector: 'projects-page',
    templateUrl: './projects.page.html',
    styleUrls: ['./projects.page.scss'],
    standalone: true,
    imports: [CommonModule, DataUdiDirective, DynamicHTMLModule],
    providers: [UmbracoService]
})
export class ProjectsPage {
    projects: Project[] = [];
    subscribers: Subscription[] = [];

    constructor(private umbracoService: UmbracoService) {
        this.subscribers.push(this.umbracoService.getProjectsPage().subscribe(content => {
            this.projects = content.map(content => <Project> { 
                title: UmbracoHelper.processContent(content.properties?.["bodyText"]?.sections[0]?.rows[0]?.areas[0]?.controls[0]?.value),
                innerHtml: UmbracoHelper.processContent(content.properties?.["bodyText"]?.sections[0]?.rows[0]?.areas[0]?.controls[1]?.value)
            });
        }));
    }
}