export enum CountryEnum {
    AF = "Afghanistan",
    AL = "Albanië",
    DZ = "Algerije",
    AD = "Andorra",
    AO = "Angola",
    AG = "Antigua en Barbuda",
    AR = "Argentinië",
    AM = "Armenië",
    AU = "Australië",
    AT = "Oostenrijk",
    AZ = "Azerbeidzjan",
    BS = "Bahama's",
    BH = "Bahrein",
    BD = "Bangladesh",
    BB = "Barbados",
    BY = "Wit-Rusland",
    BE = "België",
    BZ = "Belize",
    BJ = "Benin",
    BT = "Bhutan",
    BO = "Bolivië",
    BA = "Bosnië en Herzegovina",
    BW = "Botswana",
    BR = "Brazilië",
    BN = "Brunei",
    BG = "Bulgarije",
    BF = "Burkina Faso",
    BI = "Burundi",
    CV = "Cabo Verde",
    KH = "Cambodja",
    CM = "Kameroen",
    CA = "Canada",
    CF = "Centraal-Afrikaanse Republiek",
    TD = "Tsjaad",
    CL = "Chili",
    CN = "China",
    CO = "Colombia",
    KM = "Comoren",
    CD = "Congo (Democratische Republiek)",
    CG = "Congo (Republiek)",
    CR = "Costa Rica",
    HR = "Kroatië",
    CU = "Cuba",
    CY = "Cyprus",
    CZ = "Tsjechië",
    DK = "Denemarken",
    DJ = "Djibouti",
    DM = "Dominica",
    DO = "Dominicaanse Republiek",
    EC = "Ecuador",
    EG = "Egypte",
    SV = "El Salvador",
    GQ = "Equatoriaal-Guinea",
    ER = "Eritrea",
    EE = "Estland",
    SZ = "Eswatini",
    ET = "Ethiopië",
    FJ = "Fiji",
    FI = "Finland",
    FR = "Frankrijk",
    GA = "Gabon",
    GM = "Gambia",
    GE = "Georgië",
    DE = "Duitsland",
    GH = "Ghana",
    GR = "Griekenland",
    GD = "Grenada",
    GT = "Guatemala",
    GN = "Guinee",
    GW = "Guinee-Bissau",
    GY = "Guyana",
    HT = "Haïti",
    HN = "Honduras",
    HU = "Hongarije",
    IS = "IJsland",
    IN = "India",
    ID = "Indonesië",
    IR = "Iran",
    IQ = "Irak",
    IE = "Ierland",
    IL = "Israël",
    IT = "Italië",
    CI = "Ivoorkust",
    JM = "Jamaica",
    JP = "Japan",
    JO = "Jordanië",
    KZ = "Kazachstan",
    KE = "Kenia",
    KI = "Kiribati",
    KP = "Noord-Korea",
    KR = "Zuid-Korea",
    KW = "Koeweit",
    KG = "Kirgizië",
    LA = "Laos",
    LV = "Letland",
    LB = "Libanon",
    LS = "Lesotho",
    LR = "Liberia",
    LY = "Libië",
    LI = "Liechtenstein",
    LT = "Litouwen",
    LU = "Luxemburg",
    MG = "Madagaskar",
    MW = "Malawi",
    MY = "Maleisië",
    MV = "Maldiven",
    ML = "Mali",
    MT = "Malta",
    MA = "Marokko",
    MR = "Mauritanië",
    MU = "Mauritius",
    MX = "Mexico",
    FM = "Micronesië",
    MD = "Moldavië",
    MC = "Monaco",
    MN = "Mongolië",
    ME = "Montenegro",
    MZ = "Mozambique",
    MM = "Myanmar",
    NA = "Namibië",
    NR = "Nauru",
    NP = "Nepal",
    NL = "Nederland",
    NZ = "Nieuw-Zeeland",
    NI = "Nicaragua",
    NE = "Niger",
    NG = "Nigeria",
    MK = "Noord-Macedonië",
    NO = "Noorwegen",
    OM = "Oman",
    PK = "Pakistan",
    PW = "Palau",
    PS = "Palestina",
    PA = "Panama",
    PG = "Papoea-Nieuw-Guinea",
    PY = "Paraguay",
    PE = "Peru",
    PH = "Filipijnen",
    PL = "Polen",
    PT = "Portugal",
    QA = "Qatar",
    RO = "Roemenië",
    RU = "Rusland",
    RW = "Rwanda",
    KN = "Saint Kitts en Nevis",
    LC = "Saint Lucia",
    VC = "Saint Vincent en de Grenadines",
    WS = "Samoa",
    SM = "San Marino",
    ST = "Sao Tomé en Principe",
    SA = "Saoedi-Arabië",
    SN = "Senegal",
    RS = "Servië",
    SC = "Seychellen",
    SL = "Sierra Leone",
    SG = "Singapore",
    SI = "Slovenië",
    SB = "Salomonseilanden",
    SO = "Somalië",
    ZA = "Zuid-Afrika",
    SS = "Zuid-Soedan",
    ES = "Spanje",
    LK = "Sri Lanka",
    SD = "Soedan",
    SR = "Suriname",
    SE = "Zweden",
    CH = "Zwitserland",
    SY = "Syrië",
    TJ = "Tadzjikistan",
    TZ = "Tanzania",
    TH = "Thailand",
    TG = "Togo",
    TO = "Tonga",
    TT = "Trinidad en Tobago",
    TN = "Tunesië",
    TR = "Turkije",
    TM = "Turkmenistan",
    TV = "Tuvalu",
    UG = "Oeganda",
    UA = "Oekraïne",
    AE = "Verenigde Arabische Emiraten",
    GB = "Verenigd Koninkrijk",
    US = "Verenigde Staten",
    UY = "Uruguay",
    UZ = "Oezbekistan",
    VU = "Vanuatu",
    VA = "Vaticaanstad",
    VE = "Venezuela",
    VN = "Vietnam",
    YE = "Jemen",
    ZM = "Zambia",
    ZW = "Zimbabwe"
}