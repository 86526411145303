import { Component } from "@angular/core";
import { UniqueSellingPointsComponent } from "src/app/components/usp/usp.component";
import { MissionStatementComponent } from "../../../components/mission-statement/mission-statement.component";
import { PageTitle } from "../../../components/page-title/page-title.component";
import { ImageComponent } from "../../../components/image/image.component";
import { ProductCompareComponent } from "src/app/components/product-compare/product-compare.component";
import { DynamicTariffsComponent } from "../../../components/dynamic-tariffs/dynamic-tariffs.component";
import { CallToActionComponent } from "../../../components/call-to-action/call-to-action.component";
import { NewsComponent } from "../../../components/news/news.component";

@Component({
    selector: 'home-particulieren-page',
    standalone: true,
    templateUrl: './home-particulieren.page.html',
    styleUrls: ['home-particulieren.page.scss'],
    imports: [UniqueSellingPointsComponent, MissionStatementComponent, PageTitle, ImageComponent, ProductCompareComponent, DynamicTariffsComponent, CallToActionComponent, NewsComponent]
})
export class HomePage {
    constructor() {
        window.history.state.customerType = "customer";
    }
}