<dynamic-template *ngIf="htmlContent" [content]="htmlContent"></dynamic-template>
<h3>Historische tarieven</h3>
<div class="row">
    <div class="xl:col-6 lg:col-6 col-12">
        <h6>Elektriciteit</h6>
        <table>
            @for (electricityTariff of electricityTariffs; track $index) {
                <tr><a [href]="umbracoUrl + electricityTariff.url">{{electricityTariff.name}}</a></tr>
            }
        </table>
    </div>
    <div class="xl:col-6 lg:col-6 col-12">
        <h6>Gas</h6>
        <table>
            @for (gasTariff of gasTariffs; track $index) {
                <tr><a [href]="umbracoUrl + gasTariff.url">{{gasTariff.name}}</a></tr>
            }
        </table>
    </div>
</div>