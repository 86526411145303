import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, Router, RouterStateSnapshot } from "@angular/router";
import { StringHelper } from "src/app/helpers/string.helper";
import { Contract } from "src/app/models/contract.model";
import { Customer } from "src/app/models/customer.model";
import { SituationEnum } from "src/app/models/enums/situation.enum";

@Injectable()
export class CustomerWizardStep4CustomerGuard implements CanActivate {
    constructor(public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardResult {
        const extras = this.router.getCurrentNavigation()?.extras;
        const customer: Customer = extras?.state?.["customer"];
        const contract: Contract = extras?.state?.["contract"];
        return (extras?.state?.["electricityType"] ? true : false || extras?.state?.['gasType'] ? true : false) 
        && ((!!customer && customer.gender != 0 && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.firstName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.lastName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.telephone) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.streetName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.houseNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.postalCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.location) && (customer.invoiceAddressIsSameToPostalAddress || (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressStreetName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressHouseNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressPostalCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressLocation)))) ? true : false) 
        && ((!!contract && !!contract.situation && !!contract.startDate && (extras?.state?.["electricityType"] == null || StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.electricityEAN)) && (extras?.state?.["gasType"] == null || StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.gasEAN))) ? true : false); 
    }
}