import { ComponentFactoryResolver, Injectable, Injector } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { FilterPipe } from "../pipes/filter.pipe";
import { ProductService } from "./product.service";
import { DistributionManagerService } from "./distibutionmanager.service";
import { EnumKeyValue } from "../pipes/enumKeyValue.pipe";
import { UmbracoService } from "./umbraco.service";
import { UmbracoHelper } from "../helpers/umbraco.helper";
import { Subscription } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    constructor(private componentFactoryResolver: ComponentFactoryResolver, private router: Router) {}

    async searchRoutes(query: string): Promise<any[]> {
        const config = this.router.config;
        return await this.findRoutes(config, query);
    }

    private async findRoutes(routes: any[], query: string): Promise<any[]> {
        let results: any[] = [];
        for (const route of routes) {
            var result = await this.extractInnerTextComponent(route.component);
            const innerText = result.innerText;
            const innerHTML = result.innerHTML;
            if (route.title?.includes(query) || innerHTML?.toLowerCase()?.includes(query?.toLowerCase()) || innerText?.toLowerCase()?.includes(query?.toLowerCase())) {
                const sentences = innerText?.split(/[\.\?\!]/).filter((sentence: string) => sentence?.toLowerCase()?.includes(query?.toLowerCase()));
                results.push({route: route, innerText: UmbracoHelper.processContent(sentences.join('<br>')), innerHTML: UmbracoHelper.processContent(innerHTML)});
            }
        }
        return results;
    }

    private async extractInnerTextComponent(component: any): Promise<any> {
        if (!component) return { innerText: '', innerHTML: '' };
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        if (!componentFactory) return { innerText: '', innerHTML: '' };
        const componentInstance = componentFactory.create(Injector.create({providers: [DistributionManagerService, EnumKeyValue, FilterPipe, ProductService, RouterModule, UmbracoService]}));
        if (!componentInstance) return { innerText: '', innerHTML: '' };
        (componentInstance.instance as any).ngOnInit?.();
        (componentInstance.instance as any).ngAfterViewInit?.();
        await Promise.all((componentInstance.instance as any).subscribers?.map((subscriber: Subscription) => {
            return new Promise<void>(resolve => {
            const checkSubscription = () => {
                if (subscriber.closed) {
                resolve();
                } else {
                setTimeout(checkSubscription, 100);
                }
            };
            checkSubscription();
            });
        }) || []);
        componentInstance.changeDetectorRef.detectChanges();
        const innerText = componentInstance.location.nativeElement.innerText;
        const innerHTML = componentInstance.location.nativeElement.innerHTML;
        return { innerText: innerText, innerHTML: innerHTML };
    }
}