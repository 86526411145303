<div>
<h1>Overzicht</h1>
<p></p>
<h4>Persoonlijke gegevens<div class="float-right"><p-button outlined="true"><span class="pi-pencil" [routerLink]="['/customer-wizard/customer', 'step-2']" [state]="{ 'gasType': gasType, 'electricityType': electricityType, 'customer': customer, 'contract': contract, 'paymentDetails': paymentDetails }"></span></p-button></div></h4>

<div class="grid fluid">
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <label>Particuliere klant</label>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="gender">Aanspreking</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="gender">{{Gender[customer.gender]}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="firstName">Voornaam</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="firstName">{{customer.firstName}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="lastName">Familienaam</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="lastName">{{customer.lastName}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="telephone">Telefoonnummer</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="telephone">{{customer.telephone}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="email">E-mailadres</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="email">{{customer.email}}</strong>
        </div>
    </div>
    @if(!customer.invoiceAddressIsSameToPostalAddress) {
        <div class="row">
            <div class="xl:col-6 lg:col-6 col-12">
                <label for="invoicing-address">Facturatieadres</label>
            </div>
            <div class="xl:col-6 lg:col-6 col-12">
                <strong name="invoicing-address">{{!customer.invoiceAddressIsSameToPostalAddress ? customer.invoicingAddressStreetName + " " 
                    + customer.invoicingAddressHouseNumber + ", " + customer.invoicingAddressPostalCode + " " + customer.invoicingAddressLocation 
                    : customer.streetName + " " + customer.houseNumber + ", " + customer.postalCode + " " + customer.location }}</strong>
            </div>
        </div>
    }
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="address">{{customer.invoiceAddressIsSameToPostalAddress ? 'Adresgegevens' : 'Verbruiksadres' }}</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="address">{{customer.streetName}} {{customer.houseNumber}}, {{customer.postalCode}} {{customer.location}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <h3>Je aansluiting <div class="float-right"><p-button outlined="true"><span class="pi-pencil" [routerLink]="['/customer-wizard/customer', 'step-3']" [state]="{ 'gasType': gasType, 'electricityType': electricityType, 'customer': customer, 'contract': contract, 'paymentDetails': paymentDetails }"></span></p-button></div></h3>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="situation">Je situatie </label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="situation">{{ContractSituation[contract.situation]}}</strong>
        </div>
    </div>
    @if (contract.situation == ContractSituation['Ik verhuis en kies voor Ebem (overname)']) {
        <div class="row">
            <div class="xl:col-6 lg:col-6 col-12">
                <label>Overnamedocument</label>
            </div>
            <div class="xl:col-6 lg:col-6 col-12">
                <p-button [style]="{padding: 0}" link="true" *ngIf="contract.takeoverDocuments && contract.takeoverDocuments.length > 0" (click)="open(contract.takeoverDocuments[0])" target="_blank">{{contract.takeoverDocuments[0].name}}</p-button>
            </div>
        </div>
    }
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="startDate">Start van het contract</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="startDate">{{contract.startDate | date: 'dd/MM/yyyy'}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="startDate">EAN-nummers</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <p><strong name="startDate">{{contract.electricityEAN}}</strong></p>
            <p><strong name="startDate">{{contract.gasEAN}}</strong></p>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <h3>Betaling <div class="float-right"><p-button outlined="true"><span class="pi-pencil" [routerLink]="['/customer-wizard/customer', 'step-4']" [state]="{ 'gasType': gasType, 'electricityType': electricityType, 'customer': customer, 'contract': contract, 'paymentDetails': paymentDetails }"></span></p-button></div></h3>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="invoicing">Facturatie </label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="invoicing">{{InvoiceModality[paymentDetails.invoiceModality]}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="frequency">Afrekening</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="frequency">{{BillingFrequency[paymentDetails.billingFrequency]}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="frequency">Betaalwijze</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="frequency">{{PaymentPreference[paymentDetails.paymentPreference]}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="frequency">Rekeningnummer</label>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <strong name="frequency">{{paymentDetails.iban}}</strong>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <h3>Geselecteerde producten <div class="float-right"><p-button outlined="true"><span class="pi-pencil" (click)="enableSelection=true"></span></p-button></div></h3>
        </div>
        <div class="xl:col-12 lg:col-12 col-12">
            <product-selection-component [iconCSSClass]="'pi-bolt ebemGreen'" [checkmarksCSSClass]="'check-ebemGreen'" [selectedBackgroundCSSClass]="'backgroundEbemLightGreen'" [products]="electricityProducts | filter: includesType(electricityType)" [(selection)]="electricityType" (selectionChange)="calculateTotalPrice()"></product-selection-component>
            @if(enableSelection) {
                <p-accordion [expandIcon]="'pi-angle-down'" [collapseIcon]="'pi-angle-up'">
                    <p-accordion-panel value="0">
                        <p-accordion-header class="backgroundLightGray">
                            Een ander Elektriciteit product selecteren
                        </p-accordion-header>
                        <p-accordion-content>
                            <product-selection-component [iconCSSClass]="'pi-bolt ebemGreen'" [checkmarksCSSClass]="'check-ebemGreen'" [selectedBackgroundCSSClass]="'backgroundEbemLightGreen'" [products]="electricityProducts | filter: excludesType(electricityType)" [(selection)]="electricityType" (selectionChange)="calculateTotalPrice()"></product-selection-component>
                        </p-accordion-content>
                    </p-accordion-panel>
                </p-accordion>
            }
        </div>
        <div class="xl:col-12 lg:col-12 col-12">
            <product-selection-component [iconCSSClass]="'material-icons ebemBlue'" [materialIcon]="'local_fire_department'" [checkmarksCSSClass]="'check-ebemBlue'" [selectedBackgroundCSSClass]="'backgroundEbemLightBlue'" [products]="gasProducts | filter: includesType(gasType)" [(selection)]="gasType" (selectionChange)="calculateTotalPrice()"></product-selection-component>
            @if(enableSelection) {
                <p-accordion [expandIcon]="'pi-angle-down'" [collapseIcon]="'pi-angle-up'">
                    <p-accordion-panel value="0">
                        <p-accordion-header class="backgroundLightGray">
                            Een ander Gas product selecteren
                        </p-accordion-header>
                        <p-accordion-content>
                            <product-selection-component [iconCSSClass]="'material-icons ebemBlue'" [materialIcon]="'local_fire_department'" [checkmarksCSSClass]="'check-ebemBlue'" [selectedBackgroundCSSClass]="'backgroundEbemLightBlue'" [products]="gasProducts | filter: excludesType(gasType)" [(selection)]="gasType" (selectionChange)="calculateTotalPrice()"></product-selection-component>
                        </p-accordion-content>
                    </p-accordion-panel>
                </p-accordion>
            }
        </div>
    </div>

    <div class="xl:col-12 lg:col-12 col-12" *ngIf="priceTotal != 0 && !isNaN(priceTotal) && priceTotal != undefined && priceTotal != null">
        <p-inputgroup class="backgroundEbemLighterGreen">  
            <h3><span class="pi-bolt ebemGreen"></span> Totaal <div class="right-price"><strong>€ {{priceTotal | number: '1.0-2'}}</strong>/<small>mnd</small></div></h3>
            <ul class="check-ebemGreen">
                <li>Prijs incl. BTW</li>
                <li>Facturatie per email</li>
            </ul>
            <p-button (onClick)="overlayPanel.toggle($event)" link="true">Tarief opbouw en details bekijken</p-button>
            <p-overlayPanel #overlayPanel>
                <p-tabs [value]="electricityType ? 0 : gasType ? 1 : -1">
                    <p-tablist>
                        @if (electricityType) {
                        <p-tab title="Elektriciteit" [value]="0">
                            <div class="grid fluid">

                                <div class="row">
                                    <div class="xl:col-12 lg:col-12 col-12">                           
                                        <span class="pi-bolt ebemGreen" style="font-size: 22px"></span> Elektriciteit
                                    </div>
                                </div>
                            </div>
                        </p-tab>
                        }
                        @if (gasType) {
                        <p-tab title="Gas" [value]="1">
                            <div class="grid fluid">
                                <div class="row">
                                    <div class="xl:col-12 lg:col-12 col-12">
                                        <span class="material-icons ebemBlue" style="font-size: 25px">local_fire_department</span> Aardgas
                                    </div>
                                </div>
                            </div>
                        </p-tab>
                        }
                    </p-tablist>
                    <p-tabpanels>
                        <p-tabpanel [value]="0">
                            <div *ngFor="let selectedElectricityProduct of electricityProducts | filter: includesType(electricityType); track $index">
                                <div class="row" *ngFor="let priceDetail of selectedElectricityProduct?.priceDetails">
                                    <div class="xl:col-6 lg:col-6 col-12">
                                        <p-input-group>
                                            <strong>{{priceDetail?.description}}</strong>
                                        </p-input-group>
                                    </div>
                                    <div class="xl:col-6 lg:col-6 col-12">
                                        <p-input-group>
                                            <strong>{{priceDetail?.amount}}</strong>
                                        </p-input-group>
                                    </div>
                                </div>
                            </div>
                        </p-tabpanel>
                        <p-tabpanel [value]="1">
                            <div *ngFor="let selectedGasProduct of gasProducts | filter: includesType(gasType); track $index">
                                <div class="row" *ngFor="let priceDetail of selectedGasProduct?.priceDetails; track $index">
                                    <div class="xl:col-6 lg:col-6 col-12">
                                        <p-input-group>
                                            <strong>{{priceDetail?.description}}</strong>
                                        </p-input-group>
                                    </div>
                                    <div class="xl:col-6 lg:col-6 col-12">
                                        <p-input-group>
                                            <strong>{{priceDetail?.amount}}</strong>
                                        </p-input-group>
                                    </div>
                                </div>
                            </div>
                        </p-tabpanel>
                    </p-tabpanels>
                </p-tabs>
                <p-button class="close-button-right" outlined="true" (onClick)="overlayPanel.hide()">Sluiten</p-button>
            </p-overlayPanel>
        </p-inputgroup>
    </div>

    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <p-inputgroup class="backgroundEbemLighterGreen">
                <div class="checkbox-left"><p-checkbox size="large" class="checkbox-left" [(ngModel)]="confirmed" [binary]="true"></p-checkbox><label>&nbsp;&nbsp;&nbsp;Ik bevestig dat bovenstaande gegevens juist en volledig zijn en dat ik de algemene voorwaarden aanvaard.</label></div>
            </p-inputgroup>
        </div>
    </div>
</div>
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="100" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/customer-wizard/customer', 'step-4']" [state]="{ 'gasType': gasType, 'electricityType': electricityType, 'customer': customer, 'contract': contract, 'paymentDetails': paymentDetails, 'postalCode': postalCode, 'priceSimulationInput': priceSimulationInput }">Terug</p-button>
    </div>
    <div class="col-6">
        <p-button [disabled]="!confirmed" class="float-right" (click)="postCustomer();">Klant worden</p-button>     
    </div>
</div>
<p-toast/>
</div>