<div>
<h3>Nog even samenvatten</h3>
<p>Je voorstel wordt gemaakt op basis van volgende gegevens:</p>

<h4>Algemeen <div class="float-right"><p-button outlined="true"><span class="pi-pencil" [routerLink]="['/price-calculation', 'step-2']" [state]="{ 'electricityType': electricityType, 'gasType': gasType,  'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }"></span></p-button></div></h4>
<p>Je woonplaats is <strong>{{priceSimulationInput.postalCode}} - {{getNameFromPostalCode(priceSimulationInput.postalCode)}}</strong></p>
<p>
    <span>Je wilt graag een voorstel voor </span>
    @for(priceSimulationChoice of priceSimulationChoices; track $index) {
        {{PriceSimulationChoice[priceSimulationChoice]}} <span *ngIf="$index < priceSimulationChoices.length - 1">en </span>
    }
    </p>
<hr class="hrEbemGreen"/>

<h4>Voorstel op maat <div class="float-right"><p-button outlined="true"><span class="pi-pencil" [routerLink]="['/price-calculation', 'step-2b']" [state]="{ 'electricityType': electricityType, 'gasType': gasType,  'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }"></span></p-button></div></h4>
<p>Je hebt een {{PriceSimulationTariffType[priceSimulationOptions.tariffType]}}, {{PriceSimulationMeterType[priceSimulationOptions.meterType]}} en {{priceSimulationOptions.hasNightTariff ? '' : 'geen'}} nachttarief.</p>
<p>Je hebt {{PriceSimulationSolarPanels[priceSimulationOptions.solarPanels]}}.</p>

<hr class="hrEbemGreen"/>

<h4>Verbruik inschatten <div class="float-right"><p-button outlined="true"><span class="pi-pencil" [routerLink]="['/price-calculation', 'step-2d']" [state]="{ 'electricityType': electricityType, 'gasType': gasType,  'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }"></span></p-button></div></h4>
<p>Je verwarmt je woning {{PriceSimulationHeatingType[priceSimulationOptions.heatingType]}}. Je hebt <span>@for(energyConsumer of priceSimulationOptions.energyConsumers; track $index) { {{PriceSimulationEnergyConsumers[energyConsumer]}}<span *ngIf="$index < priceSimulationOptions.energyConsumers.length - 1">, </span> }</span> <span *ngIf="priceSimulationOptions.energyConsumers.length == 0"> geen</span><span *ngIf="priceSimulationOptions.energyConsumers.length > 1"> als</span> bijkomende grote verbruikers aangeduid.</p>
<p>Je woont in een {{BuildingType[buildingType]}}, met {{numberOfPersons}} personen.</p>
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="80" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/price-calculation', 'step-2']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Terug</p-button>
    </div>
    <div class="col-6">
        <p-button class="float-right" [routerLink]="['/price-calculation', 'step-3']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Bereken mijn voorstel <span class="pi-arrow-right"></span></p-button>
    </div>
</div>
</div>