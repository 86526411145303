.radio-left {
    position: relative;
    float: left;
}

.checkbox-left {
    position: relative;
    float: left;
}

.close-button-right {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 15px;
}

p-input-group {
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    align-content: center;
    margin-bottom: 15px;

    p-inputgroup-addon {
        justify-content: flex-start;
    }

    &:has(.p-checkbox-checked) {
        border-color: #436807 !important;
        border-width: 2px !important;
        background-color: #D1E09B !important;
    }

    &:has(.p-radiobutton-checked) {
        border-color: #436807 !important;
        border-width: 2px !important;
        background-color: #D1E09B !important;
    }
}

p-inputgroup {
    border: 1px solid #A2C138 !important;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    align-content: flex-start;
    margin-bottom: 15px;

    p-inputgroup-addon {
        justify-content: flex-start;
    }
}