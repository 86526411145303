<div class="row pt-8 mb-4">
    <div class="xl:justify-content-end lg:justify-content-end md:justify-content-center sm:justify-content-center flex col-12">
        <p-button [link]="true" [styleClass]="'p-button-link-no-decoration'" (click)="picker.updateModel(addDays(selectedDate, -1)); picker.updateInputfield();"><span class="pi-arrow-left"></span></p-button>
        <span>&nbsp;</span><p-datepicker [styleClass]="'no-border w-7rem'" [maxDate]="today" #picker dateFormat="d-mm-yy" [(ngModel)]="selectedDate" (ngModelChange)="generateDataForDate(selectedDate);"></p-datepicker><span>&nbsp;</span>
        <p-button [disabled]="selectedDate >= today" [link]="true" [styleClass]="'p-button-link-no-decoration'" (click)="picker.updateModel(addDays(selectedDate, 1)); picker.updateInputfield();"><span class="pi-arrow-right"></span></p-button>
    </div>
</div>
<graph-component [data]="chartData" [options]="chartOptions"></graph-component>
<br/>
<br/>
<br/>
<br/>
<br/>