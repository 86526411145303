export class BelpexResponse {
    date: Date;
    hour: number;
    index: number;

    constructor(date: Date, hour: number, index: number) {
        this.date = date;
        this.hour = hour;
        this.index = index;
    }
}

export function jsonToBelpexResponse(json: any): BelpexResponse {
    return new BelpexResponse(json.date, json.hour, json.index);
}