
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="company">Bedrijf</label>
            <input name="company" [(ngModel)]="contactForm.bedrijf" placeholder="Aa" pInputText />
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="VAT">BTW-nummer</label>
            <input name="VAT" placeholder="BE" [ngClass]="inputVATNumberValid == null || inputVATNumberValid == undefined ? '' : inputVATNumberValid === true ? 'ng-valid' : inputVATNumberValid === false ? 'ng-invalid' : ''" (input)="validateVATInput()" [(ngModel)]="contactForm.btwNummer" pInputText />
            <p-card class="danger no-padding" *ngIf="showErrorVATInvalid">BTW-nummer heeft niet het juiste formaat: BE gevolgd door 10 cijfers</p-card>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="firstName">Voornaam</label>
            <input name="firstName" [(ngModel)]="contactForm.voornaam" placeholder="Aa" [ngClass]="inputFirstNameValid == null ||  inputFirstNameValid == undefined ? '' : inputFirstNameValid === true ? 'ng-valid' : inputFirstNameValid === false ? 'ng-invalid' : ''" (input)="validateFirstNameInput()" [(ngModel)]="contactForm.voornaam" pInputText >
            <p-card class="danger no-padding" *ngIf="showErrorNoFirstNameFilledIn">Voornaam is een verplicht veld</p-card>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <label for="lastName">Naam</label>
            <input name="lastName" [(ngModel)]="contactForm.naam" placeholder="Aa" [ngClass]="inputLastNameValid == null ||  inputLastNameValid == undefined ? '' : inputLastNameValid === true ? 'ng-valid' : inputLastNameValid === false ? 'ng-invalid' : ''" (input)="validateLastNameInput()" [(ngModel)]="contactForm.naam" pInputText >
            <p-card class="danger no-padding" *ngIf="showErrorNoLastNameFilledIn">Achternaam is een verplicht veld</p-card>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-3 lg:col-3 col-12">
            <label for="postalCode">Postcode</label>
            <p-inputnumber class="flex" name="postalCode" [ngClass]="inputPostalCodeValid == null ||  inputPostalCodeValid == undefined ? '' : inputPostalCodeValid === true ? 'ng-valid' : inputPostalCodeValid === false ? 'ng-invalid' : ''" placeholder="1234" [minFractionDigits]="0" [maxFractionDigits]="0" mode="decimal" [useGrouping]="false" [(ngModel)]="contactForm.postCode" (onInput)="contactForm.plaats = getNameFromPostalCode(contactForm.postCode); validatePostalCodeInput();" ></p-inputnumber>
            <p-card class="danger no-padding" *ngIf="showErrorNoPostalCodeFilledIn">Postcode is een verplicht veld</p-card>  
            <p-card class="danger no-padding" *ngIf="showErrorPostalCodeInvalid">Het opgegeven postcode is niet in Vlaanderen gelegen</p-card>  
        </div>
        <div class="xl:col-9 lg:col-9 col-12">
            <p style="position: relative; top: 7.5px; font-size: 20px;"><span class="pi-map-marker"></span> {{getNameFromPostalCode(contactForm.postCode)}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label for="email">Email</label>
            <input type="email" name="email" placeholder="voorbeeld@domein.com" [ngClass]="inputEmailValid == null ||  inputEmailValid == undefined ? '' : inputEmailValid === true ? 'ng-valid' : inputEmailValid === false ? 'ng-invalid' : ''" (input)="validateEmailInput()" [(ngModel)]="contactForm.email" pInputText >
            <p-card class="danger no-padding" *ngIf="showErrorNoEmailFilledIn">Email is een verplicht veld</p-card>
            <p-card class="danger no-padding" *ngIf="showErrorEmailWrongFormat">Email heeft niet het juiste formaat: tekenreeks beginnend met een letter of een cijfer gevolgd door een &#64; gevolgd door een emailprovider en een webextensie (.com, .be, ...)</p-card>    
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label for="telephone">Telefoon</label>
            <input type="tel" name="telephone" placeholder="+32" [ngClass]="inputTelephoneValid == null ||  inputTelephoneValid == undefined ? '' : inputTelephoneValid === true ? 'ng-valid' : inputTelephoneValid === false ? 'ng-invalid' : ''" (input)="validateTelephoneNumberInput()" [(ngModel)]="contactForm.telefoon" pInputText >
            <p-card class="danger no-padding" *ngIf="showErrorNoTelephoneFilledIn">Telefoon is een verplicht veld</p-card>
            <p-card class="danger no-padding" *ngIf="showErrorTelephoneWrongFormat">Telefoonnummer heeft niet het juiste formaat: landcode (+32 voor België) gevolgd door 8 cijfers (vast telefoonnummer) of 9 cijfers (gsmnummer)</p-card>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label for="message">Bericht</label>
            <p-editor name="message" placeholder="Aa" [style]="{ height: '356px' }" [ngClass]="inputMessageValid == null ||  inputMessageValid == undefined ? '' : inputMessageValid === true ? 'ng-valid' : inputMessageValid === false ? 'ng-invalid' : ''" (input)="validateMessageInput()" [(ngModel)]="contactForm.bericht" />
            <p-card class="danger no-padding" *ngIf="showErrorNoMessageFilledIn">Bericht is een verplicht veld</p-card>
        </div>
    </div>
    <div>
        
        <ngx-turnstile [siteKey]="siteKey" (resolved)="sendCaptchaResponse($event)" theme="auto" [tabIndex]="0" [appearance]="'interaction-only'"></ngx-turnstile>
    </div>
    <div class="row">
        <div class="col-12">
            <p-button (click)="send()">Versturen</p-button>
        </div>
    </div>
    <p-toast/>