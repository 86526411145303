.text-center {
  text-align: center;
}

.right-border {
    border-right: 1px solid #e0e0e0;
}

.gray {
    color: #757575;
}