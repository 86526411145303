<p-card class="backgroundEbemLighterGreen">
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <h2>Ook interesse in een {{product?.isDynamic ? 'dynamisch' : product?.isVariable ? 'variabel' : 'vast'}} tarief?</h2>
            <p>Geniet van een voordelige groene energieprijs op basis van de Belgische energiemarkt. Kom niet voor verrassingen te staan.</p>
        </div>
        <div class="xl:col-6 lg:col-6 col-12">
            <p-inputgroup [style]="{backgroundColor: 'white'}">  
                <h3><span [ngClass]="isElectricityProduct(product) ? 'pi-bolt ebemGreen' : 'material-icons ebemBlue'">{{isGasProduct(product) ? 'local_fire_department' : ''}}</span> {{product?.name}}</h3>
                <ul [ngClass]="isElectricityProduct(product) ? 'check-ebemGreen' : 'check-ebemBlue'">
                    <li>{{product?.isVariable ? 'Variabele' : ''}} {{product?.isDynamic ? 'Dynamische' : ''}} prijs</li>
                    <li>{{product?.forAnalogMeter ? 'Voor analoge & ' : 'Enkel voor ' }} digitale meters</li>
                    <li>{{product?.onlyElectronicInvoicing ? 'Enkel ' : ''}} Elektronische facturatie mogelijk</li>
                </ul>
                <p-accordion [expandIcon]="'pi-angle-down'" [collapseIcon]="'pi-angle-up'">
                    <p-accordion-panel value="0">
                        <p-accordion-header [ngClass]="isElectricityProduct(product) ? 'backgroundEbemLightGreen' : 'backgroundEbemLightBlue'">Meer details</p-accordion-header>
                        <p-accordion-content>            
                            <p class="m-0">
                                <div class="row">
                                    <div class="col-12">
                                        <p-table [value]="[{}]">
                                            <ng-template #header>
                                                <tr>
                                                    <th></th>
                                                    <th class="text-center">{{product?.name}}</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template #body let-rowData let-columns="products">
                                                <tr>
                                                    <td class="right-border">Variabele prijs</td>
                                                    <td class="text-center"><span [ngClass]="product?.isVariable ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                                </tr>
                                                <tr>
                                                    <td class="right-border">Dynamische prijs</td>
                                                    <td class="text-center"><span [ngClass]="product?.isDynamic ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                                </tr>
                                                <tr>
                                                    <td class="right-border">Geschikt voor analoge meter</td>
                                                    <td class="text-center"><span [ngClass]="product?.forAnalogMeter ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                                </tr>   
                                                <tr>
                                                    <td class="right-border">Facturatie<br/><small class="gray">Maandelijke facturatie is enkel mogelijk met een digitale meter</small></td>
                                                    <td class="text-center">{{InvoicingModality[product?.invoicing ?? 0]}}</td>
                                                </tr>  
                                                <tr>
                                                    <td class="right-border">Domiciliëring of overschrijving</td>
                                                    <td class="text-center"><span [ngClass]="product?.directDebit ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                                </tr>  
                                                <tr>
                                                    <td class="right-border">Enkel elektronische facturatie</td>
                                                    <td class="text-center"><span [ngClass]="product?.onlyElectronicInvoicing ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                                </tr>  
                                                <tr>
                                                    <td class="right-border">Particulier of klein-zakelijk</td>
                                                    <td class="text-center"><span [ngClass]="product?.individualOrSmallBusiness ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                                </tr> 
                                            </ng-template>    
                                        </p-table>
                                    </div>
                                </div>
                        </p-accordion-content>
                    </p-accordion-panel>
                </p-accordion>
                <br/><br/>
                <p-button fluid="true" [severity]="isElectricityProduct(product) ? 'primary' : isGasProduct(product) ? 'secondary' : 'primary'" [routerLink]="['/customer-wizard/' + customerType, 'step-1']" [state]="{ 'electricityType': isElectricityProduct(product) ? product?.enumValue : '', 'gasType': isGasProduct(product) ? product?.enumValue : '' }">Kies voor {{product?.name}}</p-button>
                <a [href]="isElectricityProduct(product) ? product?.isDynamic ? umbracoUrl + electricityTariffDynamic?.url : umbracoUrl + electricityTariff?.url : umbracoUrl + gasTariff?.url">Opbouw van tarief bekijken</a>
            </p-inputgroup>
        </div>
    </div>
</p-card>
