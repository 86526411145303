<div>
<h1>Klant worden</h1>

<h2>Welkom als nieuw lid van Ebem!</h2>

<div class="row">
    <div class="xl:col-12 lg:col-12 col-12">
        <p>Je krijgt binnen de x werkdagen een bevestiging van je contract</p>
    </div>
</div>
<div class="row">
    <div class="xl:col-12 lg:col-12 col-12">
        <p-button fluid="true" class="w-100" [routerLink]="['/']">Naar homepage</p-button>
    </div>
</div>
<p-toast/>
</div>