import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'enumKeyValue', standalone: true})
export class EnumKeyValue implements PipeTransform {
  transform(obj: object) {
    return Object.entries(obj).
      filter(([key, value]) =>
        (!/^\d+$/.test(key) ||         // Include keys that don't look like integers or...
        !obj.hasOwnProperty(value)) &&   // ...include keys whose values do not appear as keys also.
        (key !== undefined && key !== null && key !== '') &&
        (value !== undefined && value !== null && value !== '')).  
      map(([key, value]) => ({key, value}));
  }
}