export class NacebelCode {
    id: number;
    code: string;
    description: string;

    constructor(id: number, code: string, description: string) {
        this.id = id;
        this.code = code;
        this.description = description;
    }
}

export function jsonToNacebel(json: any): NacebelCode {
    return new NacebelCode(json.id, json.code, json.description);
}
export function nacebelToJson(value: NacebelCode): any {
    return {
        id: value.id,
        code: value.code,
        description: value.description
    };
}