import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MenuItem } from "primeng/api";
import { MegaMenuItem } from "primeng/api";
import { TabsModule } from "primeng/tabs"
import { MenubarModule } from "primeng/menubar"
import { MegaMenuModule } from "primeng/megamenu"
import { SearchComponent } from "../search/search.component";
import { Vacancy } from "src/app/models/vacancy.model";
import { VacancyService } from "src/app/services/vacancy.service";
import { ButtonModule } from "primeng/button";
import { PopoverModule } from "primeng/popover";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['header.component.scss'],
    standalone: true,
    imports: [CommonModule, ButtonModule, MenubarModule, MegaMenuModule, FormsModule, PopoverModule, ReactiveFormsModule, RouterModule, SearchComponent, TabsModule],
    providers: [VacancyService]
})
export class HeaderComponent {
    selectedTab: number = 0;
    vacancies: Vacancy[] = [];
    window = window;

    constructor(vacacyService: VacancyService) {
        vacacyService.getAllVacancies().subscribe(vacancies => {
            this.vacancies = vacancies;
            const vacatureParticulieren = this.itemsParticulieren.find(x => x.label == 'Over ons')?.items?.at(0)?.flatMap(x => x.items).find(x => x?.label == 'Vacatures');
            if (vacatureParticulieren) {
                vacatureParticulieren.styleClass = this.vacancies?.length === 0 ? 'hidden' : '';    
            }
            const zelfstandigenVacature = this.itemsZelfstandigen.find(x => x.label == 'Over ons')?.items?.at(0)?.flatMap(x => x.items).find(x => x?.label == 'Vacatures');
            if (zelfstandigenVacature) {
                zelfstandigenVacature.styleClass = this.vacancies?.length === 0 ? 'hidden' : '';    
            }
            const kmosVacature = this.itemsKMOs.find(x => x.label == 'Over ons')?.items?.at(0)?.flatMap(x => x.items).find(x => x?.label == 'Vacatures');
            if (kmosVacature) {
                kmosVacature.styleClass = this.vacancies?.length === 0 ? 'hidden' : '';    
            }
        });
    }
    
    items: MenuItem[] = [
        {
            label:'Mijn Ebem',
            icon:'pi pi-user',
            styleClass: 'p-button-outlined',
            url: 'https://mijn.ebem.be/',
        },
        {
            icon: 'pi pi-search',
            routerLink: ['/search'],
        }
    ];

    itemsParticulieren: MegaMenuItem[] = [
        {
            label:'Klant worden',
            routerLink: ['/customer-wizard/customer', 'step-1'],
            float: 'start'
        },
        {
            label: 'Verhuizen',
            routerLink: ['/moving'],
            float: 'start'
        },
        {
            label: 'Nieuwbouw of renovatie',
            routerLink: ['/construction-or-renovation'],
            float: 'start'
        },
        {
            label: 'Info',
            float: 'start',
            routerLink: ['/info'],
            items: [
                [{
                    items: [
                        {
                            label: 'Veelgestelde vragen',
                            routerLink: ['/faq'],
                            float: 'start'
                        },
                        {
                            label: 'Tarieven',
                            routerLink: ['/tariffs'],
                            float: 'start'
                        },
                        {
                            label: 'Glossarium',
                            routerLink: ['/glossary'],
                            float: 'start'
                        }
                    ]
                }]
            ]
        },
        {
            label: 'Over ons',
            float: 'start',
            items: [
                [{
                    items: [
                        {
                            label: 'Wie zijn we?',
                            routerLink: ['/about-us'],
                            float: 'start'
                        },
                        {
                            label: 'Projecten',
                            routerLink: ['/projects'],
                            float: 'start'
                        },
                        {
                            label: 'Vrije energiemarkt',
                            routerLink: ['/energy-market'],
                            float: 'start'
                        },
                        {
                            label: 'Consumentenbescherming',
                            routerLink: ['/consumer-protection'],
                            float: 'start'
                        },
                        {
                            label: 'Vacatures',
                            routerLink: ['/vacancies'],
                            float: 'start',
                            styleClass: this.vacancies?.length === 0 ? 'hidden' : ''
                        }
                    ]
                }]
            ]
        },
        {
            label: 'Contact',
            routerLink: ['/contact'],
            float: 'start'
        }
    ];

    itemsZelfstandigen: MegaMenuItem[] = [
        {
            label:'Klant worden',
            routerLink: ['/customer-wizard/self-employed', 'step-1'],
            float: 'start'
        },
        {
            label: 'Verhuizen',
            routerLink: ['/moving'],
            float: 'start'
        },
        {
            label: 'Nieuwbouw of renovatie',
            routerLink: ['/construction-or-renovation'],
            float: 'start'
        },
        {
            label: 'Info',
            float: 'start',
            routerLink: ['/info'],
            items: [
                [{
                    items: [
                        {
                            label: 'Veelgestelde vragen',
                            routerLink: ['/faq'],
                            float: 'start'
                        },
                        {
                            label: 'Tarieven',
                            routerLink: ['/tariffs'],
                            float: 'start'
                        },
                        {
                            label: 'Glossarium',
                            routerLink: ['/glossary'],
                            float: 'start'
                        },
                    ]
                }]
            ]
        },
        {
            label: 'Over ons',
            float: 'start',
            items: [
                [{
                    items: [
                        {
                            label: 'Wie zijn we?',
                            routerLink: ['/about-us'],
                            float: 'start'
                        },
                        {
                            label: 'Projecten',
                            routerLink: ['/projects'],
                            float: 'start'
                        },
                        {
                            label: 'Vrije energiemarkt',
                            routerLink: ['/energy-market'],
                            float: 'start'
                        },
                        {
                            label: 'Consumentenbescherming',
                            routerLink: ['/consumer-protection'],
                            float: 'start'
                        },
                        {
                            label: 'Vacatures',
                            routerLink: ['/vacancies'],
                            float: 'start',
                            styleClass: this.vacancies?.length === 0 ? 'hidden' : ''
                        }
                    ]
                }]
            ]
        },
        {
            label: 'Contact',
            routerLink: ['/contact'],
            float: 'start'
        }
    ];

    itemsKMOs: MegaMenuItem[] = [
        {
            label: 'Contact',
            routerLink: ['/contact'],
            float: 'start'
        },
        {
            label: 'Verhuizen',
            routerLink: ['/moving'],
            float: 'start'
        },
        {
            label: 'Nieuwbouw of renovatie',
            routerLink: ['/construction-or-renovation'],
            float: 'start'
        },
        {
            label: 'Info',
            float: 'start',
            routerLink: ['/info'],
            items: [
                [{
                    items: [
                        {
                            label: 'Veelgestelde vragen',
                            routerLink: ['/faq'],
                            float: 'start'
                        },
                        {
                            label: 'Tarieven',
                            routerLink: ['/tariffs'],
                            float: 'start'
                        },
                        {
                            label: 'Glossarium',
                            routerLink: ['/glossary'],
                            float: 'start'
                        }
                    ]
                }]
            ]
        },
        {
            label: 'Over ons',
            float: 'start',
            items: [
                [{
                    items: [
                        {
                            label: 'Wie zijn we?',
                            routerLink: ['/about-us'],
                            float: 'start'
                        },
                        {
                            label: 'Projecten',
                            routerLink: ['/projects'],
                            float: 'start'
                        },
                        {
                            label: 'Vrije energiemarkt',
                            routerLink: ['/energy-market'],
                            float: 'start'
                        },
                        {
                            label: 'Consumentenbescherming',
                            routerLink: ['/consumer-protection'],
                            float: 'start'
                        },
                        {
                            label: 'Vacatures',
                            routerLink: ['/vacancies'],
                            float: 'start',
                            styleClass: this.vacancies?.length === 0 ? 'hidden' : ''
                        }
                    ]
                }]
            ]
        }
    ];
}