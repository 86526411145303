import { Component, Predicate } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccordionModule } from "primeng/accordion";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RadioButtonModule } from 'primeng/radiobutton'
import { ElectricityType } from "src/app/models/enums/electricity-type.enum";
import { GasType } from "src/app/models/enums/gas-type.enum";
import { Router, RouterModule } from "@angular/router";
import { Contract } from "src/app/models/contract.model";
import { CheckboxModule } from "primeng/checkbox";
import { PaymentDetails } from "src/app/models/payment-details.model";
import { PaymentPreferenceEnum } from "src/app/models/enums/payment-preference.enum";
import { BillingFrequency } from "src/app/models/enums/billing-frequency.enum";
import { Product } from "src/app/models/product.model";
import { ProductSelectionComponent } from "../../../../components/product-selection/product-selection.component";
import { FilterPipe } from "src/app/pipes/filter.pipe";
import { InvoiceModality } from "src/app/models/enums/invoice-modality.enum";
import { SituationEnum } from "src/app/models/enums/situation.enum";
import { CommonModule } from "@angular/common";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TabsModule } from "primeng/tabs";
import { ProgressBarModule } from "primeng/progressbar";
import { ProductService } from "src/app/services/product.service";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";
import { Company } from "src/app/models/company.model";
import { CountryEnum } from "src/app/models/enums/countries.enum";
import { MessageService } from "primeng/api";
import { CustomerService } from "src/app/services/customer.service";
import { ToastModule } from "primeng/toast";

@Component({
    selector: 'customer-wizard-step-5-self-employed',
    standalone: true,
    styleUrls: ['./customer-wizard-step-5.page.scss'],
    templateUrl: './customer-wizard-step-5.page.html',
    imports: [AccordionModule, ButtonModule, CheckboxModule, CommonModule, InputNumberModule, InputGroupModule, InputGroupAddonModule, InputTextModule, OverlayPanelModule, FilterPipe, FormsModule, ProgressBarModule, RadioButtonModule, RouterModule, TabsModule, ToastModule, ProductSelectionComponent],
    providers: [CustomerService, MessageService, ProductService, RouterModule]
})
export class CustomerWizardStep5SelfEmployedPage {
    //Enum values    
    electricityType: number;
    gasType: number;
    
    //Enum definitions
    ElectricityType = ElectricityType;
    GasType = GasType;

    Countries = CountryEnum;
    
    company: Company;
    contract: Contract;
    paymentDetails: PaymentDetails;
    PaymentPreference = PaymentPreferenceEnum;
    BillingFrequency = BillingFrequency;
    InvoiceModality = InvoiceModality;
    ContractSituation = SituationEnum;

    enableSelection: boolean = false;
    confirmed: boolean = false;

    postalCode!: string | number | undefined;

    priceSimulationInput: PriceSimulationInput;
    
    priceTotal: number = 0;
    
    electricityProducts: Product[] = [];
    gasProducts: Product[] = [];

    constructor(private customerService: CustomerService, private messageService: MessageService, private productService: ProductService, public router: Router) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"]; 
        this.company = window.history.state["company"]; 
        this.contract = window.history.state["contract"];
        this.paymentDetails = window.history.state["paymentDetails"];
        this.postalCode = window.history.state["postalCode"];
        this.priceSimulationInput = window.history.state["priceSimulationInput"]
        if (this.priceSimulationInput) {
            this.productService.simulatePriceCalculation(this.priceSimulationInput).subscribe(products => {
                this.electricityProducts = products.filter(x => x.enumType == "ElectricityType");
                this.gasProducts = products.filter(x => x.enumType == "GasType");
            });
        } else {
            this.productService.getAllProducts().subscribe(products => {
                this.electricityProducts = products.filter(x => x.enumType == "ElectricityType");
                this.gasProducts = products.filter(x => x.enumType == "GasType");
            });
        }
    }

    calculateTotalPrice() {
        this.priceTotal = (this.electricityProducts.find(x => x.enumValue == this.electricityType)?.price ?? 0) + (this.gasProducts.find(x => x.enumValue == this.gasType)?.price ?? 0);
    }

    includesType(type: number): Predicate<Product> {
        return (product) => product?.enumValue === type;
    }

    excludesType(type: number): Predicate<Product> {
        return (product) => product?.enumValue !== type;
    }

    castToElectricityType(type: number): ElectricityType {
        return type as unknown as ElectricityType;
    }

    castToGasType(type: number): GasType {
        return type as unknown as GasType;
    }

    isNaN(value: number): boolean {
        return isNaN(value);
    }

    open(file: File) {
        window.open(URL.createObjectURL(file));
    }

    postCompany() {
        this.customerService.postCompany(this.company, this.contract, this.paymentDetails).toPromise().then((res: any) => {
            this.messageService.add({ severity:'success', summary: 'Gelukt', detail: 'Klant succesvol aangemaakt', life: 3000  });
            this.router.navigate(['/customer-wizard/self-employed/step-6'], { state: { 'gasType': this.gasType, 'electricityType': this.electricityType, 'company': this.company, 'contract': this.contract, 'paymentDetails': this.paymentDetails, 'postalCode': this.postalCode, 'priceSimulationInput': this.priceSimulationInput, 'confirmed': this.confirmed } });
        }).catch((err: any) => {
            this.messageService.add({ severity: 'error', summary: 'Fout', detail: 'Klant kon niet aangemaakt worden', life: 3000 });
        });
    }
}