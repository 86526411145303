import { Injectable } from "@angular/core";
import { FAQItem, jsonToFAQItem } from "../models/faq.model";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import _ from "lodash";
import { jsonToNewsItem, NewsItem } from "../models/news-item.model";
import { jsonToUmbracoContent, UmbracoContent } from "../models/umbraco-content.model";
import { InfoTab, jsonToInfoTab } from "../models/info-tab.model";
import { jsonToUmbracoLink, UmbracoLink } from "../models/umbraco-link.model";

@Injectable()
export class UmbracoService {
    constructor(private http: HttpClient) {}

    getFaqItems(): Observable<FAQItem[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=contentType%3AfAQItem&skip=0&take=100&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToFAQItem(x))));
    }
    
    getNewsItems(): Observable<NewsItem[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=contentType%3AnewsItem&sort=createDate%3Adesc&skip=0&take=100&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToNewsItem(x))));
    }

    getInfoTabs(): Observable<Observable<InfoTab>[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?fetch=children%3Ainfo&skip=0&take=100&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToInfoTab(this.http, x))));
    }

    getConsumerProtectionPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=name%3AConsumentenbescherming&filter=contentType%3AcontentPage&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getConstructionOrRenovationPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=name%3ANieuwbouw&filter=contentType%3AcontentPage&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getDynamicTariffsPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=contentType%3AcontentPage&filter=name%3ADynamische&filter=name%3Atarieven&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getEnergyMarketPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=name%3AEnergiemarkt&filter=contentType%3AcontentPage&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getGlossaryPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=name%3Aglossarium&skip=0&take=100&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getMovingPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=name%3AVerhuizen&filter=contentType%3AcontentPage&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getProjectsPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=contentType%3AcontentPage&filter=name%3AProjecten&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getTariffsPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=contentType%3AtariffPage&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getCookiePolicyPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=name%3ACookie&filter=name%3APolicy&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getPrivacyPolicyPage(): Observable<UmbracoContent[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=name%3APrivacy&filter=name%3APolicy&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoContent(x))));
    }

    getElectriticyTariffs(): Observable<UmbracoLink[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/media?fetch=children%3A%2Ftariff%20folder%2Fmonthly%20tariff%20electricity%2F&skip=0&take=100&expand=properties%5B%24all%5D&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoLink(x))));
    }

    getGasTariffs(): Observable<UmbracoLink[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/media?fetch=children%3A%2Ftariff%20folder%2Fmonthly%20tariff%20gas%2F&skip=0&take=100&expand=properties%5B%24all%5D&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToUmbracoLink(x))));
    }
}

