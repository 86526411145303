export class PriceSimulationOptions {
    meterType!: PriceSimulationMeterType;
    tariffType!: PriceSimulationTariffType;
    hasNightTariff!: boolean;
    solarPanels!: PriceSimulationSolarPanels;
    heatingType!: PriceSimulationHeatingType;
    energyConsumers!: PriceSimulationEnergyConsumers[];
    directDebit!: PriceSimulationDirectDebit;
    usageDocuments: File[] = [];
}

export enum PriceSimulationMeterType {
    "", "Digitale meter", "Analoge meter"
}

export enum PriceSimulationTariffType {
    "", "Enkelvoudig", "Tweevoudig"
}

export enum PriceSimulationSolarPanels {
    "", "Zonnepanelen", "Geen zonnepanelen"
}

export enum PriceSimulationHeatingType {
    "", "met Gas", "Niet met Gas", "met Accumulatieverwarming", "met Stookolie", "met Warmtepomp", "met Andere brandstoffen (pallets, hout, ...)"
}

export enum PriceSimulationEnergyConsumers {
    "", "Warmtepomp", "Thuisbatterij", "Laadpaal"
}

export enum PriceSimulationDirectDebit {
    "", "Ja", "Nee"
}