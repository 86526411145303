import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, forwardRef, signal, computed, effect, ViewChild, ContentChildren, ContentChild, ViewEncapsulation, ChangeDetectionStrategy, Component, model, input, booleanAttribute, HostListener, numberAttribute, NgModule } from '@angular/core';
import { getWidth, isRTL, findSingle, getOuterWidth, getOffset, equals, getAttribute, focus, uuid } from '@primeuix/utils';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
import { SharedModule, PrimeTemplate } from 'primeng/api';
import * as i2 from 'primeng/ripple';
import { RippleModule, Ripple } from 'primeng/ripple';
import { ChevronLeftIcon, ChevronRightIcon } from 'primeng/icons';
const theme = ({
  dt
}) => `
.p-tabs {
    display: flex;
    flex-direction: column;
}

.p-tablist {
    display: flex;
    position: relative;
}

.p-tabs-scrollable > .p-tablist {
    overflow: hidden;
}

.p-tablist-viewport {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    overscroll-behavior: contain auto;
}

.p-tablist-viewport::-webkit-scrollbar {
    display: none;
}

.p-tablist-tab-list {
    position: relative;
    display: flex;
    background: ${dt('tabs.tablist.background')};
    border-style: solid;
    border-color: ${dt('tabs.tablist.border.color')};
    border-width: ${dt('tabs.tablist.border.width')};
}

.p-tablist-content {
    flex-grow: 1;
}

.p-tablist-nav-button {
    all: unset;
    position: absolute !important;
    flex-shrink: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${dt('tabs.nav.button.background')};
    color: ${dt('tabs.nav.button.color')};
    width: ${dt('tabs.nav.button.width')};
    transition: color ${dt('tabs.transition.duration')}, outline-color ${dt('tabs.transition.duration')}, box-shadow ${dt('tabs.transition.duration')};
    box-shadow: ${dt('tabs.nav.button.shadow')};
    outline-color: transparent;
    cursor: pointer;
}

.p-tablist-nav-button:focus-visible {
    z-index: 1;
    box-shadow: ${dt('tabs.nav.button.focus.ring.shadow')};
    outline: ${dt('tabs.nav.button.focus.ring.width')} ${dt('tabs.nav.button.focus.ring.style')} ${dt('tabs.nav.button.focus.ring.color')};
    outline-offset: ${dt('tabs.nav.button.focus.ring.offset')};
}

.p-tablist-nav-button:hover {
    color: ${dt('tabs.nav.button.hover.color')};
}

.p-tablist-prev-button {
    left: 0;
}

.p-tablist-next-button {
    right: 0;
}

.p-tab {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;
    position: relative;
    border-style: solid;
    white-space: nowrap;
    gap: ${dt('tabs.tab.gap')};
    background: ${dt('tabs.tab.background')};
    border-width: ${dt('tabs.tab.border.width')};
    border-color: ${dt('tabs.tab.border.color')};
    color: ${dt('tabs.tab.color')};
    padding: ${dt('tabs.tab.padding')};
    font-weight: ${dt('tabs.tab.font.weight')};
    transition: background ${dt('tabs.transition.duration')}, border-color ${dt('tabs.transition.duration')}, color ${dt('tabs.transition.duration')}, outline-color ${dt('tabs.transition.duration')}, box-shadow ${dt('tabs.transition.duration')};
    margin: ${dt('tabs.tab.margin')};
    outline-color: transparent;
}

.p-tab:not(.p-disabled):focus-visible {
    z-index: 1;
    box-shadow: ${dt('tabs.tab.focus.ring.shadow')};
    outline: ${dt('tabs.tab.focus.ring.width')} ${dt('tabs.tab.focus.ring.style')} ${dt('tabs.tab.focus.ring.color')};
    outline-offset: ${dt('tabs.tab.focus.ring.offset')};
}

.p-tab:not(.p-tab-active):not(.p-disabled):hover {
    background: ${dt('tabs.tab.hover.background')};
    border-color: ${dt('tabs.tab.hover.border.color')};
    color: ${dt('tabs.tab.hover.color')};
}

.p-tab-active {
    background: ${dt('tabs.tab.active.background')};
    border-color: ${dt('tabs.tab.active.border.color')};
    color: ${dt('tabs.tab.active.color')};
}

.p-tabpanels {
    background: ${dt('tabs.tabpanel.background')};
    color: ${dt('tabs.tabpanel.color')};
    padding: ${dt('tabs.tabpanel.padding')};
    outline: 0 none;
}

.p-tabpanel:focus-visible {
    box-shadow: ${dt('tabs.tabpanel.focus.ring.shadow')};
    outline: ${dt('tabs.tabpanel.focus.ring.width')} ${dt('tabs.tabpanel.focus.ring.style')} ${dt('tabs.tabpanel.focus.ring.color')};
    outline-offset: ${dt('tabs.tabpanel.focus.ring.offset')};
}

.p-tablist-active-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: ${dt('tabs.active.bar.bottom')};
    height: ${dt('tabs.active.bar.height')};
    background: ${dt('tabs.active.bar.background')};
    transition: 250ms cubic-bezier(0.35, 0, 0.25, 1);
}
`;
const classes = {
  root: ({
    props
  }) => ['p-tabs p-component', {
    'p-tabs-scrollable': props.scrollable
  }]
};
class TabsStyle extends BaseStyle {
  name = 'tabs';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabsStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabsStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TabsStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * Tabs facilitates seamless switching between different views.
 *
 * [Live Demo](https://www.primeng.org/tabs/)
 *
 * @module tabsstyle
 *
 */
var TabsClasses;
(function (TabsClasses) {
  /**
   * Class name of the root element
   */
  TabsClasses["root"] = "p-tabs";
  /**
   * Class name of the wrapper element
   */
  TabsClasses["list"] = "p-tablist";
  /**
   * Class name of the content element
   */
  TabsClasses["content"] = "p-tablist-content";
  /**
   * Class name of the tab list element
   */
  TabsClasses["tablist"] = "p-tablist-tab-list";
  /**
   * Class name of the tab list element
   */
  TabsClasses["tab"] = "p-tab";
  /**
   * Class name of the inkbar element
   */
  TabsClasses["inkbar"] = "p-tablist-active-bar";
  /**
   * Class name of the navigation buttons
   */
  TabsClasses["button"] = "p-tablist-nav-button";
  /**
   * Class name of the tab panels wrapper
   */
  TabsClasses["tabpanels"] = "p-tabpanels";
  /**
   * Class name of the tab panel element
   */
  TabsClasses["tabpanel"] = "p-tabs-panel";
})(TabsClasses || (TabsClasses = {}));

/**
 * TabList is a helper component for Tabs component.
 * @group Components
 */
class TabList extends BaseComponent {
  /**
   * A template reference variable that represents the previous icon in a UI component.
   * @type {TemplateRef<any> | undefined}
   * @group Templates
   */
  prevIconTemplate;
  /**
   * A template reference variable that represents the next icon in a UI component.
   * @type {TemplateRef<any> | undefined}
   * @group Templates
   */
  nextIconTemplate;
  templates;
  content;
  prevButton;
  nextButton;
  inkbar;
  tabs;
  pcTabs = inject(forwardRef(() => Tabs));
  isPrevButtonEnabled = signal(false);
  isNextButtonEnabled = signal(false);
  resizeObserver;
  showNavigators = computed(() => this.pcTabs.showNavigators());
  tabindex = computed(() => this.pcTabs.tabindex());
  scrollable = computed(() => this.pcTabs.scrollable());
  constructor() {
    super();
    effect(() => {
      this.pcTabs.value();
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          this.updateInkBar();
        });
      }
    });
  }
  get prevButtonAriaLabel() {
    return this.config.translation.aria.previous;
  }
  get nextButtonAriaLabel() {
    return this.config.translation.aria.next;
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.showNavigators() && isPlatformBrowser(this.platformId)) {
      this.updateButtonState();
      this.bindResizeObserver();
    }
  }
  _prevIconTemplate;
  _nextIconTemplate;
  ngAfterContentInit() {
    this.templates.forEach(t => {
      switch (t.getType()) {
        case 'previcon':
          this._prevIconTemplate = t.template;
          break;
        case 'nexticon':
          this._nextIconTemplate = t.template;
          break;
      }
    });
  }
  ngOnDestroy() {
    this.unbindResizeObserver();
    super.ngOnDestroy();
  }
  onScroll(event) {
    this.showNavigators() && this.updateButtonState();
    event.preventDefault();
  }
  onPrevButtonClick() {
    const _content = this.content.nativeElement;
    const width = getWidth(_content);
    const pos = Math.abs(_content.scrollLeft) - width;
    const scrollLeft = pos <= 0 ? 0 : pos;
    _content.scrollLeft = isRTL(_content) ? -1 * scrollLeft : scrollLeft;
  }
  onNextButtonClick() {
    const _content = this.content.nativeElement;
    const width = getWidth(_content) - this.getVisibleButtonWidths();
    const pos = _content.scrollLeft + width;
    const lastPos = _content.scrollWidth - width;
    const scrollLeft = pos >= lastPos ? lastPos : pos;
    _content.scrollLeft = isRTL(_content) ? -1 * scrollLeft : scrollLeft;
  }
  updateButtonState() {
    const _content = this.content?.nativeElement;
    const _list = this.el?.nativeElement;
    const {
      scrollWidth,
      offsetWidth
    } = _content;
    const scrollLeft = Math.abs(_content.scrollLeft);
    const width = getWidth(_content);
    this.isPrevButtonEnabled.set(scrollLeft !== 0);
    this.isNextButtonEnabled.set(_list.offsetWidth >= offsetWidth && scrollLeft !== scrollWidth - width);
  }
  updateInkBar() {
    const _content = this.content.nativeElement;
    const _inkbar = this.inkbar.nativeElement;
    const _tabs = this.tabs.nativeElement;
    const activeTab = findSingle(_content, '[data-pc-name="tab"][data-p-active="true"]');
    _inkbar.style.width = getOuterWidth(activeTab) + 'px';
    _inkbar.style.left = getOffset(activeTab).left - getOffset(_tabs).left + 'px';
  }
  getVisibleButtonWidths() {
    const _prevBtn = this.prevButton?.nativeElement;
    const _nextBtn = this.nextButton?.nativeElement;
    return [_prevBtn, _nextBtn].reduce((acc, el) => el ? acc + getWidth(el) : acc, 0);
  }
  bindResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => this.updateButtonState());
    this.resizeObserver.observe(this.el.nativeElement);
  }
  unbindResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.el.nativeElement);
      this.resizeObserver = null;
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabList,
    deps: [],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "17.0.0",
    version: "19.1.7",
    type: TabList,
    isStandalone: true,
    selector: "p-tablist",
    host: {
      properties: {
        "class.p-tablist": "true",
        "class.p-component": "true",
        "attr.data-pc-name": "\"tablist\""
      }
    },
    queries: [{
      propertyName: "prevIconTemplate",
      first: true,
      predicate: ["previcon"]
    }, {
      propertyName: "nextIconTemplate",
      first: true,
      predicate: ["nexticon"]
    }, {
      propertyName: "templates",
      predicate: PrimeTemplate
    }],
    viewQueries: [{
      propertyName: "content",
      first: true,
      predicate: ["content"],
      descendants: true
    }, {
      propertyName: "prevButton",
      first: true,
      predicate: ["prevButton"],
      descendants: true
    }, {
      propertyName: "nextButton",
      first: true,
      predicate: ["nextButton"],
      descendants: true
    }, {
      propertyName: "inkbar",
      first: true,
      predicate: ["inkbar"],
      descendants: true
    }, {
      propertyName: "tabs",
      first: true,
      predicate: ["tabs"],
      descendants: true
    }],
    usesInheritance: true,
    ngImport: i0,
    template: `
        @if (showNavigators() && isPrevButtonEnabled()) {
            <button type="button" #prevButton pRipple class="p-tablist-nav-button p-tablist-prev-button" [attr.aria-label]="prevButtonAriaLabel" [attr.tabindex]="tabindex()" [attr.data-pc-group-section]="'navigator'" (click)="onPrevButtonClick()">
                @if (prevIconTemplate || _prevIconTemplate) {
                    <ng-container *ngTemplateOutlet="prevIconTemplate || _prevIconTemplate" />
                } @else {
                    <ChevronLeftIcon />
                }
            </button>
        }
        <div #content class="p-tablist-content" [ngClass]="{ 'p-tablist-viewport': scrollable() }" (scroll)="onScroll($event)">
            <div #tabs class="p-tablist-tab-list" role="tablist">
                <ng-content />
                <span #inkbar role="presentation" class="p-tablist-active-bar" [attr.data-pc-section]="'inkbar'"></span>
            </div>
        </div>
        @if (showNavigators() && isNextButtonEnabled()) {
            <button type="button" #nextButton pRipple class="p-tablist-nav-button p-tablist-next-button" [attr.aria-label]="nextButtonAriaLabel" [attr.tabindex]="tabindex()" [attr.data-pc-group-section]="'navigator'" (click)="onNextButtonClick()">
                @if (nextIconTemplate || _nextIconTemplate) {
                    <ng-container *ngTemplateOutlet="nextIconTemplate || _nextIconTemplate" />
                } @else {
                    <ChevronRightIcon />
                }
            </button>
        }
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "component",
      type: ChevronLeftIcon,
      selector: "ChevronLeftIcon"
    }, {
      kind: "component",
      type: ChevronRightIcon,
      selector: "ChevronRightIcon"
    }, {
      kind: "ngmodule",
      type: RippleModule
    }, {
      kind: "directive",
      type: i2.Ripple,
      selector: "[pRipple]"
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TabList,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tablist',
      standalone: true,
      imports: [CommonModule, ChevronLeftIcon, ChevronRightIcon, RippleModule, SharedModule],
      template: `
        @if (showNavigators() && isPrevButtonEnabled()) {
            <button type="button" #prevButton pRipple class="p-tablist-nav-button p-tablist-prev-button" [attr.aria-label]="prevButtonAriaLabel" [attr.tabindex]="tabindex()" [attr.data-pc-group-section]="'navigator'" (click)="onPrevButtonClick()">
                @if (prevIconTemplate || _prevIconTemplate) {
                    <ng-container *ngTemplateOutlet="prevIconTemplate || _prevIconTemplate" />
                } @else {
                    <ChevronLeftIcon />
                }
            </button>
        }
        <div #content class="p-tablist-content" [ngClass]="{ 'p-tablist-viewport': scrollable() }" (scroll)="onScroll($event)">
            <div #tabs class="p-tablist-tab-list" role="tablist">
                <ng-content />
                <span #inkbar role="presentation" class="p-tablist-active-bar" [attr.data-pc-section]="'inkbar'"></span>
            </div>
        </div>
        @if (showNavigators() && isNextButtonEnabled()) {
            <button type="button" #nextButton pRipple class="p-tablist-nav-button p-tablist-next-button" [attr.aria-label]="nextButtonAriaLabel" [attr.tabindex]="tabindex()" [attr.data-pc-group-section]="'navigator'" (click)="onNextButtonClick()">
                @if (nextIconTemplate || _nextIconTemplate) {
                    <ng-container *ngTemplateOutlet="nextIconTemplate || _nextIconTemplate" />
                } @else {
                    <ChevronRightIcon />
                }
            </button>
        }
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        '[class.p-tablist]': 'true',
        '[class.p-component]': 'true',
        '[attr.data-pc-name]': '"tablist"'
      }
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    prevIconTemplate: [{
      type: ContentChild,
      args: ['previcon', {
        descendants: false
      }]
    }],
    nextIconTemplate: [{
      type: ContentChild,
      args: ['nexticon', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    content: [{
      type: ViewChild,
      args: ['content']
    }],
    prevButton: [{
      type: ViewChild,
      args: ['prevButton']
    }],
    nextButton: [{
      type: ViewChild,
      args: ['nextButton']
    }],
    inkbar: [{
      type: ViewChild,
      args: ['inkbar']
    }],
    tabs: [{
      type: ViewChild,
      args: ['tabs']
    }]
  }
});

/**
 * Defines valid properties in Tab component.
 * @group Components
 */
class Tab extends BaseComponent {
  /**
   * Value of tab.
   * @defaultValue undefined
   * @group Props
   */
  value = model();
  /**
   * Whether the tab is disabled.
   * @defaultValue false
   * @group Props
   */
  disabled = input(false, {
    transform: booleanAttribute
  });
  pcTabs = inject(forwardRef(() => Tabs));
  pcTabList = inject(forwardRef(() => TabList));
  ripple = computed(() => this.config.ripple());
  id = computed(() => `${this.pcTabs.id()}_tab_${this.value()}`);
  ariaControls = computed(() => `${this.pcTabs.id()}_tabpanel_${this.value()}`);
  active = computed(() => equals(this.pcTabs.value(), this.value()));
  tabindex = computed(() => this.active() ? this.pcTabs.tabindex() : -1);
  onFocus(event) {
    this.pcTabs.selectOnFocus() && this.changeActiveValue();
  }
  onClick(event) {
    this.changeActiveValue();
  }
  onKeyDown(event) {
    switch (event.code) {
      case 'ArrowRight':
        this.onArrowRightKey(event);
        break;
      case 'ArrowLeft':
        this.onArrowLeftKey(event);
        break;
      case 'Home':
        this.onHomeKey(event);
        break;
      case 'End':
        this.onEndKey(event);
        break;
      case 'PageDown':
        this.onPageDownKey(event);
        break;
      case 'PageUp':
        this.onPageUpKey(event);
        break;
      case 'Enter':
      case 'NumpadEnter':
      case 'Space':
        this.onEnterKey(event);
        break;
      default:
        break;
    }
    event.stopPropagation();
  }
  onArrowRightKey(event) {
    const nextTab = this.findNextTab(event.currentTarget);
    nextTab ? this.changeFocusedTab(event, nextTab) : this.onHomeKey(event);
    event.preventDefault();
  }
  onArrowLeftKey(event) {
    const prevTab = this.findPrevTab(event.currentTarget);
    prevTab ? this.changeFocusedTab(event, prevTab) : this.onEndKey(event);
    event.preventDefault();
  }
  onHomeKey(event) {
    const firstTab = this.findFirstTab();
    this.changeFocusedTab(event, firstTab);
    event.preventDefault();
  }
  onEndKey(event) {
    const lastTab = this.findLastTab();
    this.changeFocusedTab(event, lastTab);
    event.preventDefault();
  }
  onPageDownKey(event) {
    this.scrollInView(this.findLastTab());
    event.preventDefault();
  }
  onPageUpKey(event) {
    this.scrollInView(this.findFirstTab());
    event.preventDefault();
  }
  onEnterKey(event) {
    this.changeActiveValue();
    event.preventDefault();
  }
  findNextTab(tabElement, selfCheck = false) {
    const element = selfCheck ? tabElement : tabElement.nextElementSibling;
    return element ? getAttribute(element, 'data-p-disabled') || getAttribute(element, 'data-pc-section') === 'inkbar' ? this.findNextTab(element) : element : null;
  }
  findPrevTab(tabElement, selfCheck = false) {
    const element = selfCheck ? tabElement : tabElement.previousElementSibling;
    return element ? getAttribute(element, 'data-p-disabled') || getAttribute(element, 'data-pc-section') === 'inkbar' ? this.findPrevTab(element) : element : null;
  }
  findFirstTab() {
    return this.findNextTab(this.pcTabList?.tabs?.nativeElement?.firstElementChild, true);
  }
  findLastTab() {
    return this.findPrevTab(this.pcTabList?.tabs?.nativeElement?.lastElementChild, true);
  }
  changeActiveValue() {
    this.pcTabs.updateValue(this.value());
  }
  changeFocusedTab(event, element) {
    focus(element);
    this.scrollInView(element);
  }
  scrollInView(element) {
    element?.scrollIntoView?.({
      block: 'nearest'
    });
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Tab,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "17.1.0",
    version: "19.1.7",
    type: Tab,
    isStandalone: true,
    selector: "p-tab",
    inputs: {
      value: {
        classPropertyName: "value",
        publicName: "value",
        isSignal: true,
        isRequired: false,
        transformFunction: null
      },
      disabled: {
        classPropertyName: "disabled",
        publicName: "disabled",
        isSignal: true,
        isRequired: false,
        transformFunction: null
      }
    },
    outputs: {
      value: "valueChange"
    },
    host: {
      listeners: {
        "focus": "onFocus($event)",
        "click": "onClick($event)",
        "keydown": "onKeyDown($event)"
      },
      properties: {
        "class.p-tab": "true",
        "class.p-tab-active": "active()",
        "class.p-disabled": "disabled()",
        "class.p-component": "true",
        "attr.data-pc-name": "\"tab\"",
        "attr.id": "id()",
        "attr.aria-controls": "ariaControls()",
        "attr.role": "\"tab\"",
        "attr.aria-selected": "active()",
        "attr.data-p-disabled": "disabled()",
        "attr.data-p-active": "active()",
        "attr.tabindex": "tabindex()"
      }
    },
    usesInheritance: true,
    hostDirectives: [{
      directive: i2.Ripple
    }],
    ngImport: i0,
    template: ` <ng-content></ng-content>`,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Tab,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tab',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: ` <ng-content></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        '[class.p-tab]': 'true',
        '[class.p-tab-active]': 'active()',
        '[class.p-disabled]': 'disabled()',
        '[class.p-component]': 'true',
        '[attr.data-pc-name]': '"tab"',
        '[attr.id]': 'id()',
        '[attr.aria-controls]': 'ariaControls()',
        '[attr.role]': '"tab"',
        '[attr.aria-selected]': 'active()',
        '[attr.data-p-disabled]': 'disabled()',
        '[attr.data-p-active]': 'active()',
        '[attr.tabindex]': 'tabindex()'
      },
      hostDirectives: [Ripple]
    }]
  }],
  propDecorators: {
    onFocus: [{
      type: HostListener,
      args: ['focus', ['$event']]
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onKeyDown: [{
      type: HostListener,
      args: ['keydown', ['$event']]
    }]
  }
});

/**
 * TabPanel is a helper component for Tabs component.
 * @group Components
 */
class TabPanel extends BaseComponent {
  pcTabs = inject(forwardRef(() => Tabs));
  /**
   * Value of the active tab.
   * @defaultValue undefined
   * @group Props
   */
  value = model(undefined);
  id = computed(() => `${this.pcTabs.id()}_tabpanel_${this.value()}`);
  ariaLabelledby = computed(() => `${this.pcTabs.id()}_tab_${this.value()}`);
  active = computed(() => equals(this.pcTabs.value(), this.value()));
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabPanel,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "17.0.0",
    version: "19.1.7",
    type: TabPanel,
    isStandalone: true,
    selector: "p-tabpanel",
    inputs: {
      value: {
        classPropertyName: "value",
        publicName: "value",
        isSignal: true,
        isRequired: false,
        transformFunction: null
      }
    },
    outputs: {
      value: "valueChange"
    },
    host: {
      properties: {
        "class.p-tabpanel": "true",
        "class.p-component": "true",
        "attr.data-pc-name": "\"tabpanel\"",
        "attr.id": "id()",
        "attr.role": "\"tabpanel\"",
        "attr.aria-labelledby": "ariaLabelledby()",
        "attr.data-p-active": "active()"
      }
    },
    usesInheritance: true,
    ngImport: i0,
    template: `@if (active()) {
        <ng-content></ng-content>
    }`,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TabPanel,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tabpanel',
      standalone: true,
      imports: [CommonModule],
      template: `@if (active()) {
        <ng-content></ng-content>
    }`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        '[class.p-tabpanel]': 'true',
        '[class.p-component]': 'true',
        '[attr.data-pc-name]': '"tabpanel"',
        '[attr.id]': 'id()',
        '[attr.role]': '"tabpanel"',
        '[attr.aria-labelledby]': 'ariaLabelledby()',
        '[attr.data-p-active]': 'active()'
      }
    }]
  }]
});

/**
 * TabPanels is a helper component for Tabs component.
 * @group Components
 */
class TabPanels extends BaseComponent {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabPanels,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: TabPanels,
    isStandalone: true,
    selector: "p-tabpanels",
    host: {
      properties: {
        "class.p-tabpanels": "true",
        "class.p-component": "true",
        "attr.data-pc-name": "\"tabpanels\"",
        "attr.role": "\"presentation\""
      }
    },
    usesInheritance: true,
    ngImport: i0,
    template: ` <ng-content></ng-content>`,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TabPanels,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tabpanels',
      standalone: true,
      imports: [CommonModule],
      template: ` <ng-content></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        '[class.p-tabpanels]': 'true',
        '[class.p-component]': 'true',
        '[attr.data-pc-name]': '"tabpanels"',
        '[attr.role]': '"presentation"'
      }
    }]
  }]
});

/**
 * Tabs facilitates seamless switching between different views.
 * @group Components
 */
class Tabs extends BaseComponent {
  /**
   * Value of the active tab.
   * @defaultValue undefined
   * @group Props
   */
  value = model(undefined);
  /**
   * When specified, enables horizontal and/or vertical scrolling.
   * @type boolean
   * @defaultValue false
   * @group Props
   */
  scrollable = input(false, {
    transform: booleanAttribute
  });
  /**
   * When enabled, hidden tabs are not rendered at all. Defaults to false that hides tabs with css.
   * @type boolean
   * @defaultValue false
   * @group Props
   */
  lazy = input(false, {
    transform: booleanAttribute
  });
  /**
   * When enabled, the focused tab is activated.
   * @type boolean
   * @defaultValue false
   * @group Props
   */
  selectOnFocus = input(false, {
    transform: booleanAttribute
  });
  /**
   * Whether to display navigation buttons in container when scrollable is enabled.
   * @type boolean
   * @defaultValue true
   * @group Props
   */
  showNavigators = input(true, {
    transform: booleanAttribute
  });
  /**
   * Tabindex of the tab buttons.
   * @type number
   * @defaultValue 0
   * @group Props
   */
  tabindex = input(0, {
    transform: numberAttribute
  });
  id = signal(uuid('pn_id_'));
  _componentStyle = inject(TabsStyle);
  updateValue(newValue) {
    this.value.update(() => newValue);
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Tabs,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "17.1.0",
    version: "19.1.7",
    type: Tabs,
    isStandalone: true,
    selector: "p-tabs",
    inputs: {
      value: {
        classPropertyName: "value",
        publicName: "value",
        isSignal: true,
        isRequired: false,
        transformFunction: null
      },
      scrollable: {
        classPropertyName: "scrollable",
        publicName: "scrollable",
        isSignal: true,
        isRequired: false,
        transformFunction: null
      },
      lazy: {
        classPropertyName: "lazy",
        publicName: "lazy",
        isSignal: true,
        isRequired: false,
        transformFunction: null
      },
      selectOnFocus: {
        classPropertyName: "selectOnFocus",
        publicName: "selectOnFocus",
        isSignal: true,
        isRequired: false,
        transformFunction: null
      },
      showNavigators: {
        classPropertyName: "showNavigators",
        publicName: "showNavigators",
        isSignal: true,
        isRequired: false,
        transformFunction: null
      },
      tabindex: {
        classPropertyName: "tabindex",
        publicName: "tabindex",
        isSignal: true,
        isRequired: false,
        transformFunction: null
      }
    },
    outputs: {
      value: "valueChange"
    },
    host: {
      properties: {
        "class.p-tabs": "true",
        "class.p-tabs-scrollable": "scrollable()",
        "class.p-component": "true",
        "attr.data-pc-name": "\"tabs\"",
        "attr.id": "id"
      }
    },
    providers: [TabsStyle],
    usesInheritance: true,
    ngImport: i0,
    template: ` <ng-content></ng-content>`,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Tabs,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tabs',
      standalone: true,
      imports: [CommonModule],
      template: ` <ng-content></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [TabsStyle],
      host: {
        '[class.p-tabs]': 'true',
        '[class.p-tabs-scrollable]': 'scrollable()',
        '[class.p-component]': 'true',
        '[attr.data-pc-name]': '"tabs"',
        '[attr.id]': 'id'
      }
    }]
  }]
});
class TabsModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabsModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabsModule,
    imports: [Tabs, TabPanels, TabPanel, TabList, Tab],
    exports: [Tabs, TabPanels, TabPanel, TabList, Tab]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabsModule,
    imports: [Tabs, TabPanels, TabPanel, TabList, Tab]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TabsModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [Tabs, TabPanels, TabPanel, TabList, Tab],
      exports: [Tabs, TabPanels, TabPanel, TabList, Tab]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { Tab, TabList, TabPanel, TabPanels, Tabs, TabsClasses, TabsModule, TabsStyle };
