import { CommonModule } from "@angular/common";
import { Component, Input, ViewChild, ViewContainerRef, ComponentRef, Compiler, Injector, NgModuleRef, NgModule, ComponentFactoryResolver, createNgModule } from "@angular/core";
import { AccordionModule } from "primeng/accordion";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { MenubarModule } from 'primeng/menubar';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { GalleriaModule } from 'primeng/galleria';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeSelectModule } from 'primeng/treeselect';
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { EditorModule } from "primeng/editor";

@Component({
    selector: 'dynamic-template',
    template: `<div #vc></div>`,
    standalone: false
  })
  export class DynamicTemplateComponent {
    @Input() content!: string;
    @ViewChild('vc', { read: ViewContainerRef }) vc!: ViewContainerRef;

    private cmpRef!: ComponentRef<any>;
  
    constructor(
      private injector: Injector,
      private moduleRef: NgModuleRef<any>,
      private compiler: Compiler
    ) {}
  
    ngAfterViewInit() {
      // Here, get your HTML from backend.
      this.createComponentFromRaw(
        this.content
      );
    }
  
    // Here we create the component.
    private createComponentFromRaw(template: string) {
      // Let's say your template looks like `<h2><some-component [data]="data"></some-component>`
      // As you see, it has an (existing) angular component `some-component` and it injects it [data]
  
      // Now we create a new component. It has that template, and we can even give it data.
      const styles: any[] = [];
  
      class TmpCmpConstructor {}
      const tmpCmp: any = Component({ template, styles })(TmpCmpConstructor);
  
      // Now, also create a dynamic module.
      const tmpModule = NgModule({
        imports: [
          AccordionModule, ButtonModule, CardModule, CommonModule,
          InputTextModule, DropdownModule, TableModule, DialogModule,
          TooltipModule, CalendarModule, CheckboxModule, RadioButtonModule,
          ProgressBarModule, ToastModule, ConfirmDialogModule, FileUploadModule,
          MenubarModule, PanelModule, TabViewModule, ToolbarModule, TreeModule,
          TreeTableModule, GalleriaModule, CarouselModule, ChartModule,
          ProgressSpinnerModule, MultiSelectModule, SliderModule, SplitButtonModule,
          StepsModule, TabMenuModule, TieredMenuModule, ToggleButtonModule,
          TreeSelectModule, DataUdiDirective, EditorModule
        ],
        declarations: [tmpCmp, DataUdiDirective, DynamicTemplateComponent],
        // providers: [] - e.g. if your dynamic component needs any service, provide it here.
      })(class {});
  
      this.compiler.compileModuleAndAllComponentsAsync(tmpModule).then((compiled) => {
        const factory = compiled.componentFactories.find((f) => f.componentType === tmpCmp);
        if (factory) {
          this.cmpRef = this.vc.createComponent(factory, 0, this.injector, [], this.moduleRef);
          this.vc.insert(this.cmpRef.hostView);
        }
      });

    }
  
    // Cleanup properly. You can add more cleanup-related stuff here.
    ngOnDestroy() {
      if (this.cmpRef) {
        this.cmpRef.destroy();
      }
    }
  }