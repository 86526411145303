import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { NacebelCode, jsonToNacebel } from "../models/nacebelcode.model";
import _ from "lodash";

@Injectable()
export class NacebelService {
    constructor(private http: HttpClient) { }

    getNacebelCodes(): Observable<NacebelCode[]> {
        return this.http.get<NacebelCode[]>(`${environment.baseUrl}/NaceCodeService/GetNaceCodes`).pipe(map(res => _.map(res, x => jsonToNacebel(x))));
    }
}