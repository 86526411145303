<h1>Wie is mijn distributienetbeheerder?</h1>
<p>Indien u niet weet wie uw netbeheerder is, vul dan hier uw postcode in en druk op OK.</p>
<div class="row">
    <div class="col-12">
        <p-inputnumber placeholder="Postcode" name="postalCode" [ngClass]="inputPostalCodeValid == null ||  inputPostalCodeValid == undefined ? '' : inputPostalCodeValid === true ? 'ng-valid' : inputPostalCodeValid === false ? 'ng-invalid' : ''" [minFractionDigits]="0" [maxFractionDigits]="0" mode="decimal" [useGrouping]="false" [(ngModel)]="postalCode" (onInput)="validatePostalCodeInput();"></p-inputnumber>
        <p-card class="danger no-padding" *ngIf="showErrorNoPostalCodeFilledIn">Postcode is een verplicht veld</p-card>  
        <p-card class="danger no-padding" *ngIf="showErrorPostalCodeInvalid">Het opgegeven postcode is niet in Vlaanderen gelegen</p-card>  
    </div>
</div>
<div class="row">
<div class="col-12">
    <p-button (click)="validateForm()">OK</p-button>
</div>
</div>
<div class="row">
    <p-card class="xl:col-6 lg:col-6 col-12" *ngIf="electricityDistributionManager">
        <h5>Uw distributienetbeheerder voor Elekticiteit is:</h5>
        <h6>{{electricityDistributionManager.name}}</h6>
        <p>{{electricityDistributionManager.street}}</p>
        <p>{{electricityDistributionManager.postalCode}} {{electricityDistributionManager.city}}</p>
        <p>{{electricityDistributionManager.phone}}</p>
        <p>{{electricityDistributionManager.email}}</p>
        <p>{{electricityDistributionManager.website}}</p>
    </p-card>
    <p-card class="xl:col-6 lg:col-6 col-12" *ngIf="gasDistributionManager">
        <h5>Uw distributienetbeheerder voor Gas is:</h5>
        <h6>{{gasDistributionManager.name}}</h6>
        <p>{{gasDistributionManager.street}}</p>
        <p>{{gasDistributionManager.postalCode}} {{gasDistributionManager.city}}</p>
        <p>{{gasDistributionManager.phone}}</p>
        <p>{{gasDistributionManager.email}}</p>
        <p>{{gasDistributionManager.website}}</p>
    </p-card>
</div>