import { UmbracoHelper } from "../helpers/umbraco.helper";

export class FAQItem {
    id: number;
    question: string;
    answer: string | undefined;
    category: string;
    isPinned: boolean;
    constructor(id: number, question: string, answer: string | undefined, category: string, isPinned: boolean) {
        this.id = id;
        this.question = question;
        this.answer = answer;
        this.category = category;
        this.isPinned = isPinned;
    }
}

export function jsonToFAQItem(json: any): FAQItem {
    var category = json.route.path.split('/')[3];
    //capitalize first letter
    category = category.substring(0, 1).toUpperCase() + category.substring(1);
    //replace dashes with spaces
    category = category.replace(/-/g, ' ')
    return new FAQItem(json?.id, json?.properties?.question, UmbracoHelper.processContent(json.properties?.answer?.markup), category, json.properties?.isPinned);
}
