<div class="row pt-8 mb-4 pb-8 backgroundLightEbemGreen">
    <div class="xl:col-4 lg:col-4 col-12">
        <h2><img class="icon-small" [src]="'icons/call-to-action-icon.png'" />{{title}}</h2>
        <p>{{text}}</p>
        <div class="row">
            <div class="col-4">
                <p-inputnumber placeholder="Postcode" name="postalCode" [ngClass]="inputPostalCodeValid == null ||  inputPostalCodeValid == undefined ? '' : inputPostalCodeValid === true ? 'ng-valid' : inputPostalCodeValid === false ? 'ng-invalid' : ''" [minFractionDigits]="0" [maxFractionDigits]="0" mode="decimal" [useGrouping]="false" [(ngModel)]="postalCode" (onInput)="validatePostalCodeInput();" ></p-inputnumber>
                <p-card class="danger no-padding" *ngIf="showErrorNoPostalCodeFilledIn">Postcode is een verplicht veld</p-card>  
                <p-card class="danger no-padding" *ngIf="showErrorPostalCodeInvalid">Het opgegeven postcode is niet in Vlaanderen gelegen</p-card>  
            </div>
            <div class="col-8">
                <p-button (click)="validateForm() ? this.router.navigate(buttonRoute, {state: { 'postalCode': postalCode } }) : null">{{buttonText}}</p-button>
            </div>
        </div>
        <div [style]="{ position: 'relative', bottom: '-30px' }"><small class="gray" [innerHTML]="subtext"></small></div>
    </div>
    <div class="xl:col-2 lg:col-2 col-12"></div>
    <div class="xl:col-5 lg:col-5 col-12">
        <div class="card-container">
            <img class="card-background" [src]="imageSrc">
        </div>
    </div>
</div>