import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Input, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { find } from '@primeuix/utils';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
import { Subject } from 'rxjs';
const theme = ({
  dt
}) => `
.p-terminal {
    height: ${dt('terminal.height')};
    overflow: auto;
    background: ${dt('terminal.background')};
    color: ${dt('terminal.color')};
    border: 1px solid ${dt('terminal.border.color')};
    padding: ${dt('terminal.padding')};
    border-radius: ${dt('terminal.border.radius')};
}

.p-terminal-prompt {
    display: flex;
    align-items: center;
}

.p-terminal-prompt-value {
    flex: 1 1 auto;
    border: 0 none;
    background: transparent;
    color: inherit;
    padding: 0;
    outline: 0 none;
    font-family: inherit;
    font-feature-settings: inherit;
    font-size: 1rem;
}

.p-terminal-prompt-label {
    margin-inline-end: ${dt('terminal.prompt.gap')};
}

.p-terminal-input::-ms-clear {
    display: none;
}

.p-terminal-command-response {
    margin: ${dt('terminal.command.response.margin')};
}
`;
const classes = {
  root: 'p-terminal p-component',
  welcomeMessage: 'p-terminal-welcome-message',
  commandList: 'p-terminal-command-list',
  command: 'p-terminal-command',
  commandValue: 'p-terminal-command-value',
  commandResponse: 'p-terminal-command-response',
  prompt: 'p-terminal-prompt',
  promptLabel: 'p-terminal-prompt-label',
  promptValue: 'p-terminal-prompt-value'
};
class TerminalStyle extends BaseStyle {
  name = 'terminal';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TerminalStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TerminalStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TerminalStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * Terminal is a text based user interface.
 *
 * [Live Demo](https://www.primeng.org/terminal)
 *
 * @module terminalstyle
 *
 */
var TerminalClasses;
(function (TerminalClasses) {
  /**
   * Class name of the root element
   */
  TerminalClasses["root"] = "p-terminal";
  /**
   * Class name of the welcome message element
   */
  TerminalClasses["welcomeMessage"] = "p-terminal-welcome-message";
  /**
   * Class name of the command list element
   */
  TerminalClasses["commandList"] = "p-terminal-command-list";
  /**
   * Class name of the command element
   */
  TerminalClasses["command"] = "p-terminal-command";
  /**
   * Class name of the command value element
   */
  TerminalClasses["commandValue"] = "p-terminal-command-value";
  /**
   * Class name of the command response element
   */
  TerminalClasses["commandResponse"] = "p-terminal-command-response";
  /**
   * Class name of the prompt element
   */
  TerminalClasses["prompt"] = "p-terminal-prompt";
  /**
   * Class name of the prompt label element
   */
  TerminalClasses["promptLabel"] = "p-terminal-prompt-label";
  /**
   * Class name of the prompt value element
   */
  TerminalClasses["promptValue"] = "p-terminal-prompt-value";
})(TerminalClasses || (TerminalClasses = {}));
class TerminalService {
  commandSource = new Subject();
  responseSource = new Subject();
  commandHandler = this.commandSource.asObservable();
  responseHandler = this.responseSource.asObservable();
  sendCommand(command) {
    if (command) {
      this.commandSource.next(command);
    }
  }
  sendResponse(response) {
    if (response) {
      this.responseSource.next(response);
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TerminalService,
    deps: [],
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TerminalService
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TerminalService,
  decorators: [{
    type: Injectable
  }]
});

/**
 * Terminal is a text based user interface.
 * @group Components
 */
class Terminal extends BaseComponent {
  terminalService;
  /**
   * Initial text to display on terminal.
   * @group Props
   */
  welcomeMessage;
  /**
   * Prompt text for each command.
   * @group Props
   */
  prompt;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  commands = [];
  command;
  container;
  commandProcessed;
  subscription;
  _componentStyle = inject(TerminalStyle);
  constructor(terminalService) {
    super();
    this.terminalService = terminalService;
    this.subscription = terminalService.responseHandler.subscribe(response => {
      this.commands[this.commands.length - 1].response = response;
      this.commandProcessed = true;
    });
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.container = find(this.el.nativeElement, '.p-terminal')[0];
  }
  ngAfterViewChecked() {
    if (this.commandProcessed) {
      this.container.scrollTop = this.container.scrollHeight;
      this.commandProcessed = false;
    }
  }
  set response(value) {
    if (value) {
      this.commands[this.commands.length - 1].response = value;
      this.commandProcessed = true;
    }
  }
  handleCommand(event) {
    if (event.keyCode == 13) {
      this.commands.push({
        text: this.command
      });
      this.terminalService.sendCommand(this.command);
      this.command = '';
    }
  }
  focus(element) {
    element.focus();
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    super.ngOnDestroy();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Terminal,
    deps: [{
      token: TerminalService
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: Terminal,
    isStandalone: true,
    selector: "p-terminal",
    inputs: {
      welcomeMessage: "welcomeMessage",
      prompt: "prompt",
      style: "style",
      styleClass: "styleClass",
      response: "response"
    },
    providers: [TerminalStyle],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <div [ngClass]="'p-terminal p-component'" [ngStyle]="style" [class]="styleClass" (click)="focus(in)">
            <div class="p-terminal-welcome-message" *ngIf="welcomeMessage">{{ welcomeMessage }}</div>
            <div class="p-terminal-command-list">
                <div class="p-terminal-command" *ngFor="let command of commands">
                    <span class="p-terminal-prompt-label">{{ prompt }}</span>
                    <span class="p-terminal-command-value">{{ command.text }}</span>
                    <div class="p-terminal-command-response" [attr.aria-live]="'polite'">{{ command.response }}</div>
                </div>
            </div>
            <div class="p-terminal-prompt">
                <span class="p-terminal-prompt-label">{{ prompt }}</span>
                <input #in type="text" [(ngModel)]="command" class="p-terminal-prompt-value" autocomplete="off" (keydown)="handleCommand($event)" autofocus />
            </div>
        </div>
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i2.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i2.NgForOf,
      selector: "[ngFor][ngForOf]",
      inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
    }, {
      kind: "directive",
      type: i2.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i2.NgStyle,
      selector: "[ngStyle]",
      inputs: ["ngStyle"]
    }, {
      kind: "ngmodule",
      type: FormsModule
    }, {
      kind: "directive",
      type: i3.DefaultValueAccessor,
      selector: "input:not([type=checkbox])[formControlName],textarea[formControlName],input:not([type=checkbox])[formControl],textarea[formControl],input:not([type=checkbox])[ngModel],textarea[ngModel],[ngDefaultControl]"
    }, {
      kind: "directive",
      type: i3.NgControlStatus,
      selector: "[formControlName],[ngModel],[formControl]"
    }, {
      kind: "directive",
      type: i3.NgModel,
      selector: "[ngModel]:not([formControlName]):not([formControl])",
      inputs: ["name", "disabled", "ngModel", "ngModelOptions"],
      outputs: ["ngModelChange"],
      exportAs: ["ngModel"]
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Terminal,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-terminal',
      standalone: true,
      imports: [CommonModule, FormsModule, SharedModule],
      template: `
        <div [ngClass]="'p-terminal p-component'" [ngStyle]="style" [class]="styleClass" (click)="focus(in)">
            <div class="p-terminal-welcome-message" *ngIf="welcomeMessage">{{ welcomeMessage }}</div>
            <div class="p-terminal-command-list">
                <div class="p-terminal-command" *ngFor="let command of commands">
                    <span class="p-terminal-prompt-label">{{ prompt }}</span>
                    <span class="p-terminal-command-value">{{ command.text }}</span>
                    <div class="p-terminal-command-response" [attr.aria-live]="'polite'">{{ command.response }}</div>
                </div>
            </div>
            <div class="p-terminal-prompt">
                <span class="p-terminal-prompt-label">{{ prompt }}</span>
                <input #in type="text" [(ngModel)]="command" class="p-terminal-prompt-value" autocomplete="off" (keydown)="handleCommand($event)" autofocus />
            </div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [TerminalStyle]
    }]
  }],
  ctorParameters: () => [{
    type: TerminalService
  }],
  propDecorators: {
    welcomeMessage: [{
      type: Input
    }],
    prompt: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    response: [{
      type: Input
    }]
  }
});
class TerminalModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TerminalModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TerminalModule,
    imports: [Terminal, SharedModule],
    exports: [Terminal, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TerminalModule,
    imports: [Terminal, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TerminalModule,
  decorators: [{
    type: NgModule,
    args: [{
      exports: [Terminal, SharedModule],
      imports: [Terminal, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { Terminal, TerminalClasses, TerminalModule, TerminalService, TerminalStyle };
