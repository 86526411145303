import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AvatarModule } from "primeng/avatar"
import { ButtonModule } from "primeng/button"
import { CardModule } from "primeng/card"
import { InputNumberModule } from "primeng/inputnumber"
import { ContactComponent } from "../../components/contact/contact.component";

@Component({
    selector: 'contact-page',
    standalone: true,
    templateUrl: './contact.page.html',
    styleUrls: ['contact.page.scss'],
    imports: [AvatarModule, ButtonModule, CardModule, InputNumberModule, FormsModule, RouterModule, ContactComponent]
})
export class ContactPage {
}