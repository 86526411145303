import { Injectable } from "@angular/core";
import { jsonToVacancy, Vacancy } from "../models/vacancy.model";
import { map, Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import _ from "lodash";

@Injectable()
export class VacancyService {
    constructor(private http: HttpClient) {}

    getAllVacancies(): Observable<Vacancy[]> {
        return this.http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?filter=contentType%3AvacancyItem&skip=0&take=10&fields=properties%5B%24all%5D`).pipe(map(res => _.map(res.items, x => jsonToVacancy(x))));
    }
}