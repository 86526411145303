import { Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";

@Component({
    selector: 'mission-statement-component',
    standalone: true,
    templateUrl: 'mission-statement.component.html',
    styleUrls: ['./mission-statement.component.scss'],
    imports: [AvatarModule, ButtonModule, RouterModule]
})
export class MissionStatementComponent {
    customerType: string = "customer";
    constructor() {
        this.customerType = window.history.state.customerType;
    }
}