import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, Router, RouterStateSnapshot } from "@angular/router";
import { PriceSimulationChoice } from "src/app/models/enums/price-simulation-choice.enum";
import { PriceSimulationOptions } from "src/app/models/enums/price-simulation-options.enum";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";

@Injectable()
export class PriceCalculationStep2cGuard implements CanActivate {
    constructor(public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardResult {
        const extras = this.router.getCurrentNavigation()?.extras;
        const priceSimulationInput: PriceSimulationInput = extras?.state?.["priceSimulationInput"];
        const priceSimulationChoices: PriceSimulationChoice[] = extras?.state?.["priceSimulationChoices"];
        const priceSimulationOptions: PriceSimulationOptions = extras?.state?.["priceSimulationOptions"];
        return (!!priceSimulationInput.postalCode) && (!!priceSimulationChoices && priceSimulationChoices.length > 0 ? true : false) && (!!priceSimulationOptions && priceSimulationOptions.meterType != 0  && priceSimulationOptions.tariffType && priceSimulationOptions.solarPanels != 0 ? true : false);
    }
}