h6 {
    color: #909090 !important;
}

a, p, label{
    color: white !important;
    text-decoration: none !important;
}

a:hover {
    color: #A2C138 !important;
    text-decoration: none !important;   
}

.darkGreenHover {
    color: #20260B !important;
    text-decoration: none !important;   
}

.darkGreenHover:hover {
    color: #20260B !important;
    text-decoration: none !important;   
}

.darkGreenHover:active {
    color: #20260B !important;
    text-decoration: none !important;   
}
    
.darkGreenHover:visited {
    color: #20260B !important;
    text-decoration: none !important;   
}

a:active {
    color: #A2C138 !important;
    text-decoration: none !important;   
}

a:visited {
    color: white !important;
    text-decoration: none !important;   
}

.p-card-body {
    left: -5px;
    width: calc(100% + 12.5px);
}

.backgroundLightEbemGreen {
    background-color: #E8EFCD !important;
    display: block;
    padding: 0px 5%;
}

.backgroundDarkEbemGreen {
    background-color: #20260B !important;
    display: block;
    padding: 0px 5%;
}

.backgroundEbemGreen {
    background-color: #A2C138 !important;
    display: block;
    padding: 0px 5%;
}