import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { Vacancy } from "src/app/models/vacancy.model";
import { VacancyService } from "src/app/services/vacancy.service";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";
import { UmbracoHelper } from "src/app/helpers/umbraco.helper";
import { Subscription } from "rxjs";

@Component({
    selector: 'vacancies-page',
    templateUrl: './vacancies.page.html',
    styleUrls: ['./vacancies.page.scss'],
    standalone: true,
    imports: [ButtonModule, CardModule, CommonModule, DataUdiDirective, DynamicHTMLModule, FormsModule, RouterModule],
    providers: [VacancyService]
})
export class VacanciesPage {
    vacancies: Vacancy[] = []
    subscribers: Subscription[] = [];
    constructor(private vacancyService: VacancyService) {
        this.subscribers.push(this.vacancyService.getAllVacancies().subscribe(vacancies => this.vacancies = vacancies.map(vacancy => <Vacancy> { title: vacancy.title, innerHtml: UmbracoHelper.processContent(vacancy.innerHtml) })));
    }
}