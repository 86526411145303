import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { InputNumberModule } from "primeng/inputnumber";
import { Nullable } from "primeng/ts-helpers";
import { DistributionManager } from "src/app/models/distributionmanager.model";
import { jsonToPostalCode, PostalCode } from "src/app/models/postalcode.model";
import { DistributionManagerService } from "src/app/services/distibutionmanager.service";
import { PostalcodeService } from "src/app/services/postalcode.service";

@Component({
    selector: 'distribution-manager',
    templateUrl: './distribution-manager.page.html',
    styleUrls: ['./distribution-manager.page.scss'],
    standalone: true,
    imports: [ButtonModule, CardModule, CommonModule, InputNumberModule, FormsModule],
    providers: [DistributionManagerService, PostalcodeService]
})
export class DistributionManagerPage {
    inputPostalCodeValid: Nullable<boolean>;
    showErrorNoPostalCodeFilledIn: boolean = false;
    showErrorPostalCodeInvalid: boolean = false;
    postalCode!: string | number | undefined;

    PostalCodes: PostalCode[] = [];

    electricityDistributionManager!: DistributionManager | undefined;
    gasDistributionManager!: DistributionManager | undefined;

    constructor(private distributionManagerService: DistributionManagerService, private postalCodeService: PostalcodeService, private route: ActivatedRoute) {
        postalCodeService.getPostalCodes().subscribe((res: PostalCode[]) => {
            this.PostalCodes = res.filter(x => x.isValidArea);
        });
        this.route.queryParams.subscribe(params => {
            this.postalCode = params['postalCode'];
        
            if (this.postalCode) {
                this.distributionManagerService.getDistributionManager(this.postalCode).subscribe((distributionManagers: DistributionManager[]) => {
                    this.electricityDistributionManager = distributionManagers.find(distributionManager => distributionManager.utilityType === "Elektriciteit");
                    this.gasDistributionManager = distributionManagers.find(distributionManager => distributionManager.utilityType === "Gas");
                });
            }
        });
    }

    validatePostalCodeInput() {
        this.inputPostalCodeValid = !!this.postalCode && !!this.PostalCodes.map(x => x.code).includes(this.postalCode.toString());
        this.showErrorNoPostalCodeFilledIn = !this.postalCode;
        this.showErrorPostalCodeInvalid = !!this.postalCode && !this.PostalCodes.map(x => x.code).includes(this.postalCode.toString());
    }

    validateInputs() {
        this.validatePostalCodeInput();
    }

    validateForm() {
        this.validateInputs();

        if (this.inputPostalCodeValid) {
            this.distributionManagerService.getDistributionManager(this.postalCode).subscribe((distributionManagers: DistributionManager[]) => { 
                this.electricityDistributionManager = distributionManagers.find(distributionManager => distributionManager.utilityType === "Elektriciteit");
                this.gasDistributionManager = distributionManagers.find(distributionManager => distributionManager.utilityType === "Gas");
            });
        }

        return this.inputPostalCodeValid;
    }
}