import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { TagModule } from "primeng/tag";
import { NewsItem } from "src/app/models/news-item.model";
import { ProductCardComponent } from "../product-card/product-card.component";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";
import { Product } from "src/app/models/product.model";

@Component({
    selector: 'news-article',
    templateUrl: './news-article.component.html',
    styleUrls: ['./news-article.component.scss'],
    standalone: true,
    imports: [ButtonModule, CommonModule, DataUdiDirective, DynamicHTMLModule, ProductCardComponent, TagModule],
    providers: []
})
export class NewsArticleComponent {
    @Input() newsItem!: NewsItem | undefined;
    @Input() promotedProduct!: Product | undefined;
    window = window;
}