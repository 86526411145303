export class UmbracoLink {
    id!: string;
    name!: string;
    url!: string;
}

export function jsonToUmbracoLink(json: any): UmbracoLink {
    return {
        id: json.id,
        name: json.name,
        url: json.url,
    };
}