export class UmbracoContent {
    id!: string;
    name!: string;
    contentType!: string;
    properties!: { [key: string]: any };
}

export function jsonToUmbracoContent(json: any): UmbracoContent {
    return {
        id: json.id,
        name: json.name,
        contentType: json.contentType,
        properties: json.properties
    };
}

export class UmbracoMediaContent {
    path!: string;
    createDate!: Date;
    updateDate!: Date;
    id!: string;
    name!: string;
    mediaType!: string;
    url!: string;
    extension!: string;
    width!: number;
    height!: number;
    bytes!: number;
    properties!: { [key: string]: any };
    focalPoint!: any;
    crops!: any[];

    constructor(json: any) {
        this.path = json.path;
        this.createDate = new Date(json.createDate);
        this.updateDate = new Date(json.updateDate);
        this.id = json.id;
        this.name = json.name;
        this.mediaType = json.mediaType;
        this.url = json.url;
        this.extension = json.extension;
        this.width = json.width;
        this.height = json.height;
        this.bytes = json.bytes;
        this.properties = json.properties;
        this.focalPoint = json.focalPoint;
        this.crops = json.crops;
    }
}