import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";
import { UmbracoService } from "src/app/services/umbraco.service";
import { UmbracoHelper } from "src/app/helpers/umbraco.helper";
import { Subscription } from "rxjs";

@Component({
    selector: 'glossarium-page',
    templateUrl: './glossarium.page.html',
    styleUrls: ['./glossarium.page.scss'],
    standalone: true,
    imports: [CommonModule, DataUdiDirective, DynamicHTMLModule],
    providers: [UmbracoService]
})
export class GlossariumPage {
    htmlContent!: string;
    subscribers: Subscription[] = [];

    constructor(private umbracoService: UmbracoService) {
        this.subscribers.push(this.umbracoService.getGlossaryPage().subscribe(content => this.htmlContent = UmbracoHelper.processContent(content[0].properties?.["bodyText"]?.sections[0]?.rows[0]?.areas[0]?.controls[0]?.value)));
    }
}