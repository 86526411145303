import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Contact } from "../models/contact.model";
import { Observable } from "rxjs";

@Injectable() 
export class ContactService {
    constructor(private http: HttpClient) {}

    sendContactForm(contactForm: Contact, captchaResponse: string): Observable<any> {
        const headers = { 'cf-turnstile-response': captchaResponse };
        return this.http.post(environment.baseUrl + "/Subscription/SaveContactForm", <Contact> {
            bedrijf: contactForm.bedrijf,
            btwNummer: contactForm.btwNummer,
            voornaam: contactForm.voornaam,
            naam: contactForm.naam,
            email: contactForm.email,
            telefoon: contactForm.telefoon,
            postCode: contactForm.postCode?.toString() ?? "",
            plaats: contactForm.plaats,
            bericht: contactForm.bericht
        }, { headers: headers });
    }
}
