import { Component, input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox"
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RadioButtonModule } from 'primeng/radiobutton'
import { ElectricityType } from "src/app/models/enums/electricity-type.enum";
import { GasType } from "src/app/models/enums/gas-type.enum";
import { Router, RouterModule } from "@angular/router";
import { Customer } from "src/app/models/customer.model";
import { Contract } from "src/app/models/contract.model";
import { PaymentDetails } from "src/app/models/payment-details.model";
import { SelectModule } from "primeng/select";
import { EnumKeyValue } from "src/app/pipes/enumKeyValue.pipe";
import { CommonModule } from "@angular/common";
import { ProgressBarModule } from "primeng/progressbar";
import { CardModule } from "primeng/card";
import { Nullable } from "primeng/ts-helpers";
import { StringHelper } from "src/app/helpers/string.helper";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";
import { Company } from "src/app/models/company.model";
import { CountryEnum } from "src/app/models/enums/countries.enum";
import { ReplacePipe } from "src/app/pipes/replace.pipe";
import { NacebelCode } from "src/app/models/nacebelcode.model";
import { PostalCode } from "src/app/models/postalcode.model";
import { PostalcodeService } from "src/app/services/postalcode.service";
import { NacebelService } from "src/app/services/nacebel.service";
import { CustomerService } from "src/app/services/customer.service";
import { ContactPerson } from "src/app/models/contact-person.model";

@Component({
    selector: 'customer-wizard-step-2-self-employed',
    standalone: true,
    styleUrls: ['./customer-wizard-step-2.page.scss'],
    templateUrl: './customer-wizard-step-2.page.html',
    imports: [ButtonModule, CardModule, CheckboxModule, CommonModule, EnumKeyValue, InputGroupModule, InputNumberModule, InputTextModule, InputGroupAddonModule, FormsModule, ProgressBarModule, RadioButtonModule, ReplacePipe, RouterModule, SelectModule],
    providers: [CustomerService, NacebelService, PostalcodeService]
})
export class CustomerWizardStep2SelfEmployedPage {
    //Enum values    
    electricityType: number;
    gasType: number;
    //Enum definitions
    ElectricityType = ElectricityType;
    GasType = GasType;
    AllPostalCodes: PostalCode[] = [];
    PostalCodes:PostalCode[] = [];
    CompanyType: string[] = [];
    Countries = CountryEnum;
    NacebelCodes: NacebelCode[] = [];
    StringHelper = StringHelper;
    
    company: Company;
    contract: Contract;
    paymentDetails: PaymentDetails;

    priceSimulationInput: PriceSimulationInput;

    postalCode!: string | number | undefined;

    inputGenderValid: Nullable<boolean>;
    inputContactPersonFirstNameValid: Nullable<boolean>;
    inputContactPersonLastNameValid: Nullable<boolean>;
    inputContactPersonFunctionValid: Nullable<boolean>;
    inputContactPersonTelephoneValid: Nullable<boolean>;
    inputEmailValid: Nullable<boolean>;
    inputTelephoneValid: Nullable<boolean>; 
    inputStreetNameValid: Nullable<boolean>;
    inputHouseNumberValid: Nullable<boolean>;
    inputPostalCodeValid: Nullable<boolean>;
    inputLocationValid: Nullable<boolean>;
    inputInvoicingAddressStreetNameValid: Nullable<boolean>;
    inputInvoicingAddressHouseNumberValid: Nullable<boolean>;
    inputInvoicingAddressPostalCodeValid: Nullable<boolean>;
    inputInvoicingAddressLocationValid: Nullable<boolean>;
    inputCompanyNameValid: Nullable<boolean>;
    inputCompanyNumberValid: Nullable<boolean>;
    inputNacebelValid: Nullable<boolean>;
    inputCompanyTypeValid: Nullable<boolean>;
    inputInvoicingAddressCountryValid: Nullable<boolean>;
    inputCountryValid: Nullable<boolean>;

    showErrorNoGenderChosen: boolean = false;
    showErrorNoContactPersonFirstNameFilledIn: boolean = false;
    showErrorNoContactPersonLastNameFilledIn: boolean = false;
    showErrorNoContactPersonFunctionFilledIn: boolean = false;
    showErrorNoContactPersonTelephoneFilledIn: boolean = false;
    showErrorContactPersonTelephoneWrongFormat: boolean = false;
    showErrorNoTelephoneFilledIn: boolean = false;
    showErrorTelephoneWrongFormat: boolean = false;
    showErrorNoEmailFilledIn: boolean = false;
    showErrorEmailWrongFormat: boolean = false;
    showErrorNoStreetNameFilledIn: boolean = false;
    showErrorNoHouseNumberFilledIn: boolean = false;
    showErrorNoPostalCodeFilledIn: boolean = false;
    showErrorPostalCodeInvalid: boolean = false;
    showErrorNoLocationFilledIn: boolean = false;
    showErrorNoInvoicingAddressStreetNameFilledIn: boolean = false;
    showErrorNoInvoicingAddressHouseNumberFilledIn: boolean = false;
    showErrorNoInvoicingAddressPostalCodeFilledIn: boolean = false;
    showErrorNoInvoicingAddressLocationFilledIn: boolean = false;
    showErrorNoCompanyNameFilledIn: boolean = false;
    showErrorNoCompanyNumberFilledIn: boolean = false;
    showErrorCompanyNumberWrongFormat: boolean = false;
    showErrorNoCompanyTypeFilledIn: boolean = false;
    showErrorNoCountryFilledIn: boolean = false;
    showErrorNoNacebelFilledIn: boolean = false;
    showErrorNacebelWrongFormat: boolean = false;
    showErrorNoInvoicingAddressCountryFilledIn: boolean = false;

    constructor(private customerService: CustomerService, private nacebelService: NacebelService, private postalCodeService: PostalcodeService, public router: Router) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"]; 
        this.company = window.history.state["company"] ?? <Company>{ invoiceAddressIsSameToPostalAddress: true, invoicingAddressCountry: 'BE', telephone: '+32', contactPerson: <ContactPerson>{ telephone: '+32' } };
        this.contract = window.history.state["contract"];
        this.paymentDetails = window.history.state["paymentDetails"];
        this.postalCode = window.history.state["postalCode"];
        this.priceSimulationInput = window.history.state["priceSimulationInput"];
        this.postalCodeService.getPostalCodes().subscribe(res => { this.PostalCodes = res.filter(x => x.isValidArea); this.AllPostalCodes = res; });
        this.nacebelService.getNacebelCodes().subscribe(res => this.NacebelCodes = res);
        this.customerService.getCompanyTypes().subscribe(res => this.CompanyType = res);
    }

    validateInputs() {
        this.validateCompanyTypeInput();

        this.validateCompanyNumberInput();

        this.validateCompanyNameInput();

        this.validateCountryInput();

        this.validateNacebelInput();

        this.validateContactPersonFirstNameInput();
        
        this.validateContactPersonLastNameInput();

        this.validateContactPersonFunctionInput();

        this.validateContactPersonTelephoneNumberInput();

        this.validateEmailInput();

        this.validateTelephoneNumberInput();

        this.validateStreetNameInput();

        this.validateHouseNumberInput();

        this.validatePostalCodeInput();

        this.validateLocationInput();

        this.validateInvoicingAddressStreetNameInput();

        this.validateInvoicingAddressHouseNumberInput();

        this.validateInvoicingAddressPostalCodeInput();

        this.validateInvoicingAddressLocationInput();

        this.validateInvoicingAddressCountryInput();
    }

    validateInvoicingAddressCountryInput() {
        this.inputInvoicingAddressCountryValid = !!this.company.invoiceAddressIsSameToPostalAddress || (!this.company.invoiceAddressIsSameToPostalAddress && !!this.company.invoicingAddressCountry);
        this.showErrorNoInvoicingAddressCountryFilledIn = !this.company.invoiceAddressIsSameToPostalAddress && !this.company.invoicingAddressCountry;
    }
 
    validateInvoicingAddressLocationInput() {
        this.inputInvoicingAddressLocationValid = !!this.company.invoiceAddressIsSameToPostalAddress || (!this.company.invoiceAddressIsSameToPostalAddress && !!this.company.invoicingAddressLocation);
        this.showErrorNoInvoicingAddressLocationFilledIn = !this.company.invoiceAddressIsSameToPostalAddress && !this.company.invoicingAddressLocation;
    }

    validateInvoicingAddressPostalCodeInput() {
        this.inputInvoicingAddressPostalCodeValid = !!this.company.invoiceAddressIsSameToPostalAddress || (!this.company.invoiceAddressIsSameToPostalAddress && !!this.company.invoicingAddressPostalCode);
        this.showErrorNoInvoicingAddressPostalCodeFilledIn = !this.company.invoiceAddressIsSameToPostalAddress && !this.company.invoicingAddressPostalCode;
    }

    validateInvoicingAddressHouseNumberInput() {
        this.inputInvoicingAddressHouseNumberValid = !!this.company.invoiceAddressIsSameToPostalAddress || (!this.company.invoiceAddressIsSameToPostalAddress && !!this.company.invoicingAddressHouseNumber);
        this.showErrorNoInvoicingAddressHouseNumberFilledIn = !this.company.invoiceAddressIsSameToPostalAddress && !this.company.invoicingAddressHouseNumber;
    }

    validateInvoicingAddressStreetNameInput() {
        this.inputInvoicingAddressStreetNameValid = !!this.company.invoiceAddressIsSameToPostalAddress || (!this.company.invoiceAddressIsSameToPostalAddress && !!this.company.invoicingAddressStreetName);
        this.showErrorNoInvoicingAddressStreetNameFilledIn = !this.company.invoiceAddressIsSameToPostalAddress && !this.company.invoicingAddressStreetName;
    }

    validateLocationInput() {
        this.inputLocationValid = !!this.company.location;
        this.showErrorNoLocationFilledIn = !this.company.location;
    }

    validatePostalCodeInput() {
        this.inputPostalCodeValid = !!this.company.postalCode && !!this.PostalCodes.map(x => x.code).includes(this.company.postalCode.toString());
        this.showErrorNoPostalCodeFilledIn = !this.company.postalCode;
        this.showErrorPostalCodeInvalid = !!this.company.postalCode && !this.PostalCodes.map(x => x.code).includes(this.company.postalCode.toString());
    }

    validateHouseNumberInput() {
        this.inputHouseNumberValid = !!this.company.houseNumber;
        this.showErrorNoHouseNumberFilledIn = !this.company.houseNumber;
    }

    validateStreetNameInput() {
        this.inputStreetNameValid = !!this.company.streetName;
        this.showErrorNoStreetNameFilledIn = !this.company.streetName;
    }

    validateTelephoneNumberInput() {
        this.inputTelephoneValid = !!this.company.telephone && (!!this.company.telephone.match(/^[+][3][1-2][0-9]{8}$/) || !!this.company.telephone.match(/^[+][3][1-2][4][0-9]{8}$/));
        this.showErrorNoTelephoneFilledIn = !this.company.telephone;
        this.showErrorTelephoneWrongFormat = !!this.company.telephone && !this.company.telephone.match(/^[+][3][1-2][0-9]{8}$/) && !this.company.telephone.match(/^[+][3][1-2][4][0-9]{8}$/);
    }

    validateEmailInput() {
        this.inputEmailValid = !!this.company.email && !!this.company.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/);
        this.showErrorNoEmailFilledIn = !this.company.email;
        this.showErrorEmailWrongFormat = !!this.company.email && !this.company.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/);
    }

    validateContactPersonLastNameInput() {
        this.inputContactPersonLastNameValid = !!this.company.contactPerson.lastName;
        this.showErrorNoContactPersonLastNameFilledIn = !this.company.contactPerson.lastName;
    }

    validateContactPersonFirstNameInput() {
        this.inputContactPersonFirstNameValid = !!this.company.contactPerson.firstName;
        this.showErrorNoContactPersonFirstNameFilledIn = !this.company.contactPerson.firstName;
    }

    validateContactPersonFunctionInput() {
        this.inputContactPersonFunctionValid = !!this.company.contactPerson.function;
        this.showErrorNoContactPersonFunctionFilledIn = !this.company.contactPerson.function;
    }

    validateContactPersonTelephoneNumberInput() {
        this.inputContactPersonTelephoneValid = !!this.company.contactPerson.telephone && (!!this.company.contactPerson.telephone.match(/^[+][3][1-2][0-9]{8}$/) || !!this.company.contactPerson.telephone.match(/^[+][3][1-2][4][0-9]{8}$/));
        this.showErrorNoContactPersonTelephoneFilledIn = !this.company.contactPerson.telephone;
        this.showErrorContactPersonTelephoneWrongFormat = !!this.company.contactPerson.telephone && !this.company.contactPerson.telephone.match(/^[+][3][1-2][0-9]{8}$/) && !this.company.contactPerson.telephone.match(/^[+][3][1-2][4][0-9]{8}$/);
    }

    validateCountryInput() {
        this.inputCountryValid = !!this.company.country;
        this.showErrorNoCountryFilledIn = !this.company.country;
    }

    validateCompanyTypeInput() {
        this.inputCompanyTypeValid = !!this.company.companyType;
        this.showErrorNoCompanyTypeFilledIn = !this.company.companyType;
    }

    validateCompanyNameInput() {
        this.inputCompanyNameValid = !!this.company.companyName;
        this.showErrorNoCompanyNameFilledIn = !this.company.companyName;
    }

    validateCompanyNumberInput() {
        this.inputCompanyNumberValid = !!this.company.companyNumber && !!this.company.companyNumber.match(/^[0-9]{10}$/);
        this.showErrorNoCompanyNumberFilledIn = !this.company.companyNumber;
        this.showErrorCompanyNumberWrongFormat = !!this.company.companyNumber && !this.company.companyNumber.match(/^[0-9]{10}$/);
    }

    validateNacebelInput() {
        this.inputNacebelValid = !!this.company.nacebelCode && !!this.NacebelCodes.map(x => x.code).includes(this.company.nacebelCode);
        this.showErrorNoNacebelFilledIn = !this.company.nacebelCode;
        this.showErrorNacebelWrongFormat = !!this.company.nacebelCode && !this.NacebelCodes.map(x => x.code).includes(this.company.nacebelCode);
    }

    validateForm() {
        this.validateInputs();

        return this.inputCompanyTypeValid && this.inputCompanyNameValid && this.inputCountryValid && this.inputCompanyNumberValid && this.inputNacebelValid && this.inputContactPersonFirstNameValid && this.inputContactPersonLastNameValid && this.inputContactPersonTelephoneValid && this.inputEmailValid && this.inputTelephoneValid && this.inputStreetNameValid && this.inputHouseNumberValid && this.inputPostalCodeValid && this.inputLocationValid && this.inputInvoicingAddressStreetNameValid && this.inputInvoicingAddressHouseNumberValid && this.inputInvoicingAddressPostalCodeValid && this.inputInvoicingAddressLocationValid;
    }

    getNameFromPostalCode(postalCode: string | number | undefined): string {
        return this.AllPostalCodes.find(p => p.code == postalCode)?.name ?? '';
    }

    getPostalCodeFromName(name: string): string {
        return this.AllPostalCodes.find(p => p.name == name)?.code ?? '0';
    }
}