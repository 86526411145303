import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, Predicate } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AccordionModule } from "primeng/accordion";
import { InputGroupModule } from "primeng/inputgroup";
import { RadioButtonModule } from "primeng/radiobutton";
import { TableModule } from "primeng/table";
import { ElectricityType } from "src/app/models/enums/electricity-type.enum";
import { GasType } from "src/app/models/enums/gas-type.enum";
import { BillingFrequency } from "src/app/models/enums/billing-frequency.enum";
import { Product } from "src/app/models/product.model";

@Component({
    selector: 'product-selection-component',
    standalone: true,
    templateUrl: 'product-selection.component.html',
    styleUrls: ['./product-selection.component.scss'],
    imports: [AccordionModule, CommonModule, FormsModule, InputGroupModule, TableModule, RadioButtonModule, ReactiveFormsModule, RouterModule]
})
export class ProductSelectionComponent {
    @Input() iconCSSClass!: string;
    @Input() materialIcon: string = '';
    @Input() checkmarksCSSClass!: string;
    @Input() selectedBackgroundCSSClass!: string;
    @Input() selectionValue!: number;
    @Input() selection!: number;
    @Input() products!: Product[];
    @Input() enableSelection: boolean = true;
    @Output() selectionChange: EventEmitter<number> = new EventEmitter<number>();

    //Enum definitions
    InvoicingModality = BillingFrequency;
    GasType = GasType;
    ElectricityType = ElectricityType;

    isNaN(value: any): boolean {
        return isNaN(value);
    }

    includesType(type: ElectricityType | GasType): Predicate<Product> {
        return (product) => product?.name === ElectricityType[type] || product?.name === GasType[type];
    }

    excludesType(type: ElectricityType | GasType): Predicate<Product> {
        return (product) => product?.name !== ElectricityType[type] && product?.name !== GasType[type];
    }
}