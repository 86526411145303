<div>
<div class="row">
    <div class="xl:col-12 lg:col-12 col-12">
        <h3>Dit voorstel als e-mail ontvangen?</h3>
    </div>
    <div class="xl:col-12 lg:col-12 col-12">
        <p>
            We houden je ingevulde gegevens voor een periode van maximaal 30 kalenderdagen bij. We sturen je een link waarmee je jouw voorstel binnen deze periode opnieuw kan openen. 
        </p>        
    </div>
   <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <label for="email">E-mail</label>
        </div>
        <div class="xl:col-12 lg:col-12 col-12">
            <input type="email" name="email" placeholder="voorbeeld@domein.com" [ngClass]="inputEmailValid == null ||  inputEmailValid == undefined ? '' : inputEmailValid === true ? 'ng-valid' : inputEmailValid === false ? 'ng-invalid' : ''" (input)="validateEmailInput()" [(ngModel)]="email" pInputText >
            <p-card class="danger no-padding" *ngIf="showErrorNoEmailFilledIn">Email is een verplicht veld</p-card>
            <p-card class="danger no-padding" *ngIf="showErrorEmailWrongFormat">Email heeft niet het juiste formaat: tekenreeks beginnend met een letter of een cijfer gevolgd door een &#64; gevolgd door een emailprovider en een webextensie (.com, .be, ...) </p-card>      
            <div>        
                <ngx-turnstile [siteKey]="siteKey" (resolved)="sendCaptchaResponse($event)" theme="auto" [tabIndex]="0" [appearance]="'interaction-only'"></ngx-turnstile>
            </div>      
        </div>
        <div class="xl:col-12 lg:col-12 col-12">
            <p-checkbox #dataCheckRef [(ngModel)]="dataCheck" [binary]="true"></p-checkbox>
            <p-button [styleClass]="'p-button-link-no-decoration'" [link]="true" [style]="{ padding: 0 }" (click)="dataCheckRef.updateModel(!dataCheckRef.checked)">&nbsp;&nbsp;&nbsp;Ik geef toestemming aan Ebem om mijn gegevens tijdelijk op te slaan en naar bovenstaande e-mail adres te sturen.</p-button>
        </div>   
        <div class="xl:col-12 lg:col-12 col-12">
            <p-button class="float-left" (click)="validateEmailInput(); saveState(email, { 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse })" [routerLink]="['/']" [disabled]="!dataCheck || showErrorNoEmailFilledIn || showErrorEmailWrongFormat || !email">E-mail versturen</p-button>   
        </div>
</div>
<br/>
<p-progress-bar [showValue]="false" [value]="100" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Naar homepagina</p-button>
    </div>
    <div class="col-6">
        <p-button class="float-right" [routerLink]="['/customer-wizard/customer', 'step-1']" [state]="{ 'gasType': gasType, 'electricityType': electricityType, 'postalCode': postalCode, 'priceSimulationInput': priceSimulationInput }">Klant worden</p-button>
    </div>
</div>
</div>
<p-toast/>