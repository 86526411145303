import { Component } from "@angular/core";
import { SearchComponent } from "src/app/components/search/search.component";

@Component({
    selector: 'search-page',
    templateUrl: './search.page.html',
    styleUrls: ['./search.page.scss'],
    standalone: true,
    imports: [SearchComponent]
})
export class SearchPage {
}