<div>
<div class="row">
    <div class="xl:col-12 lg:col-12 col-12">
        <h3>Selecteer uw gewenste producten</h3>
        <p>Als je richtprijzen wil zien, gebaseerd op je geschatte verbruik, doorloop dan onze <a class="ebemGreen" [routerLink]="['/price-calculation', 'step-2b']" [state]="{'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse}">prijssimulatie</a></p>
    </div>
    <div class="xl:col-12 lg:col-12 col-12">
        @if (priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit)) {
            <h3><img class="icon-small" [src]="'icons/electricity-icon.png'" />Elektriciteit</h3>
            <product-selection-component [iconCSSClass]="'pi-bolt ebemGreen'" [checkmarksCSSClass]="'check-ebemGreen'" [selectedBackgroundCSSClass]="'backgroundEbemLightGreen'" [products]="electricityProducts" [(selection)]="electricityType"></product-selection-component>
        }
    </div>
    <div class="xl:col-12 lg:col-12 col-12">
        @if (priceSimulationChoices?.includes(PriceSimulationChoice.Gas)) {
            <h3><img class="icon-small" [src]="'icons/gas-icon.png'" />Gas</h3>
            <product-selection-component [iconCSSClass]="'material-icons ebemBlue'" [materialIcon]="'local_fire_department'" [checkmarksCSSClass]="'check-ebemBlue'" [selectedBackgroundCSSClass]="'backgroundEbemLightBlue'" [products]="gasProducts" [(selection)]="gasType"></product-selection-component>
        }
    </div>
</div>
<p-card class="danger no-padding" *ngIf="showErrorNoProductChosen">Gelieve een keuze te maken</p-card>
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="100" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/price-calculation', 'step-2']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Terug</p-button>
    </div>
    <div class="col-6">
       <p-button class="float-right" (click)="this.evaluateForm($event) ? this.router.navigate(['/customer-wizard/customer', 'step-1'], {state: { 'gasType': gasType, 'electricityType': electricityType, 'postalCode': postalCode }}) : null">Klant worden <span class="pi-arrow-right"></span></p-button>
    </div>
</div>
</div>