import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UmbracoLink } from "src/app/models/umbraco-link.model";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";
import { UmbracoService } from "src/app/services/umbraco.service";
import { environment } from "src/environments/environment";
import { UmbracoHelper } from "src/app/helpers/umbraco.helper";
import { Subscription } from "rxjs";

@Component({
    selector: 'tariffs-page',
    templateUrl: './tariffs.page.html',
    styleUrls: ['./tariffs.page.scss'],
    standalone: true,
    imports: [CommonModule, DataUdiDirective, DynamicHTMLModule],
    providers: [UmbracoService]
})
export class TariffsPage {
    htmlContent!: string;
    electricityTariffs!: UmbracoLink[];
    gasTariffs!: UmbracoLink[];
    umbracoUrl = environment.umbracoUrl;
    subscribers: Subscription[] = [];

    constructor(private umbracoService: UmbracoService) {
        this.subscribers.push(this.umbracoService.getTariffsPage().subscribe(content => this.htmlContent = UmbracoHelper.processContent(content[0].properties?.["bodyText"]?.sections[0]?.rows[0]?.areas[0]?.controls[0]?.value)));
        this.umbracoService.getElectriticyTariffs().subscribe(content => this.electricityTariffs = content);
        this.umbracoService.getGasTariffs().subscribe(content => this.gasTariffs = content);
    }
}