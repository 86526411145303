<h1>Openstaande vacatures</h1>
@if (vacancies.length === 0) {
    <p-card>
        <p>Op dit moment hebben wij geen openstaande vacatures.</p>
        <p>U kan echter steeds spontaan solliciteren door uw CV en motivatiebrief te sturen naar <a href="mailto:vacatures@ebem.be">jobs&#64;ebem.be</a></p>
    </p-card>
} @else {
    @for(vacancy of vacancies; track $index) {
        <p-card>
            <h3>{{vacancy.title}}</h3>
            <dynamic-template *ngIf="vacancy" [content]="vacancy.innerHtml"></dynamic-template>
            <a href="mailto:vacatures@ebem.be" pButton>Soliciteer door uw CV en motivatiebrief te sturen naar vacatures&#64;ebem.be</a>
        </p-card>
        <br/>
    }
}
