<div class="row">
    <div class="xl:col-6 lg:col-6 col-12">          
        <h2>Over ons</h2>
        <p>Ebem is een energieleverancier die dicht bij de mensen staat, duurzaam onderneemt en zorg draagt voor het milieu. Wij zijn opgericht na de vrijmaking van de elektriciteitsmarkt. Als enige in Vlaanderen besloot de gemeente Merksplas de ervaring van de eigen Elektriciteitsregie niet verloren te laten gaan.</p>
        <div class="row">
            <div class="xl:col-3 lg:col-2 col-12">
                <p-button [routerLink]="['//customer-wizard/' + customerType, 'step-1']">Klant worden</p-button>
            </div>
            <div class="xl:col-3 lg:col-2 col-12">
                <p-button outlined="true" [routerLink]="['/price-calculation', 'step-1']">Prijs berekenen</p-button>
            </div>
        </div>       
    </div>
    <div class="xl:col-6 lg:col-6 col-12">
            <div class="card-container">
                <img class="card-background" [src]="'img/solarpanels.png'">
            </div>
    </div>
</div>
<div class="row">    
    <div class="xl:col-6 lg:col-6 col-12">
        <div class="card-container">
            <img class="card-background" [src]="'img/solarpanels 2.png'">
        </div>
    </div>
    <div class="xl:col-6 lg:col-6 col-12">          
        <h2>Ons aanbod</h2>
        <p><i class="pi pi-check" style="color: green;"></i> Afhankelijk van de mogelijkheden kan je het elektriciteitsverbruik van bepaalde toestellen en toepassingen zoveel mogelijk laten samenvallen met de uren waar lagere prijzen voor gelden (bv. laden elektrisch voertuig, warmtepomp, wasmachine). Zo kan je de energiekost op jouw factuur verlagen.</p>
        <p><i class="pi pi-check" style="color: green;"></i> Ebem Groen Dyn&#64;mic is voordelig indien je een groot energieverbruik hebt waarvan je het moment van verbruik kan plannen. Dit is het geval bij een elektrische wagen, maar kan ook dankzij het gebruik van een batterij in combinatie met zonnepanelen.</p>
        <p><i class="pi pi-check" style="color: green;"></i> Om echt voordeel met dit tarief te doen, moet je verbruiken op - of kunnen verschuiven naar- die momenten waarop het dynamisch energietarief voordeliger is dan een standaard tarief. Typisch is tussen middernacht en 6 uur 's ochtends. Kan je jouw verbruik niet verschuiven naar 's nachts, dan is de namiddag meestal een goed alternatief.</p>
        <div class="row">
            <div class="xl:col-3 lg:col-2 col-12">
                <p-button [routerLink]="['/customer-wizard/' + customerType, 'step-1']">Klant worden</p-button>
            </div>
            <div class="xl:col-3 lg:col-2 col-12">
                <p-button outlined="true" [routerLink]="['/price-calculation', 'step-1']">Prijs berekenen</p-button>
            </div>
        </div>       
    </div>   
</div>
<usp-component></usp-component>
<mission-statement-component></mission-statement-component>
<dynamic-tariffs-component></dynamic-tariffs-component>