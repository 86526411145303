<div class="backgroundLightEbemGreen">
    <div class="row">
        <h2 class="flex mb-4 pt-8 xl:flex-row lg:flex-row md:flex-column sm:flex-column md:align-items-center sm:align-items-center align-content-start"><img class="icon-small" [src]="'icons/usp-icon.png'" />Waar maken wij het verschil?</h2>
    </div>
    <div class="row">
        <p-card class="flex xl:col-4 lg:col-4 col-12">
            <p-avatar image="img/tree-badge.png" size="xlarge"></p-avatar>
            <h3 class="pt-8">Duurzame<br/>onderneming</h3>
            <p>Wij investeren in lokale hernieuwbare energie zoals biogasmotoren en PV-installaties</p>
        </p-card>
        <p-card class="flex xl:col-4 lg:col-4 col-12">
            <p-avatar image="img/windmill-badge.png" size="xlarge" ></p-avatar>
            <h3 class="pt-8">Groene energie</h3>
            <p>Wij bevoorraden ons via windturbines, biomassacentrales en projecten met fotovoltaïsche zonnecellen.</p>
        </p-card>
        <p-card class="flex xl:col-4 lg:col-4 col-12">
            <p-avatar image="img/lighting-badge.png" size="xlarge"></p-avatar>
            <h3 class="pt-8">Lokaal</h3>
            <p>Sinds 1 maart 2008 levert Ebem enkel nog 100% lokale groene stroom</p>
        </p-card>
    </div>
    <p-button class="flex mt-4 pb-8 justify-content-center" [routerLink]="['/', 'about-us']">Meer over ons</p-button>
</div>