.button-align-bottom-right {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-end;
    justify-content: flex-end;
}

.container {
    background-color: #E8EFCD !important;
}