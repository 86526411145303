import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { AccordionModule } from "primeng/accordion";
import { FAQItem } from "src/app/models/faq.model";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";

@Component({
    selector: 'faq-component',
    standalone: true,
    styleUrls: ['./faq.component.scss'],
    templateUrl: './faq.component.html',
    imports: [AccordionModule, CommonModule, DataUdiDirective, DynamicHTMLModule]
})
export class FAQComponent {
    @Input() faq!: FAQItem;
}