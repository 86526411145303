import { HttpClient } from "@angular/common/http";
import { UmbracoContent } from "./umbraco-content.model";
import { environment } from "src/environments/environment";
import { map, Observable, of, switchMap } from "rxjs";
import { jsonToFAQItem } from "./faq.model";
import { UmbracoHelper } from "../helpers/umbraco.helper";

export class InfoTab {
    header!: string;
    content!: string | undefined;
}

export function jsonToInfoTab(http: HttpClient, json: any): Observable<InfoTab> {
    const infoTab = new InfoTab();
    infoTab.header = json.name;
    infoTab.content = UmbracoHelper.processContent(json.properties?.["bodyText"]?.sections[0]?.rows[0]?.areas[0]?.controls[0]?.value);

    if (json.contentType === 'redirect') {
        return http.get<UmbracoContent>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content/item/${json.properties.redirectUrl}`).pipe(
            map(content => {
                infoTab.header = content.name;
                infoTab.content = UmbracoHelper.processContent(content.properties?.["bodyText"]?.sections[0]?.rows[0]?.areas[0]?.controls[0]?.value);
                return infoTab;
            })
        );
    } else if (json.contentType === 'fAQPage') {
        return http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?fetch=children:${json.route.path.replace(/\//gm, '%2F')}&filter=contentType:fAQSubject&fields=properties[$all]`).pipe(
            switchMap(content => {
                if (content.items[0].contentType === 'fAQSubject') {
                    const title = content.items[0].properties?.title;
                    return http.get<{ items: any[] }>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/content?fetch=children:${content.items[0].route.path.replace(/\//gm, '%2F')}&filter=contentType:fAQItem&fields=properties[$all]`).pipe(
                        map(content => {
                            const faqItems = content.items.map(item => jsonToFAQItem(item));
                            infoTab.content = `<h1>${title}</h1>` + faqItems.map(item => `<p-accordion><p-accordion-panel value="0"><p-accordion-header>${item.question.replace(/@/gm, '&#64;')}</p-accordion-header><p-accordion-content>${item.answer?.replace(/@/gm, '&#64;')}</p-accordion-content></p-accordion-panel></p-accordion>`).join('<br/>') + infoTab.content;
                            return infoTab;
                        })
                    );
                }
                return of(infoTab);
            })
        );
    }

    return of(infoTab);
}