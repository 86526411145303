import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { InputNumberModule } from "primeng/inputnumber";
import { Nullable } from "primeng/ts-helpers";
import { PostalCode } from "src/app/models/postalcode.model";
import { PostalcodeService } from "src/app/services/postalcode.service";

@Component({
    selector: 'call-to-action-component',
    standalone: true,
    templateUrl: './call-to-action.component.html',
    styleUrls: ['call-to-action.component.scss'],
    imports: [ButtonModule, CardModule, CommonModule, InputNumberModule, FormsModule, RouterModule],
    providers: [PostalcodeService]
})
export class CallToActionComponent {
    @Input() imageSrc!: string;
    @Input() title!: string;
    @Input() text!: string;
    @Input() buttonText!: string;
    @Input() buttonRoute!: string[];
    @Input() subtext!: string;

    inputPostalCodeValid: Nullable<boolean>;
    showErrorNoPostalCodeFilledIn: boolean = false;
    showErrorPostalCodeInvalid: boolean = false;
    postalCode!: string | number | undefined;

    PostalCodes: PostalCode[] = [];

    constructor(private postalCodeService: PostalcodeService, public router: Router){
        this.postalCodeService.getPostalCodes().subscribe(res => {
            this.PostalCodes = res.filter(x => x.isValidArea);
        });
    }

    validatePostalCodeInput() {
        this.inputPostalCodeValid = !!this.postalCode && !!this.PostalCodes.map(x => x.code).includes(this.postalCode.toString());
        this.showErrorNoPostalCodeFilledIn = !this.postalCode;
        this.showErrorPostalCodeInvalid = !!this.postalCode && !this.PostalCodes.map(x => x.code).includes(this.postalCode.toString());
    }

    validateInputs() {
        this.validatePostalCodeInput();
    }

    validateForm() {
        this.validateInputs();

        return this.inputPostalCodeValid;
    }
}