import { trigger, state, transition, style, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, booleanAttribute, ContentChildren, ContentChild, Output, Input, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { uuid } from '@primeuix/utils';
import { SharedModule, PrimeTemplate } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { ButtonModule } from 'primeng/button';
import { MinusIcon, PlusIcon } from 'primeng/icons';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-fieldset {
    background: ${dt('fieldset.background')};
    border: 1px solid ${dt('fieldset.border.color')};
    border-radius: ${dt('fieldset.border.radius')};
    color: ${dt('fieldset.color')};
    padding:  ${dt('fieldset.padding')};
    margin: 0;
}

.p-fieldset-legend {
    background: ${dt('fieldset.legend.background')};
    border-radius: ${dt('fieldset.legend.border.radius')};
    border-width: ${dt('fieldset.legend.border.width')};
    border-style: solid;
    border-color: ${dt('fieldset.legend.border.color')};
    padding: ${dt('fieldset.legend.padding')};
    transition: background ${dt('fieldset.transition.duration')}, color ${dt('fieldset.transition.duration')}, outline-color ${dt('fieldset.transition.duration')}, box-shadow ${dt('fieldset.transition.duration')};
}

.p-fieldset-toggleable > .p-fieldset-legend {
    padding: 0;
}

.p-fieldset-toggle-button {
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    display: flex;
    gap: ${dt('fieldset.legend.gap')};
    align-items: center;
    justify-content: center;
    padding: ${dt('fieldset.legend.padding')};
    background: transparent;
    border: 0 none;
    border-radius: ${dt('fieldset.legend.border.radius')};
    transition: background ${dt('fieldset.transition.duration')}, color ${dt('fieldset.transition.duration')}, outline-color ${dt('fieldset.transition.duration')}, box-shadow ${dt('fieldset.transition.duration')};
    outline-color: transparent;
}

.p-fieldset-legend-label {
    font-weight: ${dt('fieldset.legend.font.weight')};
}

.p-fieldset-toggle-button:focus-visible {
    box-shadow: ${dt('fieldset.legend.focus.ring.shadow')};
    outline: ${dt('fieldset.legend.focus.ring.width')} ${dt('fieldset.legend.focus.ring.style')} ${dt('fieldset.legend.focus.ring.color')};
    outline-offset: ${dt('fieldset.legend.focus.ring.offset')};
}

.p-fieldset-toggleable > .p-fieldset-legend:hover {
    color: ${dt('fieldset.legend.hover.color')};
    background: ${dt('fieldset.legend.hover.background')};
}

.p-fieldset-toggle-icon {
    color: ${dt('fieldset.toggle.icon.color')};
    transition: color ${dt('fieldset.transition.duration')};
}

.p-fieldset-toggleable > .p-fieldset-legend:hover .p-fieldset-toggle-icon {
    color: ${dt('fieldset.toggle.icon.hover.color')};
}

.p-fieldset .p-fieldset-content {
    padding: ${dt('fieldset.content.padding')};
}

/* For PrimeNG */
.p-fieldset-toggleable.p-fieldset-expanded > .p-fieldset-content-container:not(.ng-animating) {
    overflow: visible
}

.p-fieldset-toggleable .p-fieldset-content-container {
    overflow: hidden;
}
`;
const classes = {
  root: ({
    props
  }) => ['p-fieldset p-component', {
    'p-fieldset-toggleable': props.toggleable
  }],
  legend: 'p-fieldset-legend',
  legendLabel: 'p-fieldset-legend-label',
  toggleButton: 'p-fieldset-toggle-button',
  toggleIcon: 'p-fieldset-toggle-icon',
  contentContainer: 'p-fieldset-content-container',
  content: 'p-fieldset-content'
};
class FieldsetStyle extends BaseStyle {
  name = 'fieldset';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: FieldsetStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: FieldsetStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: FieldsetStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * Fieldset is a grouping component with the optional content toggle feature.
 *
 * [Live Demo](https://www.primeng.org/fieldset/)
 *
 * @module fieldsetstyle
 *
 */
var FieldsetClasses;
(function (FieldsetClasses) {
  /**
   * Class name of the root element
   */
  FieldsetClasses["root"] = "p-fieldset";
  /**
   * Class name of the legend element
   */
  FieldsetClasses["legend"] = "p-fieldset-legend";
  /**
   * Class name of the legend label element
   */
  FieldsetClasses["legendLabel"] = "p-fieldset-legend-label";
  /**
   * Class name of the toggle icon element
   */
  FieldsetClasses["toggleIcon"] = "p-fieldset-toggle-icon";
  /**
   * Class name of the content container element
   */
  FieldsetClasses["contentContainer"] = "p-fieldset-content-container";
  /**
   * Class name of the content element
   */
  FieldsetClasses["content"] = "p-fieldset-content";
})(FieldsetClasses || (FieldsetClasses = {}));

/**
 * Fieldset is a grouping component with the optional content toggle feature.
 * @group Components
 */
class Fieldset extends BaseComponent {
  /**
   * Header text of the fieldset.
   * @group Props
   */
  legend;
  /**
   * When specified, content can toggled by clicking the legend.
   * @group Props
   * @defaultValue false
   */
  toggleable;
  /**
   * Defines the default visibility state of the content.
   * * @group Props
   */
  collapsed = false;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Transition options of the panel animation.
   * @group Props
   */
  transitionOptions = '400ms cubic-bezier(0.86, 0, 0.07, 1)';
  /**
   * Emits when the collapsed state changes.
   * @param {boolean} value - New value.
   * @group Emits
   */
  collapsedChange = new EventEmitter();
  /**
   * Callback to invoke before panel toggle.
   * @param {PanelBeforeToggleEvent} event - Custom toggle event
   * @group Emits
   */
  onBeforeToggle = new EventEmitter();
  /**
   * Callback to invoke after panel toggle.
   * @param {PanelAfterToggleEvent} event - Custom toggle event
   * @group Emits
   */
  onAfterToggle = new EventEmitter();
  get id() {
    return uuid('pn_id_');
  }
  get buttonAriaLabel() {
    return this.legend;
  }
  animating;
  _componentStyle = inject(FieldsetStyle);
  /**
   * Defines the header template.
   * @group Templates
   */
  headerTemplate;
  /**
   * Defines the expandicon template.
   * @group Templates
   */
  expandIconTemplate;
  /**
   * Defines the collapseicon template.
   * @group Templates
   */
  collapseIconTemplate;
  /**
   * Defines the content template.
   * @group Templates
   */
  contentTemplate;
  toggle(event) {
    if (this.animating) {
      return false;
    }
    this.animating = true;
    this.onBeforeToggle.emit({
      originalEvent: event,
      collapsed: this.collapsed
    });
    if (this.collapsed) this.expand();else this.collapse();
    this.onAfterToggle.emit({
      originalEvent: event,
      collapsed: this.collapsed
    });
    event.preventDefault();
  }
  onKeyDown(event) {
    if (event.code === 'Enter' || event.code === 'Space') {
      this.toggle(event);
      event.preventDefault();
    }
  }
  expand() {
    this.collapsed = false;
    this.collapsedChange.emit(this.collapsed);
  }
  collapse() {
    this.collapsed = true;
    this.collapsedChange.emit(this.collapsed);
  }
  getBlockableElement() {
    return this.el.nativeElement.children[0];
  }
  onToggleDone() {
    this.animating = false;
  }
  _headerTemplate;
  _expandIconTemplate;
  _collapseIconTemplate;
  _contentTemplate;
  templates;
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'header':
          this._headerTemplate = item.template;
          break;
        case 'expandicon':
          this._expandIconTemplate = item.template;
          break;
        case 'collapseicon':
          this._collapseIconTemplate = item.template;
          break;
        case 'content':
          this._contentTemplate = item.template;
          break;
      }
    });
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Fieldset,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: Fieldset,
    isStandalone: true,
    selector: "p-fieldset",
    inputs: {
      legend: "legend",
      toggleable: ["toggleable", "toggleable", booleanAttribute],
      collapsed: ["collapsed", "collapsed", booleanAttribute],
      style: "style",
      styleClass: "styleClass",
      transitionOptions: "transitionOptions"
    },
    outputs: {
      collapsedChange: "collapsedChange",
      onBeforeToggle: "onBeforeToggle",
      onAfterToggle: "onAfterToggle"
    },
    providers: [FieldsetStyle],
    queries: [{
      propertyName: "headerTemplate",
      first: true,
      predicate: ["header"]
    }, {
      propertyName: "expandIconTemplate",
      first: true,
      predicate: ["expandicon"]
    }, {
      propertyName: "collapseIconTemplate",
      first: true,
      predicate: ["collapseicon"]
    }, {
      propertyName: "contentTemplate",
      first: true,
      predicate: ["content"]
    }, {
      propertyName: "templates",
      predicate: PrimeTemplate
    }],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <fieldset
            [attr.id]="id"
            [ngClass]="{
                'p-fieldset p-component': true,
                'p-fieldset-toggleable': toggleable,
                'p-fieldset-expanded': !collapsed && toggleable
            }"
            [ngStyle]="style"
            [class]="styleClass"
            [attr.data-pc-name]="'fieldset'"
            [attr.data-pc-section]="'root'"
        >
            <legend class="p-fieldset-legend" [attr.data-pc-section]="'legend'">
                <ng-container *ngIf="toggleable; else legendContent">
                    <button
                        [attr.id]="id + '_header'"
                        tabindex="0"
                        role="button"
                        [attr.aria-controls]="id + '_content'"
                        [attr.aria-expanded]="!collapsed"
                        [attr.aria-label]="buttonAriaLabel"
                        (click)="toggle($event)"
                        (keydown)="onKeyDown($event)"
                        class="p-fieldset-toggle-button"
                    >
                        <ng-container *ngIf="collapsed">
                            <PlusIcon *ngIf="!expandIconTemplate && !_expandIconTemplate" [styleClass]="'p-fieldset-toggler'" [attr.data-pc-section]="'togglericon'" />
                            <span *ngIf="expandIconTemplate || _expandIconTemplate" class="p-fieldset-toggler" [attr.data-pc-section]="'togglericon'">
                                <ng-container *ngTemplateOutlet="expandIconTemplate || _expandIconTemplate"></ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!collapsed">
                            <MinusIcon *ngIf="!collapseIconTemplate && !_collapseIconTemplate" [styleClass]="'p-fieldset-toggler'" [attr.aria-hidden]="true" [attr.data-pc-section]="'togglericon'" />
                            <span *ngIf="collapseIconTemplate || _collapseIconTemplate" class="p-fieldset-toggler" [attr.data-pc-section]="'togglericon'">
                                <ng-container *ngTemplateOutlet="collapseIconTemplate || _collapseIconTemplate"></ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngTemplateOutlet="legendContent"></ng-container>
                    </button>
                </ng-container>
                <ng-template #legendContent>
                    <span class="p-fieldset-legend-label" [attr.data-pc-section]="'legendtitle'">{{ legend }}</span>
                    <ng-content select="p-header"></ng-content>
                    <ng-container *ngTemplateOutlet="headerTemplate || _headerTemplate"></ng-container>
                </ng-template>
            </legend>
            <div
                [attr.id]="id + '_content'"
                role="region"
                class="p-fieldset-content-container"
                [@fieldsetContent]="collapsed ? { value: 'hidden', params: { transitionParams: transitionOptions, height: '0' } } : { value: 'visible', params: { transitionParams: animating ? transitionOptions : '0ms', height: '*' } }"
                [attr.aria-labelledby]="id + '_header'"
                [attr.aria-hidden]="collapsed"
                [attr.data-pc-section]="'toggleablecontent'"
                (@fieldsetContent.done)="onToggleDone()"
            >
                <div class="p-fieldset-content" [attr.data-pc-section]="'content'">
                    <ng-content></ng-content>
                    <ng-container *ngTemplateOutlet="contentTemplate || _contentTemplate"></ng-container>
                </div>
            </div>
        </fieldset>
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i1.NgStyle,
      selector: "[ngStyle]",
      inputs: ["ngStyle"]
    }, {
      kind: "ngmodule",
      type: ButtonModule
    }, {
      kind: "component",
      type: MinusIcon,
      selector: "MinusIcon"
    }, {
      kind: "component",
      type: PlusIcon,
      selector: "PlusIcon"
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    animations: [trigger('fieldsetContent', [state('hidden', style({
      height: '0'
    })), state('visible', style({
      height: '*'
    })), transition('visible <=> hidden', [animate('{{transitionParams}}')]), transition('void => *', animate(0))])],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Fieldset,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-fieldset',
      standalone: true,
      imports: [CommonModule, ButtonModule, MinusIcon, PlusIcon, SharedModule],
      template: `
        <fieldset
            [attr.id]="id"
            [ngClass]="{
                'p-fieldset p-component': true,
                'p-fieldset-toggleable': toggleable,
                'p-fieldset-expanded': !collapsed && toggleable
            }"
            [ngStyle]="style"
            [class]="styleClass"
            [attr.data-pc-name]="'fieldset'"
            [attr.data-pc-section]="'root'"
        >
            <legend class="p-fieldset-legend" [attr.data-pc-section]="'legend'">
                <ng-container *ngIf="toggleable; else legendContent">
                    <button
                        [attr.id]="id + '_header'"
                        tabindex="0"
                        role="button"
                        [attr.aria-controls]="id + '_content'"
                        [attr.aria-expanded]="!collapsed"
                        [attr.aria-label]="buttonAriaLabel"
                        (click)="toggle($event)"
                        (keydown)="onKeyDown($event)"
                        class="p-fieldset-toggle-button"
                    >
                        <ng-container *ngIf="collapsed">
                            <PlusIcon *ngIf="!expandIconTemplate && !_expandIconTemplate" [styleClass]="'p-fieldset-toggler'" [attr.data-pc-section]="'togglericon'" />
                            <span *ngIf="expandIconTemplate || _expandIconTemplate" class="p-fieldset-toggler" [attr.data-pc-section]="'togglericon'">
                                <ng-container *ngTemplateOutlet="expandIconTemplate || _expandIconTemplate"></ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!collapsed">
                            <MinusIcon *ngIf="!collapseIconTemplate && !_collapseIconTemplate" [styleClass]="'p-fieldset-toggler'" [attr.aria-hidden]="true" [attr.data-pc-section]="'togglericon'" />
                            <span *ngIf="collapseIconTemplate || _collapseIconTemplate" class="p-fieldset-toggler" [attr.data-pc-section]="'togglericon'">
                                <ng-container *ngTemplateOutlet="collapseIconTemplate || _collapseIconTemplate"></ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngTemplateOutlet="legendContent"></ng-container>
                    </button>
                </ng-container>
                <ng-template #legendContent>
                    <span class="p-fieldset-legend-label" [attr.data-pc-section]="'legendtitle'">{{ legend }}</span>
                    <ng-content select="p-header"></ng-content>
                    <ng-container *ngTemplateOutlet="headerTemplate || _headerTemplate"></ng-container>
                </ng-template>
            </legend>
            <div
                [attr.id]="id + '_content'"
                role="region"
                class="p-fieldset-content-container"
                [@fieldsetContent]="collapsed ? { value: 'hidden', params: { transitionParams: transitionOptions, height: '0' } } : { value: 'visible', params: { transitionParams: animating ? transitionOptions : '0ms', height: '*' } }"
                [attr.aria-labelledby]="id + '_header'"
                [attr.aria-hidden]="collapsed"
                [attr.data-pc-section]="'toggleablecontent'"
                (@fieldsetContent.done)="onToggleDone()"
            >
                <div class="p-fieldset-content" [attr.data-pc-section]="'content'">
                    <ng-content></ng-content>
                    <ng-container *ngTemplateOutlet="contentTemplate || _contentTemplate"></ng-container>
                </div>
            </div>
        </fieldset>
    `,
      animations: [trigger('fieldsetContent', [state('hidden', style({
        height: '0'
      })), state('visible', style({
        height: '*'
      })), transition('visible <=> hidden', [animate('{{transitionParams}}')]), transition('void => *', animate(0))])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [FieldsetStyle]
    }]
  }],
  propDecorators: {
    legend: [{
      type: Input
    }],
    toggleable: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    collapsed: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    transitionOptions: [{
      type: Input
    }],
    collapsedChange: [{
      type: Output
    }],
    onBeforeToggle: [{
      type: Output
    }],
    onAfterToggle: [{
      type: Output
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['header', {
        descendants: false
      }]
    }],
    expandIconTemplate: [{
      type: ContentChild,
      args: ['expandicon', {
        descendants: false
      }]
    }],
    collapseIconTemplate: [{
      type: ContentChild,
      args: ['collapseicon', {
        descendants: false
      }]
    }],
    contentTemplate: [{
      type: ContentChild,
      args: ['content', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  }
});
class FieldsetModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: FieldsetModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: FieldsetModule,
    imports: [Fieldset, SharedModule],
    exports: [Fieldset, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: FieldsetModule,
    imports: [Fieldset, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: FieldsetModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [Fieldset, SharedModule],
      exports: [Fieldset, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { Fieldset, FieldsetClasses, FieldsetModule, FieldsetStyle };
