<div class="row">
    <div class="xl:col-4 lg:col-4 col-12 align-content-center align-items-center">
        <h2><img class="icon-small" [src]="'icons/graph-icon.png'" />Dynamische tarieven</h2>
        <p>In een dynamisch contract wordt de prijs voor de energiekost per uur bepaald op basis van de prijzen op de energiebeurzen waarop Ebem energie aankoopt voor de dag nadien. Concreet zal dit betekenen dat jouw elektriciteitsafname per uur wordt afgerekend gebaseerd op de prijs van dat exacte uur.</p>
        <p-button [routerLink]="['/dynamic-tariffs']">Tarieven detail bekijken</p-button>
    </div>
    <div class="xl:col-1 lg:col-1 col-12"></div>
    <div class="xl:col-7 lg:col-7 col-12">
        <dynamic-tariffs-graph-component></dynamic-tariffs-graph-component>
    </div>
</div>
