import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { jsonToPostalCode, PostalCode } from "../models/postalcode.model";
import _ from "lodash";

@Injectable()
export class PostalcodeService {
    constructor(private http: HttpClient) { }

    getPostalCodes(): Observable<PostalCode[]> {
        return this.http.get<PostalCode[]>(`${environment.baseUrl}/PostalCodeService/GetPostalCodes`).pipe(map(res => _.map(res, res => jsonToPostalCode(res))));
    }
}