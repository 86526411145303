import { environment } from "src/environments/environment";

export class UmbracoHelper {
    static processContent(content: string): string {
        return content?.replaceAll(/@/gm, '&#64;')
            .replaceAll(/<(h[1-6])>(.*)<\/h[1-6]>/gm, (match, p1, p2) => {
                var id = p2.replace(/"+/g, '').replace(/\s+/g, '-').toLowerCase();
                return `<${p1} id="${id}">${p2}</${p1}>`;
            })
             .replaceAll(/data-udi/gm, 'dataUdi [data-udi]')
             .replaceAll(/href\="\/{localLink:umb:\/\/media\/([a-z0-9]{32})}"/gm, "dataUdi [data-udi]=\"umb://media/$1\"")
             .replaceAll(/href\="(\/media\/[a-z0-9]{8}\/.*)"/gm, `href=\"${environment.umbracoUrl}$1\"`)
             .replaceAll(/src\="(\/media\/[a-z0-9]{8}\/.*)"/gm, `src=\"${environment.umbracoUrl}$1\"`)
             .replaceAll(/umb:\/\/media\/([a-z0-9]{32})/gm, "'umb://media/$1'")

    }
}
