import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { DynamicTariffsGraphComponent } from "src/app/components/dynamic-tariffs-graph/dynamic-tariffs-graph.component";
import { ProductCardComponent } from "src/app/components/product-card/product-card.component";
import { BulletPoint } from "src/app/models/news-item.model";
import { Product } from "src/app/models/product.model";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";
import { ProductService } from "src/app/services/product.service";
import { UmbracoContent } from "src/app/models/umbraco-content.model";
import { UmbracoHelper } from "src/app/helpers/umbraco.helper";
import { Subscription } from "rxjs";
import { UmbracoService } from "src/app/services/umbraco.service";

@Component({
    selector: 'dynamic-tariffs',
    standalone: true,
    templateUrl: './dynamic-tariffs.page.html',
    styleUrls: ['./dynamic-tariffs.page.scss'],
    imports: [ButtonModule, CommonModule, DynamicTariffsGraphComponent, ProductCardComponent, DynamicHTMLModule, DataUdiDirective],
    providers: [ProductService, UmbracoService]
})
export class DynamicTariffsPage {
    htmlContent!: string;
    bulletPoints!: BulletPoint[];
    dynamicProduct!: Product;
    window = window;
    subscribers: Subscription[] = [];
    
    constructor(private productService: ProductService, private umbracoService: UmbracoService) {
        this.subscribers.push(this.productService.getAllProducts().subscribe((products: Product[]) => {
            this.dynamicProduct = products.find((product: Product) => product.name === 'EBEM Groen Dyn@mic') ?? new Product();
        }));
        this.subscribers.push(this.umbracoService.getDynamicTariffsPage().subscribe((content) => {
            this.htmlContent = UmbracoHelper.processContent(content[0].properties?.['bodyText']?.sections[0]?.rows[0]?.areas[0]?.controls[0]?.value);
            this.bulletPoints = UmbracoHelper.processContent(content[0].properties?.['bodyText']?.sections[0]?.rows[0]?.areas[0]?.controls[0]?.value).match(/<h[1-6] id=\"(.*)\">(.*)<\/h[1-6]>/gm)?.map((bulletPoint) => (<BulletPoint>{
                title: bulletPoint.match(/<h[1-6] id=\"(.*)\">(.*)<\/h[1-6]>/)?.[2] ?? '',
                href: bulletPoint.match(/<h[1-6] id=\"(.*)\">(.*)<\/h[1-6]>/)?.[1] ?? ''
            })) ?? [];
        })); //Replace with Umbraco content
    }
}