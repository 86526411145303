import { trigger, transition, style, animate } from '@angular/animations';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, booleanAttribute, ContentChildren, Output, Input, Optional, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import * as i1 from 'primeng/api';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import * as i3 from 'primeng/button';
import { ButtonModule } from 'primeng/button';
import { CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon } from 'primeng/icons';
import { Ripple } from 'primeng/ripple';
import { timer } from 'rxjs';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-messages {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: ${dt('message.content.gap')};
}

.p-message {
    border-radius: ${dt('message.border.radius')};
    outline-width: ${dt('message.border.width')};
    outline-style: solid;
}

.p-message-content {
    display: flex;
    align-items: center;
    padding: ${dt('message.content.padding')};
    height: 100%;
}

.p-message .p-message-content:has(:nth-child(1)) {
    gap: ${dt('message.content.gap')};
}

.p-message-icon {
    flex-shrink: 0;
}

.p-message-close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 0 0 0 auto;
    overflow: hidden;
    position: relative;
    width: ${dt('message.close.button.width')};
    height: ${dt('message.close.button.height')};
    border-radius: ${dt('message.close.button.border.radius')};
    background: transparent;
    transition: background ${dt('message.transition.duration')}, color ${dt('message.transition.duration')}, outline-color ${dt('message.transition.duration')}, box-shadow ${dt('message.transition.duration')}, opacity 0.3s;
    outline-color: transparent;
    color: inherit;
    padding: 0;
    border: none;
    cursor: pointer;
    user-select: none;
}

.p-message-close-icon {
    font-size: ${dt('message.close.icon.size')};
    width: ${dt('message.close.icon.size')};
    height: ${dt('message.close.icon.size')};
}

.p-message-close-button:focus-visible {
    outline-width: ${dt('message.close.button.focus.ring.width')};
    outline-style: ${dt('message.close.button.focus.ring.style')};
    outline-offset: ${dt('message.close.button.focus.ring.offset')};
}

.p-message-info {
    background: ${dt('message.info.background')};
    outline-color: ${dt('message.info.border.color')};
    color: ${dt('message.info.color')};
    box-shadow: ${dt('message.info.shadow')};
}

.p-message-info .p-message-close-button:focus-visible {
    outline-color: ${dt('message.info.close.button.focus.ring.color')};
    box-shadow: ${dt('message.info.close.button.focus.ring.shadow')};
}

.p-message-info .p-message-close-button:hover {
    background: ${dt('message.info.close.button.hover.background')};
}

.p-message-success {
    background: ${dt('message.success.background')};
    outline-color: ${dt('message.success.border.color')};
    color: ${dt('message.success.color')};
    box-shadow: ${dt('message.success.shadow')};
}

.p-message-success .p-message-close-button:focus-visible {
    outline-color: ${dt('message.success.close.button.focus.ring.color')};
    box-shadow: ${dt('message.success.close.button.focus.ring.shadow')};
}

.p-message-success .p-message-close-button:hover {
    background: ${dt('message.success.close.button.hover.background')};
}

.p-message-warn {
    background: ${dt('message.warn.background')};
    outline-color: ${dt('message.warn.border.color')};
    color: ${dt('message.warn.color')};
    box-shadow: ${dt('message.warn.shadow')};
}

.p-message-warn .p-message-close-button:focus-visible {
    outline-color: ${dt('message.warn.close.button.focus.ring.color')};
    box-shadow: ${dt('message.warn.close.button.focus.ring.shadow')};
}

.p-message-warn .p-message-close-button:hover {
    background: ${dt('message.warn.close.button.hover.background')};
}

.p-message-error {
    background: ${dt('message.error.background')};
    outline-color: ${dt('message.error.border.color')};
    color: ${dt('message.error.color')};
    box-shadow: ${dt('message.error.shadow')};
}

.p-message-error .p-message-close-button:focus-visible {
    outline-color: ${dt('message.error.close.button.focus.ring.color')};
    box-shadow: ${dt('message.error.close.button.focus.ring.shadow')};
}

.p-message-error .p-message-close-button:hover {
    background: ${dt('message.error.close.button.hover.background')};
}

.p-message-secondary {
    background: ${dt('message.secondary.background')};
    outline-color: ${dt('message.secondary.border.color')};
    color: ${dt('message.secondary.color')};
    box-shadow: ${dt('message.secondary.shadow')};
}

.p-message-secondary .p-message-close-button:focus-visible {
    outline-color: ${dt('message.secondary.close.button.focus.ring.color')};
    box-shadow: ${dt('message.secondary.close.button.focus.ring.shadow')};
}

.p-message-secondary .p-message-close-button:hover {
    background: ${dt('message.secondary.close.button.hover.background')};
}

.p-message-contrast {
    background: ${dt('message.contrast.background')};
    outline-color: ${dt('message.contrast.border.color')};
    color: ${dt('message.contrast.color')};
    box-shadow: ${dt('message.contrast.shadow')};
}

.p-message-contrast .p-message-close-button:focus-visible {
    outline-color: ${dt('message.contrast.close.button.focus.ring.color')};
    box-shadow: ${dt('message.contrast.close.button.focus.ring.shadow')};
}

.p-message-contrast .p-message-close-button:hover {
    background: ${dt('message.contrast.close.button.hover.background')};
}

.p-message-text {
    font-size: ${dt('message.text.font.size')};
    font-weight: ${dt('message.text.font.weight')};
}

.p-message-icon {
    font-size: ${dt('message.icon.size')};
    width: ${dt('message.icon.size')};
    height: ${dt('message.icon.size')};
}

.p-message-enter-from {
    opacity: 0;
}

.p-message-enter-active {
    transition: opacity 0.3s;
}

.p-message.p-message-leave-from {
    max-height: 1000px;
}

.p-message.p-message-leave-to {
    max-height: 0;
    opacity: 0;
    margin: 0;
}

.p-message-leave-active {
    overflow: hidden;
    transition: max-height 0.45s cubic-bezier(0, 1, 0, 1), opacity 0.3s, margin 0.3s;
}

.p-message-leave-active .p-message-close-button {
    opacity: 0;
}
/* For PrimeNG */
.p-messages .p-message.ng-animating {
    overflow: hidden;
}

.p-message-content > p-button[data-pc-section="closebutton"] {
    margin-left: auto;
}
`;
const classes = {
  root: ({
    instance
  }) => ({
    'p-message': true
  }),
  container: 'p-messages p-component',
  content: 'p-message-content',
  icon: 'p-message-icon',
  text: 'p-message-text',
  closeButton: 'p-message-close-button',
  closeIcon: 'p-message-close-icon'
};
class MessagesStyle extends BaseStyle {
  name = 'message';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: MessagesStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: MessagesStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: MessagesStyle,
  decorators: [{
    type: Injectable
  }]
});

/**
 * Messages is used to display alerts inline.
 * @group Components
 * @deprecated Use Message component instead.
 */
class Messages extends BaseComponent {
  messageService;
  /**
   * An array of messages to display.
   * @group Props
   */
  set value(messages) {
    this.messages = messages;
    this.startMessageLifes(this.messages);
  }
  /**
   * Defines if message box can be closed by the click icon.
   * @group Props
   */
  closable = true;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Whether displaying services messages are enabled.
   * @group Props
   */
  enableService = true;
  /**
   * Id to match the key of the message to enable scoping in service based messaging.
   * @group Props
   */
  key;
  /**
   * Whether displaying messages would be escaped or not.
   * @group Props
   */
  escape = true;
  /**
   * Severity level of the message.
   * @group Props
   */
  severity;
  /**
   * Transition options of the show animation.
   * @group Props
   */
  showTransitionOptions = '300ms ease-out';
  /**
   * Transition options of the hide animation.
   * @group Props
   */
  hideTransitionOptions = '200ms cubic-bezier(0.86, 0, 0.07, 1)';
  /**
   * This function is executed when the value changes.
   * @param {ToastMessageOptions[]} value - messages value.
   * @group Emits
   */
  valueChange = new EventEmitter();
  /**
   * This function is executed when a message is closed.
   * @param {ToastMessageOptions} value - Closed message.
   * @group Emits
   */
  onClose = new EventEmitter();
  messages;
  messageSubscription;
  clearSubscription;
  timerSubscriptions = [];
  contentTemplate;
  _componentStyle = inject(MessagesStyle);
  constructor(messageService) {
    super();
    this.messageService = messageService;
    console.log('Messages component is deprecated as of v18. Use Message component instead.');
  }
  templates;
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;
        default:
          this.contentTemplate = item.template;
          break;
      }
    });
    if (this.messageService && this.enableService && !this.contentTemplate) {
      this.messageSubscription = this.messageService.messageObserver.subscribe(messages => {
        if (messages) {
          if (!Array.isArray(messages)) {
            messages = [messages];
          }
          const filteredMessages = messages.filter(m => this.key === m.key);
          this.messages = this.messages ? [...this.messages, ...filteredMessages] : [...filteredMessages];
          this.startMessageLifes(filteredMessages);
          this.cd.markForCheck();
        }
      });
      this.clearSubscription = this.messageService.clearObserver.subscribe(key => {
        if (key) {
          if (this.key === key) {
            this.messages = null;
          }
        } else {
          this.messages = null;
        }
        this.cd.markForCheck();
      });
    }
  }
  hasMessages() {
    let parentEl = this.el.nativeElement.parentElement;
    if (parentEl && parentEl.offsetParent) {
      return this.contentTemplate != null || this.messages && this.messages.length > 0;
    }
    return false;
  }
  clear() {
    this.messages = [];
    this.valueChange.emit(this.messages);
  }
  removeMessage(i) {
    const removedMessage = this.messages[i];
    this.messages = this.messages?.filter((msg, index) => index !== i);
    removedMessage && this.onClose.emit(removedMessage);
    this.valueChange.emit(this.messages);
  }
  get icon() {
    const severity = this.severity || (this.hasMessages() ? this.messages[0].severity : null);
    if (this.hasMessages()) {
      switch (severity) {
        case 'success':
          return 'pi-check';
        case 'info':
          return 'pi-info-circle';
        case 'error':
        case 'danger':
          return 'pi-times';
        case 'warn':
          return 'pi-exclamation-triangle';
        default:
          return 'pi-info-circle';
      }
    }
    return null;
  }
  get closeAriaLabel() {
    return this.config.translation.aria ? this.config.translation.aria.close : undefined;
  }
  ngOnDestroy() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.clearSubscription) {
      this.clearSubscription.unsubscribe();
    }
    this.timerSubscriptions?.forEach(subscription => subscription.unsubscribe());
    super.ngOnDestroy();
  }
  startMessageLifes(messages) {
    messages?.forEach(message => message.life && this.startMessageLife(message));
  }
  startMessageLife(message) {
    const timerSubsctiption = timer(message.life).subscribe(() => {
      this.messages = this.messages?.filter(msgEl => msgEl !== message);
      this.timerSubscriptions = this.timerSubscriptions?.filter(timerEl => timerEl !== timerSubsctiption);
      this.valueChange.emit(this.messages);
      this.cd.markForCheck();
    });
    this.timerSubscriptions.push(timerSubsctiption);
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Messages,
    deps: [{
      token: i1.MessageService,
      optional: true
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "17.0.0",
    version: "19.1.7",
    type: Messages,
    isStandalone: false,
    selector: "p-messages",
    inputs: {
      value: "value",
      closable: ["closable", "closable", booleanAttribute],
      style: "style",
      styleClass: "styleClass",
      enableService: ["enableService", "enableService", booleanAttribute],
      key: "key",
      escape: ["escape", "escape", booleanAttribute],
      severity: "severity",
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions"
    },
    outputs: {
      valueChange: "valueChange",
      onClose: "onClose"
    },
    providers: [MessagesStyle],
    queries: [{
      propertyName: "templates",
      predicate: PrimeTemplate
    }],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <div [ngClass]="cx('container')" role="alert" [ngStyle]="style" [class]="styleClass" [attr.aria-atomic]="true" [attr.aria-live]="'assertive'" [attr.data-pc-name]="'message'">
            @if (contentTemplate) {
                <div [ngClass]="'p-message p-message-' + severity" role="alert">
                    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
                </div>
            } @else {
                <div
                    *ngFor="let msg of messages; let i = index"
                    [ngClass]="cx('root')"
                    [class]="'p-message-' + msg.severity"
                    role="alert"
                    [@messageAnimation]="{
                        value: 'visible',
                        params: {
                            showTransitionParams: showTransitionOptions,
                            hideTransitionParams: hideTransitionOptions
                        }
                    }"
                >
                    <div [ngClass]="cx('content')" [attr.data-pc-section]="'wrapper'" [attr.id]="msg.id || null">
                        @if (msg.icon) {
                            <span [ngClass]="cx('icon')" [class]="'pi ' + msg.icon" [attr.data-pc-section]="'icon'"> </span>
                        } @else {
                            <span [ngClass]="cx('icon')">
                                @switch (msg.severity) {
                                    @case ('success') {
                                        <CheckIcon [attr.data-pc-section]="'icon'" />
                                    }
                                    @case ('error') {
                                        <TimesCircleIcon [attr.data-pc-section]="'icon'" />
                                    }
                                    @case ('danger') {
                                        <TimesCircleIcon [attr.data-pc-section]="'icon'" />
                                    }
                                    @case ('warn') {
                                        <ExclamationTriangleIcon [attr.data-pc-section]="'icon'" />
                                    }
                                    @default {
                                        <InfoCircleIcon [attr.data-pc-section]="'icon'" />
                                    }
                                }
                            </span>
                        }
                        @if (escape) {
                            @if (msg.text) {
                                <span [ngClass]="cx('text')">{{ msg.text }}</span>
                            }
                            @if (msg.summary) {
                                <span [ngClass]="cx('text', 'p-message-summary')" [attr.data-pc-section]="'summary'">
                                    {{ msg.summary }}
                                </span>
                            }
                            @if (msg.detail) {
                                <span [ngClass]="cx('text', 'p-message-detail')" [attr.data-pc-section]="'detail'">
                                    {{ msg.detail }}
                                </span>
                            }
                        } @else {
                            <span *ngIf="msg.summary" class="p-message-summary" [innerHTML]="msg.summary" [attr.data-pc-section]="'summary'"></span>
                            <span *ngIf="msg.detail" class="p-message-detail" [innerHTML]="msg.detail" [attr.data-pc-section]="'detail'"></span>
                        }
                        <p-button *ngIf="closable && (msg.closable ?? true)" rounded text severity="secondary" [styleClass]="cx('closeButton')" (onClick)="removeMessage(i)" [ariaLabel]="closeAriaLabel" [attr.data-pc-section]="'closebutton'">
                            <TimesIcon [ngClass]="cx('closeIcon')" [attr.data-pc-section]="'closeicon'" />
                        </p-button>
                    </div>
                </div>
            }
        </div>
    `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i0.forwardRef(() => i2.NgClass),
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i2.NgForOf),
      selector: "[ngFor][ngForOf]",
      inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i2.NgIf),
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i2.NgTemplateOutlet),
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i2.NgStyle),
      selector: "[ngStyle]",
      inputs: ["ngStyle"]
    }, {
      kind: "component",
      type: i0.forwardRef(() => CheckIcon),
      selector: "CheckIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => InfoCircleIcon),
      selector: "InfoCircleIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => TimesCircleIcon),
      selector: "TimesCircleIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => ExclamationTriangleIcon),
      selector: "ExclamationTriangleIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => TimesIcon),
      selector: "TimesIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => i3.Button),
      selector: "p-button",
      inputs: ["type", "iconPos", "icon", "badge", "label", "disabled", "loading", "loadingIcon", "raised", "rounded", "text", "plain", "severity", "outlined", "link", "tabindex", "size", "variant", "style", "styleClass", "badgeClass", "badgeSeverity", "ariaLabel", "autofocus", "fluid", "buttonProps"],
      outputs: ["onClick", "onFocus", "onBlur"]
    }],
    animations: [trigger('messageAnimation', [transition(':enter', [style({
      opacity: 0,
      transform: 'translateY(-25%)'
    }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
      height: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      opacity: 0
    }))])])],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Messages,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-messages',
      standalone: false,
      template: `
        <div [ngClass]="cx('container')" role="alert" [ngStyle]="style" [class]="styleClass" [attr.aria-atomic]="true" [attr.aria-live]="'assertive'" [attr.data-pc-name]="'message'">
            @if (contentTemplate) {
                <div [ngClass]="'p-message p-message-' + severity" role="alert">
                    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
                </div>
            } @else {
                <div
                    *ngFor="let msg of messages; let i = index"
                    [ngClass]="cx('root')"
                    [class]="'p-message-' + msg.severity"
                    role="alert"
                    [@messageAnimation]="{
                        value: 'visible',
                        params: {
                            showTransitionParams: showTransitionOptions,
                            hideTransitionParams: hideTransitionOptions
                        }
                    }"
                >
                    <div [ngClass]="cx('content')" [attr.data-pc-section]="'wrapper'" [attr.id]="msg.id || null">
                        @if (msg.icon) {
                            <span [ngClass]="cx('icon')" [class]="'pi ' + msg.icon" [attr.data-pc-section]="'icon'"> </span>
                        } @else {
                            <span [ngClass]="cx('icon')">
                                @switch (msg.severity) {
                                    @case ('success') {
                                        <CheckIcon [attr.data-pc-section]="'icon'" />
                                    }
                                    @case ('error') {
                                        <TimesCircleIcon [attr.data-pc-section]="'icon'" />
                                    }
                                    @case ('danger') {
                                        <TimesCircleIcon [attr.data-pc-section]="'icon'" />
                                    }
                                    @case ('warn') {
                                        <ExclamationTriangleIcon [attr.data-pc-section]="'icon'" />
                                    }
                                    @default {
                                        <InfoCircleIcon [attr.data-pc-section]="'icon'" />
                                    }
                                }
                            </span>
                        }
                        @if (escape) {
                            @if (msg.text) {
                                <span [ngClass]="cx('text')">{{ msg.text }}</span>
                            }
                            @if (msg.summary) {
                                <span [ngClass]="cx('text', 'p-message-summary')" [attr.data-pc-section]="'summary'">
                                    {{ msg.summary }}
                                </span>
                            }
                            @if (msg.detail) {
                                <span [ngClass]="cx('text', 'p-message-detail')" [attr.data-pc-section]="'detail'">
                                    {{ msg.detail }}
                                </span>
                            }
                        } @else {
                            <span *ngIf="msg.summary" class="p-message-summary" [innerHTML]="msg.summary" [attr.data-pc-section]="'summary'"></span>
                            <span *ngIf="msg.detail" class="p-message-detail" [innerHTML]="msg.detail" [attr.data-pc-section]="'detail'"></span>
                        }
                        <p-button *ngIf="closable && (msg.closable ?? true)" rounded text severity="secondary" [styleClass]="cx('closeButton')" (onClick)="removeMessage(i)" [ariaLabel]="closeAriaLabel" [attr.data-pc-section]="'closebutton'">
                            <TimesIcon [ngClass]="cx('closeIcon')" [attr.data-pc-section]="'closeicon'" />
                        </p-button>
                    </div>
                </div>
            }
        </div>
    `,
      animations: [trigger('messageAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'translateY(-25%)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        height: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        opacity: 0
      }))])])],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [MessagesStyle]
    }]
  }],
  ctorParameters: () => [{
    type: i1.MessageService,
    decorators: [{
      type: Optional
    }]
  }],
  propDecorators: {
    value: [{
      type: Input
    }],
    closable: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    enableService: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    key: [{
      type: Input
    }],
    escape: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    severity: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }],
    onClose: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  }
});
class MessagesModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: MessagesModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: MessagesModule,
    declarations: [Messages],
    imports: [CommonModule, Ripple, CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon, ButtonModule, SharedModule],
    exports: [Messages, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: MessagesModule,
    imports: [CommonModule, CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon, ButtonModule, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: MessagesModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, Ripple, CheckIcon, InfoCircleIcon, TimesCircleIcon, ExclamationTriangleIcon, TimesIcon, ButtonModule, SharedModule],
      exports: [Messages, SharedModule],
      declarations: [Messages]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { Messages, MessagesModule, MessagesStyle };
