import { Component, Input, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AutoComplete, AutoCompleteModule, AutoCompleteSelectEvent } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { SearchService } from "src/app/services/search.service";
import { CardModule } from "primeng/card";
import { HighlightSearchPipe } from "src/app/pipes/hightlight.pipe";
import { IconField } from "primeng/iconfield";
import { InputIcon } from "primeng/inputicon";
import { PopoverModule } from 'primeng/popover';
import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { OverlayOptions } from "primeng/api";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";

@Component({
    selector: 'search-component',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    standalone: true,
    imports: [AutoCompleteModule, ButtonModule, CardModule, CommonModule, DataUdiDirective, DynamicHTMLModule, HighlightSearchPipe, IconField, InputIcon, InputTextModule, PopoverModule, FormsModule],
    providers: [HighlightSearchPipe, SearchService]
})
export class SearchComponent {
    searchQuery: string = "";
    searchResults: any[] = [];
    @Input() overlayOptions: OverlayOptions = {};
    @ViewChild('searchbar') searchbar!: AutoComplete;

    constructor(private router: Router, public searchService: SearchService) {
    }

    async search() {
        // Search for pages that contain the search query
        // and display them in the search
        // results list
        this.searchResults = await this.searchService.searchRoutes(this.searchQuery);
        console.log(this.searchResults);
    }

    onSelect(event: AutoCompleteSelectEvent) {
        var route = event.value.route;
        this.router.navigate([route.path]);
        this.searchbar.clear();
    }
}