<div class="backgroundLightEbemGreen"><p-tag>Grote bedrijven | &gt;100MWh per maand</p-tag></div>
<page-title-component [title]="'Ebem als betrouwbare energiepartner'" [buttonText]="'Klant worden'" [buttonRoute]="['/contact']"></page-title-component>
<image-component [imageSrc]="'img/solarpanels.png'" [text]="'Neem contact op met uw persoonlijke adviseur'" [showAvatar]="true" [avatarSrc]="'img/avatar.png'" [avatarName]="'Johan Hendrix'" [avatarTitle]="'Adviseur'" [showPostCode]="false" [buttonText]="'Neem contact op'" [buttonRoute]="['/contact']"></image-component>
<mission-statement-component></mission-statement-component>
<usp-component></usp-component>
<div class="pt-8 pb-8">
    <h2><img class="icon-small" [src]="'icons/contact-icon.png'" /> Neem contact op</h2>
    <div class="row">
        <div class="xl:col-6 lg:col-6 col-12">
            <contact-component></contact-component>
        </div>
        <div class="col-4 lg:col-4 col-12">
            <business-card-component [text]="'Onze accountmanager, Johan Hendrix, contacteert je om samen te bekijken wat we voor jouw bedrijf kunnen betekenen.'" [showAvatar]="true" [avatarSrc]="'img/avatar.png'" [avatarName]="'Johan Hendrix'" [avatarTitle]="'Adviseur'"></business-card-component>
        </div>
        <div class="col-2 lg:col-2 col-12"></div>
    </div>
</div>
<hr class="hrEbemGreen">
<news-component></news-component>