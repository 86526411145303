<div>
<h1>Betaling</h1>

<h3>Facturatie</h3>
<div class="row">

    <p-inputgroup [ngClass]="inputPaymentDetailsInvoiceModalityValid == null ||  inputPaymentDetailsInvoiceModalityValid == undefined ? '' : inputPaymentDetailsInvoiceModalityValid === true ? 'ng-valid' : inputPaymentDetailsInvoiceModalityValid === false ? 'ng-invalid' : ''" (click)="paymentDetails.invoiceModality = InvoiceModality['Per e-mail']; validateInvoiceModalityInput();">
        <div class="radio-left"><p-radio-button (click)="validateInvoiceModalityInput()" [ngClass]="inputPaymentDetailsInvoiceModalityValid == null ||  inputPaymentDetailsInvoiceModalityValid == undefined ? '' : inputPaymentDetailsInvoiceModalityValid === true ? 'ng-valid' : inputPaymentDetailsInvoiceModalityValid === false ? 'ng-invalid' : ''" [(ngModel)]="paymentDetails.invoiceModality" [value]="InvoiceModality['Per e-mail']"></p-radio-button><label>&nbsp;&nbsp;&nbsp;Facturen ontvangen per <strong>e-mail</strong></label></div>
    </p-inputgroup>
    <p-inputgroup [ngClass]="inputPaymentDetailsInvoiceModalityValid == null ||  inputPaymentDetailsInvoiceModalityValid == undefined ? '' : inputPaymentDetailsInvoiceModalityValid === true ? 'ng-valid' : inputPaymentDetailsInvoiceModalityValid === false ? 'ng-invalid' : ''" [style]="electricityType == ElectricityType['EBEM Groen B@sic +'] || gasType == GasType['EBEM G@s +'] ? {color: 'lightgray'} : {}" (click)="(electricityType == ElectricityType['EBEM Groen B@sic +'] || gasType == GasType['EBEM G@s +']) ? null : paymentDetails.invoiceModality = InvoiceModality['Per post']; validateInvoiceModalityInput();">
        <div class="radio-left"><p-radio-button (click)="validateInvoiceModalityInput()" [ngClass]="inputPaymentDetailsInvoiceModalityValid == null ||  inputPaymentDetailsInvoiceModalityValid == undefined ? '' : inputPaymentDetailsInvoiceModalityValid === true ? 'ng-valid' : inputPaymentDetailsInvoiceModalityValid === false ? 'ng-invalid' : ''" [disabled]="electricityType == ElectricityType['EBEM Groen B@sic +'] || gasType == GasType['EBEM G@s +']" [(ngModel)]="paymentDetails.invoiceModality" [value]="InvoiceModality['Per post']"></p-radio-button><label>&nbsp;&nbsp;&nbsp;Facturen ontvangen per <strong>post</strong></label></div>
    </p-inputgroup>

    <p>Je koos
        <span *ngIf="electricityType != 0">voor {{ElectricityType[electricityType]}} {{ElectricityTypeSimulation[electricityType]}}</span>
        <span *ngIf="electricityType != 0 && gasType != 0">&nbsp;en</span>
        <span *ngIf="gasType != 0">&nbsp;voor {{GasType[gasType]}} {{GasTypeSimulation[gasType]}}</span>

        <span *ngIf="electricityType == ElectricityType['EBEM Groen B@sic +'] || gasType == GasType['EBEM G@s +']">, dit is één van onze online producten waarbij je facturen sowieso per e-mail worden verzonden.</span>
    </p>
    <div class="xl:col-12 lg:col-12 col-12">
        <p-card class="danger no-padding" *ngIf="showErrorNoInvoiceModalityChosen">Gelieve een keuze te maken</p-card>
    </div>
</div>

<h3>Afrekening</h3>
<div class="row">  
    <p-inputgroup [ngClass]="inputPaymentDetailsBillingFrequencyValid == null ||  inputPaymentDetailsBillingFrequencyValid == undefined ? '' : inputPaymentDetailsBillingFrequencyValid === true ? 'ng-valid' : inputPaymentDetailsBillingFrequencyValid === false ? 'ng-invalid' : ''" (click)="paymentDetails.billingFrequency = BillingFrequency['Maandelijks']; validateBillingFrequencyInput();">
        <div class="radio-left"><p-radio-button (click)="validateBillingFrequencyInput()" [ngClass]="inputPaymentDetailsBillingFrequencyValid == null ||  inputPaymentDetailsBillingFrequencyValid == undefined ? '' : inputPaymentDetailsBillingFrequencyValid === true ? 'ng-valid' : inputPaymentDetailsBillingFrequencyValid === false ? 'ng-invalid' : ''" [(ngModel)]="paymentDetails.billingFrequency" [value]="BillingFrequency['Maandelijks']"></p-radio-button><label>&nbsp;&nbsp;&nbsp;Ik wil geen voorschotten maar een <strong>maandelijkse afrekening</strong></label></div>
    </p-inputgroup>
    <p-inputgroup [ngClass]="inputPaymentDetailsBillingFrequencyValid == null ||  inputPaymentDetailsBillingFrequencyValid == undefined ? '' : inputPaymentDetailsBillingFrequencyValid === true ? 'ng-valid' : inputPaymentDetailsBillingFrequencyValid === false ? 'ng-invalid' : ''" [style]="electricityType == ElectricityType['EBEM Groen Dyn@mic'] ? {color: 'lightgray'} : {}" (click)="electricityType === ElectricityType['EBEM Groen Dyn@mic'] ? null : paymentDetails.billingFrequency = BillingFrequency['Jaarlijks']; validateBillingFrequencyInput();">
        <div class="radio-left"><p-radio-button (click)="validateBillingFrequencyInput()" [ngClass]="inputPaymentDetailsBillingFrequencyValid == null ||  inputPaymentDetailsBillingFrequencyValid == undefined ? '' : inputPaymentDetailsBillingFrequencyValid === true ? 'ng-valid' : inputPaymentDetailsBillingFrequencyValid === false ? 'ng-invalid' : ''" [(ngModel)]="paymentDetails.billingFrequency" [disabled]="electricityType == ElectricityType['EBEM Groen Dyn@mic']" [value]="BillingFrequency['Jaarlijks']"></p-radio-button><label>&nbsp;&nbsp;&nbsp;Ik wil <strong>maandelijkse voorschotten</strong> en een jaarlijkse eindafrekening</label></div>
    </p-inputgroup>
    <div class="xl:col-12 lg:col-12 col-12">
        <p-card class="danger no-padding" *ngIf="showErrorNoBillingFrequencyChosen">Gelieve een keuze te maken</p-card>
    </div>
</div>

<h3>Hoe wil je betalen?</h3>
<div class="row">
    <p-inputgroup [ngClass]="inputPaymentDetailsPaymentPreferenceValid == null ||  inputPaymentDetailsPaymentPreferenceValid == undefined ? '' : inputPaymentDetailsPaymentPreferenceValid === true ? 'ng-valid' : inputPaymentDetailsPaymentPreferenceValid === false ? 'ng-invalid' : ''" (click)="paymentDetails.paymentPreference = PaymentPreference['Ik verkies domicilliëring']; validatePaymentPreferenceInput();">
        <div class="radio-left"><p-radio-button (click)="validatePaymentPreferenceInput()" [ngClass]="inputPaymentDetailsPaymentPreferenceValid == null ||  inputPaymentDetailsPaymentPreferenceValid == undefined ? '' : inputPaymentDetailsPaymentPreferenceValid === true ? 'ng-valid' : inputPaymentDetailsPaymentPreferenceValid === false ? 'ng-invalid' : ''" [(ngModel)]="paymentDetails.paymentPreference" [value]="PaymentPreference['Ik verkies domicilliëring']"></p-radio-button><label>&nbsp;&nbsp;&nbsp;Ik verkies <strong>domicilliëring</strong></label></div>
    </p-inputgroup>
    <p-inputgroup [ngClass]="inputPaymentDetailsPaymentPreferenceValid == null ||  inputPaymentDetailsPaymentPreferenceValid == undefined ? '' : inputPaymentDetailsPaymentPreferenceValid === true ? 'ng-valid' : inputPaymentDetailsPaymentPreferenceValid === false ? 'ng-invalid' : ''" (click)="paymentDetails.paymentPreference = PaymentPreference['Ik verkies losse overschrijvingen']; validatePaymentPreferenceInput();">
        <div class="radio-left"><p-radio-button (click)="validatePaymentPreferenceInput()" [ngClass]="inputPaymentDetailsPaymentPreferenceValid == null ||  inputPaymentDetailsPaymentPreferenceValid == undefined ? '' : inputPaymentDetailsPaymentPreferenceValid === true ? 'ng-valid' : inputPaymentDetailsPaymentPreferenceValid === false ? 'ng-invalid' : ''" [(ngModel)]="paymentDetails.paymentPreference" [value]="PaymentPreference['Ik verkies losse overschrijvingen']"></p-radio-button><label>&nbsp;&nbsp;&nbsp;Ik verkies <strong>losse overschrijvingen</strong></label></div>
    </p-inputgroup>
    <div class="xl:col-12 lg:col-12 col-12">
        <p-card class="danger no-padding" *ngIf="showErrorNoPaymentPreferenceChosen">Gelieve een keuze te maken</p-card>
    </div>
</div>

@if (paymentDetails.paymentPreference == 0 || paymentDetails.paymentPreference == undefined || paymentDetails.paymentPreference == null || paymentDetails.paymentPreference == PaymentPreference['Ik verkies domicilliëring']) {
    <h3>Gegevens voor domicilliëring</h3>

    <div class="grid fluid">
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <label for="iban">IBAN-Nummer</label>
            </div>
            <div class="xl:col-12 lg:col-12 col-12">
                <input (input)="validateIbanInput()" [ngClass]="inputPaymentDetailsIbanValid == null ||  inputPaymentDetailsIbanValid == undefined ? '' : inputPaymentDetailsIbanValid === true ? 'ng-valid' : inputPaymentDetailsIbanValid === false ? 'ng-invalid' : ''" type="text" name="iban" placeholder="BE" [(ngModel)]="paymentDetails.iban" pInputText  >
                <p-card class="danger no-padding" *ngIf="showErrorNoIbanFilledIn">IBAN nummer is een verplicht veld</p-card>
                <p-card class="danger no-padding" *ngIf="showErrorInvalidIban">IBAN nummer is niet geldig</p-card>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <label for="iban">BIC-Nummer</label>
            </div>
            <div class="xl:col-12 lg:col-12 col-12">
                <input (input)="validateBicInput()" [ngClass]="inputPaymentDetailsBicValid == null ||  inputPaymentDetailsBicValid == undefined ? '' : inputPaymentDetailsBicValid === true ? 'ng-valid' : inputPaymentDetailsBicValid === false ? 'ng-invalid' : ''" type="text" name="bic" placeholder="Aa" [(ngModel)]="paymentDetails.bic" pInputText  >
                <p-card class="danger no-padding" *ngIf="showErrorNoBicFilledIn">BIC nummer is een verplicht veld</p-card>
                <p-card class="danger no-padding" *ngIf="showErrorInvalidBic">BIC nummer is niet geldig</p-card>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-1 lg:col-1 col-12">
                <p-checkbox [(ngModel)]="paymentDetails.checkingAccountOnSomeoneElsesName" [binary]="true"></p-checkbox>
            </div>
            <div class="xl:col-11 lg:col-11 col-12">
                <label for="checkingAccountOnSomeoneElsesName">Deze bankrekening staat op naam van iemand anders</label>
            </div>
        </div>
        @if (paymentDetails.checkingAccountOnSomeoneElsesName) {
            <h3>Persoonlijke gegevens kaarthouder</h3>
            <div class="row">
                <div class="xl:col-12 lg:col-12 col-12">
                    <label for="gender">Aanspreking</label>
                </div>
                <div class="xl:col-12 lg:col-12 col-12">
                    <p-select fluid="true" [options]="Gender | enumKeyValue" [ngClass]="inputGenderValid == null ||  inputGenderValid == undefined ? 'ng-pristine' : inputGenderValid === true ? 'ng-valid ng-dirty' : inputGenderValid === false ? 'ng-invalid ng-dirty' : ''" (onChange)="validateGenderInput()" [(ngModel)]="paymentDetails.checkingAccountHolder.gender">
                        <ng-template let-option #selectedItem>
                            <div class="flex items-center gap-2">
                                <div>{{ option.key }}</div>
                            </div>
                        </ng-template>
                        <ng-template #item let-option>
                            {{option.key}}
                        </ng-template>
                    </p-select>
                    <p-card class="danger no-padding" *ngIf="showErrorNoGenderChosen">Gelieve een keuze te maken</p-card>
                </div>
            </div>
            <div class="row">
                <div class="xl:col-12 lg:col-12 col-12">
                    <label for="firstName">Voornaam</label>
                </div>
                <div class="xl:col-12 lg:col-12 col-12">
                    <input type="text" name="firstName" placeholder="Aa" [ngClass]="inputFirstNameValid == null ||  inputFirstNameValid == undefined ? '' : inputFirstNameValid === true ? 'ng-valid' : inputFirstNameValid === false ? 'ng-invalid' : ''" (input)="validateFirstNameInput()" [(ngModel)]="paymentDetails.checkingAccountHolder.firstName" pInputText >
                    <p-card class="danger no-padding" *ngIf="showErrorNoFirstNameFilledIn">Voornaam is een verplicht veld indien "Deze bankrekening staat op naam van iemand anders" is aangevinkt</p-card>
                </div>
            </div>
            <div class="row">
                <div class="xl:col-12 lg:col-12 col-12">
        
                    <label for="lastName">Achternaam</label>
                </div>
                <div class="xl:col-12 lg:col-12 col-12">
                    <input type="text" name="lastName" placeholder="Aa" [ngClass]="inputLastNameValid == null ||  inputLastNameValid == undefined ? '' : inputLastNameValid === true ? 'ng-valid' : inputLastNameValid === false ? 'ng-invalid' : ''" (input)="validateLastNameInput()" [(ngModel)]="paymentDetails.checkingAccountHolder.lastName" pInputText >
                    <p-card class="danger no-padding" *ngIf="showErrorNoLastNameFilledIn">Achternaam is een verplicht veld indien "Deze bankrekening staat op naam van iemand anders" is aangevinkt</p-card>
                </div>
            </div>
            <h3>Contactgegevens kaarthouder</h3>
            <div class="row">
                <div class="xl:col-12 lg:col-12 col-12">
                    <label for="telephone">Telefoon</label>
                </div>
                <div class="xl:col-12 lg:col-12 col-12">
                    <input type="tel" name="telephone" placeholder="+32" [ngClass]="inputTelephoneValid == null ||  inputTelephoneValid == undefined ? '' : inputTelephoneValid === true ? 'ng-valid' : inputTelephoneValid === false ? 'ng-invalid' : ''" (input)="validateTelephoneNumberInput()" [(ngModel)]="paymentDetails.checkingAccountHolder.telephone" pInputText >
                    <p-card class="danger no-padding" *ngIf="showErrorNoTelephoneFilledIn">Telefoon is een verplicht veld indien "Deze bankrekening staat op naam van iemand anders" is aangevinkt</p-card>
                    <p-card class="danger no-padding" *ngIf="showErrorTelephoneWrongFormat">Telefoonnummer heeft niet het juiste formaat: landcode (bijvoorbeeld +31 voor Nederland en +32 voor België) gevolgd door 8 cijfers (vast telefoonnummer) of 9 cijfers (gsmnummer)</p-card>
                </div>
            </div>
            <div class="row">
                <div class="xl:col-12 lg:col-12 col-12">
                    <label for="email">E-mail</label>
                </div>
                <div class="xl:col-12 lg:col-12 col-12">
                    <input type="email" name="email" placeholder="voorbeeld@domein.com" [ngClass]="inputEmailValid == null ||  inputEmailValid == undefined ? '' : inputEmailValid === true ? 'ng-valid' : inputEmailValid === false ? 'ng-invalid' : ''" (input)="validateEmailInput()" [(ngModel)]="paymentDetails.checkingAccountHolder.email" pInputText >
                    <p-card class="danger no-padding" *ngIf="showErrorNoEmailFilledIn">Email is een verplicht veld indien "Deze bankrekening staat op naam van iemand anders" is aangevinkt</p-card>
                    <p-card class="danger no-padding" *ngIf="showErrorEmailWrongFormat">Email heeft niet het juiste formaat: tekenreeks beginnend met een letter of een cijfer gevolgd door een &#64; gevolgd door een emailprovider en een webextensie (.com, .be, ...) </p-card>    
                
                </div>
            </div>
            <h3>Adresgegevens kaarthouder</h3> 
            <div class="row">
                <div class="xl:col-8 lg:col-8 col-12">
                    <label for="streetName">Straatnaam</label>
                </div>
                <div class="xl:col-4 lg:col-4 col-12">
                    <label for="houseNumber">Huisnr. (+ busnr.)</label>
                </div>
            </div>
            <div class="row">
                <div class="xl:col-8 lg:col-8 col-12">
                    <input type="text" name="streetName" [ngClass]="inputStreetNameValid == null ||  inputStreetNameValid == undefined ? '' : inputStreetNameValid === true ? 'ng-valid' : inputStreetNameValid === false ? 'ng-invalid' : ''" (input)="validateStreetNameInput()" placeholder="Aa" [(ngModel)]="paymentDetails.checkingAccountHolder.streetName" pInputText >
                    <p-card class="danger no-padding" *ngIf="showErrorNoStreetNameFilledIn">Straatnaam is een verplicht veld indien "Deze bankrekening staat op naam van iemand anders" is aangevinkt</p-card>    
                </div>
                <div class="xl:col-4 lg:col-4 col-12">
                    <input name="houseNumber" [ngClass]="inputHouseNumberValid == null ||  inputHouseNumberValid == undefined ? '' : inputHouseNumberValid === true ? 'ng-valid' : inputHouseNumberValid === false ? 'ng-invalid' : ''" (input)="validateHouseNumberInput()" placeholder="123A" [(ngModel)]="paymentDetails.checkingAccountHolder.houseNumber" pInputText >
                    <p-card class="danger no-padding" *ngIf="showErrorNoHouseNumberFilledIn">Huisnummer is een verplicht veld indien "Deze bankrekening staat op naam van iemand anders" is aangevinkt</p-card>  
                </div>
            </div>
            <div class="row">
                <div class="xl:col-4 lg:col-4 col-12">
                    <label for="postalCode">Postcode</label>
                </div>
                <div class="xl:col-8 lg:col-8 col-12"> 
                    <label for="location">Plaats</label>
                </div>
            </div>
            <div class="row">
                @if (paymentDetails.checkingAccountHolder.country == 'BE') {
                    <div class="xl:col-4 lg:col-4 col-12">
                        <p-inputnumber name="postalCode" [ngClass]="inputPostalCodeValid == null ||  inputPostalCodeValid == undefined ? '' : inputPostalCodeValid === true ? 'ng-valid' : inputPostalCodeValid === false ? 'ng-invalid' : ''" placeholder="1234" [minFractionDigits]="0" [maxFractionDigits]="0" mode="decimal" [useGrouping]="false" [(ngModel)]="paymentDetails.checkingAccountHolder.postalCode" (onInput)="paymentDetails.checkingAccountHolder.location = getNameFromPostalCode(paymentDetails.checkingAccountHolder.postalCode); validatePostalCodeInput(); validateLocationInput();" ></p-inputnumber>
                        <p-card class="danger no-padding" *ngIf="showErrorNoPostalCodeFilledIn">Postcode van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>    
                    </div>
                    <div class="col-12 xl:hidden lg:hidden">
                        <label for="location">Plaats</label>
                    </div>
                    <div class="xl:col-8 lg:col-8 col-12">
                        <p-select [fluid]="true" [virtualScroll]="true"  [virtualScrollItemSize]="30" [filter]="true" [filterBy]="'code'" [filterValue]="paymentDetails.checkingAccountHolder.postalCode?.toString()" [options]="PostalCodes" [optionValue]="'name'" name="Location" [ngClass]="inputLocationValid == null ||  inputLocationValid == undefined ? '' : inputLocationValid === true ? 'ng-valid' : inputLocationValid === false ? 'ng-invalid' : ''" (onChange)="paymentDetails.checkingAccountHolder.postalCode = getPostalCodeFromName($event.value); validateLocationInput();" placeholder="Aa" [(ngModel)]="paymentDetails.checkingAccountHolder.location">
                            <ng-template #selectedItem let-selectedOption>
                                <div class="flex items-center gap-2" *ngIf="selectedOption">
                                    <div>{{ selectedOption.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template #item let-option>
                                {{ option.name }}
                            </ng-template>
                        </p-select>
                        <p-card class="danger no-padding" *ngIf="showErrorNoLocationFilledIn">Plaats van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>  
                    </div>
                } @else {
                    <div class="xl:col-4 lg:col-4 col-12">
                        <input type="text" name="postalCode" [ngClass]="inputPostalCodeValid == null ||  inputPostalCodeValid == undefined ? '' : inputPostalCodeValid === true ? 'ng-valid' : inputPostalCodeValid === false ? 'ng-invalid' : ''" placeholder="Aa" (input)="validatePostalCodeInput(); validateLocationInput();" [(ngModel)]="paymentDetails.checkingAccountHolder.postalCode" pInputText>
                        <p-card class="danger no-padding" *ngIf="showErrorNoPostalCodeFilledIn">Postcode van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>    
                    </div>
                    <div class="col-12 xl:hidden lg:hidden">
                        <label for="location">Plaats</label>
                    </div>
                    <div class="xl:col-8 lg:col-8 col-12">
                        <input type="text" name="Location" [ngClass]="inputLocationValid == null ||  inputLocationValid == undefined ? '' : inputLocationValid === true ? 'ng-valid' : inputLocationValid === false ? 'ng-invalid' : ''" (input)="validateLocationInput()" placeholder="Aa" [(ngModel)]="paymentDetails.checkingAccountHolder.location" pInputText >
                        <p-card class="danger no-padding" *ngIf="showErrorNoLocationFilledIn">Plaats van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>  
                    </div>
                }
              </div>
              <div class="row">
                <div class="xl:col-4 lg:col-4 col-12">
                    <label for="location">Land</label>
                </div>
                <div class="xl:col-8 lg:col-8 xl:flex lg:flex md:hidden sm:hidden">
                </div>
            </div>
            <div class="row">
                <div class="xl:col-4 lg:col-4 col-12">
                    <p-select [fluid]="true" [options]="Countries | enumKeyValue" [optionValue]="'key'" [optionLabel]="'value'" name="invoicingAddressCountry" [ngClass]="inputCountryValid == null ||  inputCountryValid == undefined ? '' : inputCountryValid === true ? 'ng-valid' : inputCountryValid === false ? 'ng-invalid' : ''" (input)="validateCountryInput()" placeholder="Aa" [(ngModel)]="paymentDetails.checkingAccountHolder.country" />
                    <p-card class="danger no-padding" *ngIf="showErrorNoCountryFilledIn">Land van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>  
                </div>
                <div class="xl:col-8 lg:col-8 xl:flex lg:flex md:hidden sm:hidden">
                </div>
            </div>
        }
    </div>
}
@if (paymentDetails.paymentPreference == PaymentPreference['Ik verkies losse overschrijvingen']) {
    <h3>Rekeningnummer</h3>
    <div class="grid fluid">
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <label for="iban">IBAN-Nummer</label>
            </div>
            <div class="xl:col-12 lg:col-12 col-12">
                <input (input)="validateIbanInput()" [ngClass]="inputPaymentDetailsBicValid == null ||  inputPaymentDetailsBicValid == undefined ? '' : inputPaymentDetailsBicValid === true ? 'ng-valid' : inputPaymentDetailsBicValid === false ? 'ng-invalid' : ''" type="text" name="iban" placeholder="BE" [(ngModel)]="paymentDetails.iban" pInputText >
                <p-card class="danger no-padding" *ngIf="showErrorNoIbanFilledIn">IBAN nummer is een verplicht veld</p-card>
                <p-card class="danger no-padding" *ngIf="showErrorInvalidIban">IBAN nummer is niet geldig</p-card>
            </div>
        </div>
    </div>
}
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="80" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/customer-wizard/self-employed', 'step-3']" [state]="{ 'gasType': gasType, 'electricityType': electricityType, 'company': company, 'contract': contract, 'paymentDetails': paymentDetails, 'postalCode': postalCode, 'priceSimulationInput': priceSimulationInput }">Terug</p-button>
    </div>
    <div class="col-6">
        <p-button class="float-right" (click)="validateForm() ? this.router.navigate(['/customer-wizard/self-employed', 'step-5'], { state: { 'gasType': gasType, 'electricityType': electricityType, 'company': company, 'contract': contract, 'paymentDetails': paymentDetails, 'postalCode': postalCode, 'priceSimulationInput': priceSimulationInput } }) : null">Volgende</p-button>
    </div>
</div>
</div>