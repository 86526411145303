import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, signal, inject, booleanAttribute, ContentChildren, ContentChild, ViewChildren, ViewChild, Output, Input, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import * as i2 from '@angular/router';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { resolve, getAttribute, focus, findSingle, getWidth, getOffset } from '@primeuix/utils';
import { SharedModule, PrimeTemplate } from 'primeng/api';
import * as i4 from 'primeng/badge';
import { BadgeModule } from 'primeng/badge';
import { BaseComponent } from 'primeng/basecomponent';
import { ChevronLeftIcon, ChevronRightIcon } from 'primeng/icons';
import { Ripple } from 'primeng/ripple';
import * as i3 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `

/* For PrimeNG */
.p-tabmenu.p-component {
    display: flex;
    flex-direction: column;
}

.p-tabmenu-nav-container {
    display: flex;
    position: relative;
}

.p-tabmenu.p-tabmenu-scrollable .p-tabmenu-nav-container {
    overflow: hidden;
}

.p-tabmenu-nav-content {
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    overscroll-behavior: contain auto;
}

.p-tabmenu-nav {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    background: ${dt('tabs.tablist.background')};
    border-style: solid;
    border-color: ${dt('tabs.tablist.border.color')};
    border-width: ${dt('tabs.tablist.border.width')};
}

.p-tabmenuitem {
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    border-style: solid;
    background: ${dt('tabs.tab.background')};
    border-width: ${dt('tabs.tab.border.width')};
    border-color: ${dt('tabs.tab.border.color')};
    padding: ${dt('tabs.tab.padding')};
    transition: background ${dt('tabs.transition.duration')}, border-color ${dt('tabs.transition.duration')}, color ${dt('tabs.transition.duration')}, outline-color ${dt('tabs.transition.duration')}, box-shadow ${dt('tabs.transition.duration')};
    margin: ${dt('tabs.tab.margin')};
    outline-color: transparent;
}

.p-tabmenuitem > .p-menuitem-link {
    white-space: nowrap;
    user-select: none;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    gap: ${dt('tabs.tab.gap')};
    color: ${dt('tabs.tab.color')};
    font-weight: ${dt('tabs.tab.font.weight')};
}

.p-tabmenuitem.p-tabmenuitem-active .p-menuitem-link {
    color: ${dt('tabs.tab.active.color')};
}

.p-tabmenuitem:not(.p-disabled):focus-visible {
    z-index: 1;
    box-shadow: ${dt('tabs.tab.focus.ring.shadow')};
    outline: ${dt('tabs.tab.focus.ring.width')} ${dt('tabs.tab.focus.ring.style')} ${dt('tabs.tab.focus.ring.color')};
    outline-offset: ${dt('tabs.tab.focus.ring.offset')};
}

.p-tabmenuitem:not(.p-tabmenuitem-active):not(.p-disabled):hover {
    background: ${dt('tabs.tab.hover.background')};
    border-color: ${dt('tabs.tab.hover.border.color')};
    color: ${dt('tabs.tab.hover.color')};
}

.p-tabmenuitem-active {
    background: ${dt('tabs.tab.active.background')};
    border-color: ${dt('tabs.tab.active.border.color')};
    color: ${dt('tabs.tab.active.color')};
}

.p-tabmenuitem-active-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: ${dt('tabs.active.bar.bottom')};
    height: ${dt('tabs.active.bar.height')};
    background: ${dt('tabs.active.bar.background')};
    transition: 250ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-tabmenu-nav-button {
    all: unset;
    position: absolute !important;
    flex-shrink: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${dt('tabs.nav.button.background')};
    color: ${dt('tabs.nav.button.color')};
    width: ${dt('tabs.nav.button.width')};
    transition: color ${dt('tabs.transition.duration')}, outline-color ${dt('tabs.transition.duration')}, box-shadow ${dt('tabs.transition.duration')};
    box-shadow: ${dt('tabs.nav.button.shadow')};
    outline-color: transparent;
    cursor: pointer;
}

.p-tabmenu-nav-button:focus-visible {
    z-index: 1;
    box-shadow: ${dt('tabs.nav.button.focus.ring.shadow')};
    outline: ${dt('tabs.nav.button.focus.ring.width')} ${dt('tabs.nav.button.focus.ring.style')} ${dt('tabs.nav.button.focus.ring.color')};
    outline-offset: ${dt('tabs.nav.button.focus.ring.offset')};
}

.p-tabmenu-nav-button:hover {
    color: ${dt('tabs.nav.button.hover.color')};
}

.p-tabmenu-nav-prev-button {
    left: 0;
}

.p-tabmenu-nav-next-button {
    right: 0;
}`;
class TabMenuStyle extends BaseStyle {
  name = 'tabs';
  theme = theme;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabMenuStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabMenuStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TabMenuStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * TabMenu is a navigation component that displays items as tab headers. Example below uses nested routes with TabMenu.
 *
 * [Live Demo](https://www.primeng.org/tabmenu/)
 *
 * @module tabmenustyle
 *
 */
var TabMenuClasses;
(function (TabMenuClasses) {
  /**
   * Class name of the root element
   */
  TabMenuClasses["root"] = "p-tabmenu";
  /**
   * Class name of the tablist element
   */
  TabMenuClasses["tablist"] = "p-tabmenu-tablist";
  /**
   * Class name of the item element
   */
  TabMenuClasses["item"] = "p-tabmenu-item";
  /**
   * Class name of the item link element
   */
  TabMenuClasses["itemLink"] = "p-tabmenu-item-link";
  /**
   * Class name of the item icon element
   */
  TabMenuClasses["itemIcon"] = "p-tabmenu-item-icon";
  /**
   * Class name of the item label element
   */
  TabMenuClasses["itemLabel"] = "p-tabmenu-item-label";
  /**
   * Class name of the inkbar element
   */
  TabMenuClasses["inkbar"] = "p-tabmenu-ink-bar";
})(TabMenuClasses || (TabMenuClasses = {}));

/**
 * TabMenu is a navigation component that displays items as tab headers.
 * @group Components
 */
class TabMenu extends BaseComponent {
  /**
   * An array of menuitems.
   * @group Props
   */
  set model(value) {
    this._model = value;
    this._focusableItems = (this._model || []).reduce((result, item) => {
      result.push(item);
      return result;
    }, []);
  }
  get model() {
    return this._model;
  }
  /**
   * Defines the default active menuitem
   * @group Props
   */
  set activeItem(value) {
    this._activeItem = value;
    this.activeItemChange.emit(value);
    this.tabChanged = true;
  }
  get activeItem() {
    return this._activeItem;
  }
  /**
   * When enabled displays buttons at each side of the tab headers to scroll the tab list.
   * @group Props
   */
  scrollable;
  /**
   * Defines if popup mode enabled.
   */
  popup;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Defines a string value that labels an interactive element.
   * @group Props
   */
  ariaLabel;
  /**
   * Identifier of the underlying input element.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Event fired when a tab is selected.
   * @param {MenuItem} item - Menu item.
   * @group Emits
   */
  activeItemChange = new EventEmitter();
  content;
  navbar;
  inkbar;
  prevBtn;
  nextBtn;
  tabLink;
  tab;
  /**
   * Template of the menu item.
   * @group Templates
   */
  itemTemplate;
  /**
   * Template of the previous icon.
   * @group Templates
   */
  previousIconTemplate;
  /**
   * Template of the next icon.
   * @group Templates
   */
  nextIconTemplate;
  templates;
  _itemTemplate;
  _nextIconTemplate;
  _previousIconTemplate;
  tabChanged;
  backwardIsDisabled = true;
  forwardIsDisabled = false;
  timerIdForInitialAutoScroll = null;
  _focusableItems;
  _model;
  _activeItem;
  focusedItemInfo = signal(null);
  router = inject(Router);
  route = inject(ActivatedRoute);
  _componentStyle = inject(TabMenuStyle);
  get focusableItems() {
    if (!this._focusableItems || !this._focusableItems.length) {
      this._focusableItems = (this.model || []).reduce((result, item) => {
        result.push(item);
        return result;
      }, []);
    }
    return this._focusableItems;
  }
  constructor() {
    super();
    console.log('TabMenu is deprecated as of v18. Use tabs component instead https://primeng.org/tabs#tabmenu');
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (isPlatformBrowser(this.platformId)) {
      this.updateInkBar();
      this.initAutoScrollForActiveItem();
      this.initButtonState();
    }
  }
  ngAfterViewChecked() {
    if (isPlatformBrowser(this.platformId)) {
      this.updateInkBar();
      this.tabChanged = false;
    }
  }
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'item':
          this._itemTemplate = item.template;
          break;
        case 'nexticon':
          this._nextIconTemplate = item.template;
          break;
        case 'previousicon':
          this._previousIconTemplate = item.template;
          break;
        default:
          this._itemTemplate = item.template;
          break;
      }
    });
  }
  ngOnDestroy() {
    this.clearAutoScrollHandler();
    super.ngOnDestroy();
  }
  isActive(item) {
    if (item.routerLink) {
      const routerLink = Array.isArray(item.routerLink) ? item.routerLink : [item.routerLink];
      return this.router.isActive(this.router.createUrlTree(routerLink, {
        relativeTo: this.route
      }).toString(), item.routerLinkActiveOptions?.exact ?? item.routerLinkActiveOptions ?? false);
    }
    return item === this.activeItem;
  }
  getItemProp(item, name) {
    return item ? resolve(item[name]) : undefined;
  }
  visible(item) {
    return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
  }
  disabled(item) {
    return typeof item.disabled === 'function' ? item.disabled() : item.disabled;
  }
  onMenuItemFocus(item) {
    this.focusedItemInfo.set(item);
  }
  itemClick(event, item) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }
    if (!item.url && !item.routerLink) {
      event.preventDefault();
    }
    if (item.command) {
      item.command({
        originalEvent: event,
        item: item
      });
    }
    this.activeItem = item;
    this.activeItemChange.emit(item);
    this.tabChanged = true;
    this.cd.markForCheck();
  }
  onKeydownItem(event, index, item) {
    let i = index;
    let foundElement = {};
    const tabs = this.tab.toArray();
    switch (event.code) {
      case 'ArrowRight':
        foundElement = this.findNextItem(tabs, i);
        i = foundElement['i'];
        this.changeFocusedTab(event, foundElement['nextItem'], i);
        break;
      case 'ArrowLeft':
        foundElement = this.findPrevItem(tabs, i);
        i = foundElement['i'];
        this.changeFocusedTab(event, foundElement['prevItem'], i);
        break;
      case 'End':
        foundElement = this.findPrevItem(tabs, this.model.length);
        i = foundElement['i'];
        this.changeFocusedTab(event, foundElement['prevItem'], i);
        event.preventDefault();
        break;
      case 'Home':
        foundElement = this.findNextItem(tabs, -1);
        i = foundElement['i'];
        this.changeFocusedTab(event, foundElement['nextItem'], i);
        event.preventDefault();
        break;
      case 'Space':
      case 'Enter':
        this.itemClick(event, item);
        break;
      case 'Tab':
        this.onTabKeyDown(tabs);
        break;
      default:
        break;
    }
    if (tabs[i] && tabs[index]) {
      tabs[index].nativeElement.tabIndex = '-1';
      tabs[i].nativeElement.tabIndex = '0';
      tabs[i].nativeElement.focus();
    }
    this.cd.markForCheck();
  }
  onTabKeyDown(tabLinks) {
    tabLinks.forEach(item => {
      item.nativeElement.tabIndex = getAttribute(item.nativeElement.parentElement, 'data-p-highlight') ? '0' : '-1';
    });
  }
  changeFocusedTab(event, element, index) {
    if (element) {
      focus(element);
      element.scrollIntoView({
        block: 'nearest'
      });
      this.itemClick(event, element);
    }
  }
  findNextItem(items, index) {
    let i = index + 1;
    if (i >= items.length) {
      return {
        nextItem: items[items.length],
        i: items.length
      };
    }
    let nextItem = items[i];
    if (nextItem) return getAttribute(nextItem.nativeElement, 'data-p-disabled') ? this.findNextItem(items, i) : {
      nextItem: nextItem.nativeElement,
      i
    };else return null;
  }
  findPrevItem(items, index) {
    let i = index - 1;
    if (i < 0) {
      return {
        prevItem: items[0],
        i: 0
      };
    }
    let prevItem = items[i];
    if (prevItem) return getAttribute(prevItem.nativeElement, 'data-p-disabled') ? this.findPrevItem(items, i) : {
      prevItem: prevItem.nativeElement,
      i
    };else return null;
  }
  updateInkBar() {
    const tabHeader = findSingle(this.navbar?.nativeElement, 'li.p-tabmenu-active');
    if (tabHeader) {
      this.inkbar.nativeElement.style.width = getWidth(tabHeader) + 'px';
      this.inkbar.nativeElement.style.left = getOffset(tabHeader).left - getOffset(this.navbar?.nativeElement).left + 'px';
    }
  }
  getVisibleButtonWidths() {
    return [this.prevBtn?.nativeElement, this.nextBtn?.nativeElement].reduce((acc, el) => el ? acc + getWidth(el) : acc, 0);
  }
  updateButtonState() {
    const content = this.content?.nativeElement;
    const {
      scrollLeft,
      scrollWidth
    } = content;
    const width = getWidth(content);
    this.backwardIsDisabled = scrollLeft === 0;
    this.forwardIsDisabled = parseInt(scrollLeft) === scrollWidth - width;
  }
  updateScrollBar(index) {
    const tabHeader = this.navbar?.nativeElement.children[index];
    if (!tabHeader) {
      return;
    }
    tabHeader.scrollIntoView({
      block: 'nearest'
    });
  }
  onScroll(event) {
    this.scrollable && this.updateButtonState();
    event.preventDefault();
  }
  navBackward() {
    const content = this.content?.nativeElement;
    const width = getWidth(content) - this.getVisibleButtonWidths();
    const pos = content.scrollLeft - width;
    content.scrollLeft = pos <= 0 ? 0 : pos;
  }
  navForward() {
    const content = this.content?.nativeElement;
    const width = getWidth(content) - this.getVisibleButtonWidths();
    const pos = content.scrollLeft + width;
    const lastPos = content.scrollWidth - width;
    content.scrollLeft = pos >= lastPos ? lastPos : pos;
  }
  initAutoScrollForActiveItem() {
    if (!this.scrollable) {
      return;
    }
    this.clearAutoScrollHandler();
    // We have to wait for the rendering and then can scroll to element.
    this.timerIdForInitialAutoScroll = setTimeout(() => {
      const activeItem = this.model.findIndex(menuItem => this.isActive(menuItem));
      if (activeItem !== -1) {
        this.updateScrollBar(activeItem);
      }
    });
  }
  clearAutoScrollHandler() {
    if (this.timerIdForInitialAutoScroll) {
      clearTimeout(this.timerIdForInitialAutoScroll);
      this.timerIdForInitialAutoScroll = null;
    }
  }
  initButtonState() {
    if (this.scrollable) {
      // We have to wait for the rendering and then retrieve the actual size element from the DOM.
      // in future `Promise.resolve` can be changed to `queueMicrotask` (if ie11 support will be dropped)
      Promise.resolve().then(() => {
        this.updateButtonState();
        this.cd.markForCheck();
      });
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabMenu,
    deps: [],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: TabMenu,
    isStandalone: true,
    selector: "p-tabMenu, p-tabmenu",
    inputs: {
      model: "model",
      activeItem: "activeItem",
      scrollable: ["scrollable", "scrollable", booleanAttribute],
      popup: ["popup", "popup", booleanAttribute],
      style: "style",
      styleClass: "styleClass",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy"
    },
    outputs: {
      activeItemChange: "activeItemChange"
    },
    providers: [TabMenuStyle],
    queries: [{
      propertyName: "itemTemplate",
      first: true,
      predicate: ["item"]
    }, {
      propertyName: "previousIconTemplate",
      first: true,
      predicate: ["previousicon"]
    }, {
      propertyName: "nextIconTemplate",
      first: true,
      predicate: ["nexticon"]
    }, {
      propertyName: "templates",
      predicate: PrimeTemplate
    }],
    viewQueries: [{
      propertyName: "content",
      first: true,
      predicate: ["content"],
      descendants: true
    }, {
      propertyName: "navbar",
      first: true,
      predicate: ["navbar"],
      descendants: true
    }, {
      propertyName: "inkbar",
      first: true,
      predicate: ["inkbar"],
      descendants: true
    }, {
      propertyName: "prevBtn",
      first: true,
      predicate: ["prevBtn"],
      descendants: true
    }, {
      propertyName: "nextBtn",
      first: true,
      predicate: ["nextBtn"],
      descendants: true
    }, {
      propertyName: "tabLink",
      predicate: ["tabLink"],
      descendants: true
    }, {
      propertyName: "tab",
      predicate: ["tab"],
      descendants: true
    }],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <div [ngClass]="{ 'p-tabmenu p-component': true, 'p-tabmenu-scrollable': scrollable }" [ngStyle]="style" [class]="styleClass">
            <div class="p-tabmenu-nav-container">
                <button *ngIf="scrollable && !backwardIsDisabled" #prevBtn class="p-tabmenu-nav-prev-button p-tabmenu-nav-button" (click)="navBackward()" type="button" role="navigation" pRipple>
                    <ChevronLeftIcon *ngIf="!previousIconTemplate && !_previousIconTemplate" [attr.aria-hidden]="true" />
                    <ng-template *ngTemplateOutlet="previousIconTemplate || _previousIconTemplate"></ng-template>
                </button>
                <div #content class="p-tabmenu-nav-content" (scroll)="onScroll($event)">
                    <ul #navbar class="p-tabmenu-nav p-reset" role="menubar" [attr.aria-labelledby]="ariaLabelledBy" [attr.aria-label]="ariaLabel">
                        <li
                            #tab
                            *ngFor="let item of focusableItems; let i = index"
                            role="menuitem"
                            [ngStyle]="item.style"
                            [class]="item.styleClass"
                            [attr.data-p-disabled]="disabled(item)"
                            [attr.data-p-highlight]="focusedItemInfo() === item"
                            (click)="itemClick($event, item)"
                            (keydown)="onKeydownItem($event, i, item)"
                            (focus)="onMenuItemFocus(item)"
                            [ngClass]="{
                                'p-tabmenuitem': true,
                                'p-disabled': getItemProp(item, 'disabled'),
                                'p-tabmenuitem-active': isActive(item),
                                'p-hidden': item.visible === false
                            }"
                            pTooltip
                            [tooltipOptions]="item.tooltipOptions"
                            pRipple
                            [attr.aria-label]="getItemProp(item, 'label')"
                            [attr.tabindex]="disabled(item) ? -1 : 0"
                        >
                            <a
                                #tabLink
                                *ngIf="!item.routerLink && !itemTemplate && !_itemTemplate"
                                class="p-menuitem-link"
                                role="presentation"
                                [attr.href]="getItemProp(item, 'url')"
                                [attr.id]="getItemProp(item, 'id')"
                                [attr.aria-disabled]="disabled(item)"
                                [target]="getItemProp(item, 'target')"
                                [tabindex]="-1"
                            >
                                <ng-container>
                                    <span class="p-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon" [ngStyle]="item.iconStyle"></span>
                                    <span class="p-menuitem-text" *ngIf="item.escape !== false; else htmlLabel">{{ getItemProp(item, 'label') }}</span>
                                    <ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="getItemProp(item, 'label')"></span></ng-template>
                                    <p-badge *ngIf="item.badge" [styleClass]="item.badgeStyleClass" [value]="getItemProp(item, 'badge')" size="small" />
                                </ng-container>
                            </a>
                            <a
                                #tabLink
                                *ngIf="item.routerLink && !itemTemplate && !_itemTemplate"
                                [routerLink]="item.routerLink"
                                [queryParams]="item.queryParams"
                                [routerLinkActive]="'p-menuitem-link-active'"
                                [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
                                role="presentation"
                                class="p-menuitem-link"
                                [target]="item.target"
                                [attr.id]="getItemProp(item, 'id')"
                                [attr.aria-disabled]="disabled(item)"
                                [tabindex]="-1"
                                [fragment]="item.fragment"
                                [queryParamsHandling]="item.queryParamsHandling"
                                [preserveFragment]="item.preserveFragment"
                                [skipLocationChange]="item.skipLocationChange"
                                [replaceUrl]="item.replaceUrl"
                                [state]="item.state"
                            >
                                <ng-container>
                                    <span class="p-menuitem-icon" [attr.aria-hidden]="true" [ngClass]="item.icon" *ngIf="item.icon" [ngStyle]="item.iconStyle"></span>
                                    <span class="p-menuitem-text" *ngIf="item.escape !== false; else htmlRouteLabel">{{ getItemProp(item, 'label') }}</span>
                                    <ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="getItemProp(item, 'label')"></span></ng-template>
                                    <p-badge *ngIf="item.badge" [styleClass]="item.badgeStyleClass" [value]="getItemProp(item, 'badge')" size="small" />
                                </ng-container>
                            </a>
                            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item, index: i }"></ng-container>
                        </li>
                        <li #inkbar class="p-tabmenuitem-active-bar" role="presentation" [attr.data-pc-section]="'inkbar'"></li>
                    </ul>
                </div>
                <button *ngIf="scrollable && !forwardIsDisabled" #nextBtn class="p-tabmenu-nav-next-button p-tabmenu-nav-button" (click)="navForward()" type="button" role="navigation" pRipple>
                    <ChevronRightIcon *ngIf="!previousIconTemplate && !_previousIconTemplate" [attr.aria-hidden]="true" />
                    <ng-template *ngTemplateOutlet="nextIconTemplate || _nextIconTemplate"></ng-template>
                </button>
            </div>
        </div>
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgForOf,
      selector: "[ngFor][ngForOf]",
      inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i1.NgStyle,
      selector: "[ngStyle]",
      inputs: ["ngStyle"]
    }, {
      kind: "ngmodule",
      type: RouterModule
    }, {
      kind: "directive",
      type: i2.RouterLink,
      selector: "[routerLink]",
      inputs: ["target", "queryParams", "fragment", "queryParamsHandling", "state", "info", "relativeTo", "preserveFragment", "skipLocationChange", "replaceUrl", "routerLink"]
    }, {
      kind: "directive",
      type: i2.RouterLinkActive,
      selector: "[routerLinkActive]",
      inputs: ["routerLinkActiveOptions", "ariaCurrentWhenActive", "routerLinkActive"],
      outputs: ["isActiveChange"],
      exportAs: ["routerLinkActive"]
    }, {
      kind: "directive",
      type: Ripple,
      selector: "[pRipple]"
    }, {
      kind: "ngmodule",
      type: TooltipModule
    }, {
      kind: "directive",
      type: i3.Tooltip,
      selector: "[pTooltip]",
      inputs: ["tooltipPosition", "tooltipEvent", "appendTo", "positionStyle", "tooltipStyleClass", "tooltipZIndex", "escape", "showDelay", "hideDelay", "life", "positionTop", "positionLeft", "autoHide", "fitContent", "hideOnEscape", "pTooltip", "tooltipDisabled", "tooltipOptions"]
    }, {
      kind: "component",
      type: ChevronLeftIcon,
      selector: "ChevronLeftIcon"
    }, {
      kind: "component",
      type: ChevronRightIcon,
      selector: "ChevronRightIcon"
    }, {
      kind: "ngmodule",
      type: BadgeModule
    }, {
      kind: "component",
      type: i4.Badge,
      selector: "p-badge",
      inputs: ["styleClass", "style", "badgeSize", "size", "severity", "value", "badgeDisabled"]
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TabMenu,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tabMenu, p-tabmenu',
      standalone: true,
      imports: [CommonModule, RouterModule, Ripple, TooltipModule, ChevronLeftIcon, ChevronRightIcon, BadgeModule, SharedModule],
      template: `
        <div [ngClass]="{ 'p-tabmenu p-component': true, 'p-tabmenu-scrollable': scrollable }" [ngStyle]="style" [class]="styleClass">
            <div class="p-tabmenu-nav-container">
                <button *ngIf="scrollable && !backwardIsDisabled" #prevBtn class="p-tabmenu-nav-prev-button p-tabmenu-nav-button" (click)="navBackward()" type="button" role="navigation" pRipple>
                    <ChevronLeftIcon *ngIf="!previousIconTemplate && !_previousIconTemplate" [attr.aria-hidden]="true" />
                    <ng-template *ngTemplateOutlet="previousIconTemplate || _previousIconTemplate"></ng-template>
                </button>
                <div #content class="p-tabmenu-nav-content" (scroll)="onScroll($event)">
                    <ul #navbar class="p-tabmenu-nav p-reset" role="menubar" [attr.aria-labelledby]="ariaLabelledBy" [attr.aria-label]="ariaLabel">
                        <li
                            #tab
                            *ngFor="let item of focusableItems; let i = index"
                            role="menuitem"
                            [ngStyle]="item.style"
                            [class]="item.styleClass"
                            [attr.data-p-disabled]="disabled(item)"
                            [attr.data-p-highlight]="focusedItemInfo() === item"
                            (click)="itemClick($event, item)"
                            (keydown)="onKeydownItem($event, i, item)"
                            (focus)="onMenuItemFocus(item)"
                            [ngClass]="{
                                'p-tabmenuitem': true,
                                'p-disabled': getItemProp(item, 'disabled'),
                                'p-tabmenuitem-active': isActive(item),
                                'p-hidden': item.visible === false
                            }"
                            pTooltip
                            [tooltipOptions]="item.tooltipOptions"
                            pRipple
                            [attr.aria-label]="getItemProp(item, 'label')"
                            [attr.tabindex]="disabled(item) ? -1 : 0"
                        >
                            <a
                                #tabLink
                                *ngIf="!item.routerLink && !itemTemplate && !_itemTemplate"
                                class="p-menuitem-link"
                                role="presentation"
                                [attr.href]="getItemProp(item, 'url')"
                                [attr.id]="getItemProp(item, 'id')"
                                [attr.aria-disabled]="disabled(item)"
                                [target]="getItemProp(item, 'target')"
                                [tabindex]="-1"
                            >
                                <ng-container>
                                    <span class="p-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon" [ngStyle]="item.iconStyle"></span>
                                    <span class="p-menuitem-text" *ngIf="item.escape !== false; else htmlLabel">{{ getItemProp(item, 'label') }}</span>
                                    <ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="getItemProp(item, 'label')"></span></ng-template>
                                    <p-badge *ngIf="item.badge" [styleClass]="item.badgeStyleClass" [value]="getItemProp(item, 'badge')" size="small" />
                                </ng-container>
                            </a>
                            <a
                                #tabLink
                                *ngIf="item.routerLink && !itemTemplate && !_itemTemplate"
                                [routerLink]="item.routerLink"
                                [queryParams]="item.queryParams"
                                [routerLinkActive]="'p-menuitem-link-active'"
                                [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
                                role="presentation"
                                class="p-menuitem-link"
                                [target]="item.target"
                                [attr.id]="getItemProp(item, 'id')"
                                [attr.aria-disabled]="disabled(item)"
                                [tabindex]="-1"
                                [fragment]="item.fragment"
                                [queryParamsHandling]="item.queryParamsHandling"
                                [preserveFragment]="item.preserveFragment"
                                [skipLocationChange]="item.skipLocationChange"
                                [replaceUrl]="item.replaceUrl"
                                [state]="item.state"
                            >
                                <ng-container>
                                    <span class="p-menuitem-icon" [attr.aria-hidden]="true" [ngClass]="item.icon" *ngIf="item.icon" [ngStyle]="item.iconStyle"></span>
                                    <span class="p-menuitem-text" *ngIf="item.escape !== false; else htmlRouteLabel">{{ getItemProp(item, 'label') }}</span>
                                    <ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="getItemProp(item, 'label')"></span></ng-template>
                                    <p-badge *ngIf="item.badge" [styleClass]="item.badgeStyleClass" [value]="getItemProp(item, 'badge')" size="small" />
                                </ng-container>
                            </a>
                            <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item, index: i }"></ng-container>
                        </li>
                        <li #inkbar class="p-tabmenuitem-active-bar" role="presentation" [attr.data-pc-section]="'inkbar'"></li>
                    </ul>
                </div>
                <button *ngIf="scrollable && !forwardIsDisabled" #nextBtn class="p-tabmenu-nav-next-button p-tabmenu-nav-button" (click)="navForward()" type="button" role="navigation" pRipple>
                    <ChevronRightIcon *ngIf="!previousIconTemplate && !_previousIconTemplate" [attr.aria-hidden]="true" />
                    <ng-template *ngTemplateOutlet="nextIconTemplate || _nextIconTemplate"></ng-template>
                </button>
            </div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [TabMenuStyle]
    }]
  }],
  ctorParameters: () => [],
  propDecorators: {
    model: [{
      type: Input
    }],
    activeItem: [{
      type: Input
    }],
    scrollable: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    popup: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    activeItemChange: [{
      type: Output
    }],
    content: [{
      type: ViewChild,
      args: ['content']
    }],
    navbar: [{
      type: ViewChild,
      args: ['navbar']
    }],
    inkbar: [{
      type: ViewChild,
      args: ['inkbar']
    }],
    prevBtn: [{
      type: ViewChild,
      args: ['prevBtn']
    }],
    nextBtn: [{
      type: ViewChild,
      args: ['nextBtn']
    }],
    tabLink: [{
      type: ViewChildren,
      args: ['tabLink']
    }],
    tab: [{
      type: ViewChildren,
      args: ['tab']
    }],
    itemTemplate: [{
      type: ContentChild,
      args: ['item', {
        descendants: false
      }]
    }],
    previousIconTemplate: [{
      type: ContentChild,
      args: ['previousicon', {
        descendants: false
      }]
    }],
    nextIconTemplate: [{
      type: ContentChild,
      args: ['nexticon', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  }
});
class TabMenuModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabMenuModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabMenuModule,
    imports: [TabMenu, SharedModule],
    exports: [TabMenu, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TabMenuModule,
    imports: [TabMenu, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TabMenuModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [TabMenu, SharedModule],
      exports: [TabMenu, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { TabMenu, TabMenuClasses, TabMenuModule, TabMenuStyle };
