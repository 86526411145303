import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, HostBinding, Input, ChangeDetectionStrategy, ViewEncapsulation, Component, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const classes = {
  root: 'p-inputicon'
};
class InputIconStyle extends BaseStyle {
  name = 'inputicon';
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputIconStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputIconStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputIconStyle,
  decorators: [{
    type: Injectable
  }]
});

/**
 * InputIcon displays an icon.
 * @group Components
 */
class InputIcon extends BaseComponent {
  /**
   * Style class of the element.
   * @group Props
   */
  styleClass;
  get hostClasses() {
    return this.styleClass;
  }
  _componentStyle = inject(InputIconStyle);
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputIcon,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: InputIcon,
    isStandalone: true,
    selector: "p-inputicon, p-inputIcon",
    inputs: {
      styleClass: "styleClass"
    },
    host: {
      properties: {
        "class": "this.hostClasses",
        "class.p-inputicon": "true"
      }
    },
    providers: [InputIconStyle],
    usesInheritance: true,
    ngImport: i0,
    template: `<ng-content></ng-content>`,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputIcon,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-inputicon, p-inputIcon',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: `<ng-content></ng-content>`,
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [InputIconStyle],
      host: {
        '[class]': 'styleClass',
        '[class.p-inputicon]': 'true'
      }
    }]
  }],
  propDecorators: {
    styleClass: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  }
});
class InputIconModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputIconModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputIconModule,
    imports: [InputIcon, SharedModule],
    exports: [InputIcon, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputIconModule,
    imports: [InputIcon, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputIconModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [InputIcon, SharedModule],
      exports: [InputIcon, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { InputIcon, InputIconModule, InputIconStyle };
