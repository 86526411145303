<div>
<h3>Geef je verbruik in</h3>
<p>Om een correct voorstel te doen, vragen wij je om je verbruik in te geven. <a [routerLink]="['/price-calculation', 'step-2d']" [state]="{'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse}">Indien je je verbruik toch niet kent, kan je deze hier simuleren.</a></p>

<div class="grid fluid">
    @if (priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit)) {
        @if (priceSimulationOptions.tariffType == PriceSimulationTariffType.Enkelvoudig) {
            <div class="row">
                <div class="xl:col-12 lg:col-12 col-12">
                    <p-input-group>
                        <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-clock ebemGreen" style="font-size: 22px"></span>
                        <span>&nbsp;&nbsp;</span>
                        <p-input-number name="estimatedElectricityUse" (input)="validateEstimatedElectricityUse(); estimatedElectricityUse = priceSimulationInput.kwh24u + priceSimulationInput.kwhExclNacht" [(ngModel)]="priceSimulationInput.kwh24u" placeholder="0000" [minFractionDigits]="0" [maxFractionDigits]="4" locale="en-US" mode="decimal" [useGrouping]="false"></p-input-number>
                        <span>&nbsp;&nbsp;</span>
                        <label style="color: gray; font-size: 14px">KWh/jaar (24u)</label>
                    </p-input-group>
                    <p-card class="danger no-padding" *ngIf="showErrorNoEstimatedElectricityUseFilledIn">Geschatte elektriciteitsverbruik 24u is een verplicht veld</p-card>
                </div>
            </div>
            @if (priceSimulationOptions.hasNightTariff) {
                <div class="row">
                    <div class="xl:col-12 lg:col-12 col-12">
                        <p-input-group>
                            <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-moon ebemGreen" style="font-size: 18px"></span><span>&nbsp;&nbsp;</span><p-input-number (input)="validateEstimatedElectricityUse(); estimatedElectricityUse = priceSimulationInput.kwh24u + priceSimulationInput.kwhExclNacht" [(ngModel)]="priceSimulationInput.kwhExclNacht" placeholder="0000" [minFractionDigits]="0" [maxFractionDigits]="4" locale="en-US" mode="decimal" [useGrouping]="false"></p-input-number><span>&nbsp;&nbsp;</span><label style="color: gray; font-size: 14px">Nachtverbruik KWh/jaar</label>
                        </p-input-group>
                        <p-card class="danger no-padding" *ngIf="showErrorNoEstimatedElectricityUseFilledIn">Geschatte elektriciteitsverbruik exclusief nachttarief is een verplicht veld</p-card>
                    </div>
                </div>
            }
        } @else {
            <div class="row">
                <div class="xl:col-12 lg:col-12 col-12">
                    <p-input-group>
                        <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-angle-up ebemGreen" style="font-size: 22px"></span>
                        <span>&nbsp;&nbsp;</span>
                        <p-input-number name="estimatedElectricityUse" (input)="validateEstimatedElectricityUse(); estimatedElectricityUse = priceSimulationInput.kwhPiek + priceSimulationInput.kwhDal + priceSimulationInput.kwhExclNacht" [(ngModel)]="priceSimulationInput.kwhPiek" placeholder="0000" [minFractionDigits]="0" [maxFractionDigits]="4" locale="en-US" mode="decimal" [useGrouping]="false"></p-input-number>
                        <span>&nbsp;&nbsp;</span>
                        <label style="color: gray; font-size: 14px">KWh/jaar (Piek)</label>
                    </p-input-group>
                    <p-card class="danger no-padding" *ngIf="showErrorNoEstimatedElectricityUseFilledIn">Geschatte elektriciteitsverbruik piektarief is een verplicht veld</p-card>
                </div>
            </div>
            <div class="row">
                <div class="xl:col-12 lg:col-12 col-12">
                    <p-input-group>
                        <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-angle-down ebemGreen" style="font-size: 22px"></span>
                        <span>&nbsp;&nbsp;</span>
                        <p-input-number name="estimatedElectricityUse" (input)="validateEstimatedElectricityUse(); estimatedElectricityUse = priceSimulationInput.kwhPiek + priceSimulationInput.kwhDal + priceSimulationInput.kwhExclNacht" [(ngModel)]="priceSimulationInput.kwhDal" placeholder="0000" [minFractionDigits]="0" [maxFractionDigits]="4" locale="en-US" mode="decimal" [useGrouping]="false"></p-input-number>
                        <span>&nbsp;&nbsp;</span>
                        <label style="color: gray; font-size: 14px">KWh/jaar (Dal)</label>
                    </p-input-group>
                    <p-card class="danger no-padding" *ngIf="showErrorNoEstimatedElectricityUseFilledIn">Geschatte elektriciteitsverbruik daltarief is een verplicht veld</p-card>
                </div>
            </div>
            @if (priceSimulationOptions.hasNightTariff) {
                <div class="row">
                    <div class="xl:col-12 lg:col-12 col-12">
                        <p-input-group>
                            <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-moon ebemGreen" style="font-size: 18px"></span><span>&nbsp;&nbsp;</span><p-input-number (input)="validateEstimatedElectricityUse(); estimatedElectricityUse = priceSimulationInput.kwhPiek + priceSimulationInput.kwhDal + priceSimulationInput.kwhExclNacht" [(ngModel)]="priceSimulationInput.kwhExclNacht" placeholder="0000" [minFractionDigits]="0" [maxFractionDigits]="4" locale="en-US" mode="decimal" [useGrouping]="false"></p-input-number><span>&nbsp;&nbsp;</span><label style="color: gray; font-size: 14px">Nachtverbruik KWh/jaar</label>
                        </p-input-group>
                        <p-card class="danger no-padding" *ngIf="showErrorNoEstimatedElectricityUseFilledIn">Geschatte elektriciteitsverbruik exclusief nachttarief is een verplicht veld</p-card>
                    </div>
                </div>
            }
        } 
    }

    @if (priceSimulationOptions.meterType == PriceSimulationMeterType['Analoge meter'] && priceSimulationOptions.solarPanels == PriceSimulationSolarPanels.Zonnepanelen) {
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p-input-group>
                    <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-sun ebemGreen" style="font-size: 22px"></span><span>&nbsp;&nbsp;</span><p-input-number [(ngModel)]="priceSimulationInput.kweInjectie" placeholder="00" [minFractionDigits]="0" [maxFractionDigits]="0" mode="decimal" [useGrouping]="false"></p-input-number><span>&nbsp;&nbsp;</span><label style="color: gray; font-size: 14px">Vermogen omvormer KWe/jaar</label>
                </p-input-group>
                <p-card class="danger no-padding" *ngIf="showErrorNoEstimatedElectricityUseFilledIn">Geschatte vermogen omvormer zonnepanelen is een verplicht veld</p-card>
            </div>
        </div>
    }
    @if (priceSimulationChoices?.includes(PriceSimulationChoice.Gas)) {
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p-input-group class="row">
                    <span class="material-icons ebemBlue" style="font-size: 25px">local_fire_department</span>
                    <span>&nbsp;&nbsp;</span>
                    <p-input-number name="estimatedGasUse" (input)="validateEstimatedGasUseInput(); estimatedGasUse = priceSimulationInput.kwhGas" [(ngModel)]="priceSimulationInput.kwhGas" placeholder="0000" [minFractionDigits]="0" [maxFractionDigits]="4" locale="en-US" mode="decimal" [useGrouping]="false"></p-input-number>
                    <span>&nbsp;&nbsp;</span>
                    <label style="color: gray; font-size: 14px">KWh/jaar</label>
                </p-input-group>
                <p-card class="danger no-padding" *ngIf="showErrorNoEstimatedGasUseFilledIn">Geschatte gasverbruik is een verplicht veld</p-card>
            </div>
        </div>
    }
</div>
@if (priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit)) {
<h3>Uploaden</h3>
<p>Gebruik een export van je verbruik bij je huidige leverancier. Dit document in CSV formaat kan U opvragen bij Fluvius.</p>
<p-button [style]="{padding: 0}" link="true" *ngIf="priceSimulationOptions.usageDocuments && priceSimulationOptions.usageDocuments.length > 0" (click)="priceSimulationOptions.usageDocuments.length > 0 ? open(priceSimulationOptions.usageDocuments[0]) : null" target="_blank">{{priceSimulationOptions.usageDocuments[0]?.name}}</p-button>
<p-fileupload ngDefaultControl [(ngModel)]="priceSimulationOptions.usageDocuments" mode="basic" accept="text/csv" url="/upload" [multiple]="false" maxFileSize="20000000" chooseLabel="Verbruik uploaden" [chooseButtonProps]="{ outlined: true }" [chooseIcon]="priceSimulationOptions.usageDocuments && priceSimulationOptions.usageDocuments.length > 0 ? 'pi-check-circle' : 'pi-upload'" uploadLabel="Uploaden" cancelLabel="Annuleren" [auto]="true" [customUpload]="true" (uploadHandler)="onUpload($event);" ></p-fileupload><br/>
<br/>
<p-progress-bar [showValue]="false" [value]="80" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
}
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/price-calculation', 'step-2b']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Terug</p-button>
    </div>
    <div class="col-6">
        <p-button class="float-right" (click)="validateForm() ? this.router.navigate(['/price-calculation', 'step-3'], {state: { 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }}) : null">Bereken mijn voorstel <span class="pi-arrow-right"></span></p-button>

    </div>
</div>
</div>