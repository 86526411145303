import { HttpClient } from "@angular/common/http";
import { Directive, Input } from "@angular/core";
import { ElementRef, Renderer2 } from "@angular/core";
import { UmbracoMediaContent } from "../models/umbraco-content.model";
import { environment } from "src/environments/environment";
import { map, Observable } from "rxjs";

@Directive({
    selector: '[dataUdi]'
})
export class DataUdiDirective {
    @Input('data-udi') set dataUdi(value: string) {
        this.transformUdi(value).forEach(transformedValueObservable => transformedValueObservable.subscribe(transformedValue => {
            if (this.el.nativeElement.tagName === 'IMG') {
                this.renderer.setAttribute(this.el.nativeElement, 'src', environment.umbracoUrl + transformedValue);
            } else {
                this.renderer.setAttribute(this.el.nativeElement, 'href', environment.umbracoUrl + transformedValue);
            }
        }));
    }

    constructor(private http: HttpClient, private el: ElementRef, private renderer: Renderer2) {}

    private transformUdi(content: string): Observable<string>[] {
        const mediaRegex = /umb:\/\/media\/([a-z0-9]{32})/gm;
        const matches = content.match(mediaRegex) || [];
        return matches.map((match: any) => {
            const guid = `${match.substring(12, 20)}-${match.substring(20, 24)}-${match.substring(24, 28)}-${match.substring(28, 32)}-${match.substring(32)}`;
            return this.http.get<UmbracoMediaContent>(`${environment.umbracoUrl}/umbraco/delivery/api/v2/media/item/${guid}?fields=properties[$all]`).pipe(map(mediaContent => {
                content = content.replace(match, mediaContent.url);
                return content;
            }));
        });
    }
}