import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AccordionModule } from "primeng/accordion";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { InputGroupModule } from "primeng/inputgroup";
import { RadioButtonModule } from "primeng/radiobutton";
import { TableModule } from "primeng/table";
import { ElectricityType } from "src/app/models/enums/electricity-type.enum";
import { GasType } from "src/app/models/enums/gas-type.enum";
import { InvoiceModality } from "src/app/models/enums/invoice-modality.enum";
import { Product } from "src/app/models/product.model";
import { UmbracoService } from "src/app/services/umbraco.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    standalone: true,
    imports: [AccordionModule, ButtonModule, CardModule, CommonModule, FormsModule, InputGroupModule, TableModule, RadioButtonModule, ReactiveFormsModule, RouterModule]
})
export class ProductCardComponent {
    @Input() product!: Product;
    customerType: string = "customer";
    electricityTariff: any;
    electricityTariffDynamic: any;
    gasTariff: any;
    umbracoUrl: string = environment.umbracoUrl;
    constructor(private umbracoService: UmbracoService) {
        this.customerType = window.history.state.customerType ?? 'customer';
        this.umbracoService.getElectriticyTariffs().subscribe(content => {
            this.electricityTariffDynamic = content.filter(x => x.name.includes('Dyn'))[0];
            this.electricityTariff = content.filter(x => !x.name.includes('Dyn'))[0];
        } );
        this.umbracoService.getGasTariffs().subscribe(content => {
            this.gasTariff = content[0];
        });
    }

    InvoicingModality = InvoiceModality;

    isElectricityProduct(product: Product): boolean {
        return product?.enumType == "ElectricityType";
    }

    isGasProduct(product: Product): boolean {
        return product?.enumType == "GasType";
    }
}