import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Input, Component, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-inputgroup,
.p-inputgroup .p-floatlabel,
.p-inputgroup .p-iftalabel {
    display: flex;
    align-items: stretch;
    width: 100%;
}

.p-inputgroup .p-inputtext,
.p-inputgroup .p-inputwrapper {
    flex: 1 1 auto;
    width: 1%;
}

.p-inputgroupaddon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${dt('inputgroup.addon.padding')};
    background: ${dt('inputgroup.addon.background')};
    color: ${dt('inputgroup.addon.color')};
    border-block-start: 1px solid ${dt('inputgroup.addon.border.color')};
    border-block-end: 1px solid ${dt('inputgroup.addon.border.color')};
    min-width: ${dt('inputgroup.addon.min.width')};
}

.p-inputgroupaddon:first-child,
.p-inputgroupaddon + .p-inputgroupaddon {
    border-inline-start: 1px solid ${dt('inputgroup.addon.border.color')};
}

.p-inputgroupaddon:last-child {
    border-inline-end: 1px solid ${dt('inputgroup.addon.border.color')};
}

.p-inputgroupaddon:has(.p-button) {
    padding: 0;
    overflow: hidden;
}

.p-inputgroupaddon .p-button {
    border-radius: 0;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-component,
.p-inputgroup:first-child > p-button > .p-button,
.p-inputgroup > .p-floatlabel > .p-component,
.p-inputgroup > .p-floatlabel > .p-inputwrapper > .p-component,
.p-inputgroup > .p-iftalabel > .p-component,
.p-inputgroup > .p-iftalabel > .p-inputwrapper > .p-component {
    border-radius: 0;
    margin: 0;
}

.p-inputgroupaddon:first-child,
.p-inputgroup > .p-component:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-floatlabel:first-child > .p-component,
.p-inputgroup > .p-floatlabel:first-child > .p-inputwrapper > .p-component,
.p-inputgroup > .p-iftalabel:first-child > .p-component,
.p-inputgroup > .p-iftalabel:first-child > .p-inputwrapper > .p-component {
    border-start-start-radius: ${dt('inputgroup.addon.border.radius')};
    border-end-start-radius: ${dt('inputgroup.addon.border.radius')};
}

.p-inputgroupaddon:last-child,
.p-inputgroup > .p-component:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-floatlabel:last-child > .p-component,
.p-inputgroup > .p-floatlabel:last-child > .p-inputwrapper > .p-component,
.p-inputgroup > .p-iftalabel:last-child > .p-component,
.p-inputgroup > .p-iftalabel:last-child > .p-inputwrapper > .p-component {
    border-start-end-radius: ${dt('inputgroup.addon.border.radius')};
    border-end-end-radius: ${dt('inputgroup.addon.border.radius')};
}

.p-inputgroup .p-component:focus,
.p-inputgroup .p-component.p-focus,
.p-inputgroup .p-inputwrapper-focus,
.p-inputgroup .p-component:focus ~ label,
.p-inputgroup .p-component.p-focus ~ label,
.p-inputgroup .p-inputwrapper-focus ~ label {
    z-index: 1;
}

.p-inputgroup > .p-button:not(.p-button-icon-only) {
    width: auto;
}

/*For PrimeNG*/

.p-inputgroup p-button:first-child, .p-inputgroup p-button:last-child {
    display: inline-flex;
}

.p-inputgroup:has(> p-button:first-child) .p-button{
    border-start-start-radius: ${dt('inputgroup.addon.border.radius')};
    border-end-start-radius: ${dt('inputgroup.addon.border.radius')};
}

.p-inputgroup:has(> p-button:last-child) .p-button {
    border-start-end-radius: ${dt('inputgroup.addon.border.radius')};
    border-end-end-radius: ${dt('inputgroup.addon.border.radius')};
}
`;
const classes = {
  root: ({
    props
  }) => ['p-inputgroup', {
    'p-inputgroup-fluid': props.fluid
  }]
};
class InputGroupStyle extends BaseStyle {
  name = 'inputgroup';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputGroupStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * InputGroup displays text, icon, buttons and other content can be grouped next to an input.
 *
 * [Live Demo](https://www.primeng.org/inputgroup/)
 *
 * @module inputgroupstyle
 *
 */
var InputGroupClasses;
(function (InputGroupClasses) {
  /**
   * Class name of the root element
   */
  InputGroupClasses["root"] = "p-inputgroup";
})(InputGroupClasses || (InputGroupClasses = {}));

/**
 * InputGroup displays text, icon, buttons and other content can be grouped next to an input.
 * @group Components
 */
class InputGroup extends BaseComponent {
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  _componentStyle = inject(InputGroupStyle);
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroup,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: InputGroup,
    isStandalone: true,
    selector: "p-inputgroup, p-inputGroup, p-input-group",
    inputs: {
      style: "style",
      styleClass: "styleClass"
    },
    host: {
      properties: {
        "attr.data-pc-name": "\"inputgroup\"",
        "class": "styleClass",
        "style": "style"
      },
      classAttribute: "p-inputgroup"
    },
    providers: [InputGroupStyle],
    usesInheritance: true,
    ngImport: i0,
    template: ` <ng-content></ng-content> `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "ngmodule",
      type: SharedModule
    }]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-inputgroup, p-inputGroup, p-input-group',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: ` <ng-content></ng-content> `,
      providers: [InputGroupStyle],
      host: {
        class: 'p-inputgroup',
        '[attr.data-pc-name]': '"inputgroup"',
        '[class]': 'styleClass',
        '[style]': 'style'
      }
    }]
  }],
  propDecorators: {
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }]
  }
});
class InputGroupModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupModule,
    imports: [InputGroup, SharedModule],
    exports: [InputGroup, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupModule,
    imports: [InputGroup, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputGroupModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [InputGroup, SharedModule],
      exports: [InputGroup, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { InputGroup, InputGroupClasses, InputGroupModule, InputGroupStyle };
