import { trigger, transition, style, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, signal, booleanAttribute, HostListener, Input, Directive, Pipe, forwardRef, EventEmitter, numberAttribute, ContentChildren, ContentChild, ViewChild, Output, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { addClass, absolutePosition, removeClass, hasClass, isTouchDevice, getOuterWidth, relativePosition } from '@primeuix/utils';
import { OverlayService, TranslationKeys, SharedModule, PrimeTemplate } from 'primeng/api';
import { AutoFocus } from 'primeng/autofocus';
import { BaseComponent } from 'primeng/basecomponent';
import { DomHandler, ConnectedOverlayScrollHandler } from 'primeng/dom';
import { TimesIcon, EyeSlashIcon, EyeIcon } from 'primeng/icons';
import { InputText } from 'primeng/inputtext';
import { ZIndexUtils } from 'primeng/utils';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-password {
    display: inline-flex;
    position: relative;
}

.p-password .p-password-overlay {
    min-width: 100%;
}

.p-password-meter {
    height: ${dt('password.meter.height')};
    background: ${dt('password.meter.background')};
    border-radius: ${dt('password.meter.border.radius')};
}

.p-password-meter-label {
    height: 100%;
    width: 0;
    transition: width 1s ease-in-out;
    border-radius: ${dt('password.meter.border.radius')};
}

.p-password-meter-weak {
    background: ${dt('password.strength.weak.background')};
}

.p-password-meter-medium {
    background: ${dt('password.strength.medium.background')};
}

.p-password-meter-strong {
    background: ${dt('password.strength.strong.background')};
}

.p-password-fluid {
    display: flex;
}

.p-password-fluid .p-password-input {
    width: 100%;
}

.p-password-input::-ms-reveal,
.p-password-input::-ms-clear {
    display: none;
}

.p-password-overlay {
    position: absolute;
    padding: ${dt('password.overlay.padding')};
    background: ${dt('password.overlay.background')};
    color: ${dt('password.overlay.color')};
    border: 1px solid ${dt('password.overlay.border.color')};
    box-shadow: ${dt('password.overlay.shadow')};
    border-radius: ${dt('password.overlay.border.radius')};
}

.p-password-content {
    display: flex;
    flex-direction: column;
    gap: ${dt('password.content.gap')};
}

.p-password-toggle-mask-icon {
    inset-inline-end: ${dt('form.field.padding.x')};
    color: ${dt('password.icon.color')};
    position: absolute;
    top: 50%;
    margin-top: calc(-1 * calc(${dt('icon.size')} / 2));
    width: ${dt('icon.size')};
    height: ${dt('icon.size')};
}

.p-password:has(.p-password-toggle-mask-icon) .p-password-input {
    padding-inline-end: calc((${dt('form.field.padding.x')} * 2) + ${dt('icon.size')});
}

/* For PrimeNG */
p-password.ng-invalid.ng-dirty .p-inputtext {
    border-color: ${dt('inputtext.invalid.border.color')};
}

p-password.ng-invalid.ng-dirty .p-inputtext:enabled:focus {
    border-color: ${dt('inputtext.focus.border.color')};
}

p-password.ng-invalid.ng-dirty .p-inputtext::placeholder {
    color: ${dt('inputtext.invalid.placeholder.color')};
}

.p-password-clear-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    cursor: pointer;
    inset-inline-end: ${dt('form.field.padding.x')};
    color: ${dt('form.field.icon.color')};
}

.p-password-fluid-directive {
    width:100%
}
`;
const inlineStyles = {
  root: ({
    instance
  }) => ({
    position: instance.appendTo === 'self' ? 'relative' : undefined
  })
};
const classes = {
  root: ({
    instance
  }) => ({
    'p-password p-component p-inputwrapper': true,
    'p-inputwrapper-filled': instance.filled(),
    'p-variant-filled': 'instance.variant === "filled" || instance.config.inputVariant() === "filled" || instance.config.inputStyle() === "filled"',
    'p-inputwrapper-focus': instance.focused,
    'p-password-fluid': instance.hasFluid
  }),
  pcInput: 'p-password-input',
  maskIcon: 'p-password-toggle-mask-icon p-password-mask-icon',
  unmaskIcon: 'p-password-toggle-mask-icon p-password-unmask-icon',
  overlay: 'p-password-overlay p-component',
  content: 'p-password-content',
  meter: 'p-password-meter',
  meterLabel: ({
    instance
  }) => `p-password-meter-label ${instance.meter ? 'p-password-meter-' + instance.meter.strength : ''}`,
  meterText: 'p-password-meter-text'
};
class PasswordStyle extends BaseStyle {
  name = 'password';
  theme = theme;
  classes = classes;
  inlineStyles = inlineStyles;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: PasswordStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: PasswordStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: PasswordStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * Password displays strength indicator for password fields.
 *
 * [Live Demo](https://www.primeng.org/password/)
 *
 * @module passwordstyle
 *
 */
var PasswordClasses;
(function (PasswordClasses) {
  /**
   * Class name of the root element
   */
  PasswordClasses["root"] = "p-password";
  /**
   * Class name of the pt input element
   */
  PasswordClasses["pcInput"] = "p-password-input";
  /**
   * Class name of the mask icon element
   */
  PasswordClasses["maskIcon"] = "p-password-mask-icon";
  /**
   * Class name of the unmask icon element
   */
  PasswordClasses["unmaskIcon"] = "p-password-unmask-icon";
  /**
   * Class name of the overlay element
   */
  PasswordClasses["overlay"] = "p-password-overlay";
  /**
   * Class name of the meter element
   */
  PasswordClasses["meter"] = "p-password-meter";
  /**
   * Class name of the meter label element
   */
  PasswordClasses["meterLabel"] = "p-password-meter-label";
  /**
   * Class name of the meter text element
   */
  PasswordClasses["meterText"] = "p-password-meter-text";
})(PasswordClasses || (PasswordClasses = {}));

/**
 * Password directive.
 * @group Components
 */
// strengthClass(meter: any) {
//     return `p-password-meter-label p-password-meter${meter?.strength ? `-${meter.strength}` : ''}`;
// }
class PasswordDirective extends BaseComponent {
  zone;
  /**
   * Text to prompt password entry. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  promptLabel = 'Enter a password';
  /**
   * Text for a weak password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  weakLabel = 'Weak';
  /**
   * Text for a medium password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  mediumLabel = 'Medium';
  /**
   * Text for a strong password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  strongLabel = 'Strong';
  /**
   * Whether to show the strength indicator or not.
   * @group Props
   */
  feedback = true;
  /**
   * Sets the visibility of the password field.
   * @group Props
   */
  set showPassword(show) {
    this.el.nativeElement.type = show ? 'text' : 'password';
  }
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant;
  /**
   * Spans 100% width of the container when enabled.
   * @group Props
   */
  fluid = false;
  panel;
  meter;
  info;
  filled;
  content;
  label;
  scrollHandler;
  documentResizeListener;
  _componentStyle = inject(PasswordStyle);
  get hasFluid() {
    const nativeElement = this.el.nativeElement;
    const fluidComponent = nativeElement.closest('p-fluid');
    return this.fluid || !!fluidComponent;
  }
  constructor(zone) {
    super();
    this.zone = zone;
  }
  ngDoCheck() {
    this.updateFilledState();
  }
  onInput(e) {
    this.updateFilledState();
  }
  updateFilledState() {
    this.filled = this.el.nativeElement.value && this.el.nativeElement.value.length;
  }
  createPanel() {
    if (isPlatformBrowser(this.platformId)) {
      this.panel = this.renderer.createElement('div');
      this.renderer.addClass(this.panel, 'p-password-overlay');
      this.renderer.addClass(this.panel, 'p-component');
      this.content = this.renderer.createElement('div');
      this.renderer.addClass(this.content, 'p-password-content');
      this.renderer.appendChild(this.panel, this.content);
      this.meter = this.renderer.createElement('div');
      this.renderer.addClass(this.meter, 'p-password-meter');
      this.renderer.appendChild(this.content, this.meter);
      this.label = this.renderer.createElement('div');
      this.renderer.addClass(this.label, 'p-password-meter-label');
      this.renderer.appendChild(this.meter, this.label);
      this.info = this.renderer.createElement('div');
      this.renderer.addClass(this.info, 'p-password-meter-text');
      this.renderer.setProperty(this.info, 'textContent', this.promptLabel);
      this.renderer.appendChild(this.content, this.info);
      this.renderer.setStyle(this.panel, 'minWidth', `${this.el.nativeElement.offsetWidth}px`);
      this.renderer.appendChild(document.body, this.panel);
      this.updateMeter();
    }
  }
  showOverlay() {
    if (this.feedback) {
      if (!this.panel) {
        this.createPanel();
      }
      this.renderer.setStyle(this.panel, 'zIndex', String(++DomHandler.zindex));
      this.renderer.setStyle(this.panel, 'display', 'block');
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          addClass(this.panel, 'p-connected-overlay-visible');
          this.bindScrollListener();
          this.bindDocumentResizeListener();
        }, 1);
      });
      absolutePosition(this.panel, this.el.nativeElement);
    }
  }
  hideOverlay() {
    if (this.feedback && this.panel) {
      addClass(this.panel, 'p-connected-overlay-hidden');
      removeClass(this.panel, 'p-connected-overlay-visible');
      this.unbindScrollListener();
      this.unbindDocumentResizeListener();
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.ngOnDestroy();
        }, 150);
      });
    }
  }
  onFocus() {
    this.showOverlay();
  }
  onBlur() {
    this.hideOverlay();
  }
  labelSignal = signal('');
  onKeyup(e) {
    if (this.feedback) {
      let value = e.target.value,
        label = null,
        meterPos = null;
      if (value.length === 0) {
        label = this.promptLabel;
        meterPos = '0px 0px';
      } else {
        var score = this.testStrength(value);
        if (score < 30) {
          label = this.weakLabel;
          meterPos = '0px -10px';
        } else if (score >= 30 && score < 80) {
          label = this.mediumLabel;
          meterPos = '0px -20px';
        } else if (score >= 80) {
          label = this.strongLabel;
          meterPos = '0px -30px';
        }
        this.labelSignal.set(label);
        this.updateMeter();
      }
      if (!this.panel || !hasClass(this.panel, 'p-connected-overlay-visible')) {
        this.showOverlay();
      }
      this.renderer.setStyle(this.meter, 'backgroundPosition', meterPos);
      this.info.textContent = label;
    }
  }
  updateMeter() {
    if (this.labelSignal()) {
      const label = this.labelSignal();
      const strengthClass = this.strengthClass(label.toLowerCase());
      const width = this.getWidth(label.toLowerCase());
      this.renderer.addClass(this.meter, strengthClass);
      this.renderer.setStyle(this.meter, 'width', width);
      this.info.textContent = label;
    }
  }
  getWidth(label) {
    return label === 'weak' ? '33.33%' : label === 'medium' ? '66.66%' : label === 'strong' ? '100%' : '';
  }
  strengthClass(label) {
    return `p-password-meter${label ? `-${label}` : ''}`;
  }
  testStrength(str) {
    let grade = 0;
    let val;
    val = str.match('[0-9]');
    grade += this.normalize(val ? val.length : 1 / 4, 1) * 25;
    val = str.match('[a-zA-Z]');
    grade += this.normalize(val ? val.length : 1 / 2, 3) * 10;
    val = str.match('[!@#$%^&*?_~.,;=]');
    grade += this.normalize(val ? val.length : 1 / 6, 1) * 35;
    val = str.match('[A-Z]');
    grade += this.normalize(val ? val.length : 1 / 6, 1) * 30;
    grade *= str.length / 8;
    return grade > 100 ? 100 : grade;
  }
  normalize(x, y) {
    let diff = x - y;
    if (diff <= 0) return x / y;else return 1 + 0.5 * (x / (x + y / 4));
  }
  get disabled() {
    return this.el.nativeElement.disabled;
  }
  bindScrollListener() {
    if (!this.scrollHandler) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.el.nativeElement, () => {
        if (hasClass(this.panel, 'p-connected-overlay-visible')) {
          this.hideOverlay();
        }
      });
    }
    this.scrollHandler.bindScrollListener();
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  bindDocumentResizeListener() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.documentResizeListener) {
        const window = this.document.defaultView;
        this.documentResizeListener = this.renderer.listen(window, 'resize', this.onWindowResize.bind(this));
      }
    }
  }
  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      this.documentResizeListener();
      this.documentResizeListener = null;
    }
  }
  onWindowResize() {
    if (!isTouchDevice()) {
      this.hideOverlay();
    }
  }
  ngOnDestroy() {
    if (this.panel) {
      if (this.scrollHandler) {
        this.scrollHandler.destroy();
        this.scrollHandler = null;
      }
      this.unbindDocumentResizeListener();
      this.renderer.removeChild(this.document.body, this.panel);
      this.panel = null;
      this.meter = null;
      this.info = null;
    }
    super.ngOnDestroy();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: PasswordDirective,
    deps: [{
      token: i0.NgZone
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: PasswordDirective,
    isStandalone: true,
    selector: "[pPassword]",
    inputs: {
      promptLabel: "promptLabel",
      weakLabel: "weakLabel",
      mediumLabel: "mediumLabel",
      strongLabel: "strongLabel",
      feedback: ["feedback", "feedback", booleanAttribute],
      showPassword: "showPassword",
      variant: "variant",
      fluid: ["fluid", "fluid", booleanAttribute]
    },
    host: {
      listeners: {
        "input": "onInput($event)",
        "focus": "onFocus()",
        "blur": "onBlur()",
        "keyup": "onKeyup($event)"
      },
      properties: {
        "class.p-inputwrapper-filled": "filled",
        "class.p-variant-filled": "variant === \"filled\" || config.inputStyle() === \"filled\" || config.inputVariant() === \"filled\"",
        "class.p-password-fluid-directive": "hasFluid"
      },
      classAttribute: "p-password p-inputtext p-component p-inputwrapper"
    },
    providers: [PasswordStyle],
    usesInheritance: true,
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: PasswordDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pPassword]',
      standalone: true,
      host: {
        class: 'p-password p-inputtext p-component p-inputwrapper',
        '[class.p-inputwrapper-filled]': 'filled',
        '[class.p-variant-filled]': 'variant === "filled" || config.inputStyle() === "filled" || config.inputVariant() === "filled"',
        '[class.p-password-fluid-directive]': 'hasFluid'
      },
      providers: [PasswordStyle]
    }]
  }],
  ctorParameters: () => [{
    type: i0.NgZone
  }],
  propDecorators: {
    promptLabel: [{
      type: Input
    }],
    weakLabel: [{
      type: Input
    }],
    mediumLabel: [{
      type: Input
    }],
    strongLabel: [{
      type: Input
    }],
    feedback: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showPassword: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    fluid: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event']]
    }],
    onFocus: [{
      type: HostListener,
      args: ['focus']
    }],
    onBlur: [{
      type: HostListener,
      args: ['blur']
    }],
    onKeyup: [{
      type: HostListener,
      args: ['keyup', ['$event']]
    }]
  }
});
class MapperPipe {
  transform(value, mapper, ...args) {
    return mapper(value, ...args);
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: MapperPipe,
    deps: [],
    target: i0.ɵɵFactoryTarget.Pipe
  });
  static ɵpipe = i0.ɵɵngDeclarePipe({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: MapperPipe,
    isStandalone: true,
    name: "mapper"
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: MapperPipe,
  decorators: [{
    type: Pipe,
    args: [{
      name: 'mapper',
      pure: true,
      standalone: true
    }]
  }]
});
const Password_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Password),
  multi: true
};
/**
 * Password displays strength indicator for password fields.
 * @group Components
 */
class Password extends BaseComponent {
  /**
   * Defines a string that labels the input for accessibility.
   * @group Props
   */
  ariaLabel;
  /**
   * Whether the component should span the full width of its parent.
   * @group Props
   */
  fluid;
  /**
   * Specifies one or more IDs in the DOM that labels the input field.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Label of the input for accessibility.
   * @group Props
   */
  label;
  /**
   * Indicates whether the component is disabled or not.
   * @group Props
   */
  disabled;
  /**
   * Text to prompt password entry. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  promptLabel;
  /**
   * Regex value for medium regex.
   * @group Props
   */
  mediumRegex = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})';
  /**
   * Regex value for strong regex.
   * @group Props
   */
  strongRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';
  /**
   * Text for a weak password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  weakLabel;
  /**
   * Text for a medium password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  mediumLabel;
  /**
   * specifies the maximum number of characters allowed in the input element.
   * @group Props
   */
  maxLength;
  /**
   * Text for a strong password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  strongLabel;
  /**
   * Identifier of the accessible input element.
   * @group Props
   */
  inputId;
  /**
   * Whether to show the strength indicator or not.
   * @group Props
   */
  feedback = true;
  /**
   * Id of the element or "body" for document where the overlay should be appended to.
   * @group Props
   */
  appendTo;
  /**
   * Whether to show an icon to display the password as plain text.
   * @group Props
   */
  toggleMask;
  /**
   * Defines the size of the component.
   * @group Props
   */
  size;
  /**
   * Style class of the input field.
   * @group Props
   */
  inputStyleClass;
  /**
   * Style class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Inline style of the input field.
   * @group Props
   */
  inputStyle;
  /**
   * Transition options of the show animation.
   * @group Props
   */
  showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Transition options of the hide animation.
   * @group Props
   */
  hideTransitionOptions = '.1s linear';
  /**
   * Specify automated assistance in filling out password by browser.
   * @group Props
   */
  autocomplete;
  /**
   * Advisory information to display on input.
   * @group Props
   */
  placeholder;
  /**
   * When enabled, a clear icon is displayed to clear the value.
   * @group Props
   */
  showClear = false;
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  autofocus;
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex;
  /**
   * Callback to invoke when the component receives focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  /**
   * Callback to invoke when the component loses focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  /**
   * Callback to invoke when clear button is clicked.
   * @group Emits
   */
  onClear = new EventEmitter();
  input;
  contentTemplate;
  footerTemplate;
  headerTemplate;
  clearIconTemplate;
  hideIconTemplate;
  showIconTemplate;
  templates;
  _contentTemplate;
  _footerTemplate;
  _headerTemplate;
  _clearIconTemplate;
  _hideIconTemplate;
  _showIconTemplate;
  overlayVisible = false;
  meter;
  infoText;
  focused = false;
  unmasked = false;
  mediumCheckRegExp;
  strongCheckRegExp;
  resizeListener;
  scrollHandler;
  overlay;
  value = null;
  onModelChange = () => {};
  onModelTouched = () => {};
  translationSubscription;
  _componentStyle = inject(PasswordStyle);
  get hasFluid() {
    const nativeElement = this.el.nativeElement;
    const fluidComponent = nativeElement.closest('p-fluid');
    return this.fluid || !!fluidComponent;
  }
  overlayService = inject(OverlayService);
  ngOnInit() {
    super.ngOnInit();
    this.infoText = this.promptText();
    this.mediumCheckRegExp = new RegExp(this.mediumRegex);
    this.strongCheckRegExp = new RegExp(this.strongRegex);
    this.translationSubscription = this.config.translationObserver.subscribe(() => {
      this.updateUI(this.value || '');
    });
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this._contentTemplate = item.template;
          break;
        case 'header':
          this._headerTemplate = item.template;
          break;
        case 'footer':
          this._footerTemplate = item.template;
          break;
        case 'clearicon':
          this._clearIconTemplate = item.template;
          break;
        case 'hideicon':
          this._hideIconTemplate = item.template;
          break;
        case 'showicon':
          this._showIconTemplate = item.template;
          break;
        default:
          this._contentTemplate = item.template;
          break;
      }
    });
  }
  onAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        this.overlay = event.element;
        ZIndexUtils.set('overlay', this.overlay, this.config.zIndex.overlay);
        this.appendContainer();
        this.alignOverlay();
        this.bindScrollListener();
        this.bindResizeListener();
        break;
      case 'void':
        this.unbindScrollListener();
        this.unbindResizeListener();
        this.overlay = null;
        break;
    }
  }
  onAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        ZIndexUtils.clear(event.element);
        break;
    }
  }
  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body') this.renderer.appendChild(this.document.body, this.overlay);else this.document.getElementById(this.appendTo).appendChild(this.overlay);
    }
  }
  alignOverlay() {
    if (this.appendTo) {
      this.overlay.style.minWidth = getOuterWidth(this.input.nativeElement) + 'px';
      absolutePosition(this.overlay, this.input.nativeElement);
    } else {
      relativePosition(this.overlay, this.input.nativeElement);
    }
  }
  onInput(event) {
    this.value = event.target.value;
    this.onModelChange(this.value);
  }
  onInputFocus(event) {
    this.focused = true;
    if (this.feedback) {
      this.overlayVisible = true;
    }
    this.onFocus.emit(event);
  }
  onInputBlur(event) {
    this.focused = false;
    if (this.feedback) {
      this.overlayVisible = false;
    }
    this.onModelTouched();
    this.onBlur.emit(event);
  }
  onKeyUp(event) {
    if (this.feedback) {
      let value = event.target.value;
      this.updateUI(value);
      if (event.code === 'Escape') {
        this.overlayVisible && (this.overlayVisible = false);
        return;
      }
      if (!this.overlayVisible) {
        this.overlayVisible = true;
      }
    }
  }
  updateUI(value) {
    let label = null;
    let meter = null;
    switch (this.testStrength(value)) {
      case 1:
        label = this.weakText();
        meter = {
          strength: 'weak',
          width: '33.33%'
        };
        break;
      case 2:
        label = this.mediumText();
        meter = {
          strength: 'medium',
          width: '66.66%'
        };
        break;
      case 3:
        label = this.strongText();
        meter = {
          strength: 'strong',
          width: '100%'
        };
        break;
      default:
        label = this.promptText();
        meter = null;
        break;
    }
    this.meter = meter;
    this.infoText = label;
  }
  onMaskToggle() {
    this.unmasked = !this.unmasked;
  }
  onOverlayClick(event) {
    this.overlayService.add({
      originalEvent: event,
      target: this.el.nativeElement
    });
  }
  testStrength(str) {
    let level = 0;
    if (this.strongCheckRegExp.test(str)) level = 3;else if (this.mediumCheckRegExp.test(str)) level = 2;else if (str.length) level = 1;
    return level;
  }
  writeValue(value) {
    if (value === undefined) this.value = null;else this.value = value;
    if (this.feedback) this.updateUI(this.value || '');
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
    this.cd.markForCheck();
  }
  bindScrollListener() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.scrollHandler) {
        this.scrollHandler = new ConnectedOverlayScrollHandler(this.input.nativeElement, () => {
          if (this.overlayVisible) {
            this.overlayVisible = false;
          }
        });
      }
      this.scrollHandler.bindScrollListener();
    }
  }
  bindResizeListener() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.resizeListener) {
        const window = this.document.defaultView;
        this.resizeListener = this.renderer.listen(window, 'resize', () => {
          if (this.overlayVisible && !isTouchDevice()) {
            this.overlayVisible = false;
          }
        });
      }
    }
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  unbindResizeListener() {
    if (this.resizeListener) {
      this.resizeListener();
      this.resizeListener = null;
    }
  }
  containerClass(toggleMask) {
    return {
      'p-password p-component p-inputwrapper': true,
      'p-input-icon-right': toggleMask
    };
  }
  get rootClass() {
    return this._componentStyle.classes.root({
      instance: this
    });
  }
  inputFieldClass(disabled) {
    return {
      'p-password-input': true,
      'p-disabled': disabled
    };
  }
  strengthClass(meter) {
    return `p-password-meter-label p-password-meter${meter?.strength ? `-${meter.strength}` : ''}`;
  }
  filled() {
    return this.value != null && this.value.toString().length > 0;
  }
  promptText() {
    return this.promptLabel || this.getTranslation(TranslationKeys.PASSWORD_PROMPT);
  }
  weakText() {
    return this.weakLabel || this.getTranslation(TranslationKeys.WEAK);
  }
  mediumText() {
    return this.mediumLabel || this.getTranslation(TranslationKeys.MEDIUM);
  }
  strongText() {
    return this.strongLabel || this.getTranslation(TranslationKeys.STRONG);
  }
  restoreAppend() {
    if (this.overlay && this.appendTo) {
      if (this.appendTo === 'body') this.renderer.removeChild(this.document.body, this.overlay);else this.document.getElementById(this.appendTo).removeChild(this.overlay);
    }
  }
  inputType(unmasked) {
    return unmasked ? 'text' : 'password';
  }
  getTranslation(option) {
    return this.config.getTranslation(option);
  }
  clear() {
    this.value = null;
    this.onModelChange(this.value);
    this.writeValue(this.value);
    this.onClear.emit();
  }
  ngOnDestroy() {
    if (this.overlay) {
      ZIndexUtils.clear(this.overlay);
      this.overlay = null;
    }
    this.restoreAppend();
    this.unbindResizeListener();
    if (this.scrollHandler) {
      this.scrollHandler.destroy();
      this.scrollHandler = null;
    }
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Password,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: Password,
    isStandalone: true,
    selector: "p-password",
    inputs: {
      ariaLabel: "ariaLabel",
      fluid: ["fluid", "fluid", booleanAttribute],
      ariaLabelledBy: "ariaLabelledBy",
      label: "label",
      disabled: ["disabled", "disabled", booleanAttribute],
      promptLabel: "promptLabel",
      mediumRegex: "mediumRegex",
      strongRegex: "strongRegex",
      weakLabel: "weakLabel",
      mediumLabel: "mediumLabel",
      maxLength: ["maxLength", "maxLength", numberAttribute],
      strongLabel: "strongLabel",
      inputId: "inputId",
      feedback: ["feedback", "feedback", booleanAttribute],
      appendTo: "appendTo",
      toggleMask: ["toggleMask", "toggleMask", booleanAttribute],
      size: "size",
      inputStyleClass: "inputStyleClass",
      styleClass: "styleClass",
      style: "style",
      inputStyle: "inputStyle",
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions",
      autocomplete: "autocomplete",
      placeholder: "placeholder",
      showClear: ["showClear", "showClear", booleanAttribute],
      autofocus: ["autofocus", "autofocus", booleanAttribute],
      variant: "variant",
      tabindex: ["tabindex", "tabindex", numberAttribute]
    },
    outputs: {
      onFocus: "onFocus",
      onBlur: "onBlur",
      onClear: "onClear"
    },
    providers: [Password_VALUE_ACCESSOR, PasswordStyle],
    queries: [{
      propertyName: "contentTemplate",
      first: true,
      predicate: ["content"]
    }, {
      propertyName: "footerTemplate",
      first: true,
      predicate: ["footer"]
    }, {
      propertyName: "headerTemplate",
      first: true,
      predicate: ["header"]
    }, {
      propertyName: "clearIconTemplate",
      first: true,
      predicate: ["clearicon"]
    }, {
      propertyName: "hideIconTemplate",
      first: true,
      predicate: ["headericon"]
    }, {
      propertyName: "showIconTemplate",
      first: true,
      predicate: ["showicon"]
    }, {
      propertyName: "templates",
      predicate: PrimeTemplate
    }],
    viewQueries: [{
      propertyName: "input",
      first: true,
      predicate: ["input"],
      descendants: true
    }],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <div [ngClass]="rootClass" [ngStyle]="style" [class]="styleClass" [attr.data-pc-name]="'password'" [attr.data-pc-section]="'root'">
            <input
                #input
                [attr.label]="label"
                [attr.aria-label]="ariaLabel"
                [attr.aria-labelledBy]="ariaLabelledBy"
                [attr.id]="inputId"
                [attr.tabindex]="tabindex"
                pInputText
                [disabled]="disabled"
                [pSize]="size"
                [ngClass]="disabled | mapper: inputFieldClass"
                [ngStyle]="inputStyle"
                [class]="inputStyleClass"
                [attr.type]="unmasked | mapper: inputType"
                [attr.placeholder]="placeholder"
                [attr.autocomplete]="autocomplete"
                [value]="value"
                [variant]="variant"
                (input)="onInput($event)"
                (focus)="onInputFocus($event)"
                (blur)="onInputBlur($event)"
                (keyup)="onKeyUp($event)"
                [attr.maxlength]="maxLength"
                [attr.data-pc-section]="'input'"
                [pAutoFocus]="autofocus"
            />
            <ng-container *ngIf="showClear && value != null">
                <TimesIcon *ngIf="!clearIconTemplate && !_clearIconTemplate" class="p-password-clear-icon" (click)="clear()" [attr.data-pc-section]="'clearIcon'" />
                <span (click)="clear()" class="p-password-clear-icon" [attr.data-pc-section]="'clearIcon'">
                    <ng-template *ngTemplateOutlet="clearIconTemplate || _clearIconTemplate"></ng-template>
                </span>
            </ng-container>

            <ng-container *ngIf="toggleMask">
                <ng-container *ngIf="unmasked">
                    <EyeSlashIcon class="p-password-toggle-mask-icon p-password-mask-icon" *ngIf="!hideIconTemplate && !_hideIconTemplate" (click)="onMaskToggle()" [attr.data-pc-section]="'hideIcon'" />
                    <span *ngIf="hideIconTemplate || _hideIconTemplate" (click)="onMaskToggle()">
                        <ng-template *ngTemplateOutlet="hideIconTemplate || _hideIconTemplate; context: { class: 'p-password-toggle-mask-icon p-password-mask-icon' }"></ng-template>
                    </span>
                </ng-container>
                <ng-container *ngIf="!unmasked">
                    <EyeIcon *ngIf="!showIconTemplate && !_showIconTemplate" class="p-password-toggle-mask-icon p-password-mask-icon" (click)="onMaskToggle()" [attr.data-pc-section]="'showIcon'" />
                    <span *ngIf="showIconTemplate || _showIconTemplate" (click)="onMaskToggle()">
                        <ng-template *ngTemplateOutlet="showIconTemplate || _showIconTemplate"></ng-template>
                    </span>
                </ng-container>
            </ng-container>

            <div
                #overlay
                *ngIf="overlayVisible"
                class="p-password-overlay p-component"
                (click)="onOverlayClick($event)"
                [@overlayAnimation]="{
                    value: 'visible',
                    params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
                }"
                (@overlayAnimation.start)="onAnimationStart($event)"
                (@overlayAnimation.done)="onAnimationEnd($event)"
                [attr.data-pc-section]="'panel'"
            >
                <ng-container *ngTemplateOutlet="headerTemplate || _headerTemplate"></ng-container>
                <ng-container *ngIf="contentTemplate || _contentTemplate; else content">
                    <ng-container *ngTemplateOutlet="contentTemplate || _contentTemplate"></ng-container>
                </ng-container>
                <ng-template #content>
                    <div class="p-password-content">
                        <div class="p-password-meter" [attr.data-pc-section]="'meter'">
                            <div [ngClass]="meter | mapper: strengthClass" [ngStyle]="{ width: meter ? meter.width : '' }" [attr.data-pc-section]="'meterLabel'"></div>
                        </div>
                        <div class="p-password-meter-text" [attr.data-pc-section]="'info'">{{ infoText }}</div>
                    </div>
                </ng-template>
                <ng-container *ngTemplateOutlet="footerTemplate || _footerTemplate"></ng-container>
            </div>
        </div>
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i1.NgStyle,
      selector: "[ngStyle]",
      inputs: ["ngStyle"]
    }, {
      kind: "directive",
      type: InputText,
      selector: "[pInputText]",
      inputs: ["variant", "fluid", "pSize"]
    }, {
      kind: "directive",
      type: AutoFocus,
      selector: "[pAutoFocus]",
      inputs: ["autofocus", "pAutoFocus"]
    }, {
      kind: "component",
      type: TimesIcon,
      selector: "TimesIcon"
    }, {
      kind: "component",
      type: EyeSlashIcon,
      selector: "EyeSlashIcon"
    }, {
      kind: "component",
      type: EyeIcon,
      selector: "EyeIcon"
    }, {
      kind: "pipe",
      type: MapperPipe,
      name: "mapper"
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    animations: [trigger('overlayAnimation', [transition(':enter', [style({
      opacity: 0,
      transform: 'scaleY(0.8)'
    }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
      opacity: 0
    }))])])],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Password,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-password',
      standalone: true,
      imports: [CommonModule, InputText, AutoFocus, TimesIcon, EyeSlashIcon, EyeIcon, MapperPipe, SharedModule],
      template: `
        <div [ngClass]="rootClass" [ngStyle]="style" [class]="styleClass" [attr.data-pc-name]="'password'" [attr.data-pc-section]="'root'">
            <input
                #input
                [attr.label]="label"
                [attr.aria-label]="ariaLabel"
                [attr.aria-labelledBy]="ariaLabelledBy"
                [attr.id]="inputId"
                [attr.tabindex]="tabindex"
                pInputText
                [disabled]="disabled"
                [pSize]="size"
                [ngClass]="disabled | mapper: inputFieldClass"
                [ngStyle]="inputStyle"
                [class]="inputStyleClass"
                [attr.type]="unmasked | mapper: inputType"
                [attr.placeholder]="placeholder"
                [attr.autocomplete]="autocomplete"
                [value]="value"
                [variant]="variant"
                (input)="onInput($event)"
                (focus)="onInputFocus($event)"
                (blur)="onInputBlur($event)"
                (keyup)="onKeyUp($event)"
                [attr.maxlength]="maxLength"
                [attr.data-pc-section]="'input'"
                [pAutoFocus]="autofocus"
            />
            <ng-container *ngIf="showClear && value != null">
                <TimesIcon *ngIf="!clearIconTemplate && !_clearIconTemplate" class="p-password-clear-icon" (click)="clear()" [attr.data-pc-section]="'clearIcon'" />
                <span (click)="clear()" class="p-password-clear-icon" [attr.data-pc-section]="'clearIcon'">
                    <ng-template *ngTemplateOutlet="clearIconTemplate || _clearIconTemplate"></ng-template>
                </span>
            </ng-container>

            <ng-container *ngIf="toggleMask">
                <ng-container *ngIf="unmasked">
                    <EyeSlashIcon class="p-password-toggle-mask-icon p-password-mask-icon" *ngIf="!hideIconTemplate && !_hideIconTemplate" (click)="onMaskToggle()" [attr.data-pc-section]="'hideIcon'" />
                    <span *ngIf="hideIconTemplate || _hideIconTemplate" (click)="onMaskToggle()">
                        <ng-template *ngTemplateOutlet="hideIconTemplate || _hideIconTemplate; context: { class: 'p-password-toggle-mask-icon p-password-mask-icon' }"></ng-template>
                    </span>
                </ng-container>
                <ng-container *ngIf="!unmasked">
                    <EyeIcon *ngIf="!showIconTemplate && !_showIconTemplate" class="p-password-toggle-mask-icon p-password-mask-icon" (click)="onMaskToggle()" [attr.data-pc-section]="'showIcon'" />
                    <span *ngIf="showIconTemplate || _showIconTemplate" (click)="onMaskToggle()">
                        <ng-template *ngTemplateOutlet="showIconTemplate || _showIconTemplate"></ng-template>
                    </span>
                </ng-container>
            </ng-container>

            <div
                #overlay
                *ngIf="overlayVisible"
                class="p-password-overlay p-component"
                (click)="onOverlayClick($event)"
                [@overlayAnimation]="{
                    value: 'visible',
                    params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
                }"
                (@overlayAnimation.start)="onAnimationStart($event)"
                (@overlayAnimation.done)="onAnimationEnd($event)"
                [attr.data-pc-section]="'panel'"
            >
                <ng-container *ngTemplateOutlet="headerTemplate || _headerTemplate"></ng-container>
                <ng-container *ngIf="contentTemplate || _contentTemplate; else content">
                    <ng-container *ngTemplateOutlet="contentTemplate || _contentTemplate"></ng-container>
                </ng-container>
                <ng-template #content>
                    <div class="p-password-content">
                        <div class="p-password-meter" [attr.data-pc-section]="'meter'">
                            <div [ngClass]="meter | mapper: strengthClass" [ngStyle]="{ width: meter ? meter.width : '' }" [attr.data-pc-section]="'meterLabel'"></div>
                        </div>
                        <div class="p-password-meter-text" [attr.data-pc-section]="'info'">{{ infoText }}</div>
                    </div>
                </ng-template>
                <ng-container *ngTemplateOutlet="footerTemplate || _footerTemplate"></ng-container>
            </div>
        </div>
    `,
      animations: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])],
      providers: [Password_VALUE_ACCESSOR, PasswordStyle],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }],
  propDecorators: {
    ariaLabel: [{
      type: Input
    }],
    fluid: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    promptLabel: [{
      type: Input
    }],
    mediumRegex: [{
      type: Input
    }],
    strongRegex: [{
      type: Input
    }],
    weakLabel: [{
      type: Input
    }],
    mediumLabel: [{
      type: Input
    }],
    maxLength: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    strongLabel: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    feedback: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    appendTo: [{
      type: Input
    }],
    toggleMask: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    size: [{
      type: Input
    }],
    inputStyleClass: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    inputStyle: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    autocomplete: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    showClear: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    autofocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    variant: [{
      type: Input
    }],
    tabindex: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    onClear: [{
      type: Output
    }],
    input: [{
      type: ViewChild,
      args: ['input']
    }],
    contentTemplate: [{
      type: ContentChild,
      args: ['content', {
        descendants: false
      }]
    }],
    footerTemplate: [{
      type: ContentChild,
      args: ['footer', {
        descendants: false
      }]
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['header', {
        descendants: false
      }]
    }],
    clearIconTemplate: [{
      type: ContentChild,
      args: ['clearicon', {
        descendants: false
      }]
    }],
    hideIconTemplate: [{
      type: ContentChild,
      args: ['headericon', {
        descendants: false
      }]
    }],
    showIconTemplate: [{
      type: ContentChild,
      args: ['showicon', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  }
});
class PasswordModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: PasswordModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: PasswordModule,
    imports: [Password, PasswordDirective, SharedModule],
    exports: [PasswordDirective, Password, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: PasswordModule,
    imports: [Password, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: PasswordModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [Password, PasswordDirective, SharedModule],
      exports: [PasswordDirective, Password, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { MapperPipe, Password, PasswordClasses, PasswordDirective, PasswordModule, PasswordStyle, Password_VALUE_ACCESSOR };
