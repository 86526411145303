import { Routes } from '@angular/router';
import { ContactPage } from './pages/contact/contact.page';
import { CustomerWizardStep1CustomerPage } from './pages/customer-wizard/customer/step-1/customer-wizard-step-1.page';
import { CustomerWizardStep2CustomerPage } from './pages/customer-wizard/customer/step-2/customer-wizard-step-2.page';
import { CustomerWizardStep3CustomerPage } from './pages/customer-wizard/customer/step-3/customer-wizard-step-3.page';
import { CustomerWizardStep4CustomerPage } from './pages/customer-wizard/customer/step-4/customer-wizard-step-4.page';
import { CustomerWizardStep5CustomerPage } from './pages/customer-wizard/customer/step-5/customer-wizard-step-5.page';
import { CustomerWizardStep6CustomerPage } from './pages/customer-wizard/customer/step-6/customer-wizard-step-6.page';
import { CustomerWizardStep2CustomerGuard } from './pages/customer-wizard/customer/step-2/customer-wizard-step-2.guard';
import { CustomerWizardStep3CustomerGuard } from './pages/customer-wizard/customer/step-3/customer-wizard-step-3.guard';
import { CustomerWizardStep4CustomerGuard } from './pages/customer-wizard/customer/step-4/customer-wizard-step-4.guard';
import { CustomerWizardStep5CustomerGuard } from './pages/customer-wizard/customer/step-5/customer-wizard-step-5.guard';
import { CustomerWizardStep6CustomerGuard } from './pages/customer-wizard/customer/step-6/customer-wizard-step-6.guard';
import { CustomerWizardStep1SelfEmployedPage } from './pages/customer-wizard/self-employed/step-1/customer-wizard-step-1.page';
import { CustomerWizardStep2SelfEmployedPage } from './pages/customer-wizard/self-employed/step-2/customer-wizard-step-2.page';
import { CustomerWizardStep3SelfEmployedPage } from './pages/customer-wizard/self-employed/step-3/customer-wizard-step-3.page';
import { CustomerWizardStep4SelfEmployedPage } from './pages/customer-wizard/self-employed/step-4/customer-wizard-step-4.page';
import { CustomerWizardStep5SelfEmployedPage } from './pages/customer-wizard/self-employed/step-5/customer-wizard-step-5.page';
import { CustomerWizardStep6SelfEmployedPage } from './pages/customer-wizard/self-employed/step-6/customer-wizard-step-6.page';
import { CustomerWizardStep2SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-2/customer-wizard-step-2.guard';
import { CustomerWizardStep3SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-3/customer-wizard-step-3.guard';
import { CustomerWizardStep4SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-4/customer-wizard-step-4.guard';
import { CustomerWizardStep5SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-5/customer-wizard-step-5.guard';
import { CustomerWizardStep6SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-6/customer-wizard-step-6.guard';
import { HomePage } from './pages/home/particulieren/home-particulieren.page';
import { HomeKMOPage } from './pages/home/kmo/home-kmo.page';
import { HomeZelfstandigenPage } from './pages/home/zelfstandigen/home-zelfstandigen.page';
import { PriceCalculationStep1Page } from './pages/price-calculation/step-1/price-calculation-step-1.page';
import { PriceCalculationStep2Page } from './pages/price-calculation/step-2/price-calculation-step-2.page';
import { PriceCalculationStep2aPage } from './pages/price-calculation/step-2a/price-calculation-step-2a.page';
import { PriceCalculationStep2bPage } from './pages/price-calculation/step-2b/price-calculation-step-2b.page';
import { PriceCalculationStep3Page } from './pages/price-calculation/step-3/price-calculation-step-3.page';
import { PriceCalculationStep4Page } from './pages/price-calculation/step-4/price-calculation-step-4.page';
import { PriceCalculationStep2cPage } from './pages/price-calculation/step-2c/price-calculation-step-2c.page';
import { PriceCalculationStep2dPage } from './pages/price-calculation/step-2d/price-calculation-step-2d.page';
import { PriceCalculationStep2ePage } from './pages/price-calculation/step-2e/price-calculation-step-2e.page';
import { PriceCalculationStep2aGuard } from './pages/price-calculation/step-2a/price-calculation-step-2a.guard';
import { PriceCalculationStep2bGuard } from './pages/price-calculation/step-2b/price-calculation-step-2b.guard';
import { PriceCalculationStep2cGuard } from './pages/price-calculation/step-2c/price-calculation-step-2c.guard';
import { PriceCalculationStep2dGuard } from './pages/price-calculation/step-2d/price-calculation-step-2d.guard';
import { PriceCalculationStep2eGuard } from './pages/price-calculation/step-2e/price-calculation-step-2e.guard';
import { PriceCalculationStep3Guard } from './pages/price-calculation/step-3/price-calculation-step-3.guard';
import { PriceCalculationStep4Guard } from './pages/price-calculation/step-4/price-calculation-step-4.guard';
import { FAQPage } from './pages/faq/faq.page';
import { NewsPage } from './pages/news/news.page';
import { AboutUsPage } from './pages/about-us/about-us.page';
import { DynamicTariffsPage } from './pages/dynamic-tariffs/dynamic-tariffs.page';
import { MovingPage } from './pages/moving/moving.page';
import { ConstructionOrRenovationPage } from './pages/construction-or-renovation/construction-or-renovation.page';
import { DistributionManagerPage } from './pages/distribution-manager/distribution-manager.page';
import { TariffsPage } from './pages/tariffs/tariffs.page';
import { GlossariumPage } from './pages/glossarium/glossarium.page';
import { CookiePolicyPage } from './pages/cookie-policy/cookie-policy.page';
import { PrivacyPolicyPage } from './pages/privacy-policy/privacy-policy.page';
import { SearchPage } from './pages/search/search.page';
import { ProjectsPage } from './pages/projects/projects.page';
import { ConsumerProtectionPage } from './pages/consumer-protection/consumer-protection.page';
import { VacanciesPage } from './pages/vacancies/vacancies.page';
import { InfoPage } from './pages/info/info.page';
import { EnergyMarketPage } from './pages/energy-market/energy-market.page';
import { PriceCalculationProposal } from './pages/price-calculation/proposal/price-calculation-proposal';

export const routes: Routes = [
  {
    path: '',
    component: HomePage,
    title: 'Particulieren'
  },
  {
    path: 'Zelfstandigen',
    component: HomeZelfstandigenPage,
    title: 'Zelfstandigen'
  },
  {
    path: 'KMO',
    component: HomeKMOPage,
    title: 'KMO'
  },
  {
    path: 'about-us',
    component: AboutUsPage,
    title: 'Over ons'
  },
  {
    path: 'artikels/:title',
    component: NewsPage,
    title: 'Nieuws'
  },
  {
    path: 'energy-market',
    component: EnergyMarketPage,
    title: 'Vrije energiemarkt'
  },
  {
    path: 'cookiepolicy',
    component: CookiePolicyPage,
    title: 'Cookiebeleid'
  },
  {
    path: 'construction-or-renovation',
    component: ConstructionOrRenovationPage,
    title: 'Nieuwbouw of renovatie'
  },
  {
    path: 'consumer-protection',
    component: ConsumerProtectionPage,
    title: 'Consumentenbescherming'
  },
  {
    path: 'customer-wizard',
    title: 'Klant worden',
    children: [
      {
        path: 'customer',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'step-1',
          },
          {
            path: 'step-1',
            component: CustomerWizardStep1CustomerPage
          },
          {
            path: 'step-2',
            component: CustomerWizardStep2CustomerPage,
            canActivate: [CustomerWizardStep2CustomerGuard]
          },
          {
            path: 'step-3',
            component: CustomerWizardStep3CustomerPage,
            canActivate: [CustomerWizardStep3CustomerGuard]
          },
          {
            path: 'step-4',
            component: CustomerWizardStep4CustomerPage,
            canActivate: [CustomerWizardStep4CustomerGuard]
          },
          {
            path: 'step-5',
            component: CustomerWizardStep5CustomerPage,
            canActivate: [CustomerWizardStep5CustomerGuard]
          },
          {
            path: 'step-6',
            component: CustomerWizardStep6CustomerPage,
            canActivate: [CustomerWizardStep6CustomerGuard]
          }
        ]
      },
      {
        path: 'self-employed',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'step-1',
          },
          {
            path: 'step-1',
            component: CustomerWizardStep1SelfEmployedPage
          },
          {
            path: 'step-2',
            component: CustomerWizardStep2SelfEmployedPage,
            canActivate: [CustomerWizardStep2SelfEmployedGuard]
          },
          {
            path: 'step-3',
            component: CustomerWizardStep3SelfEmployedPage,
            canActivate: [CustomerWizardStep3SelfEmployedGuard]
          },
          {
            path: 'step-4',
            component: CustomerWizardStep4SelfEmployedPage,
            canActivate: [CustomerWizardStep4SelfEmployedGuard]
          },
          {
            path: 'step-5',
            component: CustomerWizardStep5SelfEmployedPage,
            canActivate: [CustomerWizardStep5SelfEmployedGuard]
          },
          {
            path: 'step-6',
            component: CustomerWizardStep6SelfEmployedPage,
            canActivate: [CustomerWizardStep6SelfEmployedGuard]
          }
        ]
      }
    ]
  },
  {
    path: 'contact',
    component: ContactPage,
    title: 'Contact'
  },
  {
    path: 'distribution-manager',
    component: DistributionManagerPage,
    title: 'Distributienetbeheerder'
  },
  {
    path: 'dynamic-tariffs',
    component: DynamicTariffsPage,
    title: 'Dynamische tarieven'
  },
  {
    path: 'glossary',
    component: GlossariumPage,
    title: 'Glossarium'
  },
  {
    path: 'info',
    component: InfoPage,
    title: 'Info'
  },
  {
    path: 'info/:tab',
    component: InfoPage,
    title: 'Info'
  },
  {
    path: 'price-calculation',
    title: 'Prijs voorstel',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'step-1',
      },
      {
        path: 'proposal/:id',
        component: PriceCalculationProposal
      },
      {
        path: 'step-1',
        component: PriceCalculationStep1Page
      },
      {
        path: 'step-2',
        component: PriceCalculationStep2Page
      },
      {
        path: 'step-2a',
        component: PriceCalculationStep2aPage,
        canActivate: [PriceCalculationStep2aGuard]
      },
      {
        path: 'step-2b',
        component: PriceCalculationStep2bPage,
        canActivate: [PriceCalculationStep2bGuard]
      },
      {
        path: 'step-2c',
        component: PriceCalculationStep2cPage,
        canActivate: [PriceCalculationStep2cGuard]
      },
      {
        path: 'step-2d',
        component: PriceCalculationStep2dPage,
        canActivate: [PriceCalculationStep2dGuard]
      },
      {
        path: 'step-2e',
        component: PriceCalculationStep2ePage,
        canActivate: [PriceCalculationStep2eGuard]
      },
      {
        path: 'step-3',
        component: PriceCalculationStep3Page,
        canActivate: [PriceCalculationStep3Guard]
      },
      {
        path: 'step-4',
        component: PriceCalculationStep4Page,
        canActivate: [PriceCalculationStep4Guard]
      }
    ]
  },
  {
    path: 'faq',
    component: FAQPage,
    title: 'Veelgestelde vragen'
  },
  {
    path: 'moving',
    component: MovingPage,
    title: 'Verhuizen'
  },
  {
    path: 'news/:title',
    component: NewsPage,
    title: 'Nieuws'
  },
  {
    path: 'privacypolicy',
    component: PrivacyPolicyPage,
    title: 'Privacybeleid'
  },
  {
    path: 'projects',
    component: ProjectsPage,
    title: 'Projecten'
  },
  {
    path: 'search',
    component: SearchPage,
    title: 'Zoeken'
  },
  {
    path: 'tariffs',
    component: TariffsPage,
    title: 'Tarieven'
  },
  {
    path: 'vacancies',
    component: VacanciesPage,
    title: 'Vacatures'
  },
  {
    path: '**',
    redirectTo: ''
  }
];
