export class Contact {
    public bedrijf!: string;
    public btwNummer!: string;
    public voornaam!: string;
    public naam!: string;
    public postCode!: string | number | undefined;
    public plaats!: string;
    public email!: string;
    public telefoon!: string;
    public bericht!: string;
}