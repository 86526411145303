.radio-left {
    position: relative;
    float: left;
}

.checkbox-left {
    position: relative;
    float: left;
}

.close-button-right {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 15px;
}