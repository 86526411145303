<div class="row">
    <div class="xl:col-5 lg:col-5 col-12">
        <h2><img class="icon" [src]="'icons/mission-statement-icon.png'"/>Wij geloven in een duurzame economie die het milieu respecteert.</h2>
    </div>
    <div clas="xl:col-2 lg-col-2 col-12"></div>
    <div class="xl:col-5 lg:col-5 col-12">
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p>Daarom investeren we lokaal in hernieuwbare energie, zoals biogasmotoren en PV-installaties. De dingen waar we goed in zijn, doen we zelf: klantenservice, facturatie en strategieontwikkeling.</p>
                <p>Waar anderen beter in zijn, besteden we lokaal uit, zoals ICT, drukwerk en projectontwikkeling. Zo creëren we banen in heel Vlaanderen. Word nu Ebem-klant en investeer samen met ons in lokale energie en mensen.</p>
            </div>
        </div>
        <div class="row">
            <div class="xl:col lg:col col-12">
                <p-button class="mr-2" [routerLink]="['/customer-wizard/' + customerType, 'step-1']">Klant worden</p-button>
                <p-button class="ml-2" outlined="true" [routerLink]="['/price-calculation', 'step-1']">Prijs berekenen</p-button>
            </div>
            <div class="xl:col-4 lg:col-8 col-12">
            </div>
        </div>
    </div>
</div>
<br/>
<br/>