import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ChartData, ChartOptions} from "chart.js";
import { ButtonModule } from "primeng/button";
import { ChartModule } from 'primeng/chart';
import { DatePickerModule } from "primeng/datepicker";

@Component({
    selector: 'graph-component',
    standalone: true,
    templateUrl: './graph.component.html',
    styleUrls: ['graph.component.scss'],
    imports: [ ButtonModule, ChartModule, DatePickerModule, FormsModule ]
})
export class GraphComponent {
    @Input() data: ChartData = {
        labels: [],
        datasets: []
    }

    @Input() options: ChartOptions = {}
}