import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox"
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectModule } from 'primeng/select';
import { ElectricityType } from "src/app/models/enums/electricity-type-simulation.enum";
import { GasType } from "src/app/models/enums/gas-type-simulation.enum";
import { RouterModule } from "@angular/router";
import { PriceSimulationChoice } from "src/app/models/enums/price-simulation-choice.enum";
import { PriceSimulationDirectDebit, PriceSimulationEnergyConsumers, PriceSimulationHeatingType, PriceSimulationMeterType, PriceSimulationOptions, PriceSimulationSolarPanels, PriceSimulationTariffType } from "src/app/models/enums/price-simulation-options.enum";
import { BuildingType } from "src/app/models/enums/building-type.enum";
import { CommonModule } from "@angular/common";
import { CardModule } from "primeng/card";
import { ProgressBarModule } from "primeng/progressbar";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";
import { PostalCode } from "src/app/models/postalcode.model";
import { PostalcodeService } from "src/app/services/postalcode.service";

@Component({
    selector: 'price-calculation-step-2e',
    standalone: true,
    styleUrls: ['./price-calculation-step-2e.page.scss'],
    templateUrl: './price-calculation-step-2e.page.html',
    imports: [ButtonModule, CardModule, CheckboxModule, CommonModule, InputGroupModule, InputNumberModule, InputTextModule, InputGroupAddonModule, FormsModule, OverlayPanelModule, ProgressBarModule, RadioButtonModule, RouterModule, SelectModule],
    providers: [PostalcodeService]
})
export class PriceCalculationStep2ePage {
    //Enum values
    electricityType!: ElectricityType;
    gasType!: GasType;
    priceSimulationInput: PriceSimulationInput;
    priceSimulationChoices: PriceSimulationChoice[] = [];
    priceSimulationOptions: PriceSimulationOptions;
    buildingType: BuildingType = 0;

    //Enum definitions
    ElectricityType = ElectricityType;
    GasType = GasType;
    PriceSimulationChoice = PriceSimulationChoice;
    PriceSimulationMeterType = PriceSimulationMeterType;
    PriceSimulationTariffType = PriceSimulationTariffType;
    PriceSimulationSolarPanels = PriceSimulationSolarPanels;
    PriceSimulationHeatingType = PriceSimulationHeatingType;
    PriceSimulationEnergyConsumers = PriceSimulationEnergyConsumers;
    BuildingType = BuildingType;

    PostalCodes: PostalCode[] = [];

    postalCode!: string | number | undefined;

    numberOfPersons!: number;
    estimatedElectricityUse!: number;
    estimatedGasUse!: number;

    constructor(private postalCodeService: PostalcodeService) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"]; 
        this.priceSimulationInput = window.history.state["priceSimulationInput"];
        this.priceSimulationChoices = window.history.state["priceSimulationChoices"];
        this.priceSimulationOptions = window.history.state["priceSimulationOptions"] ?? <PriceSimulationOptions>{ meterType: PriceSimulationMeterType[""], tariffType: PriceSimulationTariffType[""], hasNightTariff: false, solarPanels: PriceSimulationSolarPanels[""], heatingType: PriceSimulationHeatingType[""] }; 
        this.buildingType = window.history.state["buildingType"];
        this.postalCode = window.history.state["postalCode"]; 
        this.numberOfPersons = window.history.state["numberOfPersons"];
        this.estimatedElectricityUse = window.history.state["estimatedElectricityUse"]; 
        this.estimatedGasUse = window.history.state["estimatedGasUse"];
        this.postalCodeService.getPostalCodes().subscribe(res => {
            this.PostalCodes = res.filter(x => x.isValidArea);
        });
    }

    determineType() {
        if (this.priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit)) {

            if (this.priceSimulationOptions.directDebit == PriceSimulationDirectDebit.Ja) {
                this.electricityType = ElectricityType["EBEM Groen B@sic +"];
            } else {
                if (this.priceSimulationOptions.meterType == PriceSimulationMeterType["Digitale meter"]) {
                    this.electricityType = ElectricityType["EBEM Groen Variabel"];
                }
                else {
                    this.electricityType = ElectricityType["EBEM Groen Variabel"];
                }
            }
            
        }
        if (this.priceSimulationChoices?.includes(PriceSimulationChoice.Gas)) {
            if (this.priceSimulationOptions.directDebit == PriceSimulationDirectDebit.Ja) {
                this.gasType = GasType["EBEM Aardgas Variabel"];
            }
            else {
                this.gasType = GasType["EBEM G@s +"];
            }
        }
    }

    calculateEstimates() {
        var baseE = 0;
        var baseG = 0;

        switch(this.buildingType) {
            case BuildingType.Appartement:
                baseE = 1;
                baseG = 0.65;
                break;
            case BuildingType.Rijhuis:
                baseE = 0.75;
                baseG = 0.65;
                break;
            case BuildingType["Halfopen bebouwing"]:
                baseE = 0.9;
                baseG = 0.75;
                break;
            case BuildingType["Open bebouwing"]:
                baseE = 1.2;
                baseG = 1.1;
                break;            
        }

        var usageE = 0;
        var usageG = 0;
        
        switch (this.numberOfPersons) {
            case 1:
                usageE = 600;
                usageG = 2326;
                break;
            case 2:
                usageE = 1200;
                usageG = 4652;
                break;
            case 3:
                usageE = 1800;
                usageG = 6978;
                break;
            case 4:
                usageE = 2400;
                usageG = 9304;
                break;
            case 5:
                usageE = 3000;
                usageG = 11630;
                break;               
        }

        if (this.priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit)) {
            if (this.priceSimulationOptions.solarPanels == PriceSimulationSolarPanels.Zonnepanelen) {
                if (this.priceSimulationOptions.energyConsumers.includes(PriceSimulationEnergyConsumers["Thuisbatterij"])) {
                    baseE -= 0.5;
                } else {
                    baseE -= 0.2; //Gemiddeld wordt maar 20% van opgewekte energie verbruikt
                }
            }

            if (this.priceSimulationOptions.energyConsumers.includes(PriceSimulationEnergyConsumers["Laadpaal"])) {
                baseE += 0.25;
            }

            if (this.priceSimulationOptions.energyConsumers.includes(PriceSimulationEnergyConsumers["Warmtepomp"])) {
                baseE += 0.25;
            }

            this.estimatedElectricityUse = baseE * usageE;
            if (this.priceSimulationOptions.tariffType == PriceSimulationTariffType.Enkelvoudig) { this.priceSimulationInput.kwh24u = this.estimatedElectricityUse; } else { this.priceSimulationInput.kwh24u = 0; }
            if (this.priceSimulationOptions.tariffType == PriceSimulationTariffType.Tweevoudig) { this.priceSimulationInput.kwhPiek = this.estimatedElectricityUse * (16/24) * (5/7);  } else { this.priceSimulationInput.kwhPiek = 0; }
            if (this.priceSimulationOptions.tariffType == PriceSimulationTariffType.Tweevoudig) { this.priceSimulationInput.kwhDal = this.estimatedElectricityUse * (8/24) + this.estimatedElectricityUse * (2/7);  } else { this.priceSimulationInput.kwhDal = 0; }
            if (this.priceSimulationOptions.hasNightTariff) { this.priceSimulationInput.kwhExclNacht = this.estimatedElectricityUse * (8/24) * (5/7) } else { this.priceSimulationInput.kwhExclNacht = 0 };
        } else {
            this.estimatedElectricityUse = 0;
        }
        if (this.priceSimulationChoices?.includes(PriceSimulationChoice.Gas)) {
            if (this.priceSimulationOptions.heatingType == PriceSimulationHeatingType["met Gas"]) {
                baseG += 2; //Gasverbruik verdubbeld als men verwarmd op gas
            }

            this.estimatedGasUse = baseG * usageG;
            this.priceSimulationInput.kwhGas = this.estimatedGasUse;
        } else {
            this.estimatedGasUse = 0;
        }
    }

    getNameFromPostalCode(postalCode: string | number | undefined): string {
        return this.PostalCodes.find(p => p.code == postalCode)?.name ?? '';
    }
}