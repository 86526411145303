import { Component } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { RouterModule } from "@angular/router";

@Component({
    selector: 'price-calculation-step-1',
    standalone: true,
    styleUrls: ['./price-calculation-step-1.page.scss'],
    templateUrl: './price-calculation-step-1.page.html',
    imports: [ButtonModule, RouterModule]
})
export class PriceCalculationStep1Page {
}