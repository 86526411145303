import { Component, Predicate } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccordionModule } from "primeng/accordion";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox"
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ElectricityType } from "src/app/models/enums/electricity-type-simulation.enum";
import { GasType } from "src/app/models/enums/gas-type-simulation.enum";
import { RouterModule } from "@angular/router";
import { PriceSimulationChoice } from "src/app/models/enums/price-simulation-choice.enum";
import { PriceSimulationHeatingType, PriceSimulationMeterType, PriceSimulationOptions, PriceSimulationSolarPanels, PriceSimulationTariffType } from "src/app/models/enums/price-simulation-options.enum";
import { BuildingType } from "src/app/models/enums/building-type.enum";
import { CommonModule } from "@angular/common";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ProductSelectionComponent } from "../../../components/product-selection/product-selection.component";
import { Product } from "src/app/models/product.model";
import { FilterPipe } from "src/app/pipes/filter.pipe";
import { TabsModule } from "primeng/tabs";
import { ProgressBarModule } from "primeng/progressbar";
import { ProductService } from "src/app/services/product.service";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";

@Component({
    selector: 'price-calculation-step-3',
    standalone: true,
    styleUrls: ['./price-calculation-step-3.page.scss'],
    templateUrl: './price-calculation-step-3.page.html',
    imports: [AccordionModule, ButtonModule, CheckboxModule, CommonModule, InputNumberModule, InputGroupModule, InputGroupAddonModule, InputTextModule, FilterPipe, FormsModule, OverlayPanelModule, ProgressBarModule, RadioButtonModule, RouterModule, TabsModule, ProductSelectionComponent],
    providers: [ProductService, RouterModule]
})
export class PriceCalculationStep3Page {
    //Enum values
    electricityType: ElectricityType;
    gasType: GasType;
    priceSimulationInput: PriceSimulationInput;
    priceSimulationChoices: PriceSimulationChoice[];
    priceSimulationOptions: PriceSimulationOptions;
    buildingType: BuildingType;

    //Enum definitions
    ElectricityType = ElectricityType;
    GasType = GasType;
    PriceSimulationChoice = PriceSimulationChoice;
    PriceSimulationMeterType = PriceSimulationMeterType;
    PriceSimulationTariffType = PriceSimulationTariffType;
    PriceSimulationSolarPanels = PriceSimulationSolarPanels;
    PriceSimulationHeatingType = PriceSimulationHeatingType;
    BuildingType = BuildingType;

    postalCode: number;

    numberOfPersons: number;
    estimatedElectricityUse: number;
    estimatedGasUse: number;

    priceGroenVariabel: number = 0;
    priceGroenDynamic: number = 0;
    priceGroenBasicPlus: number = 0;
    priceAardgasVariabel: number = 0;
    priceAardgasBasicPlus: number = 0;

    pricePerKWhGroenVariabelSingle: number = 0.11;
    pricePerKWhGroenDynamicSingle: number = 0.0965;
    pricePerKWhGroenBasicPlusSingle: number = 0.11;

    pricePerKWhGroenVariabelPeak: number = 0.12;
    pricePerKWhGroenDynamicPeak: number = 0.0965;
    pricePerKWhGroenBasicPlusPeak: number = 0.11;

    pricePerKWhGroenVariabelOffPeak: number = 0.099;
    pricePerKWhGroenDynamicOffPeak: number = 0.0965;
    pricePerKWhGroenBasicPlusOffPeak: number = 0.11;

    pricePerKWhAardgasVariabel: number = 0.105;
    pricePerKWhAardgasBasicPlus: number = 0.105;

    fixedPriceGroenVariabel: number = 84.91;
    fixedPriceGroenDynamic: number = 70.75;
    fixedPriceGroenBasicPlus: number = 70.75;
    fixedPriceAardgasVariabel: number = 70.75;
    fixedPriceAardgasBasicPlus: number = 61.32;

    costGreenEnergy: number = 0.011;
    costWKK: number = 0.0042; //WKK = Warmtekrachtkoppeling

    vatElectricity: number = 0.06;
    vatGas: number = 0.06;

    extraTaxElectricity: number = 172.91;
    extraTaxGas: number = 190.78;

    distributionCostsElectricityDataManagementPart: number = 13.16;
    distributionCostsElectricityNetUsagePart: number = 171.70;
    distributionCostsElectricityODVSupplimentsPart: number = 106.71;
    transmissionCostsElectricityNetUsagePart: number = 45.56;
    transmissionCostsElectricityODVSupplimentsPart: number = 5.5;
    distributionCostsGas: number = 257.08;

    priceTotal: number = 0;
    
    
    
        electricityProducts: Product[] = [];
        gasProducts: Product[] = [];

    constructor(private productService: ProductService) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"]; 
        this.priceSimulationInput = window.history.state["priceSimulationInput"];
        this.priceSimulationChoices = window.history.state["priceSimulationChoices"];
        this.priceSimulationOptions = window.history.state["priceSimulationOptions"]; 
        this.buildingType = window.history.state["buildingType"];
        this.postalCode = window.history.state["postalCode"]; 
        this.numberOfPersons = window.history.state["numberOfPersons"];
        this.estimatedElectricityUse = window.history.state["estimatedElectricityUse"]; 
        this.estimatedGasUse = window.history.state["estimatedGasUse"];
        if (this.priceSimulationInput) {
            this.productService.simulatePriceCalculation(this.priceSimulationInput).subscribe(products => {
                this.electricityProducts = products.filter(x => x.enumType == "ElectricityType");
                this.gasProducts = products.filter(x => x.enumType == "GasType");
                this.calculateTotalPrice();
            });
        }

        console.log(this.electricityType);
        console.log(this.gasType);
    }

    calculateTotalPrice() {
        this.priceTotal = (this.electricityProducts.find(x => x.enumValue == this.electricityType)?.price ?? 0) + (this.gasProducts.find(x => x.enumValue == this.gasType)?.price ?? 0);
    }

    includesType(type: number): Predicate<Product> {
        return (product) => product?.enumValue === type;
    }

    excludesType(type: number): Predicate<Product> {
        return (product) => product?.enumValue !== type;
    }

    castToElectricityType(type: string): ElectricityType {
        return type as unknown as ElectricityType;
    }

    castToGasType(type: string): GasType {
        return type as unknown as GasType;
    }
}