export class DistributionManager {
    public utilityType!: string;
    public name!: string;
    public street!: string;
    public postalCode!: string | number | undefined;
    public city!: string;
    public email!: string;
    public phone!: string;
    public fax!: string;
    public website!: string;
}

export function jsonToDistributionManager(json: any): DistributionManager {
    let distributionManager = new DistributionManager();
    distributionManager.utilityType = json["utilityType"];
    distributionManager.name = json["name"];
    distributionManager.street = json["street"];
    distributionManager.postalCode = json["postalCode"];
    distributionManager.city = json["city"];
    distributionManager.email = json["email"];
    distributionManager.phone = json["phone"];
    distributionManager.fax = json["fax"];
    distributionManager.website = json["website"];
    return distributionManager;
}