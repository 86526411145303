import { DOCUMENT, isPlatformServer } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, PLATFORM_ID, ElementRef, Injector, ChangeDetectorRef, Renderer2, Input, Directive } from '@angular/core';
import { ThemeService, Theme } from '@primeuix/styled';
import { uuid, getKeyValue } from '@primeuix/utils';
import { BaseStyle, Base } from 'primeng/base';
import { PrimeNG } from 'primeng/config';
class BaseComponentStyle extends BaseStyle {
  name = 'common';
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: BaseComponentStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: BaseComponentStyle,
    providedIn: 'root'
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: BaseComponentStyle,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
class BaseComponent {
  document = inject(DOCUMENT);
  platformId = inject(PLATFORM_ID);
  el = inject(ElementRef);
  injector = inject(Injector);
  cd = inject(ChangeDetectorRef);
  renderer = inject(Renderer2);
  config = inject(PrimeNG);
  baseComponentStyle = inject(BaseComponentStyle);
  baseStyle = inject(BaseStyle);
  scopedStyleEl;
  rootEl;
  dt;
  get styleOptions() {
    return {
      nonce: this.config?.csp().nonce
    };
  }
  get _name() {
    return this.constructor.name.replace(/^_/, '').toLowerCase();
  }
  get componentStyle() {
    return this['_componentStyle'];
  }
  attrSelector = uuid('pc');
  themeChangeListeners = [];
  _getHostInstance(instance) {
    if (instance) {
      return instance ? this['hostName'] ? instance['name'] === this['hostName'] ? instance : this._getHostInstance(instance.parentInstance) : instance.parentInstance : undefined;
    }
  }
  _getOptionValue(options, key = '', params = {}) {
    return getKeyValue(options, key, params);
  }
  ngOnInit() {
    if (this.document) {
      this._loadStyles();
    }
  }
  ngAfterViewInit() {
    this.rootEl = this.el?.nativeElement;
    if (this.rootEl) {
      this.rootEl?.setAttribute(this.attrSelector, '');
    }
  }
  ngOnChanges(changes) {
    if (this.document && !isPlatformServer(this.platformId)) {
      const {
        dt
      } = changes;
      if (dt && dt.currentValue) {
        this._loadScopedThemeStyles(dt.currentValue);
        this._themeChangeListener(() => this._loadScopedThemeStyles(dt.currentValue));
      }
    }
  }
  ngOnDestroy() {
    this._unloadScopedThemeStyles();
    this.themeChangeListeners.forEach(callback => ThemeService.off('theme:change', callback));
  }
  _loadStyles() {
    const _load = () => {
      if (!Base.isStyleNameLoaded('base')) {
        this.baseStyle.loadGlobalCSS(this.styleOptions);
        Base.setLoadedStyleName('base');
      }
      this._loadThemeStyles();
      // @todo update config.theme()
    };
    _load();
    this._themeChangeListener(() => _load());
  }
  _loadCoreStyles() {
    if (!Base.isStyleNameLoaded('base') && this._name) {
      this.baseComponentStyle.loadCSS(this.styleOptions);
      this.componentStyle && this.componentStyle?.loadCSS(this.styleOptions);
      Base.setLoadedStyleName(this.componentStyle?.name);
    }
  }
  _loadThemeStyles() {
    // common
    if (!Theme.isStyleNameLoaded('common')) {
      const {
        primitive,
        semantic,
        global,
        style
      } = this.componentStyle?.getCommonTheme?.() || {};
      this.baseStyle.load(primitive?.css, {
        name: 'primitive-variables',
        ...this.styleOptions
      });
      this.baseStyle.load(semantic?.css, {
        name: 'semantic-variables',
        ...this.styleOptions
      });
      this.baseStyle.load(global?.css, {
        name: 'global-variables',
        ...this.styleOptions
      });
      this.baseStyle.loadGlobalTheme({
        name: 'global-style',
        ...this.styleOptions
      }, style);
      Theme.setLoadedStyleName('common');
    }
    // component
    if (!Theme.isStyleNameLoaded(this.componentStyle?.name) && this.componentStyle?.name) {
      const {
        css,
        style
      } = this.componentStyle?.getComponentTheme?.() || {};
      this.componentStyle?.load(css, {
        name: `${this.componentStyle?.name}-variables`,
        ...this.styleOptions
      });
      this.componentStyle?.loadTheme({
        name: `${this.componentStyle?.name}-style`,
        ...this.styleOptions
      }, style);
      Theme.setLoadedStyleName(this.componentStyle?.name);
    }
    // layer order
    if (!Theme.isStyleNameLoaded('layer-order')) {
      const layerOrder = this.componentStyle?.getLayerOrderThemeCSS?.();
      this.baseStyle.load(layerOrder, {
        name: 'layer-order',
        first: true,
        ...this.styleOptions
      });
      Theme.setLoadedStyleName('layer-order');
    }
    if (this.dt) {
      this._loadScopedThemeStyles(this.dt);
      this._themeChangeListener(() => this._loadScopedThemeStyles(this.dt));
    }
  }
  _loadScopedThemeStyles(preset) {
    const {
      css
    } = this.componentStyle?.getPresetTheme?.(preset, `[${this.attrSelector}]`) || {};
    const scopedStyle = this.componentStyle?.load(css, {
      name: `${this.attrSelector}-${this.componentStyle?.name}`,
      ...this.styleOptions
    });
    this.scopedStyleEl = scopedStyle?.el;
  }
  _unloadScopedThemeStyles() {
    this.scopedStyleEl?.remove();
  }
  _themeChangeListener(callback = () => {}) {
    Base.clearLoadedStyleNames();
    ThemeService.on('theme:change', callback);
    this.themeChangeListeners.push(callback);
  }
  cx(arg, rest) {
    const classes = this.parent ? this.parent.componentStyle?.classes?.[arg] : this.componentStyle?.classes?.[arg];
    if (typeof classes === 'function') {
      return classes({
        instance: this
      });
    }
    return typeof classes === 'string' ? classes : arg;
  }
  sx(arg) {
    const styles = this.componentStyle?.inlineStyles?.[arg];
    if (typeof styles === 'function') {
      return styles({
        instance: this
      });
    }
    if (typeof styles === 'string') {
      return styles;
    } else {
      return {
        ...styles
      };
    }
  }
  // cx(key = '', params = {}) {
  //     const classes = this.parent ? this.parent.componentStyle?.classes : this.componentStyle?.classes;
  //     return this._getOptionValue(classes({ instance: this._getHostInstance(this) }), key, { ...params });
  // }
  get parent() {
    return this['parentInstance'];
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: BaseComponent,
    deps: [],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: BaseComponent,
    isStandalone: true,
    inputs: {
      dt: "dt"
    },
    providers: [BaseComponentStyle, BaseStyle],
    usesOnChanges: true,
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: BaseComponent,
  decorators: [{
    type: Directive,
    args: [{
      standalone: true,
      providers: [BaseComponentStyle, BaseStyle]
    }]
  }],
  propDecorators: {
    dt: [{
      type: Input
    }]
  }
});

/**
 * Generated bundle index. Do not edit.
 */

export { BaseComponent, BaseComponentStyle };
