import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { DistributionManager, jsonToDistributionManager } from "../models/distributionmanager.model";
import { environment } from "src/environments/environment";
import _ from "lodash";

@Injectable()
export class DistributionManagerService {
    constructor(private client: HttpClient) { }

    getDistributionManager(postalCode: string | number | undefined): Observable<DistributionManager[]> {
        return this.client.get(environment.baseUrl + '/DistributionManager/GetDistributionManager?postalCode=' + postalCode).pipe(map(res => _.map(res, x => jsonToDistributionManager(x))));
    }
}