import { Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";

@Component({
    selector: 'page-title-component',
    standalone: true,
    templateUrl: 'page-title.component.html',
    styleUrls: ['page-title.component.scss'],
    imports: [ButtonModule, RouterModule]
})
export class PageTitle {
    @Input() title!: string;
    @Input() buttonText!: string;
    @Input() buttonRoute!: string[];
}