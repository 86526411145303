import { trigger, state, transition, style, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, forwardRef, booleanAttribute, Input, Inject, ChangeDetectionStrategy, ViewEncapsulation, Component, EventEmitter, inject, ContentChild, ContentChildren, Output, NgModule } from '@angular/core';
import { hasClass } from '@primeuix/utils';
import { SharedModule, PrimeTemplate } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { ChevronDownIcon, ChevronUpIcon } from 'primeng/icons';
import { Subject } from 'rxjs';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-organizationchart-table {
    border-spacing: 0;
    border-collapse: separate;
    margin: 0 auto;
}

.p-organizationchart-table > tbody > tr > td {
    text-align: center;
    vertical-align: top;
    padding: 0 ${dt('organizationchart.gutter')};
}

.p-organizationchart-node {
    display: inline-block;
    position: relative;
    border: 1px solid ${dt('organizationchart.node.border.color')};
    background: ${dt('organizationchart.node.background')};
    color: ${dt('organizationchart.node.color')};
    padding: ${dt('organizationchart.node.padding')};
    border-radius: ${dt('organizationchart.node.border.radius')};
    transition: background ${dt('organizationchart.transition.duration')}, border-color ${dt('organizationchart.transition.duration')}, color ${dt('organizationchart.transition.duration')}, box-shadow ${dt('organizationchart.transition.duration')};
}

.p-organizationchart-node:has(.p-organizationchart-node-toggle-button) {
    padding: ${dt('organizationchart.node.toggleable.padding')};
}

.p-organizationchart-node.p-organizationchart-node-selectable:not(.p-organizationchart-node-selected):hover {
    background: ${dt('organizationchart.node.hover.background')};
    color: ${dt('organizationchart.node.hover.color')};
}

.p-organizationchart-node-selected {
    background: ${dt('organizationchart.node.selected.background')};
    color: ${dt('organizationchart.node.selected.color')};
}

.p-organizationchart-node-toggle-button {
    position: absolute;
    inset-block-end: calc(-1 * calc(${dt('organizationchart.node.toggle.button.size')} / 2));
    margin-inline-start: calc(-1 * calc(${dt('organizationchart.node.toggle.button.size')} / 2));
    z-index: 2;
    inset-inline-start: 50%;
    user-select: none;
    cursor: pointer;
    width: ${dt('organizationchart.node.toggle.button.size')};
    height: ${dt('organizationchart.node.toggle.button.size')};
    text-decoration: none;
    background: ${dt('organizationchart.node.toggle.button.background')};
    color: ${dt('organizationchart.node.toggle.button.color')};
    border-radius: ${dt('organizationchart.node.toggle.button.border.radius')};
    border: 1px solid ${dt('organizationchart.node.toggle.button.border.color')};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline-color: transparent;
    transition: background ${dt('organizationchart.transition.duration')}, color ${dt('organizationchart.transition.duration')}, border-color ${dt('organizationchart.transition.duration')}, outline-color ${dt('organizationchart.transition.duration')}, box-shadow ${dt('organizationchart.transition.duration')};
}

.p-organizationchart-node-toggle-button:hover {
    background: ${dt('organizationchart.node.toggle.button.hover.background')};
    color: ${dt('organizationchart.node.toggle.button.hover.color')};
}

.p-organizationchart-node-toggle-button:focus-visible {
    box-shadow: ${dt('breadcrumb.item.focus.ring.shadow')};
    outline: ${dt('breadcrumb.item.focus.ring.width')} ${dt('breadcrumb.item.focus.ring.style')} ${dt('breadcrumb.item.focus.ring.color')};
    outline-offset: ${dt('breadcrumb.item.focus.ring.offset')};
}

.p-organizationchart-node-toggle-button-icon {
    position: relative;
    inset-block-start: 1px;
}

.p-organizationchart-connector-down {
    margin: 0 auto;
    height: ${dt('organizationchart.connector.height')};
    width: 1px;
    background: ${dt('organizationchart.connector.color')};
}

.p-organizationchart-connector-right {
    border-radius: 0;
}

.p-organizationchart-connector-left {
    border-radius: 0;
    border-inline-end: 1px solid ${dt('organizationchart.connector.color')};
}

.p-organizationchart-connector-top {
    border-block-start: 1px solid ${dt('organizationchart.connector.color')};
}

.p-organizationchart-node-selectable {
    cursor: pointer;
}

.p-organizationchart-connectors :nth-child(1 of .p-organizationchart-connector-left) {
    border-inline-end: 0 none;
}

.p-organizationchart-connectors :nth-last-child(1 of .p-organizationchart-connector-left) {
    border-start-end-radius: ${dt('organizationchart.connector.border.radius')};
}

.p-organizationchart-connectors :nth-child(1 of .p-organizationchart-connector-right) {
    border-inline-start: 1px solid ${dt('organizationchart.connector.color')};
    border-start-start-radius: ${dt('organizationchart.connector.border.radius')};
}
`;
const classes = {
  root: 'p-organizationchart p-component',
  table: 'p-organizationchart-table',
  node: ({
    instance
  }) => ['p-organizationchart-node', {
    'p-organizationchart-node-selectable': instance.selectable,
    'p-organizationchart-node-selected': instance.selected
  }],
  nodeToggleButton: 'p-organizationchart-node-toggle-button',
  nodeToggleButtonIcon: 'p-organizationchart-node-toggle-button-icon',
  connectors: 'p-organizationchart-connectors',
  connectorDown: 'p-organizationchart-connector-down',
  connectorLeft: ({
    index
  }) => ['p-organizationchart-connector-left', {
    'p-organizationchart-connector-top': !(index === 0)
  }],
  connectorRight: ({
    props,
    index
  }) => ['p-organizationchart-connector-right', {
    'p-organizationchart-connector-top': !(index === props.node.children.length - 1)
  }],
  nodeChildren: 'p-organizationchart-node-children'
};
class OrganizationChartStyle extends BaseStyle {
  name = 'organizationchart';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: OrganizationChartStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: OrganizationChartStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: OrganizationChartStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * OrganizationChart visualizes hierarchical organization data.
 *
 * [Live Demo](https://www.primeng.org/organizationchart)
 *
 * @module organizationchartstyle
 *
 */
var OrganizationChartClasses;
(function (OrganizationChartClasses) {
  /**
   * Class name of the root element
   */
  OrganizationChartClasses["root"] = "p-organizationchart";
  /**
   * Class name of the table element
   */
  OrganizationChartClasses["table"] = "p-organizationchart-table";
  /**
   * Class name of the node element
   */
  OrganizationChartClasses["node"] = "p-organizationchart-node";
  /**
   * Class name of the node toggle button element
   */
  OrganizationChartClasses["nodeToggleButton"] = "p-organizationchart-node-toggle-button";
  /**
   * Class name of the node toggle button icon element
   */
  OrganizationChartClasses["nodeToggleButtonIcon"] = "p-organizationchart-node-toggle-button-icon";
  /**
   * Class name of the connectors element
   */
  OrganizationChartClasses["connectors"] = "p-organizationchart-connectors";
  /**
   * Class name of the connector down element
   */
  OrganizationChartClasses["connectorDown"] = "p-organizationchart-connector-down";
  /**
   * Class name of the connector left element
   */
  OrganizationChartClasses["connectorLeft"] = "p-organizationchart-connector-left";
  /**
   * Class name of the connector right element
   */
  OrganizationChartClasses["connectorRight"] = "p-organizationchart-connector-right";
  /**
   * Class name of the node children element
   */
  OrganizationChartClasses["nodeChildren"] = "p-organizationchart-node-children";
})(OrganizationChartClasses || (OrganizationChartClasses = {}));
class OrganizationChartNode {
  cd;
  node;
  root;
  first;
  last;
  collapsible;
  chart;
  subscription;
  constructor(chart, cd) {
    this.cd = cd;
    this.chart = chart;
    this.subscription = this.chart.selectionSource$.subscribe(() => {
      this.cd.markForCheck();
    });
  }
  get leaf() {
    if (this.node) {
      return this.node.leaf == false ? false : !(this.node.children && this.node.children.length);
    }
  }
  get colspan() {
    if (this.node) {
      return this.node.children && this.node.children.length ? this.node.children.length * 2 : null;
    }
  }
  getChildStyle(node) {
    return {
      visibility: !this.leaf && node.expanded ? 'inherit' : 'hidden'
    };
  }
  onNodeClick(event, node) {
    this.chart.onNodeClick(event, node);
  }
  toggleNode(event, node) {
    node.expanded = !node.expanded;
    if (node.expanded) this.chart.onNodeExpand.emit({
      originalEvent: event,
      node: this.node
    });else this.chart.onNodeCollapse.emit({
      originalEvent: event,
      node: this.node
    });
    event.preventDefault();
  }
  isSelected() {
    return this.chart.isSelected(this.node);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: OrganizationChartNode,
    deps: [{
      token: forwardRef(() => OrganizationChart)
    }, {
      token: i0.ChangeDetectorRef
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: OrganizationChartNode,
    isStandalone: true,
    selector: "[pOrganizationChartNode]",
    inputs: {
      node: "node",
      root: ["root", "root", booleanAttribute],
      first: ["first", "first", booleanAttribute],
      last: ["last", "last", booleanAttribute],
      collapsible: ["collapsible", "collapsible", booleanAttribute]
    },
    ngImport: i0,
    template: `
        <tbody *ngIf="node" [attr.data-pc-section]="'body'">
            <tr [attr.data-pc-section]="'row'">
                <td [attr.colspan]="colspan" [attr.data-pc-section]="'cell'">
                    <div
                        [class]="node.styleClass"
                        [ngClass]="{
                            'p-organizationchart-node': true,
                            'p-organizationchart-node-selectable': chart.selectionMode && node.selectable !== false,
                            'p-organizationchart-node-selected': isSelected()
                        }"
                        (click)="onNodeClick($event, node)"
                        [attr.data-pc-section]="'node'"
                    >
                        <div *ngIf="!chart.getTemplateForNode(node)">{{ node.label }}</div>
                        <div *ngIf="chart.getTemplateForNode(node)">
                            <ng-container *ngTemplateOutlet="chart.getTemplateForNode(node); context: { $implicit: node }"></ng-container>
                        </div>
                        <ng-container *ngIf="collapsible">
                            <a
                                *ngIf="!leaf"
                                tabindex="0"
                                class="p-organizationchart-node-toggle-button"
                                (click)="toggleNode($event, node)"
                                (keydown.enter)="toggleNode($event, node)"
                                (keydown.space)="toggleNode($event, node)"
                                [attr.data-pc-section]="'nodeToggler'"
                            >
                                <ng-container *ngIf="!chart.togglerIconTemplate && !chart._togglerIconTemplate">
                                    <ChevronDownIcon *ngIf="node.expanded" [styleClass]="'p-organizationchart-node-toggle-button-icon'" [attr.data-pc-section]="'nodeTogglerIcon'" />
                                    <ChevronUpIcon *ngIf="!node.expanded" [styleClass]="'p-organizationchart-node-toggle-button-icon'" [attr.data-pc-section]="'nodeTogglerIcon'" />
                                </ng-container>
                                <span class="p-organizationchart-node-toggle-button-icon" *ngIf="chart.togglerIconTemplate || chart._togglerIconTemplate" [attr.data-pc-section]="'nodeTogglerIcon'">
                                    <ng-template *ngTemplateOutlet="chart.togglerIconTemplate || chart._togglerIconTemplate; context: { $implicit: node.expanded }"></ng-template>
                                </span>
                            </a>
                        </ng-container>
                    </div>
                </td>
            </tr>
            <tr [ngStyle]="getChildStyle(node)" class="p-organizationchart-connectors" [@childState]="'in'" [attr.data-pc-section]="'lines'">
                <td [attr.data-pc-section]="'lineCell'" [attr.colspan]="colspan">
                    <div [attr.data-pc-section]="'lineDown'" class="p-organizationchart-connector-down"></div>
                </td>
            </tr>
            <tr [ngStyle]="getChildStyle(node)" class="p-organizationchart-connectors" [@childState]="'in'" [attr.data-pc-section]="'lines'">
                <ng-container *ngIf="node.children && node.children.length === 1">
                    <td [attr.data-pc-section]="'lineCell'" [attr.colspan]="colspan">
                        <div [attr.data-pc-section]="'lineDown'" class="p-organizationchart-connector-down"></div>
                    </td>
                </ng-container>
                <ng-container *ngIf="node.children && node.children.length > 1">
                    <ng-template ngFor let-child [ngForOf]="node.children" let-first="first" let-last="last">
                        <td [attr.data-pc-section]="'lineLeft'" class="p-organizationchart-connector-left" [ngClass]="{ 'p-organizationchart-connector-top': !first }">&nbsp;</td>
                        <td [attr.data-pc-section]="'lineRight'" class="p-organizationchart-connector-right" [ngClass]="{ 'p-organizationchart-connector-top': !last }">&nbsp;</td>
                    </ng-template>
                </ng-container>
            </tr>
            <tr [ngStyle]="getChildStyle(node)" class="p-organizationchart-node-children" [@childState]="'in'" [attr.data-pc-section]="'nodes'">
                <td *ngFor="let child of node.children" colspan="2" [attr.data-pc-section]="'nodeCell'">
                    <table class="p-organizationchart-table" pOrganizationChartNode [node]="child" [collapsible]="node.children && node.children.length > 0 && collapsible"></table>
                </td>
            </tr>
        </tbody>
    `,
    isInline: true,
    dependencies: [{
      kind: "component",
      type: OrganizationChartNode,
      selector: "[pOrganizationChartNode]",
      inputs: ["node", "root", "first", "last", "collapsible"]
    }, {
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgForOf,
      selector: "[ngFor][ngForOf]",
      inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i1.NgStyle,
      selector: "[ngStyle]",
      inputs: ["ngStyle"]
    }, {
      kind: "component",
      type: ChevronDownIcon,
      selector: "ChevronDownIcon"
    }, {
      kind: "component",
      type: ChevronUpIcon,
      selector: "ChevronUpIcon"
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    animations: [trigger('childState', [state('in', style({
      opacity: 1
    })), transition('void => *', [style({
      opacity: 0
    }), animate(150)]), transition('* => void', [animate(150, style({
      opacity: 0
    }))])])],
    changeDetection: i0.ChangeDetectionStrategy.Default,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: OrganizationChartNode,
  decorators: [{
    type: Component,
    args: [{
      selector: '[pOrganizationChartNode]',
      standalone: true,
      imports: [CommonModule, ChevronDownIcon, ChevronUpIcon, SharedModule],
      template: `
        <tbody *ngIf="node" [attr.data-pc-section]="'body'">
            <tr [attr.data-pc-section]="'row'">
                <td [attr.colspan]="colspan" [attr.data-pc-section]="'cell'">
                    <div
                        [class]="node.styleClass"
                        [ngClass]="{
                            'p-organizationchart-node': true,
                            'p-organizationchart-node-selectable': chart.selectionMode && node.selectable !== false,
                            'p-organizationchart-node-selected': isSelected()
                        }"
                        (click)="onNodeClick($event, node)"
                        [attr.data-pc-section]="'node'"
                    >
                        <div *ngIf="!chart.getTemplateForNode(node)">{{ node.label }}</div>
                        <div *ngIf="chart.getTemplateForNode(node)">
                            <ng-container *ngTemplateOutlet="chart.getTemplateForNode(node); context: { $implicit: node }"></ng-container>
                        </div>
                        <ng-container *ngIf="collapsible">
                            <a
                                *ngIf="!leaf"
                                tabindex="0"
                                class="p-organizationchart-node-toggle-button"
                                (click)="toggleNode($event, node)"
                                (keydown.enter)="toggleNode($event, node)"
                                (keydown.space)="toggleNode($event, node)"
                                [attr.data-pc-section]="'nodeToggler'"
                            >
                                <ng-container *ngIf="!chart.togglerIconTemplate && !chart._togglerIconTemplate">
                                    <ChevronDownIcon *ngIf="node.expanded" [styleClass]="'p-organizationchart-node-toggle-button-icon'" [attr.data-pc-section]="'nodeTogglerIcon'" />
                                    <ChevronUpIcon *ngIf="!node.expanded" [styleClass]="'p-organizationchart-node-toggle-button-icon'" [attr.data-pc-section]="'nodeTogglerIcon'" />
                                </ng-container>
                                <span class="p-organizationchart-node-toggle-button-icon" *ngIf="chart.togglerIconTemplate || chart._togglerIconTemplate" [attr.data-pc-section]="'nodeTogglerIcon'">
                                    <ng-template *ngTemplateOutlet="chart.togglerIconTemplate || chart._togglerIconTemplate; context: { $implicit: node.expanded }"></ng-template>
                                </span>
                            </a>
                        </ng-container>
                    </div>
                </td>
            </tr>
            <tr [ngStyle]="getChildStyle(node)" class="p-organizationchart-connectors" [@childState]="'in'" [attr.data-pc-section]="'lines'">
                <td [attr.data-pc-section]="'lineCell'" [attr.colspan]="colspan">
                    <div [attr.data-pc-section]="'lineDown'" class="p-organizationchart-connector-down"></div>
                </td>
            </tr>
            <tr [ngStyle]="getChildStyle(node)" class="p-organizationchart-connectors" [@childState]="'in'" [attr.data-pc-section]="'lines'">
                <ng-container *ngIf="node.children && node.children.length === 1">
                    <td [attr.data-pc-section]="'lineCell'" [attr.colspan]="colspan">
                        <div [attr.data-pc-section]="'lineDown'" class="p-organizationchart-connector-down"></div>
                    </td>
                </ng-container>
                <ng-container *ngIf="node.children && node.children.length > 1">
                    <ng-template ngFor let-child [ngForOf]="node.children" let-first="first" let-last="last">
                        <td [attr.data-pc-section]="'lineLeft'" class="p-organizationchart-connector-left" [ngClass]="{ 'p-organizationchart-connector-top': !first }">&nbsp;</td>
                        <td [attr.data-pc-section]="'lineRight'" class="p-organizationchart-connector-right" [ngClass]="{ 'p-organizationchart-connector-top': !last }">&nbsp;</td>
                    </ng-template>
                </ng-container>
            </tr>
            <tr [ngStyle]="getChildStyle(node)" class="p-organizationchart-node-children" [@childState]="'in'" [attr.data-pc-section]="'nodes'">
                <td *ngFor="let child of node.children" colspan="2" [attr.data-pc-section]="'nodeCell'">
                    <table class="p-organizationchart-table" pOrganizationChartNode [node]="child" [collapsible]="node.children && node.children.length > 0 && collapsible"></table>
                </td>
            </tr>
        </tbody>
    `,
      animations: [trigger('childState', [state('in', style({
        opacity: 1
      })), transition('void => *', [style({
        opacity: 0
      }), animate(150)]), transition('* => void', [animate(150, style({
        opacity: 0
      }))])])],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.Default
    }]
  }],
  ctorParameters: () => [{
    type: OrganizationChart,
    decorators: [{
      type: Inject,
      args: [forwardRef(() => OrganizationChart)]
    }]
  }, {
    type: i0.ChangeDetectorRef
  }],
  propDecorators: {
    node: [{
      type: Input
    }],
    root: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    first: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    last: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    collapsible: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }]
  }
});
/**
 * OrganizationChart visualizes hierarchical organization data.
 * @group Components
 */
class OrganizationChart extends BaseComponent {
  el;
  cd;
  /**
   * An array of nested TreeNodes.
   * @group Props
   */
  value;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Defines the selection mode.
   * @group Props
   */
  selectionMode;
  /**
   * Whether the nodes can be expanded or toggled.
   * @group Props
   */
  collapsible;
  /**
   * Whether the space allocated by a node is preserved when hidden.
   * @group Props
   */
  preserveSpace = true;
  /**
   * A single treenode instance or an array to refer to the selections.
   * @group Props
   */
  get selection() {
    return this._selection;
  }
  set selection(val) {
    this._selection = val;
    if (this.initialized) this.selectionSource.next(null);
  }
  /**
   * Callback to invoke on selection change.
   * @param {*} any - selected value.
   * @group Emits
   */
  selectionChange = new EventEmitter();
  /**
   * Callback to invoke when a node is selected.
   * @param {OrganizationChartNodeSelectEvent} event - custom node select event.
   * @group Emits
   */
  onNodeSelect = new EventEmitter();
  /**
   * Callback to invoke when a node is unselected.
   * @param {OrganizationChartNodeUnSelectEvent} event - custom node unselect event.
   * @group Emits
   */
  onNodeUnselect = new EventEmitter();
  /**
   * Callback to invoke when a node is expanded.
   * @param {OrganizationChartNodeExpandEvent} event - custom node expand event.
   * @group Emits
   */
  onNodeExpand = new EventEmitter();
  /**
   * Callback to invoke when a node is collapsed.
   * @param {OrganizationChartNodeCollapseEvent} event - custom node collapse event.
   * @group Emits
   */
  onNodeCollapse = new EventEmitter();
  templates;
  togglerIconTemplate;
  templateMap;
  _togglerIconTemplate;
  selectionSource = new Subject();
  _selection;
  initialized;
  selectionSource$ = this.selectionSource.asObservable();
  _componentStyle = inject(OrganizationChartStyle);
  constructor(el, cd) {
    super();
    this.el = el;
    this.cd = cd;
  }
  get root() {
    return this.value && this.value.length ? this.value[0] : null;
  }
  ngAfterContentInit() {
    if (this.templates.length) {
      this.templateMap = {};
    }
    this.templates.forEach(item => {
      if (item.getType() === 'togglericon') {
        this._togglerIconTemplate = item.template;
      } else {
        this.templateMap[item.getType()] = item.template;
      }
    });
    this.initialized = true;
  }
  getTemplateForNode(node) {
    if (this.templateMap) return node.type ? this.templateMap[node.type] : this.templateMap['default'];else return null;
  }
  onNodeClick(event, node) {
    let eventTarget = event.target;
    if (eventTarget.className && (hasClass(eventTarget, 'p-organizationchart-node-toggle-button') || hasClass(eventTarget, 'p-organizationchart-node-toggle-button-icon'))) {
      return;
    } else if (this.selectionMode) {
      if (node.selectable === false) {
        return;
      }
      let index = this.findIndexInSelection(node);
      let selected = index >= 0;
      if (this.selectionMode === 'single') {
        if (selected) {
          this.selection = null;
          this.onNodeUnselect.emit({
            originalEvent: event,
            node: node
          });
        } else {
          this.selection = node;
          this.onNodeSelect.emit({
            originalEvent: event,
            node: node
          });
        }
      } else if (this.selectionMode === 'multiple') {
        if (selected) {
          this.selection = this.selection.filter((val, i) => i != index);
          this.onNodeUnselect.emit({
            originalEvent: event,
            node: node
          });
        } else {
          this.selection = [...(this.selection || []), node];
          this.onNodeSelect.emit({
            originalEvent: event,
            node: node
          });
        }
      }
      this.selectionChange.emit(this.selection);
      this.selectionSource.next(null);
    }
  }
  findIndexInSelection(node) {
    let index = -1;
    if (this.selectionMode && this.selection) {
      if (this.selectionMode === 'single') {
        index = this.selection == node ? 0 : -1;
      } else if (this.selectionMode === 'multiple') {
        for (let i = 0; i < this.selection.length; i++) {
          if (this.selection[i] == node) {
            index = i;
            break;
          }
        }
      }
    }
    return index;
  }
  isSelected(node) {
    return this.findIndexInSelection(node) != -1;
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: OrganizationChart,
    deps: [{
      token: i0.ElementRef
    }, {
      token: i0.ChangeDetectorRef
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: OrganizationChart,
    isStandalone: true,
    selector: "p-organizationChart, p-organization-chart, p-organizationchart",
    inputs: {
      value: "value",
      style: "style",
      styleClass: "styleClass",
      selectionMode: "selectionMode",
      collapsible: ["collapsible", "collapsible", booleanAttribute],
      preserveSpace: ["preserveSpace", "preserveSpace", booleanAttribute],
      selection: "selection"
    },
    outputs: {
      selectionChange: "selectionChange",
      onNodeSelect: "onNodeSelect",
      onNodeUnselect: "onNodeUnselect",
      onNodeExpand: "onNodeExpand",
      onNodeCollapse: "onNodeCollapse"
    },
    providers: [OrganizationChartStyle],
    queries: [{
      propertyName: "togglerIconTemplate",
      first: true,
      predicate: ["togglericon"]
    }, {
      propertyName: "templates",
      predicate: PrimeTemplate
    }],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <div [ngStyle]="style" [class]="styleClass" [ngClass]="{ 'p-organizationchart p-component': true, 'p-organizationchart-preservespace': preserveSpace }" [attr.data-pc-section]="'root'">
            <table class="p-organizationchart-table" [collapsible]="collapsible" pOrganizationChartNode [node]="root" *ngIf="root"></table>
        </div>
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgStyle,
      selector: "[ngStyle]",
      inputs: ["ngStyle"]
    }, {
      kind: "component",
      type: OrganizationChartNode,
      selector: "[pOrganizationChartNode]",
      inputs: ["node", "root", "first", "last", "collapsible"]
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.Default
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: OrganizationChart,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-organizationChart, p-organization-chart, p-organizationchart',
      standalone: true,
      imports: [CommonModule, OrganizationChartNode, SharedModule],
      template: `
        <div [ngStyle]="style" [class]="styleClass" [ngClass]="{ 'p-organizationchart p-component': true, 'p-organizationchart-preservespace': preserveSpace }" [attr.data-pc-section]="'root'">
            <table class="p-organizationchart-table" [collapsible]="collapsible" pOrganizationChartNode [node]="root" *ngIf="root"></table>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.Default,
      providers: [OrganizationChartStyle]
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }],
  propDecorators: {
    value: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    selectionMode: [{
      type: Input
    }],
    collapsible: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    preserveSpace: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    selection: [{
      type: Input
    }],
    selectionChange: [{
      type: Output
    }],
    onNodeSelect: [{
      type: Output
    }],
    onNodeUnselect: [{
      type: Output
    }],
    onNodeExpand: [{
      type: Output
    }],
    onNodeCollapse: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    togglerIconTemplate: [{
      type: ContentChild,
      args: ['togglericon', {
        descendants: false
      }]
    }]
  }
});
class OrganizationChartModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: OrganizationChartModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: OrganizationChartModule,
    imports: [OrganizationChart, OrganizationChartNode, SharedModule],
    exports: [OrganizationChart, OrganizationChartNode, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: OrganizationChartModule,
    imports: [OrganizationChart, OrganizationChartNode, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: OrganizationChartModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [OrganizationChart, OrganizationChartNode, SharedModule],
      exports: [OrganizationChart, OrganizationChartNode, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { OrganizationChart, OrganizationChartClasses, OrganizationChartModule, OrganizationChartNode, OrganizationChartStyle };
