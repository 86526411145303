.icon {
    position: relative;
    font-size: 37px;
    left: -5px;
}

p-inputgroup:has(.p-radiobutton-checked) {
    border-color: #436807 !important;
    border-width: 2px !important;
    background-color: #D1E09B !important;
}