import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, Router, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class CustomerWizardStep2CustomerGuard implements CanActivate {
    constructor(public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardResult {
        const extras = this.router.getCurrentNavigation()?.extras;
        return extras?.state?.["electricityType"] ? true : false || !!extras?.state?.['gasType'] ? true : false;
    }
}