import { Component, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccordionModule } from "primeng/accordion";
import { ButtonModule } from "primeng/button";
import { DatePicker, DatePickerModule } from "primeng/datepicker"
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton'
import { ElectricityType } from "src/app/models/enums/electricity-type.enum";
import { GasType } from "src/app/models/enums/gas-type.enum";
import { Router, RouterModule } from "@angular/router";
import { Contract } from "src/app/models/contract.model";
import { PaymentDetails } from "src/app/models/payment-details.model";
import { InputGroupModule } from "primeng/inputgroup";
import { CardModule } from "primeng/card";
import { FileUploadHandlerEvent, FileUploadModule } from "primeng/fileupload";
import { CommonModule } from "@angular/common";
import { SituationEnum } from "src/app/models/enums/situation.enum";
import { ProgressBarModule } from "primeng/progressbar";
import { Nullable } from "primeng/ts-helpers";
import { StringHelper } from "src/app/helpers/string.helper";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";
import { Company } from "src/app/models/company.model";
import { CountryEnum } from "src/app/models/enums/countries.enum";

declare global {
    interface Date {
        addDays(days: number): Date;
    }
}

Date.prototype.addDays = function(days: number): Date {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

@Component({
    selector: 'customer-wizard-step-3-self-employed',
    standalone: true,
    styleUrls: ['./customer-wizard-step-3.page.scss'],
    templateUrl: './customer-wizard-step-3.page.html',
    imports: [AccordionModule, ButtonModule, CardModule, CommonModule, DatePickerModule, InputGroupModule, InputNumberModule, InputTextModule, FileUploadModule, FormsModule, ProgressBarModule, RadioButtonModule, RouterModule]
})
export class CustomerWizardStep3SelfEmployedPage {
    @ViewChild("calendar") calendar!: DatePicker;

    //Enum values    
    electricityType: number;
    gasType: number;
    
    //Enum definitions
    ElectricityType = ElectricityType;
    GasType = GasType;
    Countries = CountryEnum;
    ContractSituation = SituationEnum;
    postalCode!: string | number | undefined;

    company: Company;
    contract: Contract;

    StringHelper = StringHelper;

    priceSimulationInput: PriceSimulationInput;

    disabledDatesRanges: Date[] = [];

    today: Date = new Date();
    maxDate: Date = new Date(this.today.getFullYear(), this.today.getMonth() + 7, this.today.getDate());

    paymentDetails: PaymentDetails;

    inputContractSituationValid: Nullable<boolean>;
    inputContractStartDateValid: Nullable<boolean>;
    inputContractElectricityEANValid: Nullable<boolean>;
    inputContractGasEANValid: Nullable<boolean>;

    showErrorNoSituationChosen: boolean = false;
    showErrorNoStartDateChosen: boolean = false;
    showErrorNoElectricityEANFilledIn: boolean = false;
    showErrorElectricityEANInvalid: boolean = false;
    showErrorNoGasEANFilledIn: boolean = false;
    showErrorElectricityEANMinimumLengthNotMet: boolean = false;
    showErrorGasEANMinimumLengthNotMet: boolean = false;
    showErrorGasEANInvalid: boolean = false;

    constructor(public router: Router) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"]; 
        this.company = window.history.state["company"];
        this.contract = window.history.state["contract"] ?? <Contract>{ takeoverDocuments: <File[]>[], electricityEAN: (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.electricityType)) ? "54" : null, gasEAN: (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.gasType)) ? "54" : null };
        this.paymentDetails = window.history.state["paymentDetails"];
        this.postalCode = window.history.state["postalCode"];
        this.priceSimulationInput = window.history.state["priceSimulationInput"]
    }

    onUpload(event: FileUploadHandlerEvent) {
        this.contract.takeoverDocuments = event.files;
    }

    open(file: File) {
        window.open(URL.createObjectURL(file));
    }

    changePossibleStartDates() {
        this.disabledDatesRanges = [];
        this.calendar.clear();
        console.log(this.contract.situation);
        console.log(this.disabledDatesRanges);
        switch (this.contract.situation) {
            case SituationEnum["Ik wil veranderen van leverancier"]:
                let from = this.today;
                let to = this.maxDate.addDays(1);

                do {
                    if (from.getDate() != 1) {
                        this.disabledDatesRanges.push(new Date(from));
                    }
                    from = from.addDays(1);
                } while (from < to);

                break;
            case SituationEnum["Ik verhuis en kies voor Ebem (overname)"]:
            case SituationEnum["Nieuwe aansluiting op het net (nieuwbouw)"]:
            case SituationEnum["De aansluiting heropenen na afsluiting"]:
            default:
                this.disabledDatesRanges = [this.today, this.today.addDays(1), this.today.addDays(2), this.today.addDays(3), this.today.addDays(4), this.today.addDays(5), this.today.addDays(6)];
                break;
        }
        console.log(this.disabledDatesRanges);
        this.calendar.updateInputfield();
    }

    validateInputs() {
        this.validateContractSituationInput();
 
        this.validateContractStartDateInput();

        this.validateElectricityEANInput();
        
        this.validateGasEANInput();
    }

    validateGasEANInput() {
        this.inputContractGasEANValid = (StringHelper.isNullOrEmptyOrWhiteSpaceOrUndefined(this.gasType)) || (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.gasType) && !!this.contract.gasEAN && !!this.contract.gasEAN.match(/^[5][4][0-9]{16}$/) && this.isValidEanN(this.contract.gasEAN, 18));
        this.showErrorNoGasEANFilledIn = StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.gasType) && !this.contract.gasEAN;
        this.showErrorGasEANMinimumLengthNotMet = StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.gasType) && !!this.contract.gasEAN && !this.contract.gasEAN.match(/^[5][4][0-9]{16}$/);
        this.showErrorGasEANInvalid = StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.gasType) && !!this.contract.gasEAN && !this.isValidEanN(this.contract.gasEAN, 18);
    }

    validateElectricityEANInput() {
        this.inputContractElectricityEANValid = (StringHelper.isNullOrEmptyOrWhiteSpaceOrUndefined(this.electricityType)) || (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.electricityType) && !!this.contract.electricityEAN && !!this.contract.electricityEAN.match(/^[5][4][0-9]{16}$/) && this.isValidEanN(this.contract.electricityEAN, 18));
        this.showErrorNoElectricityEANFilledIn = (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.electricityType)) && !this.contract.electricityEAN;
        this.showErrorElectricityEANMinimumLengthNotMet = (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.electricityType)) && !!this.contract.electricityEAN && !this.contract.electricityEAN.match(/^[5][4][0-9]{16}$/);
        this.showErrorElectricityEANInvalid = StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.electricityType) && !!this.contract.electricityEAN && !this.isValidEanN(this.contract.electricityEAN, 18);
    }

    validateContractStartDateInput() {
        this.inputContractStartDateValid = !!this.contract.startDate;
        this.showErrorNoStartDateChosen = !this.contract.startDate;
    }

    validateContractSituationInput() {
        this.inputContractSituationValid = !!this.contract.situation;
        this.showErrorNoSituationChosen = !this.contract.situation;
    }

    validateForm() {
        this.validateInputs();
        return this.inputContractSituationValid && this.inputContractStartDateValid && this.inputContractElectricityEANValid && this.inputContractGasEANValid;
    }

    isValidEanN(input: string, length: number): boolean
        {
            if (input == null)
                return false;
 
            var ean = input?.toString().trim();
 
            if (ean.length != length || isNaN(parseInt(ean)))
                return false;
 
            var sumEven: number = 0;
            var sumOdd: number = 0;
 
            for (var i = 0; i < length - 1; i += 2)
                sumEven = sumEven + parseInt(ean[i]);
 
            for (var i = 1; i < length - 1; i += 2)
                sumOdd = sumOdd + parseInt(ean[i]);
 
            var checkdigit: number;
 
            if (length == 18)
                checkdigit = (10 - Math.abs((3 * sumEven + sumOdd) % 10)) % 10;
            else
                checkdigit = (10 - Math.abs((3 * sumOdd + sumEven) % 10)) % 10;
 
            if (checkdigit != parseInt(ean.substring(ean.length - 1, ean.length)))
                return false;
 
            return true;
        }
}