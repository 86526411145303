import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxTurnstileModule } from "ngx-turnstile";
import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { InputGroup } from "primeng/inputgroup";
import { InputText } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { Nullable } from "primeng/ts-helpers";
import { NewsletterInput } from "src/app/models/newsletter-input.model";
import { NewsletterService } from "src/app/services/newsletter.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['footer.component.scss'],
    standalone: true,
    imports: [ButtonModule, CardModule, CommonModule, InputGroup, InputText, FormsModule, ReactiveFormsModule, RouterModule, ToastModule, NgxTurnstileModule],
    providers: [NewsletterService, MessageService]
})
export class FooterModule {
    window: Window = window;
    captchaResponse: string = '';
    newsletterEmail: string = '';
    inputNewsletterEmailValid: Nullable<boolean>;
    showErrorNewsletterEmailInvalid: boolean = false;
    siteKey = environment.siteKey;

    constructor(private messageService: MessageService, private newsletterService: NewsletterService) {}

    validateNewsletterEmail() {
        this.inputNewsletterEmailValid = this.validateEmail(this.newsletterEmail);
        this.showErrorNewsletterEmailInvalid = !this.inputNewsletterEmailValid;
    }

    validateEmail(email: string): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    sendCaptchaResponse(captchaResponse: string) {
        this.captchaResponse = captchaResponse;
    }

    subscribeNewsletter() {
        if (this.inputNewsletterEmailValid) {
            this.newsletterService.postNewsletter(<NewsletterInput> { email: this.newsletterEmail }, this.captchaResponse).toPromise().then(res => {
                    this.newsletterEmail = '';
                    this.inputNewsletterEmailValid = false;
                    this.showErrorNewsletterEmailInvalid = false;
                    this.messageService.add({ severity: 'success', summary: 'Succes', detail: 'Je bent succesvol ingeschreven op de nieuwsbrief.' });
            }).catch(err => {
                this.messageService.add({ severity: 'error', summary: 'Fout', detail: 'Er is een fout opgetreden bij het inschrijven voor de nieuwsbrief.' });
            });
        }
    }
}