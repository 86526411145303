@if (newsItem) {
    <p-tag>Ebem artikel</p-tag>
    <h1>{{newsItem?.title}}</h1>
    <p-button [outlined]="true" (click)="window.location.hash = ''; window.location.hash = 'article'">Verder lezen</p-button>
    <div *ngIf="newsItem?.image" class="card-container">
        <img class="card-background" [src]="newsItem?.image">
    </div>
    <div class="row">
        <div class="xl:col-8 lg:col-8 col-12">
            <dynamic-template *ngIf="newsItem" [content]="newsItem.content"></dynamic-template>
            @if (promotedProduct) {
                <product-card [product]="promotedProduct"></product-card>
            }
        </div>
        @if (newsItem?.bulletPoints ?? false && newsItem.bulletPoints.length > 0) {
            <div class="xl:block xl:col-4 lg:block lg:col-4 hidden">
                <h3>Op deze pagina</h3>
                @for(bulletPoint of newsItem?.bulletPoints; track $index) {
                    <div class="row">
                        <div class="col-1">
                            <small [class]="window.location.hash.replace('#', '') == bulletPoint.href ? 'pi-circle-fill ebemGreen' : 'hidden'"></small>
                        </div>
                        <div class="col-11">
                            <a [class]="window.location.hash.replace('#', '') != bulletPoint.href ? 'gray' : ''" (click)="window.location.hash = bulletPoint.href">{{bulletPoint.title}}</a>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
}