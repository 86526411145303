import { Component } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ProductService } from "src/app/services/product.service";

@Component({
    selector: 'price-calculation-proposal',
    template: ``,
    standalone: true,
    imports: [],
    providers: [ProductService, RouterModule],
})
export class PriceCalculationProposal {
    constructor(productService: ProductService, private router: Router, private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            productService.loadPriceCalculation(params['id']).subscribe(res => {
                this.router.navigate(['/price-calculation', 'step-2e' ], { state: res });
            });
        });
     }
}