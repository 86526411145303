import { HttpClient } from "@angular/common/http";
import { UmbracoHelper } from "../helpers/umbraco.helper";
import { Str } from "./str.model";

export class NewsItem {
    id: number
    title: string;
    date: string;
    summary: string;
    content: string;
    image: string;
    link: string;
    bulletPoints: BulletPoint[] = [];
    constructor(id: number, title: string, date: string, summary: string, content: string, image: string, link: string, bulletPoints: BulletPoint[] = []) {
        this.id = id;
        this.title = title;
        this.date = date;
        this.summary = summary;
        this.content = content;
        this.image = image;
        this.link = link;
        this.bulletPoints = bulletPoints;
    }
}

export class BulletPoint {
    title: string;
    href: string;
    constructor(title: string, href: string) {
        this.title = title;
        this.href = href;
    }
}

export function jsonToNewsItem(json: any): NewsItem {   
    return new NewsItem(json.id, json.properties.title, json.properties.date, json.properties.summary, UmbracoHelper.processContent(json.properties.content?.markup), json.properties.socialShareImage, json.properties.redirectUrl, UmbracoHelper.processContent(json.properties.content?.markup)?.match(/<h[1-6] id=\"(.*)\">(.*)<\/h[1-6]>/gm)?.map((bulletPoint) => (<BulletPoint>{
        title: bulletPoint.match(/<h[1-6] id=\"(.*)\">(.*)<\/h[1-6]>/)?.[2] ?? '',
        href: bulletPoint.match(/<h[1-6] id=\"(.*)\">(.*)<\/h[1-6]>/)?.[1] ?? ''
    })));
}