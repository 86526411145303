h6 {
    margin-block-start: 0.5em;
    margin-block-end: 0;
}

.card-container {
    position: relative;
    margin-bottom: 50px;
}

.card-background {
    display: block;
    width: 100%;
    border-radius: 25px;
    height:635px;
    object-fit: cover;
}

.card {
    position:absolute;
    top: auto;
    width: fit-content;
    margin-right: 15%;
    bottom: 25px;
    left: 10%;

    .p-card {
        border-radius: 25px !important;
    }

    p-input-number {
        width: 100%;
        margin-bottom: 25px;
    }

    p-button {
        width: 100%;
    }
}

.white {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.25) !important;
}

h4 {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}