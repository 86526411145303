import { Component } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { RouterModule } from "@angular/router";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";

@Component({
    selector: 'customer-wizard-step-6-self-employed',
    standalone: true,
    styleUrls: ['./customer-wizard-step-6.page.scss'],
    templateUrl: './customer-wizard-step-6.page.html',
    imports: [ButtonModule, ToastModule, RouterModule],
    providers: [MessageService]
})
export class CustomerWizardStep6SelfEmployedPage {
    constructor(private messageService: MessageService) {
        this.messageService.add({ severity:'success', summary: 'Gelukt', detail: 'Klant succesvol aangemaakt', life: 3000  });
     }
}