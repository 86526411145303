import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'unique',
    standalone: true
})
export class UniquePipe implements PipeTransform {
    transform(array: Array<any>, selector: string): Array<any> {
        return array.filter((e, i) => array.findIndex(a => a[selector] === e[selector]) === i);
    }
}