@for(product of products; track $index) {
    <p-inputgroup (click)="enableSelection ? selectionChange.emit(product?.enumValue) : {}" [ngClass]="product?.enumValue == selection ? selectedBackgroundCSSClass : ''">  
        <p-radiobutton *ngIf="enableSelection" size="large" class="radio-right" [(ngModel)]="selection" [value]="product?.enumValue"></p-radiobutton>
        <h3><span [ngClass]="iconCSSClass">{{materialIcon}}</span> {{product?.name}}<div *ngIf="product?.price != 0 && !isNaN(product?.price) && product?.price != undefined && product?.price != null" class="right-price"><strong>{{(product?.price ?? 0) / 12 | currency: '€'}}</strong>/<small>mnd</small></div></h3>
        <ul [ngClass]="checkmarksCSSClass">
            <li>{{product?.isVariable ? 'Variabele' : ''}} {{product?.isDynamic ? 'Dynamische' : ''}} prijs</li>
            <li>{{product?.forAnalogMeter ? 'Voor analoge & ' : 'Enkel voor ' }} digitale meters</li>
            <li>{{product?.onlyElectronicInvoicing ? 'Enkel ' : ''}} Elektronische facturatie mogelijk</li>
        </ul>
        <p-accordion [expandIcon]="'pi-angle-down'" [collapseIcon]="'pi-angle-up'">
            <p-accordion-panel value="0">
                <p-accordion-header [ngClass]="selectedBackgroundCSSClass">Meer details</p-accordion-header>
                <p-accordion-content>            
                    <p class="m-0">
                        <div class="row">
                            <div class="col-12">
                                <p-table [value]="[{}]">
                                    <ng-template #header>
                                        <tr>
                                            <th></th>
                                            <th class="text-center">{{product?.name}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template #body let-rowData let-columns="products">
                                        <tr>
                                            <td class="right-border">Variabele prijs</td>
                                            <td class="text-center"><span [ngClass]="product?.isVariable ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                        </tr>
                                        <tr>
                                            <td class="right-border">Dynamische prijs</td>
                                            <td class="text-center"><span [ngClass]="product?.isDynamic ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                        </tr>
                                        <tr>
                                            <td class="right-border">Geschikt voor analoge meter</td>
                                            <td class="text-center"><span [ngClass]="product?.forAnalogMeter ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                        </tr>   
                                        <tr>
                                            <td class="right-border">Facturatie<br/><small class="gray">Maandelijke facturatie is enkel mogelijk met een digitale meter</small></td>
                                            <td class="text-center">{{InvoicingModality[product?.invoicing ?? 0]}}</td>
                                        </tr>  
                                        <tr>
                                            <td class="right-border">Domiciliëring of overschrijving</td>
                                            <td class="text-center"><span [ngClass]="product?.directDebit ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                        </tr>  
                                        <tr>
                                            <td class="right-border">Enkel elektronische facturatie</td>
                                            <td class="text-center"><span [ngClass]="product?.onlyElectronicInvoicing ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                        </tr>  
                                        <tr>
                                            <td class="right-border">Particulier of klein-zakelijk</td>
                                            <td class="text-center"><span [ngClass]="product?.individualOrSmallBusiness ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                        </tr> 
                                    </ng-template>    
                                </p-table>
                            </div>
                        </div>
                </p-accordion-content>
            </p-accordion-panel>
        </p-accordion>
    </p-inputgroup>
    <br/>
}