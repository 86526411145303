import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, Router, RouterStateSnapshot } from "@angular/router";
import { PriceSimulationChoice } from "src/app/models/enums/price-simulation-choice.enum";
import { PriceSimulationOptions, PriceSimulationTariffType } from "src/app/models/enums/price-simulation-options.enum";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";

@Injectable()
export class PriceCalculationStep3Guard implements CanActivate {
    constructor(public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardResult {
        const extras = this.router.getCurrentNavigation()?.extras;
        const priceSimulationInput: PriceSimulationInput = extras?.state?.["priceSimulationInput"];
        const priceSimulationChoices: PriceSimulationChoice[] = extras?.state?.["priceSimulationChoices"];
        const priceSimulationOptions: PriceSimulationOptions = extras?.state?.["priceSimulationOptions"];
        return (!!priceSimulationInput.postalCode) && (!!priceSimulationChoices && priceSimulationChoices.length > 0 ? true : false) && (!priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit) || (priceSimulationOptions.tariffType === PriceSimulationTariffType.Enkelvoudig && priceSimulationInput != null && priceSimulationInput.kwh24u > 0) || (priceSimulationOptions.tariffType === PriceSimulationTariffType.Tweevoudig && priceSimulationInput != null && priceSimulationInput.kwhPiek > 0 && priceSimulationInput.kwhDal > 0) && (!priceSimulationOptions.hasNightTariff || (priceSimulationInput != null && priceSimulationInput.kwhExclNacht > 0))) && (!priceSimulationChoices?.includes(PriceSimulationChoice.Gas) || (priceSimulationInput != null && priceSimulationInput.kwhGas > 0))
    }
}