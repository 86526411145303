import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Input, HostBinding, ChangeDetectionStrategy, ViewEncapsulation, Component, NgModule } from '@angular/core';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-iconfield {
    position: relative;
    display: block;
}

.p-inputicon {
    position: absolute;
    top: 50%;
    margin-top: calc(-1 * (${dt('icon.size')} / 2));
    color: ${dt('iconfield.icon.color')};
    line-height: 1;
}

.p-iconfield .p-inputicon:first-child {
    inset-inline-start: ${dt('form.field.padding.x')};
}

.p-iconfield .p-inputicon:last-child {
    inset-inline-end: ${dt('form.field.padding.x')};
}

.p-iconfield .p-inputtext:not(:first-child) {
    padding-inline-start: calc((${dt('form.field.padding.x')} * 2) + ${dt('icon.size')});
}

.p-iconfield .p-inputtext:not(:last-child) {
    padding-inline-end: calc((${dt('form.field.padding.x')} * 2) + ${dt('icon.size')});
}

.p-iconfield:has(.p-inputfield-sm) .p-inputicon {
    font-size: ${dt('form.field.sm.font.size')};
    width: ${dt('form.field.sm.font.size')};
    height: ${dt('form.field.sm.font.size')};
    margin-top: calc(-1 * (${dt('form.field.sm.font.size')} / 2));
}

.p-iconfield:has(.p-inputfield-lg) .p-inputicon {
    font-size: ${dt('form.field.lg.font.size')};
    width: ${dt('form.field.lg.font.size')};
    height: ${dt('form.field.lg.font.size')};
    margin-top: calc(-1 * (${dt('form.field.lg.font.size')} / 2));
}
`;
const classes = {
  root: 'p-iconfield'
};
class IconFieldStyle extends BaseStyle {
  name = 'iconfield';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: IconFieldStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: IconFieldStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: IconFieldStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * IconField wraps an input and an icon.
 *
 * [Live Demo](https://www.primeng.org/iconfield/)
 *
 * @module iconfieldstyle
 *
 */
var IconFieldClasses;
(function (IconFieldClasses) {
  /**
   * Class name of the root element
   */
  IconFieldClasses["root"] = "p-iconfield";
})(IconFieldClasses || (IconFieldClasses = {}));

/**
 * IconField wraps an input and an icon.
 * @group Components
 */
class IconField extends BaseComponent {
  /**
   * Position of the icon.
   * @group Props
   */
  iconPosition = 'left';
  get _styleClass() {
    return this.styleClass;
  }
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  _componentStyle = inject(IconFieldStyle);
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: IconField,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: IconField,
    isStandalone: true,
    selector: "p-iconfield, p-iconField, p-icon-field",
    inputs: {
      iconPosition: "iconPosition",
      styleClass: "styleClass"
    },
    host: {
      properties: {
        "class.p-iconfield-left": "iconPosition === \"left\"",
        "class.p-iconfield-right": "iconPosition === \"right\"",
        "class": "this._styleClass"
      },
      classAttribute: "p-iconfield"
    },
    providers: [IconFieldStyle],
    usesInheritance: true,
    ngImport: i0,
    template: ` <ng-content></ng-content>`,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: IconField,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-iconfield, p-iconField, p-icon-field',
      standalone: true,
      imports: [CommonModule],
      template: ` <ng-content></ng-content>`,
      providers: [IconFieldStyle],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'p-iconfield',
        '[class.p-iconfield-left]': 'iconPosition === "left"',
        '[class.p-iconfield-right]': 'iconPosition === "right"'
      }
    }]
  }],
  propDecorators: {
    iconPosition: [{
      type: Input
    }],
    _styleClass: [{
      type: HostBinding,
      args: ['class']
    }],
    styleClass: [{
      type: Input
    }]
  }
});
class IconFieldModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: IconFieldModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: IconFieldModule,
    imports: [IconField],
    exports: [IconField]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: IconFieldModule,
    imports: [IconField]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: IconFieldModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [IconField],
      exports: [IconField]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { IconField, IconFieldClasses, IconFieldModule, IconFieldStyle };
