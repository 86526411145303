p-tabs {
    flex-grow: 1;
}

.p-tabpanels {
    padding: 0;
}

.p-button-outlined {
    color: black;
    
    @media (min-width: 992px) {
        background-color: white;
        border: 1px solid #A2C138;
        border-radius: 8px;   
    }
}