import * as i0 from '@angular/core';
import { Injectable, inject, booleanAttribute, HostListener, Input, Optional, Directive, NgModule } from '@angular/core';
import { isEmpty } from '@primeuix/utils';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
import * as i1 from '@angular/forms';
const theme = ({
  dt
}) => `
.p-inputtext {
    font-family: inherit;
    font-feature-settings: inherit;
    font-size: 1rem;
    color: ${dt('inputtext.color')};
    background: ${dt('inputtext.background')};
    padding-block: ${dt('inputtext.padding.y')};
    padding-inline: ${dt('inputtext.padding.x')};
    border: 1px solid ${dt('inputtext.border.color')};
    transition: background ${dt('inputtext.transition.duration')}, color ${dt('inputtext.transition.duration')}, border-color ${dt('inputtext.transition.duration')}, outline-color ${dt('inputtext.transition.duration')}, box-shadow ${dt('inputtext.transition.duration')};
    appearance: none;
    border-radius: ${dt('inputtext.border.radius')};
    outline-color: transparent;
    box-shadow: ${dt('inputtext.shadow')};
}

.p-inputtext.ng-invalid.ng-dirty {
    border-color: ${dt('inputtext.invalid.border.color')};
}

.p-inputtext:enabled:hover {
    border-color: ${dt('inputtext.hover.border.color')};
}

.p-inputtext:enabled:focus {
    border-color: ${dt('inputtext.focus.border.color')};
    box-shadow: ${dt('inputtext.focus.ring.shadow')};
    outline: ${dt('inputtext.focus.ring.width')} ${dt('inputtext.focus.ring.style')} ${dt('inputtext.focus.ring.color')};
    outline-offset: ${dt('inputtext.focus.ring.offset')};
}

.p-inputtext.p-invalid {
    border-color: ${dt('inputtext.invalid.border.color')};
}

.p-inputtext.p-variant-filled {
    background: ${dt('inputtext.filled.background')};
}
    
.p-inputtext.p-variant-filled:enabled:hover {
    background: ${dt('inputtext.filled.hover.background')};
}

.p-inputtext.p-variant-filled:enabled:focus {
    background: ${dt('inputtext.filled.focus.background')};
}

.p-inputtext:disabled {
    opacity: 1;
    background: ${dt('inputtext.disabled.background')};
    color: ${dt('inputtext.disabled.color')};
}

.p-inputtext::placeholder {
    color: ${dt('inputtext.placeholder.color')};
}

.p-inputtext.ng-invalid.ng-dirty::placeholder {
    color: ${dt('inputtext.invalid.placeholder.color')};
}

.p-inputtext-sm {
    font-size: ${dt('inputtext.sm.font.size')};
    padding-block: ${dt('inputtext.sm.padding.y')};
    padding-inline: ${dt('inputtext.sm.padding.x')};
}

.p-inputtext-lg {
    font-size: ${dt('inputtext.lg.font.size')};
    padding-block: ${dt('inputtext.lg.padding.y')};
    padding-inline: ${dt('inputtext.lg.padding.x')};
}

.p-inputtext-fluid {
    width: 100%;
}
`;
const classes = {
  root: ({
    instance,
    props
  }) => ['p-inputtext p-component', {
    'p-filled': instance.filled,
    'p-inputtext-sm': props.size === 'small',
    'p-inputtext-lg': props.size === 'large',
    'p-invalid': props.invalid,
    'p-variant-filled': props.variant === 'filled',
    'p-inputtext-fluid': props.fluid
  }]
};
class InputTextStyle extends BaseStyle {
  name = 'inputtext';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputTextStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputTextStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputTextStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * InputText renders a text field to enter data.
 *
 * [Live Demo](https://www.primeng.org/inputtext/)
 *
 * @module inputtextstyle
 *
 */
var InputTextClasses;
(function (InputTextClasses) {
  /**
   * The class of root element
   */
  InputTextClasses["root"] = "p-inputtext";
})(InputTextClasses || (InputTextClasses = {}));

/**
 * InputText directive is an extension to standard input element with theming.
 * @group Components
 */
class InputText extends BaseComponent {
  ngModel;
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant;
  /**
   * Spans 100% width of the container when enabled.
   * @group Props
   */
  fluid;
  /**
   * Defines the size of the component.
   * @group Props
   */
  pSize;
  filled;
  _componentStyle = inject(InputTextStyle);
  get hasFluid() {
    const nativeElement = this.el.nativeElement;
    const fluidComponent = nativeElement.closest('p-fluid');
    return isEmpty(this.fluid) ? !!fluidComponent : this.fluid;
  }
  constructor(ngModel) {
    super();
    this.ngModel = ngModel;
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.updateFilledState();
    this.cd.detectChanges();
  }
  ngDoCheck() {
    this.updateFilledState();
  }
  onInput() {
    this.updateFilledState();
  }
  updateFilledState() {
    this.filled = this.el.nativeElement.value && this.el.nativeElement.value.length || this.ngModel && this.ngModel.model;
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputText,
    deps: [{
      token: i1.NgModel,
      optional: true
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: InputText,
    isStandalone: true,
    selector: "[pInputText]",
    inputs: {
      variant: "variant",
      fluid: ["fluid", "fluid", booleanAttribute],
      pSize: "pSize"
    },
    host: {
      listeners: {
        "input": "onInput($event)"
      },
      properties: {
        "class.p-filled": "filled",
        "class.p-variant-filled": "(variant ?? (config.inputStyle() || config.inputVariant())) === \"filled\"",
        "class.p-inputtext-fluid": "hasFluid",
        "class.p-inputtext-sm": "pSize === \"small\"",
        "class.p-inputfield-sm": "pSize === \"small\"",
        "class.p-inputtext-lg": "pSize === \"large\"",
        "class.p-inputfield-lg": "pSize === \"large\""
      },
      classAttribute: "p-inputtext p-component"
    },
    providers: [InputTextStyle],
    usesInheritance: true,
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputText,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pInputText]',
      standalone: true,
      host: {
        class: 'p-inputtext p-component',
        '[class.p-filled]': 'filled',
        '[class.p-variant-filled]': '(variant ?? (config.inputStyle() || config.inputVariant())) === "filled"',
        '[class.p-inputtext-fluid]': 'hasFluid',
        '[class.p-inputtext-sm]': 'pSize === "small"',
        '[class.p-inputfield-sm]': 'pSize === "small"',
        '[class.p-inputtext-lg]': 'pSize === "large"',
        '[class.p-inputfield-lg]': 'pSize === "large"'
      },
      providers: [InputTextStyle]
    }]
  }],
  ctorParameters: () => [{
    type: i1.NgModel,
    decorators: [{
      type: Optional
    }]
  }],
  propDecorators: {
    variant: [{
      type: Input
    }],
    fluid: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    pSize: [{
      type: Input,
      args: ['pSize']
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event']]
    }]
  }
});
class InputTextModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputTextModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputTextModule,
    imports: [InputText],
    exports: [InputText]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputTextModule
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputTextModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [InputText],
      exports: [InputText]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { InputText, InputTextClasses, InputTextModule, InputTextStyle };
