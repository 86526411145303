export class StringHelper {
    public static isNullOrEmpty(value: any): boolean {
        return value == null || value == "";
    }

    public static isNullOrWhiteSpace(value: any): boolean {
        return value == null || value?.toString().trim() == "";
    }

    public static isNotNullOrEmpty(value: any): boolean {
        return !StringHelper.isNullOrEmpty(value);
    }

    public static isNotNullOrWhiteSpace(value: any): boolean {
        return !StringHelper.isNullOrWhiteSpace(value);
    }

    public static isNullOrUndefined(value: any): boolean {
        return value == null || value == undefined;
    }

    public static isNotNullOrUndefined(value: any): boolean {
        return !StringHelper.isNullOrUndefined(value);
    }

    public static isNullOrEmptyOrWhiteSpace(value: any): boolean {
        return StringHelper.isNullOrEmpty(value) || StringHelper.isNullOrWhiteSpace(value);
    }

    public static isNotNullOrEmptyOrWhiteSpace(value: any): boolean {
        return !StringHelper.isNullOrEmptyOrWhiteSpace(value);
    }

    public static isNullOrEmptyOrUndefined(value: any): boolean {
        return StringHelper.isNullOrEmpty(value) || StringHelper.isNullOrUndefined(value);
    }

    public static isNotNullOrEmptyOrUndefined(value: any): boolean {
        return !StringHelper.isNullOrEmptyOrUndefined(value);
    }

    public static isNullOrEmptyOrWhiteSpaceOrUndefined(value: any): boolean {
        return StringHelper.isNullOrEmpty(value) || StringHelper.isNullOrWhiteSpace(value) || StringHelper.isNullOrUndefined(value);
    }

    public static isNotNullOrEmptyOrWhiteSpaceOrUndefined(value: any): boolean {
        return !StringHelper.isNullOrEmptyOrWhiteSpaceOrUndefined(value);
    }
}