<div class="row">
    <div class="xl:col-6 lg:col-6 col-12 pt-8 mb-4 flex">
        <h2><img class="icon-small" [src]="'icons/product-compare-icon.png'" />Vergelijk onze producten</h2>
    </div>
    <div class="xl:col-6 lg:col-6 col-12 flex justify-content-end align-content-center align-items-center">
        <p-tabs [(value)]="productType">
            <p-tablist>
                <p-tab value="Electricity"><span class="pi-bolt ebemLightGreen" style="font-size: 22px;"></span>Aanbod Elektriciteit</p-tab>
                <p-tab value="Gas"><span class="material-icons ebemBlue">local_fire_department</span>Aanbod Gas</p-tab>
            </p-tablist>
        </p-tabs>
    </div>
</div>
<div class="row pb-8">
    <p-tabs class="col-12 flex" [value]="productType">
        <p-tabpanels class="no-padding">
            <p-tabpanel value="Electricity">
                <div class="xl:block lg:block hidden">
                    <div class="col-12">
                        <p-table [value]="[{}]">
                            <ng-template #header>
                                <tr>
                                    <th></th>
                                    <th class="text-center" *ngFor="let product of electricityProducts">{{product?.name}}</th>
                                </tr>
                            </ng-template>
                            <ng-template #body let-rowData let-columns="products">
                                <tr>
                                    <td class="right-border">Variabele prijs</td>
                                    <td class="text-center" *ngFor="let product of electricityProducts"><span [ngClass]="product?.isVariable ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>
                                <tr>
                                    <td class="right-border">Dynamische prijs</td>
                                    <td class="text-center" *ngFor="let product of electricityProducts"><span [ngClass]="product?.isDynamic ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>
                                <tr>
                                    <td class="right-border">Geschikt voor analoge meter</td>
                                    <td class="text-center" *ngFor="let product of electricityProducts"><span [ngClass]="product?.forAnalogMeter ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>   
                                <tr>
                                    <td class="right-border">Facturatie<br/><small class="gray">Maandelijke facturatie is enkel mogelijk met een digitale meter</small></td>
                                    <td class="text-center" *ngFor="let product of electricityProducts">{{InvoicingModality[product?.invoicing ?? 0]}}</td>
                                </tr>  
                                <tr>
                                    <td class="right-border">Domiciliëring of overschrijving</td>
                                    <td class="text-center" *ngFor="let product of electricityProducts"><span [ngClass]="product?.directDebit ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>  
                                <tr>
                                    <td class="right-border">Enkel elektronische facturatie</td>
                                    <td class="text-center" *ngFor="let product of electricityProducts"><span [ngClass]="product?.onlyElectronicInvoicing ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>  
                                <tr>
                                    <td class="right-border">Particulier of klein-zakelijk</td>
                                    <td class="text-center" *ngFor="let product of electricityProducts"><span [ngClass]="product?.individualOrSmallBusiness ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr> 
                            </ng-template>    
                        </p-table>
                    </div>
                </div>
                <div class="xl:hidden lg:hidden md:flex sm:flex">
                    <product-selection-component [enableSelection]="false" [iconCSSClass]="'pi-bolt ebemGreen'" [checkmarksCSSClass]="'check-ebemGreen'" [selectedBackgroundCSSClass]="'backgroundEbemLightGreen'" [products]="electricityProducts"></product-selection-component>
                </div>
            </p-tabpanel>
            <p-tabpanel value="Gas">
                <div class="xl:flex lg:flex hidden">
                    <div class="col-12">
                        <p-table [value]="[{}]">
                            <ng-template #header>
                                <tr>
                                    <th></th>
                                    <th class="text-center" *ngFor="let product of gasProducts">{{product?.name}}</th>
                                </tr>
                            </ng-template>
                            <ng-template #body let-rowData let-columns="products">
                                <tr>
                                    <td class="right-border">Variabele prijs</td>
                                    <td class="text-center" *ngFor="let product of gasProducts"><span [ngClass]="product?.isVariable ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>
                                <tr>
                                    <td class="right-border">Dynamische prijs</td>
                                    <td class="text-center" *ngFor="let product of gasProducts"><span [ngClass]="product?.isDynamic ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>
                                <tr>
                                    <td class="right-border">Geschikt voor analoge meter</td>
                                    <td class="text-center" *ngFor="let product of gasProducts"><span [ngClass]="product?.forAnalogMeter ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>   
                                <tr>
                                    <td class="right-border">Facturatie<br/><small class="gray">Maandelijke facturatie is enkel mogelijk met een digitale meter</small></td>
                                    <td class="text-center" *ngFor="let product of gasProducts">{{InvoicingModality[product?.invoicing ?? 0]}}</td>
                                </tr>  
                                <tr>
                                    <td class="right-border">Domiciliëring of overschrijving</td>
                                    <td class="text-center" *ngFor="let product of gasProducts"><span [ngClass]="product?.directDebit ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>  
                                <tr>
                                    <td class="right-border">Enkel elektronische facturatie</td>
                                    <td class="text-center" *ngFor="let product of gasProducts"><span [ngClass]="product?.onlyElectronicInvoicing ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr>  
                                <tr>
                                    <td class="right-border">Particulier of klein-zakelijk</td>
                                    <td class="text-center" *ngFor="let product of gasProducts"><span [ngClass]="product?.individualOrSmallBusiness ? 'pi-check ebemGreen' : 'pi-times ebemRed'"></span></td>
                                </tr> 
                            </ng-template>    
                        </p-table>
                    </div>
                </div>
                <div class="xl:hidden lg:hidden md:flex sm:flex">
                    <product-selection-component [enableSelection]="false" [iconCSSClass]="'material-icons ebemBlue'" [materialIcon]="'local_fire_department'" [checkmarksCSSClass]="'check-ebemBlue'" [selectedBackgroundCSSClass]="'backgroundEbemLightBlue'" [products]="gasProducts"></product-selection-component>
                </div>
            </p-tabpanel>
        </p-tabpanels>
    </p-tabs>
</div>