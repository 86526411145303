<div>
    <h1>Klant worden</h1>
    <p>Fantastisch dat je voor Ebem kiest! We stellen je vertrouwen op prijs en staan steeds voor je klaar als je vragen hebt.</p>
    <h3>Hoe mogen we je begroeten?</h3>
    <div class="grid fluid">
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <label for="gender">Aanspreking</label>
            </div>
            <div class="xl:col-12 lg:col-12 col-12">
                <p-select fluid="true" [options]="Gender | enumKeyValue" [ngClass]="inputGenderValid == null ||  inputGenderValid == undefined ? 'ng-pristine' : inputGenderValid === true ? 'ng-valid ng-dirty' : inputGenderValid === false ? 'ng-invalid ng-dirty' : ''" (onChange)="validateGenderInput()" [(ngModel)]="customer.gender">
                    <ng-template let-option #selectedItem>
                        <div class="flex items-center gap-2">
                            <div>{{ option.key }}</div>
                        </div>
                    </ng-template>
                    <ng-template #item let-option>
                        {{option.key}}
                    </ng-template>
                </p-select>
                <p-card class="danger no-padding" *ngIf="showErrorNoGenderChosen">Gelieve een keuze te maken</p-card>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <label for="firstName">Voornaam</label>
            </div>
            <div class="xl:col-12 lg:col-12 col-12">
                <input type="text" name="firstName" placeholder="Aa" [ngClass]="inputFirstNameValid == null ||  inputFirstNameValid == undefined ? '' : inputFirstNameValid === true ? 'ng-valid' : inputFirstNameValid === false ? 'ng-invalid' : ''" (input)="validateFirstNameInput()" [(ngModel)]="customer.firstName" pInputText >
                <p-card class="danger no-padding" *ngIf="showErrorNoFirstNameFilledIn">Voornaam is een verplicht veld</p-card>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
    
                <label for="lastName">Achternaam</label>
            </div>
            <div class="xl:col-12 lg:col-12 col-12">
                <input type="text" name="lastName" placeholder="Aa" [ngClass]="inputLastNameValid == null ||  inputLastNameValid == undefined ? '' : inputLastNameValid === true ? 'ng-valid' : inputLastNameValid === false ? 'ng-invalid' : ''" (input)="validateLastNameInput()" [(ngModel)]="customer.lastName" pInputText >
                <p-card class="danger no-padding" *ngIf="showErrorNoLastNameFilledIn">Achternaam is een verplicht veld</p-card>
            </div>
        </div>
        <h3>Je contactgegevens</h3>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <label for="telephone">Telefoon</label>
            </div>
            <div class="xl:col-12 lg:col-12 col-12">
                <input type="tel" name="telephone" placeholder="+32" [ngClass]="inputTelephoneValid == null ||  inputTelephoneValid == undefined ? '' : inputTelephoneValid === true ? 'ng-valid' : inputTelephoneValid === false ? 'ng-invalid' : ''" (input)="validateTelephoneNumberInput()" [(ngModel)]="customer.telephone" pInputText >
                <p-card class="danger no-padding" *ngIf="showErrorNoTelephoneFilledIn">Telefoon is een verplicht veld</p-card>
                <p-card class="danger no-padding" *ngIf="showErrorTelephoneWrongFormat">Telefoonnummer heeft niet het juiste formaat: landcode (bijvoorbeeld +31 voor Nederland en +32 voor België) gevolgd door 8 cijfers (vast telefoonnummer) of 9 cijfers (gsmnummer)</p-card>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <label for="email">E-mail</label>
            </div>
            <div class="xl:col-12 lg:col-12 col-12">
                <input type="email" name="email" placeholder="voorbeeld@domein.com" [ngClass]="inputEmailValid == null ||  inputEmailValid == undefined ? '' : inputEmailValid === true ? 'ng-valid' : inputEmailValid === false ? 'ng-invalid' : ''" (input)="validateEmailInput()" [(ngModel)]="customer.email" pInputText >
                <p-card class="danger no-padding" *ngIf="showErrorNoEmailFilledIn">Email is een verplicht veld</p-card>
                <p-card class="danger no-padding" *ngIf="showErrorEmailWrongFormat">Email heeft niet het juiste formaat: tekenreeks beginnend met een letter of een cijfer gevolgd door een &#64; gevolgd door een emailprovider en een webextensie (.com, .be, ...) </p-card>    
            
            </div>
        </div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p>Je koos
                    <span *ngIf="electricityType != 0">voor {{ElectricityType[electricityType]}} {{ElectricityTypeSimulation[electricityType]}}</span>
                    <span *ngIf="electricityType != 0 && gasType != 0">&nbsp;en</span>
                    <span *ngIf="gasType != 0">&nbsp;voor {{GasType[gasType]}} {{GasTypeSimulation[gasType]}}</span>
    
                    <span *ngIf="electricityType == ElectricityType['EBEM Groen B@sic +'] || gasType == GasType['EBEM G@s +']">&nbsp;dus je zal je facturen ontvangen per e-mail.</span>
                </p>
            </div>
        </div>
        @if(!customer.invoiceAddressIsSameToPostalAddress) { 
            <h3>Verbruiksadres</h3> 
        } @else { 
            <h3>Je adresgegevens</h3> 
        }
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
            <p *ngIf="postalCode">Je gaf ons al eerder je postcode, {{postalCode}}. We kennen ook ook graag je straatnaam en huisnummer, en of je verbruiksadres hetzelfde is als je factuuradres</p>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-8 lg:col-8 xl:flex lg:flex col-12">
                <label for="streetName">Straatnaam</label>
            </div>
            <div class="xl:col-4 lg:col-4 xl:flex lg:flex hidden">
                <label for="houseNumber">Huisnr. (+ busnr.)</label>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-8 lg:col-8 col-12">
                <input type="text" name="streetName" [ngClass]="inputStreetNameValid == null ||  inputStreetNameValid == undefined ? '' : inputStreetNameValid === true ? 'ng-valid' : inputStreetNameValid === false ? 'ng-invalid' : ''" (input)="validateStreetNameInput()" placeholder="Aa" [(ngModel)]="customer.streetName" pInputText >
                <p-card class="danger no-padding" *ngIf="showErrorNoStreetNameFilledIn">Straatnaam is een verplicht veld</p-card>    
            </div>
            <div class="col-12 xl:hidden lg:hidden">
                <label for="houseNumber">Huisnr. (+ busnr.)</label>
            </div>
            <div class="xl:col-4 lg:col-4 col-12">
                <input name="houseNumber" [ngClass]="inputHouseNumberValid == null ||  inputHouseNumberValid == undefined ? '' : inputHouseNumberValid === true ? 'ng-valid' : inputHouseNumberValid === false ? 'ng-invalid' : ''" (input)="validateHouseNumberInput()" placeholder="123A" [(ngModel)]="customer.houseNumber" pInputText  />
                <p-card class="danger no-padding" *ngIf="showErrorNoHouseNumberFilledIn">Huisnr. (+ busnr.) is een verplicht veld</p-card>  
            </div>
        </div>
        <div class="row">
            <div class="xl:col-4 lg:col-4 col-12">  
                <label for="postalCode">Postcode</label>
            </div>
            <div class="xl:col-8 lg:col-8 xl:flex lg:flex hidden">
                <label for="location">Plaats</label>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-4 lg:col-4 col-12">
                <p-inputnumber name="postalCode" [ngClass]="inputPostalCodeValid == null ||  inputPostalCodeValid == undefined ? '' : inputPostalCodeValid === true ? 'ng-valid' : inputPostalCodeValid === false ? 'ng-invalid' : ''" placeholder="1234" [minFractionDigits]="0" [maxFractionDigits]="0" mode="decimal" [useGrouping]="false" [(ngModel)]="customer.postalCode" (onInput)="customer.location = getNameFromPostalCode(customer.postalCode); validatePostalCodeInput(); validateLocationInput();" ></p-inputnumber>
                <p-card class="danger no-padding" *ngIf="showErrorNoPostalCodeFilledIn">Postcode is een verplicht veld</p-card>  
                <p-card class="danger no-padding" *ngIf="showErrorPostalCodeInvalid">Het opgegeven postcode is niet in Vlaanderen gelegen</p-card>  
            </div>
            <div class="col-12 xl:hidden lg:hidden">
                <label for="location">Plaats</label>
            </div>
            <div class="xl:col-8 lg:col-8 col-12">
                <p-select [fluid]="true" [virtualScroll]="true" [filter]="true" [filterBy]="'code'" [filterValue]="customer.postalCode?.toString()" [virtualScrollItemSize]="30" [options]="PostalCodes" [optionValue]="'name'" name="location" [ngClass]="inputLocationValid == null ||  inputLocationValid == undefined ? '' : inputLocationValid === true ? 'ng-valid' : inputLocationValid === false ? 'ng-invalid' : ''" placeholder="Aa" (onChange)="customer.postalCode = getPostalCodeFromName($event.value); validateLocationInput()" [(ngModel)]="customer.location">
                    <ng-template #selectedItem let-selectedOption>
                        <div class="flex items-center gap-2" *ngIf="selectedOption">
                            <div>{{ selectedOption.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template #item let-option>
                        {{option.name}}
                    </ng-template>
                </p-select>
                <p-card class="danger no-padding" *ngIf="showErrorNoLocationFilledIn">Plaats is een verplicht veld</p-card>    
            </div>
        </div>
        <div class="row">
            <div class="xl:col-1 lg:col-1 col-1">
                <p-checkbox size="large" [(ngModel)]="customer.invoiceAddressIsSameToPostalAddress" [binary]="true"></p-checkbox>
            </div>
            <div class="xl:col-11 lg:col-11 col-11">
                <label>Verbruiksadres en facturatieadres zijn hetzelfde</label>
            </div>
        </div>
        @if(!customer.invoiceAddressIsSameToPostalAddress) {
            <h3>Facturatieadres</h3>
            <div class="row">
                <div class="xl:col-8 lg:col-8 col-12">
                    <label for="invoicingAddressStreetName">Straatnaam</label>
                </div>
                <div class="xl:col-4 lg:col-4 xl:flex lg:flex hidden">
                    <label for="invoicingHouseNumber">Huisnr. (+ busnr.)</label>
                </div>
            </div>
            <div class="row">
                <div class="xl:col-8 lg:col-8 col-12">
                    <input type="text" name="invoicingAddressStreetName" [ngClass]="inputInvoicingAddressStreetNameValid == null ||  inputInvoicingAddressStreetNameValid == undefined ? '' : inputInvoicingAddressStreetNameValid === true ? 'ng-valid' : inputInvoicingAddressStreetNameValid === false ? 'ng-invalid' : ''" (input)="validateInvoicingAddressStreetNameInput()" placeholder="Aa" [(ngModel)]="customer.invoicingAddressStreetName" pInputText >
                    <p-card class="danger no-padding" *ngIf="showErrorNoInvoicingAddressStreetNameFilledIn">Straatnaam van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>    
                </div>
                <div class="col-12 xl:hidden lg:hidden">
                    <label for="houseNumber">Huisnr. (+ busnr.)</label>
                </div>
                <div class="xl:col-4 lg:col-4 col-12">
                    <input name="invoicingAddressHouseNumber" [ngClass]="inputInvoicingAddressHouseNumberValid == null ||  inputInvoicingAddressHouseNumberValid == undefined ? '' : inputInvoicingAddressHouseNumberValid === true ? 'ng-valid' : inputInvoicingAddressHouseNumberValid === false ? 'ng-invalid' : ''" (input)="validateInvoicingAddressHouseNumberInput()" placeholder="123A" [(ngModel)]="customer.invoicingAddressHouseNumber" pInputText >
                    <p-card class="danger no-padding" *ngIf="showErrorNoInvoicingAddressHouseNumberFilledIn">Huisnummer van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>  
                </div>
            </div>
            <div class="row">
                <div class="xl:col-4 lg:col-4 col-12">
                    <label for="invoicingAddressPostalCode">Postcode</label>
                </div>
                <div class="xl:col-8 lg:col-8 xl:flex lg:flex hidden"> 
                    <label for="invoicingAddressLocation">Plaats</label>
                </div>
            </div>
            <div class="row">
                @if (customer.invoicingAddressCountry == 'BE') {
                    <div class="xl:col-4 lg:col-4 col-12">
                        <p-inputnumber name="invoicingAddressPostalCode" [ngClass]="inputInvoicingAddressPostalCodeValid == null ||  inputInvoicingAddressPostalCodeValid == undefined ? '' : inputInvoicingAddressPostalCodeValid === true ? 'ng-valid' : inputInvoicingAddressPostalCodeValid === false ? 'ng-invalid' : ''" placeholder="1234" [minFractionDigits]="0" [maxFractionDigits]="0" mode="decimal" [useGrouping]="false" [(ngModel)]="customer.invoicingAddressPostalCode" (onInput)="customer.invoicingAddressLocation = getNameFromPostalCode(customer.invoicingAddressPostalCode); validateInvoicingAddressPostalCodeInput(); validateInvoicingAddressLocationInput();" ></p-inputnumber>
                        <p-card class="danger no-padding" *ngIf="showErrorNoInvoicingAddressPostalCodeFilledIn">Postcode van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>    
                    </div>
                    <div class="col-12 xl:hidden lg:hidden">
                        <label for="location">Plaats</label>
                    </div>
                    <div class="xl:col-8 lg:col-8 col-12">
                        <p-select [fluid]="true" [virtualScroll]="true"  [virtualScrollItemSize]="30" [filter]="true" [filterBy]="'code'" [filterValue]="customer.invoicingAddressPostalCode?.toString()" [options]="PostalCodes" [optionValue]="'name'" name="invoicingAddressLocation" [ngClass]="inputInvoicingAddressLocationValid == null ||  inputInvoicingAddressLocationValid == undefined ? '' : inputInvoicingAddressLocationValid === true ? 'ng-valid' : inputInvoicingAddressLocationValid === false ? 'ng-invalid' : ''" (onChange)="customer.invoicingAddressPostalCode = getPostalCodeFromName($event.value); validateInvoicingAddressLocationInput();" placeholder="Aa" [(ngModel)]="customer.invoicingAddressLocation">
                            <ng-template #selectedItem let-selectedOption>
                                <div class="flex items-center gap-2" *ngIf="selectedOption">
                                    <div>{{ selectedOption.name }}</div>
                                </div>
                            </ng-template>
                            <ng-template #item let-option>
                                {{ option.name }}
                            </ng-template>
                        </p-select>
                        <p-card class="danger no-padding" *ngIf="showErrorNoInvoicingAddressLocationFilledIn">Plaats van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>  
                    </div>
                } @else {
                    <div class="xl:col-4 lg:col-4 col-12">
                        <input type="text" name="invoicingAddressPostalCode" [ngClass]="inputInvoicingAddressPostalCodeValid == null ||  inputInvoicingAddressPostalCodeValid == undefined ? '' : inputInvoicingAddressPostalCodeValid === true ? 'ng-valid' : inputInvoicingAddressPostalCodeValid === false ? 'ng-invalid' : ''" placeholder="Aa" [(ngModel)]="customer.invoicingAddressPostalCode" (onInput)="validateInvoicingAddressPostalCodeInput(); validateInvoicingAddressLocationInput();" pInputText>
                        <p-card class="danger no-padding" *ngIf="showErrorNoInvoicingAddressPostalCodeFilledIn">Postcode van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>    
                    </div>
                    <div class="col-12 xl:hidden lg:hidden">
                        <label for="location">Plaats</label>
                    </div>
                    <div class="xl:col-8 lg:col-8 col-12">
                        <input type="text" name="invoicingAddressLocation" [ngClass]="inputInvoicingAddressLocationValid == null ||  inputInvoicingAddressLocationValid == undefined ? '' : inputInvoicingAddressLocationValid === true ? 'ng-valid' : inputInvoicingAddressLocationValid === false ? 'ng-invalid' : ''" (input)="validateInvoicingAddressLocationInput()" placeholder="Aa" [(ngModel)]="customer.invoicingAddressLocation" pInputText >
                        <p-card class="danger no-padding" *ngIf="showErrorNoInvoicingAddressLocationFilledIn">Plaats van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>  
                    </div>
                }
            </div>
            <div class="row">
                <div class="xl:col-4 lg:col-4 col-12">
                    <label for="location">Land</label>
                </div>
                <div class="xl:col-8 lg:col-8 xl:flex lg:flex md:hidden sm:hidden">
                </div>
            </div>
            <div class="row">
                <div class="xl:col-4 lg:col-4 col-12">
                    <p-select [fluid]="true" [options]="Countries | enumKeyValue" [optionValue]="'key'" [optionLabel]="'value'" name="invoicingAddressLocation" [ngClass]="inputInvoicingAddressCountryValid == null ||  inputInvoicingAddressCountryValid == undefined ? '' : inputInvoicingAddressCountryValid === true ? 'ng-valid' : inputInvoicingAddressCountryValid === false ? 'ng-invalid' : ''" (input)="validateInvoicingAddressCountryInput()" placeholder="Aa" [(ngModel)]="customer.invoicingAddressCountry" />
                    <p-card class="danger no-padding" *ngIf="showErrorNoInvoicingAddressCountryFilledIn">Land van het facturatieadres is een verplicht veld indien "Verbruiksadres en facturatieadres zijn hetzelfde" is uitgevinkt</p-card>  
                </div>
                <div class="xl:col-8 lg:col-8 xl:flex lg:flex md:hidden sm:hidden">
                </div>
            </div>
        }
    </div>
    <br/>
    <br/>
    <p-progress-bar [showValue]="false" [value]="40" [style]="{ height: '6px' }" ></p-progress-bar>
    <br/>
    <br/>
    <div class="row">
        <div class="col-6">
            <p-button class="float-left" outlined="true" [routerLink]="['/customer-wizard/customer', 'step-1']" [state]="{ 'gasType': gasType, 'electricityType': electricityType, 'customer': customer, 'contract': contract, 'paymentDetails': paymentDetails, 'postalCode': postalCode, 'priceSimulationInput': priceSimulationInput }">Terug</p-button>
        </div>
        <div class="col-6">
             <p-button class="float-right" (click)="validateForm() ? this.router.navigate(['/customer-wizard/customer', 'step-3'], { state: { 'gasType': gasType, 'electricityType': electricityType, 'customer': customer, 'contract': contract, 'paymentDetails': paymentDetails, 'postalCode': postalCode, 'priceSimulationInput': priceSimulationInput }}) : null">Volgende</p-button>
        </div>
    </div>
</div>