import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BelpexResponse, jsonToBelpexResponse } from "../models/belpexresponse.model";
import { map, Observable } from "rxjs";
import _ from "lodash";

@Injectable()
export class RatesService {
    constructor(private http: HttpClient) { }

    getDynamicGraphData(date: Date): Observable<BelpexResponse[]> {
        return this.http.get<BelpexResponse[]>(`${environment.baseUrl}/api/Rates/GetDynamicGraphData?date=${date.toDateString()}`).pipe(map(res => _.map(res, x => jsonToBelpexResponse(x))));
    }
}