<div>
<h1><img class="icon" [src]="'icons/faq-icon.png'" />Veelgestelde vragen</h1>
<div class="row">
    <div class="xl:col-8 lg:col-8 col-12">
        <faq-component *ngFor="let faq of faqItems | filter: isPinned" [faq]="faq"></faq-component>
        <br/><br/><br/><br/>
        <h3>Meer veelgestelde vragen</h3>
        <p-tabs [showNavigators]="true" [scrollable]="true" [(value)]="selectedCategory">
            <p-tablist>
                <p-tab *ngFor="let faq of faqItems | filter: isNotPinned | unique: 'category'" [value]="faq.category"><p-button [outlined]="faq.category != selectedCategory">{{ faq.category }}</p-button></p-tab>
            </p-tablist>
            <p-tabpanel [(value)]="selectedCategory">
                    <faq-component *ngFor="let faq of faqItems | filter: isNotPinned | filter: isCategory(selectedCategory)" [faq]="faq"></faq-component>
            </p-tabpanel>
        </p-tabs>
    </div>
    <div class="xl:col-4 lg:col-4 col-12">
        <p-card class="backgroundEbemLighterGreen">
            <h3>Geen antwoord gevonden op je vraag?</h3>
            <p><p-button [style]="{padding: 0}" [link]="true" [routerLink]="['/Contact']">Neem gerust contact op met ons</p-button> en dan kijken we samen naar een correcte oplossing voor jou.</p>
            <hr class="hrEbemGreen" />
            <p><span class="pi-phone ebemGreen"></span>&nbsp;014 43 10 31</p>
            <p><span class="pi-envelope ebemGreen"></span>&nbsp;<a style="text-decoration: underline !important; color: black" href="mailto:info@ebem.be">info&#64;ebem.be</a></p>
            <p><strong>Kom bij ons langs</strong></p>
            <p>Leopoldstraat 10 bus 001<br/>2330 Merksplas</p>
        </p-card>
    </div>
</div>
</div>