import { NgModule, ModuleWithProviders } from '@angular/core';
import { DynamicTemplateComponent } from './dynamic-html.component';
import { DynamicHTMLOptions } from './options';
import { DynamicHTMLRenderer } from './renderer';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';

@NgModule({
    declarations: [DynamicTemplateComponent],
    exports: [DynamicTemplateComponent],
    imports: [AccordionModule,CommonModule, FormsModule],
})
export class DynamicHTMLModule {
    static forRoot(options: DynamicHTMLOptions): ModuleWithProviders<any> {
        return {
            ngModule: DynamicHTMLModule,
            providers: [
                DynamicHTMLRenderer,
                { provide: DynamicHTMLOptions, useValue: options }
            ],
        };
    }
}