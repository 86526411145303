export class PostalCode {
    id: number;
    code: string;
    name: string;
    isValidArea: boolean;

    constructor(id: number, code: string, name: string, isValidArea: boolean) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.isValidArea = isValidArea;
    }
}

export function jsonToPostalCode(json: any): PostalCode {
    return new PostalCode(json.id, json.code, json.name, json.isValidArea);
}