import { Component, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox"
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { RadioButton, RadioButtonModule } from 'primeng/radiobutton';
import { SelectModule } from 'primeng/select';
import { ElectricityType } from "src/app/models/enums/electricity-type-simulation.enum";
import { GasType } from "src/app/models/enums/gas-type-simulation.enum";
import { Router, RouterModule } from "@angular/router";
import { PriceSimulationChoice } from "src/app/models/enums/price-simulation-choice.enum";
import { PriceSimulationDirectDebit, PriceSimulationEnergyConsumers, PriceSimulationHeatingType, PriceSimulationMeterType, PriceSimulationOptions, PriceSimulationSolarPanels, PriceSimulationTariffType } from "src/app/models/enums/price-simulation-options.enum";
import { BuildingType } from "src/app/models/enums/building-type.enum";
import { EnumKeyValue } from "src/app/pipes/enumKeyValue.pipe";
import { CommonModule } from "@angular/common";
import { OrderByPipe } from "src/app/pipes/orderBy.pipe";
import { CardModule } from "primeng/card";
import { PriceSimulationFlow } from "src/app/models/enums/price-simulation-flow.enum";
import { ProgressBarModule } from "primeng/progressbar";
import { Nullable } from "primeng/ts-helpers";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";
import { PostalCode } from "src/app/models/postalcode.model";
import { PostalcodeService } from "src/app/services/postalcode.service";

@Component({
    selector: 'price-calculation-step-2',
    standalone: true,
    styleUrls: ['./price-calculation-step-2.page.scss'],
    templateUrl: './price-calculation-step-2.page.html',
    imports: [ButtonModule, CardModule, CheckboxModule, CommonModule, EnumKeyValue, InputGroupModule, InputNumberModule, InputTextModule, InputGroupAddonModule, FormsModule, OrderByPipe, OverlayPanelModule, ProgressBarModule, RadioButtonModule, RouterModule, SelectModule],
    providers: [PostalcodeService]
})
export class PriceCalculationStep2Page {
    @ViewChild('priceSimultationChoiceGas') priceSimultationChoiceGas!: RadioButton;
    @ViewChild('priceSimultationChoiceElektriciteit') priceSimultationChoiceElektriciteit!: RadioButton;
    @ViewChild('priceSimultationChoiceBoth') priceSimultationChoiceBoth!: RadioButton;

    time = new Date().getTime();
    
    //Enum values
    electricityType!: ElectricityType;
    gasType!: GasType;
    priceSimulationInput: PriceSimulationInput = new PriceSimulationInput();
    priceSimulationChoices: PriceSimulationChoice[];
    priceSimulationOptions: PriceSimulationOptions = { meterType: PriceSimulationMeterType[""], tariffType: PriceSimulationTariffType[""], hasNightTariff: false, solarPanels: PriceSimulationSolarPanels[""], heatingType: PriceSimulationHeatingType[""], energyConsumers: [], directDebit: PriceSimulationDirectDebit[""], usageDocuments: [] };
    buildingType: BuildingType = 0;
    
    priceSimulationFlow!: keyof typeof PriceSimulationFlow;

    //Enum definitions
    ElectricityType = ElectricityType;
    GasType = GasType;
    PriceSimulationChoice = PriceSimulationChoice;
    PriceSimulationOptions = PriceSimulationOptions;
    PostalCodes: PostalCode[] = [];
    BuildingType = BuildingType;
    PriceSimulationFlow = PriceSimulationFlow;

    postalCode!: string | number | undefined;

    numberOfPersons!: number;
    estimatedElectricityUse!: number;
    estimatedGasUse!: number;

    inputPostalCodeValid: Nullable<boolean>;
    inputPriceSimulationChoiceValid: Nullable<boolean>;
    inputPriceSimulationFlowValid: Nullable<boolean>;

    showErrorNoPostalCodeChosen: boolean = false;
    showErrorNoPriceSimulationChoiceChosen: boolean = false;
    showErrorNoPriceSimulationFlowChosen: boolean = false;

    constructor(private postalCodeService: PostalcodeService, public router: Router) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"];
        this.postalCode = window.history.state["postalCode"]; 
        this.priceSimulationInput = window.history.state["priceSimulationInput"] ?? <PriceSimulationInput> { postalCode: this.postalCode?.toString() };
        this.priceSimulationChoices = window.history.state["priceSimulationChoices"] ?? [];
        this.priceSimulationOptions = window.history.state["priceSimulationOptions"] ?? <PriceSimulationOptions>{ meterType: PriceSimulationMeterType[""], tariffType: PriceSimulationTariffType[""], hasNightTariff: false, solarPanels: PriceSimulationSolarPanels[""], heatingType: PriceSimulationHeatingType[""], usageDocuments: <File[]>[], energyConsumers: <PriceSimulationEnergyConsumers[]>[], directDebit: PriceSimulationDirectDebit[""] }; 
        this.buildingType = window.history.state["buildingType"];
        this.numberOfPersons = window.history.state["numberOfPersons"];
        this.estimatedElectricityUse = window.history.state["estimatedElectricityUse"]; 
        this.estimatedGasUse = window.history.state["estimatedGasUse"];
        this.postalCodeService.getPostalCodes().subscribe(res => this.PostalCodes = res.filter(x => x.isValidArea));
    }

    valueToKey(value: any): any {
        return Object.keys(PriceSimulationFlow).find((key: any) => PriceSimulationFlow[key] === value);
    }

    validateInputs() {
        this.validatePostalCodeInput();
        this.validatePriceSimulationChoiceInput();
        this.validatePriceSimulationFlowInput();
    }

    validatePostalCodeInput() {
        this.inputPostalCodeValid = this.priceSimulationInput.postalCode != null && this.PostalCodes.map(x => x.code).includes(this.priceSimulationInput.postalCode.toString());
        this.showErrorNoPostalCodeChosen = !this.inputPostalCodeValid;
    }
    validatePriceSimulationChoiceInput() {
        this.inputPriceSimulationChoiceValid = this.priceSimulationChoices != null && this.priceSimulationChoices.length > 0;
        this.showErrorNoPriceSimulationChoiceChosen = !this.inputPriceSimulationChoiceValid;
    }
    validatePriceSimulationFlowInput() {
        this.inputPriceSimulationFlowValid = this.priceSimulationFlow != null;
        this.showErrorNoPriceSimulationFlowChosen = !this.inputPriceSimulationFlowValid;
    }

    validateForm() {
        this.validateInputs();
        return this.inputPostalCodeValid && this.inputPriceSimulationChoiceValid && this.inputPriceSimulationFlowValid;
    }

    ngAfterViewInit() {
        if (this.priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit) && this.priceSimulationChoices?.includes(PriceSimulationChoice.Gas)) {
            setTimeout(() => this.priceSimultationChoiceBoth.select(new Event('click')), 200);
        } else if (this.priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit)) {
            setTimeout(() => this.priceSimultationChoiceElektriciteit.select(new Event('click')), 200);
        } else if (this.priceSimulationChoices?.includes(PriceSimulationChoice.Gas)) {
            setTimeout(() =>this.priceSimultationChoiceGas.select(new Event('click')), 200);
        }
    }
}