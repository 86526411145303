import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Accordion, AccordionModule } from "primeng/accordion";
import { ButtonModule } from "primeng/button";
import { TabsModule } from "primeng/tabs";
import { Subscription } from "rxjs";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { InfoTab } from "src/app/models/info-tab.model";
import { UmbracoService } from "src/app/services/umbraco.service";

@Component({
    selector: 'info-page',
    templateUrl: './info.page.html',
    styleUrls: ['./info.page.scss'],
    standalone: true,
    imports: [AccordionModule, ButtonModule, DataUdiDirective, CommonModule, FormsModule, DynamicHTMLModule, TabsModule], //Do not remove DataUdiDirective it is used in the dynamic HTML template
    providers: [UmbracoService]
})
export class InfoPage {
    
    constructor(private route: ActivatedRoute, private umbracoService: UmbracoService) {
        this.subscribers.push(this.umbracoService.getInfoTabs().subscribe(res => { 
            res.forEach(x => x.subscribe(infoTab => { this.infoTabs.push(infoTab);
                this.route.params.subscribe(params => {
                    this.selectedTab = params['tab'] ? params['tab'].replace(/\-/g, ' ') : this.infoTabs[0]?.header?.toLowerCase();
                });                
            })); 
        }));
     }

    selectedTab!: string;
    Accordion = Accordion;
    infoTabs: InfoTab[] = [];
    subscribers: Subscription[] = [];
}