import { Component } from "@angular/core";
import { EditorModule } from "primeng/editor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextModule } from "primeng/inputtext";
import { Contact } from "src/app/models/contact.model";
import { CardModule } from "primeng/card";
import { CommonModule } from "@angular/common";
import { Nullable } from "primeng/ts-helpers";
import { StringHelper } from "src/app/helpers/string.helper";
import { PostalCode } from "src/app/models/postalcode.model";
import { PostalcodeService } from "src/app/services/postalcode.service";
import { NgxTurnstileModule } from "ngx-turnstile";
import { ContactService } from "src/app/services/contact.service";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { environment } from "src/environments/environment";

@Component({
    selector: 'contact-component',
    standalone: true,
    templateUrl: 'contact.component.html',
    styleUrls: ['./contact.component.scss'],
    imports: [CardModule, CommonModule, EditorModule, InputNumberModule, InputTextModule, ButtonModule, FormsModule, ReactiveFormsModule, RouterModule, ToastModule, NgxTurnstileModule],
    providers: [ContactService, MessageService, PostalcodeService]
})
export class ContactComponent {
    contactForm: Contact = new Contact();
    captchaResponse: string = '';
    PostalCodes: PostalCode[] = [];
    siteKey = environment.siteKey;

    inputVATNumberValid: Nullable<boolean>;
    inputFirstNameValid: Nullable<boolean>;
    inputLastNameValid: Nullable<boolean>;
    inputEmailValid: Nullable<boolean>;
    inputTelephoneValid: Nullable<boolean>;    
    inputPostalCodeValid: Nullable<boolean>;
    inputMessageValid: Nullable<boolean>;

    showErrorVATInvalid: boolean = false;
    showErrorNoFirstNameFilledIn: boolean = false;
    showErrorNoLastNameFilledIn: boolean = false;
    showErrorNoTelephoneFilledIn: boolean = false;
    showErrorTelephoneWrongFormat: boolean = false;
    showErrorNoEmailFilledIn: boolean = false;
    showErrorEmailWrongFormat: boolean = false;
    showErrorNoPostalCodeFilledIn: boolean = false;
    showErrorPostalCodeInvalid: boolean = false;
    showErrorNoMessageFilledIn: boolean = false;

    constructor(private contactService: ContactService, private messageService: MessageService, private postalCodeService: PostalcodeService) {
        this.postalCodeService.getPostalCodes().subscribe(res => {
            this.PostalCodes = res;
        });
    }

    validateInputs() {
        this.validateVATInput();

        this.validateFirstNameInput();
        
        this.validateLastNameInput();

        this.validateEmailInput();

        this.validateTelephoneNumberInput();

        this.validatePostalCodeInput();

        this.validateMessageInput();
    }

    validateVATInput() {
        this.inputVATNumberValid = !this.contactForm.btwNummer || !!this.contactForm.btwNummer.match(/^[B][E][0-9]{10}$/);
        this.showErrorVATInvalid = !!this.contactForm.btwNummer && !this.contactForm.btwNummer.match(/^[B][E][0-9]{10}$/);
    }
 
    validatePostalCodeInput() {
        this.inputPostalCodeValid = !!this.contactForm.postCode && !!this.PostalCodes.map(x => x.code).includes(this.contactForm.postCode.toString());
        this.showErrorNoPostalCodeFilledIn = !this.contactForm.postCode;
        this.showErrorPostalCodeInvalid = !!this.contactForm.postCode && !this.PostalCodes.map(x => x.code).includes(this.contactForm.postCode.toString());
    }

    validateTelephoneNumberInput() {
        this.inputTelephoneValid = !!this.contactForm.telefoon && (!!this.contactForm.telefoon.match(/^[+][3][1-2][0-9]{8}$/) || !!this.contactForm.telefoon.match(/^[+][3][1-2][4][0-9]{8}$/));
        this.showErrorNoTelephoneFilledIn = !this.contactForm.telefoon;
        this.showErrorTelephoneWrongFormat = !!this.contactForm.telefoon && !this.contactForm.telefoon.match(/^[+][3][1-2][0-9]{8}$/) && !this.contactForm.telefoon.match(/^[+][3][1-2][4][0-9]{8}$/);
    }

    validateEmailInput() {
        this.inputEmailValid = !!this.contactForm.email && !!this.contactForm.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/);
        this.showErrorNoEmailFilledIn = !this.contactForm.email;
        this.showErrorEmailWrongFormat = !!this.contactForm.email && !this.contactForm.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/);
    }

    validateLastNameInput() {
        this.inputLastNameValid = !!this.contactForm.naam;
        this.showErrorNoLastNameFilledIn = !this.contactForm.naam;
    }

    validateFirstNameInput() {
        this.inputFirstNameValid = !!this.contactForm.voornaam;
        this.showErrorNoFirstNameFilledIn = !this.contactForm.voornaam;
    }

    validateMessageInput() {
        this.inputMessageValid = StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(this.contactForm.bericht);
        this.showErrorNoMessageFilledIn = StringHelper.isNullOrEmptyOrWhiteSpaceOrUndefined(this.contactForm.bericht);
    }

    validateForm() {
        this.validateInputs();

        return this.inputFirstNameValid && this.inputLastNameValid && this.inputEmailValid && this.inputTelephoneValid && this.inputPostalCodeValid;
    }

    getNameFromPostalCode(postalCode: string | number | undefined): string {
        return this.PostalCodes.find(p => p.code == postalCode)?.name ?? '';
    }

    sendCaptchaResponse(captchaResponse: string) {
        this.captchaResponse = captchaResponse;
    }

    send() {
        if (this.validateForm()) {
            this.contactService.sendContactForm(this.contactForm, this.captchaResponse).toPromise().then(res => {
                this.messageService.add({severity: 'success', summary: 'Success', detail: 'Uw bericht is verzonden!'});
                this.contactForm = new Contact();
            }).catch(err => {
                this.messageService.add({severity: 'error', summary: 'Error', detail: 'Er is iets misgegaan bij het verzenden van uw bericht. Probeer het later opnieuw.'});
            });
        }
    }
}