import { Component } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { DynamicTariffsGraphComponent } from "../dynamic-tariffs-graph/dynamic-tariffs-graph.component";
import { RouterModule } from "@angular/router";

@Component({
    selector: 'dynamic-tariffs-component',
    standalone: true,
    templateUrl: './dynamic-tariffs.component.html',
    styleUrls: ['dynamic-tariffs.component.scss'],
    imports: [ButtonModule, DynamicTariffsGraphComponent, RouterModule]
})
export class DynamicTariffsComponent {
}