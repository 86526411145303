<div>
<h3>
    @if (time > 12) {
        Goedemiddag
    } @else if (time > 18) {
        Goedenavond
    } @else if (time > 0) {
        Goedemorgen
    }
</h3>

<p-card [styleClass]="'backgroundEbemLighterGreen'" [style]="{ borderRadius: '10px' }">
    <h4>Laten we beginnen met je postcode</h4>
    <p>Omdat de prijs voor distributie van gas en elektriciteit afhangt van de netbeheerder van je woonplaats hebben we je postcode nodig.</p>
    <div class="grid fluid">
        <div class="row">
            <div class="col-1">
                <span style="position: relative; top: 7.5px; font-size: 24px; color: #436807"><span class="pi-map-marker"></span></span>
            </div>
            <div class="col-11">
                <p-select class="w-full" [filter]="true" filterBy="code,name" [virtualScroll]="true" [virtualScrollItemSize]="30"  (onChange)="validatePostalCodeInput()"  [options]="PostalCodes | orderBy: 'code'" [optionValue]="'code'" [(ngModel)]="priceSimulationInput.postalCode">
                    <ng-template #selectedItem let-selectedOption>
                        <div class="flex items-center gap-2" *ngIf="selectedOption">
                            <div>{{ selectedOption.code }} - {{ selectedOption.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template #item let-option>
                        {{option.code}} - {{option.name}}
                    </ng-template>
                </p-select>
                <p-card class="danger no-padding" *ngIf="showErrorNoPostalCodeChosen">Gelieve een keuze te maken</p-card>
            </div>
    
        </div>
    </div>
</p-card>

<h4>Ben je op zoek naar elektriciteit, gas of beide?</h4>

<div>
<p-input-group (click)="priceSimultationChoiceGas.select($event); validatePriceSimulationChoiceInput()">
    <div class="radio-left"><p-radio-button #priceSimultationChoiceGas [(ngModel)]="priceSimulationChoices" binary="false" [value]="[PriceSimulationChoice.Gas]"></p-radio-button><label>&nbsp;&nbsp;&nbsp;Ik wil <strong>enkel gas</strong></label></div>
</p-input-group>

<p-input-group (click)="priceSimultationChoiceElektriciteit.select($event); validatePriceSimulationChoiceInput()">
    <div class="radio-left"><p-radio-button #priceSimultationChoiceElektriciteit [(ngModel)]="priceSimulationChoices" binary="false" [value]="[PriceSimulationChoice.Elektriciteit]"></p-radio-button><label>&nbsp;&nbsp;&nbsp;Ik wil <strong>enkel elektriciteit</strong></label></div>
</p-input-group>

<p-input-group (click)="priceSimultationChoiceBoth.select($event); validatePriceSimulationChoiceInput()">
    <div class="radio-left"><p-radio-button #priceSimultationChoiceBoth [(ngModel)]="priceSimulationChoices" binary="false" [value]="[PriceSimulationChoice.Elektriciteit, PriceSimulationChoice.Gas]"></p-radio-button><label>&nbsp;&nbsp;&nbsp;Ik wil <strong>gas én elektriciteit</strong></label></div>
</p-input-group>
</div>

<p-card class="danger no-padding" *ngIf="showErrorNoPriceSimulationChoiceChosen">Gelieve een keuze te maken</p-card>

<h4>Je producten kiezen</h4>
<p>Wil je zelf je producten kiezen, of wil je dat we je een aantal vragen stellen en jou ons beste voorstel doen?</p>

<div class="row">
    <div class="col-6">
        <p-inputgroup [ngClass]="priceSimulationFlow == PriceSimulationFlow['Product overzicht'] ? 'backgroundEbemLightGreen' : ''" (click)="priceSimulationFlowManual.select($event); validatePriceSimulationFlowInput()">
            <p-radiobutton size="large" class="radio-right" #priceSimulationFlowManual [(ngModel)]="priceSimulationFlow" [value]="PriceSimulationFlow['Product overzicht']"></p-radiobutton>
            <img class="icon-small" [src]="'icons/product-selection-icon.png'" />    
            <p>Toon mij een overzicht met alle producten, ik maak zelf een keuze.</p>
        </p-inputgroup>
    </div>
    
    <div class="col-6">
        <p-inputgroup [ngClass]="priceSimulationFlow == PriceSimulationFlow['Voorstel op maat'] ? 'backgroundEbemLightBlue' : ''" (click)="priceSimulationFlowQuestionaire.select($event); validatePriceSimulationFlowInput()">
            <p-radiobutton size="large" class="radio-right" #priceSimulationFlowQuestionaire [(ngModel)]="priceSimulationFlow" [value]="PriceSimulationFlow['Voorstel op maat']"></p-radiobutton>
            <img class="icon-small" [src]="'icons/questionaire-icon.png'" />    
            <p>Ik beantwoord een aantal vragen en krijg een voorstel op maat.</p>
        </p-inputgroup>
    </div>
</div>
<p-card class="danger no-padding" *ngIf="showErrorNoPriceSimulationFlowChosen">Gelieve een keuze te maken</p-card>
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="20" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/price-calculation', 'step-1']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Terug</p-button>
    </div>
    <div class="col-6">
        <p-button [severity]="priceSimulationFlow == PriceSimulationFlow['Product overzicht'] ? 'primary' : priceSimulationFlow == PriceSimulationFlow['Voorstel op maat'] ? 'secondary' : 'primary'" class="float-right" (click)="validateForm() ? this.router.navigate(['/price-calculation', 'step-2' + priceSimulationFlow], {state:{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }}) : null">{{ valueToKey(priceSimulationFlow) }} <span class="pi-arrow-right"></span></p-button>
    </div>
</div>