import { trigger, transition, style, animate } from '@angular/animations';
import * as i1 from '@angular/common';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, NgZone, numberAttribute, booleanAttribute, ContentChild, ContentChildren, ViewChild, Output, Input, ViewEncapsulation, ChangeDetectionStrategy, Component, Directive, HostListener, PLATFORM_ID, Inject, Optional, forwardRef, ElementRef, NgModule } from '@angular/core';
import * as i5 from '@angular/forms';
import { FormsModule } from '@angular/forms';
import * as i3 from 'primeng/api';
import { OverlayService, FilterService, FilterOperator, FilterMatchMode, PrimeTemplate, TranslationKeys, SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import * as i9 from 'primeng/button';
import { Button, ButtonModule } from 'primeng/button';
import * as i7 from 'primeng/checkbox';
import { CheckboxModule } from 'primeng/checkbox';
import * as i11 from 'primeng/datepicker';
import { DatePickerModule } from 'primeng/datepicker';
import { DomHandler, ConnectedOverlayScrollHandler } from 'primeng/dom';
import { ArrowDownIcon } from 'primeng/icons/arrowdown';
import { ArrowUpIcon } from 'primeng/icons/arrowup';
import { CheckIcon } from 'primeng/icons/check';
import { FilterIcon } from 'primeng/icons/filter';
import { FilterSlashIcon } from 'primeng/icons/filterslash';
import { PlusIcon } from 'primeng/icons/plus';
import { SortAltIcon } from 'primeng/icons/sortalt';
import { SortAmountDownIcon } from 'primeng/icons/sortamountdown';
import { SortAmountUpAltIcon } from 'primeng/icons/sortamountupalt';
import { SpinnerIcon } from 'primeng/icons/spinner';
import { TrashIcon } from 'primeng/icons/trash';
import * as i12 from 'primeng/inputnumber';
import { InputNumberModule } from 'primeng/inputnumber';
import * as i10 from 'primeng/inputtext';
import { InputTextModule } from 'primeng/inputtext';
import * as i2 from 'primeng/paginator';
import { PaginatorModule } from 'primeng/paginator';
import * as i6 from 'primeng/radiobutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import * as i4 from 'primeng/scroller';
import { ScrollerModule } from 'primeng/scroller';
import * as i8 from 'primeng/select';
import { SelectModule } from 'primeng/select';
import { SelectButtonModule } from 'primeng/selectbutton';
import { UniqueComponentId, ObjectUtils, ZIndexUtils } from 'primeng/utils';
import { Subject } from 'rxjs';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-datatable {
    position: relative;
}

.p-datatable-table {
    border-spacing: 0;
    border-collapse: separate;
    width: 100%;
}

.p-datatable-scrollable > .p-datatable-table-container {
    position: relative;
}
/* For PrimeNG */
.p-datatable-scrollable-table > .p-datatable-thead {
    top: 0;
    z-index: 2;
}
/* For PrimeNG */
.p-datatable-scrollable-table > .p-datatable-frozen-tbody {
    position: sticky;
    z-index: 2;
}
/* For PrimeNG */
.p-datatable-scrollable-table > .p-datatable-frozen-tbody + .p-datatable-frozen-tbody {
    z-index: 1;
}

.p-datatable-scrollable-table > .p-datatable-tfoot {
    bottom: 0;
    z-index: 1;
}

.p-datatable-scrollable > tr:not(:has(.p-datatable-selectable-row)) >.p-datatable-frozen-column {
    position: sticky;
    background: ${dt('datatable.header.cell.background')};
}

.p-datatable-scrollable th.p-datatable-frozen-column {
    z-index: 1;
    position: sticky;
    background: ${dt('datatable.header.cell.background')};
}
.p-datatable-scrollable td.p-datatable-frozen-column {
    z-index: 1;
    position: sticky;
    background: ${dt('datatable.header.cell.background')};
}

.p-datatable-scrollable > .p-datatable-table-container > .p-datatable-table > .p-datatable-thead,
.p-datatable-scrollable > .p-datatable-table-container > .p-virtualscroller > .p-datatable-table > .p-datatable-thead {
    background: ${dt('datatable.header.cell.background')};
}

.p-datatable-scrollable > .p-datatable-table-container > .p-datatable-table > .p-datatable-tfoot,
.p-datatable-scrollable > .p-datatable-table-container > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
    background: ${dt('datatable.footer.cell.background')};
}

.p-datatable-flex-scrollable {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.p-datatable-flex-scrollable > .p-datatable-table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.p-datatable-scrollable-table > .p-datatable-tbody > .p-datatable-row-group-header {
    position: sticky;
    z-index: 1;
}

.p-datatable-resizable-table > .p-datatable-thead > tr > th,
.p-datatable-resizable-table > .p-datatable-tfoot > tr > td,
.p-datatable-resizable-table > .p-datatable-tbody > tr > td {
    overflow: hidden;
    white-space: nowrap;
}

.p-datatable-resizable-table > .p-datatable-thead > tr > th.p-datatable-resizable-column:not(.p-datatable-frozen-column) {
    background-clip: padding-box;
    position: relative;
}

.p-datatable-resizable-table-fit > .p-datatable-thead > tr > th.p-datatable-resizable-column:last-child .p-datatable-column-resizer {
    display: none;
}

.p-datatable-column-resizer {
    display: block;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    margin: 0;
    width: ${dt('datatable.column.resizer.width')};
    height: 100%;
    padding: 0px;
    cursor: col-resize;
    border: 1px solid transparent;
}

/*
.p-datatable-column-header-content {
    display: flex;
    align-items: center;
    gap: ${dt('datatable.header.cell.gap')};
}
.p-datatable-thead > tr > th {
    display: flex;
    align-items: center;
    gap: ${dt('datatable.header.cell.gap')};
}
*/

.p-datatable-column-resize-indicator {
    width: ${dt('datatable.resize.indicator.width')};
    position: absolute;
    z-index: 10;
    display: none;
    background: ${dt('datatable.resize.indicator.color')};
}

.p-datatable-row-reorder-indicator-up,
.p-datatable-row-reorder-indicator-down {
    position: absolute;
    display: none;
}

.p-datatable-reorderable-column,
.p-datatable-reorderable-row-handle {
    cursor: move;
}

.p-datatable-mask {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.p-datatable-inline-filter {
    display: flex;
    align-items: center;
    width: 100%;
    gap: ${dt('datatable.filter.inline.gap')};
}

.p-datatable-inline-filter .p-datatable-filter-element-container {
    flex: 1 1 auto;
    width: 1%;
}

.p-datatable-filter-overlay {
    position: absolute;
    background: ${dt('datatable.filter.overlay.select.background')};
    color: ${dt('datatable.filter.overlay.select.color')};
    border: 1px solid ${dt('datatable.filter.overlay.select.border.color')};
    border-radius: ${dt('datatable.filter.overlay.select.border.radius')};
    box-shadow: ${dt('datatable.filter.overlay.select.shadow')};
    min-width: 12.5rem;
}

.p-datatable-filter-constraint-list {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: ${dt('datatable.filter.constraint.list.padding')};
    gap: ${dt('datatable.filter.constraint.list.gap')};
}

.p-datatable-filter-constraint {
    padding: ${dt('datatable.filter.constraint.padding')};
    color: ${dt('datatable.filter.constraint.color')};
    border-radius: ${dt('datatable.filter.constraint.border.radius')};
    cursor: pointer;
    transition: background ${dt('datatable.transition.duration')}, color ${dt('datatable.transition.duration')}, border-color ${dt('datatable.transition.duration')},
        box-shadow ${dt('datatable.transition.duration')};
}

.p-datatable-filter-constraint-selected {
    background: ${dt('datatable.filter.constraint.selected.background')};
    color: ${dt('datatable.filter.constraint.selected.color')};
}

.p-datatable-filter-constraint:not(.p-datatable-filter-constraint-selected):not(.p-disabled):hover {
    background: ${dt('datatable.filter.constraint.focus.background')};
    color: ${dt('datatable.filter.constraint.focus.color')};
}

.p-datatable-filter-constraint:focus-visible {
    outline: 0 none;
    background: ${dt('datatable.filter.constraint.focus.background')};
    color: ${dt('datatable.filter.constraint.focus.color')};
}

.p-datatable-filter-constraint-selected:focus-visible {
    outline: 0 none;
    background: ${dt('datatable.filter.constraint.selected.focus.background')};
    color: ${dt('datatable.filter.constraint.selected.focus.color')};
}

.p-datatable-filter-constraint-separator {
    border-top: 1px solid ${dt('datatable.filter.constraint.separator.border.color')};
}

.p-datatable-popover-filter {
    display: inline-flex;
    margin-inline-start: auto;
}

.p-datatable-filter-overlay-popover {
    background: ${dt('datatable.filter.overlay.popover.background')};
    color: ${dt('datatable.filter.overlay.popover.color')};
    border: 1px solid ${dt('datatable.filter.overlay.popover.border.color')};
    border-radius: ${dt('datatable.filter.overlay.popover.border.radius')};
    box-shadow: ${dt('datatable.filter.overlay.popover.shadow')};
    min-width: 12.5rem;
    padding: ${dt('datatable.filter.overlay.popover.padding')};
    display: flex;
    flex-direction: column;
    gap: ${dt('datatable.filter.overlay.popover.gap')};
}

.p-datatable-filter-operator-dropdown, .p-datatable-filter-constraint-dropdown {
    width: 100%;
}

.p-datatable-filter-rule-list,
.p-datatable-filter-rule {
    display: flex;
    flex-direction: column;
    gap: ${dt('datatable.filter.overlay.popover.gap')};
}

.p-datatable-filter-rule {
    border-bottom: 1px solid ${dt('datatable.filter.rule.border.color')};
}

.p-datatable-filter-rule:last-child {
    border-bottom: 0 none;
}

.p-datatable-filter-add-rule-button, .p-datatable-filter-remove-rule-button {
    width: 100%;
}

.p-datatable-filter-remove-button {
    width: 100%;
}

.p-datatable-filter-buttonbar {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p-datatable-virtualscroller-spacer {
    display: flex;
}

.p-datatable .p-virtualscroller .p-virtualscroller-loading {
    transform: none !important;
    min-height: 0;
    position: sticky;
    top: 0;
    inset-inline-start: 0;
}

.p-datatable-paginator-top {
    border-color: ${dt('datatable.paginator.top.border.color')};
    border-style: solid;
    border-width: ${dt('datatable.paginator.top.border.width')};
}

.p-datatable-paginator-bottom {
    border-color: ${dt('datatable.paginator.bottom.border.color')};
    border-style: solid;
    border-width: ${dt('datatable.paginator.bottom.border.width')};
}

.p-datatable-header {
    background: ${dt('datatable.header.background')};
    color: ${dt('datatable.header.color')};
    border-color: ${dt('datatable.header.border.color')};
    border-style: solid;
    border-width: ${dt('datatable.header.border.width')};
    padding: ${dt('datatable.header.padding')};
}

.p-datatable-footer {
    background: ${dt('datatable.footer.background')};
    color: ${dt('datatable.footer.color')};
    border-color: ${dt('datatable.footer.border.color')};
    border-style: solid;
    border-width: ${dt('datatable.footer.border.width')};
    padding: ${dt('datatable.footer.padding')};
}

.p-datatable-thead > tr > th {
    padding: ${dt('datatable.header.cell.padding')};
    background: ${dt('datatable.header.cell.background')};
    border-color: ${dt('datatable.header.cell.border.color')};
    border-style: solid;
    border-width: 0 0 1px 0;
    color: ${dt('datatable.header.cell.color')};
    font-weight: ${dt('datatable.column.title.font.weight')};
    text-align: start;
    transition: background ${dt('datatable.transition.duration')}, color ${dt('datatable.transition.duration')}, border-color ${dt('datatable.transition.duration')},
            outline-color ${dt('datatable.transition.duration')}, box-shadow ${dt('datatable.transition.duration')};
}

/** For PrimeNG **/
.p-datatable-thead > tr > th p-columnfilter {
    font-weight: normal;
}
/** For PrimeNG End **/

/*
.p-datatable-column-title {
    font-weight: ${dt('datatable.column.title.font.weight')};
}
*/

.p-datatable-tbody > tr {
    outline-color: transparent;
    background: ${dt('datatable.row.background')};
    color: ${dt('datatable.row.color')};
    transition: background ${dt('datatable.transition.duration')}, color ${dt('datatable.transition.duration')}, border-color ${dt('datatable.transition.duration')},
            outline-color ${dt('datatable.transition.duration')}, box-shadow ${dt('datatable.transition.duration')};
}

.p-datatable-tbody > tr > td {
    text-align: start;
    border-color: ${dt('datatable.body.cell.border.color')};
    border-style: solid;
    border-width: 0 0 1px 0;
    padding: ${dt('datatable.body.cell.padding')};
}

.p-datatable-hoverable .p-datatable-tbody > tr:not(.p-datatable-row-selected):hover {
    background: ${dt('datatable.row.hover.background')};
    color: ${dt('datatable.row.hover.color')};
}

.p-datatable-tbody > tr.p-datatable-row-selected {
    background: ${dt('datatable.row.selected.background')};
    color: ${dt('datatable.row.selected.color')};
}

.p-datatable-tbody > tr:has(+ .p-datatable-row-selected) > td {
    border-bottom-color: ${dt('datatable.body.cell.selected.border.color')};
}

.p-datatable-tbody > tr.p-datatable-row-selected > td {
    border-bottom-color: ${dt('datatable.body.cell.selected.border.color')};
}

.p-datatable-tbody > tr:focus-visible,
.p-datatable-tbody > tr.p-datatable-contextmenu-row-selected {
    box-shadow: ${dt('datatable.row.focus.ring.shadow')};
    outline: ${dt('datatable.row.focus.ring.width')} ${dt('datatable.row.focus.ring.style')} ${dt('datatable.row.focus.ring.color')};
    outline-offset: ${dt('datatable.row.focus.ring.offset')};
}

.p-datatable-tbody:has(+ .p-datatable-tfoot) > tr:last-child > td {
    border-width: 0;
}

.p-datatable-tfoot > tr > td {
    text-align: start;
    padding: ${dt('datatable.footer.cell.padding')};
    border-color: ${dt('datatable.footer.cell.border.color')};
    border-style: solid;
    border-width: 1px 0 1px 0;
    color: ${dt('datatable.footer.cell.color')};
    background: ${dt('datatable.footer.cell.background')};
}

.p-datatable-column-footer {
    font-weight: ${dt('datatable.column.footer.font.weight')};
}

.p-datatable-sortable-column {
    cursor: pointer;
    user-select: none;
    outline-color: transparent;
}

.p-datatable-thead > tr > th,
.p-datatable-sort-icon,
.p-datatable-sort-badge {
    vertical-align: middle;
}

.p-datatable-sort-icon {
    color: ${dt('datatable.sort.icon.color')};
    transition: color ${dt('datatable.transition.duration')};
}

.p-datatable-sortable-column:not(.p-datatable-column-sorted):hover {
    background: ${dt('datatable.header.cell.hover.background')};
    color: ${dt('datatable.header.cell.hover.color')};
}

.p-datatable-sortable-column:not(.p-datatable-column-sorted):hover .p-datatable-sort-icon {
    color: ${dt('datatable.sort.icon.hover.color')};
}

.p-datatable-thead > tr > th.p-datatable-column-sorted {
    background: ${dt('datatable.header.cell.selected.background')};
    color: ${dt('datatable.header.cell.selected.color')};
}

.p-datatable-thead > tr > th.p-datatable-column-sorted .p-datatable-sort-icon {
    color: ${dt('datatable.header.cell.selected.color')};
}

.p-datatable-sortable-column:focus-visible {
    box-shadow: ${dt('datatable.header.cell.focus.ring.shadow')};
    outline: ${dt('datatable.header.cell.focus.ring.width')} ${dt('datatable.header.cell.focus.ring.style')} ${dt('datatable.header.cell.focus.ring.color')};
    outline-offset: ${dt('datatable.header.cell.focus.ring.offset')};
}

.p-datatable-hoverable .p-datatable-selectable-row {
    cursor: pointer;
}

.p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 ${dt('datatable.drop.point.color')};
}

.p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 ${dt('datatable.drop.point.color')};
}

.p-datatable-loading-icon {
    font-size: ${dt('datatable.loading.icon.size')};
    width: ${dt('datatable.loading.icon.size')};
    height: ${dt('datatable.loading.icon.size')};
}

.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
}

.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
}

.p-datatable-gridlines .p-datatable-paginator-top {
    border-width: 1px 1px 0 1px;
}

.p-datatable-gridlines .p-datatable-paginator-bottom {
    border-width: 0 1px 1px 1px;
}

.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px;
}

.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px;
}

.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px;
}

.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0 1px;
}

p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px;
}

.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 1px;
}

.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px;
}

.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
    border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
    border-width: 0 0 0 1px;
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd) {
    background: ${dt('datatable.row.striped.background')};
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd).p-datatable-row-selected {
    background: ${dt('datatable.row.selected.background')};
    color: ${dt('datatable.row.selected.color')};
}

.p-datatable-striped.p-datatable-hoverable .p-datatable-tbody > tr:not(.p-datatable-row-selected):hover {
    background: ${dt('datatable.row.hover.background')};
    color: ${dt('datatable.row.hover.color')};
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.375rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.375rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.375rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.375rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.375rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 0.9375rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 0.9375rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
    padding: 0.9375rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td {
    padding: 0.9375rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 0.9375rem 1.25rem;
}

.p-datatable-row-toggle-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: ${dt('datatable.row.toggle.button.size')};
    height: ${dt('datatable.row.toggle.button.size')};
    color: ${dt('datatable.row.toggle.button.color')};
    border: 0 none;
    background: transparent;
    cursor: pointer;
    border-radius: ${dt('datatable.row.toggle.button.border.radius')};
    transition: background ${dt('datatable.transition.duration')}, color ${dt('datatable.transition.duration')}, border-color ${dt('datatable.transition.duration')},
            outline-color ${dt('datatable.transition.duration')}, box-shadow ${dt('datatable.transition.duration')};
    outline-color: transparent;
    user-select: none;
}

.p-datatable-row-toggle-button:enabled:hover {
    color: ${dt('datatable.row.toggle.button.hover.color')};
    background: ${dt('datatable.row.toggle.button.hover.background')};
}

.p-datatable-tbody > tr.p-datatable-row-selected .p-datatable-row-toggle-button:hover {
    background: ${dt('datatable.row.toggle.button.selected.hover.background')};
    ${dt('datatable.row.toggle.button.selected.hover.color')};
}

.p-datatable-row-toggle-button:focus-visible {
    box-shadow: ${dt('datatable.row.toggle.button.focus.ring.shadow')};
    outline: ${dt('datatable.row.toggle.button.focus.ring.width')} ${dt('datatable.row.toggle.button.focus.ring.style')} ${dt('datatable.row.toggle.button.focus.ring.color')};
    outline-offset: ${dt('datatable.row.toggle.button.focus.ring.offset')};
}
`;
const classes = {
  root: ({
    instance
  }) => ({
    'p-datatable p-component': true,
    'p-datatable-hoverable': instance.rowHover || instance.selectionMode,
    'p-datatable-resizable': instance.resizableColumns,
    'p-datatable-resizable-fit': instance.resizableColumns && instance.columnResizeMode === 'fit',
    'p-datatable-scrollable': instance.scrollable,
    'p-datatable-flex-scrollable': instance.scrollable && instance.scrollHeight === 'flex',
    'p-datatable-striped': instance.stripedRows,
    'p-datatable-gridlines': instance.showGridlines,
    'p-datatable-sm': instance.size === 'small',
    'p-datatable-lg': instance.size === 'large'
  }),
  mask: 'p-datatable-mask p-overlay-mask',
  loadingIcon: 'p-datatable-loading-icon',
  header: 'p-datatable-header',
  pcPaginator: ({
    instance
  }) => 'p-datatable-paginator-' + instance.paginatorPosition,
  tableContainer: 'p-datatable-table-container',
  table: ({
    instance
  }) => ({
    'p-datatable-table': true,
    'p-datatable-scrollable-table': instance.scrollable,
    'p-datatable-resizable-table': instance.resizableColumns,
    'p-datatable-resizable-table-fit': instance.resizableColumns && instance.columnResizeMode === 'fit'
  }),
  thead: 'p-datatable-thead',
  columnResizer: 'p-datatable-column-resizer',
  columnHeaderContent: 'p-datatable-column-header-content',
  columnTitle: 'p-datatable-column-title',
  columnFooter: 'p-datatable-column-footer',
  sortIcon: 'p-datatable-sort-icon',
  pcSortBadge: 'p-datatable-sort-badge',
  filter: ({
    instance
  }) => ({
    'p-datatable-filter': true,
    'p-datatable-inline-filter': instance.display === 'row',
    'p-datatable-popover-filter': instance.display === 'menu'
  }),
  filterElementContainer: 'p-datatable-filter-element-container',
  pcColumnFilterButton: 'p-datatable-column-filter-button',
  pcColumnFilterClearButton: 'p-datatable-column-filter-clear-button',
  filterOverlay: ({
    instance
  }) => ({
    'p-datatable-filter-overlay p-component': true,
    'p-datatable-filter-overlay-popover': instance.display === 'menu'
  }),
  filterConstraintList: 'p-datatable-filter-constraint-list',
  filterConstraint: 'p-datatable-filter-constraint',
  filterConstraintSeparator: 'p-datatable-filter-constraint-separator',
  filterOperator: 'p-datatable-filter-operator',
  pcFilterOperatorDropdown: 'p-datatable-filter-operator-dropdown',
  filterRuleList: 'p-datatable-filter-rule-list',
  filterRule: 'p-datatable-filter-rule',
  pcFilterConstraintDropdown: 'p-datatable-filter-constraint-dropdown',
  pcFilterRemoveRuleButton: 'p-datatable-filter-remove-rule-button',
  pcFilterAddRuleButton: 'p-datatable-filter-add-rule-button',
  filterButtonbar: 'p-datatable-filter-buttonbar',
  pcFilterClearButton: 'p-datatable-filter-clear-button',
  pcFilterApplyButton: 'p-datatable-filter-apply-button',
  tbody: ({
    instance
  }) => ({
    'p-datatable-tbody': true,
    'p-datatable-frozen-tbody': instance.frozenValue || instance.frozenBodyTemplate,
    'p-virtualscroller-content': instance.virtualScroll
  }),
  rowGroupHeader: 'p-datatable-row-group-header',
  rowToggleButton: 'p-datatable-row-toggle-button',
  rowToggleIcon: 'p-datatable-row-toggle-icon',
  rowExpansion: 'p-datatable-row-expansion',
  rowGroupFooter: 'p-datatable-row-group-footer',
  emptyMessage: 'p-datatable-empty-message',
  bodyCell: ({
    instance
  }) => ({
    'p-datatable-frozen-column': instance.columnProp('frozen')
  }),
  reorderableRowHandle: 'p-datatable-reorderable-row-handle',
  pcRowEditorInit: 'p-datatable-row-editor-init',
  pcRowEditorSave: 'p-datatable-row-editor-save',
  pcRowEditorCancel: 'p-datatable-row-editor-cancel',
  tfoot: 'p-datatable-tfoot',
  footerCell: ({
    instance
  }) => ({
    'p-datatable-frozen-column': instance.columnProp('frozen')
  }),
  virtualScrollerSpacer: 'p-datatable-virtualscroller-spacer',
  footer: 'p-datatable-tfoot',
  columnResizeIndicator: 'p-datatable-column-resize-indicator',
  rowReorderIndicatorUp: 'p-datatable-row-reorder-indicator-up',
  rowReorderIndicatorDown: 'p-datatable-row-reorder-indicator-down'
};
const inlineStyles = {
  tableContainer: ({
    instance
  }) => ({
    'max-height': instance.virtualScroll ? '' : instance.scrollHeight,
    overflow: 'auto'
  }),
  thead: {
    position: 'sticky'
  },
  tfoot: {
    position: 'sticky'
  }
};
class TableStyle extends BaseStyle {
  name = 'datatable';
  theme = theme;
  classes = classes;
  inlineStyles = inlineStyles;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TableStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * DataTable displays data in tabular format.
 *
 * [Live Demo](https://www.primeng.org/table/)
 *
 * @module tablestyle
 *
 */
var TableClasses;
(function (TableClasses) {
  /**
   * Class name of the root element
   */
  TableClasses["root"] = "p-datatable";
  /**
   * Class name of the mask element
   */
  TableClasses["mask"] = "p-datatable-mask";
  /**
   * Class name of the loading icon element
   */
  TableClasses["loadingIcon"] = "p-datatable-loading-icon";
  /**
   * Class name of the header element
   */
  TableClasses["header"] = "p-datatable-header";
  /**
   * Class name of the paginator element
   */
  TableClasses["pcPaginator"] = "p-datatable-paginator-[position]";
  /**
   * Class name of the table container element
   */
  TableClasses["tableContainer"] = "p-datatable-table-container";
  /**
   * Class name of the table element
   */
  TableClasses["table"] = "p-datatable-table";
  /**
   * Class name of the thead element
   */
  TableClasses["thead"] = "p-datatable-thead";
  /**
   * Class name of the column resizer element
   */
  TableClasses["columnResizer"] = "p-datatable-column-resizer";
  /**
   * Class name of the column header content element
   */
  TableClasses["columnHeaderContent"] = "p-datatable-column-header-content";
  /**
   * Class name of the column title element
   */
  TableClasses["columnTitle"] = "p-datatable-column-title";
  /**
   * Class name of the sort icon element
   */
  TableClasses["sortIcon"] = "p-datatable-sort-icon";
  /**
   * Class name of the sort badge element
   */
  TableClasses["pcSortBadge"] = "p-datatable-sort-badge";
  /**
   * Class name of the filter element
   */
  TableClasses["filter"] = "p-datatable-filter";
  /**
   * Class name of the filter element container element
   */
  TableClasses["filterElementContainer"] = "p-datatable-filter-element-container";
  /**
   * Class name of the column filter button element
   */
  TableClasses["pcColumnFilterButton"] = "p-datatable-column-filter-button";
  /**
   * Class name of the column filter clear button element
   */
  TableClasses["pcColumnFilterClearButton"] = "p-datatable-column-filter-clear-button";
  /**
   * Class name of the filter overlay element
   */
  TableClasses["filterOverlay"] = "p-datatable-filter-overlay";
  /**
   * Class name of the filter constraint list element
   */
  TableClasses["filterConstraintList"] = "p-datatable-filter-constraint-list";
  /**
   * Class name of the filter constraint element
   */
  TableClasses["filterConstraint"] = "p-datatable-filter-constraint";
  /**
   * Class name of the filter constraint separator element
   */
  TableClasses["filterConstraintSeparator"] = "p-datatable-filter-constraint-separator";
  /**
   * Class name of the filter operator element
   */
  TableClasses["filterOperator"] = "p-datatable-filter-operator";
  /**
   * Class name of the filter operator dropdown element
   */
  TableClasses["pcFilterOperatorDropdown"] = "p-datatable-filter-operator-dropdown";
  /**
   * Class name of the filter rule list element
   */
  TableClasses["filterRuleList"] = "p-datatable-filter-rule-list";
  /**
   * Class name of the filter rule element
   */
  TableClasses["filterRule"] = "p-datatable-filter-rule";
  /**
   * Class name of the filter constraint dropdown element
   */
  TableClasses["pcFilterConstraintDropdown"] = "p-datatable-filter-constraint-dropdown";
  /**
   * Class name of the filter remove rule button element
   */
  TableClasses["pcFilterRemoveRuleButton"] = "p-datatable-filter-remove-rule-button";
  /**
   * Class name of the filter add rule button element
   */
  TableClasses["pcFilterAddRuleButton"] = "p-datatable-filter-add-rule-button";
  /**
   * Class name of the filter buttonbar element
   */
  TableClasses["filterButtonbar"] = "p-datatable-filter-buttonbar";
  /**
   * Class name of the filter clear button element
   */
  TableClasses["pcFilterClearButton"] = "p-datatable-filter-clear-button";
  /**
   * Class name of the filter apply button element
   */
  TableClasses["pcFilterApplyButton"] = "p-datatable-filter-apply-button";
  /**
   * Class name of the tbody element
   */
  TableClasses["tbody"] = "p-datatable-tbody";
  /**
   * Class name of the row group header element
   */
  TableClasses["rowGroupHeader"] = "p-datatable-row-group-header";
  /**
   * Class name of the row toggle button element
   */
  TableClasses["rowToggleButton"] = "p-datatable-row-toggle-button";
  /**
   * Class name of the row toggle icon element
   */
  TableClasses["rowToggleIcon"] = "p-datatable-row-toggle-icon";
  /**
   * Class name of the row expansion element
   */
  TableClasses["rowExpansion"] = "p-datatable-row-expansion";
  /**
   * Class name of the row group footer element
   */
  TableClasses["rowGroupFooter"] = "p-datatable-row-group-footer";
  /**
   * Class name of the empty message element
   */
  TableClasses["emptyMessage"] = "p-datatable-empty-message";
  /**
   * Class name of the reorderable row handle element
   */
  TableClasses["reorderableRowHandle"] = "p-datatable-reorderable-row-handle";
  /**
   * Class name of the row editor init element
   */
  TableClasses["pcRowEditorInit"] = "p-datatable-row-editor-init";
  /**
   * Class name of the row editor save element
   */
  TableClasses["pcRowEditorSave"] = "p-datatable-row-editor-save";
  /**
   * Class name of the row editor cancel element
   */
  TableClasses["pcRowEditorCancel"] = "p-datatable-row-editor-cancel";
  /**
   * Class name of the tfoot element
   */
  TableClasses["tfoot"] = "p-datatable-tfoot";
  /**
   * Class name of the virtual scroller spacer element
   */
  TableClasses["virtualScrollerSpacer"] = "p-datatable-virtualscroller-spacer";
  /**
   * Class name of the footer element
   */
  TableClasses["footer"] = "p-datatable-footer";
  /**
   * Class name of the column resize indicator element
   */
  TableClasses["columnResizeIndicator"] = "p-datatable-column-resize-indicator";
  /**
   * Class name of the row reorder indicator up element
   */
  TableClasses["rowReorderIndicatorUp"] = "p-datatable-row-reorder-indicator-up";
  /**
   * Class name of the row reorder indicator down element
   */
  TableClasses["rowReorderIndicatorDown"] = "p-datatable-row-reorder-indicator-down";
})(TableClasses || (TableClasses = {}));
class TableService {
  sortSource = new Subject();
  selectionSource = new Subject();
  contextMenuSource = new Subject();
  valueSource = new Subject();
  totalRecordsSource = new Subject();
  columnsSource = new Subject();
  sortSource$ = this.sortSource.asObservable();
  selectionSource$ = this.selectionSource.asObservable();
  contextMenuSource$ = this.contextMenuSource.asObservable();
  valueSource$ = this.valueSource.asObservable();
  totalRecordsSource$ = this.totalRecordsSource.asObservable();
  columnsSource$ = this.columnsSource.asObservable();
  onSort(sortMeta) {
    this.sortSource.next(sortMeta);
  }
  onSelectionChange() {
    this.selectionSource.next(null);
  }
  onContextMenu(data) {
    this.contextMenuSource.next(data);
  }
  onValueChange(value) {
    this.valueSource.next(value);
  }
  onTotalRecordsChange(value) {
    this.totalRecordsSource.next(value);
  }
  onColumnsChange(columns) {
    this.columnsSource.next(columns);
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableService,
    deps: [],
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableService
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TableService,
  decorators: [{
    type: Injectable
  }]
});
/**
 * Table displays data in tabular format.
 * @group Components
 */
class Table extends BaseComponent {
  /**
   * An array of objects to represent dynamic columns that are frozen.
   * @group Props
   */
  frozenColumns;
  /**
   * An array of objects to display as frozen.
   * @group Props
   */
  frozenValue;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the table.
   * @group Props
   */
  tableStyle;
  /**
   * Style class of the table.
   * @group Props
   */
  tableStyleClass;
  /**
   * When specified as true, enables the pagination.
   * @group Props
   */
  paginator;
  /**
   * Number of page links to display in paginator.
   * @group Props
   */
  pageLinks = 5;
  /**
   * Array of integer/object values to display inside rows per page dropdown of paginator
   * @group Props
   */
  rowsPerPageOptions;
  /**
   * Whether to show it even there is only one page.
   * @group Props
   */
  alwaysShowPaginator = true;
  /**
   * Position of the paginator, options are "top", "bottom" or "both".
   * @group Props
   */
  paginatorPosition = 'bottom';
  /**
   * Custom style class for paginator
   * @group Props
   */
  paginatorStyleClass;
  /**
   * Target element to attach the paginator dropdown overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
   * @group Props
   */
  paginatorDropdownAppendTo;
  /**
   * Paginator dropdown height of the viewport in pixels, a scrollbar is defined if height of list exceeds this value.
   * @group Props
   */
  paginatorDropdownScrollHeight = '200px';
  /**
   * Template of the current page report element. Available placeholders are {currentPage},{totalPages},{rows},{first},{last} and {totalRecords}
   * @group Props
   */
  currentPageReportTemplate = '{currentPage} of {totalPages}';
  /**
   * Whether to display current page report.
   * @group Props
   */
  showCurrentPageReport;
  /**
   * Whether to display a dropdown to navigate to any page.
   * @group Props
   */
  showJumpToPageDropdown;
  /**
   * Whether to display a input to navigate to any page.
   * @group Props
   */
  showJumpToPageInput;
  /**
   * When enabled, icons are displayed on paginator to go first and last page.
   * @group Props
   */
  showFirstLastIcon = true;
  /**
   * Whether to show page links.
   * @group Props
   */
  showPageLinks = true;
  /**
   * Sort order to use when an unsorted column gets sorted by user interaction.
   * @group Props
   */
  defaultSortOrder = 1;
  /**
   * Defines whether sorting works on single column or on multiple columns.
   * @group Props
   */
  sortMode = 'single';
  /**
   * When true, resets paginator to first page after sorting. Available only when sortMode is set to single.
   * @group Props
   */
  resetPageOnSort = true;
  /**
   * Specifies the selection mode, valid values are "single" and "multiple".
   * @group Props
   */
  selectionMode;
  /**
   * When enabled with paginator and checkbox selection mode, the select all checkbox in the header will select all rows on the current page.
   * @group Props
   */
  selectionPageOnly;
  /**
   * Selected row with a context menu.
   * @group Props
   */
  contextMenuSelection;
  /**
   * Callback to invoke on context menu selection change.
   * @param {*} object - row data.
   * @group Emits
   */
  contextMenuSelectionChange = new EventEmitter();
  /**
   *  Defines the behavior of context menu selection, in "separate" mode context menu updates contextMenuSelection property whereas in joint mode selection property is used instead so that when row selection is enabled, both row selection and context menu selection use the same property.
   * @group Props
   */
  contextMenuSelectionMode = 'separate';
  /**
   * A property to uniquely identify a record in data.
   * @group Props
   */
  dataKey;
  /**
   * Defines whether metaKey should be considered for the selection. On touch enabled devices, metaKeySelection is turned off automatically.
   * @group Props
   */
  metaKeySelection = false;
  /**
   * Defines if the row is selectable.
   * @group Props
   */
  rowSelectable;
  /**
   * Function to optimize the dom operations by delegating to ngForTrackBy, default algorithm checks for object identity.
   * @group Props
   */
  rowTrackBy = (index, item) => item;
  /**
   * Defines if data is loaded and interacted with in lazy manner.
   * @group Props
   */
  lazy = false;
  /**
   * Whether to call lazy loading on initialization.
   * @group Props
   */
  lazyLoadOnInit = true;
  /**
   * Algorithm to define if a row is selected, valid values are "equals" that compares by reference and "deepEquals" that compares all fields.
   * @group Props
   */
  compareSelectionBy = 'deepEquals';
  /**
   * Character to use as the csv separator.
   * @group Props
   */
  csvSeparator = ',';
  /**
   * Name of the exported file.
   * @group Props
   */
  exportFilename = 'download';
  /**
   * An array of FilterMetadata objects to provide external filters.
   * @group Props
   */
  filters = {};
  /**
   * An array of fields as string to use in global filtering.
   * @group Props
   */
  globalFilterFields;
  /**
   * Delay in milliseconds before filtering the data.
   * @group Props
   */
  filterDelay = 300;
  /**
   * Locale to use in filtering. The default locale is the host environment's current locale.
   * @group Props
   */
  filterLocale;
  /**
   * Map instance to keep the expanded rows where key of the map is the data key of the row.
   * @group Props
   */
  expandedRowKeys = {};
  /**
   * Map instance to keep the rows being edited where key of the map is the data key of the row.
   * @group Props
   */
  editingRowKeys = {};
  /**
   * Whether multiple rows can be expanded at any time. Valid values are "multiple" and "single".
   * @group Props
   */
  rowExpandMode = 'multiple';
  /**
   * Enables scrollable tables.
   * @group Props
   */
  scrollable;
  /**
   * Orientation of the scrolling, options are "vertical", "horizontal" and "both".
   * @group Props
   * @deprecated Property is obselete since v14.2.0.
   */
  scrollDirection = 'vertical';
  /**
   * Type of the row grouping, valid values are "subheader" and "rowspan".
   * @group Props
   */
  rowGroupMode;
  /**
   * Height of the scroll viewport in fixed pixels or the "flex" keyword for a dynamic size.
   * @group Props
   */
  scrollHeight;
  /**
   * Whether the data should be loaded on demand during scroll.
   * @group Props
   */
  virtualScroll;
  /**
   * Height of a row to use in calculations of virtual scrolling.
   * @group Props
   */
  virtualScrollItemSize;
  /**
   * Whether to use the scroller feature. The properties of scroller component can be used like an object in it.
   * @group Props
   */
  virtualScrollOptions;
  /**
   * Threshold in milliseconds to delay lazy loading during scrolling.
   * @group Props
   */
  virtualScrollDelay = 250;
  /**
   * Width of the frozen columns container.
   * @group Props
   */
  frozenWidth;
  /**
   * Defines if the table is responsive.
   * @group Props
   * @deprecated table is always responsive with scrollable behavior.
   */
  get responsive() {
    return this._responsive;
  }
  set responsive(val) {
    this._responsive = val;
    console.log('responsive property is deprecated as table is always responsive with scrollable behavior.');
  }
  _responsive;
  /**
   * Local ng-template varilable of a ContextMenu.
   * @group Props
   */
  contextMenu;
  /**
   * When enabled, columns can be resized using drag and drop.
   * @group Props
   */
  resizableColumns;
  /**
   * Defines whether the overall table width should change on column resize, valid values are "fit" and "expand".
   * @group Props
   */
  columnResizeMode = 'fit';
  /**
   * When enabled, columns can be reordered using drag and drop.
   * @group Props
   */
  reorderableColumns;
  /**
   * Displays a loader to indicate data load is in progress.
   * @group Props
   */
  loading;
  /**
   * The icon to show while indicating data load is in progress.
   * @group Props
   */
  loadingIcon;
  /**
   * Whether to show the loading mask when loading property is true.
   * @group Props
   */
  showLoader = true;
  /**
   * Adds hover effect to rows without the need for selectionMode. Note that tr elements that can be hovered need to have "p-selectable-row" class for rowHover to work.
   * @group Props
   */
  rowHover;
  /**
   * Whether to use the default sorting or a custom one using sortFunction.
   * @group Props
   */
  customSort;
  /**
   * Whether to use the initial sort badge or not.
   * @group Props
   */
  showInitialSortBadge = true;
  /**
   * Whether the cell widths scale according to their content or not.  Deprecated:  Table layout is always "auto".
   * @group Props
   */
  autoLayout;
  /**
   * Export function.
   * @group Props
   */
  exportFunction;
  /**
   * Custom export header of the column to be exported as CSV.
   * @group Props
   */
  exportHeader;
  /**
   * Unique identifier of a stateful table to use in state storage.
   * @group Props
   */
  stateKey;
  /**
   * Defines where a stateful table keeps its state, valid values are "session" for sessionStorage and "local" for localStorage.
   * @group Props
   */
  stateStorage = 'session';
  /**
   * Defines the editing mode, valid values are "cell" and "row".
   * @group Props
   */
  editMode = 'cell';
  /**
   * Field name to use in row grouping.
   * @group Props
   */
  groupRowsBy;
  /**
   * Defines the size of the table.
   * @group Props
   */
  size;
  /**
   * Whether to show grid lines between cells.
   * @group Props
   */
  showGridlines;
  /**
   * Whether to display rows with alternating colors.
   * @group Props
   */
  stripedRows;
  /**
   * Order to sort when default row grouping is enabled.
   * @group Props
   */
  groupRowsByOrder = 1;
  /**
   * Defines the responsive mode, valid options are "stack" and "scroll".
   * @group Props
   */
  responsiveLayout = 'scroll';
  /**
   * The breakpoint to define the maximum width boundary when using stack responsive layout.
   * @group Props
   */
  breakpoint = '960px';
  /**
   * Locale to be used in paginator formatting.
   * @group Props
   */
  paginatorLocale;
  /**
   * An array of objects to display.
   * @group Props
   */
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
  }
  /**
   * An array of objects to represent dynamic columns.
   * @group Props
   */
  get columns() {
    return this._columns;
  }
  set columns(cols) {
    this._columns = cols;
  }
  /**
   * Index of the first row to be displayed.
   * @group Props
   */
  get first() {
    return this._first;
  }
  set first(val) {
    this._first = val;
  }
  /**
   * Number of rows to display per page.
   * @group Props
   */
  get rows() {
    return this._rows;
  }
  set rows(val) {
    this._rows = val;
  }
  /**
   * Number of total records, defaults to length of value when not defined.
   * @group Props
   */
  get totalRecords() {
    return this._totalRecords;
  }
  set totalRecords(val) {
    this._totalRecords = val;
    this.tableService.onTotalRecordsChange(this._totalRecords);
  }
  /**
   * Name of the field to sort data by default.
   * @group Props
   */
  get sortField() {
    return this._sortField;
  }
  set sortField(val) {
    this._sortField = val;
  }
  /**
   * Order to sort when default sorting is enabled.
   * @group Props
   */
  get sortOrder() {
    return this._sortOrder;
  }
  set sortOrder(val) {
    this._sortOrder = val;
  }
  /**
   * An array of SortMeta objects to sort the data by default in multiple sort mode.
   * @group Props
   */
  get multiSortMeta() {
    return this._multiSortMeta;
  }
  set multiSortMeta(val) {
    this._multiSortMeta = val;
  }
  /**
   * Selected row in single mode or an array of values in multiple mode.
   * @group Props
   */
  get selection() {
    return this._selection;
  }
  set selection(val) {
    this._selection = val;
  }
  /**
   * Indicates the height of rows to be scrolled.
   * @group Props
   * @deprecated use virtualScrollItemSize property instead.
   */
  get virtualRowHeight() {
    return this._virtualRowHeight;
  }
  set virtualRowHeight(val) {
    this._virtualRowHeight = val;
    console.log('The virtualRowHeight property is deprecated.');
  }
  /**
   * Whether all data is selected.
   * @group Props
   */
  get selectAll() {
    return this._selection;
  }
  set selectAll(val) {
    this._selection = val;
  }
  /**
   * Emits when the all of the items selected or unselected.
   * @param {TableSelectAllChangeEvent} event - custom  all selection change event.
   * @group Emits
   */
  selectAllChange = new EventEmitter();
  /**
   * Callback to invoke on selection changed.
   * @param {any | null} value - selected data.
   * @group Emits
   */
  selectionChange = new EventEmitter();
  /**
   * Callback to invoke when a row is selected.
   * @param {TableRowSelectEvent} event - custom select event.
   * @group Emits
   */
  onRowSelect = new EventEmitter();
  /**
   * Callback to invoke when a row is unselected.
   * @param {TableRowUnSelectEvent} event - custom unselect event.
   * @group Emits
   */
  onRowUnselect = new EventEmitter();
  /**
   * Callback to invoke when pagination occurs.
   * @param {TablePageEvent} event - custom pagination event.
   * @group Emits
   */
  onPage = new EventEmitter();
  /**
   * Callback to invoke when a column gets sorted.
   * @param {Object} object - sort meta.
   * @group Emits
   */
  onSort = new EventEmitter();
  /**
   * Callback to invoke when data is filtered.
   * @param {TableFilterEvent} event - custom filtering event.
   * @group Emits
   */
  onFilter = new EventEmitter();
  /**
   * Callback to invoke when paging, sorting or filtering happens in lazy mode.
   * @param {TableLazyLoadEvent} event - custom lazy loading event.
   * @group Emits
   */
  onLazyLoad = new EventEmitter();
  /**
   * Callback to invoke when a row is expanded.
   * @param {TableRowExpandEvent} event - custom row expand event.
   * @group Emits
   */
  onRowExpand = new EventEmitter();
  /**
   * Callback to invoke when a row is collapsed.
   * @param {TableRowCollapseEvent} event - custom row collapse event.
   * @group Emits
   */
  onRowCollapse = new EventEmitter();
  /**
   * Callback to invoke when a row is selected with right click.
   * @param {TableContextMenuSelectEvent} event - custom context menu select event.
   * @group Emits
   */
  onContextMenuSelect = new EventEmitter();
  /**
   * Callback to invoke when a column is resized.
   * @param {TableColResizeEvent} event - custom column resize event.
   * @group Emits
   */
  onColResize = new EventEmitter();
  /**
   * Callback to invoke when a column is reordered.
   * @param {TableColumnReorderEvent} event - custom column reorder event.
   * @group Emits
   */
  onColReorder = new EventEmitter();
  /**
   * Callback to invoke when a row is reordered.
   * @param {TableRowReorderEvent} event - custom row reorder event.
   * @group Emits
   */
  onRowReorder = new EventEmitter();
  /**
   * Callback to invoke when a cell switches to edit mode.
   * @param {TableEditInitEvent} event - custom edit init event.
   * @group Emits
   */
  onEditInit = new EventEmitter();
  /**
   * Callback to invoke when cell edit is completed.
   * @param {TableEditCompleteEvent} event - custom edit complete event.
   * @group Emits
   */
  onEditComplete = new EventEmitter();
  /**
   * Callback to invoke when cell edit is cancelled with escape key.
   * @param {TableEditCancelEvent} event - custom edit cancel event.
   * @group Emits
   */
  onEditCancel = new EventEmitter();
  /**
   * Callback to invoke when state of header checkbox changes.
   * @param {TableHeaderCheckboxToggleEvent} event - custom header checkbox event.
   * @group Emits
   */
  onHeaderCheckboxToggle = new EventEmitter();
  /**
   * A function to implement custom sorting, refer to sorting section for details.
   * @param {any} any - sort meta.
   * @group Emits
   */
  sortFunction = new EventEmitter();
  /**
   * Callback to invoke on pagination.
   * @param {number} number - first element.
   * @group Emits
   */
  firstChange = new EventEmitter();
  /**
   * Callback to invoke on rows change.
   * @param {number} number - Row count.
   * @group Emits
   */
  rowsChange = new EventEmitter();
  /**
   * Callback to invoke table state is saved.
   * @param {TableState} object - table state.
   * @group Emits
   */
  onStateSave = new EventEmitter();
  /**
   * Callback to invoke table state is restored.
   * @param {TableState} object - table state.
   * @group Emits
   */
  onStateRestore = new EventEmitter();
  containerViewChild;
  resizeHelperViewChild;
  reorderIndicatorUpViewChild;
  reorderIndicatorDownViewChild;
  wrapperViewChild;
  tableViewChild;
  tableHeaderViewChild;
  tableFooterViewChild;
  scroller;
  _templates;
  _virtualRowHeight = 28;
  _value = [];
  _columns;
  _totalRecords = 0;
  _first = 0;
  _rows;
  filteredValue;
  // @todo will be refactored later
  _headerTemplate;
  headerTemplate;
  _headerGroupedTemplate;
  headerGroupedTemplate;
  _bodyTemplate;
  bodyTemplate;
  _loadingBodyTemplate;
  loadingBodyTemplate;
  _captionTemplate;
  captionTemplate;
  _footerTemplate;
  footerTemplate;
  _footerGroupedTemplate;
  footerGroupedTemplate;
  _summaryTemplate;
  summaryTemplate;
  _colGroupTemplate;
  colGroupTemplate;
  _expandedRowTemplate;
  expandedRowTemplate;
  _groupHeaderTemplate;
  groupHeaderTemplate;
  _groupFooterTemplate;
  groupFooterTemplate;
  _frozenExpandedRowTemplate;
  frozenExpandedRowTemplate;
  _frozenHeaderTemplate;
  frozenHeaderTemplate;
  _frozenBodyTemplate;
  frozenBodyTemplate;
  _frozenFooterTemplate;
  frozenFooterTemplate;
  _frozenColGroupTemplate;
  frozenColGroupTemplate;
  _emptyMessageTemplate;
  emptyMessageTemplate;
  _paginatorLeftTemplate;
  paginatorLeftTemplate;
  _paginatorRightTemplate;
  paginatorRightTemplate;
  _paginatorDropdownItemTemplate;
  paginatorDropdownItemTemplate;
  _loadingIconTemplate;
  loadingIconTemplate;
  _reorderIndicatorUpIconTemplate;
  reorderIndicatorUpIconTemplate;
  _reorderIndicatorDownIconTemplate;
  reorderIndicatorDownIconTemplate;
  _sortIconTemplate;
  sortIconTemplate;
  _checkboxIconTemplate;
  checkboxIconTemplate;
  _headerCheckboxIconTemplate;
  headerCheckboxIconTemplate;
  _paginatorDropdownIconTemplate;
  paginatorDropdownIconTemplate;
  _paginatorFirstPageLinkIconTemplate;
  paginatorFirstPageLinkIconTemplate;
  _paginatorLastPageLinkIconTemplate;
  paginatorLastPageLinkIconTemplate;
  _paginatorPreviousPageLinkIconTemplate;
  paginatorPreviousPageLinkIconTemplate;
  _paginatorNextPageLinkIconTemplate;
  paginatorNextPageLinkIconTemplate;
  selectionKeys = {};
  lastResizerHelperX;
  reorderIconWidth;
  reorderIconHeight;
  draggedColumn;
  draggedRowIndex;
  droppedRowIndex;
  rowDragging;
  dropPosition;
  editingCell;
  editingCellData;
  editingCellField;
  editingCellRowIndex;
  selfClick;
  documentEditListener;
  _multiSortMeta;
  _sortField;
  _sortOrder = 1;
  preventSelectionSetterPropagation;
  _selection;
  _selectAll = null;
  anchorRowIndex;
  rangeRowIndex;
  filterTimeout;
  initialized;
  rowTouched;
  restoringSort;
  restoringFilter;
  stateRestored;
  columnOrderStateRestored;
  columnWidthsState;
  tableWidthState;
  overlaySubscription;
  resizeColumnElement;
  columnResizing = false;
  rowGroupHeaderStyleObject = {};
  id = UniqueComponentId();
  styleElement;
  responsiveStyleElement;
  overlayService = inject(OverlayService);
  filterService = inject(FilterService);
  tableService = inject(TableService);
  zone = inject(NgZone);
  _componentStyle = inject(TableStyle);
  ngOnInit() {
    super.ngOnInit();
    if (this.lazy && this.lazyLoadOnInit) {
      if (!this.virtualScroll) {
        this.onLazyLoad.emit(this.createLazyLoadMetadata());
      }
      if (this.restoringFilter) {
        this.restoringFilter = false;
      }
    }
    if (this.responsiveLayout === 'stack') {
      this.createResponsiveStyle();
    }
    this.initialized = true;
  }
  ngAfterContentInit() {
    this._templates.forEach(item => {
      switch (item.getType()) {
        case 'caption':
          this.captionTemplate = item.template;
          break;
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'headergrouped':
          this.headerGroupedTemplate = item.template;
          break;
        case 'body':
          this.bodyTemplate = item.template;
          break;
        case 'loadingbody':
          this.loadingBodyTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
        case 'footergrouped':
          this.footerGroupedTemplate = item.template;
          break;
        case 'summary':
          this.summaryTemplate = item.template;
          break;
        case 'colgroup':
          this.colGroupTemplate = item.template;
          break;
        case 'expandedrow':
          this.expandedRowTemplate = item.template;
          break;
        case 'groupheader':
          this.groupHeaderTemplate = item.template;
          break;
        case 'groupfooter':
          this.groupFooterTemplate = item.template;
          break;
        case 'frozenheader':
          this.frozenHeaderTemplate = item.template;
          break;
        case 'frozenbody':
          this.frozenBodyTemplate = item.template;
          break;
        case 'frozenfooter':
          this.frozenFooterTemplate = item.template;
          break;
        case 'frozencolgroup':
          this.frozenColGroupTemplate = item.template;
          break;
        case 'frozenexpandedrow':
          this.frozenExpandedRowTemplate = item.template;
          break;
        case 'emptymessage':
          this.emptyMessageTemplate = item.template;
          break;
        case 'paginatorleft':
          this.paginatorLeftTemplate = item.template;
          break;
        case 'paginatorright':
          this.paginatorRightTemplate = item.template;
          break;
        case 'paginatordropdownicon':
          this.paginatorDropdownIconTemplate = item.template;
          break;
        case 'paginatordropdownitem':
          this.paginatorDropdownItemTemplate = item.template;
          break;
        case 'paginatorfirstpagelinkicon':
          this.paginatorFirstPageLinkIconTemplate = item.template;
          break;
        case 'paginatorlastpagelinkicon':
          this.paginatorLastPageLinkIconTemplate = item.template;
          break;
        case 'paginatorpreviouspagelinkicon':
          this.paginatorPreviousPageLinkIconTemplate = item.template;
          break;
        case 'paginatornextpagelinkicon':
          this.paginatorNextPageLinkIconTemplate = item.template;
          break;
        case 'loadingicon':
          this.loadingIconTemplate = item.template;
          break;
        case 'reorderindicatorupicon':
          this.reorderIndicatorUpIconTemplate = item.template;
          break;
        case 'reorderindicatordownicon':
          this.reorderIndicatorDownIconTemplate = item.template;
          break;
        case 'sorticon':
          this.sortIconTemplate = item.template;
          break;
        case 'checkboxicon':
          this.checkboxIconTemplate = item.template;
          break;
        case 'headercheckboxicon':
          this.headerCheckboxIconTemplate = item.template;
          break;
      }
    });
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (isPlatformBrowser(this.platformId)) {
      if (this.isStateful() && this.resizableColumns) {
        this.restoreColumnWidths();
      }
    }
  }
  ngOnChanges(simpleChange) {
    super.ngOnChanges(simpleChange);
    if (simpleChange.value) {
      if (this.isStateful() && !this.stateRestored && isPlatformBrowser(this.platformId)) {
        this.restoreState();
      }
      this._value = simpleChange.value.currentValue;
      if (!this.lazy) {
        this.totalRecords = this._value ? this._value.length : 0;
        if (this.sortMode == 'single' && (this.sortField || this.groupRowsBy)) this.sortSingle();else if (this.sortMode == 'multiple' && (this.multiSortMeta || this.groupRowsBy)) this.sortMultiple();else if (this.hasFilter())
          //sort already filters
          this._filter();
      }
      this.tableService.onValueChange(simpleChange.value.currentValue);
    }
    if (simpleChange.columns) {
      if (!this.isStateful()) {
        this._columns = simpleChange.columns.currentValue;
        this.tableService.onColumnsChange(simpleChange.columns.currentValue);
      }
      if (this._columns && this.isStateful() && this.reorderableColumns && !this.columnOrderStateRestored) {
        this.restoreColumnOrder();
        this.tableService.onColumnsChange(this._columns);
      }
    }
    if (simpleChange.sortField) {
      this._sortField = simpleChange.sortField.currentValue;
      //avoid triggering lazy load prior to lazy initialization at onInit
      if (!this.lazy || this.initialized) {
        if (this.sortMode === 'single') {
          this.sortSingle();
        }
      }
    }
    if (simpleChange.groupRowsBy) {
      //avoid triggering lazy load prior to lazy initialization at onInit
      if (!this.lazy || this.initialized) {
        if (this.sortMode === 'single') {
          this.sortSingle();
        }
      }
    }
    if (simpleChange.sortOrder) {
      this._sortOrder = simpleChange.sortOrder.currentValue;
      //avoid triggering lazy load prior to lazy initialization at onInit
      if (!this.lazy || this.initialized) {
        if (this.sortMode === 'single') {
          this.sortSingle();
        }
      }
    }
    if (simpleChange.groupRowsByOrder) {
      //avoid triggering lazy load prior to lazy initialization at onInit
      if (!this.lazy || this.initialized) {
        if (this.sortMode === 'single') {
          this.sortSingle();
        }
      }
    }
    if (simpleChange.multiSortMeta) {
      this._multiSortMeta = simpleChange.multiSortMeta.currentValue;
      if (this.sortMode === 'multiple' && (this.initialized || !this.lazy && !this.virtualScroll)) {
        this.sortMultiple();
      }
    }
    if (simpleChange.selection) {
      this._selection = simpleChange.selection.currentValue;
      if (!this.preventSelectionSetterPropagation) {
        this.updateSelectionKeys();
        this.tableService.onSelectionChange();
      }
      this.preventSelectionSetterPropagation = false;
    }
    if (simpleChange.selectAll) {
      this._selectAll = simpleChange.selectAll.currentValue;
      if (!this.preventSelectionSetterPropagation) {
        this.updateSelectionKeys();
        this.tableService.onSelectionChange();
        if (this.isStateful()) {
          this.saveState();
        }
      }
      this.preventSelectionSetterPropagation = false;
    }
  }
  get processedData() {
    return this.filteredValue || this.value || [];
  }
  _initialColWidths;
  dataToRender(data) {
    const _data = data || this.processedData;
    if (_data && this.paginator) {
      const first = this.lazy ? 0 : this.first;
      return _data.slice(first, first + this.rows);
    }
    return _data;
  }
  updateSelectionKeys() {
    if (this.dataKey && this._selection) {
      this.selectionKeys = {};
      if (Array.isArray(this._selection)) {
        for (let data of this._selection) {
          this.selectionKeys[String(ObjectUtils.resolveFieldData(data, this.dataKey))] = 1;
        }
      } else {
        this.selectionKeys[String(ObjectUtils.resolveFieldData(this._selection, this.dataKey))] = 1;
      }
    }
  }
  onPageChange(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.onPage.emit({
      first: this.first,
      rows: this.rows
    });
    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    }
    this.firstChange.emit(this.first);
    this.rowsChange.emit(this.rows);
    this.tableService.onValueChange(this.value);
    if (this.isStateful()) {
      this.saveState();
    }
    this.anchorRowIndex = null;
    if (this.scrollable) {
      this.resetScrollTop();
    }
  }
  sort(event) {
    let originalEvent = event.originalEvent;
    if (this.sortMode === 'single') {
      this._sortOrder = this.sortField === event.field ? this.sortOrder * -1 : this.defaultSortOrder;
      this._sortField = event.field;
      if (this.resetPageOnSort) {
        this._first = 0;
        this.firstChange.emit(this._first);
        if (this.scrollable) {
          this.resetScrollTop();
        }
      }
      this.sortSingle();
    }
    if (this.sortMode === 'multiple') {
      let metaKey = originalEvent.metaKey || originalEvent.ctrlKey;
      let sortMeta = this.getSortMeta(event.field);
      if (sortMeta) {
        if (!metaKey) {
          this._multiSortMeta = [{
            field: event.field,
            order: sortMeta.order * -1
          }];
          if (this.resetPageOnSort) {
            this._first = 0;
            this.firstChange.emit(this._first);
            if (this.scrollable) {
              this.resetScrollTop();
            }
          }
        } else {
          sortMeta.order = sortMeta.order * -1;
        }
      } else {
        if (!metaKey || !this.multiSortMeta) {
          this._multiSortMeta = [];
          if (this.resetPageOnSort) {
            this._first = 0;
            this.firstChange.emit(this._first);
          }
        }
        this._multiSortMeta.push({
          field: event.field,
          order: this.defaultSortOrder
        });
      }
      this.sortMultiple();
    }
    if (this.isStateful()) {
      this.saveState();
    }
    this.anchorRowIndex = null;
  }
  sortSingle() {
    let field = this.sortField || this.groupRowsBy;
    let order = this.sortField ? this.sortOrder : this.groupRowsByOrder;
    if (this.groupRowsBy && this.sortField && this.groupRowsBy !== this.sortField) {
      this._multiSortMeta = [this.getGroupRowsMeta(), {
        field: this.sortField,
        order: this.sortOrder
      }];
      this.sortMultiple();
      return;
    }
    if (field && order) {
      if (this.restoringSort) {
        this.restoringSort = false;
      }
      if (this.lazy) {
        this.onLazyLoad.emit(this.createLazyLoadMetadata());
      } else if (this.value) {
        if (this.customSort) {
          this.sortFunction.emit({
            data: this.value,
            mode: this.sortMode,
            field: field,
            order: order
          });
        } else {
          this.value.sort((data1, data2) => {
            let value1 = ObjectUtils.resolveFieldData(data1, field);
            let value2 = ObjectUtils.resolveFieldData(data2, field);
            let result = null;
            if (value1 == null && value2 != null) result = -1;else if (value1 != null && value2 == null) result = 1;else if (value1 == null && value2 == null) result = 0;else if (typeof value1 === 'string' && typeof value2 === 'string') result = value1.localeCompare(value2);else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
            return order * result;
          });
          this._value = [...this.value];
        }
        if (this.hasFilter()) {
          this._filter();
        }
      }
      let sortMeta = {
        field: field,
        order: order
      };
      this.onSort.emit(sortMeta);
      this.tableService.onSort(sortMeta);
    }
  }
  sortMultiple() {
    if (this.groupRowsBy) {
      if (!this._multiSortMeta) this._multiSortMeta = [this.getGroupRowsMeta()];else if (this.multiSortMeta[0].field !== this.groupRowsBy) this._multiSortMeta = [this.getGroupRowsMeta(), ...this._multiSortMeta];
    }
    if (this.multiSortMeta) {
      if (this.lazy) {
        this.onLazyLoad.emit(this.createLazyLoadMetadata());
      } else if (this.value) {
        if (this.customSort) {
          this.sortFunction.emit({
            data: this.value,
            mode: this.sortMode,
            multiSortMeta: this.multiSortMeta
          });
        } else {
          this.value.sort((data1, data2) => {
            return this.multisortField(data1, data2, this.multiSortMeta, 0);
          });
          this._value = [...this.value];
        }
        if (this.hasFilter()) {
          this._filter();
        }
      }
      this.onSort.emit({
        multisortmeta: this.multiSortMeta
      });
      this.tableService.onSort(this.multiSortMeta);
    }
  }
  multisortField(data1, data2, multiSortMeta, index) {
    const value1 = ObjectUtils.resolveFieldData(data1, multiSortMeta[index].field);
    const value2 = ObjectUtils.resolveFieldData(data2, multiSortMeta[index].field);
    if (ObjectUtils.compare(value1, value2, this.filterLocale) === 0) {
      return multiSortMeta.length - 1 > index ? this.multisortField(data1, data2, multiSortMeta, index + 1) : 0;
    }
    return this.compareValuesOnSort(value1, value2, multiSortMeta[index].order);
  }
  compareValuesOnSort(value1, value2, order) {
    return ObjectUtils.sort(value1, value2, order, this.filterLocale, this.sortOrder);
  }
  getSortMeta(field) {
    if (this.multiSortMeta && this.multiSortMeta.length) {
      for (let i = 0; i < this.multiSortMeta.length; i++) {
        if (this.multiSortMeta[i].field === field) {
          return this.multiSortMeta[i];
        }
      }
    }
    return null;
  }
  isSorted(field) {
    if (this.sortMode === 'single') {
      return this.sortField && this.sortField === field;
    } else if (this.sortMode === 'multiple') {
      let sorted = false;
      if (this.multiSortMeta) {
        for (let i = 0; i < this.multiSortMeta.length; i++) {
          if (this.multiSortMeta[i].field == field) {
            sorted = true;
            break;
          }
        }
      }
      return sorted;
    }
  }
  handleRowClick(event) {
    let target = event.originalEvent.target;
    let targetNode = target.nodeName;
    let parentNode = target.parentElement && target.parentElement.nodeName;
    if (targetNode == 'INPUT' || targetNode == 'BUTTON' || targetNode == 'A' || parentNode == 'INPUT' || parentNode == 'BUTTON' || parentNode == 'A' || DomHandler.hasClass(event.originalEvent.target, 'p-clickable')) {
      return;
    }
    if (this.selectionMode) {
      let rowData = event.rowData;
      let rowIndex = event.rowIndex;
      this.preventSelectionSetterPropagation = true;
      if (this.isMultipleSelectionMode() && event.originalEvent.shiftKey && this.anchorRowIndex != null) {
        DomHandler.clearSelection();
        if (this.rangeRowIndex != null) {
          this.clearSelectionRange(event.originalEvent);
        }
        this.rangeRowIndex = rowIndex;
        this.selectRange(event.originalEvent, rowIndex);
      } else {
        let selected = this.isSelected(rowData);
        if (!selected && !this.isRowSelectable(rowData, rowIndex)) {
          return;
        }
        let metaSelection = this.rowTouched ? false : this.metaKeySelection;
        let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rowData, this.dataKey)) : null;
        this.anchorRowIndex = rowIndex;
        this.rangeRowIndex = rowIndex;
        if (metaSelection) {
          let metaKey = event.originalEvent.metaKey || event.originalEvent.ctrlKey;
          if (selected && metaKey) {
            if (this.isSingleSelectionMode()) {
              this._selection = null;
              this.selectionKeys = {};
              this.selectionChange.emit(null);
            } else {
              let selectionIndex = this.findIndexInSelection(rowData);
              this._selection = this.selection.filter((val, i) => i != selectionIndex);
              this.selectionChange.emit(this.selection);
              if (dataKeyValue) {
                delete this.selectionKeys[dataKeyValue];
              }
            }
            this.onRowUnselect.emit({
              originalEvent: event.originalEvent,
              data: rowData,
              type: 'row'
            });
          } else {
            if (this.isSingleSelectionMode()) {
              this._selection = rowData;
              this.selectionChange.emit(rowData);
              if (dataKeyValue) {
                this.selectionKeys = {};
                this.selectionKeys[dataKeyValue] = 1;
              }
            } else if (this.isMultipleSelectionMode()) {
              if (metaKey) {
                this._selection = this.selection || [];
              } else {
                this._selection = [];
                this.selectionKeys = {};
              }
              this._selection = [...this.selection, rowData];
              this.selectionChange.emit(this.selection);
              if (dataKeyValue) {
                this.selectionKeys[dataKeyValue] = 1;
              }
            }
            this.onRowSelect.emit({
              originalEvent: event.originalEvent,
              data: rowData,
              type: 'row',
              index: rowIndex
            });
          }
        } else {
          if (this.selectionMode === 'single') {
            if (selected) {
              this._selection = null;
              this.selectionKeys = {};
              this.selectionChange.emit(this.selection);
              this.onRowUnselect.emit({
                originalEvent: event.originalEvent,
                data: rowData,
                type: 'row',
                index: rowIndex
              });
            } else {
              this._selection = rowData;
              this.selectionChange.emit(this.selection);
              this.onRowSelect.emit({
                originalEvent: event.originalEvent,
                data: rowData,
                type: 'row',
                index: rowIndex
              });
              if (dataKeyValue) {
                this.selectionKeys = {};
                this.selectionKeys[dataKeyValue] = 1;
              }
            }
          } else if (this.selectionMode === 'multiple') {
            if (selected) {
              let selectionIndex = this.findIndexInSelection(rowData);
              this._selection = this.selection.filter((val, i) => i != selectionIndex);
              this.selectionChange.emit(this.selection);
              this.onRowUnselect.emit({
                originalEvent: event.originalEvent,
                data: rowData,
                type: 'row',
                index: rowIndex
              });
              if (dataKeyValue) {
                delete this.selectionKeys[dataKeyValue];
              }
            } else {
              this._selection = this.selection ? [...this.selection, rowData] : [rowData];
              this.selectionChange.emit(this.selection);
              this.onRowSelect.emit({
                originalEvent: event.originalEvent,
                data: rowData,
                type: 'row',
                index: rowIndex
              });
              if (dataKeyValue) {
                this.selectionKeys[dataKeyValue] = 1;
              }
            }
          }
        }
      }
      this.tableService.onSelectionChange();
      if (this.isStateful()) {
        this.saveState();
      }
    }
    this.rowTouched = false;
  }
  handleRowTouchEnd(event) {
    this.rowTouched = true;
  }
  handleRowRightClick(event) {
    if (this.contextMenu) {
      const rowData = event.rowData;
      const rowIndex = event.rowIndex;
      if (this.contextMenuSelectionMode === 'separate') {
        this.contextMenuSelection = rowData;
        this.contextMenuSelectionChange.emit(rowData);
        this.onContextMenuSelect.emit({
          originalEvent: event.originalEvent,
          data: rowData,
          index: event.rowIndex
        });
        this.contextMenu.show(event.originalEvent);
        this.tableService.onContextMenu(rowData);
      } else if (this.contextMenuSelectionMode === 'joint') {
        this.preventSelectionSetterPropagation = true;
        let selected = this.isSelected(rowData);
        let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rowData, this.dataKey)) : null;
        if (!selected) {
          if (!this.isRowSelectable(rowData, rowIndex)) {
            return;
          }
          if (this.isSingleSelectionMode()) {
            this.selection = rowData;
            this.selectionChange.emit(rowData);
            if (dataKeyValue) {
              this.selectionKeys = {};
              this.selectionKeys[dataKeyValue] = 1;
            }
          } else if (this.isMultipleSelectionMode()) {
            this._selection = this.selection ? [...this.selection, rowData] : [rowData];
            this.selectionChange.emit(this.selection);
            if (dataKeyValue) {
              this.selectionKeys[dataKeyValue] = 1;
            }
          }
        }
        this.tableService.onSelectionChange();
        this.contextMenu.show(event.originalEvent);
        this.onContextMenuSelect.emit({
          originalEvent: event,
          data: rowData,
          index: event.rowIndex
        });
      }
    }
  }
  selectRange(event, rowIndex) {
    let rangeStart, rangeEnd;
    if (this.anchorRowIndex > rowIndex) {
      rangeStart = rowIndex;
      rangeEnd = this.anchorRowIndex;
    } else if (this.anchorRowIndex < rowIndex) {
      rangeStart = this.anchorRowIndex;
      rangeEnd = rowIndex;
    } else {
      rangeStart = rowIndex;
      rangeEnd = rowIndex;
    }
    if (this.lazy && this.paginator) {
      rangeStart -= this.first;
      rangeEnd -= this.first;
    }
    let rangeRowsData = [];
    for (let i = rangeStart; i <= rangeEnd; i++) {
      let rangeRowData = this.filteredValue ? this.filteredValue[i] : this.value[i];
      if (!this.isSelected(rangeRowData)) {
        if (!this.isRowSelectable(rangeRowData, rowIndex)) {
          continue;
        }
        rangeRowsData.push(rangeRowData);
        this._selection = [...this.selection, rangeRowData];
        let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rangeRowData, this.dataKey)) : null;
        if (dataKeyValue) {
          this.selectionKeys[dataKeyValue] = 1;
        }
      }
    }
    this.selectionChange.emit(this.selection);
    this.onRowSelect.emit({
      originalEvent: event,
      data: rangeRowsData,
      type: 'row'
    });
  }
  clearSelectionRange(event) {
    let rangeStart, rangeEnd;
    let rangeRowIndex = this.rangeRowIndex;
    let anchorRowIndex = this.anchorRowIndex;
    if (rangeRowIndex > anchorRowIndex) {
      rangeStart = this.anchorRowIndex;
      rangeEnd = this.rangeRowIndex;
    } else if (rangeRowIndex < anchorRowIndex) {
      rangeStart = this.rangeRowIndex;
      rangeEnd = this.anchorRowIndex;
    } else {
      rangeStart = this.rangeRowIndex;
      rangeEnd = this.rangeRowIndex;
    }
    for (let i = rangeStart; i <= rangeEnd; i++) {
      let rangeRowData = this.value[i];
      let selectionIndex = this.findIndexInSelection(rangeRowData);
      this._selection = this.selection.filter((val, i) => i != selectionIndex);
      let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rangeRowData, this.dataKey)) : null;
      if (dataKeyValue) {
        delete this.selectionKeys[dataKeyValue];
      }
      this.onRowUnselect.emit({
        originalEvent: event,
        data: rangeRowData,
        type: 'row'
      });
    }
  }
  isSelected(rowData) {
    if (rowData && this.selection) {
      if (this.dataKey) {
        return this.selectionKeys[ObjectUtils.resolveFieldData(rowData, this.dataKey)] !== undefined;
      } else {
        if (Array.isArray(this.selection)) return this.findIndexInSelection(rowData) > -1;else return this.equals(rowData, this.selection);
      }
    }
    return false;
  }
  findIndexInSelection(rowData) {
    let index = -1;
    if (this.selection && this.selection.length) {
      for (let i = 0; i < this.selection.length; i++) {
        if (this.equals(rowData, this.selection[i])) {
          index = i;
          break;
        }
      }
    }
    return index;
  }
  isRowSelectable(data, index) {
    if (this.rowSelectable && !this.rowSelectable({
      data,
      index
    })) {
      return false;
    }
    return true;
  }
  toggleRowWithRadio(event, rowData) {
    this.preventSelectionSetterPropagation = true;
    if (this.selection != rowData) {
      if (!this.isRowSelectable(rowData, event.rowIndex)) {
        return;
      }
      this._selection = rowData;
      this.selectionChange.emit(this.selection);
      this.onRowSelect.emit({
        originalEvent: event.originalEvent,
        index: event.rowIndex,
        data: rowData,
        type: 'radiobutton'
      });
      if (this.dataKey) {
        this.selectionKeys = {};
        this.selectionKeys[String(ObjectUtils.resolveFieldData(rowData, this.dataKey))] = 1;
      }
    } else {
      this._selection = null;
      this.selectionChange.emit(this.selection);
      this.onRowUnselect.emit({
        originalEvent: event.originalEvent,
        index: event.rowIndex,
        data: rowData,
        type: 'radiobutton'
      });
    }
    this.tableService.onSelectionChange();
    if (this.isStateful()) {
      this.saveState();
    }
  }
  toggleRowWithCheckbox(event, rowData) {
    this.selection = this.selection || [];
    let selected = this.isSelected(rowData);
    let dataKeyValue = this.dataKey ? String(ObjectUtils.resolveFieldData(rowData, this.dataKey)) : null;
    this.preventSelectionSetterPropagation = true;
    if (selected) {
      let selectionIndex = this.findIndexInSelection(rowData);
      this._selection = this.selection.filter((val, i) => i != selectionIndex);
      this.selectionChange.emit(this.selection);
      this.onRowUnselect.emit({
        originalEvent: event.originalEvent,
        index: event.rowIndex,
        data: rowData,
        type: 'checkbox'
      });
      if (dataKeyValue) {
        delete this.selectionKeys[dataKeyValue];
      }
    } else {
      if (!this.isRowSelectable(rowData, event.rowIndex)) {
        return;
      }
      this._selection = this.selection ? [...this.selection, rowData] : [rowData];
      this.selectionChange.emit(this.selection);
      this.onRowSelect.emit({
        originalEvent: event.originalEvent,
        index: event.rowIndex,
        data: rowData,
        type: 'checkbox'
      });
      if (dataKeyValue) {
        this.selectionKeys[dataKeyValue] = 1;
      }
    }
    this.tableService.onSelectionChange();
    if (this.isStateful()) {
      this.saveState();
    }
  }
  toggleRowsWithCheckbox(event, check) {
    if (this._selectAll !== null) {
      this.selectAllChange.emit({
        originalEvent: event,
        checked: check
      });
    } else {
      const data = this.selectionPageOnly ? this.dataToRender(this.processedData) : this.processedData;
      let selection = this.selectionPageOnly && this._selection ? this._selection.filter(s => !data.some(d => this.equals(s, d))) : [];
      if (check) {
        selection = this.frozenValue ? [...selection, ...this.frozenValue, ...data] : [...selection, ...data];
        selection = this.rowSelectable ? selection.filter((data, index) => this.rowSelectable({
          data,
          index
        })) : selection;
      }
      this._selection = selection;
      this.preventSelectionSetterPropagation = true;
      this.updateSelectionKeys();
      this.selectionChange.emit(this._selection);
      this.tableService.onSelectionChange();
      this.onHeaderCheckboxToggle.emit({
        originalEvent: event,
        checked: check
      });
      if (this.isStateful()) {
        this.saveState();
      }
    }
  }
  equals(data1, data2) {
    return this.compareSelectionBy === 'equals' ? data1 === data2 : ObjectUtils.equals(data1, data2, this.dataKey);
  }
  /* Legacy Filtering for custom elements */
  filter(value, field, matchMode) {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    if (!this.isFilterBlank(value)) {
      this.filters[field] = {
        value: value,
        matchMode: matchMode
      };
    } else if (this.filters[field]) {
      delete this.filters[field];
    }
    this.filterTimeout = setTimeout(() => {
      this._filter();
      this.filterTimeout = null;
    }, this.filterDelay);
    this.anchorRowIndex = null;
  }
  filterGlobal(value, matchMode) {
    this.filter(value, 'global', matchMode);
  }
  isFilterBlank(filter) {
    if (filter !== null && filter !== undefined) {
      if (typeof filter === 'string' && filter.trim().length == 0 || Array.isArray(filter) && filter.length == 0) return true;else return false;
    }
    return true;
  }
  _filter() {
    if (!this.restoringFilter) {
      this.first = 0;
      this.firstChange.emit(this.first);
    }
    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    } else {
      if (!this.value) {
        return;
      }
      if (!this.hasFilter()) {
        this.filteredValue = null;
        if (this.paginator) {
          this.totalRecords = this.value ? this.value.length : 0;
        }
      } else {
        let globalFilterFieldsArray;
        if (this.filters['global']) {
          if (!this.columns && !this.globalFilterFields) throw new Error('Global filtering requires dynamic columns or globalFilterFields to be defined.');else globalFilterFieldsArray = this.globalFilterFields || this.columns;
        }
        this.filteredValue = [];
        for (let i = 0; i < this.value.length; i++) {
          let localMatch = true;
          let globalMatch = false;
          let localFiltered = false;
          for (let prop in this.filters) {
            if (this.filters.hasOwnProperty(prop) && prop !== 'global') {
              localFiltered = true;
              let filterField = prop;
              let filterMeta = this.filters[filterField];
              if (Array.isArray(filterMeta)) {
                for (let meta of filterMeta) {
                  localMatch = this.executeLocalFilter(filterField, this.value[i], meta);
                  if (meta.operator === FilterOperator.OR && localMatch || meta.operator === FilterOperator.AND && !localMatch) {
                    break;
                  }
                }
              } else {
                localMatch = this.executeLocalFilter(filterField, this.value[i], filterMeta);
              }
              if (!localMatch) {
                break;
              }
            }
          }
          if (this.filters['global'] && !globalMatch && globalFilterFieldsArray) {
            for (let j = 0; j < globalFilterFieldsArray.length; j++) {
              let globalFilterField = globalFilterFieldsArray[j].field || globalFilterFieldsArray[j];
              globalMatch = this.filterService.filters[this.filters['global'].matchMode](ObjectUtils.resolveFieldData(this.value[i], globalFilterField), this.filters['global'].value, this.filterLocale);
              if (globalMatch) {
                break;
              }
            }
          }
          let matches;
          if (this.filters['global']) {
            matches = localFiltered ? localFiltered && localMatch && globalMatch : globalMatch;
          } else {
            matches = localFiltered && localMatch;
          }
          if (matches) {
            this.filteredValue.push(this.value[i]);
          }
        }
        if (this.filteredValue.length === this.value.length) {
          this.filteredValue = null;
        }
        if (this.paginator) {
          this.totalRecords = this.filteredValue ? this.filteredValue.length : this.value ? this.value.length : 0;
        }
      }
    }
    this.onFilter.emit({
      filters: this.filters,
      filteredValue: this.filteredValue || this.value
    });
    this.tableService.onValueChange(this.value);
    if (this.isStateful() && !this.restoringFilter) {
      this.saveState();
    }
    if (this.restoringFilter) {
      this.restoringFilter = false;
    }
    this.cd.markForCheck();
    if (this.scrollable) {
      this.resetScrollTop();
    }
  }
  executeLocalFilter(field, rowData, filterMeta) {
    let filterValue = filterMeta.value;
    let filterMatchMode = filterMeta.matchMode || FilterMatchMode.STARTS_WITH;
    let dataFieldValue = ObjectUtils.resolveFieldData(rowData, field);
    let filterConstraint = this.filterService.filters[filterMatchMode];
    return filterConstraint(dataFieldValue, filterValue, this.filterLocale);
  }
  hasFilter() {
    let empty = true;
    for (let prop in this.filters) {
      if (this.filters.hasOwnProperty(prop)) {
        empty = false;
        break;
      }
    }
    return !empty;
  }
  createLazyLoadMetadata() {
    return {
      first: this.first,
      rows: this.rows,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      filters: this.filters,
      globalFilter: this.filters && this.filters['global'] ? this.filters['global'].value : null,
      multiSortMeta: this.multiSortMeta,
      forceUpdate: () => this.cd.detectChanges()
    };
  }
  clear() {
    this._sortField = null;
    this._sortOrder = this.defaultSortOrder;
    this._multiSortMeta = null;
    this.tableService.onSort(null);
    this.clearFilterValues();
    this.filteredValue = null;
    this.first = 0;
    this.firstChange.emit(this.first);
    if (this.lazy) {
      this.onLazyLoad.emit(this.createLazyLoadMetadata());
    } else {
      this.totalRecords = this._value ? this._value.length : 0;
    }
  }
  clearFilterValues() {
    for (const [, filterMetadata] of Object.entries(this.filters)) {
      if (Array.isArray(filterMetadata)) {
        for (let filter of filterMetadata) {
          filter.value = null;
        }
      } else if (filterMetadata) {
        filterMetadata.value = null;
      }
    }
  }
  reset() {
    this.clear();
  }
  getExportHeader(column) {
    return column[this.exportHeader] || column.header || column.field;
  }
  /**
   * Data export method.
   * @param {ExportCSVOptions} object - Export options.
   * @group Method
   */
  exportCSV(options) {
    let data;
    let csv = '';
    let columns = this.columns;
    if (options && options.selectionOnly) {
      data = this.selection || [];
    } else if (options && options.allValues) {
      data = this.value || [];
    } else {
      data = this.filteredValue || this.value;
      if (this.frozenValue) {
        data = data ? [...this.frozenValue, ...data] : this.frozenValue;
      }
    }
    const exportableColumns = columns.filter(column => column.exportable !== false && column.field);
    //headers
    csv += exportableColumns.map(column => '"' + this.getExportHeader(column) + '"').join(this.csvSeparator);
    //body
    const body = data.map(record => exportableColumns.map(column => {
      let cellData = ObjectUtils.resolveFieldData(record, column.field);
      if (cellData != null) {
        if (this.exportFunction) {
          cellData = this.exportFunction({
            data: cellData,
            field: column.field
          });
        } else cellData = String(cellData).replace(/"/g, '""');
      } else cellData = '';
      return '"' + cellData + '"';
    }).join(this.csvSeparator)).join('\n');
    if (body.length) {
      csv += '\n' + body;
    }
    let blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
      type: 'text/csv;charset=utf-8;'
    });
    let link = this.renderer.createElement('a');
    link.style.display = 'none';
    this.renderer.appendChild(this.document.body, link);
    if (link.download !== undefined) {
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', this.exportFilename + '.csv');
      link.click();
    } else {
      csv = 'data:text/csv;charset=utf-8,' + csv;
      this.document.defaultView.open(encodeURI(csv));
    }
    this.renderer.removeChild(this.document.body, link);
  }
  onLazyItemLoad(event) {
    this.onLazyLoad.emit({
      ...this.createLazyLoadMetadata(),
      ...event,
      rows: event.last - event.first
    });
  }
  /**
   * Resets scroll to top.
   * @group Method
   */
  resetScrollTop() {
    if (this.virtualScroll) this.scrollToVirtualIndex(0);else this.scrollTo({
      top: 0
    });
  }
  /**
   * Scrolls to given index when using virtual scroll.
   * @param {number} index - index of the element.
   * @group Method
   */
  scrollToVirtualIndex(index) {
    this.scroller && this.scroller.scrollToIndex(index);
  }
  /**
   * Scrolls to given index.
   * @param {ScrollToOptions} options - scroll options.
   * @group Method
   */
  scrollTo(options) {
    if (this.virtualScroll) {
      this.scroller?.scrollTo(options);
    } else if (this.wrapperViewChild && this.wrapperViewChild.nativeElement) {
      if (this.wrapperViewChild.nativeElement.scrollTo) {
        this.wrapperViewChild.nativeElement.scrollTo(options);
      } else {
        this.wrapperViewChild.nativeElement.scrollLeft = options.left;
        this.wrapperViewChild.nativeElement.scrollTop = options.top;
      }
    }
  }
  updateEditingCell(cell, data, field, index) {
    this.editingCell = cell;
    this.editingCellData = data;
    this.editingCellField = field;
    this.editingCellRowIndex = index;
    this.bindDocumentEditListener();
  }
  isEditingCellValid() {
    return this.editingCell && DomHandler.find(this.editingCell, '.ng-invalid.ng-dirty').length === 0;
  }
  bindDocumentEditListener() {
    if (!this.documentEditListener) {
      this.documentEditListener = this.renderer.listen(this.document, 'click', event => {
        if (this.editingCell && !this.selfClick && this.isEditingCellValid()) {
          DomHandler.removeClass(this.editingCell, 'p-cell-editing');
          this.editingCell = null;
          this.onEditComplete.emit({
            field: this.editingCellField,
            data: this.editingCellData,
            originalEvent: event,
            index: this.editingCellRowIndex
          });
          this.editingCellField = null;
          this.editingCellData = null;
          this.editingCellRowIndex = null;
          this.unbindDocumentEditListener();
          this.cd.markForCheck();
          if (this.overlaySubscription) {
            this.overlaySubscription.unsubscribe();
          }
        }
        this.selfClick = false;
      });
    }
  }
  unbindDocumentEditListener() {
    if (this.documentEditListener) {
      this.documentEditListener();
      this.documentEditListener = null;
    }
  }
  initRowEdit(rowData) {
    let dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
    this.editingRowKeys[dataKeyValue] = true;
  }
  saveRowEdit(rowData, rowElement) {
    if (DomHandler.find(rowElement, '.ng-invalid.ng-dirty').length === 0) {
      let dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
      delete this.editingRowKeys[dataKeyValue];
    }
  }
  cancelRowEdit(rowData) {
    let dataKeyValue = String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
    delete this.editingRowKeys[dataKeyValue];
  }
  toggleRow(rowData, event) {
    if (!this.dataKey && !this.groupRowsBy) {
      throw new Error('dataKey or groupRowsBy must be defined to use row expansion');
    }
    let dataKeyValue = this.groupRowsBy ? String(ObjectUtils.resolveFieldData(rowData, this.groupRowsBy)) : String(ObjectUtils.resolveFieldData(rowData, this.dataKey));
    if (this.expandedRowKeys[dataKeyValue] != null) {
      delete this.expandedRowKeys[dataKeyValue];
      this.onRowCollapse.emit({
        originalEvent: event,
        data: rowData
      });
    } else {
      if (this.rowExpandMode === 'single') {
        this.expandedRowKeys = {};
      }
      this.expandedRowKeys[dataKeyValue] = true;
      this.onRowExpand.emit({
        originalEvent: event,
        data: rowData
      });
    }
    if (event) {
      event.preventDefault();
    }
    if (this.isStateful()) {
      this.saveState();
    }
  }
  isRowExpanded(rowData) {
    return this.groupRowsBy ? this.expandedRowKeys[String(ObjectUtils.resolveFieldData(rowData, this.groupRowsBy))] === true : this.expandedRowKeys[String(ObjectUtils.resolveFieldData(rowData, this.dataKey))] === true;
  }
  isRowEditing(rowData) {
    return this.editingRowKeys[String(ObjectUtils.resolveFieldData(rowData, this.dataKey))] === true;
  }
  isSingleSelectionMode() {
    return this.selectionMode === 'single';
  }
  isMultipleSelectionMode() {
    return this.selectionMode === 'multiple';
  }
  onColumnResizeBegin(event) {
    let containerLeft = DomHandler.getOffset(this.containerViewChild?.nativeElement).left;
    this.resizeColumnElement = event.target.closest('th');
    this.columnResizing = true;
    if (event.type == 'touchstart') {
      this.lastResizerHelperX = event.changedTouches[0].clientX - containerLeft + this.containerViewChild?.nativeElement.scrollLeft;
    } else {
      this.lastResizerHelperX = event.pageX - containerLeft + this.containerViewChild?.nativeElement.scrollLeft;
    }
    this.onColumnResize(event);
    event.preventDefault();
  }
  onColumnResize(event) {
    let containerLeft = DomHandler.getOffset(this.containerViewChild?.nativeElement).left;
    DomHandler.addClass(this.containerViewChild?.nativeElement, 'p-unselectable-text');
    this.resizeHelperViewChild.nativeElement.style.height = this.containerViewChild?.nativeElement.offsetHeight + 'px';
    this.resizeHelperViewChild.nativeElement.style.top = 0 + 'px';
    if (event.type == 'touchmove') {
      this.resizeHelperViewChild.nativeElement.style.left = event.changedTouches[0].clientX - containerLeft + this.containerViewChild?.nativeElement.scrollLeft + 'px';
    } else {
      this.resizeHelperViewChild.nativeElement.style.left = event.pageX - containerLeft + this.containerViewChild?.nativeElement.scrollLeft + 'px';
    }
    this.resizeHelperViewChild.nativeElement.style.display = 'block';
  }
  onColumnResizeEnd() {
    const delta = this.resizeHelperViewChild?.nativeElement.offsetLeft - this.lastResizerHelperX;
    const columnWidth = this.resizeColumnElement.offsetWidth;
    const newColumnWidth = columnWidth + delta;
    const elementMinWidth = this.resizeColumnElement.style.minWidth.replace(/[^\d.]/g, '');
    const minWidth = elementMinWidth ? parseFloat(elementMinWidth) : 15;
    if (newColumnWidth >= minWidth) {
      if (this.columnResizeMode === 'fit') {
        const nextColumn = this.resizeColumnElement.nextElementSibling;
        const nextColumnWidth = nextColumn.offsetWidth - delta;
        if (newColumnWidth > 15 && nextColumnWidth > 15) {
          this.resizeTableCells(newColumnWidth, nextColumnWidth);
        }
      } else if (this.columnResizeMode === 'expand') {
        this._initialColWidths = this._totalTableWidth();
        const tableWidth = this.tableViewChild?.nativeElement.offsetWidth + delta;
        this.setResizeTableWidth(tableWidth + 'px');
        this.resizeTableCells(newColumnWidth, null);
      }
      this.onColResize.emit({
        element: this.resizeColumnElement,
        delta: delta
      });
      if (this.isStateful()) {
        this.saveState();
      }
    }
    this.resizeHelperViewChild.nativeElement.style.display = 'none';
    DomHandler.removeClass(this.containerViewChild?.nativeElement, 'p-unselectable-text');
  }
  _totalTableWidth() {
    let widths = [];
    const tableHead = DomHandler.findSingle(this.containerViewChild.nativeElement, '.p-datatable-thead');
    let headers = DomHandler.find(tableHead, 'tr > th');
    headers.forEach(header => widths.push(DomHandler.getOuterWidth(header)));
    return widths;
  }
  onColumnDragStart(event, columnElement) {
    this.reorderIconWidth = DomHandler.getHiddenElementOuterWidth(this.reorderIndicatorUpViewChild?.nativeElement);
    this.reorderIconHeight = DomHandler.getHiddenElementOuterHeight(this.reorderIndicatorDownViewChild?.nativeElement);
    this.draggedColumn = columnElement;
    event.dataTransfer.setData('text', 'b'); // For firefox
  }
  onColumnDragEnter(event, dropHeader) {
    if (this.reorderableColumns && this.draggedColumn && dropHeader) {
      event.preventDefault();
      let containerOffset = DomHandler.getOffset(this.containerViewChild?.nativeElement);
      let dropHeaderOffset = DomHandler.getOffset(dropHeader);
      if (this.draggedColumn != dropHeader) {
        let dragIndex = DomHandler.indexWithinGroup(this.draggedColumn, 'preorderablecolumn');
        let dropIndex = DomHandler.indexWithinGroup(dropHeader, 'preorderablecolumn');
        let targetLeft = dropHeaderOffset.left - containerOffset.left;
        let targetTop = containerOffset.top - dropHeaderOffset.top;
        let columnCenter = dropHeaderOffset.left + dropHeader.offsetWidth / 2;
        this.reorderIndicatorUpViewChild.nativeElement.style.top = dropHeaderOffset.top - containerOffset.top - (this.reorderIconHeight - 1) + 'px';
        this.reorderIndicatorDownViewChild.nativeElement.style.top = dropHeaderOffset.top - containerOffset.top + dropHeader.offsetHeight + 'px';
        if (event.pageX > columnCenter) {
          this.reorderIndicatorUpViewChild.nativeElement.style.left = targetLeft + dropHeader.offsetWidth - Math.ceil(this.reorderIconWidth / 2) + 'px';
          this.reorderIndicatorDownViewChild.nativeElement.style.left = targetLeft + dropHeader.offsetWidth - Math.ceil(this.reorderIconWidth / 2) + 'px';
          this.dropPosition = 1;
        } else {
          this.reorderIndicatorUpViewChild.nativeElement.style.left = targetLeft - Math.ceil(this.reorderIconWidth / 2) + 'px';
          this.reorderIndicatorDownViewChild.nativeElement.style.left = targetLeft - Math.ceil(this.reorderIconWidth / 2) + 'px';
          this.dropPosition = -1;
        }
        this.reorderIndicatorUpViewChild.nativeElement.style.display = 'block';
        this.reorderIndicatorDownViewChild.nativeElement.style.display = 'block';
      } else {
        event.dataTransfer.dropEffect = 'none';
      }
    }
  }
  onColumnDragLeave(event) {
    if (this.reorderableColumns && this.draggedColumn) {
      event.preventDefault();
    }
  }
  onColumnDrop(event, dropColumn) {
    event.preventDefault();
    if (this.draggedColumn) {
      let dragIndex = DomHandler.indexWithinGroup(this.draggedColumn, 'preorderablecolumn');
      let dropIndex = DomHandler.indexWithinGroup(dropColumn, 'preorderablecolumn');
      let allowDrop = dragIndex != dropIndex;
      if (allowDrop && (dropIndex - dragIndex == 1 && this.dropPosition === -1 || dragIndex - dropIndex == 1 && this.dropPosition === 1)) {
        allowDrop = false;
      }
      if (allowDrop && dropIndex < dragIndex && this.dropPosition === 1) {
        dropIndex = dropIndex + 1;
      }
      if (allowDrop && dropIndex > dragIndex && this.dropPosition === -1) {
        dropIndex = dropIndex - 1;
      }
      if (allowDrop) {
        ObjectUtils.reorderArray(this.columns, dragIndex, dropIndex);
        this.onColReorder.emit({
          dragIndex: dragIndex,
          dropIndex: dropIndex,
          columns: this.columns
        });
        if (this.isStateful()) {
          this.zone.runOutsideAngular(() => {
            setTimeout(() => {
              this.saveState();
            });
          });
        }
      }
      if (this.resizableColumns && this.resizeColumnElement) {
        let width = this.columnResizeMode === 'expand' ? this._initialColWidths : this._totalTableWidth();
        ObjectUtils.reorderArray(width, dragIndex + 1, dropIndex + 1);
        this.updateStyleElement(width, dragIndex, null, null);
      }
      this.reorderIndicatorUpViewChild.nativeElement.style.display = 'none';
      this.reorderIndicatorDownViewChild.nativeElement.style.display = 'none';
      this.draggedColumn.draggable = false;
      this.draggedColumn = null;
      this.dropPosition = null;
    }
  }
  resizeTableCells(newColumnWidth, nextColumnWidth) {
    let colIndex = DomHandler.index(this.resizeColumnElement);
    let width = this.columnResizeMode === 'expand' ? this._initialColWidths : this._totalTableWidth();
    this.updateStyleElement(width, colIndex, newColumnWidth, nextColumnWidth);
  }
  updateStyleElement(width, colIndex, newColumnWidth, nextColumnWidth) {
    this.destroyStyleElement();
    this.createStyleElement();
    let innerHTML = '';
    width.forEach((width, index) => {
      let colWidth = index === colIndex ? newColumnWidth : nextColumnWidth && index === colIndex + 1 ? nextColumnWidth : width;
      let style = `width: ${colWidth}px !important; max-width: ${colWidth}px !important;`;
      innerHTML += `
                #${this.id}-table > .p-datatable-thead > tr > th:nth-child(${index + 1}),
                #${this.id}-table > .p-datatable-tbody > tr > td:nth-child(${index + 1}),
                #${this.id}-table > .p-datatable-tfoot > tr > td:nth-child(${index + 1}) {
                    ${style}
                }
            `;
    });
    this.renderer.setProperty(this.styleElement, 'innerHTML', innerHTML);
  }
  onRowDragStart(event, index) {
    this.rowDragging = true;
    this.draggedRowIndex = index;
    event.dataTransfer.setData('text', 'b'); // For firefox
  }
  onRowDragOver(event, index, rowElement) {
    if (this.rowDragging && this.draggedRowIndex !== index) {
      let rowY = DomHandler.getOffset(rowElement).top;
      let pageY = event.pageY;
      let rowMidY = rowY + DomHandler.getOuterHeight(rowElement) / 2;
      let prevRowElement = rowElement.previousElementSibling;
      if (pageY < rowMidY) {
        DomHandler.removeClass(rowElement, 'p-datatable-dragpoint-bottom');
        this.droppedRowIndex = index;
        if (prevRowElement) DomHandler.addClass(prevRowElement, 'p-datatable-dragpoint-bottom');else DomHandler.addClass(rowElement, 'p-datatable-dragpoint-top');
      } else {
        if (prevRowElement) DomHandler.removeClass(prevRowElement, 'p-datatable-dragpoint-bottom');else DomHandler.addClass(rowElement, 'p-datatable-dragpoint-top');
        this.droppedRowIndex = index + 1;
        DomHandler.addClass(rowElement, 'p-datatable-dragpoint-bottom');
      }
    }
  }
  onRowDragLeave(event, rowElement) {
    let prevRowElement = rowElement.previousElementSibling;
    if (prevRowElement) {
      DomHandler.removeClass(prevRowElement, 'p-datatable-dragpoint-bottom');
    }
    DomHandler.removeClass(rowElement, 'p-datatable-dragpoint-bottom');
    DomHandler.removeClass(rowElement, 'p-datatable-dragpoint-top');
  }
  onRowDragEnd(event) {
    this.rowDragging = false;
    this.draggedRowIndex = null;
    this.droppedRowIndex = null;
  }
  onRowDrop(event, rowElement) {
    if (this.droppedRowIndex != null) {
      let dropIndex = this.draggedRowIndex > this.droppedRowIndex ? this.droppedRowIndex : this.droppedRowIndex === 0 ? 0 : this.droppedRowIndex - 1;
      ObjectUtils.reorderArray(this.value, this.draggedRowIndex, dropIndex);
      if (this.virtualScroll) {
        // TODO: Check
        this._value = [...this._value];
      }
      this.onRowReorder.emit({
        dragIndex: this.draggedRowIndex,
        dropIndex: dropIndex
      });
    }
    //cleanup
    this.onRowDragLeave(event, rowElement);
    this.onRowDragEnd(event);
  }
  isEmpty() {
    let data = this.filteredValue || this.value;
    return data == null || data.length == 0;
  }
  getBlockableElement() {
    return this.el.nativeElement.children[0];
  }
  getStorage() {
    if (isPlatformBrowser(this.platformId)) {
      switch (this.stateStorage) {
        case 'local':
          return window.localStorage;
        case 'session':
          return window.sessionStorage;
        default:
          throw new Error(this.stateStorage + ' is not a valid value for the state storage, supported values are "local" and "session".');
      }
    } else {
      throw new Error('Browser storage is not available in the server side.');
    }
  }
  isStateful() {
    return this.stateKey != null;
  }
  saveState() {
    const storage = this.getStorage();
    let state = {};
    if (this.paginator) {
      state.first = this.first;
      state.rows = this.rows;
    }
    if (this.sortField) {
      state.sortField = this.sortField;
      state.sortOrder = this.sortOrder;
    }
    if (this.multiSortMeta) {
      state.multiSortMeta = this.multiSortMeta;
    }
    if (this.hasFilter()) {
      state.filters = this.filters;
    }
    if (this.resizableColumns) {
      this.saveColumnWidths(state);
    }
    if (this.reorderableColumns) {
      this.saveColumnOrder(state);
    }
    if (this.selection) {
      state.selection = this.selection;
    }
    if (Object.keys(this.expandedRowKeys).length) {
      state.expandedRowKeys = this.expandedRowKeys;
    }
    storage.setItem(this.stateKey, JSON.stringify(state));
    this.onStateSave.emit(state);
  }
  clearState() {
    const storage = this.getStorage();
    if (this.stateKey) {
      storage.removeItem(this.stateKey);
    }
  }
  restoreState() {
    const storage = this.getStorage();
    const stateString = storage.getItem(this.stateKey);
    const dateFormat = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
    const reviver = function (key, value) {
      if (typeof value === 'string' && dateFormat.test(value)) {
        return new Date(value);
      }
      return value;
    };
    if (stateString) {
      let state = JSON.parse(stateString, reviver);
      if (this.paginator) {
        if (this.first !== undefined) {
          this.first = state.first;
          this.firstChange.emit(this.first);
        }
        if (this.rows !== undefined) {
          this.rows = state.rows;
          this.rowsChange.emit(this.rows);
        }
      }
      if (state.sortField) {
        this.restoringSort = true;
        this._sortField = state.sortField;
        this._sortOrder = state.sortOrder;
      }
      if (state.multiSortMeta) {
        this.restoringSort = true;
        this._multiSortMeta = state.multiSortMeta;
      }
      if (state.filters) {
        this.restoringFilter = true;
        this.filters = state.filters;
      }
      if (this.resizableColumns) {
        this.columnWidthsState = state.columnWidths;
        this.tableWidthState = state.tableWidth;
      }
      // if (this.reorderableColumns) {
      //     this.restoreColumnOrder();
      // }
      if (state.expandedRowKeys) {
        this.expandedRowKeys = state.expandedRowKeys;
      }
      if (state.selection) {
        Promise.resolve(null).then(() => this.selectionChange.emit(state.selection));
      }
      this.stateRestored = true;
      this.onStateRestore.emit(state);
    }
  }
  saveColumnWidths(state) {
    let widths = [];
    let headers = DomHandler.find(this.containerViewChild?.nativeElement, '.p-datatable-thead > tr > th');
    headers.forEach(header => widths.push(DomHandler.getOuterWidth(header)));
    state.columnWidths = widths.join(',');
    if (this.columnResizeMode === 'expand') {
      state.tableWidth = DomHandler.getOuterWidth(this.tableViewChild?.nativeElement);
    }
  }
  setResizeTableWidth(width) {
    this.tableViewChild.nativeElement.style.width = width;
    this.tableViewChild.nativeElement.style.minWidth = width;
  }
  restoreColumnWidths() {
    if (this.columnWidthsState) {
      let widths = this.columnWidthsState.split(',');
      if (this.columnResizeMode === 'expand' && this.tableWidthState) {
        this.setResizeTableWidth(this.tableWidthState + 'px');
      }
      if (ObjectUtils.isNotEmpty(widths)) {
        this.createStyleElement();
        let innerHTML = '';
        widths.forEach((width, index) => {
          let style = `width: ${width}px !important; max-width: ${width}px !important`;
          innerHTML += `
                        #${this.id}-table > .p-datatable-thead > tr > th:nth-child(${index + 1}),
                        #${this.id}-table > .p-datatable-tbody > tr > td:nth-child(${index + 1}),
                        #${this.id}-table > .p-datatable-tfoot > tr > td:nth-child(${index + 1}) {
                            ${style}
                        }
                    `;
        });
        this.styleElement.innerHTML = innerHTML;
      }
    }
  }
  saveColumnOrder(state) {
    if (this.columns) {
      let columnOrder = [];
      this.columns.map(column => {
        columnOrder.push(column.field || column.key);
      });
      state.columnOrder = columnOrder;
    }
  }
  restoreColumnOrder() {
    const storage = this.getStorage();
    const stateString = storage.getItem(this.stateKey);
    if (stateString) {
      let state = JSON.parse(stateString);
      let columnOrder = state.columnOrder;
      if (columnOrder) {
        let reorderedColumns = [];
        columnOrder.map(key => {
          let col = this.findColumnByKey(key);
          if (col) {
            reorderedColumns.push(col);
          }
        });
        this.columnOrderStateRestored = true;
        this.columns = reorderedColumns;
      }
    }
  }
  findColumnByKey(key) {
    if (this.columns) {
      for (let col of this.columns) {
        if (col.key === key || col.field === key) return col;else continue;
      }
    } else {
      return null;
    }
  }
  createStyleElement() {
    this.styleElement = this.renderer.createElement('style');
    this.styleElement.type = 'text/css';
    this.renderer.appendChild(this.document.head, this.styleElement);
    DomHandler.setAttribute(this.styleElement, 'nonce', this.config?.csp()?.nonce);
  }
  getGroupRowsMeta() {
    return {
      field: this.groupRowsBy,
      order: this.groupRowsByOrder
    };
  }
  createResponsiveStyle() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.responsiveStyleElement) {
        this.responsiveStyleElement = this.renderer.createElement('style');
        this.responsiveStyleElement.type = 'text/css';
        this.renderer.appendChild(this.document.head, this.responsiveStyleElement);
        let innerHTML = `
    @media screen and (max-width: ${this.breakpoint}) {
        #${this.id}-table > .p-datatable-thead > tr > th,
        #${this.id}-table > .p-datatable-tfoot > tr > td {
            display: none !important;
        }

        #${this.id}-table > .p-datatable-tbody > tr > td {
            display: flex;
            width: 100% !important;
            align-items: center;
            justify-content: space-between;
        }

        #${this.id}-table > .p-datatable-tbody > tr > td:not(:last-child) {
            border: 0 none;
        }

        #${this.id}.p-datatable-gridlines > .p-datatable-table-container > .p-datatable-table > .p-datatable-tbody > tr > td:last-child {
            border-top: 0;
            border-right: 0;
            border-left: 0;
        }

        #${this.id}-table > .p-datatable-tbody > tr > td > .p-datatable-column-title {
            display: block;
        }
    }
    `;
        this.renderer.setProperty(this.responsiveStyleElement, 'innerHTML', innerHTML);
        DomHandler.setAttribute(this.responsiveStyleElement, 'nonce', this.config?.csp()?.nonce);
      }
    }
  }
  destroyResponsiveStyle() {
    if (this.responsiveStyleElement) {
      this.renderer.removeChild(this.document.head, this.responsiveStyleElement);
      this.responsiveStyleElement = null;
    }
  }
  destroyStyleElement() {
    if (this.styleElement) {
      this.renderer.removeChild(this.document.head, this.styleElement);
      this.styleElement = null;
    }
  }
  ngOnDestroy() {
    this.unbindDocumentEditListener();
    this.editingCell = null;
    this.initialized = null;
    this.destroyStyleElement();
    this.destroyResponsiveStyle();
    super.ngOnDestroy();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Table,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: Table,
    isStandalone: false,
    selector: "p-table",
    inputs: {
      frozenColumns: "frozenColumns",
      frozenValue: "frozenValue",
      style: "style",
      styleClass: "styleClass",
      tableStyle: "tableStyle",
      tableStyleClass: "tableStyleClass",
      paginator: ["paginator", "paginator", booleanAttribute],
      pageLinks: ["pageLinks", "pageLinks", numberAttribute],
      rowsPerPageOptions: "rowsPerPageOptions",
      alwaysShowPaginator: ["alwaysShowPaginator", "alwaysShowPaginator", booleanAttribute],
      paginatorPosition: "paginatorPosition",
      paginatorStyleClass: "paginatorStyleClass",
      paginatorDropdownAppendTo: "paginatorDropdownAppendTo",
      paginatorDropdownScrollHeight: "paginatorDropdownScrollHeight",
      currentPageReportTemplate: "currentPageReportTemplate",
      showCurrentPageReport: ["showCurrentPageReport", "showCurrentPageReport", booleanAttribute],
      showJumpToPageDropdown: ["showJumpToPageDropdown", "showJumpToPageDropdown", booleanAttribute],
      showJumpToPageInput: ["showJumpToPageInput", "showJumpToPageInput", booleanAttribute],
      showFirstLastIcon: ["showFirstLastIcon", "showFirstLastIcon", booleanAttribute],
      showPageLinks: ["showPageLinks", "showPageLinks", booleanAttribute],
      defaultSortOrder: ["defaultSortOrder", "defaultSortOrder", numberAttribute],
      sortMode: "sortMode",
      resetPageOnSort: ["resetPageOnSort", "resetPageOnSort", booleanAttribute],
      selectionMode: "selectionMode",
      selectionPageOnly: ["selectionPageOnly", "selectionPageOnly", booleanAttribute],
      contextMenuSelection: "contextMenuSelection",
      contextMenuSelectionMode: "contextMenuSelectionMode",
      dataKey: "dataKey",
      metaKeySelection: ["metaKeySelection", "metaKeySelection", booleanAttribute],
      rowSelectable: "rowSelectable",
      rowTrackBy: "rowTrackBy",
      lazy: ["lazy", "lazy", booleanAttribute],
      lazyLoadOnInit: ["lazyLoadOnInit", "lazyLoadOnInit", booleanAttribute],
      compareSelectionBy: "compareSelectionBy",
      csvSeparator: "csvSeparator",
      exportFilename: "exportFilename",
      filters: "filters",
      globalFilterFields: "globalFilterFields",
      filterDelay: ["filterDelay", "filterDelay", numberAttribute],
      filterLocale: "filterLocale",
      expandedRowKeys: "expandedRowKeys",
      editingRowKeys: "editingRowKeys",
      rowExpandMode: "rowExpandMode",
      scrollable: ["scrollable", "scrollable", booleanAttribute],
      scrollDirection: "scrollDirection",
      rowGroupMode: "rowGroupMode",
      scrollHeight: "scrollHeight",
      virtualScroll: ["virtualScroll", "virtualScroll", booleanAttribute],
      virtualScrollItemSize: ["virtualScrollItemSize", "virtualScrollItemSize", numberAttribute],
      virtualScrollOptions: "virtualScrollOptions",
      virtualScrollDelay: ["virtualScrollDelay", "virtualScrollDelay", numberAttribute],
      frozenWidth: "frozenWidth",
      responsive: "responsive",
      contextMenu: "contextMenu",
      resizableColumns: ["resizableColumns", "resizableColumns", booleanAttribute],
      columnResizeMode: "columnResizeMode",
      reorderableColumns: ["reorderableColumns", "reorderableColumns", booleanAttribute],
      loading: ["loading", "loading", booleanAttribute],
      loadingIcon: "loadingIcon",
      showLoader: ["showLoader", "showLoader", booleanAttribute],
      rowHover: ["rowHover", "rowHover", booleanAttribute],
      customSort: ["customSort", "customSort", booleanAttribute],
      showInitialSortBadge: ["showInitialSortBadge", "showInitialSortBadge", booleanAttribute],
      autoLayout: ["autoLayout", "autoLayout", booleanAttribute],
      exportFunction: "exportFunction",
      exportHeader: "exportHeader",
      stateKey: "stateKey",
      stateStorage: "stateStorage",
      editMode: "editMode",
      groupRowsBy: "groupRowsBy",
      size: "size",
      showGridlines: ["showGridlines", "showGridlines", booleanAttribute],
      stripedRows: ["stripedRows", "stripedRows", booleanAttribute],
      groupRowsByOrder: ["groupRowsByOrder", "groupRowsByOrder", numberAttribute],
      responsiveLayout: "responsiveLayout",
      breakpoint: "breakpoint",
      paginatorLocale: "paginatorLocale",
      value: "value",
      columns: "columns",
      first: "first",
      rows: "rows",
      totalRecords: "totalRecords",
      sortField: "sortField",
      sortOrder: "sortOrder",
      multiSortMeta: "multiSortMeta",
      selection: "selection",
      virtualRowHeight: "virtualRowHeight",
      selectAll: "selectAll"
    },
    outputs: {
      contextMenuSelectionChange: "contextMenuSelectionChange",
      selectAllChange: "selectAllChange",
      selectionChange: "selectionChange",
      onRowSelect: "onRowSelect",
      onRowUnselect: "onRowUnselect",
      onPage: "onPage",
      onSort: "onSort",
      onFilter: "onFilter",
      onLazyLoad: "onLazyLoad",
      onRowExpand: "onRowExpand",
      onRowCollapse: "onRowCollapse",
      onContextMenuSelect: "onContextMenuSelect",
      onColResize: "onColResize",
      onColReorder: "onColReorder",
      onRowReorder: "onRowReorder",
      onEditInit: "onEditInit",
      onEditComplete: "onEditComplete",
      onEditCancel: "onEditCancel",
      onHeaderCheckboxToggle: "onHeaderCheckboxToggle",
      sortFunction: "sortFunction",
      firstChange: "firstChange",
      rowsChange: "rowsChange",
      onStateSave: "onStateSave",
      onStateRestore: "onStateRestore"
    },
    providers: [TableService, TableStyle],
    queries: [{
      propertyName: "_headerTemplate",
      first: true,
      predicate: ["header"]
    }, {
      propertyName: "_headerGroupedTemplate",
      first: true,
      predicate: ["headergrouped"]
    }, {
      propertyName: "_bodyTemplate",
      first: true,
      predicate: ["body"]
    }, {
      propertyName: "_loadingBodyTemplate",
      first: true,
      predicate: ["loadingbody"]
    }, {
      propertyName: "_captionTemplate",
      first: true,
      predicate: ["caption"]
    }, {
      propertyName: "_footerTemplate",
      first: true,
      predicate: ["footer"]
    }, {
      propertyName: "_footerGroupedTemplate",
      first: true,
      predicate: ["footergrouped"]
    }, {
      propertyName: "_summaryTemplate",
      first: true,
      predicate: ["summary"]
    }, {
      propertyName: "_colGroupTemplate",
      first: true,
      predicate: ["colgroup"]
    }, {
      propertyName: "_expandedRowTemplate",
      first: true,
      predicate: ["expandedrow"]
    }, {
      propertyName: "_groupHeaderTemplate",
      first: true,
      predicate: ["groupheader"]
    }, {
      propertyName: "_groupFooterTemplate",
      first: true,
      predicate: ["groupfooter"]
    }, {
      propertyName: "_frozenExpandedRowTemplate",
      first: true,
      predicate: ["frozenexpandedrow"]
    }, {
      propertyName: "_frozenHeaderTemplate",
      first: true,
      predicate: ["frozenheader"]
    }, {
      propertyName: "_frozenBodyTemplate",
      first: true,
      predicate: ["frozenbody"]
    }, {
      propertyName: "_frozenFooterTemplate",
      first: true,
      predicate: ["frozenfooter"]
    }, {
      propertyName: "_frozenColGroupTemplate",
      first: true,
      predicate: ["frozencolgroup"]
    }, {
      propertyName: "_emptyMessageTemplate",
      first: true,
      predicate: ["emptymessage"]
    }, {
      propertyName: "_paginatorLeftTemplate",
      first: true,
      predicate: ["paginatorleft"]
    }, {
      propertyName: "_paginatorRightTemplate",
      first: true,
      predicate: ["paginatorright"]
    }, {
      propertyName: "_paginatorDropdownItemTemplate",
      first: true,
      predicate: ["paginatordropdownitem"]
    }, {
      propertyName: "_loadingIconTemplate",
      first: true,
      predicate: ["loadingicon"]
    }, {
      propertyName: "_reorderIndicatorUpIconTemplate",
      first: true,
      predicate: ["reorderindicatorupicon"]
    }, {
      propertyName: "_reorderIndicatorDownIconTemplate",
      first: true,
      predicate: ["reorderindicatordownicon"]
    }, {
      propertyName: "_sortIconTemplate",
      first: true,
      predicate: ["sorticon"]
    }, {
      propertyName: "_checkboxIconTemplate",
      first: true,
      predicate: ["checkboxicon"]
    }, {
      propertyName: "_headerCheckboxIconTemplate",
      first: true,
      predicate: ["headercheckboxicon"]
    }, {
      propertyName: "_paginatorDropdownIconTemplate",
      first: true,
      predicate: ["paginatordropdownicon"]
    }, {
      propertyName: "_paginatorFirstPageLinkIconTemplate",
      first: true,
      predicate: ["paginatorfirstpagelinkicon"]
    }, {
      propertyName: "_paginatorLastPageLinkIconTemplate",
      first: true,
      predicate: ["paginatorlastpagelinkicon"]
    }, {
      propertyName: "_paginatorPreviousPageLinkIconTemplate",
      first: true,
      predicate: ["paginatorpreviouspagelinkicon"]
    }, {
      propertyName: "_paginatorNextPageLinkIconTemplate",
      first: true,
      predicate: ["paginatornextpagelinkicon"]
    }, {
      propertyName: "_templates",
      predicate: PrimeTemplate
    }],
    viewQueries: [{
      propertyName: "containerViewChild",
      first: true,
      predicate: ["container"],
      descendants: true
    }, {
      propertyName: "resizeHelperViewChild",
      first: true,
      predicate: ["resizeHelper"],
      descendants: true
    }, {
      propertyName: "reorderIndicatorUpViewChild",
      first: true,
      predicate: ["reorderIndicatorUp"],
      descendants: true
    }, {
      propertyName: "reorderIndicatorDownViewChild",
      first: true,
      predicate: ["reorderIndicatorDown"],
      descendants: true
    }, {
      propertyName: "wrapperViewChild",
      first: true,
      predicate: ["wrapper"],
      descendants: true
    }, {
      propertyName: "tableViewChild",
      first: true,
      predicate: ["table"],
      descendants: true
    }, {
      propertyName: "tableHeaderViewChild",
      first: true,
      predicate: ["thead"],
      descendants: true
    }, {
      propertyName: "tableFooterViewChild",
      first: true,
      predicate: ["tfoot"],
      descendants: true
    }, {
      propertyName: "scroller",
      first: true,
      predicate: ["scroller"],
      descendants: true
    }],
    usesInheritance: true,
    usesOnChanges: true,
    ngImport: i0,
    template: `
        <div #container [ngStyle]="style" [class]="styleClass" [ngClass]="cx('root')" [attr.id]="id">
            <div [ngClass]="cx('mask')" *ngIf="loading && showLoader">
                <i *ngIf="loadingIcon" [class]="'p-datatable-loading-icon ' + loadingIcon"></i>
                <ng-container *ngIf="!loadingIcon">
                    <SpinnerIcon *ngIf="!loadingIconTemplate && !_loadingIconTemplate" [spin]="true" [styleClass]="cx('loadingIcon')" />
                    <span *ngIf="loadingIconTemplate || _loadingIconTemplate" [ngClass]="cx('loadingIcon')">
                        <ng-template *ngTemplateOutlet="loadingIconTemplate || _loadingIconTemplate"></ng-template>
                    </span>
                </ng-container>
            </div>
            <div *ngIf="captionTemplate || _captionTemplate" [ngClass]="cx('header')">
                <ng-container *ngTemplateOutlet="captionTemplate || _captionTemplate"></ng-container>
            </div>
            <p-paginator
                [rows]="rows"
                [first]="first"
                [totalRecords]="totalRecords"
                [pageLinkSize]="pageLinks"
                [alwaysShow]="alwaysShowPaginator"
                (onPageChange)="onPageChange($event)"
                [rowsPerPageOptions]="rowsPerPageOptions"
                *ngIf="paginator && (paginatorPosition === 'top' || paginatorPosition == 'both')"
                [templateLeft]="paginatorLeftTemplate || _paginatorLeftTemplate"
                [templateRight]="paginatorRightTemplate || _paginatorRightTemplate"
                [dropdownAppendTo]="paginatorDropdownAppendTo"
                [dropdownScrollHeight]="paginatorDropdownScrollHeight"
                [currentPageReportTemplate]="currentPageReportTemplate"
                [showFirstLastIcon]="showFirstLastIcon"
                [dropdownItemTemplate]="paginatorDropdownItemTemplate || _paginatorDropdownItemTemplate"
                [showCurrentPageReport]="showCurrentPageReport"
                [showJumpToPageDropdown]="showJumpToPageDropdown"
                [showJumpToPageInput]="showJumpToPageInput"
                [showPageLinks]="showPageLinks"
                [styleClass]="cx('pcPaginator') + ' ' + paginatorStyleClass && paginatorStyleClass"
                [locale]="paginatorLocale"
            >
                <ng-template pTemplate="dropdownicon" *ngIf="paginatorDropdownIconTemplate || _paginatorDropdownIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorDropdownIconTemplate || _paginatorDropdownIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="firstpagelinkicon" *ngIf="paginatorFirstPageLinkIconTemplate || _paginatorFirstPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorFirstPageLinkIconTemplate || _paginatorFirstPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="previouspagelinkicon" *ngIf="paginatorPreviousPageLinkIconTemplate || _paginatorPreviousPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorPreviousPageLinkIconTemplate || _paginatorPreviousPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="lastpagelinkicon" *ngIf="paginatorLastPageLinkIconTemplate || _paginatorLastPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorLastPageLinkIconTemplate || _paginatorLastPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="nextpagelinkicon" *ngIf="paginatorNextPageLinkIconTemplate || _paginatorNextPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorNextPageLinkIconTemplate || _paginatorNextPageLinkIconTemplate"></ng-container>
                </ng-template>
            </p-paginator>

            <div #wrapper [ngClass]="cx('tableContainer')" [ngStyle]="sx('tableContainer')">
                <p-scroller
                    #scroller
                    *ngIf="virtualScroll"
                    [items]="processedData"
                    [columns]="columns"
                    [style]="{
                        height: scrollHeight !== 'flex' ? scrollHeight : undefined
                    }"
                    [scrollHeight]="scrollHeight !== 'flex' ? undefined : '100%'"
                    [itemSize]="virtualScrollItemSize || _virtualRowHeight"
                    [step]="rows"
                    [delay]="lazy ? virtualScrollDelay : 0"
                    [inline]="true"
                    [lazy]="lazy"
                    (onLazyLoad)="onLazyItemLoad($event)"
                    [loaderDisabled]="true"
                    [showSpacer]="false"
                    [showLoader]="loadingBodyTemplate || _loadingBodyTemplate"
                    [options]="virtualScrollOptions"
                    [autoSize]="true"
                >
                    <ng-template #content let-items let-scrollerOptions="options">
                        <ng-container
                            *ngTemplateOutlet="
                                buildInTable;
                                context: {
                                    $implicit: items,
                                    options: scrollerOptions
                                }
                            "
                        ></ng-container>
                    </ng-template>
                </p-scroller>
                <ng-container *ngIf="!virtualScroll">
                    <ng-container
                        *ngTemplateOutlet="
                            buildInTable;
                            context: {
                                $implicit: processedData,
                                options: { columns }
                            }
                        "
                    ></ng-container>
                </ng-container>

                <ng-template #buildInTable let-items let-scrollerOptions="options">
                    <table #table role="table" [ngClass]="cx('table')" [class]="tableStyleClass" [style]="tableStyle" [attr.id]="id + '-table'">
                        <ng-container *ngTemplateOutlet="colGroupTemplate || _colGroupTemplate; context: { $implicit: scrollerOptions.columns }"></ng-container>
                        <thead role="rowgroup" #thead [ngClass]="cx('thead')" [ngStyle]="sx('thead')">
                            <ng-container
                                *ngTemplateOutlet="
                                    headerGroupedTemplate || headerTemplate || _headerTemplate;
                                    context: {
                                        $implicit: scrollerOptions.columns
                                    }
                                "
                            ></ng-container>
                        </thead>
                        <tbody
                            role="rowgroup"
                            [ngClass]="cx('tbody')"
                            *ngIf="frozenValue || frozenBodyTemplate || _frozenBodyTemplate"
                            [value]="frozenValue"
                            [frozenRows]="true"
                            [pTableBody]="scrollerOptions.columns"
                            [pTableBodyTemplate]="frozenBodyTemplate || _frozenBodyTemplate"
                            [frozen]="true"
                        ></tbody>
                        <tbody
                            role="rowgroup"
                            [ngClass]="cx('tbody', scrollerOptions.contentStyleClass)"
                            [style]="scrollerOptions.contentStyle"
                            [value]="dataToRender(scrollerOptions.rows)"
                            [pTableBody]="scrollerOptions.columns"
                            [pTableBodyTemplate]="bodyTemplate || _bodyTemplate"
                            [scrollerOptions]="scrollerOptions"
                        ></tbody>
                        <tbody
                            role="rowgroup"
                            *ngIf="scrollerOptions.spacerStyle"
                            [style]="'height: calc(' + scrollerOptions.spacerStyle.height + ' - ' + scrollerOptions.rows.length * scrollerOptions.itemSize + 'px);'"
                            [ngClass]="cx('virtualScrollerSpacer')"
                        ></tbody>
                        <tfoot role="rowgroup" *ngIf="footerGroupedTemplate || footerTemplate || _footerTemplate || _footerGroupedTemplate" #tfoot [ngClass]="cx('footer')" [ngStyle]="sx('tfoot')">
                            <ng-container
                                *ngTemplateOutlet="
                                    footerGroupedTemplate || footerTemplate || _footerTemplate || _footerGroupedTemplate;
                                    context: {
                                        $implicit: scrollerOptions.columns
                                    }
                                "
                            ></ng-container>
                        </tfoot>
                    </table>
                </ng-template>
            </div>

            <p-paginator
                [rows]="rows"
                [first]="first"
                [totalRecords]="totalRecords"
                [pageLinkSize]="pageLinks"
                [alwaysShow]="alwaysShowPaginator"
                (onPageChange)="onPageChange($event)"
                [rowsPerPageOptions]="rowsPerPageOptions"
                *ngIf="paginator && (paginatorPosition === 'bottom' || paginatorPosition == 'both')"
                [templateLeft]="paginatorLeftTemplate || _paginatorLeftTemplate"
                [templateRight]="paginatorRightTemplate || _paginatorRightTemplate"
                [dropdownAppendTo]="paginatorDropdownAppendTo"
                [dropdownScrollHeight]="paginatorDropdownScrollHeight"
                [currentPageReportTemplate]="currentPageReportTemplate"
                [showFirstLastIcon]="showFirstLastIcon"
                [dropdownItemTemplate]="paginatorDropdownItemTemplate || _paginatorDropdownItemTemplate"
                [showCurrentPageReport]="showCurrentPageReport"
                [showJumpToPageDropdown]="showJumpToPageDropdown"
                [showJumpToPageInput]="showJumpToPageInput"
                [showPageLinks]="showPageLinks"
                [styleClass]="cx('pcPaginator') + ' ' + paginatorStyleClass && paginatorStyleClass"
                [locale]="paginatorLocale"
            >
                <ng-template pTemplate="dropdownicon" *ngIf="paginatorDropdownIconTemplate || _paginatorDropdownIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorDropdownIconTemplate || _paginatorDropdownIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="firstpagelinkicon" *ngIf="paginatorFirstPageLinkIconTemplate || _paginatorFirstPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorFirstPageLinkIconTemplate || _paginatorFirstPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="previouspagelinkicon" *ngIf="paginatorPreviousPageLinkIconTemplate || _paginatorPreviousPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorPreviousPageLinkIconTemplate || _paginatorPreviousPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="lastpagelinkicon" *ngIf="paginatorLastPageLinkIconTemplate || _paginatorLastPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorLastPageLinkIconTemplate || _paginatorLastPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="nextpagelinkicon" *ngIf="paginatorNextPageLinkIconTemplate || _paginatorNextPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorNextPageLinkIconTemplate || _paginatorNextPageLinkIconTemplate"></ng-container>
                </ng-template>
            </p-paginator>

            <div *ngIf="summaryTemplate || _summaryTemplate" [ngClass]="cx('footer')">
                <ng-container *ngTemplateOutlet="summaryTemplate || _summaryTemplate"></ng-container>
            </div>

            <div #resizeHelper [ngClass]="cx('columnResizeIndicator')" style="display:none" *ngIf="resizableColumns"></div>
            <span #reorderIndicatorUp [ngClass]="cx('rowReorderIndicatorUp')" style="display: none;" *ngIf="reorderableColumns">
                <ArrowDownIcon *ngIf="!reorderIndicatorUpIconTemplate && !_reorderIndicatorUpIconTemplate" />
                <ng-template *ngTemplateOutlet="reorderIndicatorUpIconTemplate || _reorderIndicatorUpIconTemplate"></ng-template>
            </span>
            <span #reorderIndicatorDown [ngClass]="cx('rowReorderIndicatorDown')" style="display: none;" *ngIf="reorderableColumns">
                <ArrowUpIcon *ngIf="!reorderIndicatorDownIconTemplate && !_reorderIndicatorDownIconTemplate" />
                <ng-template *ngTemplateOutlet="reorderIndicatorDownIconTemplate || _reorderIndicatorDownIconTemplate"></ng-template>
            </span>
        </div>
    `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i0.forwardRef(() => i1.NgClass),
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i1.NgIf),
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i1.NgTemplateOutlet),
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i1.NgStyle),
      selector: "[ngStyle]",
      inputs: ["ngStyle"]
    }, {
      kind: "component",
      type: i0.forwardRef(() => i2.Paginator),
      selector: "p-paginator",
      inputs: ["pageLinkSize", "style", "styleClass", "alwaysShow", "dropdownAppendTo", "templateLeft", "templateRight", "appendTo", "dropdownScrollHeight", "currentPageReportTemplate", "showCurrentPageReport", "showFirstLastIcon", "totalRecords", "rows", "rowsPerPageOptions", "showJumpToPageDropdown", "showJumpToPageInput", "jumpToPageItemTemplate", "showPageLinks", "locale", "dropdownItemTemplate", "first"],
      outputs: ["onPageChange"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i3.PrimeTemplate),
      selector: "[pTemplate]",
      inputs: ["type", "pTemplate"]
    }, {
      kind: "component",
      type: i0.forwardRef(() => i4.Scroller),
      selector: "p-scroller, p-virtualscroller, p-virtual-scroller, p-virtualScroller",
      inputs: ["id", "style", "styleClass", "tabindex", "items", "itemSize", "scrollHeight", "scrollWidth", "orientation", "step", "delay", "resizeDelay", "appendOnly", "inline", "lazy", "disabled", "loaderDisabled", "columns", "showSpacer", "showLoader", "numToleratedItems", "loading", "autoSize", "trackBy", "options"],
      outputs: ["onLazyLoad", "onScroll", "onScrollIndexChange"]
    }, {
      kind: "component",
      type: i0.forwardRef(() => ArrowDownIcon),
      selector: "ArrowDownIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => ArrowUpIcon),
      selector: "ArrowUpIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => SpinnerIcon),
      selector: "SpinnerIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => TableBody),
      selector: "[pTableBody]",
      inputs: ["pTableBody", "pTableBodyTemplate", "value", "frozen", "frozenRows", "scrollerOptions"]
    }],
    changeDetection: i0.ChangeDetectionStrategy.Default,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Table,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-table',
      standalone: false,
      template: `
        <div #container [ngStyle]="style" [class]="styleClass" [ngClass]="cx('root')" [attr.id]="id">
            <div [ngClass]="cx('mask')" *ngIf="loading && showLoader">
                <i *ngIf="loadingIcon" [class]="'p-datatable-loading-icon ' + loadingIcon"></i>
                <ng-container *ngIf="!loadingIcon">
                    <SpinnerIcon *ngIf="!loadingIconTemplate && !_loadingIconTemplate" [spin]="true" [styleClass]="cx('loadingIcon')" />
                    <span *ngIf="loadingIconTemplate || _loadingIconTemplate" [ngClass]="cx('loadingIcon')">
                        <ng-template *ngTemplateOutlet="loadingIconTemplate || _loadingIconTemplate"></ng-template>
                    </span>
                </ng-container>
            </div>
            <div *ngIf="captionTemplate || _captionTemplate" [ngClass]="cx('header')">
                <ng-container *ngTemplateOutlet="captionTemplate || _captionTemplate"></ng-container>
            </div>
            <p-paginator
                [rows]="rows"
                [first]="first"
                [totalRecords]="totalRecords"
                [pageLinkSize]="pageLinks"
                [alwaysShow]="alwaysShowPaginator"
                (onPageChange)="onPageChange($event)"
                [rowsPerPageOptions]="rowsPerPageOptions"
                *ngIf="paginator && (paginatorPosition === 'top' || paginatorPosition == 'both')"
                [templateLeft]="paginatorLeftTemplate || _paginatorLeftTemplate"
                [templateRight]="paginatorRightTemplate || _paginatorRightTemplate"
                [dropdownAppendTo]="paginatorDropdownAppendTo"
                [dropdownScrollHeight]="paginatorDropdownScrollHeight"
                [currentPageReportTemplate]="currentPageReportTemplate"
                [showFirstLastIcon]="showFirstLastIcon"
                [dropdownItemTemplate]="paginatorDropdownItemTemplate || _paginatorDropdownItemTemplate"
                [showCurrentPageReport]="showCurrentPageReport"
                [showJumpToPageDropdown]="showJumpToPageDropdown"
                [showJumpToPageInput]="showJumpToPageInput"
                [showPageLinks]="showPageLinks"
                [styleClass]="cx('pcPaginator') + ' ' + paginatorStyleClass && paginatorStyleClass"
                [locale]="paginatorLocale"
            >
                <ng-template pTemplate="dropdownicon" *ngIf="paginatorDropdownIconTemplate || _paginatorDropdownIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorDropdownIconTemplate || _paginatorDropdownIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="firstpagelinkicon" *ngIf="paginatorFirstPageLinkIconTemplate || _paginatorFirstPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorFirstPageLinkIconTemplate || _paginatorFirstPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="previouspagelinkicon" *ngIf="paginatorPreviousPageLinkIconTemplate || _paginatorPreviousPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorPreviousPageLinkIconTemplate || _paginatorPreviousPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="lastpagelinkicon" *ngIf="paginatorLastPageLinkIconTemplate || _paginatorLastPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorLastPageLinkIconTemplate || _paginatorLastPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="nextpagelinkicon" *ngIf="paginatorNextPageLinkIconTemplate || _paginatorNextPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorNextPageLinkIconTemplate || _paginatorNextPageLinkIconTemplate"></ng-container>
                </ng-template>
            </p-paginator>

            <div #wrapper [ngClass]="cx('tableContainer')" [ngStyle]="sx('tableContainer')">
                <p-scroller
                    #scroller
                    *ngIf="virtualScroll"
                    [items]="processedData"
                    [columns]="columns"
                    [style]="{
                        height: scrollHeight !== 'flex' ? scrollHeight : undefined
                    }"
                    [scrollHeight]="scrollHeight !== 'flex' ? undefined : '100%'"
                    [itemSize]="virtualScrollItemSize || _virtualRowHeight"
                    [step]="rows"
                    [delay]="lazy ? virtualScrollDelay : 0"
                    [inline]="true"
                    [lazy]="lazy"
                    (onLazyLoad)="onLazyItemLoad($event)"
                    [loaderDisabled]="true"
                    [showSpacer]="false"
                    [showLoader]="loadingBodyTemplate || _loadingBodyTemplate"
                    [options]="virtualScrollOptions"
                    [autoSize]="true"
                >
                    <ng-template #content let-items let-scrollerOptions="options">
                        <ng-container
                            *ngTemplateOutlet="
                                buildInTable;
                                context: {
                                    $implicit: items,
                                    options: scrollerOptions
                                }
                            "
                        ></ng-container>
                    </ng-template>
                </p-scroller>
                <ng-container *ngIf="!virtualScroll">
                    <ng-container
                        *ngTemplateOutlet="
                            buildInTable;
                            context: {
                                $implicit: processedData,
                                options: { columns }
                            }
                        "
                    ></ng-container>
                </ng-container>

                <ng-template #buildInTable let-items let-scrollerOptions="options">
                    <table #table role="table" [ngClass]="cx('table')" [class]="tableStyleClass" [style]="tableStyle" [attr.id]="id + '-table'">
                        <ng-container *ngTemplateOutlet="colGroupTemplate || _colGroupTemplate; context: { $implicit: scrollerOptions.columns }"></ng-container>
                        <thead role="rowgroup" #thead [ngClass]="cx('thead')" [ngStyle]="sx('thead')">
                            <ng-container
                                *ngTemplateOutlet="
                                    headerGroupedTemplate || headerTemplate || _headerTemplate;
                                    context: {
                                        $implicit: scrollerOptions.columns
                                    }
                                "
                            ></ng-container>
                        </thead>
                        <tbody
                            role="rowgroup"
                            [ngClass]="cx('tbody')"
                            *ngIf="frozenValue || frozenBodyTemplate || _frozenBodyTemplate"
                            [value]="frozenValue"
                            [frozenRows]="true"
                            [pTableBody]="scrollerOptions.columns"
                            [pTableBodyTemplate]="frozenBodyTemplate || _frozenBodyTemplate"
                            [frozen]="true"
                        ></tbody>
                        <tbody
                            role="rowgroup"
                            [ngClass]="cx('tbody', scrollerOptions.contentStyleClass)"
                            [style]="scrollerOptions.contentStyle"
                            [value]="dataToRender(scrollerOptions.rows)"
                            [pTableBody]="scrollerOptions.columns"
                            [pTableBodyTemplate]="bodyTemplate || _bodyTemplate"
                            [scrollerOptions]="scrollerOptions"
                        ></tbody>
                        <tbody
                            role="rowgroup"
                            *ngIf="scrollerOptions.spacerStyle"
                            [style]="'height: calc(' + scrollerOptions.spacerStyle.height + ' - ' + scrollerOptions.rows.length * scrollerOptions.itemSize + 'px);'"
                            [ngClass]="cx('virtualScrollerSpacer')"
                        ></tbody>
                        <tfoot role="rowgroup" *ngIf="footerGroupedTemplate || footerTemplate || _footerTemplate || _footerGroupedTemplate" #tfoot [ngClass]="cx('footer')" [ngStyle]="sx('tfoot')">
                            <ng-container
                                *ngTemplateOutlet="
                                    footerGroupedTemplate || footerTemplate || _footerTemplate || _footerGroupedTemplate;
                                    context: {
                                        $implicit: scrollerOptions.columns
                                    }
                                "
                            ></ng-container>
                        </tfoot>
                    </table>
                </ng-template>
            </div>

            <p-paginator
                [rows]="rows"
                [first]="first"
                [totalRecords]="totalRecords"
                [pageLinkSize]="pageLinks"
                [alwaysShow]="alwaysShowPaginator"
                (onPageChange)="onPageChange($event)"
                [rowsPerPageOptions]="rowsPerPageOptions"
                *ngIf="paginator && (paginatorPosition === 'bottom' || paginatorPosition == 'both')"
                [templateLeft]="paginatorLeftTemplate || _paginatorLeftTemplate"
                [templateRight]="paginatorRightTemplate || _paginatorRightTemplate"
                [dropdownAppendTo]="paginatorDropdownAppendTo"
                [dropdownScrollHeight]="paginatorDropdownScrollHeight"
                [currentPageReportTemplate]="currentPageReportTemplate"
                [showFirstLastIcon]="showFirstLastIcon"
                [dropdownItemTemplate]="paginatorDropdownItemTemplate || _paginatorDropdownItemTemplate"
                [showCurrentPageReport]="showCurrentPageReport"
                [showJumpToPageDropdown]="showJumpToPageDropdown"
                [showJumpToPageInput]="showJumpToPageInput"
                [showPageLinks]="showPageLinks"
                [styleClass]="cx('pcPaginator') + ' ' + paginatorStyleClass && paginatorStyleClass"
                [locale]="paginatorLocale"
            >
                <ng-template pTemplate="dropdownicon" *ngIf="paginatorDropdownIconTemplate || _paginatorDropdownIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorDropdownIconTemplate || _paginatorDropdownIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="firstpagelinkicon" *ngIf="paginatorFirstPageLinkIconTemplate || _paginatorFirstPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorFirstPageLinkIconTemplate || _paginatorFirstPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="previouspagelinkicon" *ngIf="paginatorPreviousPageLinkIconTemplate || _paginatorPreviousPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorPreviousPageLinkIconTemplate || _paginatorPreviousPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="lastpagelinkicon" *ngIf="paginatorLastPageLinkIconTemplate || _paginatorLastPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorLastPageLinkIconTemplate || _paginatorLastPageLinkIconTemplate"></ng-container>
                </ng-template>

                <ng-template pTemplate="nextpagelinkicon" *ngIf="paginatorNextPageLinkIconTemplate || _paginatorNextPageLinkIconTemplate">
                    <ng-container *ngTemplateOutlet="paginatorNextPageLinkIconTemplate || _paginatorNextPageLinkIconTemplate"></ng-container>
                </ng-template>
            </p-paginator>

            <div *ngIf="summaryTemplate || _summaryTemplate" [ngClass]="cx('footer')">
                <ng-container *ngTemplateOutlet="summaryTemplate || _summaryTemplate"></ng-container>
            </div>

            <div #resizeHelper [ngClass]="cx('columnResizeIndicator')" style="display:none" *ngIf="resizableColumns"></div>
            <span #reorderIndicatorUp [ngClass]="cx('rowReorderIndicatorUp')" style="display: none;" *ngIf="reorderableColumns">
                <ArrowDownIcon *ngIf="!reorderIndicatorUpIconTemplate && !_reorderIndicatorUpIconTemplate" />
                <ng-template *ngTemplateOutlet="reorderIndicatorUpIconTemplate || _reorderIndicatorUpIconTemplate"></ng-template>
            </span>
            <span #reorderIndicatorDown [ngClass]="cx('rowReorderIndicatorDown')" style="display: none;" *ngIf="reorderableColumns">
                <ArrowUpIcon *ngIf="!reorderIndicatorDownIconTemplate && !_reorderIndicatorDownIconTemplate" />
                <ng-template *ngTemplateOutlet="reorderIndicatorDownIconTemplate || _reorderIndicatorDownIconTemplate"></ng-template>
            </span>
        </div>
    `,
      providers: [TableService, TableStyle],
      changeDetection: ChangeDetectionStrategy.Default,
      encapsulation: ViewEncapsulation.None
    }]
  }],
  propDecorators: {
    frozenColumns: [{
      type: Input
    }],
    frozenValue: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    tableStyle: [{
      type: Input
    }],
    tableStyleClass: [{
      type: Input
    }],
    paginator: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    pageLinks: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    rowsPerPageOptions: [{
      type: Input
    }],
    alwaysShowPaginator: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    paginatorPosition: [{
      type: Input
    }],
    paginatorStyleClass: [{
      type: Input
    }],
    paginatorDropdownAppendTo: [{
      type: Input
    }],
    paginatorDropdownScrollHeight: [{
      type: Input
    }],
    currentPageReportTemplate: [{
      type: Input
    }],
    showCurrentPageReport: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showJumpToPageDropdown: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showJumpToPageInput: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showFirstLastIcon: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showPageLinks: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    defaultSortOrder: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    sortMode: [{
      type: Input
    }],
    resetPageOnSort: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    selectionMode: [{
      type: Input
    }],
    selectionPageOnly: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    contextMenuSelection: [{
      type: Input
    }],
    contextMenuSelectionChange: [{
      type: Output
    }],
    contextMenuSelectionMode: [{
      type: Input
    }],
    dataKey: [{
      type: Input
    }],
    metaKeySelection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    rowSelectable: [{
      type: Input
    }],
    rowTrackBy: [{
      type: Input
    }],
    lazy: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    lazyLoadOnInit: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    compareSelectionBy: [{
      type: Input
    }],
    csvSeparator: [{
      type: Input
    }],
    exportFilename: [{
      type: Input
    }],
    filters: [{
      type: Input
    }],
    globalFilterFields: [{
      type: Input
    }],
    filterDelay: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    filterLocale: [{
      type: Input
    }],
    expandedRowKeys: [{
      type: Input
    }],
    editingRowKeys: [{
      type: Input
    }],
    rowExpandMode: [{
      type: Input
    }],
    scrollable: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    scrollDirection: [{
      type: Input
    }],
    rowGroupMode: [{
      type: Input
    }],
    scrollHeight: [{
      type: Input
    }],
    virtualScroll: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    virtualScrollItemSize: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    virtualScrollOptions: [{
      type: Input
    }],
    virtualScrollDelay: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    frozenWidth: [{
      type: Input
    }],
    responsive: [{
      type: Input
    }],
    contextMenu: [{
      type: Input
    }],
    resizableColumns: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    columnResizeMode: [{
      type: Input
    }],
    reorderableColumns: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    loading: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    loadingIcon: [{
      type: Input
    }],
    showLoader: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    rowHover: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    customSort: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showInitialSortBadge: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    autoLayout: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    exportFunction: [{
      type: Input
    }],
    exportHeader: [{
      type: Input
    }],
    stateKey: [{
      type: Input
    }],
    stateStorage: [{
      type: Input
    }],
    editMode: [{
      type: Input
    }],
    groupRowsBy: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    showGridlines: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    stripedRows: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    groupRowsByOrder: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    responsiveLayout: [{
      type: Input
    }],
    breakpoint: [{
      type: Input
    }],
    paginatorLocale: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    columns: [{
      type: Input
    }],
    first: [{
      type: Input
    }],
    rows: [{
      type: Input
    }],
    totalRecords: [{
      type: Input
    }],
    sortField: [{
      type: Input
    }],
    sortOrder: [{
      type: Input
    }],
    multiSortMeta: [{
      type: Input
    }],
    selection: [{
      type: Input
    }],
    virtualRowHeight: [{
      type: Input
    }],
    selectAll: [{
      type: Input
    }],
    selectAllChange: [{
      type: Output
    }],
    selectionChange: [{
      type: Output
    }],
    onRowSelect: [{
      type: Output
    }],
    onRowUnselect: [{
      type: Output
    }],
    onPage: [{
      type: Output
    }],
    onSort: [{
      type: Output
    }],
    onFilter: [{
      type: Output
    }],
    onLazyLoad: [{
      type: Output
    }],
    onRowExpand: [{
      type: Output
    }],
    onRowCollapse: [{
      type: Output
    }],
    onContextMenuSelect: [{
      type: Output
    }],
    onColResize: [{
      type: Output
    }],
    onColReorder: [{
      type: Output
    }],
    onRowReorder: [{
      type: Output
    }],
    onEditInit: [{
      type: Output
    }],
    onEditComplete: [{
      type: Output
    }],
    onEditCancel: [{
      type: Output
    }],
    onHeaderCheckboxToggle: [{
      type: Output
    }],
    sortFunction: [{
      type: Output
    }],
    firstChange: [{
      type: Output
    }],
    rowsChange: [{
      type: Output
    }],
    onStateSave: [{
      type: Output
    }],
    onStateRestore: [{
      type: Output
    }],
    containerViewChild: [{
      type: ViewChild,
      args: ['container']
    }],
    resizeHelperViewChild: [{
      type: ViewChild,
      args: ['resizeHelper']
    }],
    reorderIndicatorUpViewChild: [{
      type: ViewChild,
      args: ['reorderIndicatorUp']
    }],
    reorderIndicatorDownViewChild: [{
      type: ViewChild,
      args: ['reorderIndicatorDown']
    }],
    wrapperViewChild: [{
      type: ViewChild,
      args: ['wrapper']
    }],
    tableViewChild: [{
      type: ViewChild,
      args: ['table']
    }],
    tableHeaderViewChild: [{
      type: ViewChild,
      args: ['thead']
    }],
    tableFooterViewChild: [{
      type: ViewChild,
      args: ['tfoot']
    }],
    scroller: [{
      type: ViewChild,
      args: ['scroller']
    }],
    _templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    _headerTemplate: [{
      type: ContentChild,
      args: ['header', {
        descendants: false
      }]
    }],
    _headerGroupedTemplate: [{
      type: ContentChild,
      args: ['headergrouped', {
        descendants: false
      }]
    }],
    _bodyTemplate: [{
      type: ContentChild,
      args: ['body', {
        descendants: false
      }]
    }],
    _loadingBodyTemplate: [{
      type: ContentChild,
      args: ['loadingbody', {
        descendants: false
      }]
    }],
    _captionTemplate: [{
      type: ContentChild,
      args: ['caption', {
        descendants: false
      }]
    }],
    _footerTemplate: [{
      type: ContentChild,
      args: ['footer', {
        descendants: false
      }]
    }],
    _footerGroupedTemplate: [{
      type: ContentChild,
      args: ['footergrouped', {
        descendants: false
      }]
    }],
    _summaryTemplate: [{
      type: ContentChild,
      args: ['summary', {
        descendants: false
      }]
    }],
    _colGroupTemplate: [{
      type: ContentChild,
      args: ['colgroup', {
        descendants: false
      }]
    }],
    _expandedRowTemplate: [{
      type: ContentChild,
      args: ['expandedrow', {
        descendants: false
      }]
    }],
    _groupHeaderTemplate: [{
      type: ContentChild,
      args: ['groupheader', {
        descendants: false
      }]
    }],
    _groupFooterTemplate: [{
      type: ContentChild,
      args: ['groupfooter', {
        descendants: false
      }]
    }],
    _frozenExpandedRowTemplate: [{
      type: ContentChild,
      args: ['frozenexpandedrow', {
        descendants: false
      }]
    }],
    _frozenHeaderTemplate: [{
      type: ContentChild,
      args: ['frozenheader', {
        descendants: false
      }]
    }],
    _frozenBodyTemplate: [{
      type: ContentChild,
      args: ['frozenbody', {
        descendants: false
      }]
    }],
    _frozenFooterTemplate: [{
      type: ContentChild,
      args: ['frozenfooter', {
        descendants: false
      }]
    }],
    _frozenColGroupTemplate: [{
      type: ContentChild,
      args: ['frozencolgroup', {
        descendants: false
      }]
    }],
    _emptyMessageTemplate: [{
      type: ContentChild,
      args: ['emptymessage', {
        descendants: false
      }]
    }],
    _paginatorLeftTemplate: [{
      type: ContentChild,
      args: ['paginatorleft', {
        descendants: false
      }]
    }],
    _paginatorRightTemplate: [{
      type: ContentChild,
      args: ['paginatorright', {
        descendants: false
      }]
    }],
    _paginatorDropdownItemTemplate: [{
      type: ContentChild,
      args: ['paginatordropdownitem', {
        descendants: false
      }]
    }],
    _loadingIconTemplate: [{
      type: ContentChild,
      args: ['loadingicon', {
        descendants: false
      }]
    }],
    _reorderIndicatorUpIconTemplate: [{
      type: ContentChild,
      args: ['reorderindicatorupicon', {
        descendants: false
      }]
    }],
    _reorderIndicatorDownIconTemplate: [{
      type: ContentChild,
      args: ['reorderindicatordownicon', {
        descendants: false
      }]
    }],
    _sortIconTemplate: [{
      type: ContentChild,
      args: ['sorticon', {
        descendants: false
      }]
    }],
    _checkboxIconTemplate: [{
      type: ContentChild,
      args: ['checkboxicon', {
        descendants: false
      }]
    }],
    _headerCheckboxIconTemplate: [{
      type: ContentChild,
      args: ['headercheckboxicon', {
        descendants: false
      }]
    }],
    _paginatorDropdownIconTemplate: [{
      type: ContentChild,
      args: ['paginatordropdownicon', {
        descendants: false
      }]
    }],
    _paginatorFirstPageLinkIconTemplate: [{
      type: ContentChild,
      args: ['paginatorfirstpagelinkicon', {
        descendants: false
      }]
    }],
    _paginatorLastPageLinkIconTemplate: [{
      type: ContentChild,
      args: ['paginatorlastpagelinkicon', {
        descendants: false
      }]
    }],
    _paginatorPreviousPageLinkIconTemplate: [{
      type: ContentChild,
      args: ['paginatorpreviouspagelinkicon', {
        descendants: false
      }]
    }],
    _paginatorNextPageLinkIconTemplate: [{
      type: ContentChild,
      args: ['paginatornextpagelinkicon', {
        descendants: false
      }]
    }]
  }
});
class TableBody {
  dt;
  tableService;
  cd;
  el;
  columns;
  template;
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    if (this.frozenRows) {
      this.updateFrozenRowStickyPosition();
    }
    if (this.dt.scrollable && this.dt.rowGroupMode === 'subheader') {
      this.updateFrozenRowGroupHeaderStickyPosition();
    }
  }
  frozen;
  frozenRows;
  scrollerOptions;
  subscription;
  _value;
  ngAfterViewInit() {
    if (this.frozenRows) {
      this.updateFrozenRowStickyPosition();
    }
    if (this.dt.scrollable && this.dt.rowGroupMode === 'subheader') {
      this.updateFrozenRowGroupHeaderStickyPosition();
    }
  }
  constructor(dt, tableService, cd, el) {
    this.dt = dt;
    this.tableService = tableService;
    this.cd = cd;
    this.el = el;
    this.subscription = this.dt.tableService.valueSource$.subscribe(() => {
      if (this.dt.virtualScroll) {
        this.cd.detectChanges();
      }
    });
  }
  shouldRenderRowGroupHeader(value, rowData, i) {
    let currentRowFieldData = ObjectUtils.resolveFieldData(rowData, this.dt.groupRowsBy);
    let prevRowData = value[i - this.dt._first - 1];
    if (prevRowData) {
      let previousRowFieldData = ObjectUtils.resolveFieldData(prevRowData, this.dt.groupRowsBy);
      return currentRowFieldData !== previousRowFieldData;
    } else {
      return true;
    }
  }
  shouldRenderRowGroupFooter(value, rowData, i) {
    let currentRowFieldData = ObjectUtils.resolveFieldData(rowData, this.dt.groupRowsBy);
    let nextRowData = value[i - this.dt._first + 1];
    if (nextRowData) {
      let nextRowFieldData = ObjectUtils.resolveFieldData(nextRowData, this.dt.groupRowsBy);
      return currentRowFieldData !== nextRowFieldData;
    } else {
      return true;
    }
  }
  shouldRenderRowspan(value, rowData, i) {
    let currentRowFieldData = ObjectUtils.resolveFieldData(rowData, this.dt.groupRowsBy);
    let prevRowData = value[i - 1];
    if (prevRowData) {
      let previousRowFieldData = ObjectUtils.resolveFieldData(prevRowData, this.dt.groupRowsBy);
      return currentRowFieldData !== previousRowFieldData;
    } else {
      return true;
    }
  }
  calculateRowGroupSize(value, rowData, index) {
    let currentRowFieldData = ObjectUtils.resolveFieldData(rowData, this.dt.groupRowsBy);
    let nextRowFieldData = currentRowFieldData;
    let groupRowSpan = 0;
    while (currentRowFieldData === nextRowFieldData) {
      groupRowSpan++;
      let nextRowData = value[++index];
      if (nextRowData) {
        nextRowFieldData = ObjectUtils.resolveFieldData(nextRowData, this.dt.groupRowsBy);
      } else {
        break;
      }
    }
    return groupRowSpan === 1 ? null : groupRowSpan;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  updateFrozenRowStickyPosition() {
    this.el.nativeElement.style.top = DomHandler.getOuterHeight(this.el.nativeElement.previousElementSibling) + 'px';
  }
  updateFrozenRowGroupHeaderStickyPosition() {
    if (this.el.nativeElement.previousElementSibling) {
      let tableHeaderHeight = DomHandler.getOuterHeight(this.el.nativeElement.previousElementSibling);
      this.dt.rowGroupHeaderStyleObject.top = tableHeaderHeight + 'px';
    }
  }
  getScrollerOption(option, options) {
    if (this.dt.virtualScroll) {
      options = options || this.scrollerOptions;
      return options ? options[option] : null;
    }
    return null;
  }
  getRowIndex(rowIndex) {
    const index = this.dt.paginator ? this.dt.first + rowIndex : rowIndex;
    const getItemOptions = this.getScrollerOption('getItemOptions');
    return getItemOptions ? getItemOptions(index).index : index;
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableBody,
    deps: [{
      token: Table
    }, {
      token: TableService
    }, {
      token: i0.ChangeDetectorRef
    }, {
      token: i0.ElementRef
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: TableBody,
    isStandalone: false,
    selector: "[pTableBody]",
    inputs: {
      columns: ["pTableBody", "columns"],
      template: ["pTableBodyTemplate", "template"],
      value: "value",
      frozen: ["frozen", "frozen", booleanAttribute],
      frozenRows: ["frozenRows", "frozenRows", booleanAttribute],
      scrollerOptions: "scrollerOptions"
    },
    ngImport: i0,
    template: `
        <ng-container *ngIf="!dt.expandedRowTemplate && !dt._expandedRowTemplate">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="value" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container *ngIf="(dt.groupHeaderTemplate || dt._groupHeaderTemplate) && !dt.virtualScroll && dt.rowGroupMode === 'subheader' && shouldRenderRowGroupHeader(value, rowData, getRowIndex(rowIndex))" role="row">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.groupHeaderTemplate || dt._groupHeaderTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="dt.rowGroupMode !== 'rowspan'">
                    <ng-container
                        *ngTemplateOutlet="
                            rowData ? template : dt.loadingBodyTemplate || dt._loadingBodyTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="dt.rowGroupMode === 'rowspan'">
                    <ng-container
                        *ngTemplateOutlet="
                            rowData ? template : dt.loadingBodyTemplate || dt._loadingBodyTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen,
                                rowgroup: shouldRenderRowspan(value, rowData, rowIndex),
                                rowspan: calculateRowGroupSize(value, rowData, rowIndex)
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="(dt.groupFooterTemplate || dt._groupFooterTemplate) && !dt.virtualScroll && dt.rowGroupMode === 'subheader' && shouldRenderRowGroupFooter(value, rowData, getRowIndex(rowIndex))" role="row">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.groupFooterTemplate || dt._groupFooterTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="(dt.expandedRowTemplate || dt._expandedRowTemplate) && !(frozen && (dt.frozenExpandedRowTemplate || dt._frozenExpandedRowTemplate))">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="value" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container *ngIf="!(dt.groupHeaderTemplate && dt._groupHeaderTemplate)">
                    <ng-container
                        *ngTemplateOutlet="
                            template;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                expanded: dt.isRowExpanded(rowData),
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="(dt.groupHeaderTemplate || dt._groupHeaderTemplate) && dt.rowGroupMode === 'subheader' && shouldRenderRowGroupHeader(value, rowData, getRowIndex(rowIndex))" role="row">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.groupHeaderTemplate || dt._groupHeaderTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                expanded: dt.isRowExpanded(rowData),
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="dt.isRowExpanded(rowData)">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.expandedRowTemplate || dt._expandedRowTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                    <ng-container *ngIf="(dt.groupFooterTemplate || dt._groupFooterTemplate) && dt.rowGroupMode === 'subheader' && shouldRenderRowGroupFooter(value, rowData, getRowIndex(rowIndex))" role="row">
                        <ng-container
                            *ngTemplateOutlet="
                                dt.groupFooterTemplate || dt._groupFooterTemplate;
                                context: {
                                    $implicit: rowData,
                                    rowIndex: getRowIndex(rowIndex),
                                    columns: columns,
                                    expanded: dt.isRowExpanded(rowData),
                                    editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                    frozen: frozen
                                }
                            "
                        ></ng-container>
                    </ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="(dt.frozenExpandedRowTemplate || dt._frozenExpandedRowTemplate) && frozen">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="value" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container
                    *ngTemplateOutlet="
                        template;
                        context: {
                            $implicit: rowData,
                            rowIndex: getRowIndex(rowIndex),
                            columns: columns,
                            expanded: dt.isRowExpanded(rowData),
                            editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                            frozen: frozen
                        }
                    "
                ></ng-container>
                <ng-container *ngIf="dt.isRowExpanded(rowData)">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.frozenExpandedRowTemplate || dt._frozenExpandedRowTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="dt.loading">
            <ng-container *ngTemplateOutlet="dt.loadingBodyTemplate || dt._loadingBodyTemplate; context: { $implicit: columns, frozen: frozen }"></ng-container>
        </ng-container>
        <ng-container *ngIf="dt.isEmpty() && !dt.loading">
            <ng-container *ngTemplateOutlet="dt.emptyMessageTemplate || dt._emptyMessageTemplate; context: { $implicit: columns, frozen: frozen }"></ng-container>
        </ng-container>
    `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i1.NgForOf,
      selector: "[ngFor][ngForOf]",
      inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }],
    changeDetection: i0.ChangeDetectionStrategy.Default,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TableBody,
  decorators: [{
    type: Component,
    args: [{
      selector: '[pTableBody]',
      standalone: false,
      template: `
        <ng-container *ngIf="!dt.expandedRowTemplate && !dt._expandedRowTemplate">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="value" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container *ngIf="(dt.groupHeaderTemplate || dt._groupHeaderTemplate) && !dt.virtualScroll && dt.rowGroupMode === 'subheader' && shouldRenderRowGroupHeader(value, rowData, getRowIndex(rowIndex))" role="row">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.groupHeaderTemplate || dt._groupHeaderTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="dt.rowGroupMode !== 'rowspan'">
                    <ng-container
                        *ngTemplateOutlet="
                            rowData ? template : dt.loadingBodyTemplate || dt._loadingBodyTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="dt.rowGroupMode === 'rowspan'">
                    <ng-container
                        *ngTemplateOutlet="
                            rowData ? template : dt.loadingBodyTemplate || dt._loadingBodyTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen,
                                rowgroup: shouldRenderRowspan(value, rowData, rowIndex),
                                rowspan: calculateRowGroupSize(value, rowData, rowIndex)
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="(dt.groupFooterTemplate || dt._groupFooterTemplate) && !dt.virtualScroll && dt.rowGroupMode === 'subheader' && shouldRenderRowGroupFooter(value, rowData, getRowIndex(rowIndex))" role="row">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.groupFooterTemplate || dt._groupFooterTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="(dt.expandedRowTemplate || dt._expandedRowTemplate) && !(frozen && (dt.frozenExpandedRowTemplate || dt._frozenExpandedRowTemplate))">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="value" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container *ngIf="!(dt.groupHeaderTemplate && dt._groupHeaderTemplate)">
                    <ng-container
                        *ngTemplateOutlet="
                            template;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                expanded: dt.isRowExpanded(rowData),
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="(dt.groupHeaderTemplate || dt._groupHeaderTemplate) && dt.rowGroupMode === 'subheader' && shouldRenderRowGroupHeader(value, rowData, getRowIndex(rowIndex))" role="row">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.groupHeaderTemplate || dt._groupHeaderTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                expanded: dt.isRowExpanded(rowData),
                                editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
                <ng-container *ngIf="dt.isRowExpanded(rowData)">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.expandedRowTemplate || dt._expandedRowTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                    <ng-container *ngIf="(dt.groupFooterTemplate || dt._groupFooterTemplate) && dt.rowGroupMode === 'subheader' && shouldRenderRowGroupFooter(value, rowData, getRowIndex(rowIndex))" role="row">
                        <ng-container
                            *ngTemplateOutlet="
                                dt.groupFooterTemplate || dt._groupFooterTemplate;
                                context: {
                                    $implicit: rowData,
                                    rowIndex: getRowIndex(rowIndex),
                                    columns: columns,
                                    expanded: dt.isRowExpanded(rowData),
                                    editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                                    frozen: frozen
                                }
                            "
                        ></ng-container>
                    </ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="(dt.frozenExpandedRowTemplate || dt._frozenExpandedRowTemplate) && frozen">
            <ng-template ngFor let-rowData let-rowIndex="index" [ngForOf]="value" [ngForTrackBy]="dt.rowTrackBy">
                <ng-container
                    *ngTemplateOutlet="
                        template;
                        context: {
                            $implicit: rowData,
                            rowIndex: getRowIndex(rowIndex),
                            columns: columns,
                            expanded: dt.isRowExpanded(rowData),
                            editing: dt.editMode === 'row' && dt.isRowEditing(rowData),
                            frozen: frozen
                        }
                    "
                ></ng-container>
                <ng-container *ngIf="dt.isRowExpanded(rowData)">
                    <ng-container
                        *ngTemplateOutlet="
                            dt.frozenExpandedRowTemplate || dt._frozenExpandedRowTemplate;
                            context: {
                                $implicit: rowData,
                                rowIndex: getRowIndex(rowIndex),
                                columns: columns,
                                frozen: frozen
                            }
                        "
                    ></ng-container>
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="dt.loading">
            <ng-container *ngTemplateOutlet="dt.loadingBodyTemplate || dt._loadingBodyTemplate; context: { $implicit: columns, frozen: frozen }"></ng-container>
        </ng-container>
        <ng-container *ngIf="dt.isEmpty() && !dt.loading">
            <ng-container *ngTemplateOutlet="dt.emptyMessageTemplate || dt._emptyMessageTemplate; context: { $implicit: columns, frozen: frozen }"></ng-container>
        </ng-container>
    `,
      changeDetection: ChangeDetectionStrategy.Default,
      encapsulation: ViewEncapsulation.None
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: TableService
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }],
  propDecorators: {
    columns: [{
      type: Input,
      args: ['pTableBody']
    }],
    template: [{
      type: Input,
      args: ['pTableBodyTemplate']
    }],
    value: [{
      type: Input
    }],
    frozen: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    frozenRows: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    scrollerOptions: [{
      type: Input
    }]
  }
});
class RowGroupHeader {
  dt;
  constructor(dt) {
    this.dt = dt;
  }
  get getFrozenRowGroupHeaderStickyPosition() {
    return this.dt.rowGroupHeaderStyleObject ? this.dt.rowGroupHeaderStyleObject.top : '';
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: RowGroupHeader,
    deps: [{
      token: Table
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: RowGroupHeader,
    isStandalone: false,
    selector: "[pRowGroupHeader]",
    host: {
      properties: {
        "style.top": "getFrozenRowGroupHeaderStickyPosition"
      },
      classAttribute: "p-datatable-row-group-header"
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: RowGroupHeader,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pRowGroupHeader]',
      standalone: false,
      host: {
        class: 'p-datatable-row-group-header',
        '[style.top]': 'getFrozenRowGroupHeaderStickyPosition'
      }
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }]
});
class FrozenColumn {
  el;
  zone;
  get frozen() {
    return this._frozen;
  }
  set frozen(val) {
    this._frozen = val;
    Promise.resolve(null).then(() => this.updateStickyPosition());
  }
  alignFrozen = 'left';
  constructor(el, zone) {
    this.el = el;
    this.zone = zone;
  }
  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.recalculateColumns();
      }, 1000);
    });
  }
  recalculateColumns() {
    const siblings = DomHandler.siblings(this.el.nativeElement);
    const index = DomHandler.index(this.el.nativeElement);
    const time = (siblings.length - index + 1) * 50;
    setTimeout(() => {
      this.updateStickyPosition();
    }, time);
  }
  _frozen = true;
  updateStickyPosition() {
    if (this._frozen) {
      if (this.alignFrozen === 'right') {
        let right = 0;
        let next = this.el.nativeElement.nextElementSibling;
        if (next) {
          right = DomHandler.getOuterWidth(next) + (parseFloat(next.style.right) || 0);
        }
        this.el.nativeElement.style.right = right + 'px';
      } else {
        let left = 0;
        let prev = this.el.nativeElement.previousElementSibling;
        if (prev) {
          left = DomHandler.getOuterWidth(prev) + (parseFloat(prev.style.left) || 0);
        }
        this.el.nativeElement.style.left = left + 'px';
      }
      const filterRow = this.el.nativeElement?.parentElement?.nextElementSibling;
      if (filterRow) {
        let index = DomHandler.index(this.el.nativeElement);
        if (filterRow.children && filterRow.children[index]) {
          filterRow.children[index].style.left = this.el.nativeElement.style.left;
          filterRow.children[index].style.right = this.el.nativeElement.style.right;
        }
      }
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: FrozenColumn,
    deps: [{
      token: i0.ElementRef
    }, {
      token: i0.NgZone
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: FrozenColumn,
    isStandalone: false,
    selector: "[pFrozenColumn]",
    inputs: {
      frozen: "frozen",
      alignFrozen: "alignFrozen"
    },
    host: {
      listeners: {
        "window:resize": "recalculateColumns($event)"
      },
      properties: {
        "class.p-datatable-frozen-column": "frozen",
        "class.p-datatable-frozen-column-left": "alignFrozen === \"left\""
      }
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: FrozenColumn,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pFrozenColumn]',
      standalone: false,
      host: {
        '[class.p-datatable-frozen-column]': 'frozen',
        '[class.p-datatable-frozen-column-left]': 'alignFrozen === "left"'
      }
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }],
  propDecorators: {
    frozen: [{
      type: Input
    }],
    alignFrozen: [{
      type: Input
    }],
    recalculateColumns: [{
      type: HostListener,
      args: ['window:resize', ['$event']]
    }]
  }
});
class SortableColumn {
  dt;
  field;
  pSortableColumnDisabled;
  sorted;
  sortOrder;
  subscription;
  constructor(dt) {
    this.dt = dt;
    if (this.isEnabled()) {
      this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
        this.updateSortState();
      });
    }
  }
  ngOnInit() {
    if (this.isEnabled()) {
      this.updateSortState();
    }
  }
  updateSortState() {
    let sorted = false;
    let sortOrder = 0;
    if (this.dt.sortMode === 'single') {
      sorted = this.dt.isSorted(this.field);
      sortOrder = this.dt.sortOrder;
    } else if (this.dt.sortMode === 'multiple') {
      const sortMeta = this.dt.getSortMeta(this.field);
      sorted = !!sortMeta;
      sortOrder = sortMeta ? sortMeta.order : 0;
    }
    this.sorted = sorted;
    this.sortOrder = sorted ? sortOrder === 1 ? 'ascending' : 'descending' : 'none';
  }
  onClick(event) {
    if (this.isEnabled() && !this.isFilterElement(event.target)) {
      this.updateSortState();
      this.dt.sort({
        originalEvent: event,
        field: this.field
      });
      DomHandler.clearSelection();
    }
  }
  onEnterKey(event) {
    this.onClick(event);
    event.preventDefault();
  }
  isEnabled() {
    return this.pSortableColumnDisabled !== true;
  }
  isFilterElement(element) {
    return this.isFilterElementIconOrButton(element) || this.isFilterElementIconOrButton(element?.parentElement?.parentElement);
  }
  isFilterElementIconOrButton(element) {
    return DomHandler.hasClass(element, 'pi-filter-icon') || DomHandler.hasClass(element, 'p-column-filter-menu-button');
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: SortableColumn,
    deps: [{
      token: Table
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: SortableColumn,
    isStandalone: false,
    selector: "[pSortableColumn]",
    inputs: {
      field: ["pSortableColumn", "field"],
      pSortableColumnDisabled: ["pSortableColumnDisabled", "pSortableColumnDisabled", booleanAttribute]
    },
    host: {
      listeners: {
        "click": "onClick($event)",
        "keydown.space": "onEnterKey($event)",
        "keydown.enter": "onEnterKey($event)"
      },
      properties: {
        "class.p-datatable-sortable-column": "isEnabled()",
        "class.p-datatable-column-sorted": "sorted",
        "attr.tabindex": "isEnabled() ? \"0\" : null",
        "attr.role": "\"columnheader\"",
        "attr.aria-sort": "sortOrder"
      }
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: SortableColumn,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pSortableColumn]',
      standalone: false,
      host: {
        '[class.p-datatable-sortable-column]': 'isEnabled()',
        '[class.p-datatable-column-sorted]': 'sorted',
        '[attr.tabindex]': 'isEnabled() ? "0" : null',
        '[attr.role]': '"columnheader"',
        '[attr.aria-sort]': 'sortOrder'
      }
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }],
  propDecorators: {
    field: [{
      type: Input,
      args: ['pSortableColumn']
    }],
    pSortableColumnDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onEnterKey: [{
      type: HostListener,
      args: ['keydown.space', ['$event']]
    }, {
      type: HostListener,
      args: ['keydown.enter', ['$event']]
    }]
  }
});
class SortIcon {
  dt;
  cd;
  field;
  subscription;
  sortOrder;
  constructor(dt, cd) {
    this.dt = dt;
    this.cd = cd;
    this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
      this.updateSortState();
    });
  }
  ngOnInit() {
    this.updateSortState();
  }
  onClick(event) {
    event.preventDefault();
  }
  updateSortState() {
    if (this.dt.sortMode === 'single') {
      this.sortOrder = this.dt.isSorted(this.field) ? this.dt.sortOrder : 0;
    } else if (this.dt.sortMode === 'multiple') {
      let sortMeta = this.dt.getSortMeta(this.field);
      this.sortOrder = sortMeta ? sortMeta.order : 0;
    }
    this.cd.markForCheck();
  }
  getMultiSortMetaIndex() {
    let multiSortMeta = this.dt._multiSortMeta;
    let index = -1;
    if (multiSortMeta && this.dt.sortMode === 'multiple' && this.dt.showInitialSortBadge && multiSortMeta.length > 1) {
      for (let i = 0; i < multiSortMeta.length; i++) {
        let meta = multiSortMeta[i];
        if (meta.field === this.field || meta.field === this.field) {
          index = i;
          break;
        }
      }
    }
    return index;
  }
  getBadgeValue() {
    let index = this.getMultiSortMetaIndex();
    return this.dt.groupRowsBy && index > -1 ? index : index + 1;
  }
  isMultiSorted() {
    return this.dt.sortMode === 'multiple' && this.getMultiSortMetaIndex() > -1;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: SortIcon,
    deps: [{
      token: Table
    }, {
      token: i0.ChangeDetectorRef
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: SortIcon,
    isStandalone: false,
    selector: "p-sortIcon",
    inputs: {
      field: "field"
    },
    ngImport: i0,
    template: `
        <ng-container *ngIf="!(dt.sortIconTemplate || dt._sortIconTemplate)">
            <SortAltIcon [styleClass]="'p-sortable-column-icon'" *ngIf="sortOrder === 0" />
            <SortAmountUpAltIcon [styleClass]="'p-sortable-column-icon'" *ngIf="sortOrder === 1" />
            <SortAmountDownIcon [styleClass]="'p-sortable-column-icon'" *ngIf="sortOrder === -1" />
        </ng-container>
        <span *ngIf="dt.sortIconTemplate || dt._sortIconTemplate" class="p-sortable-column-icon">
            <ng-template *ngTemplateOutlet="dt.sortIconTemplate || dt._sortIconTemplate; context: { $implicit: sortOrder }"></ng-template>
        </span>
        <span *ngIf="isMultiSorted()" class="p-sortable-column-badge">{{ getBadgeValue() }}</span>
    `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i0.forwardRef(() => i1.NgIf),
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i1.NgTemplateOutlet),
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "component",
      type: i0.forwardRef(() => SortAltIcon),
      selector: "SortAltIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => SortAmountUpAltIcon),
      selector: "SortAmountUpAltIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => SortAmountDownIcon),
      selector: "SortAmountDownIcon"
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: SortIcon,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-sortIcon',
      standalone: false,
      template: `
        <ng-container *ngIf="!(dt.sortIconTemplate || dt._sortIconTemplate)">
            <SortAltIcon [styleClass]="'p-sortable-column-icon'" *ngIf="sortOrder === 0" />
            <SortAmountUpAltIcon [styleClass]="'p-sortable-column-icon'" *ngIf="sortOrder === 1" />
            <SortAmountDownIcon [styleClass]="'p-sortable-column-icon'" *ngIf="sortOrder === -1" />
        </ng-container>
        <span *ngIf="dt.sortIconTemplate || dt._sortIconTemplate" class="p-sortable-column-icon">
            <ng-template *ngTemplateOutlet="dt.sortIconTemplate || dt._sortIconTemplate; context: { $implicit: sortOrder }"></ng-template>
        </span>
        <span *ngIf="isMultiSorted()" class="p-sortable-column-badge">{{ getBadgeValue() }}</span>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: i0.ChangeDetectorRef
  }],
  propDecorators: {
    field: [{
      type: Input
    }]
  }
});
class SelectableRow {
  dt;
  tableService;
  el;
  data;
  index;
  pSelectableRowDisabled;
  selected;
  subscription;
  constructor(dt, tableService, el) {
    this.dt = dt;
    this.tableService = tableService;
    this.el = el;
    if (this.isEnabled()) {
      this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
        this.selected = this.dt.isSelected(this.data);
      });
    }
  }
  setRowTabIndex() {
    if (this.dt.selectionMode === 'single' || this.dt.selectionMode === 'multiple') {
      return !this.dt.selection ? 0 : this.dt.anchorRowIndex === this.index ? 0 : -1;
    }
  }
  ngOnInit() {
    if (this.isEnabled()) {
      this.selected = this.dt.isSelected(this.data);
    }
  }
  onClick(event) {
    if (this.isEnabled()) {
      this.dt.handleRowClick({
        originalEvent: event,
        rowData: this.data,
        rowIndex: this.index
      });
    }
  }
  onTouchEnd(event) {
    if (this.isEnabled()) {
      this.dt.handleRowTouchEnd(event);
    }
  }
  onKeyDown(event) {
    switch (event.code) {
      case 'ArrowDown':
        this.onArrowDownKey(event);
        break;
      case 'ArrowUp':
        this.onArrowUpKey(event);
        break;
      case 'Home':
        this.onHomeKey(event);
        break;
      case 'End':
        this.onEndKey(event);
        break;
      case 'Space':
        this.onSpaceKey(event);
        break;
      case 'Enter':
        this.onEnterKey(event);
        break;
      default:
        if (event.code === 'KeyA' && (event.metaKey || event.ctrlKey) && this.dt.selectionMode === 'multiple') {
          const data = this.dt.dataToRender(this.dt.processedData);
          this.dt.selection = [...data];
          this.dt.selectRange(event, data.length - 1);
          event.preventDefault();
        }
        break;
    }
  }
  onArrowDownKey(event) {
    if (!this.isEnabled()) {
      return;
    }
    const row = event.currentTarget;
    const nextRow = this.findNextSelectableRow(row);
    if (nextRow) {
      nextRow.focus();
    }
    event.preventDefault();
  }
  onArrowUpKey(event) {
    if (!this.isEnabled()) {
      return;
    }
    const row = event.currentTarget;
    const prevRow = this.findPrevSelectableRow(row);
    if (prevRow) {
      prevRow.focus();
    }
    event.preventDefault();
  }
  onEnterKey(event) {
    if (!this.isEnabled()) {
      return;
    }
    this.dt.handleRowClick({
      originalEvent: event,
      rowData: this.data,
      rowIndex: this.index
    });
  }
  onEndKey(event) {
    const lastRow = this.findLastSelectableRow();
    lastRow && this.focusRowChange(this.el.nativeElement, lastRow);
    if (event.ctrlKey && event.shiftKey) {
      const data = this.dt.dataToRender(this.dt.rows);
      const lastSelectableRowIndex = DomHandler.getAttribute(lastRow, 'index');
      this.dt.anchorRowIndex = lastSelectableRowIndex;
      this.dt.selection = data.slice(this.index, data.length);
      this.dt.selectRange(event, this.index);
    }
    event.preventDefault();
  }
  onHomeKey(event) {
    const firstRow = this.findFirstSelectableRow();
    firstRow && this.focusRowChange(this.el.nativeElement, firstRow);
    if (event.ctrlKey && event.shiftKey) {
      const data = this.dt.dataToRender(this.dt.rows);
      const firstSelectableRowIndex = DomHandler.getAttribute(firstRow, 'index');
      this.dt.anchorRowIndex = this.dt.anchorRowIndex || firstSelectableRowIndex;
      this.dt.selection = data.slice(0, this.index + 1);
      this.dt.selectRange(event, this.index);
    }
    event.preventDefault();
  }
  onSpaceKey(event) {
    const isInput = event.target instanceof HTMLInputElement || event.target instanceof HTMLSelectElement || event.target instanceof HTMLTextAreaElement;
    if (isInput) {
      return;
    } else {
      this.onEnterKey(event);
      if (event.shiftKey && this.dt.selection !== null) {
        const data = this.dt.dataToRender(this.dt.rows);
        let index;
        if (ObjectUtils.isNotEmpty(this.dt.selection) && this.dt.selection.length > 0) {
          let firstSelectedRowIndex, lastSelectedRowIndex;
          firstSelectedRowIndex = ObjectUtils.findIndexInList(this.dt.selection[0], data);
          lastSelectedRowIndex = ObjectUtils.findIndexInList(this.dt.selection[this.dt.selection.length - 1], data);
          index = this.index <= firstSelectedRowIndex ? lastSelectedRowIndex : firstSelectedRowIndex;
        } else {
          index = ObjectUtils.findIndexInList(this.dt.selection, data);
        }
        this.dt.anchorRowIndex = index;
        this.dt.selection = index !== this.index ? data.slice(Math.min(index, this.index), Math.max(index, this.index) + 1) : [this.data];
        this.dt.selectRange(event, this.index);
      }
      event.preventDefault();
    }
  }
  focusRowChange(firstFocusableRow, currentFocusedRow) {
    firstFocusableRow.tabIndex = '-1';
    currentFocusedRow.tabIndex = '0';
    DomHandler.focus(currentFocusedRow);
  }
  findLastSelectableRow() {
    const rows = DomHandler.find(this.dt.el.nativeElement, '.p-datatable-selectable-row');
    return rows ? rows[rows.length - 1] : null;
  }
  findFirstSelectableRow() {
    const firstRow = DomHandler.findSingle(this.dt.el.nativeElement, '.p-datatable-selectable-row');
    return firstRow;
  }
  findNextSelectableRow(row) {
    let nextRow = row.nextElementSibling;
    if (nextRow) {
      if (DomHandler.hasClass(nextRow, 'p-selectable-row')) return nextRow;else return this.findNextSelectableRow(nextRow);
    } else {
      return null;
    }
  }
  findPrevSelectableRow(row) {
    let prevRow = row.previousElementSibling;
    if (prevRow) {
      if (DomHandler.hasClass(prevRow, 'p-selectable-row')) return prevRow;else return this.findPrevSelectableRow(prevRow);
    } else {
      return null;
    }
  }
  isEnabled() {
    return this.pSelectableRowDisabled !== true;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: SelectableRow,
    deps: [{
      token: Table
    }, {
      token: TableService
    }, {
      token: i0.ElementRef
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: SelectableRow,
    isStandalone: false,
    selector: "[pSelectableRow]",
    inputs: {
      data: ["pSelectableRow", "data"],
      index: ["pSelectableRowIndex", "index"],
      pSelectableRowDisabled: ["pSelectableRowDisabled", "pSelectableRowDisabled", booleanAttribute]
    },
    host: {
      listeners: {
        "click": "onClick($event)",
        "touchend": "onTouchEnd($event)",
        "keydown": "onKeyDown($event)"
      },
      properties: {
        "class.p-datatable-selectable-row": "isEnabled()",
        "class.p-datatable-row-selected": "selected",
        "attr.tabindex": "setRowTabIndex()",
        "attr.data-p-selectable-row": "true"
      }
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: SelectableRow,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pSelectableRow]',
      standalone: false,
      host: {
        '[class.p-datatable-selectable-row]': 'isEnabled()',
        '[class.p-datatable-row-selected]': 'selected',
        '[attr.tabindex]': 'setRowTabIndex()',
        '[attr.data-p-selectable-row]': 'true'
      }
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: TableService
  }, {
    type: i0.ElementRef
  }],
  propDecorators: {
    data: [{
      type: Input,
      args: ['pSelectableRow']
    }],
    index: [{
      type: Input,
      args: ['pSelectableRowIndex']
    }],
    pSelectableRowDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onTouchEnd: [{
      type: HostListener,
      args: ['touchend', ['$event']]
    }],
    onKeyDown: [{
      type: HostListener,
      args: ['keydown', ['$event']]
    }]
  }
});
class SelectableRowDblClick {
  dt;
  tableService;
  data;
  index;
  pSelectableRowDisabled;
  selected;
  subscription;
  constructor(dt, tableService) {
    this.dt = dt;
    this.tableService = tableService;
    if (this.isEnabled()) {
      this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
        this.selected = this.dt.isSelected(this.data);
      });
    }
  }
  ngOnInit() {
    if (this.isEnabled()) {
      this.selected = this.dt.isSelected(this.data);
    }
  }
  onClick(event) {
    if (this.isEnabled()) {
      this.dt.handleRowClick({
        originalEvent: event,
        rowData: this.data,
        rowIndex: this.index
      });
    }
  }
  isEnabled() {
    return this.pSelectableRowDisabled !== true;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: SelectableRowDblClick,
    deps: [{
      token: Table
    }, {
      token: TableService
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: SelectableRowDblClick,
    isStandalone: false,
    selector: "[pSelectableRowDblClick]",
    inputs: {
      data: ["pSelectableRowDblClick", "data"],
      index: ["pSelectableRowIndex", "index"],
      pSelectableRowDisabled: ["pSelectableRowDisabled", "pSelectableRowDisabled", booleanAttribute]
    },
    host: {
      listeners: {
        "dblclick": "onClick($event)"
      },
      properties: {
        "class.p-selectable-row": "isEnabled()",
        "class.p-highlight": "selected"
      }
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: SelectableRowDblClick,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pSelectableRowDblClick]',
      standalone: false,
      host: {
        '[class.p-selectable-row]': 'isEnabled()',
        '[class.p-highlight]': 'selected'
      }
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: TableService
  }],
  propDecorators: {
    data: [{
      type: Input,
      args: ['pSelectableRowDblClick']
    }],
    index: [{
      type: Input,
      args: ['pSelectableRowIndex']
    }],
    pSelectableRowDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onClick: [{
      type: HostListener,
      args: ['dblclick', ['$event']]
    }]
  }
});
class ContextMenuRow {
  dt;
  tableService;
  el;
  data;
  index;
  pContextMenuRowDisabled;
  selected;
  subscription;
  constructor(dt, tableService, el) {
    this.dt = dt;
    this.tableService = tableService;
    this.el = el;
    if (this.isEnabled()) {
      this.subscription = this.dt.tableService.contextMenuSource$.subscribe(data => {
        this.selected = this.dt.equals(this.data, data);
      });
    }
  }
  onContextMenu(event) {
    if (this.isEnabled()) {
      this.dt.handleRowRightClick({
        originalEvent: event,
        rowData: this.data,
        rowIndex: this.index
      });
      this.el.nativeElement.focus();
      event.preventDefault();
    }
  }
  isEnabled() {
    return this.pContextMenuRowDisabled !== true;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ContextMenuRow,
    deps: [{
      token: Table
    }, {
      token: TableService
    }, {
      token: i0.ElementRef
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: ContextMenuRow,
    isStandalone: false,
    selector: "[pContextMenuRow]",
    inputs: {
      data: ["pContextMenuRow", "data"],
      index: ["pContextMenuRowIndex", "index"],
      pContextMenuRowDisabled: ["pContextMenuRowDisabled", "pContextMenuRowDisabled", booleanAttribute]
    },
    host: {
      listeners: {
        "contextmenu": "onContextMenu($event)"
      },
      properties: {
        "class.p-highlight-contextmenu": "selected",
        "attr.tabindex": "isEnabled() ? 0 : undefined"
      }
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: ContextMenuRow,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pContextMenuRow]',
      standalone: false,
      host: {
        '[class.p-highlight-contextmenu]': 'selected',
        '[attr.tabindex]': 'isEnabled() ? 0 : undefined'
      }
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: TableService
  }, {
    type: i0.ElementRef
  }],
  propDecorators: {
    data: [{
      type: Input,
      args: ['pContextMenuRow']
    }],
    index: [{
      type: Input,
      args: ['pContextMenuRowIndex']
    }],
    pContextMenuRowDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onContextMenu: [{
      type: HostListener,
      args: ['contextmenu', ['$event']]
    }]
  }
});
class RowToggler {
  dt;
  data;
  pRowTogglerDisabled;
  constructor(dt) {
    this.dt = dt;
  }
  onClick(event) {
    if (this.isEnabled()) {
      this.dt.toggleRow(this.data, event);
      event.preventDefault();
    }
  }
  isEnabled() {
    return this.pRowTogglerDisabled !== true;
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: RowToggler,
    deps: [{
      token: Table
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: RowToggler,
    isStandalone: false,
    selector: "[pRowToggler]",
    inputs: {
      data: ["pRowToggler", "data"],
      pRowTogglerDisabled: ["pRowTogglerDisabled", "pRowTogglerDisabled", booleanAttribute]
    },
    host: {
      listeners: {
        "click": "onClick($event)"
      }
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: RowToggler,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pRowToggler]',
      standalone: false
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }],
  propDecorators: {
    data: [{
      type: Input,
      args: ['pRowToggler']
    }],
    pRowTogglerDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  }
});
class ResizableColumn {
  document;
  platformId;
  renderer;
  dt;
  el;
  zone;
  pResizableColumnDisabled;
  resizer;
  resizerMouseDownListener;
  resizerTouchStartListener;
  resizerTouchMoveListener;
  resizerTouchEndListener;
  documentMouseMoveListener;
  documentMouseUpListener;
  constructor(document, platformId, renderer, dt, el, zone) {
    this.document = document;
    this.platformId = platformId;
    this.renderer = renderer;
    this.dt = dt;
    this.el = el;
    this.zone = zone;
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isEnabled()) {
        DomHandler.addClass(this.el.nativeElement, 'p-datatable-resizable-column');
        this.resizer = this.renderer.createElement('span');
        this.renderer.addClass(this.resizer, 'p-datatable-column-resizer');
        this.renderer.appendChild(this.el.nativeElement, this.resizer);
        this.zone.runOutsideAngular(() => {
          this.resizerMouseDownListener = this.renderer.listen(this.resizer, 'mousedown', this.onMouseDown.bind(this));
          this.resizerTouchStartListener = this.renderer.listen(this.resizer, 'touchstart', this.onTouchStart.bind(this));
        });
      }
    }
  }
  bindDocumentEvents() {
    this.zone.runOutsideAngular(() => {
      this.documentMouseMoveListener = this.renderer.listen(this.document, 'mousemove', this.onDocumentMouseMove.bind(this));
      this.documentMouseUpListener = this.renderer.listen(this.document, 'mouseup', this.onDocumentMouseUp.bind(this));
      this.resizerTouchMoveListener = this.renderer.listen(this.resizer, 'touchmove', this.onTouchMove.bind(this));
      this.resizerTouchEndListener = this.renderer.listen(this.resizer, 'touchend', this.onTouchEnd.bind(this));
    });
  }
  unbindDocumentEvents() {
    if (this.documentMouseMoveListener) {
      this.documentMouseMoveListener();
      this.documentMouseMoveListener = null;
    }
    if (this.documentMouseUpListener) {
      this.documentMouseUpListener();
      this.documentMouseUpListener = null;
    }
    if (this.resizerTouchMoveListener) {
      this.resizerTouchMoveListener();
      this.resizerTouchMoveListener = null;
    }
    if (this.resizerTouchEndListener) {
      this.resizerTouchEndListener();
      this.resizerTouchEndListener = null;
    }
  }
  onMouseDown(event) {
    this.dt.onColumnResizeBegin(event);
    this.bindDocumentEvents();
  }
  onTouchStart(event) {
    this.dt.onColumnResizeBegin(event);
    this.bindDocumentEvents();
  }
  onTouchMove(event) {
    this.dt.onColumnResize(event);
  }
  onDocumentMouseMove(event) {
    this.dt.onColumnResize(event);
  }
  onDocumentMouseUp(event) {
    this.dt.onColumnResizeEnd();
    this.unbindDocumentEvents();
  }
  onTouchEnd(event) {
    this.dt.onColumnResizeEnd();
    this.unbindDocumentEvents();
  }
  isEnabled() {
    return this.pResizableColumnDisabled !== true;
  }
  ngOnDestroy() {
    if (this.resizerMouseDownListener) {
      this.resizerMouseDownListener();
      this.resizerMouseDownListener = null;
    }
    this.unbindDocumentEvents();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ResizableColumn,
    deps: [{
      token: DOCUMENT
    }, {
      token: PLATFORM_ID
    }, {
      token: i0.Renderer2
    }, {
      token: Table
    }, {
      token: i0.ElementRef
    }, {
      token: i0.NgZone
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: ResizableColumn,
    isStandalone: false,
    selector: "[pResizableColumn]",
    inputs: {
      pResizableColumnDisabled: ["pResizableColumnDisabled", "pResizableColumnDisabled", booleanAttribute]
    },
    host: {
      classAttribute: "p-datatable-resizable-column"
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: ResizableColumn,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pResizableColumn]',
      standalone: false,
      host: {
        class: 'p-datatable-resizable-column'
      }
    }]
  }],
  ctorParameters: () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: Table
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }],
  propDecorators: {
    pResizableColumnDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }]
  }
});
class ReorderableColumn {
  platformId;
  renderer;
  dt;
  el;
  zone;
  pReorderableColumnDisabled;
  dragStartListener;
  dragOverListener;
  dragEnterListener;
  dragLeaveListener;
  mouseDownListener;
  constructor(platformId, renderer, dt, el, zone) {
    this.platformId = platformId;
    this.renderer = renderer;
    this.dt = dt;
    this.el = el;
    this.zone = zone;
  }
  ngAfterViewInit() {
    if (this.isEnabled()) {
      this.bindEvents();
    }
  }
  bindEvents() {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        this.mouseDownListener = this.renderer.listen(this.el.nativeElement, 'mousedown', this.onMouseDown.bind(this));
        this.dragStartListener = this.renderer.listen(this.el.nativeElement, 'dragstart', this.onDragStart.bind(this));
        this.dragOverListener = this.renderer.listen(this.el.nativeElement, 'dragover', this.onDragOver.bind(this));
        this.dragEnterListener = this.renderer.listen(this.el.nativeElement, 'dragenter', this.onDragEnter.bind(this));
        this.dragLeaveListener = this.renderer.listen(this.el.nativeElement, 'dragleave', this.onDragLeave.bind(this));
      });
    }
  }
  unbindEvents() {
    if (this.mouseDownListener) {
      this.mouseDownListener();
      this.mouseDownListener = null;
    }
    if (this.dragStartListener) {
      this.dragStartListener();
      this.dragStartListener = null;
    }
    if (this.dragOverListener) {
      this.dragOverListener();
      this.dragOverListener = null;
    }
    if (this.dragEnterListener) {
      this.dragEnterListener();
      this.dragEnterListener = null;
    }
    if (this.dragLeaveListener) {
      this.dragLeaveListener();
      this.dragLeaveListener = null;
    }
  }
  onMouseDown(event) {
    if (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA' || DomHandler.hasClass(event.target, 'p-datatable-column-resizer')) this.el.nativeElement.draggable = false;else this.el.nativeElement.draggable = true;
  }
  onDragStart(event) {
    this.dt.onColumnDragStart(event, this.el.nativeElement);
  }
  onDragOver(event) {
    event.preventDefault();
  }
  onDragEnter(event) {
    this.dt.onColumnDragEnter(event, this.el.nativeElement);
  }
  onDragLeave(event) {
    this.dt.onColumnDragLeave(event);
  }
  onDrop(event) {
    if (this.isEnabled()) {
      this.dt.onColumnDrop(event, this.el.nativeElement);
    }
  }
  isEnabled() {
    return this.pReorderableColumnDisabled !== true;
  }
  ngOnDestroy() {
    this.unbindEvents();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ReorderableColumn,
    deps: [{
      token: PLATFORM_ID
    }, {
      token: i0.Renderer2
    }, {
      token: Table
    }, {
      token: i0.ElementRef
    }, {
      token: i0.NgZone
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: ReorderableColumn,
    isStandalone: false,
    selector: "[pReorderableColumn]",
    inputs: {
      pReorderableColumnDisabled: ["pReorderableColumnDisabled", "pReorderableColumnDisabled", booleanAttribute]
    },
    host: {
      listeners: {
        "drop": "onDrop($event)"
      },
      classAttribute: "p-datatable-reorderable-column"
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: ReorderableColumn,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pReorderableColumn]',
      standalone: false,
      host: {
        class: 'p-datatable-reorderable-column'
      }
    }]
  }],
  ctorParameters: () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: Table
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }],
  propDecorators: {
    pReorderableColumnDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onDrop: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }]
  }
});
class EditableColumn {
  dt;
  el;
  zone;
  data;
  field;
  rowIndex;
  pEditableColumnDisabled;
  pFocusCellSelector;
  overlayEventListener;
  constructor(dt, el, zone) {
    this.dt = dt;
    this.el = el;
    this.zone = zone;
  }
  ngOnChanges(changes) {
    if (this.el.nativeElement && !changes.data?.firstChange) {
      this.dt.updateEditingCell(this.el.nativeElement, this.data, this.field, this.rowIndex);
    }
  }
  ngAfterViewInit() {
    if (this.isEnabled()) {
      DomHandler.addClass(this.el.nativeElement, 'p-editable-column');
    }
  }
  onClick(event) {
    if (this.isEnabled()) {
      this.dt.selfClick = true;
      if (this.dt.editingCell) {
        if (this.dt.editingCell !== this.el.nativeElement) {
          if (!this.dt.isEditingCellValid()) {
            return;
          }
          this.closeEditingCell(true, event);
          this.openCell();
        }
      } else {
        this.openCell();
      }
    }
  }
  openCell() {
    this.dt.updateEditingCell(this.el.nativeElement, this.data, this.field, this.rowIndex);
    DomHandler.addClass(this.el.nativeElement, 'p-cell-editing');
    this.dt.onEditInit.emit({
      field: this.field,
      data: this.data,
      index: this.rowIndex
    });
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        let focusCellSelector = this.pFocusCellSelector || 'input, textarea, select';
        let focusableElement = DomHandler.findSingle(this.el.nativeElement, focusCellSelector);
        if (focusableElement) {
          focusableElement.focus();
        }
      }, 50);
    });
    this.overlayEventListener = e => {
      if (this.el && this.el.nativeElement.contains(e.target)) {
        this.dt.selfClick = true;
      }
    };
    this.dt.overlaySubscription = this.dt.overlayService.clickObservable.subscribe(this.overlayEventListener);
  }
  closeEditingCell(completed, event) {
    const eventData = {
      field: this.dt.editingCellField,
      data: this.dt.editingCellData,
      originalEvent: event,
      index: this.dt.editingCellRowIndex
    };
    if (completed) {
      this.dt.onEditComplete.emit(eventData);
    } else {
      this.dt.onEditCancel.emit(eventData);
      this.dt.value.forEach(element => {
        if (element[this.dt.editingCellField] === this.data) {
          element[this.dt.editingCellField] = this.dt.editingCellData;
        }
      });
    }
    DomHandler.removeClass(this.dt.editingCell, 'p-cell-editing');
    this.dt.editingCell = null;
    this.dt.editingCellData = null;
    this.dt.editingCellField = null;
    this.dt.unbindDocumentEditListener();
    if (this.dt.overlaySubscription) {
      this.dt.overlaySubscription.unsubscribe();
    }
  }
  onEnterKeyDown(event) {
    if (this.isEnabled() && !event.shiftKey) {
      if (this.dt.isEditingCellValid()) {
        this.closeEditingCell(true, event);
      }
      event.preventDefault();
    }
  }
  onTabKeyDown(event) {
    if (this.isEnabled()) {
      if (this.dt.isEditingCellValid()) {
        this.closeEditingCell(true, event);
      }
      event.preventDefault();
    }
  }
  onEscapeKeyDown(event) {
    if (this.isEnabled()) {
      if (this.dt.isEditingCellValid()) {
        this.closeEditingCell(false, event);
      }
      event.preventDefault();
    }
  }
  onShiftKeyDown(event) {
    if (this.isEnabled()) {
      if (event.shiftKey) this.moveToPreviousCell(event);else {
        this.moveToNextCell(event);
      }
    }
  }
  onArrowDown(event) {
    if (this.isEnabled()) {
      let currentCell = this.findCell(event.target);
      if (currentCell) {
        let cellIndex = DomHandler.index(currentCell);
        let targetCell = this.findNextEditableColumnByIndex(currentCell, cellIndex);
        if (targetCell) {
          if (this.dt.isEditingCellValid()) {
            this.closeEditingCell(true, event);
          }
          DomHandler.invokeElementMethod(event.target, 'blur');
          DomHandler.invokeElementMethod(targetCell, 'click');
        }
        event.preventDefault();
      }
    }
  }
  onArrowUp(event) {
    if (this.isEnabled()) {
      let currentCell = this.findCell(event.target);
      if (currentCell) {
        let cellIndex = DomHandler.index(currentCell);
        let targetCell = this.findPrevEditableColumnByIndex(currentCell, cellIndex);
        if (targetCell) {
          if (this.dt.isEditingCellValid()) {
            this.closeEditingCell(true, event);
          }
          DomHandler.invokeElementMethod(event.target, 'blur');
          DomHandler.invokeElementMethod(targetCell, 'click');
        }
        event.preventDefault();
      }
    }
  }
  onArrowLeft(event) {
    if (this.isEnabled()) {
      this.moveToPreviousCell(event);
    }
  }
  onArrowRight(event) {
    if (this.isEnabled()) {
      this.moveToNextCell(event);
    }
  }
  findCell(element) {
    if (element) {
      let cell = element;
      while (cell && !DomHandler.hasClass(cell, 'p-cell-editing')) {
        cell = cell.parentElement;
      }
      return cell;
    } else {
      return null;
    }
  }
  moveToPreviousCell(event) {
    let currentCell = this.findCell(event.target);
    if (currentCell) {
      let targetCell = this.findPreviousEditableColumn(currentCell);
      if (targetCell) {
        if (this.dt.isEditingCellValid()) {
          this.closeEditingCell(true, event);
        }
        DomHandler.invokeElementMethod(event.target, 'blur');
        DomHandler.invokeElementMethod(targetCell, 'click');
        event.preventDefault();
      }
    }
  }
  moveToNextCell(event) {
    let currentCell = this.findCell(event.target);
    if (currentCell) {
      let targetCell = this.findNextEditableColumn(currentCell);
      if (targetCell) {
        if (this.dt.isEditingCellValid()) {
          this.closeEditingCell(true, event);
        }
        DomHandler.invokeElementMethod(event.target, 'blur');
        DomHandler.invokeElementMethod(targetCell, 'click');
        event.preventDefault();
      } else {
        if (this.dt.isEditingCellValid()) {
          this.closeEditingCell(true, event);
        }
      }
    }
  }
  findPreviousEditableColumn(cell) {
    let prevCell = cell.previousElementSibling;
    if (!prevCell) {
      let previousRow = cell.parentElement?.previousElementSibling;
      if (previousRow) {
        prevCell = previousRow.lastElementChild;
      }
    }
    if (prevCell) {
      if (DomHandler.hasClass(prevCell, 'p-editable-column')) return prevCell;else return this.findPreviousEditableColumn(prevCell);
    } else {
      return null;
    }
  }
  findNextEditableColumn(cell) {
    let nextCell = cell.nextElementSibling;
    if (!nextCell) {
      let nextRow = cell.parentElement?.nextElementSibling;
      if (nextRow) {
        nextCell = nextRow.firstElementChild;
      }
    }
    if (nextCell) {
      if (DomHandler.hasClass(nextCell, 'p-editable-column')) return nextCell;else return this.findNextEditableColumn(nextCell);
    } else {
      return null;
    }
  }
  findNextEditableColumnByIndex(cell, index) {
    let nextRow = cell.parentElement?.nextElementSibling;
    if (nextRow) {
      let nextCell = nextRow.children[index];
      if (nextCell && DomHandler.hasClass(nextCell, 'p-editable-column')) {
        return nextCell;
      }
      return null;
    } else {
      return null;
    }
  }
  findPrevEditableColumnByIndex(cell, index) {
    let prevRow = cell.parentElement?.previousElementSibling;
    if (prevRow) {
      let prevCell = prevRow.children[index];
      if (prevCell && DomHandler.hasClass(prevCell, 'p-editable-column')) {
        return prevCell;
      }
      return null;
    } else {
      return null;
    }
  }
  isEnabled() {
    return this.pEditableColumnDisabled !== true;
  }
  ngOnDestroy() {
    if (this.dt.overlaySubscription) {
      this.dt.overlaySubscription.unsubscribe();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: EditableColumn,
    deps: [{
      token: Table
    }, {
      token: i0.ElementRef
    }, {
      token: i0.NgZone
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: EditableColumn,
    isStandalone: false,
    selector: "[pEditableColumn]",
    inputs: {
      data: ["pEditableColumn", "data"],
      field: ["pEditableColumnField", "field"],
      rowIndex: ["pEditableColumnRowIndex", "rowIndex"],
      pEditableColumnDisabled: ["pEditableColumnDisabled", "pEditableColumnDisabled", booleanAttribute],
      pFocusCellSelector: "pFocusCellSelector"
    },
    host: {
      listeners: {
        "click": "onClick($event)",
        "keydown.enter": "onEnterKeyDown($event)",
        "keydown.tab": "onShiftKeyDown($event)",
        "keydown.escape": "onEscapeKeyDown($event)",
        "keydown.shift.tab": "onShiftKeyDown($event)",
        "keydown.meta.tab": "onShiftKeyDown($event)",
        "keydown.arrowdown": "onArrowDown($event)",
        "keydown.arrowup": "onArrowUp($event)",
        "keydown.arrowleft": "onArrowLeft($event)",
        "keydown.arrowright": "onArrowRight($event)"
      }
    },
    usesOnChanges: true,
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: EditableColumn,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pEditableColumn]',
      standalone: false
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }],
  propDecorators: {
    data: [{
      type: Input,
      args: ['pEditableColumn']
    }],
    field: [{
      type: Input,
      args: ['pEditableColumnField']
    }],
    rowIndex: [{
      type: Input,
      args: ['pEditableColumnRowIndex']
    }],
    pEditableColumnDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    pFocusCellSelector: [{
      type: Input
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onEnterKeyDown: [{
      type: HostListener,
      args: ['keydown.enter', ['$event']]
    }],
    onTabKeyDown: [{
      type: HostListener,
      args: ['keydown.tab', ['$event']]
    }],
    onEscapeKeyDown: [{
      type: HostListener,
      args: ['keydown.escape', ['$event']]
    }],
    onShiftKeyDown: [{
      type: HostListener,
      args: ['keydown.tab', ['$event']]
    }, {
      type: HostListener,
      args: ['keydown.shift.tab', ['$event']]
    }, {
      type: HostListener,
      args: ['keydown.meta.tab', ['$event']]
    }],
    onArrowDown: [{
      type: HostListener,
      args: ['keydown.arrowdown', ['$event']]
    }],
    onArrowUp: [{
      type: HostListener,
      args: ['keydown.arrowup', ['$event']]
    }],
    onArrowLeft: [{
      type: HostListener,
      args: ['keydown.arrowleft', ['$event']]
    }],
    onArrowRight: [{
      type: HostListener,
      args: ['keydown.arrowright', ['$event']]
    }]
  }
});
class EditableRow {
  el;
  data;
  pEditableRowDisabled;
  constructor(el) {
    this.el = el;
  }
  isEnabled() {
    return this.pEditableRowDisabled !== true;
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: EditableRow,
    deps: [{
      token: i0.ElementRef
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: EditableRow,
    isStandalone: false,
    selector: "[pEditableRow]",
    inputs: {
      data: ["pEditableRow", "data"],
      pEditableRowDisabled: ["pEditableRowDisabled", "pEditableRowDisabled", booleanAttribute]
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: EditableRow,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pEditableRow]',
      standalone: false
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }],
  propDecorators: {
    data: [{
      type: Input,
      args: ['pEditableRow']
    }],
    pEditableRowDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }]
  }
});
class InitEditableRow {
  dt;
  editableRow;
  constructor(dt, editableRow) {
    this.dt = dt;
    this.editableRow = editableRow;
  }
  onClick(event) {
    this.dt.initRowEdit(this.editableRow.data);
    event.preventDefault();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InitEditableRow,
    deps: [{
      token: Table
    }, {
      token: EditableRow
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: InitEditableRow,
    isStandalone: false,
    selector: "[pInitEditableRow]",
    host: {
      listeners: {
        "click": "onClick($event)"
      },
      classAttribute: "p-datatable-row-editor-init"
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InitEditableRow,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pInitEditableRow]',
      standalone: false,
      host: {
        class: 'p-datatable-row-editor-init'
      }
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: EditableRow
  }],
  propDecorators: {
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  }
});
class SaveEditableRow {
  dt;
  editableRow;
  constructor(dt, editableRow) {
    this.dt = dt;
    this.editableRow = editableRow;
  }
  onClick(event) {
    this.dt.saveRowEdit(this.editableRow.data, this.editableRow.el.nativeElement);
    event.preventDefault();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: SaveEditableRow,
    deps: [{
      token: Table
    }, {
      token: EditableRow
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: SaveEditableRow,
    isStandalone: false,
    selector: "[pSaveEditableRow]",
    host: {
      listeners: {
        "click": "onClick($event)"
      },
      classAttribute: "p-datatable-row-editor-save"
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: SaveEditableRow,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pSaveEditableRow]',
      standalone: false,
      host: {
        class: 'p-datatable-row-editor-save'
      }
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: EditableRow
  }],
  propDecorators: {
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  }
});
class CancelEditableRow {
  dt;
  editableRow;
  constructor(dt, editableRow) {
    this.dt = dt;
    this.editableRow = editableRow;
  }
  onClick(event) {
    this.dt.cancelRowEdit(this.editableRow.data);
    event.preventDefault();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: CancelEditableRow,
    deps: [{
      token: Table
    }, {
      token: EditableRow
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: CancelEditableRow,
    isStandalone: false,
    selector: "[pCancelEditableRow]",
    host: {
      listeners: {
        "click": "onClick($event)"
      },
      classAttribute: "p-datatable-row-editor-cancel"
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: CancelEditableRow,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pCancelEditableRow]',
      standalone: false,
      host: {
        class: 'p-datatable-row-editor-cancel'
      }
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: EditableRow
  }],
  propDecorators: {
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  }
});
class CellEditor {
  dt;
  editableColumn;
  editableRow;
  _templates;
  _inputTemplate;
  _outputTemplate;
  inputTemplate;
  outputTemplate;
  constructor(dt, editableColumn, editableRow) {
    this.dt = dt;
    this.editableColumn = editableColumn;
    this.editableRow = editableRow;
  }
  ngAfterContentInit() {
    this._templates.forEach(item => {
      switch (item.getType()) {
        case 'input':
          this.inputTemplate = item.template;
          break;
        case 'output':
          this.outputTemplate = item.template;
          break;
      }
    });
  }
  get editing() {
    return this.dt.editingCell && this.editableColumn && this.dt.editingCell === this.editableColumn.el.nativeElement || this.editableRow && this.dt.editMode === 'row' && this.dt.isRowEditing(this.editableRow.data);
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: CellEditor,
    deps: [{
      token: Table
    }, {
      token: EditableColumn,
      optional: true
    }, {
      token: EditableRow,
      optional: true
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: CellEditor,
    isStandalone: false,
    selector: "p-cellEditor",
    queries: [{
      propertyName: "_inputTemplate",
      first: true,
      predicate: ["input"],
      descendants: true
    }, {
      propertyName: "_outputTemplate",
      first: true,
      predicate: ["output"],
      descendants: true
    }, {
      propertyName: "_templates",
      predicate: PrimeTemplate
    }],
    ngImport: i0,
    template: `
        <ng-container *ngIf="editing">
            <ng-container *ngTemplateOutlet="inputTemplate || _inputTemplate"></ng-container>
        </ng-container>
        <ng-container *ngIf="!editing">
            <ng-container *ngTemplateOutlet="outputTemplate || _outputTemplate"></ng-container>
        </ng-container>
    `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }],
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: CellEditor,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-cellEditor',
      standalone: false,
      template: `
        <ng-container *ngIf="editing">
            <ng-container *ngTemplateOutlet="inputTemplate || _inputTemplate"></ng-container>
        </ng-container>
        <ng-container *ngIf="!editing">
            <ng-container *ngTemplateOutlet="outputTemplate || _outputTemplate"></ng-container>
        </ng-container>
    `,
      encapsulation: ViewEncapsulation.None
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: EditableColumn,
    decorators: [{
      type: Optional
    }]
  }, {
    type: EditableRow,
    decorators: [{
      type: Optional
    }]
  }],
  propDecorators: {
    _templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    _inputTemplate: [{
      type: ContentChild,
      args: ['input']
    }],
    _outputTemplate: [{
      type: ContentChild,
      args: ['output']
    }]
  }
});
class TableRadioButton {
  dt;
  cd;
  disabled;
  value;
  index;
  inputId;
  name;
  ariaLabel;
  inputViewChild;
  checked;
  focused;
  subscription;
  constructor(dt, cd) {
    this.dt = dt;
    this.cd = cd;
    this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
      this.checked = this.dt.isSelected(this.value);
      this.ariaLabel = this.ariaLabel || this.dt.config.translation.aria ? this.checked ? this.dt.config.translation.aria.selectRow : this.dt.config.translation.aria.unselectRow : undefined;
      this.cd.markForCheck();
    });
  }
  ngOnInit() {
    this.checked = this.dt.isSelected(this.value);
  }
  onClick(event) {
    if (!this.disabled) {
      this.dt.toggleRowWithRadio({
        originalEvent: event.originalEvent,
        rowIndex: this.index
      }, this.value);
      this.inputViewChild?.inputViewChild.nativeElement?.focus();
    }
    DomHandler.clearSelection();
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableRadioButton,
    deps: [{
      token: Table
    }, {
      token: i0.ChangeDetectorRef
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: TableRadioButton,
    isStandalone: false,
    selector: "p-tableRadioButton",
    inputs: {
      disabled: ["disabled", "disabled", booleanAttribute],
      value: "value",
      index: ["index", "index", numberAttribute],
      inputId: "inputId",
      name: "name",
      ariaLabel: "ariaLabel"
    },
    viewQueries: [{
      propertyName: "inputViewChild",
      first: true,
      predicate: ["rb"],
      descendants: true
    }],
    ngImport: i0,
    template: ` <p-radioButton #rb [(ngModel)]="checked" [disabled]="disabled" [inputId]="inputId" [name]="name" [ariaLabel]="ariaLabel" [binary]="true" [value]="value" (onClick)="onClick($event)" /> `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i5.NgControlStatus,
      selector: "[formControlName],[ngModel],[formControl]"
    }, {
      kind: "directive",
      type: i5.NgModel,
      selector: "[ngModel]:not([formControlName]):not([formControl])",
      inputs: ["name", "disabled", "ngModel", "ngModelOptions"],
      outputs: ["ngModelChange"],
      exportAs: ["ngModel"]
    }, {
      kind: "component",
      type: i6.RadioButton,
      selector: "p-radioButton, p-radiobutton, p-radio-button",
      inputs: ["value", "formControlName", "name", "disabled", "variant", "size", "tabindex", "inputId", "ariaLabelledBy", "ariaLabel", "style", "styleClass", "autofocus", "binary"],
      outputs: ["onClick", "onFocus", "onBlur"]
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TableRadioButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tableRadioButton',
      standalone: false,
      template: ` <p-radioButton #rb [(ngModel)]="checked" [disabled]="disabled" [inputId]="inputId" [name]="name" [ariaLabel]="ariaLabel" [binary]="true" [value]="value" (onClick)="onClick($event)" /> `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: i0.ChangeDetectorRef
  }],
  propDecorators: {
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    value: [{
      type: Input
    }],
    index: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    inputId: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    inputViewChild: [{
      type: ViewChild,
      args: ['rb']
    }]
  }
});
class TableCheckbox {
  dt;
  tableService;
  cd;
  disabled;
  value;
  index;
  inputId;
  name;
  required;
  ariaLabel;
  checked;
  focused;
  subscription;
  constructor(dt, tableService, cd) {
    this.dt = dt;
    this.tableService = tableService;
    this.cd = cd;
    this.subscription = this.dt.tableService.selectionSource$.subscribe(() => {
      this.checked = this.dt.isSelected(this.value) && !this.disabled;
      this.ariaLabel = this.ariaLabel || this.dt.config.translation.aria ? this.checked ? this.dt.config.translation.aria.selectRow : this.dt.config.translation.aria.unselectRow : undefined;
      this.cd.markForCheck();
    });
  }
  ngOnInit() {
    this.checked = this.dt.isSelected(this.value);
  }
  onClick(event) {
    if (!this.disabled) {
      this.dt.toggleRowWithCheckbox({
        originalEvent: event,
        rowIndex: this.index
      }, this.value);
    }
    DomHandler.clearSelection();
  }
  onFocus() {
    this.focused = true;
  }
  onBlur() {
    this.focused = false;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableCheckbox,
    deps: [{
      token: Table
    }, {
      token: TableService
    }, {
      token: i0.ChangeDetectorRef
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: TableCheckbox,
    isStandalone: false,
    selector: "p-tableCheckbox",
    inputs: {
      disabled: ["disabled", "disabled", booleanAttribute],
      value: "value",
      index: ["index", "index", numberAttribute],
      inputId: "inputId",
      name: "name",
      required: ["required", "required", booleanAttribute],
      ariaLabel: "ariaLabel"
    },
    ngImport: i0,
    template: `
        <p-checkbox [(ngModel)]="checked" [binary]="true" (onChange)="onClick($event)" [disabled]="disabled" [ariaLabel]="ariaLabel">
            <ng-container *ngIf="dt.checkboxIconTemplate || dt._checkboxIconTemplate">
                <ng-template pTemplate="icon">
                    <ng-template *ngTemplateOutlet="dt.checkboxIconTemplate || dt._checkboxIconTemplate; context: { $implicit: checked }"></ng-template>
                </ng-template>
            </ng-container>
        </p-checkbox>
    `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i3.PrimeTemplate,
      selector: "[pTemplate]",
      inputs: ["type", "pTemplate"]
    }, {
      kind: "directive",
      type: i5.NgControlStatus,
      selector: "[formControlName],[ngModel],[formControl]"
    }, {
      kind: "directive",
      type: i5.NgModel,
      selector: "[ngModel]:not([formControlName]):not([formControl])",
      inputs: ["name", "disabled", "ngModel", "ngModelOptions"],
      outputs: ["ngModelChange"],
      exportAs: ["ngModel"]
    }, {
      kind: "component",
      type: i7.Checkbox,
      selector: "p-checkbox, p-checkBox, p-check-box",
      inputs: ["value", "name", "disabled", "binary", "ariaLabelledBy", "ariaLabel", "tabindex", "inputId", "style", "inputStyle", "styleClass", "inputClass", "indeterminate", "size", "formControl", "checkboxIcon", "readonly", "required", "autofocus", "trueValue", "falseValue", "variant"],
      outputs: ["onChange", "onFocus", "onBlur"]
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TableCheckbox,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tableCheckbox',
      standalone: false,
      template: `
        <p-checkbox [(ngModel)]="checked" [binary]="true" (onChange)="onClick($event)" [disabled]="disabled" [ariaLabel]="ariaLabel">
            <ng-container *ngIf="dt.checkboxIconTemplate || dt._checkboxIconTemplate">
                <ng-template pTemplate="icon">
                    <ng-template *ngTemplateOutlet="dt.checkboxIconTemplate || dt._checkboxIconTemplate; context: { $implicit: checked }"></ng-template>
                </ng-template>
            </ng-container>
        </p-checkbox>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: TableService
  }, {
    type: i0.ChangeDetectorRef
  }],
  propDecorators: {
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    value: [{
      type: Input
    }],
    index: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    inputId: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    required: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    ariaLabel: [{
      type: Input
    }]
  }
});
class TableHeaderCheckbox {
  dt;
  tableService;
  cd;
  disabled;
  inputId;
  name;
  ariaLabel;
  checked;
  focused;
  selectionChangeSubscription;
  valueChangeSubscription;
  constructor(dt, tableService, cd) {
    this.dt = dt;
    this.tableService = tableService;
    this.cd = cd;
    this.valueChangeSubscription = this.dt.tableService.valueSource$.subscribe(() => {
      this.checked = this.updateCheckedState();
      this.ariaLabel = this.ariaLabel || this.dt.config.translation.aria ? this.checked ? this.dt.config.translation.aria.selectAll : this.dt.config.translation.aria.unselectAll : undefined;
    });
    this.selectionChangeSubscription = this.dt.tableService.selectionSource$.subscribe(() => {
      this.checked = this.updateCheckedState();
    });
  }
  ngOnInit() {
    this.checked = this.updateCheckedState();
  }
  onClick(event) {
    if (!this.disabled) {
      if (this.dt.value && this.dt.value.length > 0) {
        this.dt.toggleRowsWithCheckbox(event, this.checked);
      }
    }
    DomHandler.clearSelection();
  }
  onFocus() {
    this.focused = true;
  }
  onBlur() {
    this.focused = false;
  }
  isDisabled() {
    return this.disabled || !this.dt.value || !this.dt.value.length;
  }
  ngOnDestroy() {
    if (this.selectionChangeSubscription) {
      this.selectionChangeSubscription.unsubscribe();
    }
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }
  updateCheckedState() {
    this.cd.markForCheck();
    if (this.dt._selectAll !== null) {
      return this.dt._selectAll;
    } else {
      const data = this.dt.selectionPageOnly ? this.dt.dataToRender(this.dt.processedData) : this.dt.processedData;
      const val = this.dt.frozenValue ? [...this.dt.frozenValue, ...data] : data;
      const selectableVal = this.dt.rowSelectable ? val.filter((data, index) => this.dt.rowSelectable({
        data,
        index
      })) : val;
      return ObjectUtils.isNotEmpty(selectableVal) && ObjectUtils.isNotEmpty(this.dt.selection) && selectableVal.every(v => this.dt.selection.some(s => this.dt.equals(v, s)));
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableHeaderCheckbox,
    deps: [{
      token: Table
    }, {
      token: TableService
    }, {
      token: i0.ChangeDetectorRef
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: TableHeaderCheckbox,
    isStandalone: false,
    selector: "p-tableHeaderCheckbox",
    inputs: {
      disabled: ["disabled", "disabled", booleanAttribute],
      inputId: "inputId",
      name: "name",
      ariaLabel: "ariaLabel"
    },
    ngImport: i0,
    template: `
        <p-checkbox [(ngModel)]="checked" (onChange)="onClick($event)" [binary]="true" [disabled]="isDisabled()" [ariaLabel]="ariaLabel">
            <ng-container *ngIf="dt.headerCheckboxIconTemplate || dt._headerCheckboxIconTemplate">
                <ng-template pTemplate="icon">
                    <ng-template *ngTemplateOutlet="dt.headerCheckboxIconTemplate || dt._headerCheckboxIconTemplate; context: { $implicit: checked }"></ng-template>
                </ng-template>
            </ng-container>
        </p-checkbox>
    `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i3.PrimeTemplate,
      selector: "[pTemplate]",
      inputs: ["type", "pTemplate"]
    }, {
      kind: "directive",
      type: i5.NgControlStatus,
      selector: "[formControlName],[ngModel],[formControl]"
    }, {
      kind: "directive",
      type: i5.NgModel,
      selector: "[ngModel]:not([formControlName]):not([formControl])",
      inputs: ["name", "disabled", "ngModel", "ngModelOptions"],
      outputs: ["ngModelChange"],
      exportAs: ["ngModel"]
    }, {
      kind: "component",
      type: i7.Checkbox,
      selector: "p-checkbox, p-checkBox, p-check-box",
      inputs: ["value", "name", "disabled", "binary", "ariaLabelledBy", "ariaLabel", "tabindex", "inputId", "style", "inputStyle", "styleClass", "inputClass", "indeterminate", "size", "formControl", "checkboxIcon", "readonly", "required", "autofocus", "trueValue", "falseValue", "variant"],
      outputs: ["onChange", "onFocus", "onBlur"]
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TableHeaderCheckbox,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tableHeaderCheckbox',
      standalone: false,
      template: `
        <p-checkbox [(ngModel)]="checked" (onChange)="onClick($event)" [binary]="true" [disabled]="isDisabled()" [ariaLabel]="ariaLabel">
            <ng-container *ngIf="dt.headerCheckboxIconTemplate || dt._headerCheckboxIconTemplate">
                <ng-template pTemplate="icon">
                    <ng-template *ngTemplateOutlet="dt.headerCheckboxIconTemplate || dt._headerCheckboxIconTemplate; context: { $implicit: checked }"></ng-template>
                </ng-template>
            </ng-container>
        </p-checkbox>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: TableService
  }, {
    type: i0.ChangeDetectorRef
  }],
  propDecorators: {
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    inputId: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }]
  }
});
class ReorderableRowHandle {
  el;
  constructor(el) {
    this.el = el;
  }
  ngAfterViewInit() {
    // DomHandler.addClass(this.el.nativeElement, 'p-datatable-reorderable-row-handle');
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ReorderableRowHandle,
    deps: [{
      token: i0.ElementRef
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: ReorderableRowHandle,
    isStandalone: false,
    selector: "[pReorderableRowHandle]",
    host: {
      classAttribute: "p-datatable-reorderable-row-handle"
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: ReorderableRowHandle,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pReorderableRowHandle]',
      standalone: false,
      host: {
        class: 'p-datatable-reorderable-row-handle'
      }
    }]
  }],
  ctorParameters: () => [{
    type: i0.ElementRef
  }]
});
class ReorderableRow {
  renderer;
  dt;
  el;
  zone;
  index;
  pReorderableRowDisabled;
  mouseDownListener;
  dragStartListener;
  dragEndListener;
  dragOverListener;
  dragLeaveListener;
  dropListener;
  constructor(renderer, dt, el, zone) {
    this.renderer = renderer;
    this.dt = dt;
    this.el = el;
    this.zone = zone;
  }
  ngAfterViewInit() {
    if (this.isEnabled()) {
      this.el.nativeElement.droppable = true;
      this.bindEvents();
    }
  }
  bindEvents() {
    this.zone.runOutsideAngular(() => {
      this.mouseDownListener = this.renderer.listen(this.el.nativeElement, 'mousedown', this.onMouseDown.bind(this));
      this.dragStartListener = this.renderer.listen(this.el.nativeElement, 'dragstart', this.onDragStart.bind(this));
      this.dragEndListener = this.renderer.listen(this.el.nativeElement, 'dragend', this.onDragEnd.bind(this));
      this.dragOverListener = this.renderer.listen(this.el.nativeElement, 'dragover', this.onDragOver.bind(this));
      this.dragLeaveListener = this.renderer.listen(this.el.nativeElement, 'dragleave', this.onDragLeave.bind(this));
    });
  }
  unbindEvents() {
    if (this.mouseDownListener) {
      this.mouseDownListener();
      this.mouseDownListener = null;
    }
    if (this.dragStartListener) {
      this.dragStartListener();
      this.dragStartListener = null;
    }
    if (this.dragEndListener) {
      this.dragEndListener();
      this.dragEndListener = null;
    }
    if (this.dragOverListener) {
      this.dragOverListener();
      this.dragOverListener = null;
    }
    if (this.dragLeaveListener) {
      this.dragLeaveListener();
      this.dragLeaveListener = null;
    }
  }
  onMouseDown(event) {
    const targetElement = event.target;
    const isHandleClicked = this.isHandleElement(targetElement);
    this.el.nativeElement.draggable = isHandleClicked;
  }
  isHandleElement(element) {
    if (element?.classList.contains('p-datatable-reorderable-row-handle')) {
      return true;
    }
    if (element?.parentElement && !['TD', 'TR'].includes(element?.parentElement?.tagName)) {
      return this.isHandleElement(element?.parentElement);
    }
    return false;
  }
  onDragStart(event) {
    this.dt.onRowDragStart(event, this.index);
  }
  onDragEnd(event) {
    this.dt.onRowDragEnd(event);
    this.el.nativeElement.draggable = false;
  }
  onDragOver(event) {
    this.dt.onRowDragOver(event, this.index, this.el.nativeElement);
    event.preventDefault();
  }
  onDragLeave(event) {
    this.dt.onRowDragLeave(event, this.el.nativeElement);
  }
  isEnabled() {
    return this.pReorderableRowDisabled !== true;
  }
  onDrop(event) {
    if (this.isEnabled() && this.dt.rowDragging) {
      this.dt.onRowDrop(event, this.el.nativeElement);
    }
    event.preventDefault();
  }
  ngOnDestroy() {
    this.unbindEvents();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ReorderableRow,
    deps: [{
      token: i0.Renderer2
    }, {
      token: Table
    }, {
      token: i0.ElementRef
    }, {
      token: i0.NgZone
    }],
    target: i0.ɵɵFactoryTarget.Directive
  });
  static ɵdir = i0.ɵɵngDeclareDirective({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: ReorderableRow,
    isStandalone: false,
    selector: "[pReorderableRow]",
    inputs: {
      index: ["pReorderableRow", "index"],
      pReorderableRowDisabled: ["pReorderableRowDisabled", "pReorderableRowDisabled", booleanAttribute]
    },
    host: {
      listeners: {
        "drop": "onDrop($event)"
      }
    },
    ngImport: i0
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: ReorderableRow,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[pReorderableRow]',
      standalone: false
    }]
  }],
  ctorParameters: () => [{
    type: i0.Renderer2
  }, {
    type: Table
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }],
  propDecorators: {
    index: [{
      type: Input,
      args: ['pReorderableRow']
    }],
    pReorderableRowDisabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onDrop: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }]
  }
});
class ColumnFilter extends BaseComponent {
  /**
   * Property represented by the column.
   * @group Props
   */
  field;
  /**
   * Type of the input.
   * @group Props
   */
  type = 'text';
  /**
   * Filter display.
   * @group Props
   */
  display = 'row';
  /**
   * Decides whether to display filter menu popup.
   * @group Props
   */
  showMenu = true;
  /**
   * Filter match mode.
   * @group Props
   */
  matchMode;
  /**
   * Filter operator.
   * @defaultValue 'AND'
   * @group Props
   */
  operator = FilterOperator.AND;
  /**
   * Decides whether to display filter operator.
   * @group Props
   */
  showOperator = true;
  /**
   * Decides whether to display clear filter button.
   * @group Props
   */
  showClearButton = true;
  /**
   * Decides whether to display apply filter button.
   * @group Props
   */
  showApplyButton = true;
  /**
   * Decides whether to display filter match modes.
   * @group Props
   */
  showMatchModes = true;
  /**
   * Decides whether to display add filter button.
   * @group Props
   */
  showAddButton = true;
  /**
   * Decides whether to close popup on clear button click.
   * @group Props
   */
  hideOnClear = false;
  /**
   * Filter placeholder.
   * @group Props
   */
  placeholder;
  /**
   * Filter match mode options.
   * @group Props
   */
  matchModeOptions;
  /**
   * Defines maximum amount of constraints.
   * @group Props
   */
  maxConstraints = 2;
  /**
   * Defines minimum fraction of digits.
   * @group Props
   */
  minFractionDigits;
  /**
   * Defines maximum fraction of digits.
   * @group Props
   */
  maxFractionDigits;
  /**
   * Defines prefix of the filter.
   * @group Props
   */
  prefix;
  /**
   * Defines suffix of the filter.
   * @group Props
   */
  suffix;
  /**
   * Defines filter locale.
   * @group Props
   */
  locale;
  /**
   * Defines filter locale matcher.
   * @group Props
   */
  localeMatcher;
  /**
   * Enables currency input.
   * @group Props
   */
  currency;
  /**
   * Defines the display of the currency input.
   * @group Props
   */
  currencyDisplay;
  /**
   * Defines if filter grouping will be enabled.
   * @group Props
   */
  useGrouping = true;
  /**
   * Defines the visibility of buttons.
   * @group Props
   */
  showButtons = true;
  /**
   * Defines the aria-label of the form element.
   * @group Props
   */
  ariaLabel;
  /**
   * Used to pass all filter button property object
   * @defaultValue {
   filter: { severity: 'secondary', text: true, rounded: true },
   inline: {
   clear: { severity: 'secondary', text: true, rounded: true }
   },
   popover: {
   addRule: { severity: 'info', text: true, size: 'small' },
   removeRule: { severity: 'danger', text: true, size: 'small' },
   apply: { size: 'small' },
   clear: { outlined: true, size: 'small' }
   }
   }
   */
  filterButtonProps = {
    filter: {
      severity: 'secondary',
      text: true,
      rounded: true
    },
    inline: {
      clear: {
        severity: 'secondary',
        text: true,
        rounded: true
      }
    },
    popover: {
      addRule: {
        severity: 'info',
        text: true,
        size: 'small'
      },
      removeRule: {
        severity: 'danger',
        text: true,
        size: 'small'
      },
      apply: {
        size: 'small'
      },
      clear: {
        outlined: true,
        size: 'small'
      }
    }
  };
  /**
   * Callback to invoke on overlay is shown.
   * @param {AnimationEvent} originalEvent - animation event.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Callback to invoke on overlay is hidden.
   * @param {AnimationEvent} originalEvent - animation event.
   * @group Emits
   */
  onHide = new EventEmitter();
  icon;
  clearButtonViewChild;
  _templates;
  overlaySubscription;
  _headerTemplate;
  headerTemplate;
  _filterTemplate;
  filterTemplate;
  _footerTemplate;
  footerTemplate;
  _filterIconTemplate;
  filterIconTemplate;
  _removeRuleIconTemplate;
  removeRuleIconTemplate;
  _addRuleIconTemplate;
  addRuleIconTemplate;
  _clearFilterIconTemplate;
  clearFilterIconTemplate;
  operatorOptions;
  overlayVisible;
  overlay;
  scrollHandler;
  documentClickListener;
  documentResizeListener;
  matchModes;
  translationSubscription;
  resetSubscription;
  selfClick;
  overlayEventListener;
  overlayId;
  get fieldConstraints() {
    return this.dt.filters ? this.dt.filters[this.field] : null;
  }
  get showRemoveIcon() {
    return this.fieldConstraints ? this.fieldConstraints.length > 1 : false;
  }
  get showMenuButton() {
    return this.showMenu && (this.display === 'row' ? this.type !== 'boolean' : true);
  }
  get isShowOperator() {
    return this.showOperator && this.type !== 'boolean';
  }
  get isShowAddConstraint() {
    return this.showAddButton && this.type !== 'boolean' && this.fieldConstraints && this.fieldConstraints.length < this.maxConstraints;
  }
  get showMenuButtonLabel() {
    return this.config.getTranslation(TranslationKeys.SHOW_FILTER_MENU);
  }
  get applyButtonLabel() {
    return this.config.getTranslation(TranslationKeys.APPLY);
  }
  get clearButtonLabel() {
    return this.config.getTranslation(TranslationKeys.CLEAR);
  }
  get addRuleButtonLabel() {
    return this.config.getTranslation(TranslationKeys.ADD_RULE);
  }
  get removeRuleButtonLabel() {
    return this.config.getTranslation(TranslationKeys.REMOVE_RULE);
  }
  get noFilterLabel() {
    return this.config.getTranslation(TranslationKeys.NO_FILTER);
  }
  get filterMenuButtonAriaLabel() {
    return this.config.translation ? this.overlayVisible ? this.config.translation.aria.hideFilterMenu : this.config.translation.aria.showFilterMenu : undefined;
  }
  get removeRuleButtonAriaLabel() {
    return this.config.translation ? this.config.translation.removeRule : undefined;
  }
  get filterOperatorAriaLabel() {
    return this.config.translation ? this.config.translation.aria.filterOperator : undefined;
  }
  get filterConstraintAriaLabel() {
    return this.config.translation ? this.config.translation.aria.filterConstraint : undefined;
  }
  dt = inject(Table);
  overlayService = inject(OverlayService);
  hostName = 'Table';
  parentInstance = inject(forwardRef(() => Table));
  ngOnInit() {
    super.ngOnInit();
    this.overlayId = UniqueComponentId();
    if (!this.dt.filters[this.field]) {
      this.initFieldFilterConstraint();
    }
    this.translationSubscription = this.config.translationObserver.subscribe(() => {
      this.generateMatchModeOptions();
      this.generateOperatorOptions();
    });
    this.generateMatchModeOptions();
    this.generateOperatorOptions();
  }
  generateMatchModeOptions() {
    this.matchModes = this.matchModeOptions || this.config.filterMatchModeOptions[this.type]?.map(key => {
      return {
        label: this.config.getTranslation(key),
        value: key
      };
    });
  }
  generateOperatorOptions() {
    this.operatorOptions = [{
      label: this.config.getTranslation(TranslationKeys.MATCH_ALL),
      value: FilterOperator.AND
    }, {
      label: this.config.getTranslation(TranslationKeys.MATCH_ANY),
      value: FilterOperator.OR
    }];
  }
  ngAfterContentInit() {
    this._templates.forEach(item => {
      switch (item.getType()) {
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'filter':
          this.filterTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
        case 'filtericon':
          this.filterIconTemplate = item.template;
          break;
        case 'clearfiltericon':
          this.clearFilterIconTemplate = item.template;
          break;
        case 'removeruleicon':
          this.removeRuleIconTemplate = item.template;
          break;
        case 'addruleicon':
          this.addRuleIconTemplate = item.template;
          break;
        default:
          this.filterTemplate = item.template;
          break;
      }
    });
  }
  initFieldFilterConstraint() {
    let defaultMatchMode = this.getDefaultMatchMode();
    this.dt.filters[this.field] = this.display == 'row' ? {
      value: null,
      matchMode: defaultMatchMode
    } : [{
      value: null,
      matchMode: defaultMatchMode,
      operator: this.operator
    }];
  }
  onMenuMatchModeChange(value, filterMeta) {
    filterMeta.matchMode = value;
    if (!this.showApplyButton) {
      this.dt._filter();
    }
  }
  onRowMatchModeChange(matchMode) {
    const fieldFilter = this.dt.filters[this.field];
    fieldFilter.matchMode = matchMode;
    if (fieldFilter.value) {
      this.dt._filter();
    }
    this.hide();
  }
  onRowMatchModeKeyDown(event) {
    let item = event.target;
    switch (event.key) {
      case 'ArrowDown':
        var nextItem = this.findNextItem(item);
        if (nextItem) {
          item.removeAttribute('tabindex');
          nextItem.tabIndex = '0';
          nextItem.focus();
        }
        event.preventDefault();
        break;
      case 'ArrowUp':
        var prevItem = this.findPrevItem(item);
        if (prevItem) {
          item.removeAttribute('tabindex');
          prevItem.tabIndex = '0';
          prevItem.focus();
        }
        event.preventDefault();
        break;
    }
  }
  onRowClearItemClick() {
    this.clearFilter();
    this.hide();
  }
  isRowMatchModeSelected(matchMode) {
    return this.dt.filters[this.field].matchMode === matchMode;
  }
  addConstraint() {
    this.dt.filters[this.field].push({
      value: null,
      matchMode: this.getDefaultMatchMode(),
      operator: this.getDefaultOperator()
    });
    DomHandler.focus(this.clearButtonViewChild.nativeElement);
  }
  removeConstraint(filterMeta) {
    this.dt.filters[this.field] = this.dt.filters[this.field].filter(meta => meta !== filterMeta);
    if (!this.showApplyButton) {
      this.dt._filter();
    }
    DomHandler.focus(this.clearButtonViewChild.nativeElement);
  }
  onOperatorChange(value) {
    this.dt.filters[this.field].forEach(filterMeta => {
      filterMeta.operator = value;
      this.operator = value;
    });
    if (!this.showApplyButton) {
      this.dt._filter();
    }
  }
  toggleMenu(event) {
    this.overlayVisible = !this.overlayVisible;
    event.stopPropagation();
  }
  onToggleButtonKeyDown(event) {
    switch (event.key) {
      case 'Escape':
      case 'Tab':
        this.overlayVisible = false;
        break;
      case 'ArrowDown':
        if (this.overlayVisible) {
          let focusable = DomHandler.getFocusableElements(this.overlay);
          if (focusable) {
            focusable[0].focus();
          }
          event.preventDefault();
        } else if (event.altKey) {
          this.overlayVisible = true;
          event.preventDefault();
        }
        break;
      case 'Enter':
        this.toggleMenu(event);
        event.preventDefault();
        break;
    }
  }
  onEscape() {
    this.overlayVisible = false;
    this.icon?.nativeElement.focus();
  }
  findNextItem(item) {
    let nextItem = item.nextElementSibling;
    if (nextItem) return DomHandler.hasClass(nextItem, 'p-datatable-filter-constraint-separator') ? this.findNextItem(nextItem) : nextItem;else return item.parentElement?.firstElementChild;
  }
  findPrevItem(item) {
    let prevItem = item.previousElementSibling;
    if (prevItem) return DomHandler.hasClass(prevItem, 'p-datatable-filter-constraint-separator') ? this.findPrevItem(prevItem) : prevItem;else return item.parentElement?.lastElementChild;
  }
  onContentClick() {
    this.selfClick = true;
  }
  onOverlayAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        this.overlay = event.element;
        this.renderer.appendChild(this.document.body, this.overlay);
        ZIndexUtils.set('overlay', this.overlay, this.config.zIndex.overlay);
        DomHandler.absolutePosition(this.overlay, this.icon?.nativeElement);
        this.bindDocumentClickListener();
        this.bindDocumentResizeListener();
        this.bindScrollListener();
        this.overlayEventListener = e => {
          if (this.overlay && this.overlay.contains(e.target)) {
            this.selfClick = true;
          }
        };
        this.overlaySubscription = this.overlayService.clickObservable.subscribe(this.overlayEventListener);
        this.onShow.emit({
          originalEvent: event
        });
        break;
      case 'void':
        this.onOverlayHide();
        if (this.overlaySubscription) {
          this.overlaySubscription.unsubscribe();
        }
        break;
    }
  }
  onOverlayAnimationEnd(event) {
    switch (event.toState) {
      case 'visible':
        this.focusOnFirstElement();
        break;
      case 'void':
        ZIndexUtils.clear(event.element);
        this.onHide.emit({
          originalEvent: event
        });
        break;
    }
  }
  focusOnFirstElement() {
    if (this.overlay) {
      DomHandler.focus(DomHandler.getFirstFocusableElement(this.overlay, ''));
    }
  }
  getDefaultMatchMode() {
    if (this.matchMode) {
      return this.matchMode;
    } else {
      if (this.type === 'text') return FilterMatchMode.STARTS_WITH;else if (this.type === 'numeric') return FilterMatchMode.EQUALS;else if (this.type === 'date') return FilterMatchMode.DATE_IS;else return FilterMatchMode.CONTAINS;
    }
  }
  getDefaultOperator() {
    return this.dt.filters ? this.dt.filters[this.field][0].operator : this.operator;
  }
  hasRowFilter() {
    return this.dt.filters[this.field] && !this.dt.isFilterBlank(this.dt.filters[this.field].value);
  }
  get hasFilter() {
    let fieldFilter = this.dt.filters[this.field];
    if (fieldFilter) {
      if (Array.isArray(fieldFilter)) return !this.dt.isFilterBlank(fieldFilter[0].value);else return !this.dt.isFilterBlank(fieldFilter.value);
    }
    return false;
  }
  isOutsideClicked(event) {
    return !(DomHandler.hasClass(this.overlay?.nextElementSibling, 'p-overlay') || DomHandler.hasClass(this.overlay?.nextElementSibling, 'p-popover') || this.overlay?.isSameNode(event.target) || this.overlay?.contains(event.target) || this.icon?.nativeElement.isSameNode(event.target) || this.icon?.nativeElement.contains(event.target) || DomHandler.hasClass(event.target, 'p-datatable-filter-add-rule-button') || DomHandler.hasClass(event.target.parentElement, 'p-datatable-filter-add-rule-button') || DomHandler.hasClass(event.target, 'p-datatable-filter-remove-rule-button') || DomHandler.hasClass(event.target.parentElement, 'p-datatable-filter-remove-rule-button'));
  }
  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      const documentTarget = this.el ? this.el.nativeElement.ownerDocument : 'document';
      this.documentClickListener = this.renderer.listen(documentTarget, 'mousedown', event => {
        const dialogElements = document.querySelectorAll('[role="dialog"]');
        const targetIsColumnFilterMenuButton = event.target.closest('.p-datatable-column-filter-button');
        if (this.overlayVisible && this.isOutsideClicked(event) && (targetIsColumnFilterMenuButton || dialogElements?.length <= 1)) {
          this.hide();
        }
        this.selfClick = false;
      });
    }
  }
  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
      this.selfClick = false;
    }
  }
  bindDocumentResizeListener() {
    if (!this.documentResizeListener) {
      this.documentResizeListener = this.renderer.listen(this.document.defaultView, 'resize', event => {
        if (this.overlayVisible && !DomHandler.isTouchDevice()) {
          this.hide();
        }
      });
    }
  }
  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      this.documentResizeListener();
      this.documentResizeListener = null;
    }
  }
  bindScrollListener() {
    if (!this.scrollHandler) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.icon?.nativeElement, () => {
        if (this.overlayVisible) {
          this.hide();
        }
      });
    }
    this.scrollHandler.bindScrollListener();
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  hide() {
    this.overlayVisible = false;
    this.cd.markForCheck();
  }
  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.unbindScrollListener();
    this.overlay = null;
  }
  clearFilter() {
    this.initFieldFilterConstraint();
    this.dt._filter();
    if (this.hideOnClear) this.hide();
  }
  applyFilter() {
    this.dt._filter();
    this.hide();
  }
  ngOnDestroy() {
    if (this.overlay) {
      this.renderer.appendChild(this.el.nativeElement, this.overlay);
      ZIndexUtils.clear(this.overlay);
      this.onOverlayHide();
    }
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    if (this.overlaySubscription) {
      this.overlaySubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ColumnFilter,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: ColumnFilter,
    isStandalone: false,
    selector: "p-columnFilter",
    inputs: {
      field: "field",
      type: "type",
      display: "display",
      showMenu: ["showMenu", "showMenu", booleanAttribute],
      matchMode: "matchMode",
      operator: "operator",
      showOperator: ["showOperator", "showOperator", booleanAttribute],
      showClearButton: ["showClearButton", "showClearButton", booleanAttribute],
      showApplyButton: ["showApplyButton", "showApplyButton", booleanAttribute],
      showMatchModes: ["showMatchModes", "showMatchModes", booleanAttribute],
      showAddButton: ["showAddButton", "showAddButton", booleanAttribute],
      hideOnClear: ["hideOnClear", "hideOnClear", booleanAttribute],
      placeholder: "placeholder",
      matchModeOptions: "matchModeOptions",
      maxConstraints: ["maxConstraints", "maxConstraints", numberAttribute],
      minFractionDigits: ["minFractionDigits", "minFractionDigits", value => numberAttribute(value, null)],
      maxFractionDigits: ["maxFractionDigits", "maxFractionDigits", value => numberAttribute(value, null)],
      prefix: "prefix",
      suffix: "suffix",
      locale: "locale",
      localeMatcher: "localeMatcher",
      currency: "currency",
      currencyDisplay: "currencyDisplay",
      useGrouping: ["useGrouping", "useGrouping", booleanAttribute],
      showButtons: ["showButtons", "showButtons", booleanAttribute],
      ariaLabel: "ariaLabel",
      filterButtonProps: "filterButtonProps"
    },
    outputs: {
      onShow: "onShow",
      onHide: "onHide"
    },
    queries: [{
      propertyName: "_headerTemplate",
      first: true,
      predicate: ["header"]
    }, {
      propertyName: "_filterTemplate",
      first: true,
      predicate: ["filter"]
    }, {
      propertyName: "_footerTemplate",
      first: true,
      predicate: ["footer"]
    }, {
      propertyName: "_filterIconTemplate",
      first: true,
      predicate: ["filtericon"]
    }, {
      propertyName: "_removeRuleIconTemplate",
      first: true,
      predicate: ["removeruleicon"]
    }, {
      propertyName: "_addRuleIconTemplate",
      first: true,
      predicate: ["addruleicon"]
    }, {
      propertyName: "_clearFilterIconTemplate",
      first: true,
      predicate: ["clearfiltericon"]
    }, {
      propertyName: "_templates",
      predicate: PrimeTemplate
    }],
    viewQueries: [{
      propertyName: "icon",
      first: true,
      predicate: Button,
      descendants: true,
      read: ElementRef
    }, {
      propertyName: "clearButtonViewChild",
      first: true,
      predicate: ["clearBtn"],
      descendants: true
    }],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <div [ngClass]="cx('filter')">
            <p-columnFilterFormElement
                *ngIf="display === 'row'"
                class="p-fluid"
                [type]="type"
                [field]="field"
                [ariaLabel]="ariaLabel"
                [filterConstraint]="dt.filters[field]"
                [filterTemplate]="filterTemplate || _filterTemplate"
                [placeholder]="placeholder"
                [minFractionDigits]="minFractionDigits"
                [maxFractionDigits]="maxFractionDigits"
                [prefix]="prefix"
                [suffix]="suffix"
                [locale]="locale"
                [localeMatcher]="localeMatcher"
                [currency]="currency"
                [currencyDisplay]="currencyDisplay"
                [useGrouping]="useGrouping"
                [showButtons]="showButtons"
            ></p-columnFilterFormElement>
            <p-button
                *ngIf="showMenuButton"
                [styleClass]="cx('pcColumnFilterButton')"
                [attr.aria-haspopup]="true"
                [ariaLabel]="filterMenuButtonAriaLabel"
                [attr.aria-controls]="overlayVisible ? overlayId : null"
                [attr.aria-expanded]="overlayVisible ?? false"
                (click)="toggleMenu($event)"
                (keydown)="onToggleButtonKeyDown($event)"
                [buttonProps]="filterButtonProps?.filter"
            >
                <ng-template #icon>
                    <FilterIcon *ngIf="!filterIconTemplate && !_filterIconTemplate" />
                    <span class="pi-filter-icon" *ngIf="filterIconTemplate || _filterIconTemplate">
                        <ng-template *ngTemplateOutlet="filterIconTemplate || _filterIconTemplate"></ng-template>
                    </span>
                </ng-template>
            </p-button>
            <p-button *ngIf="showClearButton && display === 'row' && hasFilter" [styleClass]="cx('pcColumnfilterClearButton')" (onClick)="clearFilter()" [ariaLabel]="clearButtonLabel" [buttonProps]="filterButtonProps?.inline?.clear">
                <ng-template #icon>
                    <FilterSlashIcon *ngIf="!clearFilterIconTemplate && !_clearFilterIconTemplate" />
                    <ng-template *ngTemplateOutlet="clearFilterIconTemplate || _clearFilterIconTemplate"></ng-template>
                </ng-template>
            </p-button>
            <div
                *ngIf="showMenu && overlayVisible"
                [ngClass]="cx('filterOverlay')"
                [id]="overlayId"
                [attr.aria-modal]="true"
                role="dialog"
                (click)="onContentClick()"
                [@overlayAnimation]="'visible'"
                (@overlayAnimation.start)="onOverlayAnimationStart($event)"
                (@overlayAnimation.done)="onOverlayAnimationEnd($event)"
                (keydown.escape)="onEscape()"
            >
                <ng-container *ngTemplateOutlet="headerTemplate || _headerTemplate; context: { $implicit: field }"></ng-container>
                <ul *ngIf="display === 'row'; else menu" [ngClass]="cx('filterConstraintList')">
                    <li
                        *ngFor="let matchMode of matchModes; let i = index"
                        (click)="onRowMatchModeChange(matchMode.value)"
                        (keydown)="onRowMatchModeKeyDown($event)"
                        (keydown.enter)="onRowMatchModeChange(matchMode.value)"
                        [ngClass]="cx('filterConstraint')"
                        [class.p-datatable-filter-constraint-selected]="isRowMatchModeSelected(matchMode.value)"
                        [attr.tabindex]="i === 0 ? '0' : null"
                    >
                        {{ matchMode.label }}
                    </li>
                    <li [ngClass]="cx('filterConstraintSeparator')"></li>
                    <li [ngClass]="cx('filterConstraint')" (click)="onRowClearItemClick()" (keydown)="onRowMatchModeKeyDown($event)" (keydown.enter)="onRowClearItemClick()">
                        {{ noFilterLabel }}
                    </li>
                </ul>
                <ng-template #menu>
                    <div [ngClass]="cx('filterOperator')" *ngIf="isShowOperator">
                        <p-select [options]="operatorOptions" [ngModel]="operator" (ngModelChange)="onOperatorChange($event)" [styleClass]="cx('pcFilterOperatorDropdown')"></p-select>
                    </div>
                    <div [ngClass]="cx('filterRuleList')">
                        <div *ngFor="let fieldConstraint of fieldConstraints; let i = index" [ngClass]="cx('filterRule')">
                            <p-select
                                *ngIf="showMatchModes && matchModes"
                                [options]="matchModes"
                                [ngModel]="fieldConstraint.matchMode"
                                (ngModelChange)="onMenuMatchModeChange($event, fieldConstraint)"
                                [styleClass]="cx('pcFilterConstraintDropdown')"
                            ></p-select>
                            <p-columnFilterFormElement
                                [type]="type"
                                [field]="field"
                                [filterConstraint]="fieldConstraint"
                                [filterTemplate]="filterTemplate || _filterTemplate"
                                [placeholder]="placeholder"
                                [minFractionDigits]="minFractionDigits"
                                [maxFractionDigits]="maxFractionDigits"
                                [prefix]="prefix"
                                [suffix]="suffix"
                                [locale]="locale"
                                [localeMatcher]="localeMatcher"
                                [currency]="currency"
                                [currencyDisplay]="currencyDisplay"
                                [useGrouping]="useGrouping"
                            ></p-columnFilterFormElement>
                            <div>
                                <p-button
                                    *ngIf="showRemoveIcon"
                                    [styleClass]="cx('pcFilterRemoveRuleButton')"
                                    [text]="true"
                                    severity="danger"
                                    size="small"
                                    (onClick)="removeConstraint(fieldConstraint)"
                                    [ariaLabel]="removeRuleButtonLabel"
                                    [label]="removeRuleButtonLabel"
                                    [buttonProps]="filterButtonProps?.popover?.removeRule"
                                >
                                    <TrashIcon *ngIf="!removeRuleIconTemplate && !_removeRuleIconTemplate" />
                                    <ng-template *ngTemplateOutlet="removeRuleIconTemplate || _removeRuleIconTemplate"></ng-template>
                                </p-button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isShowAddConstraint">
                        <p-button
                            type="button"
                            [label]="addRuleButtonLabel"
                            [attr.aria-label]="addRuleButtonLabel"
                            [styleClass]="cx('pcFilterAddRuleButton')"
                            [text]="true"
                            size="small"
                            (onClick)="addConstraint()"
                            [buttonProps]="filterButtonProps?.popover?.addRule"
                        >
                            <PlusIcon *ngIf="!addRuleIconTemplate && !_addRuleIconTemplate" />
                            <ng-template *ngTemplateOutlet="addRuleIconTemplate || _addRuleIconTemplate"></ng-template>
                        </p-button>
                    </div>
                    <div [ngClass]="cx('filterButtonbar')">
                        <p-button #clearBtn *ngIf="showClearButton" [outlined]="true" (onClick)="clearFilter()" [attr.aria-label]="clearButtonLabel" [label]="clearButtonLabel" [buttonProps]="filterButtonProps?.popover?.clear" />
                        <p-button *ngIf="showApplyButton" (onClick)="applyFilter()" size="small" [label]="applyButtonLabel" [attr.aria-label]="applyButtonLabel" [buttonProps]="filterButtonProps?.popover?.apply" />
                    </div>
                </ng-template>
                <ng-container *ngTemplateOutlet="footerTemplate || _footerTemplate; context: { $implicit: field }"></ng-container>
            </div>
        </div>
    `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i0.forwardRef(() => i1.NgClass),
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i1.NgForOf),
      selector: "[ngFor][ngForOf]",
      inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i1.NgIf),
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i1.NgTemplateOutlet),
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "component",
      type: i0.forwardRef(() => i8.Select),
      selector: "p-select",
      inputs: ["id", "scrollHeight", "filter", "name", "style", "panelStyle", "styleClass", "panelStyleClass", "readonly", "required", "editable", "appendTo", "tabindex", "placeholder", "loadingIcon", "filterPlaceholder", "filterLocale", "variant", "inputId", "dataKey", "filterBy", "filterFields", "autofocus", "resetFilterOnHide", "checkmark", "dropdownIcon", "loading", "optionLabel", "optionValue", "optionDisabled", "optionGroupLabel", "optionGroupChildren", "autoDisplayFirst", "group", "showClear", "emptyFilterMessage", "emptyMessage", "lazy", "virtualScroll", "virtualScrollItemSize", "virtualScrollOptions", "size", "overlayOptions", "ariaFilterLabel", "ariaLabel", "ariaLabelledBy", "filterMatchMode", "maxlength", "tooltip", "tooltipPosition", "tooltipPositionStyle", "tooltipStyleClass", "focusOnHover", "selectOnFocus", "autoOptionFocus", "autofocusFilter", "fluid", "disabled", "itemSize", "autoZIndex", "baseZIndex", "showTransitionOptions", "hideTransitionOptions", "filterValue", "options"],
      outputs: ["onChange", "onFilter", "onFocus", "onBlur", "onClick", "onShow", "onHide", "onClear", "onLazyLoad"]
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i5.NgControlStatus),
      selector: "[formControlName],[ngModel],[formControl]"
    }, {
      kind: "directive",
      type: i0.forwardRef(() => i5.NgModel),
      selector: "[ngModel]:not([formControlName]):not([formControl])",
      inputs: ["name", "disabled", "ngModel", "ngModelOptions"],
      outputs: ["ngModelChange"],
      exportAs: ["ngModel"]
    }, {
      kind: "component",
      type: i0.forwardRef(() => i9.Button),
      selector: "p-button",
      inputs: ["type", "iconPos", "icon", "badge", "label", "disabled", "loading", "loadingIcon", "raised", "rounded", "text", "plain", "severity", "outlined", "link", "tabindex", "size", "variant", "style", "styleClass", "badgeClass", "badgeSeverity", "ariaLabel", "autofocus", "fluid", "buttonProps"],
      outputs: ["onClick", "onFocus", "onBlur"]
    }, {
      kind: "component",
      type: i0.forwardRef(() => FilterIcon),
      selector: "FilterIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => FilterSlashIcon),
      selector: "FilterSlashIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => PlusIcon),
      selector: "PlusIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => TrashIcon),
      selector: "TrashIcon"
    }, {
      kind: "component",
      type: i0.forwardRef(() => ColumnFilterFormElement),
      selector: "p-columnFilterFormElement",
      inputs: ["field", "type", "filterConstraint", "filterTemplate", "placeholder", "minFractionDigits", "maxFractionDigits", "prefix", "suffix", "locale", "localeMatcher", "currency", "currencyDisplay", "useGrouping", "ariaLabel"]
    }],
    animations: [trigger('overlayAnimation', [transition(':enter', [style({
      opacity: 0,
      transform: 'scaleY(0.8)'
    }), animate('.12s cubic-bezier(0, 0, 0.2, 1)')]), transition(':leave', [animate('.1s linear', style({
      opacity: 0
    }))])])],
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: ColumnFilter,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-columnFilter',
      standalone: false,
      template: `
        <div [ngClass]="cx('filter')">
            <p-columnFilterFormElement
                *ngIf="display === 'row'"
                class="p-fluid"
                [type]="type"
                [field]="field"
                [ariaLabel]="ariaLabel"
                [filterConstraint]="dt.filters[field]"
                [filterTemplate]="filterTemplate || _filterTemplate"
                [placeholder]="placeholder"
                [minFractionDigits]="minFractionDigits"
                [maxFractionDigits]="maxFractionDigits"
                [prefix]="prefix"
                [suffix]="suffix"
                [locale]="locale"
                [localeMatcher]="localeMatcher"
                [currency]="currency"
                [currencyDisplay]="currencyDisplay"
                [useGrouping]="useGrouping"
                [showButtons]="showButtons"
            ></p-columnFilterFormElement>
            <p-button
                *ngIf="showMenuButton"
                [styleClass]="cx('pcColumnFilterButton')"
                [attr.aria-haspopup]="true"
                [ariaLabel]="filterMenuButtonAriaLabel"
                [attr.aria-controls]="overlayVisible ? overlayId : null"
                [attr.aria-expanded]="overlayVisible ?? false"
                (click)="toggleMenu($event)"
                (keydown)="onToggleButtonKeyDown($event)"
                [buttonProps]="filterButtonProps?.filter"
            >
                <ng-template #icon>
                    <FilterIcon *ngIf="!filterIconTemplate && !_filterIconTemplate" />
                    <span class="pi-filter-icon" *ngIf="filterIconTemplate || _filterIconTemplate">
                        <ng-template *ngTemplateOutlet="filterIconTemplate || _filterIconTemplate"></ng-template>
                    </span>
                </ng-template>
            </p-button>
            <p-button *ngIf="showClearButton && display === 'row' && hasFilter" [styleClass]="cx('pcColumnfilterClearButton')" (onClick)="clearFilter()" [ariaLabel]="clearButtonLabel" [buttonProps]="filterButtonProps?.inline?.clear">
                <ng-template #icon>
                    <FilterSlashIcon *ngIf="!clearFilterIconTemplate && !_clearFilterIconTemplate" />
                    <ng-template *ngTemplateOutlet="clearFilterIconTemplate || _clearFilterIconTemplate"></ng-template>
                </ng-template>
            </p-button>
            <div
                *ngIf="showMenu && overlayVisible"
                [ngClass]="cx('filterOverlay')"
                [id]="overlayId"
                [attr.aria-modal]="true"
                role="dialog"
                (click)="onContentClick()"
                [@overlayAnimation]="'visible'"
                (@overlayAnimation.start)="onOverlayAnimationStart($event)"
                (@overlayAnimation.done)="onOverlayAnimationEnd($event)"
                (keydown.escape)="onEscape()"
            >
                <ng-container *ngTemplateOutlet="headerTemplate || _headerTemplate; context: { $implicit: field }"></ng-container>
                <ul *ngIf="display === 'row'; else menu" [ngClass]="cx('filterConstraintList')">
                    <li
                        *ngFor="let matchMode of matchModes; let i = index"
                        (click)="onRowMatchModeChange(matchMode.value)"
                        (keydown)="onRowMatchModeKeyDown($event)"
                        (keydown.enter)="onRowMatchModeChange(matchMode.value)"
                        [ngClass]="cx('filterConstraint')"
                        [class.p-datatable-filter-constraint-selected]="isRowMatchModeSelected(matchMode.value)"
                        [attr.tabindex]="i === 0 ? '0' : null"
                    >
                        {{ matchMode.label }}
                    </li>
                    <li [ngClass]="cx('filterConstraintSeparator')"></li>
                    <li [ngClass]="cx('filterConstraint')" (click)="onRowClearItemClick()" (keydown)="onRowMatchModeKeyDown($event)" (keydown.enter)="onRowClearItemClick()">
                        {{ noFilterLabel }}
                    </li>
                </ul>
                <ng-template #menu>
                    <div [ngClass]="cx('filterOperator')" *ngIf="isShowOperator">
                        <p-select [options]="operatorOptions" [ngModel]="operator" (ngModelChange)="onOperatorChange($event)" [styleClass]="cx('pcFilterOperatorDropdown')"></p-select>
                    </div>
                    <div [ngClass]="cx('filterRuleList')">
                        <div *ngFor="let fieldConstraint of fieldConstraints; let i = index" [ngClass]="cx('filterRule')">
                            <p-select
                                *ngIf="showMatchModes && matchModes"
                                [options]="matchModes"
                                [ngModel]="fieldConstraint.matchMode"
                                (ngModelChange)="onMenuMatchModeChange($event, fieldConstraint)"
                                [styleClass]="cx('pcFilterConstraintDropdown')"
                            ></p-select>
                            <p-columnFilterFormElement
                                [type]="type"
                                [field]="field"
                                [filterConstraint]="fieldConstraint"
                                [filterTemplate]="filterTemplate || _filterTemplate"
                                [placeholder]="placeholder"
                                [minFractionDigits]="minFractionDigits"
                                [maxFractionDigits]="maxFractionDigits"
                                [prefix]="prefix"
                                [suffix]="suffix"
                                [locale]="locale"
                                [localeMatcher]="localeMatcher"
                                [currency]="currency"
                                [currencyDisplay]="currencyDisplay"
                                [useGrouping]="useGrouping"
                            ></p-columnFilterFormElement>
                            <div>
                                <p-button
                                    *ngIf="showRemoveIcon"
                                    [styleClass]="cx('pcFilterRemoveRuleButton')"
                                    [text]="true"
                                    severity="danger"
                                    size="small"
                                    (onClick)="removeConstraint(fieldConstraint)"
                                    [ariaLabel]="removeRuleButtonLabel"
                                    [label]="removeRuleButtonLabel"
                                    [buttonProps]="filterButtonProps?.popover?.removeRule"
                                >
                                    <TrashIcon *ngIf="!removeRuleIconTemplate && !_removeRuleIconTemplate" />
                                    <ng-template *ngTemplateOutlet="removeRuleIconTemplate || _removeRuleIconTemplate"></ng-template>
                                </p-button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isShowAddConstraint">
                        <p-button
                            type="button"
                            [label]="addRuleButtonLabel"
                            [attr.aria-label]="addRuleButtonLabel"
                            [styleClass]="cx('pcFilterAddRuleButton')"
                            [text]="true"
                            size="small"
                            (onClick)="addConstraint()"
                            [buttonProps]="filterButtonProps?.popover?.addRule"
                        >
                            <PlusIcon *ngIf="!addRuleIconTemplate && !_addRuleIconTemplate" />
                            <ng-template *ngTemplateOutlet="addRuleIconTemplate || _addRuleIconTemplate"></ng-template>
                        </p-button>
                    </div>
                    <div [ngClass]="cx('filterButtonbar')">
                        <p-button #clearBtn *ngIf="showClearButton" [outlined]="true" (onClick)="clearFilter()" [attr.aria-label]="clearButtonLabel" [label]="clearButtonLabel" [buttonProps]="filterButtonProps?.popover?.clear" />
                        <p-button *ngIf="showApplyButton" (onClick)="applyFilter()" size="small" [label]="applyButtonLabel" [attr.aria-label]="applyButtonLabel" [buttonProps]="filterButtonProps?.popover?.apply" />
                    </div>
                </ng-template>
                <ng-container *ngTemplateOutlet="footerTemplate || _footerTemplate; context: { $implicit: field }"></ng-container>
            </div>
        </div>
    `,
      animations: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('.12s cubic-bezier(0, 0, 0.2, 1)')]), transition(':leave', [animate('.1s linear', style({
        opacity: 0
      }))])])],
      encapsulation: ViewEncapsulation.None
    }]
  }],
  propDecorators: {
    field: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    display: [{
      type: Input
    }],
    showMenu: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    matchMode: [{
      type: Input
    }],
    operator: [{
      type: Input
    }],
    showOperator: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showClearButton: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showApplyButton: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showMatchModes: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showAddButton: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    hideOnClear: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    placeholder: [{
      type: Input
    }],
    matchModeOptions: [{
      type: Input
    }],
    maxConstraints: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    minFractionDigits: [{
      type: Input,
      args: [{
        transform: value => numberAttribute(value, null)
      }]
    }],
    maxFractionDigits: [{
      type: Input,
      args: [{
        transform: value => numberAttribute(value, null)
      }]
    }],
    prefix: [{
      type: Input
    }],
    suffix: [{
      type: Input
    }],
    locale: [{
      type: Input
    }],
    localeMatcher: [{
      type: Input
    }],
    currency: [{
      type: Input
    }],
    currencyDisplay: [{
      type: Input
    }],
    useGrouping: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showButtons: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    ariaLabel: [{
      type: Input
    }],
    filterButtonProps: [{
      type: Input
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    icon: [{
      type: ViewChild,
      args: [Button, {
        static: false,
        read: ElementRef
      }]
    }],
    clearButtonViewChild: [{
      type: ViewChild,
      args: ['clearBtn']
    }],
    _templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }],
    _headerTemplate: [{
      type: ContentChild,
      args: ['header', {
        descendants: false
      }]
    }],
    _filterTemplate: [{
      type: ContentChild,
      args: ['filter', {
        descendants: false
      }]
    }],
    _footerTemplate: [{
      type: ContentChild,
      args: ['footer', {
        descendants: false
      }]
    }],
    _filterIconTemplate: [{
      type: ContentChild,
      args: ['filtericon', {
        descendants: false
      }]
    }],
    _removeRuleIconTemplate: [{
      type: ContentChild,
      args: ['removeruleicon', {
        descendants: false
      }]
    }],
    _addRuleIconTemplate: [{
      type: ContentChild,
      args: ['addruleicon', {
        descendants: false
      }]
    }],
    _clearFilterIconTemplate: [{
      type: ContentChild,
      args: ['clearfiltericon', {
        descendants: false
      }]
    }]
  }
});
class ColumnFilterFormElement {
  dt;
  colFilter;
  field;
  type;
  filterConstraint;
  filterTemplate;
  placeholder;
  minFractionDigits;
  maxFractionDigits;
  prefix;
  suffix;
  locale;
  localeMatcher;
  currency;
  currencyDisplay;
  useGrouping = true;
  ariaLabel;
  get showButtons() {
    return this.colFilter.showButtons;
  }
  filterCallback;
  constructor(dt, colFilter) {
    this.dt = dt;
    this.colFilter = colFilter;
  }
  ngOnInit() {
    this.filterCallback = value => {
      this.filterConstraint.value = value;
      this.dt._filter();
    };
  }
  onModelChange(value) {
    this.filterConstraint.value = value;
    if (this.type === 'date' || this.type === 'boolean' || value === '') {
      this.dt._filter();
    }
  }
  onTextInputEnterKeyDown(event) {
    this.dt._filter();
    event.preventDefault();
  }
  onNumericInputKeyDown(event) {
    if (event.key === 'Enter') {
      this.dt._filter();
      event.preventDefault();
    }
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: ColumnFilterFormElement,
    deps: [{
      token: Table
    }, {
      token: ColumnFilter
    }],
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: ColumnFilterFormElement,
    isStandalone: false,
    selector: "p-columnFilterFormElement",
    inputs: {
      field: "field",
      type: "type",
      filterConstraint: "filterConstraint",
      filterTemplate: "filterTemplate",
      placeholder: "placeholder",
      minFractionDigits: ["minFractionDigits", "minFractionDigits", value => numberAttribute(value, null)],
      maxFractionDigits: ["maxFractionDigits", "maxFractionDigits", value => numberAttribute(value, null)],
      prefix: "prefix",
      suffix: "suffix",
      locale: "locale",
      localeMatcher: "localeMatcher",
      currency: "currency",
      currencyDisplay: "currencyDisplay",
      useGrouping: ["useGrouping", "useGrouping", booleanAttribute],
      ariaLabel: "ariaLabel"
    },
    ngImport: i0,
    template: `
        <ng-container *ngIf="filterTemplate; else builtInElement">
            <ng-container
                *ngTemplateOutlet="
                    filterTemplate;
                    context: {
                        $implicit: filterConstraint.value,
                        filterCallback: filterCallback,
                        type: type,
                        field: field,
                        filterConstraint: filterConstraint,
                        placeholder: placeholder,
                        minFractionDigits: minFractionDigits,
                        maxFractionDigits: maxFractionDigits,
                        prefix: prefix,
                        suffix: suffix,
                        locale: locale,
                        localeMatcher: localeMatcher,
                        currency: currency,
                        currencyDisplay: currencyDisplay,
                        useGrouping: useGrouping,
                        showButtons: showButtons
                    }
                "
            ></ng-container>
        </ng-container>
        <ng-template #builtInElement>
            <ng-container [ngSwitch]="type">
                <input
                    *ngSwitchCase="'text'"
                    type="text"
                    [ariaLabel]="ariaLabel"
                    pInputText
                    [value]="filterConstraint?.value"
                    (input)="onModelChange($event.target.value)"
                    (keydown.enter)="onTextInputEnterKeyDown($event)"
                    [attr.placeholder]="placeholder"
                />
                <p-inputNumber
                    *ngSwitchCase="'numeric'"
                    [ngModel]="filterConstraint?.value"
                    (ngModelChange)="onModelChange($event)"
                    (onKeyDown)="onNumericInputKeyDown($event)"
                    [showButtons]="showButtons"
                    [minFractionDigits]="minFractionDigits"
                    [maxFractionDigits]="maxFractionDigits"
                    [ariaLabel]="ariaLabel"
                    [prefix]="prefix"
                    [suffix]="suffix"
                    [placeholder]="placeholder"
                    [mode]="currency ? 'currency' : 'decimal'"
                    [locale]="locale"
                    [localeMatcher]="localeMatcher"
                    [currency]="currency"
                    [currencyDisplay]="currencyDisplay"
                    [useGrouping]="useGrouping"
                ></p-inputNumber>
                <p-checkbox [indeterminate]="true" [binary]="true" *ngSwitchCase="'boolean'" [ngModel]="filterConstraint?.value" (ngModelChange)="onModelChange($event)" />

                <p-datepicker [ariaLabel]="ariaLabel" *ngSwitchCase="'date'" [placeholder]="placeholder" [ngModel]="filterConstraint?.value" (ngModelChange)="onModelChange($event)" appendTo="body"></p-datepicker>
            </ng-container>
        </ng-template>
    `,
    isInline: true,
    dependencies: [{
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i1.NgSwitch,
      selector: "[ngSwitch]",
      inputs: ["ngSwitch"]
    }, {
      kind: "directive",
      type: i1.NgSwitchCase,
      selector: "[ngSwitchCase]",
      inputs: ["ngSwitchCase"]
    }, {
      kind: "directive",
      type: i10.InputText,
      selector: "[pInputText]",
      inputs: ["variant", "fluid", "pSize"]
    }, {
      kind: "directive",
      type: i5.NgControlStatus,
      selector: "[formControlName],[ngModel],[formControl]"
    }, {
      kind: "directive",
      type: i5.NgModel,
      selector: "[ngModel]:not([formControlName]):not([formControl])",
      inputs: ["name", "disabled", "ngModel", "ngModelOptions"],
      outputs: ["ngModelChange"],
      exportAs: ["ngModel"]
    }, {
      kind: "component",
      type: i11.DatePicker,
      selector: "p-datePicker, p-datepicker, p-date-picker",
      inputs: ["iconDisplay", "style", "styleClass", "inputStyle", "inputId", "name", "inputStyleClass", "placeholder", "ariaLabelledBy", "ariaLabel", "iconAriaLabel", "disabled", "dateFormat", "multipleSeparator", "rangeSeparator", "inline", "showOtherMonths", "selectOtherMonths", "showIcon", "fluid", "icon", "appendTo", "readonlyInput", "shortYearCutoff", "monthNavigator", "yearNavigator", "hourFormat", "timeOnly", "stepHour", "stepMinute", "stepSecond", "showSeconds", "required", "showOnFocus", "showWeek", "startWeekFromFirstDayOfYear", "showClear", "dataType", "selectionMode", "maxDateCount", "showButtonBar", "todayButtonStyleClass", "clearButtonStyleClass", "autofocus", "autoZIndex", "baseZIndex", "panelStyleClass", "panelStyle", "keepInvalid", "hideOnDateTimeSelect", "touchUI", "timeSeparator", "focusTrap", "showTransitionOptions", "hideTransitionOptions", "tabindex", "variant", "size", "minDate", "maxDate", "disabledDates", "disabledDays", "yearRange", "showTime", "responsiveOptions", "numberOfMonths", "firstDayOfWeek", "locale", "view", "defaultDate"],
      outputs: ["onFocus", "onBlur", "onClose", "onSelect", "onClear", "onInput", "onTodayClick", "onClearClick", "onMonthChange", "onYearChange", "onClickOutside", "onShow"]
    }, {
      kind: "component",
      type: i12.InputNumber,
      selector: "p-inputNumber, p-inputnumber, p-input-number",
      inputs: ["showButtons", "format", "buttonLayout", "inputId", "styleClass", "style", "placeholder", "size", "maxlength", "tabindex", "title", "ariaLabelledBy", "ariaLabel", "ariaRequired", "name", "required", "autocomplete", "min", "max", "incrementButtonClass", "decrementButtonClass", "incrementButtonIcon", "decrementButtonIcon", "readonly", "step", "allowEmpty", "locale", "localeMatcher", "mode", "currency", "currencyDisplay", "useGrouping", "variant", "minFractionDigits", "maxFractionDigits", "prefix", "suffix", "inputStyle", "inputStyleClass", "showClear", "autofocus", "disabled", "fluid"],
      outputs: ["onInput", "onFocus", "onBlur", "onKeyDown", "onClear"]
    }, {
      kind: "component",
      type: i7.Checkbox,
      selector: "p-checkbox, p-checkBox, p-check-box",
      inputs: ["value", "name", "disabled", "binary", "ariaLabelledBy", "ariaLabel", "tabindex", "inputId", "style", "inputStyle", "styleClass", "inputClass", "indeterminate", "size", "formControl", "checkboxIcon", "readonly", "required", "autofocus", "trueValue", "falseValue", "variant"],
      outputs: ["onChange", "onFocus", "onBlur"]
    }],
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: ColumnFilterFormElement,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-columnFilterFormElement',
      standalone: false,
      template: `
        <ng-container *ngIf="filterTemplate; else builtInElement">
            <ng-container
                *ngTemplateOutlet="
                    filterTemplate;
                    context: {
                        $implicit: filterConstraint.value,
                        filterCallback: filterCallback,
                        type: type,
                        field: field,
                        filterConstraint: filterConstraint,
                        placeholder: placeholder,
                        minFractionDigits: minFractionDigits,
                        maxFractionDigits: maxFractionDigits,
                        prefix: prefix,
                        suffix: suffix,
                        locale: locale,
                        localeMatcher: localeMatcher,
                        currency: currency,
                        currencyDisplay: currencyDisplay,
                        useGrouping: useGrouping,
                        showButtons: showButtons
                    }
                "
            ></ng-container>
        </ng-container>
        <ng-template #builtInElement>
            <ng-container [ngSwitch]="type">
                <input
                    *ngSwitchCase="'text'"
                    type="text"
                    [ariaLabel]="ariaLabel"
                    pInputText
                    [value]="filterConstraint?.value"
                    (input)="onModelChange($event.target.value)"
                    (keydown.enter)="onTextInputEnterKeyDown($event)"
                    [attr.placeholder]="placeholder"
                />
                <p-inputNumber
                    *ngSwitchCase="'numeric'"
                    [ngModel]="filterConstraint?.value"
                    (ngModelChange)="onModelChange($event)"
                    (onKeyDown)="onNumericInputKeyDown($event)"
                    [showButtons]="showButtons"
                    [minFractionDigits]="minFractionDigits"
                    [maxFractionDigits]="maxFractionDigits"
                    [ariaLabel]="ariaLabel"
                    [prefix]="prefix"
                    [suffix]="suffix"
                    [placeholder]="placeholder"
                    [mode]="currency ? 'currency' : 'decimal'"
                    [locale]="locale"
                    [localeMatcher]="localeMatcher"
                    [currency]="currency"
                    [currencyDisplay]="currencyDisplay"
                    [useGrouping]="useGrouping"
                ></p-inputNumber>
                <p-checkbox [indeterminate]="true" [binary]="true" *ngSwitchCase="'boolean'" [ngModel]="filterConstraint?.value" (ngModelChange)="onModelChange($event)" />

                <p-datepicker [ariaLabel]="ariaLabel" *ngSwitchCase="'date'" [placeholder]="placeholder" [ngModel]="filterConstraint?.value" (ngModelChange)="onModelChange($event)" appendTo="body"></p-datepicker>
            </ng-container>
        </ng-template>
    `,
      encapsulation: ViewEncapsulation.None
    }]
  }],
  ctorParameters: () => [{
    type: Table
  }, {
    type: ColumnFilter
  }],
  propDecorators: {
    field: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    filterConstraint: [{
      type: Input
    }],
    filterTemplate: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    minFractionDigits: [{
      type: Input,
      args: [{
        transform: value => numberAttribute(value, null)
      }]
    }],
    maxFractionDigits: [{
      type: Input,
      args: [{
        transform: value => numberAttribute(value, null)
      }]
    }],
    prefix: [{
      type: Input
    }],
    suffix: [{
      type: Input
    }],
    locale: [{
      type: Input
    }],
    localeMatcher: [{
      type: Input
    }],
    currency: [{
      type: Input
    }],
    currencyDisplay: [{
      type: Input
    }],
    useGrouping: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    ariaLabel: [{
      type: Input
    }]
  }
});
class TableModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableModule,
    declarations: [Table, SortableColumn, FrozenColumn, RowGroupHeader, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, ReorderableColumn, EditableColumn, CellEditor, TableBody, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox, ReorderableRowHandle, ReorderableRow, SelectableRowDblClick, EditableRow, InitEditableRow, SaveEditableRow, CancelEditableRow, ColumnFilter, ColumnFilterFormElement],
    imports: [CommonModule, PaginatorModule, InputTextModule, SelectModule, FormsModule, ButtonModule, SelectButtonModule, DatePickerModule, InputNumberModule, CheckboxModule, ScrollerModule, ArrowDownIcon, ArrowUpIcon, SpinnerIcon, SortAltIcon, SortAmountUpAltIcon, SortAmountDownIcon, CheckIcon, FilterIcon, FilterSlashIcon, PlusIcon, TrashIcon, RadioButtonModule],
    exports: [Table, SharedModule, SortableColumn, FrozenColumn, RowGroupHeader, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, ReorderableColumn, EditableColumn, CellEditor, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox, ReorderableRowHandle, ReorderableRow, SelectableRowDblClick, EditableRow, InitEditableRow, SaveEditableRow, CancelEditableRow, ColumnFilter, ColumnFilterFormElement, ScrollerModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TableModule,
    providers: [TableStyle],
    imports: [CommonModule, PaginatorModule, InputTextModule, SelectModule, FormsModule, ButtonModule, SelectButtonModule, DatePickerModule, InputNumberModule, CheckboxModule, ScrollerModule, ArrowDownIcon, ArrowUpIcon, SpinnerIcon, SortAltIcon, SortAmountUpAltIcon, SortAmountDownIcon, CheckIcon, FilterIcon, FilterSlashIcon, PlusIcon, TrashIcon, RadioButtonModule, SharedModule, ScrollerModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TableModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PaginatorModule, InputTextModule, SelectModule, FormsModule, ButtonModule, SelectButtonModule, DatePickerModule, InputNumberModule, CheckboxModule, ScrollerModule, ArrowDownIcon, ArrowUpIcon, SpinnerIcon, SortAltIcon, SortAmountUpAltIcon, SortAmountDownIcon, CheckIcon, FilterIcon, FilterSlashIcon, PlusIcon, TrashIcon, RadioButtonModule],
      exports: [Table, SharedModule, SortableColumn, FrozenColumn, RowGroupHeader, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, ReorderableColumn, EditableColumn, CellEditor, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox, ReorderableRowHandle, ReorderableRow, SelectableRowDblClick, EditableRow, InitEditableRow, SaveEditableRow, CancelEditableRow, ColumnFilter, ColumnFilterFormElement, ScrollerModule],
      declarations: [Table, SortableColumn, FrozenColumn, RowGroupHeader, SelectableRow, RowToggler, ContextMenuRow, ResizableColumn, ReorderableColumn, EditableColumn, CellEditor, TableBody, SortIcon, TableRadioButton, TableCheckbox, TableHeaderCheckbox, ReorderableRowHandle, ReorderableRow, SelectableRowDblClick, EditableRow, InitEditableRow, SaveEditableRow, CancelEditableRow, ColumnFilter, ColumnFilterFormElement],
      providers: [TableStyle]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { CancelEditableRow, CellEditor, ColumnFilter, ColumnFilterFormElement, ContextMenuRow, EditableColumn, EditableRow, FrozenColumn, InitEditableRow, ReorderableColumn, ReorderableRow, ReorderableRowHandle, ResizableColumn, RowGroupHeader, RowToggler, SaveEditableRow, SelectableRow, SelectableRowDblClick, SortIcon, SortableColumn, Table, TableBody, TableCheckbox, TableClasses, TableHeaderCheckbox, TableModule, TableRadioButton, TableService, TableStyle };
