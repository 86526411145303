<div>
<div class="row">
    <div class="xl:col-12 lg:col-12 col-12">
        <h3>Geselecteerde producten</h3>
    </div>
    <div class="xl:col-12 lg:col-12 col-12">
        @if (priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit)) {
            <product-selection-component [iconCSSClass]="'pi-bolt ebemGreen'" [checkmarksCSSClass]="'check-ebemGreen'" [selectedBackgroundCSSClass]="'backgroundEbemLightGreen'" [products]="electricityProducts | filter: includesType(electricityType)" [(selection)]="electricityType" (selectionChange)="calculateTotalPrice()"></product-selection-component>
            <p-accordion [expandIcon]="'pi-angle-down'" [collapseIcon]="'pi-angle-up'">
                <p-accordion-panel value="0">
                    <p-accordion-header class="backgroundLightGray">
                        Een ander Elektriciteit product selecteren
                    </p-accordion-header>
                    <p-accordion-content>
                        <product-selection-component [iconCSSClass]="'pi-bolt ebemGreen'" [checkmarksCSSClass]="'check-ebemGreen'" [selectedBackgroundCSSClass]="'backgroundEbemLightGreen'" [products]="electricityProducts | filter: excludesType(electricityType)" [(selection)]="electricityType" (selectionChange)="calculateTotalPrice()"></product-selection-component>
                    </p-accordion-content>
                </p-accordion-panel>
            </p-accordion>
        }
    </div>
    <div class="xl:col-12 lg:col-12 col-12">
        @if (priceSimulationChoices?.includes(PriceSimulationChoice.Gas)) {
            <product-selection-component [iconCSSClass]="'material-icons ebemBlue'" [materialIcon]="'local_fire_department'" [checkmarksCSSClass]="'check-ebemBlue'" [selectedBackgroundCSSClass]="'backgroundEbemLightBlue'" [products]="gasProducts | filter: includesType(gasType)" [(selection)]="gasType" (selectionChange)="calculateTotalPrice()"></product-selection-component>
            <p-accordion [expandIcon]="'pi-angle-down'" [collapseIcon]="'pi-angle-up'">
                <p-accordion-panel value="0">
                    <p-accordion-header class="backgroundLightGray">
                        Een ander Gas product selecteren
                    </p-accordion-header>
                    <p-accordion-content>
                        <product-selection-component [iconCSSClass]="'material-icons ebemBlue'" [materialIcon]="'local_fire_department'" [checkmarksCSSClass]="'check-ebemBlue'" [selectedBackgroundCSSClass]="'backgroundEbemLightBlue'" [products]="gasProducts | filter: excludesType(gasType)" [(selection)]="gasType" (selectionChange)="calculateTotalPrice()"></product-selection-component>
                    </p-accordion-content>
                </p-accordion-panel>
            </p-accordion>
        }
    </div>
    <div class="xl:col-12 lg:col-12 col-12">
        <p-inputgroup class="backgroundEbemLighterGreen">  
            <h3><span class="pi-bolt ebemGreen"></span> Totaal <div class="right-price"><strong>€ {{priceTotal / 12 | number: '1.0-2'}}</strong>/<small>mnd</small></div></h3>
            <ul class="check-ebemGreen">
                <li>Prijs incl. BTW</li>
                <li>Facturatie per email</li>
            </ul>
            <p-button (onClick)="overlayPanel.toggle($event)" link="true">Tarief opbouw en details bekijken</p-button>
            <p-overlayPanel #overlayPanel>
                <p-tabs [value]="priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit) ? 0 : priceSimulationChoices?.includes(PriceSimulationChoice.Gas) ? 1 : -1">
                    <p-tablist>
                        @if (priceSimulationChoices?.includes(PriceSimulationChoice.Elektriciteit)) {
                        <p-tab title="Elektriciteit" [value]="0">
                            <div class="grid fluid">

                                <div class="row">
                                    <div class="xl:col-12 lg:col-12 col-12">                           
                                        <span class="pi-bolt ebemGreen" style="font-size: 22px"></span> Elektriciteit
                                    </div>
                                </div>
                            </div>
                        </p-tab>
                        }
                        @if (priceSimulationChoices?.includes(PriceSimulationChoice.Gas)) {
                        <p-tab title="Gas" [value]="1">
                            <div class="grid fluid">
                                <div class="row">
                                    <div class="xl:col-12 lg:col-12 col-12">
                                        <span class="material-icons ebemBlue" style="font-size: 25px">local_fire_department</span> Aardgas
                                    </div>
                                </div>
                            </div>
                        </p-tab>
                        }
                    </p-tablist>
                    <p-tabpanels>
                        <p-tabpanel [value]="0">
                            <div *ngFor="let selectedElectricityProduct of electricityProducts | filter: includesType(electricityType); track $index">
                                <div class="row" *ngFor="let priceDetail of selectedElectricityProduct?.priceDetails">
                                    <div class="xl:col-6 lg:col-6 col-12">
                                        <p-input-group>
                                            <strong>{{priceDetail?.description}}</strong>
                                        </p-input-group>
                                    </div>
                                    <div class="xl:col-6 lg:col-6 col-12">
                                        <p-input-group>
                                            <strong>{{priceDetail?.amount / 12}}</strong>
                                        </p-input-group>
                                    </div>
                                </div>
                            </div>
                        </p-tabpanel>
                        <p-tabpanel [value]="1">
                            <div *ngFor="let selectedGasProduct of gasProducts | filter: includesType(gasType); track $index">
                                <div class="row" *ngFor="let priceDetail of selectedGasProduct?.priceDetails; track $index">
                                    <div class="xl:col-6 lg:col-6 col-12">
                                        <p-input-group>
                                            <strong>{{priceDetail?.description}}</strong>
                                        </p-input-group>
                                    </div>
                                    <div class="xl:col-6 lg:col-6 col-12">
                                        <p-input-group>
                                            <strong>{{priceDetail?.amount / 12}}</strong>
                                        </p-input-group>
                                    </div>
                                </div>
                            </div>
                        </p-tabpanel>
                    </p-tabpanels>
                </p-tabs>
                <p-button class="close-button-right" outlined="true" (onClick)="overlayPanel.hide()">Sluiten</p-button>
            </p-overlayPanel>
        </p-inputgroup>
    </div>
</div>
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="100" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/price-calculation', 'step-2']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Terug</p-button>
    </div>
    <div class="col-6">
        <p-button class="float-right" [routerLink]="['/price-calculation', 'step-4']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Verder gaan <span class="pi-arrow-right"></span></p-button>
    </div>
</div>
</div>