import { Component } from "@angular/core";
import { NewsArticleComponent } from "../../components/news-article/news-article.component";
import { NewsItem } from "src/app/models/news-item.model";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { UmbracoService } from "src/app/services/umbraco.service";
import { DataUdiDirective } from "src/app/directives/data-udi.directive";
import { DynamicHTMLModule } from "src/app/components/dynamic-html/module";
import { UmbracoHelper } from "src/app/helpers/umbraco.helper";
import { Subscription } from "rxjs";


@Component({
    selector: 'consumer-protection-page',
    templateUrl: './consumer-protection.page.html',
    styleUrls: ['./consumer-protection.page.scss'],
    standalone: true,
    imports: [CommonModule, NewsArticleComponent, FormsModule, DataUdiDirective, DynamicHTMLModule],
    providers: [UmbracoService]
})
export class ConsumerProtectionPage {
    newsarticle!: NewsItem;
    htmlContent!: string;
    subscribers: Subscription[] = [];
    
    constructor(private umbracoService: UmbracoService) {
        this.subscribers.push(this.umbracoService.getConsumerProtectionPage().subscribe(content => {
            this.htmlContent = UmbracoHelper.processContent(content[0].properties?.["bodyText"]?.sections[0]?.rows[0]?.areas[0]?.controls[0]?.value);
        }));
    }
}