import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";

@Component({
    selector: 'usp-component',
    standalone: true,
    templateUrl: 'usp.component.html',
    styleUrls: ['./usp.component.scss'],
    imports: [AvatarModule, ButtonModule, CardModule, CommonModule, FormsModule, RouterModule],
})
export class UniqueSellingPointsComponent {

}