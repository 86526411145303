import { Component, Input } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { UniqueSellingPointsComponent } from "src/app/components/usp/usp.component";
import { MissionStatementComponent } from "../../components/mission-statement/mission-statement.component";
import { DynamicTariffsComponent } from "../../components/dynamic-tariffs/dynamic-tariffs.component";
import { CallToActionComponent } from "src/app/components/call-to-action/call-to-action.component";
import { CardModule } from "primeng/card";

@Component({
    selector: 'about-us-page',
    standalone: true,
    styleUrls: ['./about-us.page.scss'],
    templateUrl: './about-us.page.html',
    imports: [UniqueSellingPointsComponent, MissionStatementComponent, DynamicTariffsComponent, 
         ButtonModule, RouterModule, CallToActionComponent, CardModule]
})
export class AboutUsPage {
    customerType: string = "customer";
    constructor() {
        this.customerType = window.history.state.customerType;
    }
}