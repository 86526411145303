import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, Router, RouterStateSnapshot } from "@angular/router";
import { StringHelper } from "src/app/helpers/string.helper";
import { Company } from "src/app/models/company.model";
import { Contract } from "src/app/models/contract.model";
import { Customer } from "src/app/models/customer.model";
import { SituationEnum } from "src/app/models/enums/situation.enum";

@Injectable()
export class CustomerWizardStep4SelfEmployedGuard implements CanActivate {
    constructor(public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardResult {
        const extras = this.router.getCurrentNavigation()?.extras;
        const company: Company = extras?.state?.["company"];
        const contract: Contract = extras?.state?.["contract"];
        return (extras?.state?.["electricityType"] ? true : false || extras?.state?.['gasType'] ? true : false) 
        && ((!!company && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.companyType) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.nacebelCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.companyNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.firstName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.lastName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.telephone) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.telephone) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.streetName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.houseNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.postalCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.location) && (company.invoiceAddressIsSameToPostalAddress || (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressStreetName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressHouseNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressPostalCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressLocation)))) ? true : false)
        && ((!!contract && !!contract.situation && !!contract.startDate && (extras?.state?.["electricityType"] == null || StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.electricityEAN)) && (extras?.state?.["gasType"] == null || StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.gasEAN))) ? true : false); 
    }
}