<div class="card-container backgroundLightEbemGreenHalfWhite">
    <img class="card-background" [src]="imageSrc">
    <div class="row card justify-content-end">
        <p-card class="flex-column xl:col-4 lg:col-4 col-12">
            <div class="row">
                <div class="col-12">
                    <h4>{{text}}</h4>
                </div>
            </div>

            @if(showAvatar) {
                <div class="row justify-content-start">
                    <div class="col-3">
                        <p-avatar [image]="avatarSrc" size="xlarge" shape="circle"></p-avatar>
                    </div>
                    <div class="col-9">
                        <h6>{{avatarName}}</h6>
                        <small style="color:gray">{{avatarTitle}}</small>
                    </div>
                </div>
            }
            @if(showPostCode) {
                <div class="row">
                    <div class="col-12">
                        <p-inputnumber placeholder="Postcode" name="postalCode" [ngClass]="inputPostalCodeValid == null ||  inputPostalCodeValid == undefined ? '' : inputPostalCodeValid === true ? 'ng-valid' : inputPostalCodeValid === false ? 'ng-invalid' : ''" [minFractionDigits]="0" [maxFractionDigits]="0" mode="decimal" [useGrouping]="false" [(ngModel)]="postalCode" (onInput)="validatePostalCodeInput();"></p-inputnumber>
                        <p-card class="danger no-padding" *ngIf="showErrorNoPostalCodeFilledIn">Postcode is een verplicht veld</p-card>  
                        <p-card class="danger no-padding" *ngIf="showErrorPostalCodeInvalid">Het opgegeven postcode is niet in Vlaanderen gelegen</p-card>  
                    </div>
                </div>
            }
            <div class="row">
                <div class="col-12">
                    <p-button [fluid]="true" (click)="validateForm() ? this.router.navigate(buttonRoute, {state: { 'postalCode': postalCode } }) : null">{{buttonText}}</p-button>
                </div>
            </div>
        </p-card>
        <div class="flex-column justify-content-end align-items-end xl:flex lg:flex xl:col-8 lg:col-8 col-12 hidden">
            <h6 class="white">Ebem staat garant voor duurzame energie van<br/> dichtbij, met respect voor milieu en toekomst.</h6>
        </div>
    </div>
</div>
