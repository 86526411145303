export class PriceSimulationInput {
    postalCode: string;
    isDigital: boolean;
    kwhInjectie: number;
    kwhPiek: number;
    kwhDal: number;
    kwh24u: number;
    kwhExclNacht: number;
    kwhGas: number;
    kweInjectie: number;

    constructor() {
        this.postalCode = '';
        this.isDigital = false;
        this.kwhInjectie = 0;
        this.kwhPiek = 0;
        this.kwhDal = 0;
        this.kwh24u = 0;
        this.kwhExclNacht = 0;
        this.kwhGas = 0;
        this.kweInjectie = 0;
    }
}