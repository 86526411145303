<div>
<h3>Verbruik inschatten</h3>

<h4>Verwarm je je woning met gas?</h4>
<p>Dit heeft uiteraard invloed op de prijs die je betaalt voor gas en eventueel ook elekriciteit.</p>
<div class="row">
    <div class="col-12">
        <p-input-group (click)="priceSimulationOptionsIkVerwarmMetGas.select($event); determineType(); calculateEstimates(); validateHeatingType();">
            <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates();" #priceSimulationOptionsIkVerwarmMetGas [(ngModel)]="priceSimulationOptions.heatingType" binary="false" [value]="PriceSimulationHeatingType['met Gas']"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Ja, Ik verwarm met gas</label></div>
        </p-input-group>
        <p-input-group (click)="priceSimulationOptionsIkVerwarmNietMetGas.select($event); determineType(); calculateEstimates(); validateHeatingType();">
            <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates();" #priceSimulationOptionsIkVerwarmNietMetGas [(ngModel)]="priceSimulationOptions.heatingType" binary="false" [value]="PriceSimulationHeatingType['Niet met Gas']"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Nee, Ik verwarm niet met gas</label></div>
        </p-input-group>
        <p-card class="danger no-padding" *ngIf="showErrorNoHeatingTypeSelected">Verwarmingskeuze is een verplicht veld</p-card>
        
    </div>
</div>

<h4>Heb je grote verbruikers thuis?</h4>
<p>Grote energieverbruikers zoals een laadpaal kunnen een behoorlijke invloed op je verbruik hebben.</p>
<div class="row">
    <div class="col-12">
        <p-input-group (click)="priceSimulationEnergyConsumerHeatPump.updateModel(true); determineType(); calculateEstimates()"><div class="checkbox-left"><p-checkbox #priceSimulationEnergyConsumerHeatPump (click)="priceSimulationEnergyConsumerHeatPump.updateModel(true); determineType(); calculateEstimates()" [(ngModel)]="priceSimulationOptions.energyConsumers" [binary]="false" [value]="PriceSimulationEnergyConsumers.Warmtepomp"></p-checkbox><label>&nbsp;&nbsp;&nbsp;Warmtepomp</label></div></p-input-group>
        <p-input-group (click)="priceSimulationEnergyConsumerHomeBattery.updateModel(true); determineType(); calculateEstimates()"><div class="checkbox-left"><p-checkbox #priceSimulationEnergyConsumerHomeBattery (click)="priceSimulationEnergyConsumerHomeBattery.updateModel(true); determineType(); calculateEstimates()" [(ngModel)]="priceSimulationOptions.energyConsumers" [binary]="false" [value]="PriceSimulationEnergyConsumers.Thuisbatterij"></p-checkbox><label>&nbsp;&nbsp;&nbsp;Thuisbatterij</label></div></p-input-group>
        <p-input-group (click)="priceSimulationEnergyConsumerChargingPort.updateModel(true); determineType(); calculateEstimates()"><div class="checkbox-left"><p-checkbox #priceSimulationEnergyConsumerChargingPort (click)="priceSimulationEnergyConsumerChargingPort.updateModel(true); determineType(); calculateEstimates()" [(ngModel)]="priceSimulationOptions.energyConsumers" [binary]="false" [value]="PriceSimulationEnergyConsumers.Laadpaal"></p-checkbox><label>&nbsp;&nbsp;&nbsp;Laadpaal</label></div></p-input-group>        
    </div>
</div>

<h4>In welk type woning woon je?</h4>
<p>Het verbruik van een open bebouwing is doorgaans hoger dan een appartement.</p>
<div><h4 class="pi-home col-2" style="display: inline;"></h4>&nbsp;<p-select class="col-10" placeholder="Type woning" optionLabel="key" optionValue="value" [options]="BuildingType | enumKeyValue" [(ngModel)]="buildingType" (onChange)="calculateEstimates(); validateBuildingType()"></p-select></div>
<p-card class="danger no-padding" *ngIf="showErrorNoBuildingTypeSelected">Type woning is een verplicht veld</p-card>

<h4><span class="pi-users"></span>&nbsp;Hoeveel personen wonen er?</h4>
<p>Hoe meer gezinsleden, hoe hoger het verbruik zal zijn.</p>
<div class="grid fluid">
    <div class="row">
        <div class="xl:col-2 lg:col-2 col-12">
            <p-input-group class="justify-content-center" (click)="numberOfPersons = 1; calculateEstimates(); validateNumberOfPersons()">
                <p-radiobutton hidden="true" [(ngModel)]="numberOfPersons" binary="false" [value]="1"></p-radiobutton><label style="text-align: center;">1</label>
            </p-input-group>
        </div>
        <div class="xl:col-2 lg:col-2 col-12">
            <p-input-group class="justify-content-center" (click)="numberOfPersons = 2; calculateEstimates(); validateNumberOfPersons()">
                <p-radiobutton hidden="true" [(ngModel)]="numberOfPersons" binary="false" [value]="2"></p-radiobutton><label style="text-align: center;">2</label>
            </p-input-group>
        </div>
        <div class="xl:col-2 lg:col-2 col-12">
            <p-input-group class="justify-content-center" (click)="numberOfPersons = 3; calculateEstimates(); validateNumberOfPersons()">
                <p-radiobutton hidden="true" [(ngModel)]="numberOfPersons" binary="false" [value]="3"></p-radiobutton><label style="text-align: center;">3</label>
            </p-input-group>
        </div>
        <div class="xl:col-2 lg:col-2 col-12">
            <p-input-group class="justify-content-center" (click)="numberOfPersons = 4; calculateEstimates(); validateNumberOfPersons()">
                <p-radiobutton hidden="true" [(ngModel)]="numberOfPersons" binary="false" [value]="4"></p-radiobutton><label style="text-align: center;">4</label>
            </p-input-group>
        </div>
        <div class="xl:col-2 lg:col-2 col-12">
            <p-input-group class="justify-content-center" (click)="numberOfPersons = 5; calculateEstimates(); validateNumberOfPersons()">
                <p-radiobutton hidden="true" [(ngModel)]="numberOfPersons" binary="false" [value]="5"></p-radiobutton><label style="text-align: center;">5+</label>
            </p-input-group>
        </div>
    </div>
</div>
<p-card class="danger no-padding" *ngIf="showErrorNoNumberOfPersonsSelected">Aantal personen is een verplicht veld</p-card>

<h4>Hoe wil je je facturen ontvangen?</h4>
<p>Als we je facturen digitaal mogen versturen kunnen we je onze online pakketten voorstellen. Deze zijn goedkoper!</p>
<div>
<p-input-group (click)="priceSimulationOptionsEnkelvoudigeMeter.select($event); determineType(); calculateEstimates(); validateDirectDebit();">
    <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates(); validateDirectDebit();" #priceSimulationOptionsEnkelvoudigeMeter [(ngModel)]="priceSimulationOptions.directDebit" binary="false" [value]="PriceSimulationDirectDebit.Ja"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Ja, facturatie mag via email</label></div>
</p-input-group>
<p-input-group (click)="priceSimulationOptionsTweevoudigeMeter.select($event); determineType(); calculateEstimates(); validateDirectDebit();">
    <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates(); validateDirectDebit();" #priceSimulationOptionsTweevoudigeMeter [(ngModel)]="priceSimulationOptions.directDebit" binary="false" [value]="PriceSimulationDirectDebit.Nee"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Nee, ik verkies facturatie per post</label></div>
</p-input-group>
<p-card class="danger no-padding" *ngIf="showErrorNoDirectDebitSelected">Facturatiekeuze is een verplicht veld</p-card>
</div>

<h4>Je geschatte verbruik</h4>
<div class="grid fluid">
    @if (priceSimulationOptions.tariffType == PriceSimulationTariffType.Enkelvoudig) {
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <p-input-group>
                <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-clock ebemGreen" style="font-size: 22px"></span>
                <span>&nbsp;&nbsp;</span>
                <span [ngStyle]="{ color: priceSimulationInput.kwh24u ? 'black' : 'lightgray' }" style="font-size: 30px">{{priceSimulationInput.kwh24u ? (priceSimulationInput.kwh24u | number: '1.0-2') : '0000'}}</span>
                <span>&nbsp;&nbsp;</span>
                <label style="color: gray; font-size: 14px">KWh/jaar</label>
            </p-input-group>
        </div>
    </div>
    } @else if (priceSimulationOptions.tariffType == PriceSimulationTariffType.Tweevoudig) {
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <p-input-group>
                <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-angle-up ebemGreen" style="font-size: 22px"></span>
                <span>&nbsp;&nbsp;</span>
                <span [ngStyle]="{ color: priceSimulationInput.kwhPiek ? 'black' : 'lightgray' }" style="font-size: 30px">{{priceSimulationInput.kwhPiek ? (priceSimulationInput.kwhPiek | number: '1.0-2') : '0000'}}</span>
                <span>&nbsp;&nbsp;</span>
                <label style="color: gray; font-size: 14px">KWh/jaar</label>
            </p-input-group>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <p-input-group>
                <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-angle-down ebemGreen" style="font-size: 22px"></span>
                <span>&nbsp;&nbsp;</span>
                <span [ngStyle]="{ color: priceSimulationInput.kwhDal ? 'black' : 'lightgray' }" style="font-size: 30px">{{priceSimulationInput.kwhDal ? (priceSimulationInput.kwhDal | number: '1.0-2') : '0000'}}</span>
                <span>&nbsp;&nbsp;</span>
                <label style="color: gray; font-size: 14px">KWh/jaar</label>
            </p-input-group>
        </div>
    </div>
    }
    @if(priceSimulationOptions.hasNightTariff) {
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p-input-group>
                    <span class="pi-bolt ebemGreen" style="font-size: 22px"></span><span class="pi-moon ebemGreen" style="font-size: 18px"></span>
                    <span>&nbsp;&nbsp;</span>
                    <span [ngStyle]="{ color: priceSimulationInput.kwhExclNacht ? 'black' : 'lightgray' }" style="font-size: 30px">{{priceSimulationInput.kwhExclNacht ? (priceSimulationInput.kwhExclNacht | number: '1.0-2') : '0000'}}</span>
                    <span>&nbsp;&nbsp;</span>
                    <label style="color: gray; font-size: 14px">KWh/jaar</label>
                </p-input-group>
            </div>
        </div>
    }
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <p-input-group>
                <span class="material-icons ebemBlue" style="font-size: 25px">local_fire_department</span>
                <span>&nbsp;&nbsp;</span>
                <span [ngStyle]="{ color: estimatedGasUse ? 'black' : 'lightgray' }" style="font-size: 30px">{{estimatedGasUse ? (estimatedGasUse  | number: '1.0-2') : '0000'}}</span>
                <span>&nbsp;&nbsp;</span>
                <label style="color: gray; font-size: 14px">KWh/jaar</label>
            </p-input-group>
        </div>
    </div>
</div>
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="60" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/price-calculation', 'step-2b']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Terug</p-button>
    </div>
    <div class="col-6">
        <p-button class="float-right" (click)="validateForm() ? this.router.navigate(['/price-calculation', 'step-2e'], {state: { 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }}) : null">Overzicht <span class="pi-arrow-right"></span></p-button>
    </div>
</div>
</div>