<div class="backgroundLightEbemGreen" [style]="{ borderBottom: '1px solid #436807' }">
    <p-menubar>
        <ng-template #start>
            <a [routerLink]="['/']"><img src="img/logo.png" class="mr-5 xl:max-h-4rem lg:max-h-4rem max-h-3rem" ></a>
            <p-tabs class="fluid xl:flex lg:flex hidden" [(value)]="selectedTab">
                <p-tablist [className]="'backgroundEbemLighterGreen'">
                    <p-tab [value]="0" [routerLink]="['/']">Particulieren</p-tab>
                    <p-tab [value]="1" [routerLink]="['/Zelfstandigen']">Zelfstandigen</p-tab>
                    <p-tab [value]="2" [routerLink]="['/KMO']">KMO's</p-tab>
                </p-tablist>
                <br/>
                <p-tabpanels>
                    <p-tabpanel [value]="0">
                        <p-megamenu [model]="itemsParticulieren" [ariaExpanded]="true"></p-megamenu>
                    </p-tabpanel>
                    <p-tabpanel [value]="1">
                        <p-megamenu [model]="itemsZelfstandigen"></p-megamenu>
                    </p-tabpanel>
                    <p-tabpanel [value]="2">
                        <p-megamenu [model]="itemsKMOs"></p-megamenu>
                    </p-tabpanel>
                </p-tabpanels>
            </p-tabs>
        </ng-template>
        <ng-template #end>
            @if (window.innerWidth < 961) {
                <p-button [outlined]="true" (click)="pop.toggle($event)"><span class="pi pi-bars"></span></p-button>
                <p-popover #pop [dismissable]="true">
                    @for (item of items; track $index) {
                        @if (item.routerLink) {
                            <a [routerLink]="item.routerLink" pRipple [ngClass]="item.styleClass" class="flex items
                            -center p-menubar-item-link">
                                <span [class]="item.icon"></span>
                                <span class="hidden xl:inline lg:inline">{{item.label}}</span>
                                <span *ngIf="item.items" [ngClass]="['ml-auto-pi', 'pi-angle-down']"></span>
                            </a>
                        } @else {
                            <a [href]="item.url" pRipple [ngClass]="item.styleClass" class="flex items-center p-menubar-item-link">
                                <span [class]="item.icon"></span>
                                <span class="hidden xl:inline lg:inline">{{item.label}}</span>
                                <span *ngIf="item.items" [ngClass]="['ml-auto-pi', 'pi-angle-down']"></span>
                            </a>
                        }
                    }
                </p-popover>
            } @else {
                <div class="flex flex-nowrap">
                @for (item of items; track $index) {
                        @if (item.routerLink) {
                            <a [routerLink]="item.routerLink" pRipple [ngClass]="item.styleClass" class="flex items
                            -center p-menubar-item-link">
                                <span [class]="item.icon"></span>
                                <span class="hidden xl:inline lg:inline">{{item.label}}</span>
                                <span *ngIf="item.items" [ngClass]="['ml-auto-pi', 'pi-angle-down']"></span>
                            </a>
                        } @else {
                            <a [href]="item.url" pRipple [ngClass]="item.styleClass" class="flex items-center p-menubar-item-link">
                                <span [class]="item.icon"></span>
                                <span class="hidden xl:inline lg:inline">{{item.label}}</span>
                                <span *ngIf="item.items" [ngClass]="['ml-auto-pi', 'pi-angle-down']"></span>
                            </a>
                        }
                }
                </div>
                <br/>
                @if (selectedTab === 0) {
                    <p-button [routerLink]="['/price-calculation', 'step-1']">Bereken mijn voorstel</p-button>
                }
                @if (selectedTab === 1) {
                    <p-button [routerLink]="['/price-calculation', 'step-1']">Bereken mijn voorstel</p-button>
                }
                @if (selectedTab === 2) {
                    <br/>
                    <br/>
                }
            }
        </ng-template>
    </p-menubar>
    <p-tabs class="xl:hidden lg:hidden md:flex sm:flex" [value]="selectedTab">
        <p-tablist [className]="'backgroundEbemLighterGreen'">
            <p-tab value="0" [routerLink]="['/']">Particulieren</p-tab>
            <p-tab value="1" [routerLink]="['/Zelfstandigen']">Zelfstandigen</p-tab>
            <p-tab value="2" [routerLink]="['/KMO']">KMO's</p-tab>
        </p-tablist>
        <p-tabpanels>
            <p-tabpanel value="0">
                <p-megamenu [model]="itemsParticulieren" [ariaExpanded]="true"></p-megamenu>
            </p-tabpanel>
            <p-tabpanel value="1">
                <p-megamenu [model]="itemsZelfstandigen"></p-megamenu>
            </p-tabpanel>
            <p-tabpanel value="2">
                <p-megamenu [model]="itemsKMOs"></p-megamenu>
            </p-tabpanel>
        </p-tabpanels>
    </p-tabs>
    <br/>
</div>