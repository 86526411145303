import { Component, Predicate } from "@angular/core";
import { AccordionModule } from "primeng/accordion";
import { FAQItem } from "src/app/models/faq.model";
import { FAQComponent } from "../../components/faq/faq.component";
import { FilterPipe } from "src/app/pipes/filter.pipe";
import { CommonModule } from "@angular/common";
import { CardModule } from "primeng/card";
import { ButtonModule } from "primeng/button";
import { TabsModule } from "primeng/tabs";
import { RouterModule } from "@angular/router";
import { UmbracoService } from "src/app/services/umbraco.service";
import { UniquePipe } from "src/app/pipes/unique.pipe";
import { Subscription } from "rxjs";

@Component({
    selector: 'faq-page',
    standalone: true,
    styleUrls: ['./faq.page.scss'],
    templateUrl: './faq.page.html',
    imports: [AccordionModule, ButtonModule, CardModule, CommonModule, FAQComponent, FilterPipe, UniquePipe, RouterModule, TabsModule],
    providers: [FilterPipe, UniquePipe, UmbracoService]
})
export class FAQPage {
    selectedCategory!: string;

    faqItems!: FAQItem[];
    subscribers: Subscription[] = [];

    constructor(private umbracoService: UmbracoService, private uniquePipe: UniquePipe) {
        this.subscribers.push(this.umbracoService.getFaqItems().subscribe(faqItems => { 
            this.faqItems = faqItems;
            this.selectedCategory = this.uniquePipe.transform(faqItems.map(faqItem => faqItem.category), 'category')[0];
        }));
    }

    isPinned(faqItem: FAQItem): boolean {
        return faqItem.isPinned;
    }

    isNotPinned(faqItem: FAQItem): boolean {
        return !faqItem.isPinned;
    }

    isCategory(category: string): Predicate<any> {
        return faqItem => faqItem.category === category;
    }
}