import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { InputNumberModule } from "primeng/inputnumber";
import { Nullable } from "primeng/ts-helpers";
import { PostalCode } from "src/app/models/postalcode.model";
import { PostalcodeService } from "src/app/services/postalcode.service";

@Component({
    selector: 'image-component',
    standalone: true,
    templateUrl: 'image.component.html',
    styleUrls: ['./image.component.scss'],
    imports: [AvatarModule, ButtonModule, CardModule, CommonModule, InputNumberModule, FormsModule, RouterModule],
    providers: [PostalcodeService]
})
export class ImageComponent {
    @Input() imageSrc!: string;
    @Input() text!: string;
    @Input() showAvatar!: boolean;
    @Input() showPostCode!: boolean;
    @Input() avatarSrc!: string;
    @Input() avatarName!: string;
    @Input() avatarTitle!: string;
    @Input() buttonText!: string;
    @Input() buttonRoute!: string[];
    
    inputPostalCodeValid: Nullable<boolean>;
    showErrorNoPostalCodeFilledIn: boolean = false;
    showErrorPostalCodeInvalid: boolean = false;
    postalCode!: string | number | undefined;

    PostalCodes: PostalCode[] = [];

    constructor(private postalcodeService: PostalcodeService, public router: Router){
        this.postalcodeService.getPostalCodes().subscribe(res => {
            this.PostalCodes = res.filter(x => x.isValidArea);
        });
    }

    validatePostalCodeInput() {
        this.inputPostalCodeValid = !this.showPostCode || (!!this.postalCode && !!this.PostalCodes.map(x => x.code).includes(this.postalCode.toString()));
        this.showErrorNoPostalCodeFilledIn = this.showPostCode && !this.postalCode;
        this.showErrorPostalCodeInvalid = this.showPostCode && !!this.postalCode && !this.PostalCodes.map(x => x.code).includes(this.postalCode.toString());
    }

    validateInputs() {
        this.validatePostalCodeInput();
    }

    validateForm() {
        this.validateInputs();

        return this.inputPostalCodeValid;
    }
}