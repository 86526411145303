import { ElectricityType } from "./enums/electricity-type.enum";
import { GasType } from "./enums/gas-type.enum";
import { BillingFrequency } from "./enums/billing-frequency.enum";

export class Product {
    public name!: string;
    public isVariable!: boolean;
    public isDynamic!: boolean;
    public forAnalogMeter!: boolean;
    public invoicing!: BillingFrequency;
    public directDebit!: boolean;
    public onlyElectronicInvoicing!: boolean;
    public individualOrSmallBusiness!: boolean;
    public enumValue!: number;
    public enumType!: string;
    public price!: number;
    public priceDetails!: PriceCalculationDetails[];
}

export class PriceCalculationDetails {
    public description!: string;
    public amount!: number;
}

export function jsonToProduct(json: any): Product {
    let product = new Product();
    product.name = json["name"];
    product.isVariable = json["isVariable"];
    product.isDynamic = json["isDynamic"];
    product.forAnalogMeter = json["forAnalogMeter"];
    product.invoicing = json["invoicing"];
    product.directDebit = json["directDebit"];
    product.onlyElectronicInvoicing = json["onlyElectronicInvoicing"];
    product.individualOrSmallBusiness = json["individualOrSmallBusiness"];
    product.enumValue = json["enumValue"];
    product.enumType = json["enumType"];
    product.price = json["price"];
    product.priceDetails = json["priceDetails"];
    return product;
}