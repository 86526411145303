import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Customer } from "../models/customer.model";
import { Contract } from "../models/contract.model";
import { PaymentDetails } from "../models/payment-details.model";
import { environment } from "src/environments/environment";
import { StringHelper } from "../helpers/string.helper";
import { Observable } from "rxjs";
import { Company } from "../models/company.model";

@Injectable()
export class CustomerService {
    constructor(private http: HttpClient) { }

    getCompanyTypes() {
        return this.http.get<string[]>(`${environment.baseUrl}/Customer/GetAllCompanyType`);
    }

    postCustomer(customer: Customer, contract: Contract, paymentDetails: PaymentDetails): Observable<{success: boolean}> {
        let formData = new FormData();
        if (customer.gender != 0) formData.append('Customer.Gender', customer.gender.toString());
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.firstName)) formData.append('Customer.FirstName', customer.firstName);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.lastName))  formData.append('Customer.LastName', customer.lastName);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.email)) formData.append('Customer.Email', customer.email);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.telephone)) formData.append('Customer.Telephone', customer.telephone);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.streetName)) formData.append('Customer.StreetName', customer.streetName);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.houseNumber)) formData.append('Customer.HouseNumber', customer.houseNumber);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.postalCode)) formData.append('Customer.PostalCode', customer.postalCode?.toString() ?? '');
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.location)) formData.append('Customer.Location', customer.location);
        if (customer.invoiceAddressIsSameToPostalAddress != undefined) formData.append('Customer.InvoiceAddressIsSameToPostalAddress', customer.invoiceAddressIsSameToPostalAddress.toString());
        if (!customer.invoiceAddressIsSameToPostalAddress) {
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressStreetName)) formData.append('Customer.InvoicingAddressStreetName', customer.invoicingAddressStreetName);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressHouseNumber)) formData.append('Customer.InvoicingAddressHouseNumber', customer.invoicingAddressHouseNumber);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressPostalCode)) formData.append('Customer.InvoicingAddressPostalCode', customer.invoicingAddressPostalCode?.toString() ?? '');
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressLocation)) formData.append('Customer.InvoicingAddressLocation', customer.invoicingAddressLocation);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressCountry)) formData.append('Customer.InvoicingAddressCountry', customer.invoicingAddressCountry);
        }
        formData.append('Contract.Situation', contract.situation.toString());
        formData.append('Contract.StartDate', contract.startDate.toDateString());
        if (contract.takeoverDocuments.length > 0) formData.append('Contract.takeoverDocuments', JSON.stringify(contract.takeoverDocuments));
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.electricityEAN)) formData.append('Contract.ElectricityEAN', contract.electricityEAN);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.gasEAN)) formData.append('Contract.GasEAN', contract.gasEAN);
        formData.append('PaymentDetails.BillingFrequency', paymentDetails.billingFrequency.toString());
        formData.append('PaymentDetails.InvoiceModality', paymentDetails.invoiceModality.toString());
        formData.append('PaymentDetails.PaymentPreference', paymentDetails.paymentPreference.toString());
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.iban)) formData.append('PaymentDetails.IBAN', paymentDetails.iban);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.bic)) formData.append('PaymentDetails.BIC', paymentDetails.bic);
        if (paymentDetails.checkingAccountOnSomeoneElsesName != undefined) formData.append('PaymentDetails.CheckingAccountOnSomeoneElsesName', paymentDetails.checkingAccountOnSomeoneElsesName.toString());
        if (paymentDetails.checkingAccountOnSomeoneElsesName) {
            if (paymentDetails.checkingAccountHolder.gender != 0) formData.append('PaymentDetails.CheckingAccountHolder.Gender', paymentDetails.checkingAccountHolder.gender.toString());
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.firstName)) formData.append('PaymentDetails.CheckingAccountHolder.FirstName', paymentDetails.checkingAccountHolder.firstName);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.lastName)) formData.append('PaymentDetails.CheckingAccountHolder.LastName', paymentDetails.checkingAccountHolder.lastName);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.email)) formData.append('PaymentDetails.CheckingAccountHolder.Email', paymentDetails.checkingAccountHolder.email);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.telephone)) formData.append('PaymentDetails.CheckingAccountHolder.Telephone', paymentDetails.checkingAccountHolder.telephone);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.streetName)) formData.append('PaymentDetails.CheckingAccountHolder.StreetName', paymentDetails.checkingAccountHolder.streetName);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.houseNumber)) formData.append('PaymentDetails.CheckingAccountHolder.HouseNumber', paymentDetails.checkingAccountHolder.houseNumber);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.postalCode)) formData.append('PaymentDetails.CheckingAccountHolder.PostalCode', paymentDetails.checkingAccountHolder.postalCode?.toString() ?? '');
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.location)) formData.append('PaymentDetails.CheckingAccountHolder.Location', paymentDetails.checkingAccountHolder.location);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.country)) formData.append('PaymentDetails.CheckingAccountHolder.Country', paymentDetails.checkingAccountHolder.country);
            if (paymentDetails.checkingAccountHolder.invoiceAddressIsSameToPostalAddress != undefined) formData.append('PaymentDetails.CheckingAccountHolder.InvoiceAddressIsSameToPostalAddress', paymentDetails.checkingAccountHolder.invoiceAddressIsSameToPostalAddress.toString());
        }
        return this.http.post<{success: boolean}>(`${environment.baseUrl}/Customer`, formData);
    }

    postCompany(company: Company, contract: Contract, paymentDetails: PaymentDetails): Observable<{success: boolean}> {
        let formData = new FormData();
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.companyType)) formData.append('Company.CompanyType', company.companyType);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.companyName)) formData.append('Company.CompanyName', company.companyName);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.companyNumber)) formData.append('Company.CompanyNumber', company.companyNumber);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.nacebelCode)) formData.append('Company.NacebelCode', company.nacebelCode);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.country)) formData.append('Company.Country', company.country);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.firstName)) formData.append('Company.ContactPerson.FirstName', company.contactPerson.firstName);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.lastName)) formData.append('Company.ContactPerson.LastName', company.contactPerson.lastName);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.function)) formData.append('Company.ContactPerson.Function', company.contactPerson.function);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.telephone)) formData.append('Company.ContactPerson.Telephone', company.contactPerson.telephone);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.email)) formData.append('Company.Email', company.email);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.telephone)) formData.append('Company.Telephone', company.telephone);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.streetName)) formData.append('Company.StreetName', company.streetName);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.houseNumber)) formData.append('Company.HouseNumber', company.houseNumber);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.postalCode)) formData.append('Company.PostalCode', company.postalCode?.toString() ?? '');
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.location)) formData.append('Company.Location', company.location);
        if (company.invoiceAddressIsSameToPostalAddress != undefined) formData.append('Company.InvoiceAddressIsSameToPostalAddress', company.invoiceAddressIsSameToPostalAddress.toString());
        if (!company.invoiceAddressIsSameToPostalAddress) {
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressStreetName)) formData.append('Company.InvoicingAddressStreetName', company.invoicingAddressStreetName);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressHouseNumber)) formData.append('Company.InvoicingAddressHouseNumber', company.invoicingAddressHouseNumber);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressPostalCode)) formData.append('Company.InvoicingAddressPostalCode', company.invoicingAddressPostalCode?.toString() ?? '');
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressLocation)) formData.append('Company.InvoicingAddressLocation', company.invoicingAddressLocation);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressCountry)) formData.append('Company.InvoicingAddressCountry', company.invoicingAddressCountry);
        }

        formData.append('Contract.Situation', contract.situation.toString());
        formData.append('Contract.StartDate', contract.startDate.toDateString());
        if (contract.takeoverDocuments.length > 0) formData.append('Contract.takeoverDocuments', JSON.stringify(contract.takeoverDocuments));
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.electricityEAN)) formData.append('Contract.ElectricityEAN', contract.electricityEAN);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.gasEAN)) formData.append('Contract.GasEAN', contract.gasEAN);
        formData.append('PaymentDetails.BillingFrequency', paymentDetails.billingFrequency.toString());
        formData.append('PaymentDetails.InvoiceModality', paymentDetails.invoiceModality.toString());
        formData.append('PaymentDetails.PaymentPreference', paymentDetails.paymentPreference.toString());
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.iban)) formData.append('PaymentDetails.IBAN', paymentDetails.iban);
        if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.bic)) formData.append('PaymentDetails.BIC', paymentDetails.bic);
        if (paymentDetails.checkingAccountOnSomeoneElsesName != undefined) formData.append('PaymentDetails.CheckingAccountOnSomeoneElsesName', paymentDetails.checkingAccountOnSomeoneElsesName.toString());
        if (paymentDetails.checkingAccountOnSomeoneElsesName) {
            if (paymentDetails.checkingAccountHolder.gender != 0) formData.append('PaymentDetails.CheckingAccountHolder.Gender', paymentDetails.checkingAccountHolder.gender.toString());
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.firstName)) formData.append('PaymentDetails.CheckingAccountHolder.FirstName', paymentDetails.checkingAccountHolder.firstName);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.lastName)) formData.append('PaymentDetails.CheckingAccountHolder.LastName', paymentDetails.checkingAccountHolder.lastName);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.email)) formData.append('PaymentDetails.CheckingAccountHolder.Email', paymentDetails.checkingAccountHolder.email);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.telephone)) formData.append('PaymentDetails.CheckingAccountHolder.Telephone', paymentDetails.checkingAccountHolder.telephone);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.streetName)) formData.append('PaymentDetails.CheckingAccountHolder.StreetName', paymentDetails.checkingAccountHolder.streetName);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.houseNumber)) formData.append('PaymentDetails.CheckingAccountHolder.HouseNumber', paymentDetails.checkingAccountHolder.houseNumber);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.postalCode)) formData.append('PaymentDetails.CheckingAccountHolder.PostalCode', paymentDetails.checkingAccountHolder.postalCode?.toString() ?? '');
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.location)) formData.append('PaymentDetails.CheckingAccountHolder.Location', paymentDetails.checkingAccountHolder.location);
            if (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.country)) formData.append('PaymentDetails.CheckingAccountHolder.Country', paymentDetails.checkingAccountHolder.country);
            if (paymentDetails.checkingAccountHolder.invoiceAddressIsSameToPostalAddress != undefined) formData.append('PaymentDetails.CheckingAccountHolder.InvoiceAddressIsSameToPostalAddress', paymentDetails.checkingAccountHolder.invoiceAddressIsSameToPostalAddress.toString());
        }
        return this.http.post<{success: boolean}>(`${environment.baseUrl}/Customer/Company`, formData);
    }
}