import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, Router, RouterStateSnapshot } from "@angular/router";
import { StringHelper } from "src/app/helpers/string.helper";
import { Company } from "src/app/models/company.model";

@Injectable()
export class CustomerWizardStep3SelfEmployedGuard implements CanActivate {
    constructor(public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardResult {
        const extras = this.router.getCurrentNavigation()?.extras;
        const company: Company = extras?.state?.["company"];
        return (extras?.state?.["electricityType"] ? true : false || extras?.state?.['gasType'] ? true : false) 
        && ((!!company && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.companyType) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.nacebelCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.companyNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.firstName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.lastName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.contactPerson.telephone) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.telephone) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.streetName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.houseNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.postalCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.location) && (company.invoiceAddressIsSameToPostalAddress || (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressStreetName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressHouseNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressPostalCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(company.invoicingAddressLocation)))) ? true : false);
    }
}