export enum BelgianBankCodeToBic {
"0000"="GEBABEBB",
"0001"="GEBABEBB",
"0002"="GEBABEBB",
"0003"="GEBABEBB",
"0004"="GEBABEBB",
"0005"="GEBABEBB",
"0006"="GEBABEBB",
"0007"="GEBABEBB",
"0008"="GEBABEBB",
"0009"="GEBABEBB",
"0010"="GEBABEBB",
"0011"="GEBABEBB",
"0012"="GEBABEBB",
"0013"="GEBABEBB",
"0014"="GEBABEBB",
"0015"="GEBABEBB",
"0016"="GEBABEBB",
"0017"="GEBABEBB",
"0018"="GEBABEBB",
"0019"="GEBABEBB",
"0020"="GEBABEBB",
"0021"="GEBABEBB",
"0022"="GEBABEBB",
"0023"="GEBABEBB",
"0024"="GEBABEBB",
"0025"="GEBABEBB",
"0026"="GEBABEBB",
"0027"="GEBABEBB",
"0028"="GEBABEBB",
"0029"="GEBABEBB",
"0030"="GEBABEBB",
"0031"="GEBABEBB",
"0032"="GEBABEBB",
"0033"="GEBABEBB",
"0034"="GEBABEBB",
"0035"="GEBABEBB",
"0036"="GEBABEBB",
"0037"="GEBABEBB",
"0038"="GEBABEBB",
"0039"="GEBABEBB",
"0040"="GEBABEBB",
"0041"="GEBABEBB",
"0042"="GEBABEBB",
"0043"="GEBABEBB",
"0044"="GEBABEBB",
"0045"="GEBABEBB",
"0046"="GEBABEBB",
"0047"="GEBABEBB",
"0048"="GEBABEBB",
"0049"="GEBABEBB",
"0050"="GKCCBEBB",
"0051"="GKCCBEBB",
"0052"="GKCCBEBB",
"0053"="GKCCBEBB",
"0054"="GKCCBEBB",
"0055"="GKCCBEBB",
"0056"="GKCCBEBB",
"0057"="GKCCBEBB",
"0058"="GKCCBEBB",
"0059"="GKCCBEBB",
"0060"="GKCCBEBB",
"0061"="GKCCBEBB",
"0062"="GKCCBEBB",
"0063"="GKCCBEBB",
"0064"="GKCCBEBB",
"0065"="GKCCBEBB",
"0066"="GKCCBEBB",
"0067"="GKCCBEBB",
"0068"="GKCCBEBB",
"0069"="GKCCBEBB",
"0070"="GKCCBEBB",
"0071"="GKCCBEBB",
"0072"="GKCCBEBB",
"0073"="GKCCBEBB",
"0074"="GKCCBEBB",
"0075"="GKCCBEBB",
"0076"="GKCCBEBB",
"0077"="GKCCBEBB",
"0078"="GKCCBEBB",
"0079"="GKCCBEBB",
"0080"="GKCCBEBB",
"0081"="GKCCBEBB",
"0082"="GKCCBEBB",
"0083"="GKCCBEBB",
"0084"="GKCCBEBB",
"0085"="GKCCBEBB",
"0086"="GKCCBEBB",
"0087"="GKCCBEBB",
"0088"="GKCCBEBB",
"0089"="GKCCBEBB",
"0090"="GKCCBEBB",
"0091"="GKCCBEBB",
"0092"="GKCCBEBB",
"0093"="GKCCBEBB",
"0094"="GKCCBEBB",
"0095"="GKCCBEBB",
"0096"="GKCCBEBB",
"0097"="GKCCBEBB",
"0098"="GKCCBEBB",
"0099"="GKCCBEBB",
"0100"="NBBEBEBB203",
"0101"="NBBEBEBBHCC",
"0102"="nav",
"0103"="NICABEBB",
"0104"="NICABEBB",
"0105"="NICABEBB",
"0106"="NICABEBB",
"0107"="NICABEBB",
"0108"="NICABEBB",
"0109"="CTBKBEBX",
"0110"="CTBKBEBX",
"0111"="ABERBE22",
"0112"="VRIJ",
"0113"="CTBKBEBX",
"0114"="CTBKBEBX",
"0115"="VRIJ",
"0116"="VRIJ",
"0117"="VRIJ",
"0118"="VRIJ",
"0119"="CTBKBEBX",
"0120"="CTBKBEBX",
"0121"="CTBKBEBX",
"0122"="CTBKBEBX",
"0123"="CTBKBEBX",
"0124"="CTBKBEBX",
"0125"="CPHBBE75",
"0126"="CPHBBE75",
"0127"="CTBKBEBX",
"0128"="VRIJ",
"0129"="CTBKBEBX",
"0130"="DIGEBEB2",
"0131"="CTBKBEBX",
"0132"="BNAGBEBB",
"0133"="CTBKBEBX",
"0134"="CTBKBEBX",
"0135"="VRIJ",
"0136"="VRIJ",
"0137"="GEBABEBB",
"0138"="NAP",
"0139"="VRIJ",
"0140"="GEBABEBB",
"0141"="GEBABEBB",
"0142"="GEBABEBB",
"0143"="GEBABEBB",
"0144"="GEBABEBB",
"0145"="GEBABEBB",
"0146"="GEBABEBB",
"0147"="GEBABEBB",
"0148"="GEBABEBB",
"0149"="GEBABEBB",
"0150"="BCMCBEBB",
"0151"="VRIJ",
"0152"="VRIJ",
"0153"="VRIJ",
"0154"="nav",
"0155"="VRIJ",
"0156"="VRIJ",
"0157"="VRIJ",
"0158"="VRIJ",
"0159"="VRIJ",
"0160"="VRIJ",
"0161"="VRIJ",
"0162"="VRIJ",
"0163"="VRIJ",
"0164"="VRIJ",
"0165"="VRIJ",
"0166"="VRIJ",
"0167"="VRIJ",
"0168"="VRIJ",
"0169"="VRIJ",
"0170"="VRIJ",
"0171"="CPHBBE75",
"0172"="VRIJ",
"0173"="VRIJ",
"0174"="VRIJ",
"0175"="NAV",
"0176"="BSCHBEBBRET",
"0177"="VRIJ",
"0178"="VRIJ",
"0179"="VRIJ",
"0180"="VRIJ",
"0181"="VRIJ",
"0182"="VRIJ",
"0183"="VRIJ",
"0184"="VRIJ",
"0185"="BBRUBEBB",
"0186"="VRIJ",
"0187"="VRIJ",
"0188"="VRIJ",
"0189"="SMBCBEBB",
"0190"="CREGBEBB",
"0191"="CREGBEBB",
"0192"="CREGBEBB",
"0193"="CREGBEBB",
"0194"="CREGBEBB",
"0195"="CREGBEBB",
"0196"="CREGBEBB",
"0197"="CREGBEBB",
"0198"="CREGBEBB",
"0199"="CREGBEBB",
"0200"="GEBABEBB",
"0201"="GEBABEBB",
"0202"="GEBABEBB",
"0203"="GEBABEBB",
"0204"="GEBABEBB",
"0205"="GEBABEBB",
"0206"="GEBABEBB",
"0207"="GEBABEBB",
"0208"="GEBABEBB",
"0209"="GEBABEBB",
"0210"="GEBABEBB",
"0211"="GEBABEBB",
"0212"="GEBABEBB",
"0213"="GEBABEBB",
"0214"="GEBABEBB",
"0215"="VRIJ",
"0216"="VRIJ",
"0217"="VRIJ",
"0218"="VRIJ",
"0219"="VRIJ",
"0220"="GEBABEBB",
"0221"="GEBABEBB",
"0222"="GEBABEBB",
"0223"="GEBABEBB",
"0224"="GEBABEBB",
"0225"="GEBABEBB",
"0226"="GEBABEBB",
"0227"="GEBABEBB",
"0228"="GEBABEBB",
"0229"="GEBABEBB",
"0230"="GEBABEBB",
"0231"="GEBABEBB",
"0232"="GEBABEBB",
"0233"="GEBABEBB",
"0234"="GEBABEBB",
"0235"="GEBABEBB",
"0236"="GEBABEBB",
"0237"="GEBABEBB",
"0238"="GEBABEBB",
"0239"="GEBABEBB",
"0240"="GEBABEBB",
"0241"="GEBABEBB",
"0242"="GEBABEBB",
"0243"="GEBABEBB",
"0244"="GEBABEBB",
"0245"="GEBABEBB",
"0246"="GEBABEBB",
"0247"="GEBABEBB",
"0248"="GEBABEBB",
"0249"="GEBABEBB",
"0250"="GEBABEBB",
"0251"="GEBABEBB",
"0252"="GEBABEBB",
"0253"="GEBABEBB",
"0254"="GEBABEBB",
"0255"="GEBABEBB",
"0256"="GEBABEBB",
"0257"="GEBABEBB",
"0258"="GEBABEBB",
"0259"="GEBABEBB",
"0260"="GEBABEBB",
"0261"="GEBABEBB",
"0262"="GEBABEBB",
"0263"="GEBABEBB",
"0264"="GEBABEBB",
"0265"="GEBABEBB",
"0266"="GEBABEBB",
"0267"="GEBABEBB",
"0268"="GEBABEBB",
"0269"="GEBABEBB",
"0270"="GEBABEBB",
"0271"="GEBABEBB",
"0272"="GEBABEBB",
"0273"="GEBABEBB",
"0274"="GEBABEBB",
"0275"="GEBABEBB",
"0276"="GEBABEBB",
"0277"="GEBABEBB",
"0278"="GEBABEBB",
"0279"="GEBABEBB",
"0280"="GEBABEBB",
"0281"="GEBABEBB",
"0282"="GEBABEBB",
"0283"="GEBABEBB",
"0284"="GEBABEBB",
"0285"="GEBABEBB",
"0286"="GEBABEBB",
"0287"="GEBABEBB",
"0288"="GEBABEBB",
"0289"="GEBABEBB",
"0290"="GEBABEBB",
"0291"="GEBABEBB",
"0292"="GEBABEBB",
"0293"="GEBABEBB",
"0294"="GEBABEBB",
"0295"="GEBABEBB",
"0296"="GEBABEBB",
"0297"="GEBABEBB",
"0298"="GEBABEBB",
"0299"="GEBABEBB",
"0300"="BBRUBEBB",
"0301"="BBRUBEBB",
"0302"="BBRUBEBB",
"0303"="BBRUBEBB",
"0304"="BBRUBEBB",
"0305"="BBRUBEBB",
"0306"="BBRUBEBB",
"0307"="BBRUBEBB",
"0308"="BBRUBEBB",
"0309"="BBRUBEBB",
"0310"="BBRUBEBB",
"0311"="BBRUBEBB",
"0312"="BBRUBEBB",
"0313"="BBRUBEBB",
"0314"="BBRUBEBB",
"0315"="BBRUBEBB",
"0316"="BBRUBEBB",
"0317"="BBRUBEBB",
"0318"="BBRUBEBB",
"0319"="BBRUBEBB",
"0320"="BBRUBEBB",
"0321"="BBRUBEBB",
"0322"="BBRUBEBB",
"0323"="BBRUBEBB",
"0324"="BBRUBEBB",
"0325"="BBRUBEBB",
"0326"="BBRUBEBB",
"0327"="BBRUBEBB",
"0328"="BBRUBEBB",
"0329"="BBRUBEBB",
"0330"="BBRUBEBB",
"0331"="BBRUBEBB",
"0332"="BBRUBEBB",
"0333"="BBRUBEBB",
"0334"="BBRUBEBB",
"0335"="BBRUBEBB",
"0336"="BBRUBEBB",
"0337"="BBRUBEBB",
"0338"="BBRUBEBB",
"0339"="BBRUBEBB",
"0340"="BBRUBEBB",
"0341"="BBRUBEBB",
"0342"="BBRUBEBB",
"0343"="BBRUBEBB",
"0344"="BBRUBEBB",
"0345"="BBRUBEBB",
"0346"="BBRUBEBB",
"0347"="BBRUBEBB",
"0348"="BBRUBEBB",
"0349"="BBRUBEBB",
"0350"="BBRUBEBB",
"0351"="BBRUBEBB",
"0352"="BBRUBEBB",
"0353"="BBRUBEBB",
"0354"="BBRUBEBB",
"0355"="BBRUBEBB",
"0356"="BBRUBEBB",
"0357"="BBRUBEBB",
"0358"="BBRUBEBB",
"0359"="BBRUBEBB",
"0360"="BBRUBEBB",
"0361"="BBRUBEBB",
"0362"="BBRUBEBB",
"0363"="BBRUBEBB",
"0364"="BBRUBEBB",
"0365"="BBRUBEBB",
"0366"="BBRUBEBB",
"0367"="BBRUBEBB",
"0368"="BBRUBEBB",
"0369"="BBRUBEBB",
"0370"="BBRUBEBB",
"0371"="BBRUBEBB",
"0372"="BBRUBEBB",
"0373"="BBRUBEBB",
"0374"="BBRUBEBB",
"0375"="BBRUBEBB",
"0376"="BBRUBEBB",
"0377"="BBRUBEBB",
"0378"="BBRUBEBB",
"0379"="BBRUBEBB",
"0380"="BBRUBEBB",
"0381"="BBRUBEBB",
"0382"="BBRUBEBB",
"0383"="BBRUBEBB",
"0384"="BBRUBEBB",
"0385"="BBRUBEBB",
"0386"="BBRUBEBB",
"0387"="BBRUBEBB",
"0388"="BBRUBEBB",
"0389"="BBRUBEBB",
"0390"="BBRUBEBB",
"0391"="BBRUBEBB",
"0392"="BBRUBEBB",
"0393"="BBRUBEBB",
"0394"="BBRUBEBB",
"0395"="BBRUBEBB",
"0396"="BBRUBEBB",
"0397"="BBRUBEBB",
"0398"="BBRUBEBB",
"0399"="BBRUBEBB",
"0400"="KREDBEBB",
"0401"="KREDBEBB",
"0402"="KREDBEBB",
"0403"="KREDBEBB",
"0404"="KREDBEBB",
"0405"="KREDBEBB",
"0406"="KREDBEBB",
"0407"="KREDBEBB",
"0408"="KREDBEBB",
"0409"="KREDBEBB",
"0410"="KREDBEBB",
"0411"="KREDBEBB",
"0412"="KREDBEBB",
"0413"="KREDBEBB",
"0414"="KREDBEBB",
"0415"="KREDBEBB",
"0416"="KREDBEBB",
"0417"="KREDBEBB",
"0418"="KREDBEBB",
"0419"="KREDBEBB",
"0420"="KREDBEBB",
"0421"="KREDBEBB",
"0422"="KREDBEBB",
"0423"="KREDBEBB",
"0424"="KREDBEBB",
"0425"="KREDBEBB",
"0426"="KREDBEBB",
"0427"="KREDBEBB",
"0428"="KREDBEBB",
"0429"="KREDBEBB",
"0430"="KREDBEBB",
"0431"="KREDBEBB",
"0432"="KREDBEBB",
"0433"="KREDBEBB",
"0434"="KREDBEBB",
"0435"="KREDBEBB",
"0436"="KREDBEBB",
"0437"="KREDBEBB",
"0438"="KREDBEBB",
"0439"="KREDBEBB",
"0440"="KREDBEBB",
"0441"="KREDBEBB",
"0442"="KREDBEBB",
"0443"="KREDBEBB",
"0444"="KREDBEBB",
"0445"="KREDBEBB",
"0446"="KREDBEBB",
"0447"="KREDBEBB",
"0448"="KREDBEBB",
"0449"="KREDBEBB",
"0450"="KREDBEBB",
"0451"="KREDBEBB",
"0452"="KREDBEBB",
"0453"="KREDBEBB",
"0454"="KREDBEBB",
"0455"="KREDBEBB",
"0456"="KREDBEBB",
"0457"="KREDBEBB",
"0458"="KREDBEBB",
"0459"="KREDBEBB",
"0460"="KREDBEBB",
"0461"="KREDBEBB",
"0462"="KREDBEBB",
"0463"="KREDBEBB",
"0464"="KREDBEBB",
"0465"="KREDBEBB",
"0466"="KREDBEBB",
"0467"="KREDBEBB",
"0468"="KREDBEBB",
"0469"="KREDBEBB",
"0470"="KREDBEBB",
"0471"="KREDBEBB",
"0472"="KREDBEBB",
"0473"="KREDBEBB",
"0474"="KREDBEBB",
"0475"="KREDBEBB",
"0476"="KREDBEBB",
"0477"="KREDBEBB",
"0478"="KREDBEBB",
"0479"="KREDBEBB",
"0480"="KREDBEBB",
"0481"="KREDBEBB",
"0482"="KREDBEBB",
"0483"="KREDBEBB",
"0484"="KREDBEBB",
"0485"="KREDBEBB",
"0486"="KREDBEBB",
"0487"="KREDBEBB",
"0488"="KREDBEBB",
"0489"="KREDBEBB",
"0490"="KREDBEBB",
"0491"="KREDBEBB",
"0492"="KREDBEBB",
"0493"="KREDBEBB",
"0494"="KREDBEBB",
"0495"="KREDBEBB",
"0496"="KREDBEBB",
"0497"="KREDBEBB",
"0498"="KREDBEBB",
"0499"="KREDBEBB",
"0500"="MTPSBEBB",
"0501"="DHBNBEBB",
"0502"="VRIJ",
"0503"="VRIJ",
"0504"="PANXBEB1",
"0505"="VRIJ",
"0506"="VRIJ",
"0507"="DIERBE21",
"0508"="PARBBEBZMDC",
"0509"="ABNABE2AIPC",
"0510"="VAPEBE22",
"0511"="VRIJ",
"0512"="DNIBBE21",
"0513"="ABNABE2AIPC",
"0514"="PUILBEBB",
"0515"="IRVTBEBB",
"0516"="VRIJ",
"0517"="VRIJ",
"0518"="VRIJ",
"0519"="VRIJ",
"0520"="NAP",
"0521"="FVLBBE22",
"0522"="UTWBBEBB",
"0523"="TRIOBEBB",
"0524"="WAFABEBB",
"0525"="FVLBBE22",
"0526"="VRIJ",
"0527"="VRIJ",
"0528"="VRIJ",
"0529"="NAV",
"0530"="VRIJ",
"0531"="VRIJ",
"0532"="VRIJ",
"0533"="VRIJ",
"0534"="VRIJ",
"0535"="VRIJ",
"0536"="VRIJ",
"0537"="VRIJ",
"0538"="nav",
"0539"="NAP",
"0540"="VRIJ",
"0541"="BKIDBE22",
"0542"="VRIJ",
"0543"="VRIJ",
"0544"="VRIJ",
"0545"="NAP",
"0546"="WAFABEBB",
"0547"="VRIJ",
"0548"="LOCYBEBB",
"0549"="CHASBEBX",
"0550"="GKCCBEBB",
"0551"="GKCCBEBB",
"0552"="GKCCBEBB",
"0553"="GKCCBEBB",
"0554"="GKCCBEBB",
"0555"="GKCCBEBB",
"0556"="GKCCBEBB",
"0557"="GKCCBEBB",
"0558"="GKCCBEBB",
"0559"="GKCCBEBB",
"0560"="GKCCBEBB",
"0561"="VRIJ",
"0562"="GKCCBEBB",
"0563"="GKCCBEBB",
"0564"="GKCCBEBB",
"0565"="GKCCBEBB",
"0566"="GKCCBEBB",
"0567"="GKCCBEBB",
"0568"="GKCCBEBB",
"0569"="GKCCBEBB",
"0570"="CITIBEBX",
"0571"="CITIBEBX",
"0572"="CITIBEBX",
"0573"="CITIBEBX",
"0574"="CITIBEBX",
"0575"="CITIBEBX",
"0576"="CITIBEBX",
"0577"="CITIBEBX",
"0578"="CITIBEBX",
"0579"="CITIBEBX",
"0580"="VRIJ",
"0581"="MHCBBEBB",
"0582"="VRIJ",
"0583"="DEGRBEBB",
"0584"="VRIJ",
"0585"="RCBPBEBB",
"0586"="CFFRBEB1",
"0587"="BIBLBE21",
"0588"="CMCIBEB1BTB",
"0589"="VRIJ",
"0590"="BSCHBEBB",
"0591"="BSCHBEBB",
"0592"="BSCHBEBB",
"0593"="BSCHBEBB",
"0594"="BSCHBEBB",
"0595"="CTBKBEBX",
"0596"="CTBKBEBX",
"0597"="CTBKBEBX",
"0598"="CTBKBEBX",
"0599"="CTBKBEBX",
"0600"="CTBKBEBX",
"0601"="CTBKBEBX",
"0602"="VRIJ",
"0603"="VRIJ",
"0604"="VRIJ",
"0605"="BKCHBEBB",
"0606"="VRIJ",
"0607"="ICBKBEBB",
"0608"="VRIJ",
"0609"="VRIJ",
"0610"="DEUTBEBE",
"0611"="DEUTBEBE",
"0612"="DEUTBEBE",
"0613"="DEUTBEBE",
"0614"="VRIJ",
"0615"="VRIJ",
"0616"="VRIJ",
"0617"="VRIJ",
"0618"="VRIJ",
"0619"="VRIJ",
"0620"="VRIJ",
"0621"="VRIJ",
"0622"="VRIJ",
"0623"="VRIJ",
"0624"="GKCCBEBB",
"0625"="GKCCBEBB",
"0626"="VRIJ",
"0627"="VRIJ",
"0628"="VRIJ",
"0629"="VRIJ",
"0630"="BBRUBEBB",
"0631"="BBRUBEBB",
"0632"="VRIJ",
"0633"="VRIJ",
"0634"="BNAGBEBB",
"0635"="BNAGBEBB",
"0636"="BNAGBEBB",
"0637"="VRIJ",
"0638"="GKCCBEBB",
"0639"="VRIJ",
"0640"="ADIABE22",
"0641"="VRIJ",
"0642"="BBVABEBB",
"0643"="BMPBBEBB",
"0644"="NAV",
"0645"="JVBABE22",
"0646"="BNAGBEBB",
"0647"="BNAGBEBB",
"0648"="BMPBBEBBVOD",
"0649"="CEPABEB2",
"0650"="REVOBEB2XXX",
"0651"="KEYTBEBB",
"0652"="BBRUBEBB",
"0653"="BARCBEBB",
"0654"="nav",
"0655"="VRIJ",
"0656"="VRIJ",
"0657"="GKCCBEBB",
"0658"="HABBBEBB",
"0659"="VRIJ",
"0660"="VRIJ",
"0661"="VRIJ",
"0662"="VRIJ",
"0663"="BMEUBEB1",
"0664"="BCDMBEBB",
"0665"="VRIJ",
"0666"="nav",
"0667"="CMCIBEB1CIC",
"0668"="SBINBE2X",
"0669"="nav",
"0670"="NYA",
"0671"="EURBBE99",
"0672"="GKCCBEBB",
"0673"="BBRUBEBB",
"0674"="ABNABE2AIDJ",
"0675"="BYBBBEBB",
"0676"="DEGRBEBB",
"0677"="CBPXBE99",
"0678"="DELEBE22",
"0679"="PCHQBEBB",
"0680"="GKCCBEBB",
"0681"="VRIJ",
"0682"="GKCCBEBB",
"0683"="GKCCBEBB",
"0684"="SGABBEB2",
"0685"="BOFABE3X",
"0686"="BOFABE3X",
"0687"="MGTCBEBE",
"0688"="SGABBEB2",
"0689"="BOFABE3X",
"0690"="VRIJ",
"0691"="VRIJ",
"0692"="VRIJ",
"0693"="BOTKBEBX",
"0694"="DEUTBEBE",
"0695"="VRIJ",
"0696"="CRLYBEBB",
"0697"="VRIJ",
"0698"="VRIJ",
"0699"="MGTCBEBE",
"0700"="AXABBE22",
"0701"="AXABBE22",
"0702"="AXABBE22",
"0703"="AXABBE22",
"0704"="AXABBE22",
"0705"="AXABBE22",
"0706"="AXABBE22",
"0707"="AXABBE22",
"0708"="AXABBE22",
"0709"="AXABBE22",
"0710"="VRIJ",
"0711"="VRIJ",
"0712"="VRIJ",
"0713"="VRIJ",
"0714"="VRIJ",
"0715"="VRIJ",
"0716"="VRIJ",
"0717"="VRIJ",
"0718"="VRIJ",
"0719"="ABNABE2AXXX",
"0720"="VRIJ",
"0721"="VRIJ",
"0722"="ABNABE2AIPC",
"0723"="VRIJ",
"0724"="VRIJ",
"0725"="KREDBEBB",
"0726"="KREDBEBB",
"0727"="KREDBEBB",
"0728"="CREGBEBB",
"0729"="CREGBEBB",
"0730"="KREDBEBB",
"0731"="KREDBEBB",
"0732"="CREGBEBB",
"0733"="KREDBEBB",
"0734"="KREDBEBB",
"0735"="KREDBEBB",
"0736"="KREDBEBB",
"0737"="KREDBEBB",
"0738"="KREDBEBB",
"0739"="KREDBEBB",
"0740"="KREDBEBB",
"0741"="KREDBEBB",
"0742"="CREGBEBB",
"0743"="KREDBEBB",
"0744"="KREDBEBB",
"0745"="KREDBEBB",
"0746"="KREDBEBB",
"0747"="KREDBEBB",
"0748"="KREDBEBB",
"0749"="KREDBEBB",
"0750"="AXABBE22",
"0751"="AXABBE22",
"0752"="AXABBE22",
"0753"="AXABBE22",
"0754"="AXABBE22",
"0755"="AXABBE22",
"0756"="AXABBE22",
"0757"="AXABBE22",
"0758"="AXABBE22",
"0759"="AXABBE22",
"0760"="AXABBE22",
"0761"="AXABBE22",
"0762"="AXABBE22",
"0763"="AXABBE22",
"0764"="AXABBE22",
"0765"="AXABBE22",
"0766"="VRIJ",
"0767"="VRIJ",
"0768"="VRIJ",
"0769"="VRIJ",
"0770"="VRIJ",
"0771"="VRIJ",
"0772"="AXABBE22",
"0773"="AXABBE22",
"0774"="AXABBE22",
"0775"="GKCCBEBB",
"0776"="GKCCBEBB",
"0777"="GKCCBEBB",
"0778"="GKCCBEBB",
"0779"="GKCCBEBB",
"0780"="GKCCBEBB",
"0781"="GKCCBEBB",
"0782"="GKCCBEBB",
"0783"="GKCCBEBB",
"0784"="GKCCBEBB",
"0785"="GKCCBEBB",
"0786"="GKCCBEBB",
"0787"="GKCCBEBB",
"0788"="GKCCBEBB",
"0789"="GKCCBEBB",
"0790"="GKCCBEBB",
"0791"="GKCCBEBB",
"0792"="GKCCBEBB",
"0793"="GKCCBEBB",
"0794"="GKCCBEBB",
"0795"="GKCCBEBB",
"0796"="GKCCBEBB",
"0797"="GKCCBEBB",
"0798"="GKCCBEBB",
"0799"="GKCCBEBB",
"0800"="AXABBE22",
"0801"="AXABBE22",
"0802"="AXABBE22",
"0803"="AXABBE22",
"0804"="AXABBE22",
"0805"="AXABBE22",
"0806"="AXABBE22",
"0807"="VRIJ",
"0808"="VRIJ",
"0809"="VRIJ",
"0810"="VRIJ",
"0811"="VRIJ",
"0812"="VRIJ",
"0813"="VRIJ",
"0814"="VRIJ",
"0815"="VRIJ",
"0816"="VRIJ",
"0817"="ISAEBEBB",
"0818"="VRIJ",
"0819"="VRIJ",
"0820"="VRIJ",
"0821"="VRIJ",
"0822"="VRIJ",
"0823"="BLUXBEBB",
"0824"="NAV",
"0825"="DEUTBEBE",
"0826"="DEUTBEBE",
"0827"="VRIJ",
"0828"="BBRUBEBB",
"0829"="VRIJ",
"0830"="GKCCBEBB",
"0831"="GKCCBEBB",
"0832"="GKCCBEBB",
"0833"="GKCCBEBB",
"0834"="GKCCBEBB",
"0835"="GKCCBEBB",
"0836"="GKCCBEBB",
"0837"="GKCCBEBB",
"0838"="GKCCBEBB",
"0839"="GKCCBEBB",
"0840"="PRIBBEBB",
"0841"="VRIJ",
"0842"="VRIJ",
"0843"="VRIJ",
"0844"="VRIJ",
"0845"="DEGRBEBB",
"0846"="VRIJ",
"0847"="VRIJ",
"0848"="VRIJ",
"0849"="VRIJ",
"0850"="NICABEBB",
"0851"="NICABEBB",
"0852"="NICABEBB",
"0853"="NICABEBB",
"0854"="VRIJ",
"0855"="VRIJ",
"0856"="VRIJ",
"0857"="VRIJ",
"0858"="VRIJ",
"0859"="NICABEBB",
"0860"="NICABEBB",
"0861"="VRIJ",
"0862"="NICABEBB",
"0863"="NICABEBB",
"0864"="VRIJ",
"0865"="NICABEBB",
"0866"="NICABEBB",
"0867"="VRIJ",
"0868"="KREDBEBB",
"0869"="VRIJ",
"0870"="VRIJ",
"0871"="BNAGBEBB",
"0872"="VRIJ",
"0873"="PCHQBEBB",
"0874"="VRIJ",
"0875"="VRIJ-LIBRE",
"0876"="MBWMBEBB",
"0877"="BNAGBEBB",
"0878"="BNAGBEBB",
"0879"="BNAGBEBB",
"0880"="BBRUBEBB",
"0881"="BBRUBEBB",
"0882"="VRIJ",
"0883"="BBRUBEBB",
"0884"="BBRUBEBB",
"0885"="VRIJ",
"0886"="VRIJ",
"0887"="BBRUBEBB",
"0888"="BBRUBEBB",
"0889"="VRIJ",
"0890"="VDSPBE91",
"0891"="VDSPBE91",
"0892"="VDSPBE91",
"0893"="VDSPBE91",
"0894"="VDSPBE91",
"0895"="VDSPBE91",
"0896"="VDSPBE91",
"0897"="VDSPBE91",
"0898"="VDSPBE91",
"0899"="VDSPBE91",
"0900"="NAP",
"0901"="NAP",
"0902"="NAP",
"0903"="VRIJ",
"0904"="TRWIBEBB",
"0905"="TRWIBEB1",
"0906"="CEKVBE88",
"0907"="NAV",
"0908"="CEKVBE88",
"0909"="VRIJ",
"0910"="BBRUBEBB",
"0911"="TUNZBEB1",
"0912"="VRIJ",
"0913"="EPBFBEBB",
"0914"="FXBBBEBB",
"0915"="OONXBEBB",
"0916"="GOCFBEB1",
"0917"="-",
"0918"="VRIJ",
"0919"="VRIJ",
"0920"="BBRUBEBB",
"0921"="VRIJ",
"0922"="BBRUBEBB",
"0923"="BBRUBEBB",
"0924"="FMMSBEB1",
"0925"="VRIJ",
"0926"="EBPBBEB1",
"0927"="VRIJ",
"0928"="VPAYBEB1",
"0929"="BBRUBEBB",
"0930"="BBRUBEBB",
"0931"="BBRUBEBB",
"0932"="VRIJ",
"0933"="VRIJ",
"0934"="BBRUBEBB",
"0935"="VRIJ",
"0936"="BBRUBEBB",
"0937"="VRIJ",
"0938"="VRIJ",
"0939"="BBRUBEBB",
"0940"="CLIQBEB1",
"0941"="CVMCBEBB",
"0942"="PUILBEBB",
"0943"="nav",
"0944"="VRIJ",
"0945"="JPMGBEBB",
"0946"="-",
"0947"="VRIJ",
"0948"="HOMNBEB1",
"0949"="HSBCBEBB",
"0950"="CTBKBEBX",
"0951"="CTBKBEBX",
"0952"="CTBKBEBX",
"0953"="CTBKBEBX",
"0954"="CTBKBEBX",
"0955"="CTBKBEBX",
"0956"="CTBKBEBX",
"0957"="CTBKBEBX",
"0958"="CTBKBEBX",
"0959"="CTBKBEBX",
"0960"="ABNABE2AIPC",
"0961"="BBRUBEBB",
"0962"="VRIJ",
"0963"="AXABBE22",
"0964"="FPEBBEB2",
"0965"="VRIJ",
"0966"="VRIJ",
"0967"="TRWIBEB1",
"0968"="ENIBBEBB",
"0969"="PUILBEBB",
"0970"="VRIJ",
"0971"="BBRUBEBB",
"0972"="NAP",
"0973"="ARSPBE22",
"0974"="PESOBEB1",
"0975"="AXABBE22",
"0976"="BBRUBEBB",
"0977"="PAYVBEB2",
"0978"="ARSPBE22",
"0979"="ARSPBE22",
"0980"="ARSPBE22",
"0981"="PCHQBEBB",
"0982"="PCHQBEBB",
"0983"="PCHQBEBB",
"0984"="PCHQBEBB",
"0985"="VRIJ",
"0986"="VRIJ",
"0987"="VRIJ",
"0988"="VRIJ",
"0989"="nav",
"0990"="nav",
"0991"="nav",
"0992"="nav",
"0993"="nav",
"0994"="nav",
"0995"="nav",
"0996"="nav",
"0997"="nav",
"0998"="nav",
"0999"="nav",
}