import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, forwardRef, EventEmitter, inject, booleanAttribute, ContentChildren, ContentChild, ViewChild, Output, Input, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { uuid, isNotEmpty, getFocusableElements, hasClass, getFirstFocusableElement, focus, getLastFocusableElement } from '@primeuix/utils';
import { SharedModule, PrimeTemplate } from 'primeng/api';
import { AutoFocus } from 'primeng/autofocus';
import { BaseComponent } from 'primeng/basecomponent';
import { Chip } from 'primeng/chip';
import { TimesIcon, ChevronDownIcon } from 'primeng/icons';
import { Overlay } from 'primeng/overlay';
import { Tree } from 'primeng/tree';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-treeselect {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    user-select: none;
    background: ${dt('treeselect.background')};
    border: 1px solid ${dt('treeselect.border.color')};
    transition: background ${dt('treeselect.transition.duration')}, color ${dt('treeselect.transition.duration')}, border-color ${dt('treeselect.transition.duration')}, outline-color ${dt('treeselect.transition.duration')}, box-shadow ${dt('treeselect.transition.duration')};
    border-radius: ${dt('treeselect.border.radius')};
    outline-color: transparent;
    box-shadow: ${dt('treeselect.shadow')};
}

p-treeselect.ng-invalid.ng-dirty .p-treeselect {
    border-color: ${dt('treeselect.invalid.border.color')};
}

p-treeselect.ng-invalid.ng-dirty .p-treeselect.p-focus {
    border-color: ${dt('treeselect.focus.border.color')};
}

.p-treeselect:not(.p-disabled):hover {
    border-color: ${dt('treeselect.hover.border.color')};
}

.p-treeselect:not(.p-disabled).p-focus {
    border-color: ${dt('treeselect.focus.border.color')};
    box-shadow: ${dt('treeselect.focus.ring.shadow')};
    outline: ${dt('treeselect.focus.ring.width')} ${dt('treeselect.focus.ring.style')} ${dt('treeselect.focus.ring.color')};
    outline-offset: ${dt('treeselect.focus.ring.offset')};
}

.p-treeselect.p-variant-filled {
    background: ${dt('treeselect.filled.background')};
}

.p-treeselect.p-variant-filled:not(.p-disabled):hover {
    background: ${dt('treeselect.filled.hover.background')};
}

.p-treeselect.p-variant-filled.p-focus {
    background: ${dt('treeselect.filled.focus.background')};
}

.p-treeselect.p-disabled {
    opacity: 1;
    background: ${dt('treeselect.disabled.background')};
}

.p-treeselect-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: transparent;
    color: ${dt('treeselect.dropdown.color')};
    width: ${dt('treeselect.dropdown.width')};
    border-start-end-radius: ${dt('border.radius.md')};
    border-end-end-radius: ${dt('border.radius.md')};
}

.p-treeselect-label-container {
    overflow: hidden;
    flex: 1 1 auto;
    cursor: pointer;
}

.p-treeselect-label {
    display: flex;
    align-items-center;
    gap: calc(${dt('treeselect.padding.y')} / 2);
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: ${dt('treeselect.padding.y')} ${dt('treeselect.padding.x')};
    color: ${dt('treeselect.color')};
}

.p-treeselect-label.p-placeholder {
    color: ${dt('treeselect.placeholder.color')};
}

p-treeselect.ng-invalid.ng-dirty .p-treeselect-label.p-placeholder {
    color: ${dt('treeselect.invalid.placeholder.color')};
}

.p-treeselect.p-disabled .p-treeselect-label {
    color: ${dt('treeselect.disabled.color')};
}

.p-treeselect-label-empty {
    overflow: hidden;
    visibility: hidden;
}

.p-treeselect .p-treeselect-overlay {
    min-width: 100%;
}

.p-treeselect-overlay {
    background: ${dt('treeselect.overlay.background')};
    color: ${dt('treeselect.overlay.color')};
    border: 1px solid ${dt('treeselect.overlay.border.color')};
    border-radius: ${dt('treeselect.overlay.border.radius')};
    box-shadow: ${dt('treeselect.overlay.shadow')};
    overflow: hidden;
}


.p-treeselect-tree-container {
    overflow: auto;
}

.p-treeselect-empty-message {
    padding: ${dt('treeselect.empty.message.padding')};
    background: transparent;
}

.p-treeselect-fluid {
    display: flex;
}

.p-treeselect-overlay .p-tree {
    padding: ${dt('treeselect.tree.padding')};
}

.p-treeselect-label .p-chip {
    padding-top: calc(${dt('treeselect.padding.y')} / 2);
    padding-bottom: calc(${dt('treeselect.padding.y')} / 2);
    border-radius: ${dt('treeselect.chip.border.radius')};
}

.p-treeselect-label:has(.p-chip) {
    padding: calc(${dt('treeselect.padding.y')} / 2) calc(${dt('treeselect.padding.x')} / 2);
}

.p-treeselect-sm .p-treeselect-label {
    font-size: ${dt('treeselect.sm.font.size')};
    padding-block: ${dt('treeselect.sm.padding.y')};
    padding-inline: ${dt('treeselect.sm.padding.x')};
}

.p-treeselect-sm .p-treeselect-dropdown .p-icon {
    font-size: ${dt('treeselect.sm.font.size')};
    width: ${dt('treeselect.sm.font.size')};
    height: ${dt('treeselect.sm.font.size')};
}

.p-treeselect-lg .p-treeselect-label {
    font-size: ${dt('treeselect.lg.font.size')};
    padding-block: ${dt('treeselect.lg.padding.y')};
    padding-inline: ${dt('treeselect.lg.padding.x')};
}

.p-treeselect-lg .p-treeselect-dropdown .p-icon {
    font-size: ${dt('treeselect.lg.font.size')};
    width: ${dt('treeselect.lg.font.size')};
    height: ${dt('treeselect.lg.font.size')};
}

.p-treeselect-clear-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: transparent;
    color: ${dt('treeselect.clear.icon.color')};
}`;
const inlineStyles = {
  root: ({
    instance
  }) => ({
    position: instance.appendTo === 'self' ? 'relative' : undefined
  })
};
const classes = {
  root: ({
    instance
  }) => ({
    'p-treeselect p-component p-inputwrapper': true,
    'p-treeselect-display-chip': instance.display === 'chip',
    'p-disabled': instance.disabled,
    'p-invalid': instance.invalid,
    'p-focus': instance.focused,
    'p-variant-filled': instance.variant === 'filled' || instance.config.inputVariant() === 'filled' || instance.config.inputStyle() === 'filled',
    'p-inputwrapper-filled': !instance.emptyValue,
    'p-inputwrapper-focus': instance.focused || instance.overlayVisible,
    'p-treeselect-open': instance.overlayVisible,
    'p-treeselect-clearable': instance.showClear,
    'p-treeselect-fluid': instance.hasFluid,
    'p-treeselect-sm p-inputfield-sm': instance.size === 'small',
    'p-treeselect-lg p-inputfield-lg': instance.size === 'large'
  }),
  labelContainer: 'p-treeselect-label-container',
  label: ({
    instance
  }) => ({
    'p-treeselect-label': true,
    'p-placeholder': instance.label === instance.placeholder,
    'p-treeselect-label-empty': !instance.placeholder && instance.emptyValue
  }),
  chip: 'p-treeselect-chip-item',
  pcChip: 'p-treeselect-chip',
  dropdown: 'p-treeselect-dropdown',
  dropdownIcon: 'p-treeselect-dropdown-icon',
  panel: 'p-treeselect-overlay p-component',
  treeContainer: 'p-treeselect-tree-container',
  emptyMessage: 'p-treeselect-empty-message'
};
class TreeSelectStyle extends BaseStyle {
  name = 'treeselect';
  theme = theme;
  classes = classes;
  inlineStyles = inlineStyles;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TreeSelectStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TreeSelectStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TreeSelectStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * TreeSelect is a form component to choose from hierarchical data.
 *
 * [Live Demo](https://www.primeng.org/treeselect/)
 *
 * @module treeselectstyle
 *
 */
var TreeSelectClasses;
(function (TreeSelectClasses) {
  /**
   * Class name of the root element
   */
  TreeSelectClasses["root"] = "p-treeselect";
  /**
   * Class name of the label container element
   */
  TreeSelectClasses["labelContainer"] = "p-treeselect-label-container";
  /**
   * Class name of the label element
   */
  TreeSelectClasses["label"] = "p-treeselect-label";
  /**
   * Class name of the chip item element
   */
  TreeSelectClasses["chipItem"] = "p-treeselect-chip-item";
  /**
   * Class name of the chip element
   */
  TreeSelectClasses["pcChip"] = "p-treeselect-chip";
  /**
   * Class name of the dropdown element
   */
  TreeSelectClasses["dropdown"] = "p-treeselect-dropdown";
  /**
   * Class name of the dropdown icon element
   */
  TreeSelectClasses["dropdownIcon"] = "p-treeselect-dropdown-icon";
  /**
   * Class name of the panel element
   */
  TreeSelectClasses["panel"] = "p-treeselect-overlay";
  /**
   * Class name of the tree container element
   */
  TreeSelectClasses["treeContainer"] = "p-treeselect-tree-container";
  /**
   * Class name of the empty message element
   */
  TreeSelectClasses["emptyMessage"] = "p-treeselect-empty-message";
})(TreeSelectClasses || (TreeSelectClasses = {}));
const TREESELECT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TreeSelect),
  multi: true
};
/**
 * TreeSelect is a form component to choose from hierarchical data.
 * @group Components
 */
class TreeSelect extends BaseComponent {
  /**
   * Identifier of the underlying input element.
   * @group Props
   */
  inputId;
  /**
   * Height of the viewport, a scrollbar is defined if height of list exceeds this value.
   * @group Props
   */
  scrollHeight = '400px';
  /**
   * When present, it specifies that the component should be disabled.
   * @group Props
   */
  disabled;
  /**
   * Defines how multiple items can be selected, when true metaKey needs to be pressed to select or unselect an item and when set to false selection of each item can be toggled individually. On touch enabled devices, metaKeySelection is turned off automatically.
   * @group Props
   */
  metaKeySelection = false;
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant;
  /**
   * Defines how the selected items are displayed.
   * @group Props
   */
  display = 'comma';
  /**
   * Defines the selection mode.
   * @group Props
   */
  selectionMode = 'single';
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex = '0';
  /**
   * Defines a string that labels the input for accessibility.
   * @group Props
   */
  ariaLabel;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Label to display when there are no selections.
   * @group Props
   */
  placeholder;
  /**
   * Style class of the overlay panel.
   * @group Props
   */
  panelClass;
  /**
   * Inline style of the panel element.
   * @group Props
   */
  panelStyle;
  /**
   * Spans 100% width of the container when enabled.
   * @group Props
   */
  fluid = false;
  /**
   * Style class of the panel element.
   * @group Props
   */
  panelStyleClass;
  /**
   * Inline style of the container element.
   * @group Props
   */
  set containerStyle(val) {
    const _rootStyle = this._componentStyle.inlineStyles.root({
      instance: this
    });
    this._containerStyle = {
      ..._rootStyle,
      ...val
    };
  }
  get containerStyle() {
    return this._containerStyle;
  }
  _containerStyle;
  /**
   * Style class of the container element.
   * @group Props
   */
  containerStyleClass;
  /**
   * Inline style of the label element.
   * @group Props
   */
  labelStyle;
  /**
   * Style class of the label element.
   * @group Props
   */
  labelStyleClass;
  /**
   * Specifies the options for the overlay.
   * @group Props
   */
  overlayOptions;
  /**
   * Text to display when there are no options available. Defaults to value from PrimeNG locale configuration.
   * @group Props
   */
  emptyMessage = '';
  /**
   * A valid query selector or an HTMLElement to specify where the overlay gets attached. Special keywords are "body" for document body and "self" for the element itself.
   * @group Props
   */
  appendTo;
  /**
   * When specified, displays an input field to filter the items.
   * @group Props
   */
  filter = false;
  /**
   * When filtering is enabled, filterBy decides which field or fields (comma separated) to search against.
   * @group Props
   */
  filterBy = 'label';
  /**
   * Mode for filtering valid values are "lenient" and "strict". Default is lenient.
   * @group Props
   */
  filterMode = 'lenient';
  /**
   * Placeholder text to show when filter input is empty.
   * @group Props
   */
  filterPlaceholder;
  /**
   * Locale to use in filtering. The default locale is the host environment's current locale.
   * @group Props
   */
  filterLocale;
  /**
   * Determines whether the filter input should be automatically focused when the component is rendered.
   * @group Props
   */
  filterInputAutoFocus = true;
  /**
   * Whether checkbox selections propagate to descendant nodes.
   * @group Props
   */
  propagateSelectionDown = true;
  /**
   * Whether checkbox selections propagate to ancestor nodes.
   * @group Props
   */
  propagateSelectionUp = true;
  /**
   * When enabled, a clear icon is displayed to clear the value.
   * @group Props
   */
  showClear = false;
  /**
   * Clears the filter value when hiding the dropdown.
   * @group Props
   */
  resetFilterOnHide = true;
  /**
   * Whether the data should be loaded on demand during scroll.
   * @group Props
   */
  virtualScroll;
  /**
   * Height of an item in the list for VirtualScrolling.
   * @group Props
   */
  virtualScrollItemSize;
  /**
   * Defines the size of the component.
   * @group Props
   */
  size;
  /**
   * Whether to use the scroller feature. The properties of scroller component can be used like an object in it.
   * @group Props
   */
  virtualScrollOptions;
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  autofocus;
  /**
   * An array of treenodes.
   * @defaultValue undefined
   * @group Props
   */
  get options() {
    return this._options;
  }
  set options(options) {
    this._options = options;
    this.updateTreeState();
  }
  /**
   * Transition options of the show animation.
   * @group Props
   * @deprecated since v14.2.0 use overlayOptions property instead.
   */
  get showTransitionOptions() {
    return this._showTransitionOptions;
  }
  set showTransitionOptions(val) {
    this._showTransitionOptions = val;
    console.log('The showTransitionOptions property is deprecated since v14.2.0, use overlayOptions property instead.');
  }
  /**
   * Transition options of the hide animation.
   * @group Props
   * @deprecated since v14.2.0 use overlayOptions property instead.
   */
  get hideTransitionOptions() {
    return this._hideTransitionOptions;
  }
  set hideTransitionOptions(val) {
    this._hideTransitionOptions = val;
    console.log('The hideTransitionOptions property is deprecated since v14.2.0, use overlayOptions property instead.');
  }
  /**
   * Displays a loader to indicate data load is in progress.
   * @group Props
   */
  loading;
  /**
   * Callback to invoke when a node is expanded.
   * @param {TreeSelectNodeExpandEvent} event - Custom node expand event.
   * @group Emits
   */
  onNodeExpand = new EventEmitter();
  /**
   * Callback to invoke when a node is collapsed.
   * @param {TreeSelectNodeCollapseEvent} event - Custom node collapse event.
   * @group Emits
   */
  onNodeCollapse = new EventEmitter();
  /**
   * Callback to invoke when the overlay is shown.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Callback to invoke when the overlay is hidden.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onHide = new EventEmitter();
  /**
   * Callback to invoke when input field is cleared.
   * @group Emits
   */
  onClear = new EventEmitter();
  /**
   * Callback to invoke when data is filtered.
   * @group Emits
   */
  onFilter = new EventEmitter();
  /**
   * Callback to invoke when treeselect gets focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  /**
   * Callback to invoke when treeselect loses focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  /**
   * Callback to invoke when a node is unselected.
   * @param {TreeNodeUnSelectEvent} event - node unselect event.
   * @group Emits
   */
  onNodeUnselect = new EventEmitter();
  /**
   * Callback to invoke when a node is selected.
   * @param {TreeNodeSelectEvent} event - node select event.
   * @group Emits
   */
  onNodeSelect = new EventEmitter();
  _showTransitionOptions;
  _hideTransitionOptions;
  containerEl;
  focusInput;
  filterViewChild;
  treeViewChild;
  panelEl;
  overlayViewChild;
  firstHiddenFocusableElementOnOverlay;
  lastHiddenFocusableElementOnOverlay;
  filteredNodes;
  filterValue = null;
  serializedValue;
  /**
   * Custom value template.
   * @group Templates
   */
  valueTemplate;
  /**
   * Custom header template.
   * @group Templates
   */
  headerTemplate;
  /**
   * Custom empty message template.
   * @group Templates
   */
  emptyTemplate;
  /**
   * Custom footer template.
   * @group Templates
   */
  footerTemplate;
  /**
   * Custom clear icon template.
   * @group Templates
   */
  clearIconTemplate;
  /**
   * Custom trigger icon template.
   * @group Templates
   */
  triggerIconTemplate;
  /**
   * Custom dropdown icon template.
   * @group Templates
   */
  dropdownIconTemplate;
  /**
   * Custom filter icon template.
   * @group Templates
   */
  filterIconTemplate;
  /**
   * Custom close icon template.
   * @group Templates
   */
  closeIconTemplate;
  /**
   * Custom item toggler icon template.
   * @group Templates
   */
  itemTogglerIconTemplate;
  /**
   * Custom item checkbox icon template.
   * @group Templates
   */
  itemCheckboxIconTemplate;
  /**
   * Custom item loading icon template.
   * @group Templates
   */
  itemLoadingIconTemplate;
  templates;
  _valueTemplate;
  _headerTemplate;
  _emptyTemplate;
  _footerTemplate;
  _clearIconTemplate;
  _triggerIconTemplate;
  _filterIconTemplate;
  _closeIconTemplate;
  _itemTogglerIconTemplate;
  _itemCheckboxIconTemplate;
  _itemLoadingIconTemplate;
  _dropdownIconTemplate;
  focused;
  overlayVisible;
  selfChange;
  value;
  expandedNodes = [];
  _options;
  templateMap;
  onModelChange = () => {};
  onModelTouched = () => {};
  listId = '';
  _componentStyle = inject(TreeSelectStyle);
  ngOnInit() {
    super.ngOnInit();
    this.listId = uuid('pn_id_') + '_list';
    this.updateTreeState();
  }
  ngAfterContentInit() {
    if (this.templates.length) {
      this.templateMap = {};
    }
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'value':
          this._valueTemplate = item.template;
          break;
        case 'header':
          this._headerTemplate = item.template;
          break;
        case 'empty':
          this._emptyTemplate = item.template;
          break;
        case 'footer':
          this._footerTemplate = item.template;
          break;
        case 'clearicon':
          this._clearIconTemplate = item.template;
          break;
        case 'triggericon':
          this._triggerIconTemplate = item.template;
          break;
        case 'filtericon':
          this._filterIconTemplate = item.template;
          break;
        case 'closeicon':
          this._closeIconTemplate = item.template;
          break;
        case 'itemtogglericon':
          this._itemTogglerIconTemplate = item.template;
          break;
        case 'itemcheckboxicon':
          this._itemCheckboxIconTemplate = item.template;
          break;
        case 'dropdownicon':
          this._dropdownIconTemplate = item.template;
          break;
        case 'itemloadingicon':
          this._itemLoadingIconTemplate = item.template;
          break;
        default:
          //TODO: @deprecated Used "value" template instead
          if (item.name) this.templateMap[item.name] = item.template;else this.valueTemplate = item.template;
          break;
      }
    });
  }
  onOverlayAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        if (this.filter) {
          isNotEmpty(this.filterValue) && this.treeViewChild?._filter(this.filterValue);
          this.filterInputAutoFocus && this.filterViewChild?.nativeElement.focus();
        } else {
          let focusableElements = getFocusableElements(this.panelEl.nativeElement);
          if (focusableElements && focusableElements.length > 0) {
            focusableElements[0].focus();
          }
        }
        break;
    }
  }
  onOverlayBeforeHide(event) {
    let focusableElements = getFocusableElements(this.containerEl.nativeElement);
    if (focusableElements && focusableElements.length > 0) {
      focusableElements[0].focus();
    }
  }
  onSelectionChange(event) {
    this.value = event;
    this.onModelChange(this.value);
    this.cd.markForCheck();
  }
  onClick(event) {
    if (this.disabled) {
      return;
    }
    if (!this.overlayViewChild?.el?.nativeElement?.contains(event.target) && !hasClass(event.target, 'p-treeselect-close') && !hasClass(event.target, 'p-checkbox-box') && !hasClass(event.target, 'p-checkbox-icon')) {
      if (this.overlayVisible) {
        this.hide();
      } else {
        this.show();
      }
      this.focusInput?.nativeElement.focus();
    }
  }
  onKeyDown(event) {
    switch (event.code) {
      //down
      case 'ArrowDown':
        if (!this.overlayVisible) {
          this.show();
          event.preventDefault();
        }
        this.onArrowDown(event);
        event.preventDefault();
        break;
      //space
      case 'Space':
      case 'Enter':
        if (!this.overlayVisible) {
          this.show();
          event.preventDefault();
        }
        break;
      //escape
      case 'Escape':
        if (this.overlayVisible) {
          this.hide();
          this.focusInput?.nativeElement.focus();
          event.preventDefault();
        }
        break;
      //tab
      case 'Tab':
        this.onTabKey(event);
        break;
      default:
        break;
    }
  }
  onFilterInput(event) {
    this.filterValue = event.target.value;
    this.treeViewChild?._filter(this.filterValue);
    this.onFilter.emit({
      filter: this.filterValue,
      filteredValue: this.treeViewChild?.filteredNodes
    });
    setTimeout(() => {
      this.overlayViewChild.alignOverlay();
    });
  }
  onArrowDown(event) {
    if (this.overlayVisible && this.panelEl?.nativeElement) {
      let focusableElements = getFocusableElements(this.panelEl.nativeElement, '.p-tree-node');
      if (focusableElements && focusableElements.length > 0) {
        focusableElements[0].focus();
      }
      event.preventDefault();
    }
  }
  onFirstHiddenFocus(event) {
    const focusableEl = event.relatedTarget === this.focusInput?.nativeElement ? getFirstFocusableElement(this.overlayViewChild?.overlayViewChild?.nativeElement, ':not([data-p-hidden-focusable="true"])') : this.focusInput?.nativeElement;
    focus(focusableEl);
  }
  onLastHiddenFocus(event) {
    const focusableEl = event.relatedTarget === this.focusInput?.nativeElement ? getLastFocusableElement(this.overlayViewChild?.overlayViewChild?.nativeElement, ':not([data-p-hidden-focusable="true"])') : this.focusInput?.nativeElement;
    focus(focusableEl);
  }
  show() {
    this.overlayVisible = true;
  }
  hide(event) {
    this.overlayVisible = false;
    this.resetFilter();
    this.onHide.emit(event);
    this.cd.markForCheck();
  }
  clear(event) {
    this.value = null;
    this.resetExpandedNodes();
    this.resetPartialSelected();
    this.onModelChange(this.value);
    this.onClear.emit();
    event.stopPropagation();
  }
  checkValue() {
    return this.value !== null && isNotEmpty(this.value);
  }
  onTabKey(event, pressedInInputText = false) {
    if (!pressedInInputText) {
      if (this.overlayVisible && this.hasFocusableElements()) {
        focus(event.shiftKey ? this.lastHiddenFocusableElementOnOverlay.nativeElement : this.firstHiddenFocusableElementOnOverlay.nativeElement);
        event.preventDefault();
      } else {
        this.overlayVisible && this.hide(this.filter);
      }
    }
  }
  hasFocusableElements() {
    return getFocusableElements(this.overlayViewChild.overlayViewChild.nativeElement, ':not([data-p-hidden-focusable="true"])').length > 0;
  }
  resetFilter() {
    if (this.filter && !this.resetFilterOnHide) {
      this.filteredNodes = this.treeViewChild?.filteredNodes;
      this.treeViewChild?.resetFilter();
    } else {
      this.filterValue = null;
    }
  }
  updateTreeState() {
    if (this.value) {
      let selectedNodes = this.selectionMode === 'single' ? [this.value] : [...this.value];
      this.resetExpandedNodes();
      this.resetPartialSelected();
      if (selectedNodes && this.options) {
        this.updateTreeBranchState(null, null, selectedNodes);
      }
    }
  }
  updateTreeBranchState(node, path, selectedNodes) {
    if (node) {
      if (this.isSelected(node)) {
        this.expandPath(path);
        selectedNodes.splice(selectedNodes.indexOf(node), 1);
      }
      if (selectedNodes.length > 0 && node.children) {
        for (let childNode of node.children) {
          this.updateTreeBranchState(childNode, [...path, node], selectedNodes);
        }
      }
    } else {
      for (let childNode of this.options) {
        this.updateTreeBranchState(childNode, [], selectedNodes);
      }
    }
  }
  expandPath(expandedNodes) {
    for (let node of expandedNodes) {
      node.expanded = true;
    }
    this.expandedNodes = [...expandedNodes];
  }
  nodeExpand(event) {
    this.onNodeExpand.emit(event);
    this.expandedNodes.push(event.node);
  }
  nodeCollapse(event) {
    this.onNodeCollapse.emit(event);
    this.expandedNodes.splice(this.expandedNodes.indexOf(event.node), 1);
  }
  resetExpandedNodes() {
    for (let node of this.expandedNodes) {
      node.expanded = false;
    }
    this.expandedNodes = [];
  }
  resetPartialSelected(nodes = this.options) {
    if (!nodes) {
      return;
    }
    for (let node of nodes) {
      node.partialSelected = false;
      if (node.children && node.children?.length > 0) {
        this.resetPartialSelected(node.children);
      }
    }
  }
  findSelectedNodes(node, keys, selectedNodes) {
    if (node) {
      if (this.isSelected(node)) {
        selectedNodes.push(node);
        delete keys[node.key];
      }
      if (Object.keys(keys).length && node.children) {
        for (let childNode of node.children) {
          this.findSelectedNodes(childNode, keys, selectedNodes);
        }
      }
    } else {
      for (let childNode of this.options) {
        this.findSelectedNodes(childNode, keys, selectedNodes);
      }
    }
  }
  isSelected(node) {
    return this.findIndexInSelection(node) != -1;
  }
  findIndexInSelection(node) {
    let index = -1;
    if (this.value) {
      if (this.selectionMode === 'single') {
        let areNodesEqual = this.value.key && this.value.key === node.key || this.value == node;
        index = areNodesEqual ? 0 : -1;
      } else {
        for (let i = 0; i < this.value.length; i++) {
          let selectedNode = this.value[i];
          let areNodesEqual = selectedNode.key && selectedNode.key === node.key || selectedNode == node;
          if (areNodesEqual) {
            index = i;
            break;
          }
        }
      }
    }
    return index;
  }
  onSelect(event) {
    this.onNodeSelect.emit(event);
    if (this.selectionMode === 'single') {
      this.hide();
      this.focusInput?.nativeElement.focus();
    }
  }
  onUnselect(event) {
    this.onNodeUnselect.emit(event);
  }
  onInputFocus(event) {
    if (this.disabled) {
      // For ScreenReaders
      return;
    }
    this.focused = true;
    this.onFocus.emit(event);
  }
  onInputBlur(event) {
    this.focused = false;
    this.onBlur.emit(event);
    this.onModelTouched();
  }
  writeValue(value) {
    this.value = value;
    this.updateTreeState();
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    setTimeout(() => {
      this.disabled = val;
      this.cd.markForCheck();
    });
  }
  get containerClass() {
    return this._componentStyle.classes.root({
      instance: this
    });
  }
  get hasFluid() {
    const nativeElement = this.el.nativeElement;
    const fluidComponent = nativeElement.closest('p-fluid');
    return this.fluid || !!fluidComponent;
  }
  get labelClass() {
    return this._componentStyle.classes.label({
      instance: this
    });
  }
  get emptyValue() {
    return !this.value || Object.keys(this.value).length === 0;
  }
  get emptyOptions() {
    return !this.options || this.options.length === 0;
  }
  get label() {
    let value = this.value || [];
    return value.length ? value.map(node => node.label).join(', ') : this.selectionMode === 'single' && this.value ? value.label : this.placeholder;
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TreeSelect,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: TreeSelect,
    isStandalone: true,
    selector: "p-treeSelect, p-treeselect, p-tree-select",
    inputs: {
      inputId: "inputId",
      scrollHeight: "scrollHeight",
      disabled: ["disabled", "disabled", booleanAttribute],
      metaKeySelection: ["metaKeySelection", "metaKeySelection", booleanAttribute],
      variant: "variant",
      display: "display",
      selectionMode: "selectionMode",
      tabindex: "tabindex",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy",
      placeholder: "placeholder",
      panelClass: "panelClass",
      panelStyle: "panelStyle",
      fluid: ["fluid", "fluid", booleanAttribute],
      panelStyleClass: "panelStyleClass",
      containerStyle: "containerStyle",
      containerStyleClass: "containerStyleClass",
      labelStyle: "labelStyle",
      labelStyleClass: "labelStyleClass",
      overlayOptions: "overlayOptions",
      emptyMessage: "emptyMessage",
      appendTo: "appendTo",
      filter: ["filter", "filter", booleanAttribute],
      filterBy: "filterBy",
      filterMode: "filterMode",
      filterPlaceholder: "filterPlaceholder",
      filterLocale: "filterLocale",
      filterInputAutoFocus: ["filterInputAutoFocus", "filterInputAutoFocus", booleanAttribute],
      propagateSelectionDown: ["propagateSelectionDown", "propagateSelectionDown", booleanAttribute],
      propagateSelectionUp: ["propagateSelectionUp", "propagateSelectionUp", booleanAttribute],
      showClear: ["showClear", "showClear", booleanAttribute],
      resetFilterOnHide: ["resetFilterOnHide", "resetFilterOnHide", booleanAttribute],
      virtualScroll: "virtualScroll",
      virtualScrollItemSize: "virtualScrollItemSize",
      size: "size",
      virtualScrollOptions: "virtualScrollOptions",
      autofocus: ["autofocus", "autofocus", booleanAttribute],
      options: "options",
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions",
      loading: ["loading", "loading", booleanAttribute]
    },
    outputs: {
      onNodeExpand: "onNodeExpand",
      onNodeCollapse: "onNodeCollapse",
      onShow: "onShow",
      onHide: "onHide",
      onClear: "onClear",
      onFilter: "onFilter",
      onFocus: "onFocus",
      onBlur: "onBlur",
      onNodeUnselect: "onNodeUnselect",
      onNodeSelect: "onNodeSelect"
    },
    providers: [TREESELECT_VALUE_ACCESSOR, TreeSelectStyle],
    queries: [{
      propertyName: "valueTemplate",
      first: true,
      predicate: ["value"]
    }, {
      propertyName: "headerTemplate",
      first: true,
      predicate: ["header"]
    }, {
      propertyName: "emptyTemplate",
      first: true,
      predicate: ["empty"]
    }, {
      propertyName: "footerTemplate",
      first: true,
      predicate: ["footer"]
    }, {
      propertyName: "clearIconTemplate",
      first: true,
      predicate: ["clearicon"]
    }, {
      propertyName: "triggerIconTemplate",
      first: true,
      predicate: ["triggericon"]
    }, {
      propertyName: "dropdownIconTemplate",
      first: true,
      predicate: ["dropdownicon"]
    }, {
      propertyName: "filterIconTemplate",
      first: true,
      predicate: ["filtericon"]
    }, {
      propertyName: "closeIconTemplate",
      first: true,
      predicate: ["closeicon"]
    }, {
      propertyName: "itemTogglerIconTemplate",
      first: true,
      predicate: ["itemtogglericon"]
    }, {
      propertyName: "itemCheckboxIconTemplate",
      first: true,
      predicate: ["itemcheckboxicon"]
    }, {
      propertyName: "itemLoadingIconTemplate",
      first: true,
      predicate: ["itemloadingicon"]
    }, {
      propertyName: "templates",
      predicate: PrimeTemplate
    }],
    viewQueries: [{
      propertyName: "containerEl",
      first: true,
      predicate: ["container"],
      descendants: true
    }, {
      propertyName: "focusInput",
      first: true,
      predicate: ["focusInput"],
      descendants: true
    }, {
      propertyName: "filterViewChild",
      first: true,
      predicate: ["filter"],
      descendants: true
    }, {
      propertyName: "treeViewChild",
      first: true,
      predicate: ["tree"],
      descendants: true
    }, {
      propertyName: "panelEl",
      first: true,
      predicate: ["panel"],
      descendants: true
    }, {
      propertyName: "overlayViewChild",
      first: true,
      predicate: ["overlay"],
      descendants: true
    }, {
      propertyName: "firstHiddenFocusableElementOnOverlay",
      first: true,
      predicate: ["firstHiddenFocusableEl"],
      descendants: true
    }, {
      propertyName: "lastHiddenFocusableElementOnOverlay",
      first: true,
      predicate: ["lastHiddenFocusableEl"],
      descendants: true
    }],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <div #container [ngClass]="containerClass" [class]="containerStyleClass" [ngStyle]="containerStyle" (click)="onClick($event)">
            <div class="p-hidden-accessible">
                <input
                    #focusInput
                    type="text"
                    role="combobox"
                    [attr.id]="inputId"
                    readonly
                    [disabled]="disabled"
                    (focus)="onInputFocus($event)"
                    (blur)="onInputBlur($event)"
                    (keydown)="onKeyDown($event)"
                    [attr.tabindex]="!disabled ? tabindex : -1"
                    [attr.aria-controls]="overlayVisible ? listId : null"
                    [attr.aria-haspopup]="'tree'"
                    [attr.aria-expanded]="overlayVisible ?? false"
                    [attr.aria-labelledby]="ariaLabelledBy"
                    [attr.aria-label]="ariaLabel || (label === 'p-emptylabel' ? undefined : label)"
                    [pAutoFocus]="autofocus"
                />
            </div>
            <div class="p-treeselect-label-container">
                <div [ngClass]="labelClass" [class]="labelStyleClass" [ngStyle]="labelStyle">
                    <ng-container *ngIf="valueTemplate || _valueTemplate; else defaultValueTemplate">
                        <ng-container *ngTemplateOutlet="valueTemplate || _valueTemplate; context: { $implicit: value, placeholder: placeholder }"></ng-container>
                    </ng-container>
                    <ng-template #defaultValueTemplate>
                        <ng-container *ngIf="display === 'comma'; else chipsValueTemplate">
                            {{ label || 'empty' }}
                        </ng-container>
                        <ng-template #chipsValueTemplate>
                            <div *ngFor="let node of value" class="p-treeselect-chip-item">
                                <p-chip [label]="node.label" styleClass="p-treeselect-chip" />
                            </div>
                            <ng-container *ngIf="emptyValue">{{ placeholder || 'empty' }}</ng-container>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
            <ng-container *ngIf="checkValue() && !disabled && showClear">
                <TimesIcon *ngIf="!clearIconTemplate && !_clearIconTemplate" [class]="'p-treeselect-clear-icon'" (click)="clear($event)" />
                <span *ngIf="clearIconTemplate || clearIconTemplate" class="p-treeselect-clear-icon" (click)="clear($event)">
                    <ng-template *ngTemplateOutlet="clearIconTemplate || _clearIconTemplate"></ng-template>
                </span>
            </ng-container>
            <div class="p-treeselect-dropdown" role="button" aria-haspopup="tree" [attr.aria-expanded]="overlayVisible ?? false" [attr.aria-label]="'treeselect trigger'">
                <ChevronDownIcon *ngIf="!triggerIconTemplate && !_triggerIconTemplate && !dropdownIconTemplate && !_dropdownIconTemplate" [styleClass]="'p-treeselect-dropdown-icon'" />
                <span *ngIf="triggerIconTemplate || _triggerIconTemplate || dropdownIconTemplate || _dropdownIconTemplate" class="p-treeselect-dropdown-icon">
                    <ng-template *ngTemplateOutlet="triggerIconTemplate || _triggerIconTemplate || dropdownIconTemplate || _dropdownIconTemplate"></ng-template>
                </span>
            </div>
            <p-overlay
                #overlay
                [(visible)]="overlayVisible"
                [options]="overlayOptions"
                [target]="'@parent'"
                [appendTo]="appendTo"
                [showTransitionOptions]="showTransitionOptions"
                [hideTransitionOptions]="hideTransitionOptions"
                (onAnimationStart)="onOverlayAnimationStart($event)"
                (onBeforeHide)="onOverlayBeforeHide($event)"
                (onShow)="onShow.emit($event)"
                (onHide)="hide($event)"
            >
                <ng-template #content>
                    <div #panel [attr.id]="listId" class="p-treeselect-overlay p-component" [ngStyle]="panelStyle" [class]="panelStyleClass" [ngClass]="panelClass">
                        <span
                            #firstHiddenFocusableEl
                            role="presentation"
                            class="p-hidden-accessible p-hidden-focusable"
                            [attr.tabindex]="0"
                            (focus)="onFirstHiddenFocus($event)"
                            [attr.data-p-hidden-accessible]="true"
                            [attr.data-p-hidden-focusable]="true"
                        >
                        </span>
                        <ng-container *ngTemplateOutlet="headerTemplate || _headerTemplate; context: { $implicit: value, options: options }"></ng-container>
                        <div class="p-treeselect-tree-container" [ngStyle]="{ 'max-height': scrollHeight }">
                            <p-tree
                                #tree
                                [value]="options"
                                [propagateSelectionDown]="propagateSelectionDown"
                                [propagateSelectionUp]="propagateSelectionUp"
                                [selectionMode]="selectionMode"
                                (selectionChange)="onSelectionChange($event)"
                                [selection]="value"
                                [metaKeySelection]="metaKeySelection"
                                (onNodeExpand)="nodeExpand($event)"
                                (onNodeCollapse)="nodeCollapse($event)"
                                (onNodeSelect)="onSelect($event)"
                                [emptyMessage]="emptyMessage"
                                (onNodeUnselect)="onUnselect($event)"
                                [filter]="filter"
                                [filterBy]="filterBy"
                                [filterMode]="filterMode"
                                [filterPlaceholder]="filterPlaceholder"
                                [filterLocale]="filterLocale"
                                [filteredNodes]="filteredNodes"
                                [virtualScroll]="virtualScroll"
                                [virtualScrollItemSize]="virtualScrollItemSize"
                                [virtualScrollOptions]="virtualScrollOptions"
                                [_templateMap]="templateMap"
                                [loading]="loading"
                            >
                                <ng-container *ngIf="emptyTemplate || _emptyTemplate">
                                    <ng-template #empty>
                                        <ng-container *ngTemplateOutlet="emptyTemplate || _emptyTemplate"></ng-container>
                                    </ng-template>
                                </ng-container>
                                <ng-template #togglericon let-expanded *ngIf="itemTogglerIconTemplate || _itemTogglerIconTemplate">
                                    <ng-container *ngTemplateOutlet="itemTogglerIconTemplate || _itemTogglerIconTemplate; context: { $implicit: expanded }"></ng-container>
                                </ng-template>
                                <ng-template #checkboxicon let-selected let-partialSelected="partialSelected" *ngIf="itemCheckboxIconTemplate || _itemCheckboxIconTemplate">
                                    <ng-container *ngTemplateOutlet="itemCheckboxIconTemplate || _itemCheckboxIconTemplate; context: { $implicit: selected, partialSelected: partialSelected }"></ng-container>
                                </ng-template>
                                <ng-template #loadingicon *ngIf="itemLoadingIconTemplate || _itemLoadingIconTemplate">
                                    <ng-container *ngTemplateOutlet="itemLoadingIconTemplate || _itemLoadingIconTemplate"></ng-container>
                                </ng-template>
                            </p-tree>
                        </div>
                        <ng-container *ngTemplateOutlet="footerTemplate; context: { $implicit: value, options: options }"></ng-container>
                        <span
                            #lastHiddenFocusableEl
                            role="presentation"
                            class="p-hidden-accessible p-hidden-focusable"
                            [attr.tabindex]="0"
                            (focus)="onLastHiddenFocus($event)"
                            [attr.data-p-hidden-accessible]="true"
                            [attr.data-p-hidden-focusable]="true"
                        ></span>
                    </div>
                </ng-template>
            </p-overlay>
        </div>
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgForOf,
      selector: "[ngFor][ngForOf]",
      inputs: ["ngForOf", "ngForTrackBy", "ngForTemplate"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "directive",
      type: i1.NgStyle,
      selector: "[ngStyle]",
      inputs: ["ngStyle"]
    }, {
      kind: "component",
      type: Overlay,
      selector: "p-overlay",
      inputs: ["visible", "mode", "style", "styleClass", "contentStyle", "contentStyleClass", "target", "appendTo", "autoZIndex", "baseZIndex", "showTransitionOptions", "hideTransitionOptions", "listener", "responsive", "options"],
      outputs: ["visibleChange", "onBeforeShow", "onShow", "onBeforeHide", "onHide", "onAnimationStart", "onAnimationDone"]
    }, {
      kind: "ngmodule",
      type: SharedModule
    }, {
      kind: "component",
      type: Tree,
      selector: "p-tree",
      inputs: ["value", "selectionMode", "loadingMode", "selection", "style", "styleClass", "contextMenu", "draggableScope", "droppableScope", "draggableNodes", "droppableNodes", "metaKeySelection", "propagateSelectionUp", "propagateSelectionDown", "loading", "loadingIcon", "emptyMessage", "ariaLabel", "togglerAriaLabel", "ariaLabelledBy", "validateDrop", "filter", "filterBy", "filterMode", "filterOptions", "filterPlaceholder", "filteredNodes", "filterLocale", "scrollHeight", "lazy", "virtualScroll", "virtualScrollItemSize", "virtualScrollOptions", "indentation", "_templateMap", "trackBy", "highlightOnSelect", "virtualNodeHeight"],
      outputs: ["selectionChange", "onNodeSelect", "onNodeUnselect", "onNodeExpand", "onNodeCollapse", "onNodeContextMenuSelect", "onNodeDoubleClick", "onNodeDrop", "onLazyLoad", "onScroll", "onScrollIndexChange", "onFilter"]
    }, {
      kind: "directive",
      type: AutoFocus,
      selector: "[pAutoFocus]",
      inputs: ["autofocus", "pAutoFocus"]
    }, {
      kind: "component",
      type: TimesIcon,
      selector: "TimesIcon"
    }, {
      kind: "component",
      type: ChevronDownIcon,
      selector: "ChevronDownIcon"
    }, {
      kind: "component",
      type: Chip,
      selector: "p-chip",
      inputs: ["label", "icon", "image", "alt", "style", "styleClass", "removable", "removeIcon", "chipProps"],
      outputs: ["onRemove", "onImageError"]
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TreeSelect,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-treeSelect, p-treeselect, p-tree-select',
      standalone: true,
      imports: [CommonModule, Overlay, SharedModule, Tree, AutoFocus, TimesIcon, ChevronDownIcon, Chip],
      template: `
        <div #container [ngClass]="containerClass" [class]="containerStyleClass" [ngStyle]="containerStyle" (click)="onClick($event)">
            <div class="p-hidden-accessible">
                <input
                    #focusInput
                    type="text"
                    role="combobox"
                    [attr.id]="inputId"
                    readonly
                    [disabled]="disabled"
                    (focus)="onInputFocus($event)"
                    (blur)="onInputBlur($event)"
                    (keydown)="onKeyDown($event)"
                    [attr.tabindex]="!disabled ? tabindex : -1"
                    [attr.aria-controls]="overlayVisible ? listId : null"
                    [attr.aria-haspopup]="'tree'"
                    [attr.aria-expanded]="overlayVisible ?? false"
                    [attr.aria-labelledby]="ariaLabelledBy"
                    [attr.aria-label]="ariaLabel || (label === 'p-emptylabel' ? undefined : label)"
                    [pAutoFocus]="autofocus"
                />
            </div>
            <div class="p-treeselect-label-container">
                <div [ngClass]="labelClass" [class]="labelStyleClass" [ngStyle]="labelStyle">
                    <ng-container *ngIf="valueTemplate || _valueTemplate; else defaultValueTemplate">
                        <ng-container *ngTemplateOutlet="valueTemplate || _valueTemplate; context: { $implicit: value, placeholder: placeholder }"></ng-container>
                    </ng-container>
                    <ng-template #defaultValueTemplate>
                        <ng-container *ngIf="display === 'comma'; else chipsValueTemplate">
                            {{ label || 'empty' }}
                        </ng-container>
                        <ng-template #chipsValueTemplate>
                            <div *ngFor="let node of value" class="p-treeselect-chip-item">
                                <p-chip [label]="node.label" styleClass="p-treeselect-chip" />
                            </div>
                            <ng-container *ngIf="emptyValue">{{ placeholder || 'empty' }}</ng-container>
                        </ng-template>
                    </ng-template>
                </div>
            </div>
            <ng-container *ngIf="checkValue() && !disabled && showClear">
                <TimesIcon *ngIf="!clearIconTemplate && !_clearIconTemplate" [class]="'p-treeselect-clear-icon'" (click)="clear($event)" />
                <span *ngIf="clearIconTemplate || clearIconTemplate" class="p-treeselect-clear-icon" (click)="clear($event)">
                    <ng-template *ngTemplateOutlet="clearIconTemplate || _clearIconTemplate"></ng-template>
                </span>
            </ng-container>
            <div class="p-treeselect-dropdown" role="button" aria-haspopup="tree" [attr.aria-expanded]="overlayVisible ?? false" [attr.aria-label]="'treeselect trigger'">
                <ChevronDownIcon *ngIf="!triggerIconTemplate && !_triggerIconTemplate && !dropdownIconTemplate && !_dropdownIconTemplate" [styleClass]="'p-treeselect-dropdown-icon'" />
                <span *ngIf="triggerIconTemplate || _triggerIconTemplate || dropdownIconTemplate || _dropdownIconTemplate" class="p-treeselect-dropdown-icon">
                    <ng-template *ngTemplateOutlet="triggerIconTemplate || _triggerIconTemplate || dropdownIconTemplate || _dropdownIconTemplate"></ng-template>
                </span>
            </div>
            <p-overlay
                #overlay
                [(visible)]="overlayVisible"
                [options]="overlayOptions"
                [target]="'@parent'"
                [appendTo]="appendTo"
                [showTransitionOptions]="showTransitionOptions"
                [hideTransitionOptions]="hideTransitionOptions"
                (onAnimationStart)="onOverlayAnimationStart($event)"
                (onBeforeHide)="onOverlayBeforeHide($event)"
                (onShow)="onShow.emit($event)"
                (onHide)="hide($event)"
            >
                <ng-template #content>
                    <div #panel [attr.id]="listId" class="p-treeselect-overlay p-component" [ngStyle]="panelStyle" [class]="panelStyleClass" [ngClass]="panelClass">
                        <span
                            #firstHiddenFocusableEl
                            role="presentation"
                            class="p-hidden-accessible p-hidden-focusable"
                            [attr.tabindex]="0"
                            (focus)="onFirstHiddenFocus($event)"
                            [attr.data-p-hidden-accessible]="true"
                            [attr.data-p-hidden-focusable]="true"
                        >
                        </span>
                        <ng-container *ngTemplateOutlet="headerTemplate || _headerTemplate; context: { $implicit: value, options: options }"></ng-container>
                        <div class="p-treeselect-tree-container" [ngStyle]="{ 'max-height': scrollHeight }">
                            <p-tree
                                #tree
                                [value]="options"
                                [propagateSelectionDown]="propagateSelectionDown"
                                [propagateSelectionUp]="propagateSelectionUp"
                                [selectionMode]="selectionMode"
                                (selectionChange)="onSelectionChange($event)"
                                [selection]="value"
                                [metaKeySelection]="metaKeySelection"
                                (onNodeExpand)="nodeExpand($event)"
                                (onNodeCollapse)="nodeCollapse($event)"
                                (onNodeSelect)="onSelect($event)"
                                [emptyMessage]="emptyMessage"
                                (onNodeUnselect)="onUnselect($event)"
                                [filter]="filter"
                                [filterBy]="filterBy"
                                [filterMode]="filterMode"
                                [filterPlaceholder]="filterPlaceholder"
                                [filterLocale]="filterLocale"
                                [filteredNodes]="filteredNodes"
                                [virtualScroll]="virtualScroll"
                                [virtualScrollItemSize]="virtualScrollItemSize"
                                [virtualScrollOptions]="virtualScrollOptions"
                                [_templateMap]="templateMap"
                                [loading]="loading"
                            >
                                <ng-container *ngIf="emptyTemplate || _emptyTemplate">
                                    <ng-template #empty>
                                        <ng-container *ngTemplateOutlet="emptyTemplate || _emptyTemplate"></ng-container>
                                    </ng-template>
                                </ng-container>
                                <ng-template #togglericon let-expanded *ngIf="itemTogglerIconTemplate || _itemTogglerIconTemplate">
                                    <ng-container *ngTemplateOutlet="itemTogglerIconTemplate || _itemTogglerIconTemplate; context: { $implicit: expanded }"></ng-container>
                                </ng-template>
                                <ng-template #checkboxicon let-selected let-partialSelected="partialSelected" *ngIf="itemCheckboxIconTemplate || _itemCheckboxIconTemplate">
                                    <ng-container *ngTemplateOutlet="itemCheckboxIconTemplate || _itemCheckboxIconTemplate; context: { $implicit: selected, partialSelected: partialSelected }"></ng-container>
                                </ng-template>
                                <ng-template #loadingicon *ngIf="itemLoadingIconTemplate || _itemLoadingIconTemplate">
                                    <ng-container *ngTemplateOutlet="itemLoadingIconTemplate || _itemLoadingIconTemplate"></ng-container>
                                </ng-template>
                            </p-tree>
                        </div>
                        <ng-container *ngTemplateOutlet="footerTemplate; context: { $implicit: value, options: options }"></ng-container>
                        <span
                            #lastHiddenFocusableEl
                            role="presentation"
                            class="p-hidden-accessible p-hidden-focusable"
                            [attr.tabindex]="0"
                            (focus)="onLastHiddenFocus($event)"
                            [attr.data-p-hidden-accessible]="true"
                            [attr.data-p-hidden-focusable]="true"
                        ></span>
                    </div>
                </ng-template>
            </p-overlay>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TREESELECT_VALUE_ACCESSOR, TreeSelectStyle],
      encapsulation: ViewEncapsulation.None
    }]
  }],
  propDecorators: {
    inputId: [{
      type: Input
    }],
    scrollHeight: [{
      type: Input
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    metaKeySelection: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    variant: [{
      type: Input
    }],
    display: [{
      type: Input
    }],
    selectionMode: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    panelClass: [{
      type: Input
    }],
    panelStyle: [{
      type: Input
    }],
    fluid: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    panelStyleClass: [{
      type: Input
    }],
    containerStyle: [{
      type: Input
    }],
    containerStyleClass: [{
      type: Input
    }],
    labelStyle: [{
      type: Input
    }],
    labelStyleClass: [{
      type: Input
    }],
    overlayOptions: [{
      type: Input
    }],
    emptyMessage: [{
      type: Input
    }],
    appendTo: [{
      type: Input
    }],
    filter: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    filterBy: [{
      type: Input
    }],
    filterMode: [{
      type: Input
    }],
    filterPlaceholder: [{
      type: Input
    }],
    filterLocale: [{
      type: Input
    }],
    filterInputAutoFocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    propagateSelectionDown: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    propagateSelectionUp: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showClear: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    resetFilterOnHide: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    virtualScroll: [{
      type: Input
    }],
    virtualScrollItemSize: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    virtualScrollOptions: [{
      type: Input
    }],
    autofocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    options: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    loading: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    onNodeExpand: [{
      type: Output
    }],
    onNodeCollapse: [{
      type: Output
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    onClear: [{
      type: Output
    }],
    onFilter: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    onNodeUnselect: [{
      type: Output
    }],
    onNodeSelect: [{
      type: Output
    }],
    containerEl: [{
      type: ViewChild,
      args: ['container']
    }],
    focusInput: [{
      type: ViewChild,
      args: ['focusInput']
    }],
    filterViewChild: [{
      type: ViewChild,
      args: ['filter']
    }],
    treeViewChild: [{
      type: ViewChild,
      args: ['tree']
    }],
    panelEl: [{
      type: ViewChild,
      args: ['panel']
    }],
    overlayViewChild: [{
      type: ViewChild,
      args: ['overlay']
    }],
    firstHiddenFocusableElementOnOverlay: [{
      type: ViewChild,
      args: ['firstHiddenFocusableEl']
    }],
    lastHiddenFocusableElementOnOverlay: [{
      type: ViewChild,
      args: ['lastHiddenFocusableEl']
    }],
    valueTemplate: [{
      type: ContentChild,
      args: ['value', {
        descendants: false
      }]
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['header', {
        descendants: false
      }]
    }],
    emptyTemplate: [{
      type: ContentChild,
      args: ['empty', {
        descendants: false
      }]
    }],
    footerTemplate: [{
      type: ContentChild,
      args: ['footer', {
        descendants: false
      }]
    }],
    clearIconTemplate: [{
      type: ContentChild,
      args: ['clearicon', {
        descendants: false
      }]
    }],
    triggerIconTemplate: [{
      type: ContentChild,
      args: ['triggericon', {
        descendants: false
      }]
    }],
    dropdownIconTemplate: [{
      type: ContentChild,
      args: ['dropdownicon', {
        descendants: false
      }]
    }],
    filterIconTemplate: [{
      type: ContentChild,
      args: ['filtericon', {
        descendants: false
      }]
    }],
    closeIconTemplate: [{
      type: ContentChild,
      args: ['closeicon', {
        descendants: false
      }]
    }],
    itemTogglerIconTemplate: [{
      type: ContentChild,
      args: ['itemtogglericon', {
        descendants: false
      }]
    }],
    itemCheckboxIconTemplate: [{
      type: ContentChild,
      args: ['itemcheckboxicon', {
        descendants: false
      }]
    }],
    itemLoadingIconTemplate: [{
      type: ContentChild,
      args: ['itemloadingicon', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  }
});
class TreeSelectModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TreeSelectModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TreeSelectModule,
    imports: [TreeSelect, SharedModule],
    exports: [TreeSelect, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TreeSelectModule,
    imports: [TreeSelect, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TreeSelectModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [TreeSelect, SharedModule],
      exports: [TreeSelect, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { TREESELECT_VALUE_ACCESSOR, TreeSelect, TreeSelectClasses, TreeSelectModule, TreeSelectStyle };
