import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { TabsModule } from "primeng/tabs";
import { TableModule } from "primeng/table";
import { Product } from "src/app/models/product.model";
import { BillingFrequency } from "src/app/models/enums/billing-frequency.enum";
import { ProductSelectionComponent } from "../product-selection/product-selection.component";
import { ProductService } from "src/app/services/product.service";
import { ElectricityType } from "src/app/models/enums/electricity-type.enum";
import { GasType } from "src/app/models/enums/gas-type.enum";

@Component({
    selector: 'product-compare-component',
    standalone: true,
    templateUrl: './product-compare.component.html',
    styleUrls: ['product-compare.component.scss'],
    imports: [TabsModule, TableModule, ButtonModule, CommonModule, FormsModule, ReactiveFormsModule, ProductSelectionComponent],
    providers: [ProductService]
})
export class ProductCompareComponent {
    InvoicingModality = BillingFrequency;
    productType: string = 'Electricity';

    

    electricityProducts: Product[] = [];
    gasProducts: Product[] = [];

    constructor(private productService: ProductService) {
        this.productService.getAllProducts().subscribe(products => {
            this.electricityProducts = products.filter(x => x.enumType == "ElectricityType");
            this.gasProducts = products.filter(x => x.enumType == "GasType");
        });
    }
}