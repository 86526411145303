import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, Router, RouterStateSnapshot } from "@angular/router";
import { StringHelper } from "src/app/helpers/string.helper";
import { Contract } from "src/app/models/contract.model";
import { Customer } from "src/app/models/customer.model";
import { PaymentPreferenceEnum } from "src/app/models/enums/payment-preference.enum";
import { SituationEnum } from "src/app/models/enums/situation.enum";
import { PaymentDetails } from "src/app/models/payment-details.model";
import * as ibantools from 'ibantools';

@Injectable()
export class CustomerWizardStep6CustomerGuard implements CanActivate {
    constructor(public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GuardResult {
        const extras = this.router.getCurrentNavigation()?.extras;
        const customer: Customer = extras?.state?.["customer"];
        const contract: Contract = extras?.state?.["contract"];
        const paymentDetails: PaymentDetails = extras?.state?.["paymentDetails"];
        return (extras?.state?.["electricityType"] ? true : false || extras?.state?.['gasType'] ? true : false) 
        && ((!!customer && customer.gender != 0 && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.firstName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.lastName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.telephone) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.streetName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.houseNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.postalCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.location) && (customer.invoiceAddressIsSameToPostalAddress || (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressStreetName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressHouseNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressPostalCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(customer.invoicingAddressLocation)))) ? true : false) 
        && ((!!contract && !!contract.situation && !!contract.startDate && (extras?.state?.["electricityType"] == null || StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.electricityEAN)) && (extras?.state?.["gasType"] == null || StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(contract.gasEAN))) ? true : false) 
        && ((!!paymentDetails && paymentDetails.billingFrequency != 0 && paymentDetails.invoiceModality != 0 && paymentDetails.paymentPreference != 0 && (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.iban) && ibantools.isValidIBAN(paymentDetails.iban)) && (paymentDetails.paymentPreference != PaymentPreferenceEnum["Ik verkies domicilliëring"] || (StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.bic) && ibantools.isValidBIC(paymentDetails.bic)) && (!paymentDetails.checkingAccountOnSomeoneElsesName || (!!paymentDetails.checkingAccountHolder && paymentDetails.checkingAccountHolder.gender != 0 && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.firstName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.lastName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.telephone) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.email) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.streetName) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.houseNumber) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.postalCode) && StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(paymentDetails.checkingAccountHolder.location))) ? true : false) ) ? true : false)
        && extras?.state?.["confirmed"];
    }
}