.radio-left {
    position: relative;
    float: left;
}

.radio-right {
    display: flex !important;
    position: relative !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
}

.checkbox-left {
    position: relative;
    float: left;
}

.close-button-right {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 15px;
}