import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { TagModule } from "primeng/tag";
import { NewsItem } from "src/app/models/news-item.model";
import { ProductService } from "src/app/services/product.service";
import { Product } from "src/app/models/product.model";
import { random } from "lodash";
import { NewsArticleComponent } from "../../components/news-article/news-article.component";
import { UmbracoService } from "src/app/services/umbraco.service";
import { Subscription } from "rxjs";

@Component({
    selector: 'news-page',
    standalone: true,
    styleUrls: ['./news.page.scss'],
    templateUrl: './news.page.html',
    imports: [ButtonModule, CommonModule, TagModule, NewsArticleComponent],
    providers: [ProductService, RouterModule, UmbracoService]
})
export class NewsPage {
    window = window;
    products!: Product[];

    subscribers: Subscription[] = [];

    promotedProduct!: Product;

    constructor(private activatedRoute: ActivatedRoute, private productService: ProductService, private umbracoService: UmbracoService) {
        var title = this.activatedRoute.snapshot.params['title'];
        this.subscribers.push(this.umbracoService.getNewsItems().subscribe(news => {
            this.news = news;
            this.newsItem = this.news.find(newsItem => {
                var test = newsItem.title?.replaceAll(/[:\-]/gm, '').toLowerCase()?.split(' ');
                var titleTest = title?.toLowerCase()?.split('-')
                return JSON.stringify(test) == JSON.stringify(titleTest); 
            });
        }));
        this.subscribers.push(this.productService.getAllProducts().subscribe(products => {this.products = products; this.promotedProduct = products[random(0, products.length - 1)]}));
    }

    newsItem: NewsItem | undefined;

    news!: NewsItem[];
}