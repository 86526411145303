import { Pipe, PipeTransform, Predicate } from "@angular/core";

@Pipe({name: 'filter', standalone: true})
export class FilterPipe implements PipeTransform {
  transform(array: any[], predicate: Predicate<any>): any[] {
    if (!array) {
      return [];
    }

    return array.filter(predicate);
  }
}