:host ::ng-deep > app-header > * { 
    @at-root .backgroundLightEbemGreen {
        background-color: #E8EFCD !important;
    }

    @at-root .backgroundLightEbemGreenHalfWhite {
        background: linear-gradient(#E8EFCD 0 50%, white 50% 100%)  !important;
    }
    
    @at-root .backgroundDarkEbemGreen {
        background-color: #20260B !important;
    }
    
    @at-root .backgroundEbemGreen {
        background-color: #A2C138 !important;
    }


    display: block;
    padding: 0px 5%;
    background-color: white;
} 


:host ::ng-deep router-outlet + * > * > * { 
    @at-root .backgroundLightEbemGreen {
        background-color: #E8EFCD !important;
    }
    
    @at-root .backgroundDarkEbemGreen {
        background-color: #20260B !important;
    }
    
    @at-root .backgroundEbemGreen {
        background-color: #A2C138 !important;
    }

    display: block;
    padding: 0px 5%;
    background-color: white;
} 

:host ::ng-deep > app-footer > p-card > .p-card { 
    @at-root .backgroundLightEbemGreen {
        background-color: #E8EFCD !important;
    }
    
    @at-root .backgroundDarkEbemGreen {
        background-color: #20260B !important;
    }
    
    @at-root .backgroundEbemGreen {
        background-color: #A2C138 !important;
    }


    display: block;
    padding: 0px 5%;
    background-color: white;
} 