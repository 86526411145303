import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { DatePickerModule } from "primeng/datepicker"
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RadioButtonModule } from 'primeng/radiobutton'
import { ElectricityType } from "src/app/models/enums/electricity-type.enum";
import { GasType } from "src/app/models/enums/gas-type.enum";
import { ElectricityType as ElectricityTypeSimulation } from "src/app/models/enums/electricity-type-simulation.enum";
import { GasType as GasTypeSimulation } from "src/app/models/enums/gas-type-simulation.enum";
import { Router, RouterModule } from "@angular/router";
import { Customer } from "src/app/models/customer.model";
import { Contract } from "src/app/models/contract.model";
import { CheckboxModule } from "primeng/checkbox";
import { PaymentDetails } from "src/app/models/payment-details.model";
import { PaymentPreferenceEnum } from "src/app/models/enums/payment-preference.enum";
import { BillingFrequency } from "src/app/models/enums/billing-frequency.enum";
import { InvoiceModality } from "src/app/models/enums/invoice-modality.enum";
import { CommonModule } from "@angular/common";
import { SituationEnum } from "src/app/models/enums/situation.enum";
import { ProgressBarModule } from "primeng/progressbar";
import { CardModule } from "primeng/card";
import { Nullable } from "primeng/ts-helpers";
import * as ibantools from 'ibantools';
import { BelgianBankCodeToBic } from "src/app/models/enums/belgian-bankcode-to-bic.enum";
import { EnumKeyValue } from "src/app/pipes/enumKeyValue.pipe";
import { StringHelper } from "src/app/helpers/string.helper";
import { GenderEnum } from "src/app/models/enums/gender.enum";
import { SelectModule } from "primeng/select";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";
import { PostalCode } from "src/app/models/postalcode.model";
import { PostalcodeService } from "src/app/services/postalcode.service";
import { CountryEnum } from "src/app/models/enums/countries.enum";

@Component({
    selector: 'customer-wizard-step-4-customer',
    standalone: true,
    styleUrls: ['./customer-wizard-step-4.page.scss'],
    templateUrl: './customer-wizard-step-4.page.html',
    imports: [ButtonModule, CardModule, CheckboxModule, CommonModule, DatePickerModule, EnumKeyValue, InputNumberModule, InputGroupModule, InputGroupAddonModule, InputTextModule, FormsModule, ProgressBarModule, RadioButtonModule, RouterModule, SelectModule],
    providers: [PostalcodeService, EnumKeyValue]
})
export class CustomerWizardStep4CustomerPage {
    //Enum values    
    electricityType: number;
    gasType: number;
    
    //Enum definitions
    ElectricityType = ElectricityType;
    GasType = GasType;
    Gender = GenderEnum;
    Countries = CountryEnum;

    PostalCodes: PostalCode[] = [];

    StringHelper = StringHelper;

    priceSimulationInput: PriceSimulationInput;

    customer: Customer; 
    contract: Contract;
    paymentDetails: PaymentDetails;
    PaymentPreference = PaymentPreferenceEnum; 
    BillingFrequency = BillingFrequency;
    InvoiceModality = InvoiceModality;
    ContractSituation = SituationEnum;

    ElectricityTypeSimulation = ElectricityTypeSimulation;
    GasTypeSimulation = GasTypeSimulation;

    postalCode!: string | number | undefined;

    inputPaymentDetailsBillingFrequencyValid: Nullable<boolean>;
    inputPaymentDetailsInvoiceModalityValid: Nullable<boolean>;
    inputPaymentDetailsPaymentPreferenceValid: Nullable<boolean>;
    inputPaymentDetailsIbanValid: Nullable<boolean>;
    inputPaymentDetailsBicValid: Nullable<boolean>;

    showErrorNoInvoiceModalityChosen: boolean = false;
    showErrorNoBillingFrequencyChosen: boolean = false;
    showErrorNoPaymentPreferenceChosen: boolean = false;
    showErrorNoIbanFilledIn: boolean = false;
    showErrorNoBicFilledIn: boolean = false;
    showErrorInvalidBic: boolean = false;
    showErrorInvalidIban: boolean = false;

    inputGenderValid: Nullable<boolean>;
    inputFirstNameValid: Nullable<boolean>;
    inputLastNameValid: Nullable<boolean>;
    inputEmailValid: Nullable<boolean>;
    inputTelephoneValid: Nullable<boolean>;    
    inputStreetNameValid: Nullable<boolean>;
    inputHouseNumberValid: Nullable<boolean>;
    inputPostalCodeValid: Nullable<boolean>;
    inputLocationValid: Nullable<boolean>;
    inputCountryValid: Nullable<boolean>;

    showErrorNoGenderChosen: boolean = false;
    showErrorNoFirstNameFilledIn: boolean = false;
    showErrorNoLastNameFilledIn: boolean = false;
    showErrorNoTelephoneFilledIn: boolean = false;
    showErrorTelephoneWrongFormat: boolean = false;
    showErrorNoEmailFilledIn: boolean = false;
    showErrorEmailWrongFormat: boolean = false;
    showErrorNoStreetNameFilledIn: boolean = false;
    showErrorNoHouseNumberFilledIn: boolean = false;
    showErrorNoPostalCodeFilledIn: boolean = false;
    showErrorPostalCodeInvalid: boolean = false;
    showErrorNoLocationFilledIn: boolean = false;
    showErrorNoCountryFilledIn: boolean = false;

    constructor(private postalcodeService: PostalcodeService, public router: Router, private enumKeyValue: EnumKeyValue) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"]; 
        this.customer = window.history.state["customer"]; 
        this.contract = window.history.state["contract"];
        this.paymentDetails = window.history.state["paymentDetails"] ?? <PaymentDetails>{ checkingAccountHolder: <Customer>{ invoiceAddressIsSameToPostalAddress: true, telephone: '+32', country: 'BE' } };
        this.postalCode = window.history.state["postalCode"];
        this.priceSimulationInput = window.history.state["priceSimulationInput"];
        this.postalcodeService.getPostalCodes().subscribe((res: PostalCode[]) => {
            this.PostalCodes = res;
        });
    }

    validateInputs() {
        this.validateBillingFrequencyInput();

        this.validateInvoiceModalityInput();

        this.validatePaymentPreferenceInput();

        this.validateIbanInput();
        
        this.validateBicInput();

        this.validateGenderInput();

        this.validateFirstNameInput();
        
        this.validateLastNameInput();

        this.validateEmailInput();

        this.validateTelephoneNumberInput();

        this.validateStreetNameInput();

        this.validateHouseNumberInput();

        this.validatePostalCodeInput();

        this.validateLocationInput();

        this.validateCountryInput();
    }

    validateCountryInput() {
        this.inputCountryValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || !!this.paymentDetails.checkingAccountHolder.country;
        this.showErrorNoCountryFilledIn = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.country;
    }

    validateLocationInput() {
        this.inputLocationValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || !!this.paymentDetails.checkingAccountHolder.location;
        this.showErrorNoLocationFilledIn = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.location;
    }

    validatePostalCodeInput() {
        this.inputPostalCodeValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || !!this.paymentDetails.checkingAccountHolder.postalCode;
        this.showErrorNoPostalCodeFilledIn = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.postalCode;
    }

    validateHouseNumberInput() {
        this.inputHouseNumberValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || !!this.paymentDetails.checkingAccountHolder.houseNumber;
        this.showErrorNoHouseNumberFilledIn = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.houseNumber;
    }

    validateStreetNameInput() {
        this.inputStreetNameValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || !!this.paymentDetails.checkingAccountHolder.streetName;
        this.showErrorNoStreetNameFilledIn = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.streetName;
    }

    validateTelephoneNumberInput() {
        this.inputTelephoneValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || (!!this.paymentDetails.checkingAccountHolder.telephone && (!!this.paymentDetails.checkingAccountHolder.telephone.match(/^[+][3][1-2][0-9]{8}$/) || !!this.paymentDetails.checkingAccountHolder.telephone.match(/^[+][3][1-2][4][0-9]{8}$/)));
        this.showErrorNoTelephoneFilledIn = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.telephone;
        this.showErrorTelephoneWrongFormat = this.paymentDetails.checkingAccountOnSomeoneElsesName && !!this.paymentDetails.checkingAccountHolder.telephone && !this.paymentDetails.checkingAccountHolder.telephone.match(/^[+][3][1-2][0-9]{8}$/) && !this.paymentDetails.checkingAccountHolder.telephone.match(/^[+][3][1-2][4][0-9]{8}$/);
    }

    validateEmailInput() {
        this.inputEmailValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || (!!this.paymentDetails.checkingAccountHolder.email && !!this.paymentDetails.checkingAccountHolder.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/));
        this.showErrorNoEmailFilledIn = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.email;
        this.showErrorEmailWrongFormat = this.paymentDetails.checkingAccountOnSomeoneElsesName && !!this.paymentDetails.checkingAccountHolder.email && !this.paymentDetails.checkingAccountHolder.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/);
    }

    validateLastNameInput() {
        this.inputLastNameValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || !!this.paymentDetails.checkingAccountHolder.lastName;
        this.showErrorNoLastNameFilledIn = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.lastName;
    }

    validateFirstNameInput() {
        this.inputFirstNameValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || !!this.paymentDetails.checkingAccountHolder.firstName;
        this.showErrorNoFirstNameFilledIn = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.firstName;
    }

    validateGenderInput() {
        this.inputGenderValid = !this.paymentDetails.checkingAccountOnSomeoneElsesName || !!this.paymentDetails.checkingAccountHolder.gender;
        this.showErrorNoGenderChosen = this.paymentDetails.checkingAccountOnSomeoneElsesName && !this.paymentDetails.checkingAccountHolder.gender;
    }

    validateBicInput() {
        this.inputPaymentDetailsBicValid = this.paymentDetails.paymentPreference != PaymentPreferenceEnum["Ik verkies domicilliëring"] || !!this.paymentDetails.bic && ibantools.isValidBIC(this.paymentDetails.bic);
        this.showErrorNoBicFilledIn = (this.paymentDetails.paymentPreference == 0 || this.paymentDetails.paymentPreference == null || this.paymentDetails.paymentPreference == undefined || this.paymentDetails.paymentPreference == PaymentPreferenceEnum["Ik verkies domicilliëring"]) && !this.paymentDetails.bic;
        this.showErrorInvalidBic = !!this.paymentDetails.bic && !/^[A-Z]{4}[A-Z]{2}([A-Z0-9]{2})?$/.test(this.paymentDetails.bic);
    }

    validateIbanInput() {
        this.inputPaymentDetailsIbanValid = !!this.paymentDetails.iban && ibantools.isValidIBAN(this.paymentDetails.iban);
        this.showErrorNoIbanFilledIn = !this.paymentDetails.iban;
        this.showErrorInvalidIban = !!this.paymentDetails.iban && !ibantools.isValidIBAN(this.paymentDetails.iban);
        var iban = ibantools.extractIBAN(this.paymentDetails.iban);
        this.paymentDetails.bic = this.enumKeyValue.transform(BelgianBankCodeToBic).find(x => x.key == '0' + iban.bankIdentifier)?.value;
    }

    validatePaymentPreferenceInput() {
        this.inputPaymentDetailsPaymentPreferenceValid = (this.paymentDetails.paymentPreference != 0 && this.paymentDetails.paymentPreference != null && this.paymentDetails.paymentPreference != undefined);
        this.showErrorNoPaymentPreferenceChosen = (this.paymentDetails.paymentPreference == 0 || this.paymentDetails.paymentPreference == null || this.paymentDetails.paymentPreference == undefined);
    }

    validateInvoiceModalityInput() {
        this.inputPaymentDetailsInvoiceModalityValid = (this.paymentDetails.invoiceModality != 0 && this.paymentDetails.invoiceModality != null && this.paymentDetails.invoiceModality != undefined);
        this.showErrorNoInvoiceModalityChosen = (this.paymentDetails.invoiceModality == 0 || this.paymentDetails.invoiceModality == null || this.paymentDetails.invoiceModality == undefined);
    }

    validateBillingFrequencyInput() {
        this.inputPaymentDetailsBillingFrequencyValid = (this.paymentDetails.billingFrequency != 0 && this.paymentDetails.billingFrequency != null && this.paymentDetails.billingFrequency != undefined);
        this.showErrorNoBillingFrequencyChosen = (this.paymentDetails.billingFrequency == 0 || this.paymentDetails.billingFrequency == null || this.paymentDetails.billingFrequency == undefined);
    }

    validateForm() {
        this.validateInputs();
        return this.inputPaymentDetailsBillingFrequencyValid && this.inputPaymentDetailsInvoiceModalityValid && this.inputPaymentDetailsPaymentPreferenceValid && this.inputPaymentDetailsIbanValid && this.inputPaymentDetailsBicValid;
    }

    getNameFromPostalCode(postalCode: string | number | undefined): string {
        return this.PostalCodes.find(p => p.code == postalCode)?.name ?? '';
    }

    getPostalCodeFromName(name: string): string {
        return this.PostalCodes.find(p => p.name == name)?.code ?? '0';
    }
}