p-accordion {
    width: 50%;

    p-accordion-panel {
        border-start-start-radius: 30px !important;
        border-start-end-radius: 30px !important;
        border-end-start-radius: 30px !important;
        border-end-end-radius: 30px !important;

        p-accordion-header {
            padding: 5px 30px;
            border-radius: 100px !important;
        }

        p-accordion-content {
            border-radius: 0px !important;
        }
    }
}

.checkbox-left {
    position: relative;
    float: left;
}

p-inputgroup:has(.p-checkbox-checked) {
    border-color: #436807 !important;
    border-width: 2px !important;
    background-color: #D1E09B !important;
}

.p-tablist-tab-list {
    background-color: white !important;
}

.right-price {
    position: relative;
    float: right;
    color: gray;

    strong {
        color: black;
        font-weight: 400;
    }
}
