<div>
<h1><img class="icon" [src]="'icons/dynamic-tariffs-icon.png'" />Dynamische tarieven</h1>
<p>In een <strong>dynamisch contract</strong> wordt de prijs voor de <strong>energiekost per uur</strong> bepaald op basis van de prijzen op de energiebeurzen waarop Ebem energie aankoopt voor de dag nadien. Concreet zal dit betekenen dat jouw <strong>elektriciteitsafname per uur wordt afgerekend</strong> gebaseerd op de prijs van dat exacte uur.</p>
<p-button [outlined]="true">Meer informatie</p-button>
<dynamic-tariffs-graph-component></dynamic-tariffs-graph-component>
<div class="row">
    <div class="xl:col-8 lg:col-8 col-12">
        <dynamic-template *ngIf="htmlContent" [content]="htmlContent"></dynamic-template>
        <product-card [product]="dynamicProduct"></product-card>
    </div>
    <div class="xl:block xl:col-4 lg:block lg:col-4 hidden">
        <h3>Op deze pagina</h3>
        @for(bulletPoint of bulletPoints; track $index) {
            <div class="row">
                <div class="col-1">
                    <small [class]="window.location.hash.replace('#', '') == bulletPoint.href ? 'pi-circle-fill ebemGreen' : 'hidden'"></small>
                </div>
                <div class="col-11">
                    <a [class]="window.location.hash.replace('#', '') != bulletPoint.href ? 'gray' : ''" (click)="window.location.hash = bulletPoint.href">{{bulletPoint.title}}</a>
                </div>
            </div>
        }
    </div>
</div>
</div>