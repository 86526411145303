import { Injectable, Injector, ElementRef, ComponentFactory, ComponentRef, createNgModule, ViewContainerRef, TemplateRef, ViewChild } from '@angular/core';
import { OnMount } from './interfaces';
import { Accordion, AccordionContent, AccordionHeader, AccordionModule, AccordionPanel } from 'primeng/accordion';
import { Button, ButtonModule } from 'primeng/button';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { Card, CardModule } from 'primeng/card';
import { Carousel, CarouselModule } from 'primeng/carousel';
import { UIChart, ChartModule } from 'primeng/chart';
import { Checkbox, CheckboxModule } from 'primeng/checkbox';
import { ColorPicker, ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenu, ContextMenuModule } from 'primeng/contextmenu';
import { Dialog, DialogModule } from 'primeng/dialog';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { Fieldset, FieldsetModule } from 'primeng/fieldset';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Galleria, GalleriaModule } from 'primeng/galleria';
import { InputSwitch, InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { Listbox, ListboxModule } from 'primeng/listbox';
import { MegaMenu, MegaMenuModule } from 'primeng/megamenu';
import { Menubar, MenubarModule } from 'primeng/menubar';
import { Message, MessageModule } from 'primeng/message';
import { Messages, MessagesModule } from 'primeng/messages';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { OrderList, OrderListModule } from 'primeng/orderlist';
import { OrganizationChart, OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Paginator, PaginatorModule } from 'primeng/paginator';
import { Panel, PanelModule } from 'primeng/panel';
import { PanelMenu, PanelMenuModule } from 'primeng/panelmenu';
import { Password, PasswordModule } from 'primeng/password';
import { PickList, PickListModule } from 'primeng/picklist';
import { ProgressBar, ProgressBarModule } from 'primeng/progressbar';
import { RadioButton, RadioButtonModule } from 'primeng/radiobutton';
import { Rating, RatingModule } from 'primeng/rating';
import { SelectButton, SelectButtonModule } from 'primeng/selectbutton';
import { Slider, SliderModule } from 'primeng/slider';
import { SplitButton, SplitButtonModule } from 'primeng/splitbutton';
import { Steps, StepsModule } from 'primeng/steps';
import { Table, TableModule } from 'primeng/table';
import { TabMenu, TabMenuModule } from 'primeng/tabmenu';
import { TabView, TabViewModule } from 'primeng/tabview';
import { Terminal, TerminalModule } from 'primeng/terminal';
import { TieredMenu, TieredMenuModule } from 'primeng/tieredmenu';
import { Toast, ToastModule } from 'primeng/toast';
import { ToggleButton, ToggleButtonModule } from 'primeng/togglebutton';
import { Toolbar, ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { Tree, TreeModule } from 'primeng/tree';
import { TreeTable, TreeTableModule } from 'primeng/treetable';

export interface DynamicHTMLRef {
    check: () => void;
    destroy: () => void;
}

function isBrowserPlatform() {
    return window != null && window.document != null;
}

@Injectable()
export class DynamicHTMLRenderer {

    private componentFactories = new Map<string, ComponentFactory<any>>();

    private componentRefs = new Map<any, Array<ComponentRef<any>>>();

     private componentMap: { [key: string]: any } = {
                    'p-accordion': Accordion,
                    'p-accordion-header': AccordionHeader,
                    'p-accordion-content': AccordionContent,
                    'p-accordion-panel': AccordionPanel,
                    'p-button': Button,
                    'p-calendar': Calendar,
                    'p-card': Card,
                    'p-carousel': Carousel,
                    'p-chart': UIChart,
                    'p-checkbox': Checkbox,
                    'p-colorpicker': ColorPicker,
                    'p-confirmdialog': ConfirmDialog,
                    'p-contextmenu': ContextMenu,
                    'p-dialog': Dialog,
                    'p-dropdown': Dropdown,
                    'p-fieldset': Fieldset,
                    'p-fileupload': FileUpload,
                    'p-galleria': Galleria,
                    'p-inputswitch': InputSwitch,
                    'p-listbox': Listbox,
                    'p-megamenu': MegaMenu,
                    'p-menubar': Menubar,
                    'p-message': Message,
                    'p-messages': Messages,
                    'p-multiselect': MultiSelect,
                    'p-orderlist': OrderList,
                    'p-organizationchart': OrganizationChart,
                    'p-overlaypanel': OverlayPanel,
                    'p-paginator': Paginator,
                    'p-panel': Panel,
                    'p-panelmenu': PanelMenu,
                    'p-password': Password,
                    'p-picklist': PickList,
                    'p-progressbar': ProgressBar,
                    'p-radiobutton': RadioButton,
                    'p-rating': Rating,
                    'p-selectbutton': SelectButton,
                    'p-slider': Slider,
                    'p-splitbutton': SplitButton,
                    'p-steps': Steps,
                    'p-table': Table,
                    'p-tabmenu': TabMenu,
                    'p-tabview': TabView,
                    'p-terminal': Terminal,
                    'p-tieredmenu': TieredMenu,
                    'p-toast': Toast,
                    'p-togglebutton': ToggleButton,
                    'p-toolbar': Toolbar,
                    'p-tree': Tree,
                    'p-treetable': TreeTable,
                };

        private moduleMap: { [key: string]: any } = {
                        'p-accordion': AccordionModule,
                        'p-accordion-header': AccordionModule,
                        'p-accordion-content': AccordionModule,
                        'p-accordion-panel': AccordionModule,
                        'p-button': ButtonModule,
                        'p-calendar': CalendarModule,
                        'p-card': CardModule,
                        'p-carousel': CarouselModule,
                        'p-chart': ChartModule,
                        'p-checkbox': CheckboxModule,
                        'p-colorpicker': ColorPickerModule,
                        'p-confirmdialog': ConfirmDialogModule,
                        'p-contextmenu': ContextMenuModule,
                        'p-dialog': DialogModule,
                        'p-dropdown': DropdownModule,
                        'p-fieldset': FieldsetModule,
                        'p-fileupload': FileUploadModule,
                        'p-galleria': GalleriaModule,
                        'p-inputswitch': InputSwitchModule,
                        'p-inputtext': InputTextModule,
                        'p-listbox': ListboxModule,
                        'p-megamenu': MegaMenuModule,
                        'p-menubar': MenubarModule,
                        'p-message': MessageModule,
                        'p-messages': MessagesModule,
                        'p-multiselect': MultiSelectModule,
                        'p-orderlist': OrderListModule,
                        'p-organizationchart': OrganizationChartModule,
                        'p-overlaypanel': OverlayPanelModule,
                        'p-paginator': PaginatorModule,
                        'p-panel': PanelModule,
                        'p-panelmenu': PanelMenuModule,
                        'p-password': PasswordModule,
                        'p-picklist': PickListModule,
                        'p-progressbar': ProgressBarModule,
                        'p-radiobutton': RadioButtonModule,
                        'p-rating': RatingModule,
                        'p-selectbutton': SelectButtonModule,
                        'p-slider': SliderModule,
                        'p-splitbutton': SplitButtonModule,
                        'p-steps': StepsModule,
                        'p-table': TableModule,
                        'p-tabmenu': TabMenuModule,
                        'p-tabview': TabViewModule,
                        'p-terminal': TerminalModule,
                        'p-tieredmenu': TieredMenuModule,
                        'p-toast': ToastModule,
                        'p-togglebutton': ToggleButtonModule,
                        'p-toolbar': ToolbarModule,
                        'p-tooltip': TooltipModule,
                        'p-tree': TreeModule,
                        'p-treetable': TreeTableModule,
                    };

    constructor(private viewContainerRef: ViewContainerRef, private injector: Injector) {
        Object.keys(this.componentMap).forEach((selector) => {
            const moduleRef = createNgModule(this.moduleMap[selector], this.injector);
            const cf = moduleRef.componentFactoryResolver.resolveComponentFactory(this.componentMap[selector]);
            this.componentFactories.set(selector, cf);
        });
    }

    renderInnerHTML(tpl: TemplateRef<any>, elementRef: ElementRef, html: string): DynamicHTMLRef {
        if (!isBrowserPlatform()) {
            throw new Error('dynamic-html supports only browser platform.');
        }
        // elementRef.nativeElement.innerHTML = html;
        tpl.elementRef.nativeElement.innerHTML = html;
        const cmpRef = this.viewContainerRef.createEmbeddedView(tpl);

        const componentRefs: Array<ComponentRef<any>> = [];
        // Object.keys(this.componentMap).forEach((selector) => {
        //     const elements = (elementRef.nativeElement as Element).querySelectorAll(selector);
        //     Array.prototype.forEach.call(elements, (el: Element) => {
        //         const moduleRef = createNgModule(this.moduleMap[selector], this.injector);
        //         const cmpRef = this.viewContainerRef.createEmbeddedView(tpl);
        //         // // remove `ng-version` attribute
        //         // el.removeAttribute('ng-version');
        //         // if ((cmpRef.instance as OnMount).dynamicOnMount) {
        //         //     const attrsMap = new Map<string, string>();
        //         //     if (el.hasAttributes()) {
        //         //         Array.prototype.forEach.call(el.attributes, (attr: Attr) => {
        //         //             attrsMap.set(attr.name, attr.value);
        //         //         });
        //         //     }
        //         //     (cmpRef.instance as OnMount).dynamicOnMount(attrsMap, content, el);
        //         //     componentRefs.push(cmpRef);
        //         // }
        //         // // Render nested children
        //         // this.renderInnerHTML(new ElementRef(el), content);
        //     });
        // });
        this.componentRefs.set(elementRef, componentRefs);
        return {
            check: () => cmpRef.detectChanges(),
            destroy: () => {
                componentRefs.forEach(ref => ref.destroy());
                this.componentRefs.delete(elementRef);
            },
        };
    }
}