import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { AccordionModule } from "primeng/accordion";
import { ButtonModule } from "primeng/button";
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from 'primeng/radiobutton'
import { Contract } from "src/app/models/contract.model";
import { Customer } from "src/app/models/customer.model";
import { ElectricityType } from "src/app/models/enums/electricity-type.enum";
import { GasType } from "src/app/models/enums/gas-type.enum";
import { PaymentDetails } from "src/app/models/payment-details.model";
import { Product } from "src/app/models/product.model";

import { CommonModule } from "@angular/common";
import { CardModule } from "primeng/card";
import { Nullable } from "primeng/ts-helpers";
import { ProductService } from "src/app/services/product.service";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";
import { ProductSelectionComponent } from "src/app/components/product-selection/product-selection.component";

@Component({
    selector: 'customer-wizard-step-1-customer',
    standalone: true,
    styleUrls: ['./customer-wizard-step-1.page.scss'],
    templateUrl: './customer-wizard-step-1.page.html',
    imports: [AccordionModule, ButtonModule, CardModule, CommonModule, InputGroupModule, InputGroupAddonModule, FormsModule, ProgressBarModule, RadioButtonModule, RouterModule, ProductSelectionComponent],
    providers: [ProductService, RouterModule]
})
export class CustomerWizardStep1CustomerPage {
    //Enum values    
    electricityType: number;
    gasType: number;

    //Enum definitions
    ElectricityType = ElectricityType;
    GasType = GasType;
    
    customer: Customer;
    contract: Contract;
    paymentDetails: PaymentDetails;

    priceSimulationInput: PriceSimulationInput;

    postalCode!: string | number | undefined;

    formValid: Nullable<boolean>;
    showErrorNoProductChosen: boolean = false;

    
        electricityProducts: Product[] = [];
        gasProducts: Product[] = [];

    constructor(private productService: ProductService, public router: Router) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"]; 
        this.customer = window.history.state["customer"];
        this.contract = window.history.state["contract"];
        this.paymentDetails = window.history.state["paymentDetails"];
        this.postalCode = window.history.state["postalCode"];
        this.priceSimulationInput = window.history.state["priceSimulationInput"];
        if (this.priceSimulationInput) {
            this.productService.simulatePriceCalculation(this.priceSimulationInput).subscribe(products => {
                this.electricityProducts = products.filter(x => x.enumType == "ElectricityType");
                this.gasProducts = products.filter(x => x.enumType == "GasType");
            });
        } else {
            this.productService.getAllProducts().subscribe(products => {
                this.electricityProducts = products.filter(x => x.enumType == "ElectricityType");
                this.gasProducts = products.filter(x => x.enumType == "GasType");
            });
        }
    }

    castToElectricityType(type: string): ElectricityType {
        return type as unknown as ElectricityType;
    }

    castToGasType(type: string): GasType {
        return type as unknown as GasType;
    }

    evaluateForm() {
        console.log(this.electricityType);
        console.log(this.gasType);
        if (this.electricityType || this.gasType) {
            this.formValid = true;
        } else {
            this.showErrorNoProductChosen = true;
        }

        return this.formValid;
    }
}