<div>
<h1>Je aansluiting</h1>

<div class="grid fluid">
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <h3>Wat is je huidige situatie?</h3>
        </div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p-inputgroup (click)="contract.situation = ContractSituation['Ik wil veranderen van leverancier']; validateContractSituationInput(); changePossibleStartDates();">
                    <div class="radio-left">
                        <p-radio-button size="large" [ngClass]="inputContractSituationValid == null ||  inputContractSituationValid == undefined ? '' : inputContractSituationValid === true ? 'ng-valid' : inputContractSituationValid === false ? 'ng-invalid' : ''" [(ngModel)]="contract.situation" [value]="ContractSituation['Ik wil veranderen van leverancier']" ></p-radio-button><label>&nbsp;&nbsp;&nbsp;Ik wil veranderen van leverancier</label>
                    </div>
                </p-inputgroup>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p-inputgroup (click)="contract.situation = ContractSituation['Ik verhuis en kies voor Ebem (overname)']; validateContractSituationInput(); changePossibleStartDates();">
                    <div class="radio-left">
                        <p-radio-button size="large" [ngClass]="inputContractSituationValid == null ||  inputContractSituationValid == undefined ? '' : inputContractSituationValid === true ? 'ng-valid' : inputContractSituationValid === false ? 'ng-invalid' : ''" [(ngModel)]="contract.situation" [value]="ContractSituation['Ik verhuis en kies voor Ebem (overname)']" ></p-radio-button><label>&nbsp;&nbsp;&nbsp;Ik verhuis en kies voor Ebem (overname)</label>
                    </div>
                </p-inputgroup>
            </div>
        </div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p-inputgroup (click)="contract.situation = ContractSituation['Nieuwe aansluiting op het net (nieuwbouw)']; validateContractSituationInput(); changePossibleStartDates();">
                    <div class="radio-left">
                        <p-radio-button size="large" [ngClass]="inputContractSituationValid == null ||  inputContractSituationValid == undefined ? '' : inputContractSituationValid === true ? 'ng-valid' : inputContractSituationValid === false ? 'ng-invalid' : ''" [(ngModel)]="contract.situation" [value]="ContractSituation['Nieuwe aansluiting op het net (nieuwbouw)']" ></p-radio-button><label>&nbsp;&nbsp;&nbsp;Nieuwe aansluiting op het net (nieuwbouw)</label>
                    </div>
                </p-inputgroup>
            </div>
        </div>  
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p-inputgroup (click)="contract.situation = ContractSituation['De aansluiting heropenen na afsluiting']; validateContractSituationInput(); changePossibleStartDates();">
                    <div class="radio-left">
                        <p-radio-button size="large" [ngClass]="inputContractSituationValid == null ||  inputContractSituationValid == undefined ? '' : inputContractSituationValid === true ? 'ng-valid' : inputContractSituationValid === false ? 'ng-invalid' : ''" [(ngModel)]="contract.situation" [value]="ContractSituation['De aansluiting heropenen na afsluiting']" ></p-radio-button><label>&nbsp;&nbsp;&nbsp;De aansluiting heropenen na afsluiting</label>
                    </div>
                </p-inputgroup>
            </div>
        </div>  
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p-card class="danger no-padding" *ngIf="showErrorNoSituationChosen">Gelieve een keuze te maken</p-card>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="contract.situation == 2">
        <p-card class="backgroundEbemLighterGreen">
            <h3>Overnamedocument uploaden</h3>
            <p-fileupload ngDefaultControl [(ngModel)]="contract.takeoverDocuments" mode="basic" accept="image/*, .pdf" url="/upload" [multiple]="false" maxFileSize="20000000" chooseLabel="Document uploaden" [chooseButtonProps]="{ outlined: contract.takeoverDocuments && contract.takeoverDocuments.length > 0 }" [chooseIcon]="contract.takeoverDocuments && contract.takeoverDocuments.length > 0 ? 'pi-check-circle' : 'pi-upload'" uploadLabel="Uploaden" cancelLabel="Annuleren" [auto]="true" [customUpload]="true" (uploadHandler)="onUpload($event);" ></p-fileupload>
            <br/>
            <p-button [style]="{padding: 0}" link="true" *ngIf="contract.takeoverDocuments && contract.takeoverDocuments.length > 0" (click)="contract.takeoverDocuments.length > 0 ? open(contract.takeoverDocuments[0]) : null" target="_blank">{{contract.takeoverDocuments[0]?.name}}</p-button>
        </p-card>
    </div>

    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <h3>Start van het contract</h3>
            <p>Selecteer op de kalender de datum waarop u het contract wenst te doen ingaan. De mogelijke data worden aangegeven.</p>
        </div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <p-datepicker #calendar [minDate]="today" [maxDate]="maxDate" [showOtherMonths]="true" [selectOtherMonths]="true" (onSelect)="validateContractStartDateInput()" [ngClass]="inputContractStartDateValid == null ||  inputContractStartDateValid == undefined ? '' : inputContractStartDateValid === true ? 'ng-valid' : inputContractStartDateValid === false ? 'ng-invalid' : ''" class="max-w-full" dataType="date" [dateFormat]="'dd.mm.yy'" [fluid]="true" [firstDayOfWeek]="1" [disabledDates]="disabledDatesRanges" [(ngModel)]="contract.startDate" [inline]="true" [showIcon]="true" [iconDisplay]="'input'" [icon]="'pi-calendar'"  />
                <p-card class="danger no-padding" *ngIf="showErrorNoStartDateChosen">Gelieve een startdatum te kiezen</p-card>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <h3>EAN-nummers</h3>
        </div>
    </div>
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12">
            <p>Voor elke aansluiting is een EAN-nummer aanwezig. Dit zijn aansluitcodes die vaak te vinden op uw energiefacturen of op de verbruiksmeters.<br/><br/><strong>Een EAN telt 18 cijfers en begint met 54.</strong></p>
        </div>
    </div>
    <div class="row">
        <p-card class="danger no-padding">
            <div class="row">
                <div class="col-1"><span class="pi-exclamation-triangle"></span></div>
                <div class="col-11">
                    Je mag de EAN-codes van je vorige verblijfplaats dus niet gebruiken! 
                    <br/><br/>
                    De EAN-codes die gevraagd worden zijn verbonden met de meters op je nieuwe adres.
                    <br/><br/>
                    Bij de minste twijfel raden wij je aan om je netbeheerder te contacteren: zij kunnen je de juiste codes binnen de 5 dagen bezorgen.
                </div>
            </div>
        </p-card>
    </div>
    <div class="row">
        <div class="xl:col-12 lg:col-12 col-12"> 
            <label>Ean Nummers</label>
        </div>
        @if(StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(electricityType)) {
            <div class="row">
                <div class="xl:col-1 lg:col-1 col-1"><span class="icon pi pi-bolt ebemGreen"></span></div>
                <div class="xl:col-11 lg:col-11 col-11"><input (input)="validateElectricityEANInput()" [ngClass]="inputContractElectricityEANValid == null ||  inputContractElectricityEANValid == undefined ? '' : inputContractElectricityEANValid === true ? 'ng-valid' : inputContractElectricityEANValid === false ? 'ng-invalid' : ''" [(ngModel)]="contract.electricityEAN" pInputText /></div>
                <p-card class="danger no-padding" *ngIf="showErrorNoElectricityEANFilledIn">Elekticiteit EAN nummer is een verplicht veld</p-card>
                <p-card class="danger no-padding" *ngIf="showErrorElectricityEANMinimumLengthNotMet">Elekticiteit EAN nummer moet minstens uit 18 cijfers bestaan en beginnen met 54</p-card>
                <p-card class="danger no-padding" *ngIf="showErrorElectricityEANInvalid">Elekticiteit EAN nummer is niet geldig</p-card>
            </div>
        }
        @if(StringHelper.isNotNullOrEmptyOrWhiteSpaceOrUndefined(gasType)) {
            <div class="row">
                <div class="xl:col-1 lg:col-1 col-1"><span class="icon material-icons ebemBlue">local_fire_department</span></div>
                <div class="xl:col-11 lg:col-11 col-11"><input (input)="validateGasEANInput()" [ngClass]="inputContractGasEANValid == null ||  inputContractGasEANValid == undefined ? '' : inputContractGasEANValid === true ? 'ng-valid' : inputContractGasEANValid === false ? 'ng-invalid' : ''" [(ngModel)]="contract.gasEAN" pInputText /></div>
                <p-card class="danger no-padding" *ngIf="showErrorNoGasEANFilledIn">Gas EAN nummer is een verplicht veld</p-card>
                <p-card class="danger no-padding" *ngIf="showErrorGasEANMinimumLengthNotMet">Gas EAN nummer moet minstens uit 18 cijfers bestaan en beginnen met 54</p-card>
                <p-card class="danger no-padding" *ngIf="showErrorGasEANInvalid">Gas EAN nummer is niet geldig</p-card>
            </div>
        }
    </div>
    <div class="row">
        <p-accordion [expandIcon]="'pi-angle-down'" [collapseIcon]="'pi-angle-up'">
            <p-accordion-panel value="0">
                <p-accordion-header class="backgroundLightGray">Waar vind ik mijn EAN</p-accordion-header>
                <p-accordion-content>            
                    <p>
                        Je kan de EAN-code terugvinden op je energiefactuur of op de meter zelf. Bij de minste twijfel raden wij je aan om je netbeheerder te contacteren: zij kunnen je de juiste codes binnen de 5 dagen bezorgen.
                    </p>
                </p-accordion-content>
            </p-accordion-panel>
        </p-accordion>
    </div>    
</div>
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="60" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/customer-wizard/self-employed', 'step-2']" [state]="{ 'gasType': gasType, 'electricityType': electricityType, 'company': company, 'contract': contract, 'paymentDetails': paymentDetails, 'postalCode': postalCode, 'priceSimulationInput': priceSimulationInput }">Terug</p-button>
    </div>
    <div class="col-6">
        <p-button class="float-right" (click)="validateForm() ? this.router.navigate(['/customer-wizard/self-employed', 'step-4'], {state: { 'gasType': gasType, 'electricityType': electricityType, 'company': company, 'contract': contract, 'paymentDetails': paymentDetails, 'postalCode': postalCode, 'priceSimulationInput': priceSimulationInput }}) : null">Volgende</p-button>   
    </div>
</div>
</div>