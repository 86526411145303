import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccordionModule } from "primeng/accordion";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox"
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ElectricityType } from "src/app/models/enums/electricity-type-simulation.enum";
import { GasType } from "src/app/models/enums/gas-type-simulation.enum";
import { Router, RouterModule } from "@angular/router";
import { PriceSimulationChoice } from "src/app/models/enums/price-simulation-choice.enum";
import { PriceSimulationOptions } from "src/app/models/enums/price-simulation-options.enum";
import { BuildingType } from "src/app/models/enums/building-type.enum";
import { CommonModule } from "@angular/common";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ProductSelectionComponent } from "../../../components/product-selection/product-selection.component";
import { Product } from "src/app/models/product.model";
import { TabsModule } from "primeng/tabs";
import { ProgressBarModule } from "primeng/progressbar";
import { CardModule } from "primeng/card";
import { Nullable } from "primeng/ts-helpers";
import { ProductService } from "src/app/services/product.service";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";

@Component({
    selector: 'price-calculation-step-2a',
    standalone: true,
    styleUrls: ['./price-calculation-step-2a.page.scss'],
    templateUrl: './price-calculation-step-2a.page.html',
    imports: [AccordionModule, ButtonModule, CardModule, CheckboxModule, CommonModule, InputNumberModule, InputGroupModule, InputGroupAddonModule, InputTextModule, FormsModule, OverlayPanelModule, ProgressBarModule, RadioButtonModule, RouterModule, TabsModule, ProductSelectionComponent],
    providers: [ProductService, RouterModule]
})
export class PriceCalculationStep2aPage {
    //Enum values
    electricityType: ElectricityType;
    gasType: GasType;
    priceSimulationInput: PriceSimulationInput;
    priceSimulationChoices: PriceSimulationChoice[];
    priceSimulationOptions: PriceSimulationOptions;
    buildingType: BuildingType;

    //Enum definitions
    ElectricityType = ElectricityType;
    GasType = GasType;
    PriceSimulationChoice = PriceSimulationChoice;
    PriceSimulationOptions = PriceSimulationOptions;
    BuildingType = BuildingType;

    postalCode: number;

    numberOfPersons: number;
    estimatedElectricityUse: number;
    estimatedGasUse: number;

    formValid: Nullable<boolean>;
    showErrorNoProductChosen: boolean = false;
    
    
        electricityProducts: Product[] = [];
        gasProducts: Product[] = [];

    constructor(private productService: ProductService, public router: Router) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"]; 
        this.priceSimulationInput = window.history.state["priceSimulationInput"];
        this.priceSimulationChoices = window.history.state["priceSimulationChoices"];
        this.priceSimulationOptions = window.history.state["priceSimulationOptions"]; 
        this.buildingType = window.history.state["buildingType"];
        this.postalCode = window.history.state["postalCode"]; 
        this.numberOfPersons = window.history.state["numberOfPersons"];
        this.estimatedElectricityUse = window.history.state["estimatedElectricityUse"]; 
        this.estimatedGasUse = window.history.state["estimatedGasUse"];

        this.productService.getAllProducts().subscribe(products => {
            this.electricityProducts = products.filter(x => x.enumType == "ElectricityType");
            this.gasProducts = products.filter(x => x.enumType == "GasType");
        });
    }

    evaluateForm(event: Event) {
        if (this.electricityType || this.gasType) {
            this.formValid = true;
        } else {
            this.showErrorNoProductChosen = true;
        }

        return this.formValid;
    }
}