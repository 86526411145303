.button-left {
    float: left;
}

.button-right {
    float: right;
}

small {
    color: gray;
}

.news-item {
    border: 1px solid #e8efcd;
    border-radius: 5px;
    padding: 15px;
    margin-right: 10px;
}

.news-item:hover {
    background-color: #e8efcd;
    border: 2px solid #A2C138;
}

.news-buttons {
    float: right;
    align-self: center;
    
    p-button{
        margin-right: 5px;
    }
}