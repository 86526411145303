import { Pipe, PipeTransform, Predicate } from "@angular/core";

@Pipe({name: 'orderBy', standalone: true})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], field: string): any[] {
    if (!array) {
      return [];
    }

    array.sort((a: any, b: any) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
  }
}