import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import _ from "lodash";
import { NewsletterInput } from "../models/newsletter-input.model";

@Injectable()
export class NewsletterService {
    constructor(private http: HttpClient) { }

    postNewsletter(newsLetterInput: NewsletterInput, captchaResponse: string): Observable<{success: boolean}> {
        const headers = { 'cf-turnstile-response': captchaResponse };
        return this.http.post<{success: boolean}>(`${environment.baseUrl}/Subscription/SubscribeNewsletter`, newsLetterInput, { headers });
    }
}