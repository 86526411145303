<p-card styleClass="backgroundDarkEbemGreen no-radius">
        <div class="row"><br/></div>
        <div class="row"><br/></div>
        <div class="row">
            <div class="xl:col-2 lg:col-2 col-12">    
                <img src="img/logo white.png" height="100" >
            </div>
            <div class="xl:col-5 lg:col-5 col-12">    
                
            </div>
            <div class="xl:col-3 lg:col-3 col-12">  
                <br/>
                <label>Nieuwsbrief</label>
                <br/>
                <input [(ngModel)]="newsletterEmail" pInputText placeholder="Aa"/>  
                <p-card class="danger no-padding" *ngIf="showErrorNewsletterEmailInvalid">Email heeft niet het juiste formaat: tekenreeks beginnend met een letter of een cijfer gevolgd door een &#64; gevolgd door een emailprovider en een webextensie (.com, .be, ...)</p-card>
                <div>
                    
                    <ngx-turnstile [siteKey]="siteKey" (resolved)="sendCaptchaResponse($event)" theme="auto" [tabIndex]="0" [appearance]="'interaction-only'"></ngx-turnstile>
                </div>
            </div>
            <div class="xl:col-1 lg:col-1 col-12">  
                <br/> 
                <br/>
                <p-button (click)="validateNewsletterEmail(); subscribeNewsletter();">Inschrijven</p-button>
            </div>
        </div>
        <div class="row"><br/></div>
        <div class="row">
            <div class="xl:col-12 lg:col-12 col-12">
                <hr class="hrEbemGreen"/>
            </div>
        </div>
        <div class="row"><br/></div>
        <div class="row">
            <div class="xl:col-4 lg:col-4 col-12">
                <h6>CONTACTEER ONS</h6>
                <p><span class="pi-phone ebemGreen"></span>&nbsp;014 43 10 31</p>
                <p><span class="pi-envelope ebemGreen"></span>&nbsp;<a style="text-decoration: underline !important;" href="mailto:info@ebem.be">info&#64;ebem.be</a></p>
                <br/>
                <p>Ebem BV</p>
                <p>Leopoldstraat 10 bus 001</p>
                <p>2330 Merksplas</p>
                <p>BTW BE 0477.065.004</p>
                <br/>
                <br/>
            </div>
            <div class="xl:col-2 lg:col-2 col-12">
                <h6>KLANTTYPE</h6>
                <p><a [routerLink]="['/']">Particulier</a></p>
                <p><a [routerLink]="['/Zelfstandigen']">Zelfstandigen</a></p>
                <p><a [routerLink]="['/KMO']">KMO's</a></p>
            </div>
            <div class="xl:col-2 lg:col-2 col-12">
                <h6>PAGINA'S</h6>
                <p><a [routerLink]="['/customer-wizard/' + (window.location.pathname.includes('Zelfstandigen') || window.location.pathname.includes('self-employed') ? 'self-employed' : 'customer'), 'step-1']">Klant worden</a></p>
                <p><a [routerLink]="['/moving']">Verhuizen</a></p>
                <p><a [routerLink]="['/construction-or-renovation']">Nieuwbouw of renovatie</a></p>
            </div>
            <div class="xl:col-2 lg:col-2 col-12">
                <h6>EBEM</h6>
                <p><a [routerLink]="['/info']">Info</a></p>
                <p><a [routerLink]="['/about-us']">Over ons</a></p>
                <p><a [routerLink]="['/contact']">Contact</a></p>
            </div>
            <div class="xl:col-2 lg:col-2 col-12">
                <h6>BELANGRIJKE LINKS</h6>
                <p><a href="https://www.vreg.be">VREG</a></p>
                <p><a href="https://vtest.vreg.be">Prijsvergelijking VREG</a></p>
                <p><a href="https://www.creg.be">CREG</a></p>
                <p><a href="https://www.energiesparen.be">energiesparen.be</a></p>
                <p><a href="https://www.ombudsmanenergie.be">Ombudsman energie</a></p>
            </div>
        </div>
</p-card>
<p-card class="no-padding-top-bottom" styleClass="backgroundEbemGreen no-radius">
    <ng-template #content>
        <div class="row">
            <div class="xl:col-2 lg:col-2 col-12">
                <a class="darkGreenHover" [href]="'https://www.ebem.be/algemenevoorwaarden'">Algemene voorwaarden</a>
            </div>
            <div class="xl:col lg:col col-12">
                <a class="darkGreenHover" [routerLink]="['/privacypolicy']">Privacy policy</a>
            </div>
            <div class="xl:col lg:col col-12">
                <a class="darkGreenHover" [routerLink]="['/cookiepolicy']">Cookie policy</a>
            </div>
            <div class="xl:col lg:col col-12">
                <a class="darkGreenHover" [routerLink]="['/contact']">Contact</a>
            </div>
            <div class="xl:col-4 lg:col-4 col-12">
            </div>
    
            <div class="xl:col-2 lg:col-2 col-12">
                <a class="darkGreenHover">&#169; 2025, EBEM NV</a>
            </div>
        </div>
    </ng-template>
</p-card>
<p-toast/>