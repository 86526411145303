import { Component, Predicate } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccordionModule } from "primeng/accordion";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox"
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ElectricityType } from "src/app/models/enums/electricity-type-simulation.enum";
import { GasType } from "src/app/models/enums/gas-type-simulation.enum";
import { Router, RouterModule } from "@angular/router";
import { PriceSimulationChoice } from "src/app/models/enums/price-simulation-choice.enum";
import { PriceSimulationEnergyConsumers, PriceSimulationHeatingType, PriceSimulationMeterType, PriceSimulationOptions, PriceSimulationSolarPanels, PriceSimulationTariffType } from "src/app/models/enums/price-simulation-options.enum";
import { BuildingType } from "src/app/models/enums/building-type.enum";
import { CommonModule } from "@angular/common";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { CardModule } from "primeng/card";
import { TabsModule } from "primeng/tabs";
import { ProgressBarModule } from "primeng/progressbar";
import { ProductService } from "src/app/services/product.service";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";
import { Nullable } from "primeng/ts-helpers";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { NgxTurnstileModule } from "ngx-turnstile";
import { environment } from "src/environments/environment";

@Component({
  selector: 'price-calculation-step-4',
  standalone: true,
  styleUrls: ['./price-calculation-step-4.page.scss'],
  templateUrl: './price-calculation-step-4.page.html',
  imports: [AccordionModule, ButtonModule, CheckboxModule, CommonModule, InputNumberModule, InputGroupModule, InputGroupAddonModule, InputTextModule, FormsModule, OverlayPanelModule, ProgressBarModule, RadioButtonModule, RouterModule, TabsModule, ToastModule, CardModule, NgxTurnstileModule],
  providers: [MessageService, ProductService, RouterModule]
})
export class PriceCalculationStep4Page { //Enum values
    inputEmailValid: Nullable<boolean>;
    showErrorNoEmailFilledIn: boolean = false;
    showErrorEmailWrongFormat: boolean = false;
    email: string = "";
    captchaResponse: string = "";
    dataCheck: boolean = false;
    siteKey = environment.siteKey;

        //Enum values
        electricityType!: ElectricityType;
        gasType!: GasType;
        priceSimulationInput: PriceSimulationInput;
        priceSimulationChoices: PriceSimulationChoice[] = [];
        priceSimulationOptions: PriceSimulationOptions;
        buildingType: BuildingType = 0;
    
        //Enum definitions
        ElectricityType = ElectricityType;
        GasType = GasType;
        PriceSimulationChoice = PriceSimulationChoice;
        PriceSimulationMeterType = PriceSimulationMeterType;
        PriceSimulationTariffType = PriceSimulationTariffType;
        PriceSimulationSolarPanels = PriceSimulationSolarPanels;
        PriceSimulationHeatingType = PriceSimulationHeatingType;
        PriceSimulationEnergyConsumers = PriceSimulationEnergyConsumers;
        BuildingType = BuildingType;
    
        postalCode!: string | number | undefined;
    
        numberOfPersons!: number;
        estimatedElectricityUse!: number;
        estimatedGasUse!: number;
    
        constructor(private messageService: MessageService, private productService: ProductService, private router: Router) {
            this.electricityType = window.history.state["electricityType"];
            this.gasType = window.history.state["gasType"]; 
            this.priceSimulationInput = window.history.state["priceSimulationInput"];
            this.priceSimulationChoices = window.history.state["priceSimulationChoices"];
            this.priceSimulationOptions = window.history.state["priceSimulationOptions"] ?? <PriceSimulationOptions>{ meterType: PriceSimulationMeterType[""], tariffType: PriceSimulationTariffType[""], hasNightTariff: false, solarPanels: PriceSimulationSolarPanels[""], heatingType: PriceSimulationHeatingType[""] }; 
            this.buildingType = window.history.state["buildingType"];
            this.postalCode = window.history.state["postalCode"]; 
            this.numberOfPersons = window.history.state["numberOfPersons"];
            this.estimatedElectricityUse = window.history.state["estimatedElectricityUse"]; 
            this.estimatedGasUse = window.history.state["estimatedGasUse"];
        }
    
    validateEmailInput() {
      this.inputEmailValid = !!this.email && !!this.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/);
      this.showErrorNoEmailFilledIn = !this.email;
      this.showErrorEmailWrongFormat = !!this.email && !this.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/);
  }

  sendCaptchaResponse(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
}

    saveState(email: string, state: object) {
      this.productService.savePriceCalculation(this.captchaResponse, email, state).toPromise().then(res => {
          this.messageService.add({ severity: 'success', summary: 'Succes', detail: 'De simulatie is succesvol opgeslagen.' });
        }).catch(err => {
          this.messageService.add({ severity: 'error', summary: 'Fout', detail: 'Er is een fout opgetreden bij het opslaan van de simulatie.' });
      });
    }
}