import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, inject, HostBinding, Output, Input, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${dt('avatar.width')};
    height: ${dt('avatar.height')};
    font-size: ${dt('avatar.font.size')};
    color: ${dt('avatar.color')};
    background: ${dt('avatar.background')};
    border-radius: ${dt('avatar.border.radius')};
}

.p-avatar-image {
    background: transparent;
}

.p-avatar-circle {
    border-radius: 50%;
}

.p-avatar-circle img {
    border-radius: 50%;
}

.p-avatar-icon {
    font-size: ${dt('avatar.icon.size')};
    width: ${dt('avatar.icon.size')};
    height: ${dt('avatar.icon.size')};
}

.p-avatar img {
    width: 100%;
    height: 100%;
}

.p-avatar-lg {
    width: ${dt('avatar.lg.width')};
    height: ${dt('avatar.lg.width')};
    font-size: ${dt('avatar.lg.font.size')};
}

.p-avatar-lg .p-avatar-icon {
    font-size: ${dt('avatar.lg.icon.size')};
    width: ${dt('avatar.lg.icon.size')};
    height: ${dt('avatar.lg.icon.size')};
}

.p-avatar-xl {
    width: ${dt('avatar.xl.width')};
    height: ${dt('avatar.xl.width')};
    font-size: ${dt('avatar.xl.font.size')};
}

.p-avatar-xl .p-avatar-icon {
    font-size: ${dt('avatar.xl.font.size')};
    width: ${dt('avatar.xl.icon.size')};
    height: ${dt('avatar.xl.icon.size')};
}

.p-avatar-group {
    display: flex;
    align-items: center;
}

.p-avatar-group .p-avatar + .p-avatar {
    margin-inline-start: ${dt('avatar.group.offset')};
}

.p-avatar-group .p-avatar {
    border: 2px solid ${dt('avatar.group.border.color')};
}

.p-avatar-group .p-avatar-lg + .p-avatar-lg {
    margin-inline-start: ${dt('avatar.lg.group.offset')};
}

.p-avatar-group .p-avatar-xl + .p-avatar-xl {
    margin-inline-start: ${dt('avatar.xl.group.offset')};
}
`;
const classes = {
  root: ({
    props
  }) => ['p-avatar p-component', {
    'p-avatar-image': props.image != null,
    'p-avatar-circle': props.shape === 'circle',
    'p-avatar-lg': props.size === 'large',
    'p-avatar-xl': props.size === 'xlarge'
  }],
  label: 'p-avatar-label',
  icon: 'p-avatar-icon'
};
class AvatarStyle extends BaseStyle {
  name = 'avatar';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: AvatarStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: AvatarStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: AvatarStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * Avatar represents people using icons, labels and images.
 *
 * - [Live Demo](https://primeng.org/avatar)
 *
 * @module avatarstyle
 *
 */
var AvatarClasses;
(function (AvatarClasses) {
  /**
   * Class name of the root element
   */
  AvatarClasses["root"] = "p-avatar";
  /**
   * Class name of the label element
   */
  AvatarClasses["label"] = "p-avatar-label";
  /**
   * Class name of the icon element
   */
  AvatarClasses["icon"] = "p-avatar-icon";
})(AvatarClasses || (AvatarClasses = {}));

/**
 * Avatar represents people using icons, labels and images.
 * @group Components
 */
class Avatar extends BaseComponent {
  /**
   * Defines the text to display.
   * @group Props
   */
  label;
  /**
   * Defines the icon to display.
   * @group Props
   */
  icon;
  /**
   * Defines the image to display.
   * @group Props
   */
  image;
  /**
   * Size of the element.
   * @group Props
   */
  size = 'normal';
  /**
   * Shape of the element.
   * @group Props
   */
  shape = 'square';
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Establishes a string value that labels the component.
   * @group Props
   */
  ariaLabel;
  /**
   * Establishes relationships between the component and label(s) where its value should be one or more element IDs.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * This event is triggered if an error occurs while loading an image file.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onImageError = new EventEmitter();
  _componentStyle = inject(AvatarStyle);
  imageError(event) {
    this.onImageError.emit(event);
  }
  get hostClass() {
    return this.styleClass;
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Avatar,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: Avatar,
    isStandalone: true,
    selector: "p-avatar",
    inputs: {
      label: "label",
      icon: "icon",
      image: "image",
      size: "size",
      shape: "shape",
      style: "style",
      styleClass: "styleClass",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy"
    },
    outputs: {
      onImageError: "onImageError"
    },
    host: {
      properties: {
        "class.p-avatar": "true",
        "class.p-component": "true",
        "class.p-avatar-circle": "shape === \"circle\"",
        "class.p-avatar-lg": "size === \"large\"",
        "class.p-avatar-xl": "size === \"xlarge\"",
        "class.p-avatar-image": "image != null",
        "attr.data-pc-name": "\"avatar\"",
        "attr.aria-label": "ariaLabel",
        "attr.aria-labelledby": "ariaLabelledBy",
        "style": "style",
        "class": "this.hostClass"
      }
    },
    providers: [AvatarStyle],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <ng-content></ng-content>
        <span class="p-avatar-text" *ngIf="label; else iconTemplate">{{ label }}</span>
        <ng-template #iconTemplate><span [class]="icon" [ngClass]="'p-avatar-icon'" *ngIf="icon; else imageTemplate"></span></ng-template>
        <ng-template #imageTemplate> <img [src]="image" *ngIf="image" (error)="imageError($event)" [attr.aria-label]="ariaLabel" /></ng-template>
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Avatar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-avatar',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: `
        <ng-content></ng-content>
        <span class="p-avatar-text" *ngIf="label; else iconTemplate">{{ label }}</span>
        <ng-template #iconTemplate><span [class]="icon" [ngClass]="'p-avatar-icon'" *ngIf="icon; else imageTemplate"></span></ng-template>
        <ng-template #imageTemplate> <img [src]="image" *ngIf="image" (error)="imageError($event)" [attr.aria-label]="ariaLabel" /></ng-template>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        '[class.p-avatar]': 'true',
        '[class.p-component]': 'true',
        '[class.p-avatar-circle]': 'shape === "circle"',
        '[class.p-avatar-lg]': 'size === "large"',
        '[class.p-avatar-xl]': 'size === "xlarge"',
        '[class.p-avatar-image]': 'image != null',
        '[attr.data-pc-name]': '"avatar"',
        '[attr.aria-label]': 'ariaLabel',
        '[attr.aria-labelledby]': 'ariaLabelledBy',
        '[style]': 'style'
      },
      providers: [AvatarStyle]
    }]
  }],
  propDecorators: {
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    image: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    shape: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    onImageError: [{
      type: Output
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  }
});
class AvatarModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: AvatarModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: AvatarModule,
    imports: [Avatar, SharedModule],
    exports: [Avatar, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: AvatarModule,
    imports: [Avatar, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: AvatarModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [Avatar, SharedModule],
      exports: [Avatar, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { Avatar, AvatarClasses, AvatarModule, AvatarStyle };
