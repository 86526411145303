import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox"
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RadioButtonModule } from 'primeng/radiobutton'
import { ElectricityType } from "src/app/models/enums/electricity-type.enum";
import { GasType } from "src/app/models/enums/gas-type.enum";
import { ElectricityType as ElectricityTypeSimulation } from "src/app/models/enums/electricity-type-simulation.enum";
import { GasType as GasTypeSimulation } from "src/app/models/enums/gas-type-simulation.enum";
import { GenderEnum } from "src/app/models/enums/gender.enum";
import { Router, RouterModule } from "@angular/router";
import { Customer } from "src/app/models/customer.model";
import { Contract } from "src/app/models/contract.model";
import { PaymentDetails } from "src/app/models/payment-details.model";
import { PostalCode } from "src/app/models/postalcode.model";
import { SelectModule } from "primeng/select";
import { EnumKeyValue } from "src/app/pipes/enumKeyValue.pipe";
import { CommonModule } from "@angular/common";
import { ProgressBarModule } from "primeng/progressbar";
import { CardModule } from "primeng/card";
import { Nullable } from "primeng/ts-helpers";
import { StringHelper } from "src/app/helpers/string.helper";
import { PriceSimulationInput } from "src/app/models/price-simulation-input.model";
import { PostalcodeService } from "src/app/services/postalcode.service";
import { CountryEnum } from "src/app/models/enums/countries.enum";

@Component({
    selector: 'customer-wizard-step-2-customer',
    standalone: true,
    styleUrls: ['./customer-wizard-step-2.page.scss'],
    templateUrl: './customer-wizard-step-2.page.html',
    imports: [ButtonModule, CardModule, CheckboxModule, CommonModule, EnumKeyValue, InputGroupModule, InputNumberModule, InputTextModule, InputGroupAddonModule, FormsModule, ProgressBarModule, RadioButtonModule, RouterModule, SelectModule],
    providers: [PostalcodeService]
})
export class CustomerWizardStep2CustomerPage {
    //Enum values    
    electricityType: number;
    gasType: number;
    //Enum definitions
    ElectricityType = ElectricityType;
    Gender = GenderEnum;
    GasType = GasType;
    Countries = CountryEnum;
    PostalCodes:PostalCode[] = [];
    console = console;
    StringHelper = StringHelper;

    ElectricityTypeSimulation = ElectricityTypeSimulation;
    GasTypeSimulation = GasTypeSimulation;
    
    customer: Customer;
    contract: Contract;
    paymentDetails: PaymentDetails;

    priceSimulationInput: PriceSimulationInput;

    postalCode!: string | number | undefined;

    inputGenderValid: Nullable<boolean>;
    inputFirstNameValid: Nullable<boolean>;
    inputLastNameValid: Nullable<boolean>;
    inputEmailValid: Nullable<boolean>;
    inputTelephoneValid: Nullable<boolean>;    
    inputStreetNameValid: Nullable<boolean>;
    inputHouseNumberValid: Nullable<boolean>;
    inputPostalCodeValid: Nullable<boolean>;
    inputLocationValid: Nullable<boolean>;
    inputInvoicingAddressStreetNameValid: Nullable<boolean>;
    inputInvoicingAddressHouseNumberValid: Nullable<boolean>;
    inputInvoicingAddressPostalCodeValid: Nullable<boolean>;
    inputInvoicingAddressLocationValid: Nullable<boolean>;
    inputInvoicingAddressCountryValid: Nullable<boolean>;

    showErrorNoGenderChosen: boolean = false;
    showErrorNoFirstNameFilledIn: boolean = false;
    showErrorNoLastNameFilledIn: boolean = false;
    showErrorNoTelephoneFilledIn: boolean = false;
    showErrorTelephoneWrongFormat: boolean = false;
    showErrorNoEmailFilledIn: boolean = false;
    showErrorEmailWrongFormat: boolean = false;
    showErrorNoStreetNameFilledIn: boolean = false;
    showErrorNoHouseNumberFilledIn: boolean = false;
    showErrorNoPostalCodeFilledIn: boolean = false;
    showErrorPostalCodeInvalid: boolean = false;
    showErrorNoLocationFilledIn: boolean = false;
    showErrorNoInvoicingAddressStreetNameFilledIn: boolean = false;
    showErrorNoInvoicingAddressHouseNumberFilledIn: boolean = false;
    showErrorNoInvoicingAddressPostalCodeFilledIn: boolean = false;
    showErrorNoInvoicingAddressLocationFilledIn: boolean = false;
    showErrorNoInvoicingAddressCountryFilledIn: boolean = false;

    constructor(private postalCodeService: PostalcodeService, public router: Router) {
        this.electricityType = window.history.state["electricityType"];
        this.gasType = window.history.state["gasType"]; 
        this.customer = window.history.state["customer"] ?? <Customer>{ invoiceAddressIsSameToPostalAddress: true, telephone: '+32', invoicingAddressCountry: 'BE' };
        this.contract = window.history.state["contract"];
        this.paymentDetails = window.history.state["paymentDetails"];
        this.postalCode = window.history.state["postalCode"];
        this.priceSimulationInput = window.history.state["priceSimulationInput"];
        this.postalCodeService.getPostalCodes().subscribe(res => this.PostalCodes = res.filter(x => x.isValidArea));
    }

    validateInputs() {
        this.validateGenderInput();

        this.validateFirstNameInput();
        
        this.validateLastNameInput();

        this.validateEmailInput();

        this.validateTelephoneNumberInput();

        this.validateStreetNameInput();

        this.validateHouseNumberInput();

        this.validatePostalCodeInput();

        this.validateLocationInput();

        this.validateInvoicingAddressStreetNameInput();

        this.validateInvoicingAddressHouseNumberInput();

        this.validateInvoicingAddressPostalCodeInput();

        this.validateInvoicingAddressLocationInput();

        this.validateInvoicingAddressCountryInput();
    }
 
    validateInvoicingAddressCountryInput() {
        this.inputInvoicingAddressCountryValid = !!this.customer.invoiceAddressIsSameToPostalAddress || (!this.customer.invoiceAddressIsSameToPostalAddress && !!this.customer.invoicingAddressCountry);
        this.showErrorNoInvoicingAddressCountryFilledIn = !this.customer.invoiceAddressIsSameToPostalAddress && !this.customer.invoicingAddressCountry;
    }

    validateInvoicingAddressLocationInput() {
        this.inputInvoicingAddressLocationValid = !!this.customer.invoiceAddressIsSameToPostalAddress || (!this.customer.invoiceAddressIsSameToPostalAddress && !!this.customer.invoicingAddressLocation);
        this.showErrorNoInvoicingAddressLocationFilledIn = !this.customer.invoiceAddressIsSameToPostalAddress && !this.customer.invoicingAddressLocation;
    }

    validateInvoicingAddressPostalCodeInput() {
        this.inputInvoicingAddressPostalCodeValid = !!this.customer.invoiceAddressIsSameToPostalAddress || (!this.customer.invoiceAddressIsSameToPostalAddress && !!this.customer.invoicingAddressPostalCode);
        this.showErrorNoInvoicingAddressPostalCodeFilledIn = !this.customer.invoiceAddressIsSameToPostalAddress && !this.customer.invoicingAddressPostalCode;
    }

    validateInvoicingAddressHouseNumberInput() {
        this.inputInvoicingAddressHouseNumberValid = !!this.customer.invoiceAddressIsSameToPostalAddress || (!this.customer.invoiceAddressIsSameToPostalAddress && !!this.customer.invoicingAddressHouseNumber);
        this.showErrorNoInvoicingAddressHouseNumberFilledIn = !this.customer.invoiceAddressIsSameToPostalAddress && !this.customer.invoicingAddressHouseNumber;
    }

    validateInvoicingAddressStreetNameInput() {
        this.inputInvoicingAddressStreetNameValid = !!this.customer.invoiceAddressIsSameToPostalAddress || (!this.customer.invoiceAddressIsSameToPostalAddress && !!this.customer.invoicingAddressStreetName);
        this.showErrorNoInvoicingAddressStreetNameFilledIn = !this.customer.invoiceAddressIsSameToPostalAddress && !this.customer.invoicingAddressStreetName;
    }

    validateLocationInput() {
        this.inputLocationValid = !!this.customer.location;
        this.showErrorNoLocationFilledIn = !this.customer.location;
    }

    validatePostalCodeInput() {
        this.inputPostalCodeValid = !!this.customer.postalCode && !!this.PostalCodes.map(x => x.code).includes(this.customer.postalCode.toString());
        this.showErrorNoPostalCodeFilledIn = !this.customer.postalCode;
        this.showErrorPostalCodeInvalid = !!this.customer.postalCode && !this.PostalCodes.map(x => x.code).includes(this.customer.postalCode.toString());
    }

    validateHouseNumberInput() {
        this.inputHouseNumberValid = !!this.customer.houseNumber;
        this.showErrorNoHouseNumberFilledIn = !this.customer.houseNumber;
    }

    validateStreetNameInput() {
        this.inputStreetNameValid = !!this.customer.streetName;
        this.showErrorNoStreetNameFilledIn = !this.customer.streetName;
    }

    validateTelephoneNumberInput() {
        this.inputTelephoneValid = !!this.customer.telephone && (!!this.customer.telephone.match(/^[+][3][1-2][0-9]{8}$/) || !!this.customer.telephone.match(/^[+][3][1-2][4][0-9]{8}$/));
        this.showErrorNoTelephoneFilledIn = !this.customer.telephone;
        this.showErrorTelephoneWrongFormat = !!this.customer.telephone && !this.customer.telephone.match(/^[+][3][1-2][0-9]{8}$/) && !this.customer.telephone.match(/^[+][3][1-2][4][0-9]{8}$/);
    }

    validateEmailInput() {
        this.inputEmailValid = !!this.customer.email && !!this.customer.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/);
        this.showErrorNoEmailFilledIn = !this.customer.email;
        this.showErrorEmailWrongFormat = !!this.customer.email && !this.customer.email.match(/^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+$/);
    }

    validateLastNameInput() {
        this.inputLastNameValid = !!this.customer.lastName;
        this.showErrorNoLastNameFilledIn = !this.customer.lastName;
    }

    validateFirstNameInput() {
        this.inputFirstNameValid = !!this.customer.firstName;
        this.showErrorNoFirstNameFilledIn = !this.customer.firstName;
    }

    validateGenderInput() {
        this.inputGenderValid = !!this.customer.gender;
        this.showErrorNoGenderChosen = !this.customer.gender;
    }

    validateForm() {
        this.validateInputs();

        return this.inputGenderValid && this.inputFirstNameValid && this.inputLastNameValid && this.inputEmailValid && this.inputTelephoneValid && this.inputStreetNameValid && this.inputHouseNumberValid && this.inputPostalCodeValid && this.inputLocationValid && this.inputInvoicingAddressStreetNameValid && this.inputInvoicingAddressHouseNumberValid && this.inputInvoicingAddressPostalCodeValid && this.inputInvoicingAddressLocationValid;
    }

    getNameFromPostalCode(postalCode: string | number | undefined): string {
        return this.PostalCodes.find(p => p.code == postalCode)?.name ?? '';
    }

    getPostalCodeFromName(name: string): string {
        return this.PostalCodes.find(p => p.name == name)?.code ?? '0';
    }
}