<br/>
<br/>
<p-carousel #carousel [showIndicators]="false" [showNavigators]="false" [value]="news" [numVisible]="3" [numScroll]="3" [autoplayInterval]="10000" [responsiveOptions]="responsiveOptions">
    <ng-template #header>
        <div class="row">
            <div class="xl:col-8 lg:col-8 col-12">
                <h2><img class="icon-small" [src]="'icons/news-icon.png'" />Nieuws</h2>
            </div>
            <div class="xl:col-4 xl:flex xl:justify-content-end lg:col-4 lg:flex lg:justify-content-end col-12">
                <div class="news-buttons">
                    <p-button class="button-left" severity="secondary" (click)="carousel.page == 0 ? carousel.page = (news.length / carousel.numVisible) - 1 : carousel.navBackward($event, carousel.page - 1)"><span class="pi pi-arrow-left"></span></p-button>
                    <p-button class="button-right" (click)="carousel.page >= (news.length / carousel.numVisible) - 1 ? carousel.page = 0 : carousel.navForward($event, carousel.page + 1)"><span class="pi pi-arrow-right"></span></p-button>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #item let-news>
        <div class="news-item h-25rem">
            <div class="news-header">
                <small>{{news.date | date: 'dd/MM/yyyy'}}</small>
                <p-button [routerLink]="news.link ? news.link : '/artikels/' + htmlEncode(news.title)" class="button-right" [outlined]="true">Lezen</p-button>
            </div>
            <h3 class="pt-4 inline-block h-1rem">{{news.title}}</h3>
            <small class="pt-4 inline-block h-2rem">{{news.summary}}</small>
        </div>
    </ng-template>
</p-carousel>
<br/>
<br/>
<br/>