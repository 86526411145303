import { Component } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { GraphComponent } from "../graph/graph.component";
import { DatePickerModule } from "primeng/datepicker";
import { FormsModule } from "@angular/forms";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import { RatesService } from "src/app/services/rates.service";

@Component({
    selector: 'dynamic-tariffs-graph-component',
    standalone: true,
    templateUrl: './dynamic-tariffs-graph.component.html',
    styleUrls: ['dynamic-tariffs-graph.component.scss'],
    imports: [ButtonModule, DatePickerModule, GraphComponent, FormsModule],
    providers: [RatesService]
})
export class DynamicTariffsGraphComponent {
    today: Date = new Date();
    dataPerDate: Record<string, number[]> = {};
    selectedDate: Date = new Date();
    chartData: ChartData = {
        xLabels: [ "0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00" ],  
        datasets: [
            {
                type: "bar",
                label: "Prijs per uur in €/MWh",
                data: {...this.dataPerDate[this.selectedDate.toLocaleDateString()]},
                borderRadius: 2,
                backgroundColor: (ctx, options) => ctx?.parsed?.y >= 0 ? '#D1E09B' : '#BF8585',
                hoverBackgroundColor: (ctx, options) => ctx?.parsed?.y >= 0 ? '#A2C138' : '#991B1B',
            }
        ],
    };
    
    chartOptions: ChartOptions = {
        scales: {
            y: {
                beginAtZero: false,
                ticks: {
                    callback: (value) => `€${value}`
                }
            },
            x: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems: TooltipItem<'bar'>[]) => {
                        return tooltipItems.map(tooltipItem => {
                            return this.clockSymbol(tooltipItem.parsed.x) + ` ${tooltipItem.label}`;    
                        });
                    },
                    label: (tooltipItem: TooltipItem<'bar'>) => {
                        return `€ ${Math.round(tooltipItem.parsed.y * 100) / 100}/MWh`;
                    }
                }
            }
        },
    };

    constructor(private ratesService: RatesService) {
        this.ratesService.getDynamicGraphData(this.selectedDate).subscribe(data => {
            this.dataPerDate[new Date().toLocaleDateString()] = data.map(x => x['index']);
        });
    }

    generateDataForDate(date: Date): void {
        this.ratesService.getDynamicGraphData(this.selectedDate).subscribe(data => {
            this.dataPerDate[date.toLocaleDateString()] = data.map(x => x['index']);
            this.chartData.datasets[0].data = this.dataPerDate[date.toLocaleDateString()];
            this.chartData = {...this.chartData};
        });
    }

    addDays = function(date: Date, days: number) {
        date.setDate(date.getDate() + days);
        return date;
    }

    clockSymbol(hour: number): string {
        switch(hour) {
            case 0:
                return '🕛';
            case 1:
                return '🕐';
            case 2:
                return '🕑';
            case 3:
                return '🕒';
            case 4:
                return '🕓';
            case 5:
                return '🕔';
            case 6:
                return '🕕';
            case 7:
                return '🕖';
            case 8:
                return '🕗';
            case 9:
                return '🕘';
            case 10:
                return '🕙';
            case 11:
                return '🕚';
            case 12:
                return '🕛';
            case 13:
                return '🕐';
            case 14:
                return '🕑';
            case 15:
                return '🕒';
            case 16:
                return '🕓';
            case 17:
                return '🕔';
            case 18:
                return '🕕';
            case 19:
                return '🕖';
            case 20:
                return '🕗';
            case 21:
                return '🕘';
            case 22:
                return '🕙';
            case 23:
                return '🕚';
            default:
                return '🕛';
        }
    }
}