import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { CarouselModule, CarouselResponsiveOptions } from 'primeng/carousel';
import { NewsItem } from "src/app/models/news-item.model";
import { UmbracoService } from "src/app/services/umbraco.service";

@Component({
    selector: 'news-component',
    standalone: true,
    templateUrl: 'news.component.html',
    styleUrls: ['./news.component.scss'],
    imports: [ButtonModule, CarouselModule, CommonModule, FormsModule, RouterModule],
    providers: [UmbracoService]
})
export class NewsComponent {
    constructor(private umbracoService: UmbracoService) {
        this.umbracoService.getNewsItems().subscribe(news => this.news = news.sort((a, b) => a.date > b.date ? -1 : 1));
    }

    console = console;
    news!: NewsItem[];
    responsiveOptions: CarouselResponsiveOptions[] = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ]

    htmlEncode(value: string): string {
        return value.replaceAll(/\-/gm, '').replaceAll(/\:/gm, '').replaceAll(/\s/gm, '-');
    }
}