import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { jsonToProduct, Product } from "../models/product.model";
import _ from 'lodash';
import { environment } from "src/environments/environment";
import { PriceSimulationInput } from "../models/price-simulation-input.model";

@Injectable()
export class ProductService {
    constructor(private client: HttpClient) { }

    getAllProducts(): Observable<Product[]> {
        return this.client.get(environment.baseUrl + '/Product/GetAllProducts').pipe(map(res => _.map(res, x => jsonToProduct(x))));
    }

    simulatePriceCalculation(priceSimulationInput: PriceSimulationInput): Observable<Product[]> {
        return this.client.post(environment.baseUrl + '/Product/SimulatePriceCalculation', priceSimulationInput).pipe(map(res => _.map(res, x => jsonToProduct(x))));
    }

    savePriceCalculation(captchaResponse: string, email: string, state: object): Observable<any> {
        const headers = { 'cf-turnstile-response': captchaResponse };
        return this.client.post(environment.baseUrl + '/Subscription/SavePriceSimulation', { email: email, state: JSON.stringify(state) }, { headers: headers });
    }

    loadPriceCalculation(id: string): Observable<any> {
        return this.client.get(environment.baseUrl + '/Subscription/LoadPriceSimulation?id=' + id);
    }
}