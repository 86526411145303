export class Vacancy {
    isEnabled!: boolean;
    title!: string;
    innerHtml!: string;
}

export function jsonToVacancy(json: any): Vacancy {
    return {
        isEnabled: json?.properties?.isEnabled,
        title: json?.properties?.title,
        innerHtml: json?.properties?.innerHtml?.markup
    };
}