p-accordion {
    p-accordion-panel {
        border-start-start-radius: 30px !important;
        border-start-end-radius: 30px !important;
        border-end-start-radius: 30px !important;
        border-end-end-radius: 30px !important;

        p-accordion-header {
            padding: 5px 30px;
            border-radius: 100px !important;
        }

        p-accordion-content {
            border-radius: 0px !important;
        }
    }
}