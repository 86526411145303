<p-tabs [(value)]="selectedTab" [scrollable]="true">
    <div class="row">
        <p-tablist class="xl:col-4 lg:col-4 col-12">
            <div role="tablist" class="xl:flex-column lg:flex-column p-tablist-tab-list md:flex-row sm:flex-row">
            @for (infoTab of infoTabs; track infoTab.header) {        
                <p-tab [value]="infoTab.header | lowercase" class="xl:pl-0 lg:pl-0 xl:pr-0 lg:pr-0 xl:pt-0 lg:pt-0 xl:block lg:block" [style]="{ border: 'none' }"><p-button [fluid]="true" [outlined]="selectedTab != (infoTab.header | lowercase)">{{infoTab.header}}</p-button></p-tab>
            }
            </div>
        </p-tablist>
    
        <p-tabpanels class="xl:col-8 lg:col-8 col-12">
            @for (infoTab of infoTabs; track infoTab.header) {
                <p-tabpanel [value]="infoTab.header | lowercase">
                    <dynamic-template [content]="infoTab.content ?? ''"></dynamic-template>
                </p-tabpanel>
            }
        </p-tabpanels>
                
    </div>
</p-tabs>