import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, booleanAttribute, ContentChildren, ContentChild, Input, ViewEncapsulation, ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { SharedModule, PrimeTemplate } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: ${dt('tag.primary.background')};
    color: ${dt('tag.primary.color')};
    font-size: ${dt('tag.font.size')};
    font-weight: ${dt('tag.font.weight')};
    padding: ${dt('tag.padding')};
    border-radius: ${dt('tag.border.radius')};
    gap: ${dt('tag.gap')};
}

.p-tag-icon {
    font-size: ${dt('tag.icon.size')};
    width: ${dt('tag.icon.size')};
    height:${dt('tag.icon.size')};
}

.p-tag-rounded {
    border-radius: ${dt('tag.rounded.border.radius')};
}

.p-tag-success {
    background: ${dt('tag.success.background')};
    color: ${dt('tag.success.color')};
}

.p-tag-info {
    background: ${dt('tag.info.background')};
    color: ${dt('tag.info.color')};
}

.p-tag-warn {
    background: ${dt('tag.warn.background')};
    color: ${dt('tag.warn.color')};
}

.p-tag-danger {
    background: ${dt('tag.danger.background')};
    color: ${dt('tag.danger.color')};
}

.p-tag-secondary {
    background: ${dt('tag.secondary.background')};
    color: ${dt('tag.secondary.color')};
}

.p-tag-contrast {
    background: ${dt('tag.contrast.background')};
    color: ${dt('tag.contrast.color')};
}
`;
const classes = {
  root: ({
    props
  }) => ['p-tag p-component', {
    'p-tag-info': props.severity === 'info',
    'p-tag-success': props.severity === 'success',
    'p-tag-warn': props.severity === 'warn',
    'p-tag-danger': props.severity === 'danger',
    'p-tag-secondary': props.severity === 'secondary',
    'p-tag-contrast': props.severity === 'contrast',
    'p-tag-rounded': props.rounded
  }],
  icon: 'p-tag-icon',
  label: 'p-tag-label'
};
class TagStyle extends BaseStyle {
  name = 'tag';
  theme = theme;
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TagStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TagStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TagStyle,
  decorators: [{
    type: Injectable
  }]
});
/**
 *
 * Tag component is used to categorize content.
 *
 * [Live Demo](https://www.primeng.org/tag)
 *
 * @module tagstyle
 *
 */
var TagClasses;
(function (TagClasses) {
  /**
   * Class name of the root element
   */
  TagClasses["root"] = "p-tag";
  /**
   * Class name of the icon element
   */
  TagClasses["icon"] = "p-tag-icon";
  /**
   * Class name of the label element
   */
  TagClasses["label"] = "p-tag-label";
})(TagClasses || (TagClasses = {}));

/**
 * Tag component is used to categorize content.
 * @group Components
 */
class Tag extends BaseComponent {
  /**
   * Inline style of the component.
   * @group Props
   */
  get style() {
    return this._style;
  }
  set style(value) {
    this._style = value;
    this.cd.markForCheck();
  }
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Severity type of the tag.
   * @group Props
   */
  severity;
  /**
   * Value to display inside the tag.
   * @group Props
   */
  value;
  /**
   * Icon of the tag to display next to the value.
   * @group Props
   */
  icon;
  /**
   * Whether the corners of the tag are rounded.
   * @group Props
   */
  rounded;
  iconTemplate;
  templates;
  _iconTemplate;
  _style;
  _componentStyle = inject(TagStyle);
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'icon':
          this._iconTemplate = item.template;
          break;
      }
    });
  }
  containerClass() {
    let classes = 'p-tag p-component';
    if (this.severity) {
      classes += ` p-tag-${this.severity}`;
    }
    if (this.rounded) {
      classes += ' p-tag-rounded';
    }
    if (this.styleClass) {
      classes += ` ${this.styleClass}`;
    }
    return classes;
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: Tag,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "16.1.0",
    version: "19.1.7",
    type: Tag,
    isStandalone: true,
    selector: "p-tag",
    inputs: {
      style: "style",
      styleClass: "styleClass",
      severity: "severity",
      value: "value",
      icon: "icon",
      rounded: ["rounded", "rounded", booleanAttribute]
    },
    host: {
      properties: {
        "class": "containerClass()",
        "style": "style"
      }
    },
    providers: [TagStyle],
    queries: [{
      propertyName: "iconTemplate",
      first: true,
      predicate: ["icon"]
    }, {
      propertyName: "templates",
      predicate: PrimeTemplate
    }],
    usesInheritance: true,
    ngImport: i0,
    template: `
        <ng-content></ng-content>
        <ng-container *ngIf="!iconTemplate && !_iconTemplate">
            <span class="p-tag-icon" [ngClass]="icon" *ngIf="icon"></span>
        </ng-container>
        <span class="p-tag-icon" *ngIf="iconTemplate || _iconTemplate">
            <ng-template *ngTemplateOutlet="iconTemplate || _iconTemplate"></ng-template>
        </span>
        <span class="p-tag-label">{{ value }}</span>
    `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }, {
      kind: "directive",
      type: i1.NgClass,
      selector: "[ngClass]",
      inputs: ["class", "ngClass"]
    }, {
      kind: "directive",
      type: i1.NgIf,
      selector: "[ngIf]",
      inputs: ["ngIf", "ngIfThen", "ngIfElse"]
    }, {
      kind: "directive",
      type: i1.NgTemplateOutlet,
      selector: "[ngTemplateOutlet]",
      inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
    }, {
      kind: "ngmodule",
      type: SharedModule
    }],
    changeDetection: i0.ChangeDetectionStrategy.OnPush,
    encapsulation: i0.ViewEncapsulation.None
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: Tag,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-tag',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: `
        <ng-content></ng-content>
        <ng-container *ngIf="!iconTemplate && !_iconTemplate">
            <span class="p-tag-icon" [ngClass]="icon" *ngIf="icon"></span>
        </ng-container>
        <span class="p-tag-icon" *ngIf="iconTemplate || _iconTemplate">
            <ng-template *ngTemplateOutlet="iconTemplate || _iconTemplate"></ng-template>
        </span>
        <span class="p-tag-label">{{ value }}</span>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [TagStyle],
      host: {
        '[class]': 'containerClass()',
        '[style]': 'style'
      }
    }]
  }],
  propDecorators: {
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    severity: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    rounded: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    iconTemplate: [{
      type: ContentChild,
      args: ['icon', {
        descendants: false
      }]
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  }
});
class TagModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TagModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TagModule,
    imports: [Tag, SharedModule],
    exports: [Tag, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: TagModule,
    imports: [Tag, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: TagModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [Tag, SharedModule],
      exports: [Tag, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { Tag, TagClasses, TagModule, TagStyle };
