import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';

import { providePrimeNG } from 'primeng/config';
import Material from '@primeng/themes/material';
import { routes } from './app.routes';
import { definePreset, palette } from '@primeng/themes';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomerWizardStep2CustomerGuard } from './pages/customer-wizard/customer/step-2/customer-wizard-step-2.guard';
import { CustomerWizardStep3CustomerGuard } from './pages/customer-wizard/customer/step-3/customer-wizard-step-3.guard';
import { CustomerWizardStep4CustomerGuard } from './pages/customer-wizard/customer/step-4/customer-wizard-step-4.guard';
import { CustomerWizardStep5CustomerGuard } from './pages/customer-wizard/customer/step-5/customer-wizard-step-5.guard';
import { CustomerWizardStep6CustomerGuard } from './pages/customer-wizard/customer/step-6/customer-wizard-step-6.guard';
import { CustomerWizardStep2SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-2/customer-wizard-step-2.guard';
import { CustomerWizardStep3SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-3/customer-wizard-step-3.guard';
import { CustomerWizardStep4SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-4/customer-wizard-step-4.guard';
import { CustomerWizardStep5SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-5/customer-wizard-step-5.guard';
import { CustomerWizardStep6SelfEmployedGuard } from './pages/customer-wizard/self-employed/step-6/customer-wizard-step-6.guard';
import { PriceCalculationStep2aGuard } from './pages/price-calculation/step-2a/price-calculation-step-2a.guard';
import { PriceCalculationStep2bGuard } from './pages/price-calculation/step-2b/price-calculation-step-2b.guard';
import { PriceCalculationStep3Guard } from './pages/price-calculation/step-3/price-calculation-step-3.guard';
import { PriceCalculationStep4Guard } from './pages/price-calculation/step-4/price-calculation-step-4.guard';
import { PriceCalculationStep2cGuard } from './pages/price-calculation/step-2c/price-calculation-step-2c.guard';
import { PriceCalculationStep2dGuard } from './pages/price-calculation/step-2d/price-calculation-step-2d.guard';
import { PriceCalculationStep2eGuard } from './pages/price-calculation/step-2e/price-calculation-step-2e.guard';

const ebemGreen = palette("#A2C138");
const ebemBlue = palette("#1A699C");
const ebem = definePreset(Material, {
  semantic: {
    FontFace: 'Roboto',
      primary: {
          50: ebemGreen["50"],
          100: ebemGreen["100"],
          200: ebemGreen["200"],
          300: ebemGreen["300"],
          400: ebemGreen["400"],
          500: ebemGreen["500"],
          600: ebemGreen["600"],
          700: ebemGreen["700"],
          800: ebemGreen["800"],
          900: ebemGreen["900"],
          950: ebemGreen["950"],
      },
      secondary: {
          50: ebemBlue["50"],
          100: ebemBlue["100"],
          200: ebemBlue["200"],
          300: ebemBlue["300"],
          400: ebemBlue["400"],
          500: ebemBlue["500"],
          600: ebemBlue["600"],
          700: ebemBlue["700"],
          800: ebemBlue["800"],
          900: ebemBlue["900"],
          950: ebemBlue["950"],
      }
  }
});

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './i18n/', '.json');

export const appConfig: ApplicationConfig = {
    providers: [
      provideHttpClient(),
      provideZoneChangeDetection({ eventCoalescing: true }),
      provideRouter(routes, withComponentInputBinding(), withInMemoryScrolling({ anchorScrolling: 'enabled', scrollPositionRestoration: 'top' })),
      provideAnimationsAsync(),
      providePrimeNG({
        theme: {
          preset: ebem,
          options: {
              prefix: 'p',
              darkModeSelector: '.my-app-dark',
              cssLayer: false
          },
        },
      }),
      provideTranslateService({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
        defaultLanguage: 'nl',
        useDefaultLang: true,
      }),
      CustomerWizardStep2CustomerGuard,
      CustomerWizardStep3CustomerGuard,
      CustomerWizardStep4CustomerGuard,
      CustomerWizardStep5CustomerGuard,
      CustomerWizardStep6CustomerGuard,
      CustomerWizardStep2SelfEmployedGuard,
      CustomerWizardStep3SelfEmployedGuard,
      CustomerWizardStep4SelfEmployedGuard,
      CustomerWizardStep5SelfEmployedGuard,
      CustomerWizardStep6SelfEmployedGuard,
      PriceCalculationStep2aGuard,
      PriceCalculationStep2bGuard,
      PriceCalculationStep2cGuard,
      PriceCalculationStep2dGuard,
      PriceCalculationStep2eGuard,
      PriceCalculationStep3Guard,
      PriceCalculationStep4Guard
    ],
  };
  