<div>
<h1>Klant worden</h1>

<p>De prijzen die hier bij de producten weergegeven worden zijn gebaseerd op uw postcode, en geen exacte schatting.</p>
<p>Liever een duidelijke inschatting?</p>
<br/>
<p-button [routerLink]="['/price-calculation', 'step-1']">Bereken mijn voorstel</p-button>

<h2>Selecteer hier onder uw gewenste producten</h2>

<h3><span class="pi-bolt ebemGreen"></span> Elektriciteit</h3>
<product-selection-component [iconCSSClass]="'pi-bolt ebemGreen'" [checkmarksCSSClass]="'check-ebemGreen'" [selectedBackgroundCSSClass]="'backgroundEbemLightGreen'" [products]="electricityProducts" [(selection)]="electricityType"></product-selection-component>
<br/>
<h3><span class="material-icons ebemBlue">local_fire_department</span> Gas</h3>
<product-selection-component [iconCSSClass]="'material-icons ebemBlue'" [materialIcon]="'local_fire_department'" [checkmarksCSSClass]="'check-ebemBlue'" [selectedBackgroundCSSClass]="'backgroundEbemLightBlue'" [products]="gasProducts" [(selection)]="gasType"></product-selection-component>
<p-card class="danger no-padding" *ngIf="showErrorNoProductChosen">Gelieve een keuze te maken</p-card>
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="20" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">

    </div>
    <div class="col-6">
        <p-button class="float-right" (click)="evaluateForm() ? router.navigate(['/customer-wizard/self-employed', 'step-2'], { state: { 'gasType': gasType, 'electricityType': electricityType, 'company': company, 'contract': contract, 'paymentDetails': paymentDetails, 'postalCode': postalCode, 'priceSimulationInput': priceSimulationInput } }) : ''">Volgende</p-button> 
    </div>
</div>
</div>