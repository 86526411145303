<div>
<h3>Voorstel op maat</h3>

<h4>Heb je een digitale meter?</h4>
<p>Je type meter heeft impact op de verschillende facturatie mogelijkheden.</p>
<div>
<p-input-group (click)="priceSimulationOptionsDigitaleMeter.select($event); determineType(); calculateEstimates(); validateSimulationOptionsMeterTypeInput();">
    <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates(); validateSimulationOptionsMeterTypeInput(); priceSimulationInput.isDigital = true;" #priceSimulationOptionsDigitaleMeter [(ngModel)]="priceSimulationOptions.meterType" binary="false" [value]="PriceSimulationMeterType['Digitale meter']"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Digitale meter</label></div>
</p-input-group>
<p-input-group (click)="priceSimulationOptionsAnalogeMeter.select($event); determineType(); calculateEstimates(); validateSimulationOptionsMeterTypeInput();">
    <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates(); validateSimulationOptionsMeterTypeInput(); priceSimulationInput.isDigital = false;" #priceSimulationOptionsAnalogeMeter [(ngModel)]="priceSimulationOptions.meterType" binary="false" [value]="PriceSimulationMeterType['Analoge meter']"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Analoge meter</label></div>
</p-input-group>
<p-card class="danger no-padding" *ngIf="showErrorNoSimulationOptionsMeterTypeChosen">Gelieve een keuze te maken</p-card>
</div>

<h4>Is je meter enkelvoudig of tweevoudig?</h4>
<p>Of je meter enkelvoudig of tweevoudig is heeft impact op de prijs van je producten.</p>
<div>
<p-input-group (click)="priceSimulationOptionsEnkelvoudigeMeter.select($event); determineType(); calculateEstimates(); validateSimulationOptionsTariffTypeInput();">
    <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates(); validateSimulationOptionsTariffTypeInput()" #priceSimulationOptionsEnkelvoudigeMeter [(ngModel)]="priceSimulationOptions.tariffType" binary="false" [value]="PriceSimulationTariffType.Enkelvoudig"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Enkelvoudig (normaal tarief)</label></div>
</p-input-group>
<p-input-group (click)="priceSimulationOptionsTweevoudigeMeter.select($event); determineType(); calculateEstimates(); validateSimulationOptionsTariffTypeInput();">
    <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates(); validateSimulationOptionsTariffTypeInput()" #priceSimulationOptionsTweevoudigeMeter [(ngModel)]="priceSimulationOptions.tariffType" binary="false" [value]="PriceSimulationTariffType.Tweevoudig"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Tweevoudig (piek en dal)</label></div>
</p-input-group>
<p-checkbox #nightTariff [(ngModel)]="priceSimulationOptions.hasNightTariff" [binary]="true"></p-checkbox><p-button [styleClass]="'p-button-link-no-decoration'" [link]="true" [style]="{ padding: 0 }" (click)="nightTariff.updateModel(!nightTariff.checked)">&nbsp;&nbsp;&nbsp;Ik heb exclusief nachttarief</p-button>
<br/>
<p-card class="danger no-padding" *ngIf="showErrorNoSimulationOptionsTariffTypeChosen">Gelieve een keuze te maken</p-card>
</div>

<h4>Heb je zonnepanelen?</h4>
<p>Zonnepanelen hebben een grote impact op je verbruik dus deze nemen we graag mee in de berekening van je voorstel.</p>
<div>
<p-input-group (click)="priceSimulationOptionsIkHebZonnepanelen.select($event); determineType(); calculateEstimates(); validateSimulationOptionsSolarPanelsInput();">
    <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates(); validateSimulationOptionsSolarPanelsInput()" #priceSimulationOptionsIkHebZonnepanelen [(ngModel)]="priceSimulationOptions.solarPanels" binary="false" [value]="PriceSimulationSolarPanels.Zonnepanelen"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Ja, ik heb zonnepanelen</label></div>
</p-input-group>
<p-input-group (click)="priceSimulationOptionsIkHebGeenZonnepanelen.select($event); determineType(); calculateEstimates(); validateSimulationOptionsSolarPanelsInput();">
    <div class="radio-left"><p-radiobutton (click)="determineType(); calculateEstimates(); validateSimulationOptionsSolarPanelsInput()" #priceSimulationOptionsIkHebGeenZonnepanelen [(ngModel)]="priceSimulationOptions.solarPanels" binary="false" [value]="PriceSimulationSolarPanels['Geen zonnepanelen']"></p-radiobutton><label>&nbsp;&nbsp;&nbsp;Nee, ik heb geen zonnepanelen</label></div>
</p-input-group>
<p-card class="danger no-padding" *ngIf="showErrorNoSimulationOptionSolarPanelsChosen">Gelieve een keuze te maken</p-card>
</div>

<h4>Ken je je huidig verbruik?</h4>
<p>Met je exacte verbruik krijg je een voorstel voor dit verbruik. Als je je verbruik niet kent kunnen we je je verbruik helpen schatten.</p>
<div class="row">
    <div class="col-6">
        <p-inputgroup [ngClass]="priceSimulationFlow == PriceSimulationFlow['Verbruik invullen'] ? 'backgroundEbemLightGreen' : ''" (click)="priceSimulationFlowManual.select($event); validatePriceSimulationFlowInput()">
            <p-radiobutton class="radio-right" size="large"  #priceSimulationFlowManual [(ngModel)]="priceSimulationFlow" [value]="PriceSimulationFlow['Verbruik invullen']"></p-radiobutton>
            <img class="icon-small" [src]="'icons/product-selection-icon.png'" />     
            <p>Ja, ik kan mijn huidig verbruik invullen.</p>
        </p-inputgroup>
    </div>
    
    <div class="col-6">
        <p-inputgroup [ngClass]="priceSimulationFlow == PriceSimulationFlow['Simulatie starten'] ? 'backgroundEbemLightBlue' : ''" (click)="priceSimulationFlowQuestionaire.select($event); validatePriceSimulationFlowInput()">
            <p-radiobutton class="radio-right" size="large" #priceSimulationFlowQuestionaire [(ngModel)]="priceSimulationFlow" [value]="PriceSimulationFlow['Simulatie starten']"></p-radiobutton>
            <img class="icon-small" [src]="'icons/simulation-icon.png'" />
            <p>Nee, ik wil graag een simulatie invullen.</p>
        </p-inputgroup>
    </div>
</div>
<p-card class="danger no-padding" *ngIf="showErrorNoPriceSimulationFlowChosen">Gelieve een keuze te maken</p-card>
<br/>
<br/>
<p-progress-bar [showValue]="false" [value]="40" [style]="{ height: '6px' }" ></p-progress-bar>
<br/>
<br/>
<div class="row">
    <div class="col-6">
        <p-button class="float-left" outlined="true" [routerLink]="['/price-calculation', 'step-2']" [state]="{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }">Terug</p-button>
    </div>
    <div class="col-6">
        <p-button [severity]="priceSimulationFlow == PriceSimulationFlow['Verbruik invullen'] ? 'primary' : priceSimulationFlow == PriceSimulationFlow['Simulatie starten'] ? 'secondary' : 'primary'" class="float-right" (click)="validateForm() ? this.router.navigate(['/price-calculation', 'step-2' + priceSimulationFlow], { state:{ 'electricityType': electricityType, 'gasType': gasType, 'priceSimulationInput': priceSimulationInput, 'priceSimulationChoices': priceSimulationChoices, 'postalCode': postalCode, 'priceSimulationOptions': priceSimulationOptions, 'buildingType': buildingType, 'numberOfPersons': numberOfPersons, 'estimatedElectricityUse': estimatedElectricityUse, 'estimatedGasUse': estimatedGasUse }}) : null">{{ valueToKey(priceSimulationFlow) }} <span class="pi-arrow-right"></span></p-button>
    </div>
</div>
</div>