.card-container {
    position: relative;
    margin-top: 25px;
    margin-bottom: 75px;
}

.card-background {
    display: block;
    width: 100%;
    border-radius: 25px;
    height:635px;
    object-fit: cover;
}

.gray {
    color: gray !important;
}