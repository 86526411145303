import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, HostBinding, Input, Component, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const classes = {
  root: 'p-inputgroupaddon'
};
class InputGroupAddonStyle extends BaseStyle {
  name = 'inputgroupaddon';
  classes = classes;
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupAddonStyle,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  static ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupAddonStyle
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputGroupAddonStyle,
  decorators: [{
    type: Injectable
  }]
});

/**
 * InputGroupAddon displays text, icon, buttons and other content can be grouped next to an input.
 * @group Components
 */
class InputGroupAddon extends BaseComponent {
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  _componentStyle = inject(InputGroupAddonStyle);
  get hostStyle() {
    return this.style;
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupAddon,
    deps: null,
    target: i0.ɵɵFactoryTarget.Component
  });
  static ɵcmp = i0.ɵɵngDeclareComponent({
    minVersion: "14.0.0",
    version: "19.1.7",
    type: InputGroupAddon,
    isStandalone: true,
    selector: "p-inputgroup-addon, p-inputGroupAddon",
    inputs: {
      style: "style",
      styleClass: "styleClass"
    },
    host: {
      properties: {
        "class": "styleClass",
        "class.p-inputgroupaddon": "true",
        "attr.data-pc-name": "\"inputgroupaddon\"",
        "style": "this.hostStyle"
      }
    },
    providers: [InputGroupAddonStyle],
    usesInheritance: true,
    ngImport: i0,
    template: ` <ng-content></ng-content> `,
    isInline: true,
    dependencies: [{
      kind: "ngmodule",
      type: CommonModule
    }]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputGroupAddon,
  decorators: [{
    type: Component,
    args: [{
      selector: 'p-inputgroup-addon, p-inputGroupAddon',
      template: ` <ng-content></ng-content> `,
      standalone: true,
      imports: [CommonModule],
      host: {
        '[class]': 'styleClass',
        '[class.p-inputgroupaddon]': 'true',
        '[attr.data-pc-name]': '"inputgroupaddon"'
      },
      providers: [InputGroupAddonStyle]
    }]
  }],
  propDecorators: {
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    hostStyle: [{
      type: HostBinding,
      args: ['style']
    }]
  }
});
class InputGroupAddonModule {
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupAddonModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupAddonModule,
    imports: [InputGroupAddon, SharedModule],
    exports: [InputGroupAddon, SharedModule]
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "19.1.7",
    ngImport: i0,
    type: InputGroupAddonModule,
    imports: [InputGroupAddon, SharedModule, SharedModule]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.1.7",
  ngImport: i0,
  type: InputGroupAddonModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [InputGroupAddon, SharedModule],
      exports: [InputGroupAddon, SharedModule]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { InputGroupAddon, InputGroupAddonModule, InputGroupAddonStyle };
