import { Component } from "@angular/core";
import { UniqueSellingPointsComponent } from "src/app/components/usp/usp.component";
import { MissionStatementComponent } from "../../../components/mission-statement/mission-statement.component";
import { PageTitle } from "../../../components/page-title/page-title.component";
import { ImageComponent } from "../../../components/image/image.component";
import { TagModule } from "primeng/tag";
import { NewsComponent } from "../../../components/news/news.component";
import { ContactComponent } from "../../../components/contact/contact.component";
import { BusinessCardComponent } from "../../../components/business-card/business-card.component";
//import { NgxTurnstileComponent } from "ngx-turnstile";

@Component({
    selector: 'home-kmo-page',
    standalone: true,
    templateUrl: './home-kmo.page.html',
    styleUrls: ['home-kmo.page.scss'],
    imports: [TagModule, UniqueSellingPointsComponent, MissionStatementComponent, PageTitle, ImageComponent, NewsComponent, ContactComponent] //, NgxTurnstileComponent, BusinessCardComponent]
})
export class HomeKMOPage {
    constructor() {
        window.history.state.customerType = "kmo";
    }
}